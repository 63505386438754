import getDoubleClickEvent from "../../../../../../../utils/getDoubleClickEvent"

export default ({ viewType, controller, entityName, changeMode, rowData, isTheSameEntity, selectedCallback }) => {
  const models = controller.models()

  const { ID, ANALYTICAL } = rowData

  getDoubleClickEvent((doubleClickEvent) => {
    if (doubleClickEvent.isActive) {
      doubleClickEvent.isActive = false
      if (viewType === "registration") {
        models._REGISTRATION_DATA[entityName].operation = "UPD"
        changeMode()
      } else if (ANALYTICAL || isTheSameEntity || Number(ID) === 0)
        selectedCallback(rowData)
    }
  })
}
