import { Entypo } from "@expo/vector-icons"
import styled from "styled-components"
import { defaultTheme } from "../../../../../../styles/themes/default"

export const Container = styled.div`
  align-items: center;
  cursor: help;
  display: flex;
  justify-content: center;
  margin-left: 6px;
`

export const Icon = styled(Entypo)
  .attrs(({
    color: defaultTheme["cyan-800"],
    name: "info-with-circle",
    size: 12
  }))``
