import { MaterialIcons } from "@expo/vector-icons"
import styled from "styled-components/native"
import globalStyles from "../../../../../../../../global/globalStyles"

export const CardContainer = styled.TouchableOpacity`
  height: 30px;
  flex-direction: row;
  align-items: center;
  padding-left: 10px ;
  background-color: #fff;
  border-radius: 4px;
  border-bottom-width: 1px;
  border-bottom-color: #dadada;
`

export const MenuTitle = styled.Text`
  color: #2e2e2e;
  font-weight: 500;
`

export const ListArea = styled.ScrollView.attrs(({ isChild }) => ({
  contentContainerStyle: {
    padding: isChild ? 0 : 5,
    gap: isChild ? 0 : 8
  },
  style: {
    borderRadius: isChild ? 0 : 10
  }
}))`
  background-color: ${globalStyles.colors.background};
`

export const Icon = styled(MaterialIcons)`
  color: #2e2e2e;
  font-size: 26px;
`

