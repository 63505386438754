import { AntDesign } from "@expo/vector-icons"
import React, { useState, useEffect, useRef } from "react"
import ReactDOM from "react-dom"
import { defaultTheme } from "../../../../../../styles/themes/default"
import * as S from "./styles"

export default function DropdownInput({ data, value, onChange, handleGetUnits }) {
  const [isOpen, setIsOpen] = useState(false)
  const [dropdownPosition, setDropdownPosition] = useState({
    top: 0,
    left: 0
  })

  const dropdownRef = useRef(null)

  const toggleDropdown = (event) => {
    const rect = event.currentTarget.getBoundingClientRect()
    setDropdownPosition({
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX
    })
    setIsOpen(!isOpen)
  }

  const handleSelect = (event, item) => {
    event.stopPropagation()
    onChange(item)
    setTimeout(() => setIsOpen(false), 150)
  }

  useEffect(() => {
    handleGetUnits()

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setTimeout(() => setIsOpen(false), 150)
      }
    }

    const handleWheel = () => {
      setIsOpen(false)
    }

    const handleKeyDown = (event) => {
      if (event.key === "ArrowUp" || event.key === "ArrowDown") {
        setIsOpen(false)
      }
    }

    if (isOpen) {
      document.addEventListener("pointerdown", handleClickOutside)
      document.addEventListener("wheel", handleWheel)
      document.addEventListener("keydown", handleKeyDown)
    }

    return () => {
      document.removeEventListener("pointerdown", handleClickOutside)
      document.removeEventListener("wheel", handleWheel)
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [isOpen])

  return (
    <S.Container ref={dropdownRef} className="dropdown-container">
      <S.SelectedValue onClick={toggleDropdown}>
        {value}
        <AntDesign
          name="caretdown"
          size={12}
          color={defaultTheme["gray-600"]}
          style={{
            position: "absolute",
            right: 5,
            transform: [
              {
                rotate: isOpen ? "180deg" : "0deg"
              }
            ]
          }}
        />
      </S.SelectedValue>

      {isOpen &&
        ReactDOM.createPortal(
          <S.DropdownList
            style={{
              position: "absolute",
              top: dropdownPosition.top,
              left: dropdownPosition.left,
              zIndex: 9999
            }}
            className="dropdown-list"
          >
            {data.map((item) => (
              <S.DropdownListItem
                key={item.ID}
                onClick={(event) => handleSelect(event, item)}
              >
                {item.ID}
              </S.DropdownListItem>
            ))}
          </S.DropdownList>,
          document.body
        )}
    </S.Container>
  )
}
