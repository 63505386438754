export default class PriceTableData {

  static PRICETABLEDATA

  static getPRICETABLEDATA() { return this.PRICETABLEDATA }

  static setPRICETABLEDATA(PRICETABLEDATA) { this.PRICETABLEDATA = PRICETABLEDATA }

  static getItemPrice(itemID) {
    for (const key in this.PRICETABLEDATA) {
      if (itemID == key) return this.PRICETABLEDATA[key]
    }
  }

  static reset() { delete this.PRICETABLEDATA }
}
