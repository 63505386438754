import styled from "rn-css"
import globalStyles from "../../../../../global/globalStyles"

const Container = styled.View`
  flex-direction: row;

  border-bottom-width: 1px;
  border-bottom-color: ${globalStyles.colors.gridRowColor};
`

export default Container
