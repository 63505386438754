import AntDesign from "@expo/vector-icons/AntDesign"
import styled from "styled-components"
import { defaultTheme } from "../../../../../../styles/themes/default"

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
  padding: 2.7px 9px;
  border: 1px solid ${defaultTheme["gray-500"]};
  border-radius: 9px;
  width: 200px;
  height: 24.8px;
  background-color: ${defaultTheme["gray-100"]};
`

export const Input = styled.input`
  background-color: transparent;
  color: ${defaultTheme["gray-700"]};
  font-size: ${defaultTheme.formInput.fontSize * 0.9}px;
  width: 100%;
  border: none;
  height: 100%;
  outline: none;

  ::placeholder {
    color: ${defaultTheme["gray-300"]};
  }
`

export const Button = styled.button`
  background-color: transparent;
`

export const Icon = styled(AntDesign).attrs({
  color: defaultTheme["gray-500"],
  size: 18
})``