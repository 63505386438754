import { RowDataBoundEventArgs } from "@syncfusion/ej2-react-grids"
import { TreeGridComponent, ColumnsDirective, ColumnDirective, Inject, AggregateColumnDirective, AggregatesDirective, AggregateDirective, AggregateColumnsDirective } from "@syncfusion/ej2-react-treegrid"
import React from "react"
import { useTranslation } from "react-i18next"
import { ActivityIndicator } from "react-native"
import { Field } from "../../../services/registration/getTableFields"
import { defaultTheme } from "../../../styles/themes/default"
import RenderColumn from "../Grid/components/Column"
import useGrid from "../Grid/hooks/useGrid"
import getAggregates from "../Grid/utils/getAggregates"
import handleRowPress from "../Grid/utils/handleRowPress"
import GridToolbar from "../Toolbars/Grid"
import * as S from "./styles"
import getPadronizedData from "./utils/getPadronizedData"
import services from "./utils/services"

export default function TreeGrid({ entity, models, functions, gridRef, rowDoubleClickCallback, screenType }) {
  const { t } = useTranslation()

  const {
    fieldsData,
    records,
    updateGridRecords,
    updateGridFields,
    loading
  } = useGrid({
    entity,
    models,
    gridRef
  })

  const { fields, detailTabs } = fieldsData
  const aggregates = getAggregates(fields)

  const treeRecords = getPadronizedData({
    records,
    models,
    entity
  })

  const onRowPress = (args: RowDataBoundEventArgs) => {
    handleRowPress({
      args,
      grid: gridRef.current,
      entity,
      models,
      detailTabs,
      records,
      rowDoubleClickCallback,
      screenType
    })
  }

  const pageSettings = {
    pageSize: 30,
    pageCount: 4,
    pageSizes: [30, 50, 100, 200, "All"]
  }

  const renderAggregate = ({ operation, field }) => {
    const isCurrencyField = field.FIELDTYPE.VALUE === "C"
    const isIntegerField = field.FIELDTYPE.VALUE === "I"

    const valuePrefix = isCurrencyField ? "R$ " : ""
    const defaultDecimalValue = isIntegerField ? 0 : 2

    const fixedValue = props => {
      const value = typeof props[operation] === "number" ? props[operation] : 0
      return (value).toFixed(field.T_DTD_ATR.DECIMAL ?? defaultDecimalValue)
    }

    const aggregateTemplate = props => {
      return (
        <S.FooterItem>
          <S.BoldText>{t(operation)}:</S.BoldText>
          <span>{valuePrefix} {fixedValue(props)}</span>
        </S.FooterItem>
      )
    }

    return (
      <AggregateColumnDirective
        key={field.ID}
        field={field.ID}
        type={operation}
        footerTemplate={aggregateTemplate}
      >{" "}</AggregateColumnDirective>
    )
  }

  const selectionSettings = { checkboxOnly: true }

  return (
    <S.Container>
      <style>
        {`
          div.e-gridcontent {
            height: calc(100% - ${screenType === "searchModal" ? 65 + aggregates.length * 27 : 164 + aggregates.length * 27}px) !important;
          }
        `}
      </style>
      <GridToolbar
        gridRef={gridRef}
        entity={entity}
        models={models}
        functions={{
          ...functions,
          handleRefresh: async () => {
            gridRef.current.showSpinner()
            await updateGridRecords()
            gridRef.current.hideSpinner()
          }
        }}
      />
      <TreeGridComponent
        id={"Hierarchy" + entity}
        ref={gridRef}
        key={"Hierarchy" + entity}
        allowFiltering={true}
        allowPaging={true}
        allowReordering={true}
        allowResizing={true}
        allowSelection={true}
        allowSorting={true}
        autoCheckHierarchy={true}
        childMapping="subtasks"
        dataSource={loading ? [] : treeRecords}
        filterSettings={{ type: "Menu" }}
        gridLines='Both'
        height="100%"
        pageSettings={pageSettings}
        rowDataBound={onRowPress}
        selectionSettings={selectionSettings}
        showColumnChooser={true}
        showColumnMenu={true}
        treeColumnIndex={1}
        width={"100%"}
        emptyRecordTemplate={() => {
          if (!loading && !treeRecords.length) return (
            <div style={{
              position: "absolute",
              top: 20,
              left: "50%",
              width: "100%",
              height: "100%"
            }}>
              <span style={{ color: defaultTheme["gray-500"] }}>{t("No records to display")}</span>
            </div>
          )
          if (loading) return (
            <div style={{
              position: "absolute",
              top: 20,
              left: 0,
              width: "100%",
              height: "100%"
            }}>
              <ActivityIndicator size={"small"} color={defaultTheme["cyan-800"]} />
            </div>
          )
        }}
      >
        <ColumnsDirective>
          <ColumnDirective
            type='checkbox'
            width={80}
            textAlign="Center"
            headerTextAlign="Center"
          />
          {fields
            .filter(({ ID, FIELDTYPE }) => FIELDTYPE.VALUE === "A" ? ID === "IMG" : true)
            .map((field) => RenderColumn({
              fieldData: field,
              t
            })
            )}
        </ColumnsDirective>

        <AggregatesDirective>
          {aggregates.map(({ operation, fields }) => (
            <AggregateDirective key={operation}>
              <AggregateColumnsDirective>
                {fields.map((field: Field) => renderAggregate({
                  operation,
                  field
                }))}
              </AggregateColumnsDirective>
            </AggregateDirective>
          ))}
        </AggregatesDirective>

        <Inject services={services} />
      </TreeGridComponent>
    </S.Container>
  )
}
