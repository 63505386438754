import RuntimeConfiguration from '../../../../models/runtimeConfiguration'
import dbRequest from '../../../../services/dbRequest/dbRequest'

export default async function getDashboardInterface(dashboardId) {
  const isAdminMode = RuntimeConfiguration.getDashboardBuilderAdminMode()
  try {
    const dataPath = `T_RDB_DSB/${dashboardId}/T_RDB_LVL`

    let levelsRequest = dbRequest.loadRecords(dataPath)

    if (isAdminMode) levelsRequest = levelsRequest.admin()

    const levels = await levelsRequest.execute()

    await Promise.all(levels.map(async (level) => {

      let gadgetsRequest = dbRequest.loadRecords(`${dataPath}/${level.ID}/T_RDB_GDG`)

      if (isAdminMode) gadgetsRequest = gadgetsRequest.admin()

      level.gadgets = await gadgetsRequest.execute()

      await Promise.all(level.gadgets.map(async (gadget) => {
        let seriesDataRequest = dbRequest.loadRecords(`${dataPath}/${level.ID}/T_RDB_GDG/${gadget.ID}/T_RDB_SRS`)

        if (isAdminMode) seriesDataRequest = seriesDataRequest.admin()

        const seriesData = await seriesDataRequest.execute()
        gadget.series = seriesData
        return gadget
      }))
    }))

    return levels
  } catch (error) {
    console.error("Error getting dashboard data:", error)
    throw error
  }
}