import styled from "styled-components"
import { defaultTheme } from "../../../../../../../../../styles/themes/default"

const Container = styled.div`
  align-items: center;
  background-color: ${defaultTheme["white"]};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: auto;
  min-height: 50vh;
  padding: 10px;
  position: relative;
  width: 35%;
`

export default Container
