import React from "react"
import ModalWrapper from "../../../../../Modal"
import getModalContent from "../../utils/getModalContent"
import Container from "./styles"

export default function RenderModal({ isVisible, entityName, controller, selectedOption, closeModal, setUpdateForm }) {
  return (
    <ModalWrapper isVisible={isVisible}>
      <Container>
        {getModalContent({
          entityName,
          controller,
          selectedOption,
          closeModal,
          setUpdateForm
        })}
      </Container>
    </ModalWrapper>
  )
}
