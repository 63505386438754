import { MaterialCommunityIcons } from "@expo/vector-icons"
import styled from "rn-css"
import globalStyles from "../../../../../global/globalStyles"

export const ModalContent = styled.View`
  background-color: ${globalStyles.colors.tertiary};

  height: 60%;
  width: 45%;

  border-radius: 10px;
`

export const CloseButton = styled.TouchableOpacity`
  position: absolute;
  justify-content: center;
  align-items: center;
  z-index: 2;

  top: 5px;
  right: 5px;
  width: 25px;
  height: 25px;

  border-radius: 4px;

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
`

export const Icon = styled(MaterialCommunityIcons)`
  color: #2E2E2E;
  font-size: 20px;
`

export const Container = styled.View`
  width: 100%;
  gap: 15px;
  height: 100%;

  padding: 8px 12px;

  border-radius: 4px;

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
`

export const ListsView = styled.View`
  flex-direction: row;

  flex: 1;
  gap: 20px;
`

export const DividingLine = styled.View`
  background-color: ${globalStyles.colors.placeholder};

  height: 100%;
  width: 1px;
`
