import React from "react"
import * as S from "./styles"

export default function Currency({ fieldData, fieldValue = 0, isSelected }) {
  const ATR = fieldData.T_DTD_ATR

  const decimalPlacesAmount = ATR.DECIMAL ?? 2

  const value = fieldValue?.toLocaleString("pt-BR", {
    maximumFractionDigits: decimalPlacesAmount,
    minimumFractionDigits: decimalPlacesAmount
  })

  return (
    <S.Container>
      <S.Text isSelected={isSelected}>R$</S.Text>

      <S.Text isSelected={isSelected}>{value}</S.Text>
    </S.Container>
  )
}
