// eslint-disable-next-line max-len
import { MaterialCommunityIcons, FontAwesome } from "@expo/vector-icons/"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { removeProduct } from "../../../../redux/redux-reducers/order-reducer"
import hasPermissionToUpdate from "../../../../services/hasPermissionToUpdate"
import showAlert from "../../../../services/showAlert"
import { defaultTheme } from "../../../../styles/themes/default"
import ImageField from "../components/ImageField"
import ModalForm from "../components/ModalForm"
import DropdownInput from "./components/DropdownInput"
import RenderField from "./components/RenderField/index.web"
import useProductManager from "./hooks/useProductManager"
import getDetailFields from "./hooks/utils/getDetailFields"
import * as S from "./styles.web"

export default function DynamicItemCard({ item, controller }) {

  const { t } = useTranslation()

  const screenName = controller.screenName

  const dispatch = useDispatch()

  const [showModalForm, setShowModalForm] = useState(false)

  const {
    productTemplate,
    units,
    selectedUnit,
    setSelectedUnit,
    handleCalculation,
    editAmountNeg,
    fields,
    defaultAmount,
    handleGetUnits
  } = useProductManager(item, controller)

  async function handleOpenModal() {
    const fields = await getDetailFields(controller.listEntityName())
    if (fields.length === 0) return showAlert({
      titleType: "info",
      text: "There are no details to be presented"
    })
    setShowModalForm(true)
  }

  const canUpdate = hasPermissionToUpdate(controller.models())

  const productImage = screenName === "Products" ? item?.IMG?.[0]?.URL : item?.ITEMID?.IMG?.[0]?.URL
  const procuctDescription = screenName === "Products" ? item?.DESCRIPTION : item.ITEMID?.DESCRIPTION
  const productID = screenName === "Products" ? item?.ID : item.ITEMID?.ID

  return (
    <S.Container
      style={{
        border: (productTemplate?.QUANTITY && productTemplate?.AMOUNTNEG > 0) ?
          `1.5px solid ${defaultTheme["cyan-300"]}`
          : `1px solid ${defaultTheme["gray-400"]}`,
        backgroundColor: (productTemplate?.QUANTITY && productTemplate?.AMOUNTNEG > 0) ? "#D6FFFC15" : defaultTheme["white"]
      }}
    >

      {
        (productTemplate?.QUANTITY && productTemplate?.AMOUNTNEG > 0 && screenName === "Products") ?
          <S.MarkerContainer>
            <MaterialCommunityIcons name="cart-check" size={20} color={defaultTheme["cyan-700"]} />
          </S.MarkerContainer>
          : null
      }

      {(screenName !== "Products" && canUpdate) &&
        <S.RemoveButton onClick={() => showAlert({
          type: "confirm",
          text: "Do you want to delete the product?",
          title: "Product deletion",
          titleType: "warning",
          handleConfirm: () => {
            dispatch(removeProduct({ newAllFields: item }))
            controller.deleteItem(item)
          }
        })}>
          <FontAwesome name="trash-o" size={18} color={defaultTheme["white"]} />
        </S.RemoveButton>
      }

      <S.InfoButton onClick={handleOpenModal}>i</S.InfoButton>
      {
        showModalForm &&
        <ModalForm
          showModalForm={showModalForm}
          setShowModalForm={setShowModalForm}
          controller={controller}
          entityFields={getDetailFields(controller.listEntityName())}
        />
      }

      <ImageField url={productImage} description={procuctDescription} />

      <S.FieldContent style={{ width: "65%" }}>
        <S.FieldTitle>{t("Description")}</S.FieldTitle>
        <S.FieldValue>{procuctDescription}</S.FieldValue>
      </S.FieldContent>
      <S.FieldContent style={{ width: "20%" }}>
        <S.FieldTitle>ID</S.FieldTitle>
        <S.FieldValue>{productID}</S.FieldValue>
      </S.FieldContent>

      {
        fields.map((field) => {
          return (<RenderField
            key={field.ID} field={field}
            item={productTemplate}
            selectedUnit={selectedUnit}
            handleCalculation={handleCalculation}
            defaultAmount={defaultAmount}
            editAmountNeg={editAmountNeg}
            isOrderScreen={screenName !== "Products"}
            models={controller.models()}
            params={{
              T_REC_ITE: item,
              T_MOV_ITE: productTemplate
            }}
          />)
        })
      }

      <S.FieldContent style={{ width: "10%" }}>
        <S.FieldTitle>{t("Unit")}</S.FieldTitle>
        {screenName === "Products" ? <DropdownInput
          data={units}
          value={selectedUnit?.ID}
          handleGetUnits={handleGetUnits}
          onChange={item => {
            setSelectedUnit(item)
            handleCalculation("change-unit", item)
          }}
        />
          :
          <S.FieldValue>{productTemplate.UNITID.ID}</S.FieldValue>
        }
      </S.FieldContent>

      <S.FieldContent style={{ width: "25%" }}>
        <S.FieldTitle>{t("Quantity")}</S.FieldTitle>
        {screenName === "Products" ?
          <S.QuantityInputContainer>
            <S.DecreaseButton onClick={() => handleCalculation("decrement-quatity")}>-</S.DecreaseButton>
            <S.QuantityInput
              placeholder="0"
              precision={selectedUnit?.QTTDEC}
              minValue={0}
              type="money"
              prefix=""
              keyboardType="numeric"
              value={productTemplate?.QUANTITY ?? 0}
              onChangeValue={r => handleCalculation("manual-quantity", r)}
            />
            <S.IncreaseButton onClick={() => handleCalculation("increment-quantity")}>+</S.IncreaseButton>
          </S.QuantityInputContainer>
          :
          <S.FieldValue>
            {productTemplate?.QUANTITY}
          </S.FieldValue>
        }
      </S.FieldContent>

    </S.Container >
  )
}
