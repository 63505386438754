export const defaultTheme = {
  "black": "#000000",
  "shadow": "#00000029",
  "white": "#FFFFFF",
  "white-transparent-61": "#FFFFFF9C",
  "gray-100": "#F5F5F5",
  "gray-200": "#EBEBEB",
  "gray-300": "#CCCCCC",
  "gray-400": "#C9C9C9",
  "gray-500": "#888888",
  "gray-600": "#707070",
  "gray-700": "#535353",
  "gray-900": "#2E2E2E",
  "cyan-300": "#51DACF",
  "cyan-500": "#00ACC1",
  "cyan-700": "#2B7F86",
  "cyan-800": "#297D85", //primary
  "red-100": "#FFCCCC",
  "red-400": "#F04242",
  "red-500": "#FF1A1A",
  "red-900": "#F60000",
  "orange-500": "#FFAA3A",
  "green-100": "#CCFFCC",
  "green-700": "#00CC00",

  "formInput": {
    "height": 26,
    "fontSize": 14
  }
} as const

// Mobile only
export const fontFamily = {
  "thin": "Roboto_100Thin",
  "light": "Roboto_300Light",
  "regular": "Roboto_400Regular",
  "medium": "Roboto_500Medium",
  "bold": "Roboto_700Bold"
} as const
