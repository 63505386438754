import React from "react"
import { useTranslation } from "react-i18next"
import showAlert from "../../../../../../../../../services/showAlert"
import * as S from "./styles"
import handleSave from "./utils/handleSave"

export default function Header({ entityName, models, closeModal, permissions }) {
  const { t } = useTranslation()

  const handleConfirm = () => {
    showAlert({
      type: "confirm",
      text: "Do you want to save?",
      title: "Access configuration",
      handleConfirm: async () => await handleSave(entityName, models, closeModal, permissions)
    })
  }

  return (
    <S.Container>
      <S.Title>{t("Access Configuration")}</S.Title>

      <S.ButtonsView>
        <S.SaveButton onPress={handleConfirm}>
          <S.SaveText>{t("Save")}</S.SaveText>
        </S.SaveButton>

        <S.CloseButton onPress={closeModal}>
          <S.Icon name="close" />
        </S.CloseButton>
      </S.ButtonsView>
    </S.Container>
  )
}
