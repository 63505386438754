import showAlert from "../../../../../services/showAlert"
import clearData from "./utils/insert/clearData"

export default function insertRecord(props) {
  const { mode } = props

  mode === "table"
    ? clearData(props)
    : showAlert({
      type: "confirm",
      text: "Do you want to insert a new record?",
      title: "Unsaved changes will be lost",
      titleType: "warning",
      handleConfirm: () => { clearData(props) }
    })
}
