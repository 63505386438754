const getProductGroups = async (controller) => {
  const data = controller.recordsList

  const groups = data.reduce((acc, item) => {
    const groupId = item?.GROUPITEMID?.ID

    if (!acc.some(group => group.VALUE === groupId)) {
      acc.push({
        LABEL: item?.GROUPITEMID?.LABEL,
        VALUE: groupId
      })
    }

    return acc
  }, [])

  return groups
}

export default getProductGroups
