import LoginData from "../../../../../models/loginData"
import Permissions from "../../../../../models/permissions"
import compareModels from "./utils/switch/compareModels"
import insertValidation from "./utils/switch/insertValidation"
import tableModeValidation from "./utils/switch/tableModeValidation"
import updateValidation from "./utils/switch/updateValidation"

export default async function switchMode(props) {
  const { models, entityName, setSelectedItem, mode, changeMode } = props

  if (mode === "table") {
    tableModeValidation(props)
    return
  }

  const callback = () => {
    models._REGISTRATION_DATA[entityName].files = {}
    models[entityName].reset()
    setSelectedItem(null)
    changeMode()
  }

  const isAnUpdate = models._REGISTRATION_DATA[entityName].operation === "UPD"

  const { ADMIN } = LoginData.getLoginData()

  const hasPermissionToUpdate = ADMIN || Permissions.getPermissions("UPDATE")

  if (isAnUpdate && hasPermissionToUpdate) {
    const hasModifiedField = compareModels({
      models,
      entityName
    })

    if (hasModifiedField) {
      updateValidation(props, callback)
      return
    }
  }

  insertValidation(callback)
}
