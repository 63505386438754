import React from "react"
import * as S from "./styles"

export default function ExpandableButton({ onPress, isVisible = false }: { onPress: () => void, isVisible?: boolean }) {

  return (
    <S.Button onPress={onPress}>
      <S.Icon name={!isVisible ? "screen-full" : "screen-normal"} />
    </S.Button>
  )
}
