const handleDrop = ({ event, setIsDragging, setSelectedFile, setPreviewImage }) => {
  event.preventDefault()
  setIsDragging(false)
  const files = [...event.dataTransfer.files]

  if (files.length > 0) {
    const file = files[0]
    setSelectedFile(file)
    const reader = new FileReader()
    reader.onloadend = () => setPreviewImage(reader.result)
    reader.readAsDataURL(file)
  }
}

export default handleDrop
