/* eslint-disable no-prototype-builtins */
import { addDoc, collection, doc, setDoc } from "firebase/firestore/lite"
import globalDate from "../../../../global/globalDate"
import LoginData from "../../../../models/loginData"
import checkSession from "../../checkSession"
import { dbConnection } from "../../dbConnection"

export default async function saveRecords(admin, _collection, record) {

  if (!await checkSession(admin)) new Error("Session expired")

  const creationDate = new globalDate().toISO()

  const { ID, DESCRIPTION, IMG = [] } = LoginData.getLoginData()

  const userObj = {
    ID,
    DESCRIPTION,
    IMG
  }

  const userTimestamps = {
    UPDATED_BY: userObj,
    UPDATED_AT: creationDate
  }

  if (!record.CREATED_AT) {
    // @ts-ignore
    userTimestamps.CREATED_AT = creationDate
    // @ts-ignore
    userTimestamps.CREATED_BY = userObj
  }

  if (record.ID) {
    const _doc = doc(dbConnection(admin), _collection, `${record.ID}`)
    const _id = record.ID
    delete record.ID

    await setDoc(_doc, {
      ...record,
      ...userTimestamps
    })

    return {
      ID: String(_id),
      ...record,
      ...userTimestamps
    }
  }

  delete record?.ID

  const collectionRef = collection(dbConnection(admin), _collection)

  const key = await addDoc(collectionRef, {
    ...record,
    ...userTimestamps
  })

  record.ID = String(key.id)

  return {
    ...record,
    ...userTimestamps
  }
}

