import React from "react"
import { ScrollView, View } from "react-native"
import globalStyles from "../../../../../../../global/globalStyles"
import * as S from "../styles"

export default function renderMenu(options, models, setUpdate, t, isAdmin) {

  const handlePress = item => {
    item.open = !item.open
    models.MENU.reset()
    models.MENU.populate({ ...item })
    setUpdate(s => !s)
  }

  const isItemSelected = id => models.MENU.ID === id

  const handleRecursion = ({ SUB_MENUS = [], T_DTD_LNC = {} }) => {
    const optionsToRender = SUB_MENUS.length > 0 ? SUB_MENUS : Object.values(T_DTD_LNC ?? {})
    return renderMenu(optionsToRender, models, setUpdate, t)
  }

  return (
    <ScrollView>
      {options.map((option, index) => {
        const { ID, DESCRIPTION, DEPTH, open } = option

        if (DEPTH === 0)
          if ((isAdmin && ID === "9999") || (!isAdmin && ID !== "9999")) return

        return (
          <S.MenuButtonView key={index}>
            <S.MenuButton
              style={isItemSelected(ID) && { backgroundColor: globalStyles.colors.tertiary }}
              onPress={() => handlePress(option)}
            >
              <S.ButtonText style={isItemSelected(ID) && { color: globalStyles.colors.primary }}>
                {t(DESCRIPTION)}
              </S.ButtonText>

            </S.MenuButton>

            {open &&
              <View style={{ paddingLeft: 20 }}>
                {handleRecursion(option)}
              </View>
            }
          </S.MenuButtonView>
        )
      })}
    </ScrollView>
  )
}

