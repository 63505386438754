import { FontAwesome } from "@expo/vector-icons"
import React from "react"
import { useTranslation } from "react-i18next"
import showAlert from "../../../services/showAlert"
import { defaultTheme } from "../../../styles/themes/default"
import * as S from "./styles"

const languages = {
  en: "English",
  fr: "French",
  de: "German",
  ja: "Japanese",
  pt: "Portuguese",
  es: "Spanish"
}
const LANGUAGE_KEY = "user-language" // Chave para armazenar o idioma no AsyncStorage

export default function TranslationModal() {

  const { t, i18n } = useTranslation()

  const changeLanguage = (value) => {
    i18n.changeLanguage(value)
    localStorage.setItem(LANGUAGE_KEY, value)
  }

  const handleClickButton = async () => {
    showAlert({
      type: "select",
      selectInputOptions: languages,
      title: "Change Languange",
      text: "Select a language:",
      handleConfirm: (value) => {
        if (value) {
          changeLanguage(value)
          showAlert({
            type: "ok",
            titleType: "success",
            title: t("Language changed successfully!"),
            text: ""
          })
        }
      }
    })
  }

  return (
    <S.Container onClick={handleClickButton}>
      <FontAwesome name='globe' size={26} color={defaultTheme["white"]} />
    </S.Container>
  )
}
