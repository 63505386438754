import { doc, updateDoc } from "firebase/firestore/lite"
import { v4 as uuidv4 } from "uuid"
import { dbConnection } from "../../dbConnection"

const LOCAL_STORAGE_KEY = "sessionToken"

export default async function saveSessionToken(record) {
  const _doc = doc(dbConnection(true), "-USER_RELATIONSHIP", record.ID)

  const sessionToken = uuidv4()

  await updateDoc(_doc, { SESSIONID: sessionToken })

  localStorage.setItem(LOCAL_STORAGE_KEY, sessionToken)
}
