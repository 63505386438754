/* eslint-disable @typescript-eslint/ban-ts-comment */
import { GridProps } from ".."
import dbRequest from "../../../../services/dbRequest/dbRequest"
import evaluateFunction from "../../../../services/evaluateFunction"

interface Filter {
  field: string
  operator: string
  value: string | number | boolean
}

const getGridRecords = async ({ entity, models, detailTabPath, screenType, staticFilters }: GridProps) => {
  const tableData = models._REGISTRATION_DATA[entity]

  let tableFilters = null

  if (screenType === "searchModal") {
    tableFilters = await evaluateFunction(staticFilters ?? "return []", models)
  } else {
    tableFilters = await evaluateFunction(tableData.tableFilter ?? "return []", models)
  }

  let request = dbRequest.loadRecords(detailTabPath || entity)

  for (const filter of tableFilters) {
    const { field, operator, value } = filter
    request = request.where(field, operator, value)
  }

  const records = await request.execute((response) => !response ? [] : response)
  //@ts-ignore
  const visibleRecords = records.filter(({ ID }) => Boolean(ID))

  models._REGISTRATION_DATA[entity].records = visibleRecords

  return visibleRecords
}

export default getGridRecords
