import React from "react"
import {
  AmountText,
  Container
} from "./styles"

export default function Totalizer({ recordsAmount }) {
  return (
    <Container>
      <AmountText>
        {Number(recordsAmount)
          .toLocaleString("pt-BR")}
      </AmountText>
    </Container>
  )
}
