/* eslint-disable object-property-newline */
import styled from "styled-components"
import globalStyles from "../../../../global/globalStyles"
import { defaultTheme } from "../../../../styles/themes/default"

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 60%;
  height: auto;
  align-items: center;
  margin-top: 10px;
`

export const Button = styled.div`
  margin: 4px 10px;
`

export const ButtonText = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: ${defaultTheme["gray-500"]};

  &:hover {
    color: ${globalStyles.colors.editButton};
  }
`

export const Separator = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: gray;
`
