import styled from "rn-css"
import globalStyles from "../../../../../../../../../../global/globalStyles"

export const Text = styled.Text`
  color: ${({ isSelected }) => isSelected ? globalStyles.colors.tertiary : globalStyles.colors.primary};
  font-size: 12px;
  text-align: center;
`

export default Text
