export default props => {
  const { mode, models, entityName, changeMode, setUpdateForm, setSelectedItem } = props

  models[entityName].reset()

  models._REGISTRATION_DATA[entityName].operation = "INS"
  setSelectedItem(null)

  models._REGISTRATION_DATA[entityName].files = {}

  mode === "table" ? changeMode() : setUpdateForm(s => !s)
}
