import showAlert from "../../../../../../../../services/showAlert"

export default ({ controller }) => {
  const models = controller.models()

  if (!models.T_DTD_TAB.ID) return showAlert({
    text: "No table selected",
    title: "Failed to export data",
    titleType: "error"
  })

  showAlert({
    type: "confirm",
    text: "Would you like to export the selected table?",
    title: "Data will be exported in JSON format",
    handleConfirm: () => {
      const jsonString = JSON.stringify(models.T_DTD_TAB)

      // Create a Blob
      const blob = new Blob([jsonString], { type: "application/json" })

      // Create a download link
      const link = document.createElement("a")
      link.href = window.URL.createObjectURL(blob)
      link.download = `${models.T_DTD_TAB.ID}.json`

      // Add the link to the DOM and automatically click it to initiate the download
      document.body.appendChild(link)
      link.click()

      // Remove the link from the DOM after the download
      document.body.removeChild(link)
    }
  })
}
