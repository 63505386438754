import React from "react"
import { useDrag } from "react-dnd"
import { useTranslation } from "react-i18next"
import ItemTypes from "../../../ItemTypes"
import * as S from "./styles"

export default function Card({ fieldData, setVisibleFields, setNonVisibleFields }) {
  const { t } = useTranslation()

  const { id, description, required } = fieldData

  console.log(fieldData)

  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.CARD,
    item: () => ({
      id,
      description
    }),
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult()
      if (item && dropResult) {
        removeNonVisibleField()
        addVisibleField()
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId()
    })
  }))

  const removeNonVisibleField = () => {
    setNonVisibleFields(state => {
      return state.filter(field => field.id !== id)
    })
  }

  const addVisibleField = () => {
    setVisibleFields(state => {
      return [...state, {
        ...fieldData,
        visible: true
      }]
    })
  }

  const cardOpacity = isDragging ? 0.4 : 1

  return (
    <div title={t(description)}>
      <S.Container ref={drag} data-testid={"card"} style={{ opacity: cardOpacity }}>
        <S.Text>{t(description)}</S.Text>
      </S.Container>
    </div>
  )
}
