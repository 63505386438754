import React, { useState, useEffect } from "react"
import { Button, Title } from "./styles"

export default function Glyphs({ index, ChartArray, resizeCallback, setUpdate }) {
  const [update, setLocalUpdate] = useState(false)

  useEffect(() => {
    ChartArray.getPosition(index).setForceUpdate = () => setLocalUpdate(s => !s)
  }, [index, ChartArray])

  function handlePressIn() {
    ChartArray.buttonDown = true
    ChartArray.selectedCoordinate = index
    setUpdate(s => !s)
  }

  const handlePressOut = () => {
    ChartArray.buttonDown = false
  }

  function handleMouseEnter() {
    if (!ChartArray.buttonDown) return
    resizeCallback(index)
    setUpdate(s => !s)
  }

  return (
    <Button
      index={index}
      onPressIn={handlePressIn}
      onPressOut={handlePressOut}
      onMouseEnter={handleMouseEnter}
      style={ChartArray.getPosition(index).id !== 0 ? { backgroundColor: ChartArray.getPosition(index).color } : null}
    >
      <Title selectable={false}>{ChartArray.getPosition(index).id ?? ""}</Title>
    </Button>
  )
}


