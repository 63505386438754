import styled from "styled-components"
import { defaultTheme } from "../../../../../../../../../../../../../styles/themes/default"

export const ModalContainer = styled.div`
  width: 50%;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  background-color: ${defaultTheme["gray-100"]};
`
export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 70px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 16px 20px 30px;
  background-color: ${defaultTheme["gray-200"]};
`

export const CloseButton = styled.div`
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const ButtonsView = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
`

export const SaveButtonContainer = styled.div`
  align-items: center;
  background-color: ${defaultTheme["gray-100"]};
  border-radius: 14px;
  cursor: pointer;
  display: flex;
  gap: 6px;
  justify-content: center;
  padding: 3px 10px 3px 3px;
  transition: opacity 0.2s;

  &:active {
    opacity: 0.3;
  }

  &:hover {
    box-shadow: 0px 4px 8px #00000019;
  }

  span {
    color: ${defaultTheme["gray-500"]};
    font-size: 0.875rem;
  }
`

export const SaveButton = styled.button`
  align-items: center;
  background-color: ${defaultTheme["cyan-300"]
  };
  border-radius: 50%;  
  display: flex;
  height: 26px;
  justify-content: center;
  width: 26px;


  transition: opacity 0.2s;

  &:active {
    opacity: 0.3;
  }
`

export const Title = styled.h2`
  font-size: 20px;
  color: ${defaultTheme["gray-700"]};
  font-weight: 600;
`

