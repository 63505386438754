import globalStyles from "../../../../../global/globalStyles"
import { ChartAttributesProps, ChartType, Series } from "../../../types/types"

type GenerateChartAttributesProps = {
  DESCRIPTION: string
  GADGET: ChartType
  actualValue: number
  dashboardParams: object,
  series: Series
}

export default function generateChartAttributes(props: GenerateChartAttributesProps) {

  const { DESCRIPTION, GADGET, actualValue, series } = props

  const attributes: ChartAttributesProps = {
    backgroundColor: globalStyles.colors.tertiary,
    title: DESCRIPTION,
    titleTextStyle: {
      fontSize: 16
    },
    series: {},
    hAxis: {
      viewWindow: {
        min: 0,
        max: GADGET !== "BarChart" && actualValue
      },
      slantedText: true
    },
    vAxis: {
      viewWindow: {
        min: 0,
        max: GADGET === "BarChart" && actualValue
      }
    },
    legend: { position: GADGET === "PieChart" ? null : "top" }
  }

  createSeriesType(GADGET, attributes, series)
  getColors(attributes, series)

  return attributes
}

function createSeriesType(GADGET: ChartType, attributes: ChartAttributesProps, serie: Series) {

  if (GADGET.VALUE === "ComboChart") {
    for (const id in serie) attributes.series[Number(id) - 1] = { type: serie[id].SERIETYPE.VALUE ?? "line" }
  }
  return attributes
}

function getColors(attributes: ChartAttributesProps, serie: Series) {

  if (serie?.["1"].COLOR) {
    attributes["colors"] = []
    for (const id in serie) attributes.colors.push(serie[id].COLOR)
  }

  return attributes
}
