import dbRequest from "../../../../services/dbRequest/dbRequest"
import showAlert from "../../../../services/showAlert"

const handleDelete = async ({ eventData }) => {
  await dbRequest
    .deleteRecords("T_REC_CAL")
    .record({ ID: eventData.Id })
    .execute()

  showAlert({
    text: "Event deleted successfully",
    titleType: "success"
  })
}

export default handleDelete