import getDoubleClickEvent from '../../../../utils/getDoubleClickEvent'
import ChartArray from '../../../../views/screens/DashboardsBuilderScreen/utils/Chart'
import { ChartData, ChartDataArray } from '../../../../views/screens/DashboardsBuilderScreen/utils/ChartData'

export default function processSelectedLevel(state, { payload }) {
  ChartDataArray.clearArr()
  if (payload === "new") {
    state.modals.levelModal.mode = "new"
    state.modals.levelModal.form = {}
    state.dashboardData.selectedLevel = { gadgets: [] }
    createLayoutGrid({gadgets: []})
    state.modals.levelModal.isVisible = true
    return
  }

  const { selectedLevel } = payload
  state.dashboardData.selectedLevel = selectedLevel

  if (state.dashboardData.selectedLevel?.ID === selectedLevel.ID) {
    getDoubleClickEvent(doubleClickEvent => {
      if (doubleClickEvent.isActive) {
        doubleClickEvent.isActive = false
        state.modals.levelModal.mode = "edit"
        state.modals.levelModal.form = selectedLevel
        createLayoutGrid(selectedLevel)
        state.modals.levelModal.isVisible = true
      }
    })
  }
}


export function createLayoutGrid(selectedLevel) {
  const gadgets = selectedLevel?.gadgets ?? []

  for (let i = 1; i <= gadgets.length; i++) {
    ChartDataArray.getArr().push(new ChartData(i))
    ChartDataArray.getPosition(i - 1).setModel(gadgets[i - 1])
  }

  let matrix = []
  for (let i = 0; i < 144; i++) {
    let pushed = false

    for (let j = 0; j < ChartDataArray.getArr().length; j++) {

      let value = gadgets[j].MATRIX.charAt(i)
      if (value === "0") continue

      matrix.push([parseInt(value), ChartDataArray.getPosition(parseInt(value) - 1).getColor()])
      pushed = true
    }

    if (!pushed) matrix.push([0, "white"])
    pushed = false
  }

  ChartArray.populate()
  for (let i = 0; i < matrix.length; i++) {
    ChartArray.getPosition(i).setID(matrix[i][0])
    ChartArray.getPosition(i).setColor(matrix[i][1])
  }
  ChartDataArray.setSelectedChart(null)
}