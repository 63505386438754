import React from "react"
import { Chart } from "react-google-charts"
import { useDispatch } from "react-redux"
import { getModalData } from "../../../../../redux/redux-reducers/dashboard-reducer"
import ExpandableButton from "../../ExpandableButton"
import useChartConstruct from "../hooks/useChartConstruct"
import * as S from "../styles.web"

export default function TableChart({ chartData, controller, chartId, entityName, chartIdArr, dashboardData }) {

  const dispatch = useDispatch()

  const props = {
    chartData,
    controller,
    chartId,
    entityName,
    chartIdArr,
    dashboardData
  }

  const { data, attributes, chartEvents } = useChartConstruct(props)

  const options = {
    width: "100%",
    pageSize: 200
  }

  if (data.length === 0) {
    return (
      <S.TextView>
        <S.Icon />
        <S.Text>Nenhum dado encontrado.</S.Text>
      </S.TextView>
    )
  }

  return data.length > 1 && (
    <S.Container>

      <S.Title>{chartData.DESCRIPTION}</S.Title>

      <S.ButtonsView>
        <ExpandableButton onPress={() => dispatch(getModalData({
          isVisible: true,
          data,
          attributes,
          chartType: "Table"
        }))} />
      </S.ButtonsView>
      <Chart
        width="99%"
        height="99%"
        chartType="Table"
        data={data}
        options={options}
        chartEvents={chartEvents}
      />
    </S.Container>
  )
}
