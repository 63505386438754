import showAlert from "../../../../services/showAlert"

const handleUndo = ({ models, entity, setUpdateForm }) => {
  showAlert({
    type: "confirm",
    title: "All changes will be lost",
    text: "Do you want to undo changes?",
    titleType: "warning",
    handleConfirm: () => {
      const tableData = models._REGISTRATION_DATA[entity]
      models[entity].reset()
      models[entity].populate({ ...tableData.originalRecord })
      setUpdateForm(s => !s)
    }
  })
}

export default handleUndo
