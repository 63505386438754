import React, { useState, useEffect } from "react"
import SearchModal from "../../../../../../Form/components/Inputs/Search/components/SearchModal/index.web"
import * as S from "./styles"

export default function SearchInput({ entity, models, updateForm, setUpdateForm }) {
  const [value, setValue] = useState(models[entity].ID || "")
  const [modalVisivle, setModalVisible] = useState(false)

  const tableData = models._REGISTRATION_DATA[entity]

  const handlePress = () => {
    setModalVisible(true)
  }

  useEffect(() => {
    setValue(models[entity].ID || "")
  }, [updateForm])

  const isAnInsertion = tableData.operation === "INS"

  return (
    <S.InputContainer>
      <S.Input
        disabled={true}
        value={value}
        //onChange={(e) => setValue(e.target.value)}
        //onBlur={() => models[entity].ID = value}
        placeholder="ID"
      />

      <S.Button
        onClick={handlePress}
        style={{ visibility: isAnInsertion ? "visible" : "hidden" }}
      >
        <S.Icon />
      </S.Button>

      {modalVisivle && (
        <SearchModal
          field={tableData.fields.find(field => field.ID === "ID")}
          modalVisible={modalVisivle}
          setModalVisible={setModalVisible}
          setUpdateForm={setUpdateForm}
          models={models}
          populateFields={item => {
            models[entity].ID = item.ID
            models[entity].DESCRIPTION = item.DESCRIPTION ?? ""
            models[entity].IMG = item.IMG ?? []
          }}
        />
      )}
    </S.InputContainer>
  )
}
