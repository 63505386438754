import { L10n, registerLicense } from "@syncfusion/ej2-base"
import React from "react"
import { MenuProvider } from "react-native-popup-menu"
import { Provider } from "react-redux"
import { ThemeProvider } from "styled-components"
import "./src/utils/loadSyncfusionStyles"
import globalDate from "./src/global/globalDate"
import store from "./src/redux/store"
import Routes from "./src/routes"
import { GlobalStyle } from "./src/styles/global"
import { gridStyles } from "./src/styles/grid"
import { defaultTheme } from "./src/styles/themes/default"

import "./src/utils/i18n/i18n"
import syncfusionTranslations from "./src/utils/syncfusionTranslations.json"

L10n.load({ "es-US": { ...syncfusionTranslations["pt-BR"] } })
registerLicense("Ngo9BigBOggjHTQxAR8/V1NCaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXlcdnVQQmZYU0F/W0Q=")

export default function App() {
  global.globalDate = globalDate

  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyle />
      <style>{gridStyles}</style>
      <Provider store={store}>
        <MenuProvider>
          <div id="web-modal"></div>
          <Routes />
        </MenuProvider>
      </Provider>
    </ThemeProvider>
  )
}
