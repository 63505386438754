import getFields from "./getFields"
import getRecords from "./getRecords"

export default async ({ entityName, models }) => {

  const records = await getRecords({
    entityName,
    models
  })

  const fields = getFields({
    entityName,
    models
  })

  const header = fields.map(({ DESCRIPTION }) => DESCRIPTION)

  return {
    records,
    fields,
    header
  }
}
