import getDoubleClickEvent from "../../../../../../utils/getDoubleClickEvent"

export default ({ props, callback }) => {
  const {
    viewType = "normal",
    controller,
    entityName,
    rowData,
    setSelectedItem = () => null,
    selectedCallback = () => null,
    detailTabs = [],
    masterTable
  } = props

  const models = controller.models()
  const tableData = models._REGISTRATION_DATA[entityName]

  if (viewType !== "readOnly") {
    models[entityName].reset()
    models[entityName].populate(rowData)

    tableData.originalRecord = { ...rowData }

    if (viewType !== "orderPanel")
      for (const table of detailTabs) {
        const tableID = table.ID
        const recordID = rowData.ID

        if (viewType === "registration")
          tableData.detailTabsPath[tableID] = `${entityName}/${recordID}/${tableID}`
        else
          tableData.detailTabsPath[tableID] = `${models._REGISTRATION_DATA[masterTable].detailTabsPath[entityName]}/${recordID}/${tableID}`
      }
  }

  setSelectedItem(rowData)
  selectedCallback(rowData)

  getDoubleClickEvent(doubleClickEvent => {
    if (doubleClickEvent.isActive && viewType !== "readOnly") {
      doubleClickEvent.isActive = false
      tableData.operation = "UPD"
      callback()
    }
  })
}
