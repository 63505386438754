import { createAsyncThunk } from '@reduxjs/toolkit'
import showAlert from '../../../../services/showAlert'
import dbRequest from '../../../../services/dbRequest/dbRequest'
import RuntimeConfiguration from '../../../../models/runtimeConfiguration'

export const deleteLevel = createAsyncThunk(
  "dashboardBuilder/deleteLevel",
  async ({selectedLevel, selectedDashboard}, thunkAPI) => {

    const isAdminMode = RuntimeConfiguration.getDashboardBuilderAdminMode()

    if (!selectedLevel?.ID) {
      return thunkAPI.rejectWithValue(showAlert({
        title: "No level were selected.",
        titleType: "warning",
        text: "Please select the level to be deleted."
      }))
    }

    try {
      const response = await new Promise((resolve, reject) => {
        showAlert({
          title: "This action cannot be undone.",
          titleType: "warning",
          text: "Do you want to delete the level?",
          type: "confirm",
          handleConfirm: async () => {
            try {
              const request = dbRequest.deleteRecords(`T_RDB_DSB/${selectedDashboard.ID}/T_RDB_LVL`).record(selectedLevel)
              
              if(isAdminMode) request = request.admin()
              
              await request.execute(() => {
                showAlert({
                  title: "Success",
                  text: "Level deleted successfully.",
                  titleType: "success",
                })
                resolve({selectedLevel: selectedLevel})
              })
            } catch (error) {
              reject(error)
            }
          }
        })
      })

      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(showAlert({
        title: "Error when trying to delete level.",
        titleType: "error",
        text: "Please try again.",
      }))
    }
  }
)