import styled from "styled-components"
//@ts-ignore
import background from "../../images/background-login.jpg"
//@ts-ignore
import loginCarousel from "../../images/login-carousel.png"

export const LoginScreenContainer = styled.div`
  background-image: url(${background});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: 100vh;
  justify-content: space-between;
  width: 100%;
`

export const FormContainer = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme["white"]};
  border-radius: 35px;
  box-shadow: 5px 5px 20px #00000029;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  height: 60%;
  justify-content: center;
  margin: auto;
  width: 35%;
`

export const Title = styled.p`
  color: ${({ theme }) => theme["gray-700"]};
  font-size: 1.5rem;
  font-weight: bold;
`

export const Text = styled.p`
  color: ${({ theme }) => theme["gray-600"]};
  font-size: 1rem;
  font-weight: lighter;
`

export const RecoverPasswordText = styled.p`
  color: ${({ theme }) => theme["gray-600"]};
  font-size: 0.75rem;

  button {
    background-color: transparent;
    color: ${({ theme }) => theme["cyan-300"]};
    font-size: inherit;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }
`

export const InfoText = styled.a`
  color: ${({ theme }) => theme["gray-300"]};
  cursor: pointer;
  font-size: 0.75rem;
  font-weight: lighter;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

export const LogoContainer = styled.div`
  bottom: 10px;
  height: 50px;
  position: absolute;
  width: 130px;
`

export const LogoImage = styled.img`
  height: 100%;
  object-fit: contain;
  width: 100%;
`

export const ImageCarousel = styled.div`
  background-image: url(${loginCarousel});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: inherit;
  width: 30%;
`
export const AppVersion = styled.div`
  color: ${({ theme }) => theme["gray-300"]};
  font-size: 0.70rem;
`
