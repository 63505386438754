import OrderComposerController from "../../../../../controllers/OrderComposerController"
import Navigation from "../../../../../models/navigation"

export default async ({ controller, setIsLoading }) => {
  // setPercentageLoading({
  //   isVisible: true,
  //   percentage: 0,
  //   stepTitle: "Initializing Order Composer..."
  // })

  setIsLoading(true)

  controller = new OrderComposerController()
  await controller.initialize(setIsLoading)

  controller.tabName = "Document"

  // setPercentageLoading(prev => ({
  //   ...prev,
  //   percentage: 100,
  //   stepTitle: "Done!"
  // }))

  Navigation.createTab({
    component: "OrderComposer",
    params: {
      attribute: null,
      controller: controller
    },
    tabName: "Document"
  })

  setIsLoading(false)

  //setTimeout(() => {
  // setPercentageLoading({
  //   isVisible: false,
  //   percentage: 0,
  //   stepTitle: ""
  // })
  //}, 1000)
}
