import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import UploadModal from "./Modal/"
import * as S from "./styles"

export default function Attachment({ models, field }) {
  const { t } = useTranslation()

  const { ID, TABLENAME, T_DTD_ATR } = field
  const { EDITABLE, FIELDDESCRIPTION } = T_DTD_ATR

  const tableData = models._REGISTRATION_DATA[TABLENAME]

  const [modalVisible, setModalVisible] = useState(false)

  const openModal = () => {
    setModalVisible(true)
    if (hasFiles(models[TABLENAME][ID]))
      tableData.files[ID] = [...models[TABLENAME][ID]]
  }

  const hasFiles = (model = []) => model.length > 0

  if (models[TABLENAME][ID]?.length === 0) tableData.files[ID] = [...models[TABLENAME][ID]]

  return (
    <S.Container
      isEditable={EDITABLE}
      disabled={!EDITABLE}
      onClick={openModal}
      hasValue={hasFiles(tableData.files[ID]) || hasFiles(models[TABLENAME][ID])}
    >
      <S.Icon />

      <span>{t(FIELDDESCRIPTION)}</span>

      {modalVisible &&
        <UploadModal
          field={field}
          models={models}
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
        />
      }
    </S.Container>
  )
}
