/* eslint-disable import/prefer-default-export */
import useAppSelector from "../../../../redux/hooks/useAppSelector"

export const useAdditionalFilters = (controller) => {
  const additionalFilters = useAppSelector(({ orderReducer }) => orderReducer.additionalFilters)

  const applyFilters = (data) => {
    if (controller.collectionName !== "T_REC_ITE") return data

    return additionalFilters.reduce((filteredArray, filter) => {
      return filteredArray.filter(record => {
        if (filter.LABEL === "General") {
          switch (filter.VALUE[0]) {
            case "Promotion": return record.PROMOTION
            case "Replenishment": return record.REPLENISHMENT
            case "Stock": return record.STOCK > 0
            default: return true
          }
        }
        if (filter.LABEL === "GROUPITEMID") return filter.VALUE.includes(record[filter.LABEL].ID)
        return filter.VALUE.includes(record[filter.LABEL])
      })
    }, data)
  }

  return { applyFilters }
}
