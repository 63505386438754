import { combineReducers } from "@reduxjs/toolkit"
import { dashboardReducer } from "./dashboard-reducer"
import { dashboardBuilderReducer } from "./dashboardBuilder-reducer"
import { orderReducer } from "./order-reducer"
import { scheduleReducer } from "./schedule-reducer"
import { screenFactoryReducer } from "./screenFactory-reducer"

export default combineReducers({
  dashboardReducer,
  scheduleReducer,
  orderReducer,
  screenFactoryReducer,
  dashboardBuilderReducer
})
