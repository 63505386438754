import React from "react"
import { useTranslation } from "react-i18next"
import getTableFields from "../../../../../../../services/registration/getTableFields"
import Form from "../../../../../Form"
import executeExpression from "../../utils/executeExpression"
import * as S from "./styles"

export default function FormModal({ models, entityName, selectedOption, closeModal }) {
  const { t } = useTranslation()

  const handlePress = async () => await executeExpression({
    option: selectedOption,
    models,
    callback: closeModal
  })

  return (
    <S.Container>
      <S.Row>
        <S.Title>{t("Function parameters")}</S.Title>

        <S.ButtonsView>
          <S.SaveButton onPress={handlePress}>
            <S.SaveText>{t("Execute")}</S.SaveText>
          </S.SaveButton>

          <S.CloseButton onPress={() => {
            models.PARAMS.reset()
            closeModal()
          }}>
            <S.Icon name="close" />
          </S.CloseButton>
        </S.ButtonsView>
      </S.Row>

      <S.FormView>
        <Form
          models={models}
          entityName={"PARAMS"}
          staticFields={getTableFields({
            entityName,
            collectionPath: `${entityName}/T_DTD_FNC/${selectedOption.ID}`
          })
            .then(fields => {
              models._REGISTRATION_DATA.PARAMS.fields = fields
              return fields
            })
          }
        />
      </S.FormView>
    </S.Container>
  )
}
