import React, { createContext, useState } from "react"

export const ModalsContext = createContext()

const ModalsProvider = ({ children }) => {

  const [modalsVisible, setModalsVisible] = useState({
    attributes: false,
    field: false,
    function: false,
    manageMenu: false,
    options: false,
    orderCardFields: false,
    orderFields: false,
    orderTabs: false,
    table: false,
    transaction: false,
    translation: false,
    trigger: false
  })

  const openModal = type => {
    setModalsVisible(s => ({
      ...s,
      [type]: true
    }))
  }

  const closeModal = type => {
    setModalsVisible(s => ({
      ...s,
      [type]: false
    }))
  }

  return (
    <ModalsContext.Provider
      value={{
        modalsVisible,
        openModal,
        closeModal
      }}
    >
      {children}
    </ModalsContext.Provider>
  )
}

export default ModalsProvider
