/* eslint-disable object-property-newline */
import deleteFiles from "./delete"
import loadFiles from "./load"
import saveFiles from "./save"

interface StorageRequestProps {
  requestType: "load" | "save" | "delete",
  filePath: string
}

export default class storageRequest {
  private requestType: "load" | "save" | "delete"
  private filePath: string
  private _file: Blob | null = null

  constructor({ requestType, filePath }: StorageRequestProps) {
    this.requestType = requestType
    this.filePath = filePath
  }

  file = (i: Blob) => {
    this._file = i
    return this
  }

  static loadFiles = (filePath: string) => new storageRequest({ requestType: "load", filePath })
  static saveFiles = (filePath: string) => new storageRequest({ requestType: "save", filePath })
  static deleteFiles = (filePath: string) => new storageRequest({ requestType: "delete", filePath })

  async execute(callback = (r: unknown) => r) {
    switch (this.requestType) {
      case "load": return callback(await loadFiles({ path: this.filePath }))
      case "save": return callback(await saveFiles({ path: this.filePath, file: this._file }))
      case "delete": return callback(await deleteFiles({ path: this.filePath }))
      default: callback(new Error("ServiceNotFoundException: the service request from dbStorage do not exists"))
    }
  }
}
