import React, { useState } from 'react'
import ModalWrapper from '../../../../../components/Modal'
import { useDispatch } from 'react-redux'
import { MaterialIcons } from '@expo/vector-icons'
import { Entypo } from "@expo/vector-icons"
import useAppSelector from '../../../../../../redux/hooks/useAppSelector'
import * as S from "../modalStyles"
import { changeFormValue, closeModal, setSelectedGadget } from '../../../../../../redux/redux-reducers/dashboardBuilder-reducer'
import GenerateGlyphs from '../../DashboardsBuilder/components/GenerateGlyphs'
import ChartArray from '../../../utils/Chart'
import { ChartData, ChartDataArray } from '../../../utils/ChartData'
import { View, Text } from 'react-native'
import globalStyles from '../../../../../../global/globalStyles'
import { saveDashboardLayout } from '../../../../../../redux/redux-reducers/dashboardBuilder-reducer/utils/saveDashboardLayout'
import { saveLevel } from '../../../../../../redux/redux-reducers/dashboardBuilder-reducer/utils/saveLevel'
import { deleteGadget } from '../../../../../../redux/redux-reducers/dashboardBuilder-reducer/utils/deleteGadget'
import { createLayoutGrid } from '../../../../../../redux/redux-reducers/dashboardBuilder-reducer/utils/processSelectedLevel'

export default function LevelModal() {

  const [update, setUpdate] = useState(false)
  const [focusedIndex, setFocusedIndex] = useState(false)

  const dispatch = useDispatch()

  const { name, isVisible, mode, form } = useAppSelector(({ dashboardBuilderReducer }) => dashboardBuilderReducer.modals.levelModal)
  const { selectedGadget, selectedLevel, selectedDashboard, levels } = useAppSelector(({ dashboardBuilderReducer }) => dashboardBuilderReducer.dashboardData)

  const refreshLayout = () => {
    ChartArray.clearPositions()
    ChartDataArray.clearArr()
    createLayoutGrid(selectedLevel)
    setUpdate(s => !s)
  }

  return (
    <ModalWrapper isVisible={isVisible}>
      <S.Container style={{ height: "90%" }}>

        <S.CloseButton onPress={() => dispatch(closeModal({ modalName: name }))}>
          <MaterialIcons name="close" size={20} color="#333333" />
        </S.CloseButton>

        <S.Title>{mode === "new" ? "Add New" : "Edit"} Level</S.Title>

        <S.SelectedView>
          {mode === "edit" && <View style={{ flexDirection: "row", alignItems: "center", gap: 3 }}>
            <S.SelectedText>Level:</S.SelectedText>
            <S.SelectedField>
              <S.TextInput
                style={{ width: 50 }}
                value={form?.ID}
                editable={false}
              />
            </S.SelectedField>
          </View>}
          <View style={{ flexDirection: "row", alignItems: "center", gap: 3 }}>
            <S.SelectedText>Description:</S.SelectedText>
            <S.SelectedField>
              <S.TextInput
                value={form?.DESCRIPTION}
                placeholder='Level Description'
                placeholderTextColor={"gray"}
                onChangeText={(tx) => dispatch(changeFormValue({ modalName: name, fieldName: "DESCRIPTION", value: tx }))}
              />
            </S.SelectedField>
          </View>
        </S.SelectedView>

        <S.Header>
          <S.SubTitle>Gadgets ({selectedLevel?.gadgets?.length ?? 0})</S.SubTitle>
          <S.ButtonsView>

            <S.AddButton onPress={() => dispatch(saveLevel({ form, selectedDashboard, levels }))}>
              <S.TitleButton>Save Level {mode === "edit" && "Changes"}</S.TitleButton>
            </S.AddButton>

            {
              selectedLevel?.ID &&
              <>
                <S.AddButton onPress={() => dispatch(setSelectedGadget("new"))}>
                  <S.TitleButton>New Gadget</S.TitleButton>
                </S.AddButton>
                <S.DeleteButton onPress={() => dispatch(deleteGadget({ selectedGadget, selectedLevel, path: `T_RDB_DSB/${selectedDashboard.ID}/T_RDB_LVL/${selectedLevel.ID}/T_RDB_GDG/`, updateLayout: setUpdate }))}>
                  <S.DeleteIcon name="trash-alt" />
                </S.DeleteButton>
              </>
            }
          </S.ButtonsView>
        </S.Header>

        <S.UnderLine />

        <S.ListArea contentContainerStyle={{ gap: 5 }}>
          {
            selectedLevel?.gadgets?.map((item, index) => (
              <S.Card
                key={index}
                isSelected={index === focusedIndex}
                onPress={() => {
                  setFocusedIndex(index)
                  ChartDataArray.setSelectedChart(index + 1)
                  dispatch(setSelectedGadget({ selectedGadget: item }))
                }}
                backgroundColor={ChartDataArray.getArr()[index]?.color}
              >
                <S.CardFieldArea style={{ width: "6%" }}>
                  <S.CartText style={{ fontWeight: "600" }}>ID: <S.CartText>{item.ID}</S.CartText></S.CartText>
                </S.CardFieldArea>
                <S.CardFieldArea style={{ width: "38%" }}>
                  <S.CartText style={{ fontWeight: "600" }}>Description: <S.CartText>{item.DESCRIPTION}</S.CartText></S.CartText>
                </S.CardFieldArea>
                <S.CardFieldArea style={{ width: "25%" }}>
                  <S.CartText style={{ fontWeight: "600" }}>Chart Type: <S.CartText>{item.GADGET.LABEL}</S.CartText></S.CartText>
                </S.CardFieldArea>
                <S.CardFieldArea style={{ width: "28%" }}>
                  <S.CartText style={{ fontWeight: "600" }}>Linked Query: <S.CartText>{item.DATAID}</S.CartText></S.CartText>
                </S.CardFieldArea>
              </S.Card>
            ))
          }
        </S.ListArea>


        <S.Header style={{ marginTop: 20 }}>
          <View style={{ flexDirection: "row", alignItems: "center", gap: 5 }}>
            <S.SubTitle>Gadgets Layout Edit</S.SubTitle>
            <div title='Select a gadget above and set its position by clicking and dragging in the grid below.'>
              <Entypo name="info-with-circle" size={16} color={globalStyles.colors.primary} />
            </div>
          </View>
          <S.ButtonsView>
            <S.AddButton disabled={mode === "new" || !selectedLevel?.gadgets?.length} onPress={() => dispatch(saveDashboardLayout({ selectedDashboard, selectedLevel }))} style={mode === "new" || !selectedLevel?.gadgets?.length && { backgroundColor: "darkgray" }}>
              <S.TitleButton>Save</S.TitleButton>
            </S.AddButton>

            <S.AddButton onPress={refreshLayout} style={{backgroundColor: globalStyles.colors.editButton}}>
              <S.TitleButton>Refresh</S.TitleButton>
            </S.AddButton>

            <S.DeleteButton onPress={() => ChartArray.clearPositions()} disabled={mode === "new" && !selectedLevel?.gadgets?.length}>
              <S.TitleButton>Clear</S.TitleButton>
            </S.DeleteButton>

          </S.ButtonsView>
        </S.Header>
        <S.UnderLine />
        <View style={{ flex: 1, gap: 10, padding: 10, borderRadius: 10, backgroundColor: globalStyles.colors.background }}>
          <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            {(mode === "edit" && selectedLevel?.gadgets?.length) ?
              <GenerateGlyphs ChartArray={ChartArray} ChartDataArray={ChartDataArray} update={update} setUpdate={setUpdate} />
              :
              <Text style={{ fontSize: 14, fontWeight: "500", color: "darkgray" }}>To edit the layout you need to save the new level and add at least one gadget.</Text>
            }
          </View>
        </View>
      </S.Container>
    </ModalWrapper>
  )
}