import LoginData from "../../../../../../models/loginData"
import dbRequest from "../../../../../../services/dbRequest/dbRequest"
import showAlert from "../../../../../../services/showAlert"
import storageRequest from "../../../../../../services/storageRequest"
import { homeScreenObserver } from "../../../../../screens/HomeScreen/index.web"

const onSaveUserData = ({
  t,
  selectedFiles,
  setModalVisible,
  setSelectedFiles,
  setPreviewImage
}) => {
  const userData = LoginData.getLoginData()

  showAlert({
    titleType: "question",
    type: "confirm",
    title: "Save changes?",
    handleConfirm: async () => {
      try {
        if (selectedFiles.userPhoto) {
          const savedFile = await storageRequest.saveFiles(`T_SET_RLU/${userData.ID}/IMG/${selectedFiles.userPhoto.name}`).file(selectedFiles.userPhoto)
            .execute(res => {
              return {
                ID: "IMG",
                NAME: selectedFiles.userPhoto.name || selectedFiles.userPhoto.NAME,
                SIZE: selectedFiles.userPhoto.size || selectedFiles.userPhoto.SIZE,
                TYPE: selectedFiles.userPhoto.type || selectedFiles.userPhoto.TYPE,
                URL: res.url
              }
            })
          userData.IMG = [savedFile]
        }

        if (selectedFiles.overviewBackground) {
          const savedFile = await storageRequest.saveFiles(`T_SET_RLU/${userData.ID}/BACKGROUND/${selectedFiles.overviewBackground.name}`).file(selectedFiles.overviewBackground)
            .execute(res => {
              return {
                ID: "BACKGROUND",
                NAME: selectedFiles.overviewBackground.name || selectedFiles.overviewBackground.NAME,
                SIZE: selectedFiles.overviewBackground.size || selectedFiles.overviewBackground.SIZE,
                TYPE: selectedFiles.overviewBackground.type || selectedFiles.overviewBackground.TYPE,
                URL: res.url
              }
            })
          userData.BACKGROUND = savedFile
        }

        LoginData.setLoginData({ ...userData })
        setModalVisible(null)
        setSelectedFiles(null)
        setPreviewImage(null)
        homeScreenObserver.notify()

        await dbRequest.updateRecords("T_SET_RLU").record(userData)
          .execute(() => showAlert({
            text: "",
            title: "Changes saved successfully",
            titleType: "success"
          }))
      } catch (error) {
        console.error(error)
        showAlert({
          text: error.message,
          title: "Failed to save changes",
          titleType: "error"
        })
      }
    }
  })

}

export default onSaveUserData
