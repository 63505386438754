import { httpsCallable } from "firebase/functions"
import { localFunctions } from "../../dbConnection/"

export default async function receitaWS(cnpj) {
  const bigqueryFunction = httpsCallable(localFunctions, "receitaWS")

  const result = await bigqueryFunction({
    cnpj: cnpj
  })

  return result.data
}
