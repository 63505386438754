import showAlert from "../../../../../../../services/showAlert"

const validateFilter = (filter) => {
  const { OPERATOR, FIELD, VALUE, STARTING_AT, ENDING_AT } = filter

  if (!FIELD) {
    showAlert({
      title: "Unable to save filter",
      titleType: "warning",
      text: "Field Name field is required."
    })
    return false
  }

  if (!OPERATOR) {
    showAlert({
      title: "Unable to save filter",
      titleType: "warning",
      text: "Operator type field is required."
    })
    return false
  }

  if (OPERATOR?.VALUE === "period") {
    if (!STARTING_AT || !ENDING_AT) {
      showAlert({
        title: "Unable to save filter",
        titleType: "warning",
        text: "Starting At and Ending At fields are required."
      })
      return false
    }
  } else if (!VALUE) {
    showAlert({
      title: "Unable to save filter",
      titleType: "warning",
      text: "Value field is required."
    })
    return false
  }

  return true
}

export default validateFilter
