import { createAsyncThunk } from "@reduxjs/toolkit"
import RuntimeConfiguration from "../../../../models/runtimeConfiguration"
import dbRequest from "../../../../services/dbRequest/dbRequest"
import showAlert from "../../../../services/showAlert"

const messages = {
  ID: "The ID field is required.",
  DESCRIPTION: "The Description field is required.",
  COLOR: "The Color field is required.",
  SERIETYPE: "The Serie Type field is required.",
  GROUPING: "The Group Label field is required.",
  VALUE: "The Group Value field is required."
}

const saveSerie = createAsyncThunk(
  "dashboardBuilder/saveSerie",
  async ({ form, isComboChart, path }, thunkAPI) => {
    const isAdminMode = RuntimeConfiguration.getDashboardBuilderAdminMode()

    const serie = { ...form }

    const checkID = validateSerieProperty(serie?.ID, thunkAPI, messages.ID)
    if (checkID) return checkID

    const checkDescription = validateSerieProperty(serie?.DESCRIPTION, thunkAPI, messages.DESCRIPTION)
    if (checkDescription) return checkDescription

    const checkColor = validateSerieProperty(serie?.COLOR, thunkAPI, messages.COLOR)
    if (checkColor) return checkColor

    const checkSerieType = isComboChart ? validateSerieProperty(serie?.SERIETYPE, thunkAPI, messages.SERIETYPE) : null
    if (checkSerieType) return checkSerieType

    const checkGrouping = validateSerieProperty(serie?.GROUPING, thunkAPI, messages.GROUPING)
    if (checkGrouping) return checkGrouping

    const checkValue = validateSerieProperty(serie?.VALUE, thunkAPI, messages.VALUE)
    if (checkValue) return checkValue

    if (!serie?.SERIETYPE?.VALUE) delete serie?.SERIETYPE

    let saveRequest = dbRequest
      .saveRecords(path)
      .record({ ...serie })

    if (isAdminMode) saveRequest = saveRequest.admin()

    await saveRequest.execute()

    return { serie }
  }
)

export default saveSerie

function validateSerieProperty(property, thunkAPI, message) {
  if (!property) {
    return thunkAPI.rejectWithValue(showAlert({
      title: "Unable to save gadget",
      titleType: "warning",
      text: message
    }))
  }
}
