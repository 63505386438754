import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { View } from "react-native"
import globalStyles from "../../global/globalStyles"
import LoadingAnimation from "../../views/components/LoadingAnimation"
import ModalChart from "./components/Charts/ModalChart/index.web"
import FilterModal from "./components/FilterModal"
import ReturnButton from "./components/ReturnButton"
import DashboardGenerator from "./Generator/DashboardGenerator"
import useDashboard from "./hooks/useDashboard"
import * as S from "./styles.web"

export default function Dashboard({
  idDashboard,
  idfilial,
  controller,
  entityName,
  positionStyle = "absolute",
  sideBarVisible = true
}) {
  const [isVisible, setIsVisible] = useState(false)

  const { t } = useTranslation()

  const { dashboard, dashboardData, fields, isLoading, isValid } = useDashboard(
    idDashboard,
    controller.models()
  )

  const commonProps = {
    idDashboard,
    idfilial,
    entityName,
    controller,
    positionStyle,
    sideBarVisible,
    isVisible,
    setIsVisible,
    isValid
  }

  return (
    <>
      <S.Tab>
        <FilterModal fields={fields} {...commonProps} />
      </S.Tab>
      <S.Container>
        {isLoading ? (
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <LoadingAnimation
              size="medium"
              color={globalStyles.colors.secondary}
            />
          </View>
        ) : isValid ? (
          <DashboardGenerator
            {...commonProps}
            dashboard={dashboard}
            dashboardData={dashboardData}
          />
        ) : (
          <S.TextView>
            <div
              style={{
                display: "flex",
                alignItems: "center"
              }}
            >
              <S.Icon size={16} color={globalStyles.colors.primary} />
              <S.Text>{t("Please fill in the required fields.")}</S.Text>
            </div>
            <S.ContentButton onClick={() => setIsVisible((s) => !s)}>
              {t("Fill Out")}
            </S.ContentButton>
          </S.TextView>
        )}

        <ReturnButton />
        <ModalChart />
      </S.Container>
    </>
  )
}
