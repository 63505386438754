import React, { useCallback, useEffect, useState } from "react"
import { View } from "react-native"
import getDashboardFields from "../../../../../../../../redux/redux-reducers/dashboardBuilder-reducer/utils/getDashboardFields"
import getTableFields from "../../../../../../../../services/registration/getTableFields"
import getVariablesList from "../../../VariablesModal/utils/getVariablesList"
import Card from "./components/Card"
import * as S from "./styles"

export default function CollectionFieldsList({
  selectedDashboard,
  mainCollection,
  currentCollection,
  originArray,
  setOriginArray,
  destinyArray,
  setDestinyArray,
  alternativeDestinyArray,
  setAlternativeDestinyArray
}) {

  const [isFetchingFields, setIsFetchingFields] = useState(false)

  const isCustomQuery = currentCollection?.ID === "Custom"
  const isVariableQuery = currentCollection?.ID === "VARIABLES"

  const fetchFields = useCallback(async () => {
    setIsFetchingFields(true)
    if (currentCollection) {
      try {
        const response = await (isCustomQuery
          ? getDashboardFields(`T_RDB_DSB/${selectedDashboard.ID}/DATA/${currentCollection.queryID}`)
          : isVariableQuery ? getVariablesList({ variablesPath: `T_RDB_DSB/${selectedDashboard.ID}/DATA/${mainCollection.ID}/VARIABLES` })
            : getTableFields({ entityName: currentCollection.ID }))

        if (isVariableQuery) {
          const newItems = response.filter((variable) => {
            return !destinyArray.some(field => field.ID === variable.ID) &&
              !alternativeDestinyArray.some(field => field.ID === variable.ID)
          }).map(variable => ({
            ID: variable.ID,
            FIELDDESCRIPTION: variable.ID,
            FIELDTYPE: "S",
            ENTITY: ""
          }))
            .sort((a, b) => a.FIELDDESCRIPTION.localeCompare(b.FIELDDESCRIPTION))

          setOriginArray(newItems)
        } else {
          const newItems = response.filter(({ ID, FIELDTYPE }) => {
            if (["K", "N"].includes(FIELDTYPE.VALUE)) return true
            else return !destinyArray.some(field => field.ID === ID) &&
              !alternativeDestinyArray.some(field => field.ID === ID)
          }
          ).map(({ ID, FIELDTYPE, T_DTD_ATR }) => ({
            ID,
            FIELDDESCRIPTION: T_DTD_ATR.FIELDDESCRIPTION,
            FIELDTYPE: FIELDTYPE.VALUE,
            ENTITY: ["K", "N", "E"].includes(FIELDTYPE.VALUE) ? T_DTD_ATR.ENTITY?.ID : ""
          }))
            .sort((a, b) => a.FIELDDESCRIPTION.localeCompare(b.FIELDDESCRIPTION))

          setOriginArray(newItems)
        }

      } catch (error) {
        console.error("Error fetching fields:", error)
      }
    }
    setIsFetchingFields(false)
  }, [currentCollection, destinyArray, alternativeDestinyArray, selectedDashboard, isCustomQuery])

  useEffect(() => {
    fetchFields()
  }, [fetchFields])

  const renderCard = useCallback(({ ID, FIELDDESCRIPTION, FIELDTYPE, ENTITY }) => (
    <Card
      key={ID}
      fieldData={{
        ID,
        FIELDDESCRIPTION,
        FIELDTYPE,
        ENTITY
      }}
      currentCollection={currentCollection}
      setOriginArray={setOriginArray}
      setDestinyArray={setDestinyArray}
      setAlternativeDestinyArray={setAlternativeDestinyArray}
    />
  ), [setOriginArray, setDestinyArray, setAlternativeDestinyArray, currentCollection])

  return (
    <S.Container>
      <S.Title>Collection Fields</S.Title>

      {isFetchingFields ? (
        <S.LoadingContainer>
          <S.Loading />
        </S.LoadingContainer>
      ) : (
        <S.List contentContainerStyle={{
          gap: 8,
          paddingBottom: 10,
          paddingHorizontal: 5,
          paddingTop: 5
        }}>
          {currentCollection ? originArray.map(renderCard) : (
            <View style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center"
            }}>
              <S.TextCollectionNotSelected>Please select a collection to display the fields.</S.TextCollectionNotSelected>
            </View>
          )}
        </S.List>
      )}
    </S.Container>
  )
}
