class Chart {

  constructor(coordinate, id = 0) {
    this.coordinate = coordinate
    this.color = "white"
    this.id = id
  }

  getID() { return this.id }
  setID(id) { this.id = id }

  getColor() { return this.color }
  setColor(color) { this.color = color }

  getCoordinate() { return this.coordinate }

  setForceUpdate() { return null }
}

export default class ChartArray {

  static arr = []
  static isPopulated = false
  static buttonDown = false
  static selectedCoordinate = null

  static populate() {
    if (this.isPopulated) return
    this.isPopulated = true
    for (let i = 0; i < 144; i++) this.arr.push(new Chart([Math.floor(i / 12), i % 12]))
  }

  static delete(id, ChartDataArray) {
    for (let i = 0; i < 144; i++) {
      if (this.arr[i].getID() !== id) continue
      this.arr[i].setID(0)
      this.arr[i].setColor("white")
      this.arr[i].setForceUpdate(s => !s)
    }
    ChartDataArray.getArr().splice(id - 1, 1)
    if (ChartDataArray.getSelectedChart() === id) ChartDataArray.setSelectedChart(null)
  }

  static clearPositions() {
    if (this.arr.length > 0)
      for (let i = 0; i < 144; i++) {
        this.arr[i].setID(0)
        this.arr[i].setColor("white")
        this.arr[i].setForceUpdate(s => !s)
      }
  }

  static getArr() { return this.arr }
  static getPosition(position) { return this.arr[position] }
}
