import dbRequest from "../../../services/dbRequest/dbRequest"
import evaluateFunction from "../../../services/evaluateFunction"
import showDashboards from "./showDashboards"

/**
 * Realiza uma solicitação ao banco de dados para recuperar os campos, níveis e dados de um dashboard específico.
 * @param {string} idDashboard - O ID do dashboard a ser recuperado.
 * @returns {Promise<Object>} - Um objeto contendo os campos do dashboard, seus níveis e dados.
 * @throws {Error} - Lança um erro se ocorrer um problema durante a solicitação.
 */
const dashboardRequest = async (idDashboard, models) => {

  try {
    const dashboardFields = await getDashboardFields(idDashboard)

    const canShowDashboard = showDashboards(dashboardFields, models)

    if (!canShowDashboard) return {
      dashboardFields,
      dashboardLevels: [],
      dashboardData: [],
      canShowDashboard
    }

    const dashboardLevelsPromise = getDashboardLevels(idDashboard)
    const dashboardDataPromise = getDashboardData(idDashboard, models)

    const [dashboardLevels, dashboardData] = await Promise.all([
      dashboardLevelsPromise,
      dashboardDataPromise
    ])

    return {
      dashboardFields,
      dashboardLevels,
      dashboardData,
      canShowDashboard
    }
  } catch (error) {
    console.error("Error executing dashboardRequest function:", error)
    throw error
  }
}

// Função para obter os campos do dashboard especificado
const getDashboardFields = async (idDashboard) => {
  try {
    const path = `T_RDB_DSB/${idDashboard}/T_DTD_FLD`

    let fieldsRequest = dbRequest.loadRecords(path)
    const fields = await fieldsRequest.execute()

    for (const field of fields) {
      let attributesRequest = dbRequest.loadRecords(`${path}/${field.ID}/T_DTD_ATR`)

      const attributes = await attributesRequest.execute()

      field.T_DTD_ATR = {}
      field.TABLENAME = "DASHBOARD"

      for (const attribute of attributes) {
        const { ID, VALUE } = attribute
        field.T_DTD_ATR[ID] = VALUE
      }

      if (field.FIELDTYPE.VALUE === "L" && !field.T_DTD_ATR.CUSTOMLIST) {
        field.T_DTD_OPT = {}

        let optionsRequest = dbRequest.loadRecords(`${path}/${field.ID}/T_DTD_OPT`)

        const options = await optionsRequest.execute()

        for (const option of options) {
          const { ID, VALUE } = option
          field.T_DTD_OPT[ID] = VALUE
        }
      }
    }

    return fields
  } catch (error) {
    console.error("Error getting dashboard fields:", error)
    throw error
  }
}

// Função para obter os níveis do dashboard especificado
export const getDashboardLevels = async (idDashboard) => {
  try {
    const dashboardLevels = await dbRequest
      .loadRecords(`T_RDB_DSB/${idDashboard}/T_RDB_LVL`)
      .execute(async (r) => {
        const levels = {}
        await Promise.all(r.map(async ({ ID }) => {
          levels[ID] = {}
          const gadgetData = await getGadgetsData(idDashboard, ID)
          await Promise.all(gadgetData.map(async (gadget) => {
            const seriesData = await getGadgetSeriesData(idDashboard, ID, gadget.ID)
            gadget.series = seriesData
            levels[ID][gadget.ID] = gadget
          }))
        }))
        return levels
      })
    return dashboardLevels
  } catch (error) {
    console.error("Error getting dashboard levels:", error)
    throw error
  }
}

// Função para obter os dados dos gadgets para um determinado dashboard e nível
const getGadgetsData = async (idDashboard, levelID) => {
  try {
    const gadgetData = await dbRequest
      .loadRecords(`T_RDB_DSB/${idDashboard}/T_RDB_LVL/${levelID}/T_RDB_GDG`)
      .execute()
    return gadgetData
  } catch (error) {
    console.error("Error getting gadgets data:", error)
    throw error
  }
}

// Função para obter os dados da série para um gadget específico em um determinado dashboard e nível
const getGadgetSeriesData = async (idDashboard, levelID, gadgetID) => {
  try {
    const seriesData = await dbRequest
      .loadRecords(`T_RDB_DSB/${idDashboard}/T_RDB_LVL/${levelID}/T_RDB_GDG/${gadgetID}/T_RDB_SRS`)
      .execute()

    const seriesObject = {}
    seriesData.map((item, index) => {
      seriesObject[index + 1] = item
    })
    return seriesObject
  } catch (error) {
    console.error("Error getting gadget series data:", error)
    throw error
  }
}

// Função para obter os dados do dashboard especificado
const getDashboardData = async (idDashboard, models) => {
  try {
    const dashboardData = await dbRequest
      .loadRecords(`T_RDB_DSB/${idDashboard}/DATA`)
      .execute(async (data) => {
        const dashboardData = {}

        const nonCustomData = data.filter(item => item.ENTITY.ID !== "Custom")
        const customData = data.filter(item => item.ENTITY.ID === "Custom")

        const dataPath = `T_RDB_DSB/${idDashboard}/DATA`

        await Promise.all(nonCustomData.map(async (mainCollection) => {
          dashboardData[mainCollection.ID] = []

          await processMainCollection(mainCollection, dataPath, dashboardData[mainCollection.ID], models)

          await processVariables(mainCollection, dataPath, dashboardData[mainCollection.ID], dashboardData, models.DASHBOARD)

          if (mainCollection.FILTER) {
            dashboardData[mainCollection.ID] = applyFilters(mainCollection.FILTER, dashboardData[mainCollection.ID], models)
          }
        }))

        await Promise.all(customData.map(async (mainCollection) => {
          dashboardData[mainCollection.ID] = []

          const customData = await evaluateFunction(mainCollection.EXPRESSION, models.DASHBOARD)
          dashboardData[mainCollection.ID].push(...customData)

          await processVariables(mainCollection, dataPath, dashboardData[mainCollection.ID], dashboardData, models.DASHBOARD)

          if (mainCollection.FILTER) {
            dashboardData[mainCollection.ID] = applyFilters(mainCollection.FILTER, dashboardData[mainCollection.ID], models)
          }
        }))

        return dashboardData
      })

    return dashboardData
  } catch (error) {
    console.error("Error getting dashboard data:", error)
    throw error
  }
}

// Função para processar a coleção principal de dados do dashboard
const processMainCollection = async (collection, dataPath, dashboardData, models) => {
  try {
    let query = dbRequest.loadRecords(collection.ENTITY.ID)

    if (collection?.WHERE) {

      for (const where of collection.WHERE) {
        if ("STARTING_AT" in where) {
          const startingValue = getModelValue(where.STARTING_AT, models.DASHBOARD)
          const endingValue = getModelValue(where.ENDING_AT, models.DASHBOARD)

          if (startingValue && endingValue) {
            query = query.filterPeriod({
              FIELD: where.FIELD,
              STARTING_AT: startingValue,
              ENDING_AT: endingValue
            })
          }
        } else {
          if (isNaN(where.VALUE)) {
            if (where.VALUE.includes("models.")) where.VALUE = getModelValue(where.VALUE, models.DASHBOARD)
          }
          if (where.VALUE) query = query.where(where.FIELD, where.OPERATOR.VALUE, where.VALUE)
        }
      }
    }

    const collectionData = await query.execute()

    const collectionConnections = await dbRequest
      .loadRecords(`${dataPath}/${collection.ID}/CONNECTIONS`)
      .execute(r => r.sort((a, b) => a.ORDERBY - b.ORDERBY))

    dataPath = `${dataPath}/${collection.ID}/CONNECTIONS`

    await Promise.all(collectionData.map(async (record) => {
      for (const field in record) {
        if (typeof record[field] === "object" && record[field] !== null) {
          if (Object.hasOwnProperty.call(record[field], "ID")) record[field] = { ID: record[field].ID }
        }
      }

      const tempDashboardData = []

      for (const collection of collectionConnections) {
        if (collection.CONNECTIONTYPE.VALUE === "field") {
          record = await adaptForeignFields(record, collection)
        }
      }

      if (collectionConnections.some(collection => collection.CONNECTIONTYPE.VALUE === "detail")) {
        await Promise.all(collectionConnections.map(async collection => {
          if (collection.CONNECTIONTYPE.VALUE === "detail") {
            const detailRecords = await getScreenDetailRecords(record, collection)

            if (!detailRecords || detailRecords.length === 0) return

            await Promise.all(detailRecords.map(async detailRecord => {
              const copyOfRecord = { ...record }
              copyOfRecord[collection.FIELDPATH] = detailRecord

              const subConnectionsPath = `${dataPath}/${collection.ID}/CONNECTIONS`
              const subConnections = await dbRequest.loadRecords(subConnectionsPath).execute()

              if (subConnections.length > 0) {
                await processSubConnections(copyOfRecord, subConnections, subConnectionsPath, tempDashboardData)
              } else {
                tempDashboardData.push(copyOfRecord)
              }
            }))
          }
        }))
      } else tempDashboardData.push(record)

      if (tempDashboardData.length > 0) dashboardData.push(...tempDashboardData)

    }))
  } catch (error) {
    console.error("Error processing main collection:", error)
    throw error
  }
}

// Função para processar as subconexões
const processSubConnections = async (record, subConnections, subConnectionsPath, dashboardData) => {

  for (const subConnection of subConnections) {
    const subConnectionPath = `${subConnectionsPath}/${subConnection.ID}/CONNECTIONS`
    const nestedSubConnections = await dbRequest.loadRecords(subConnectionPath).execute()

    if (subConnection.CONNECTIONTYPE.VALUE === "field") {
      record = await adaptForeignFields(record, subConnection)
    } else if (subConnection.CONNECTIONTYPE.VALUE === "detail") {

      const copyOfRecord = { ...record }
      await getScreenDetailRecords(copyOfRecord, subConnection).then(detailRecords => {
        for (const detailRecord of detailRecords) {
          record = createNestedFields({ ...record }, subConnection.FIELDPATH.split("."), detailRecord)
          if (nestedSubConnections.length === 0) dashboardData.push({ ...record })
        }
      })
    }

    if (nestedSubConnections.length > 0) await processSubConnections({ ...record }, nestedSubConnections, subConnectionPath, dashboardData)
  }
}

const createNestedFields = (obj, path, value) => {
  let newObj = JSON.parse(JSON.stringify(obj)) // Cria uma cópia profunda do objeto original
  let nestedObj = newObj
  let key = null

  // Percorre o caminho até o penúltimo elemento para encontrar o objeto pai
  for (let i = 0; i < path.length - 1; i++) {
    key = path[i]
    if (!nestedObj[key]) {
      nestedObj[key] = {} // Cria um novo objeto se não existir
    }
    nestedObj[key] = { ...nestedObj[key] } // Cria uma nova referência do objeto aninhado
    nestedObj = nestedObj[key] // Atualiza a referência para o objeto aninhado
  }

  // Atualiza a chave para o último elemento do caminho
  key = path[path.length - 1]

  // Verifica se a chave já existe no objeto pai
  if (!nestedObj[key]) {
    nestedObj[key] = {} // Cria um novo objeto se não existir
  }

  // Atualiza o objeto aninhado com o valor
  nestedObj[key] = value

  return newObj // Retorna o objeto completo modificado
}

// Função para adaptar os campos estrangeiros de um registro
const adaptForeignFields = async (record, collection) => {
  const newObj = { ...record }
  let value = null

  if (collection.FIELDPATH.includes(".")) {
    const parts = collection.FIELDPATH.split(".")
    let tempValue = newObj
    for (const part of parts) tempValue = tempValue[part]
    value = tempValue?.ID
  } else {
    value = newObj?.[collection.ID]?.ID
  }

  const foreignRecord = await dbRequest
    .loadRecords(collection.ENTITY.ID)
    .where("ID", "==", value)
    .execute()

  let targetObject = newObj
  if (collection.FIELDPATH.includes(".")) {
    const parts = collection.FIELDPATH.split(".")
    const lastPart = parts.pop()
    for (const part of parts) targetObject = targetObject[part]
    targetObject[lastPart] = foreignRecord[0]
  } else {
    newObj[collection.FIELDPATH] = foreignRecord[0]
  }

  return newObj
}

// Função para obter os registros de detalhes da tela
const getScreenDetailRecords = async (record, collection) => {

  const replacePathValues = (path, record) => {
    return path.replace(/\$\{([^}]+)\}/g, (_, match) => {
      const parts = match.split(".")
      let value = record
      for (let i = 0; i < parts.length; i++) {
        value = value[parts[i]]
      }
      return value
    })
  }

  const populateCollectionPath = replacePathValues(collection.COLLECTIONPATH, record)

  const query = dbRequest.loadRecords(populateCollectionPath)

  if (collection.WHERE) {
    const whereValue = replacePathValues(collection.WHERE.VALUE, record)
    query.where(collection.WHERE.FIELD, collection.WHERE.OPERATOR, whereValue)
  }

  return await query.execute()
}

const processVariables = async (collection, dataPath, collectionData, dashboardData, models) => {

  const variables = await dbRequest.loadRecords(`${dataPath}/${collection.ID}/VARIABLES`).execute()

  if (!variables.length) return

  for (const record of collectionData) {
    for (const variable of variables) {

      const params = {
        record,
        collectionData,
        dashboardData
      }

      record[variable.ID] = await evaluateFunction(variable.EXPRESSION, models, params)
    }
  }
}

function applyFilters(filtersArray, dataArray, models) {

  return dataArray.filter(item => {
    return filtersArray.every(filter => {
      let valueToCompare = filter.VALUE

      if (typeof valueToCompare === "string" && valueToCompare.includes("models.")) {
        valueToCompare = getModelValue(filter.VALUE, models.DASHBOARD)
      }

      if (valueToCompare === null || valueToCompare === undefined) return true

      switch (filter.OPERATOR.VALUE) {
        case "==":
          return item[filter.FIELD] == valueToCompare
        case "!=":
          return item[filter.FIELD] != valueToCompare
        case ">":
          return item[filter.FIELD] > valueToCompare
        case ">=":
          return item[filter.FIELD] >= valueToCompare
        case "<":
          return item[filter.FIELD] < valueToCompare
        case "<=":
          return item[filter.FIELD] <= valueToCompare
        default:
          return true
      }
    })
  })
}

function getModelValue(str, models) {
  if (!str.includes("models.")) return str

  const prefix = "models."

  let field = null

  if (str.startsWith(prefix)) {
    field = str.substring(prefix.length)
  }

  let fieldValue = null

  if (field.includes(".")) {
    const parts = field.split(".")
    let value = models
    for (let i = 0; i < parts.length; i++) {
      value = value?.[parts[i]]
    }
    fieldValue = value
  } else fieldValue = models[field]

  return fieldValue ?? null
}

export default dashboardRequest
