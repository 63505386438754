import { Feather, FontAwesome5, FontAwesome6, Fontisto, MaterialCommunityIcons, SimpleLineIcons, Ionicons } from "@expo/vector-icons"
import { defaultTheme } from "../../../../styles/themes/default"

export const toolbarIcons = {
  add: <Ionicons name="add-outline" size={16} color={defaultTheme["white"]} style={{ marginTop: 1, marginRight: 0.5 }} />,
  copy: <Ionicons name="copy-outline" size={14} color={defaultTheme["white"]} style={{ marginTop: 1, marginRight: 0.5 }} />,
  delete: <Ionicons name="trash-outline" size={14} color={defaultTheme["white"]} style={{ marginTop: 1, marginRight: 0.5 }} />,
  refresh: <SimpleLineIcons name="refresh" size={14} color={defaultTheme["white"]} style={{ marginTop: 1, marginRight: 0.5 }} />,
  expand: <MaterialCommunityIcons name="playlist-plus" size={16} color={defaultTheme["white"]} />,
  collapse: <MaterialCommunityIcons name="playlist-minus" size={16} color={defaultTheme["white"]} />,
  next: <FontAwesome6 name="arrow-right-long" size={14} color={defaultTheme["cyan-300"]} />,
  nextAlt: <FontAwesome6 name="arrow-right-long" size={14} color={defaultTheme["white"]} />,
  previous: <FontAwesome6 name="arrow-left-long" size={14} color={defaultTheme["cyan-300"]} />,
  previousAlt: <FontAwesome6 name="arrow-left-long" size={14} color={defaultTheme["white"]} />,
  save: <Fontisto name="save" size={12} color={defaultTheme["white"]} />,
  undo: <MaterialCommunityIcons name="undo-variant" size={16} color={defaultTheme["white"]} />
}
