import React from "react"
import * as S from "./styles"

export const ImgTemplate = (data) => {

  return (
    <S.Container>
      <S.ImageContainer hasAttachment={data?.IMG ? true : false}>
        {data?.IMG ? <S.Image src={data.IMG} /> : <S.ImageIcon />}
      </S.ImageContainer>
    </S.Container>
  )
}

