export default ({ field, models, orderedOptions }) => {
  const { ID, TABLENAME, FIELDTYPE } = field

  const listContent = []

  const modelValue = models[TABLENAME][ID]

  let hasModelValue = null

  if (FIELDTYPE.VALUE === "L") hasModelValue = modelValue ? Boolean(modelValue.VALUE) : false
  else if (FIELDTYPE.VALUE === "O") hasModelValue = modelValue ? Boolean(modelValue.length) : false

  for (const option of orderedOptions) {
    const { LABEL, VALUE, DEFAULTVALUE } = option

    const obj = {
      LABEL,
      VALUE
    }

    if (DEFAULTVALUE && !hasModelValue) {
      FIELDTYPE.VALUE === "O" ? models[TABLENAME][ID] = [obj] : models[TABLENAME][ID] = obj
    }

    listContent.push(option)
  }

  return listContent
}
