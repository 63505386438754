import React from "react"
import * as S from "./styles"

export default function Checkbox({ fieldValue, isSelected }) {
  const isMarked = Boolean(fieldValue)

  return (
    <S.Container isMarked={isMarked} isSelected={isSelected}>
      <S.Icon isMarked={isMarked} />
    </S.Container>
  )
}
