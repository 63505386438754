import { defaultTheme } from './../../../../../../../styles/themes/default';
import { Feather } from "@expo/vector-icons"
import styled from "styled-components"

export const Container = styled.div`
  background-color: ${defaultTheme["white"]};
  width: 90%;
  height: 80%;
  padding: 5px;
  border-radius: 8px;
  display: flex;
  gap: 5px;
  flex-direction: column;
`

export const Title = styled.p`
  color: ${defaultTheme["gray-900"]};
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-self: center;
`

export const CloseButton = styled.button`
  position: absolute;
  display: flex;
  align-self: flex-end;
  background-color: transparent;
  border-radius: 4px;
  padding: 1px;

  &:hover {
    box-shadow: 2px 2px 5px #00000029;
    scale: 1.02;
  }
`

export const CloseIcon = styled(Feather)
  .attrs({
    name: "x",
    size: 20,
    color: defaultTheme["gray-700"],
  })``

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
`