import dbRequest from "../../../../../../services/dbRequest/dbRequest"

const checkIncludedProductUnit = async (addedProducts, product, controller, models) => {
  if (addedProducts.length > 0) {
    const addedProduct = addedProducts.find(item => item.ITEMID.ID == product.ID)
    if (addedProduct) return addedProduct.UNITID
  }

  if (product?.USEUNITCOY) {
    const alternativeUnit = await getAlternativeUnit(product.ID, models)
    if (alternativeUnit) {
      const allUnits = await controller.getProductUnits(product.ID)
      const unit = allUnits.find(unit => unit.ID === alternativeUnit[0]?.UNITID.ID)
      if (unit) return unit
    }
  }
}

const getAlternativeUnit = (productID, models) => {
  return dbRequest
    .loadRecords(`T_REC_ITE/${productID}/T_SET_ICOY`)
    .where("ID", "==", models.T_MOV_HDR.COYID.ID)
    .execute()
}

export default checkIncludedProductUnit
