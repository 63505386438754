import allRequiredFieldsFilled from "../../../../../../../services/registration/allRequiredFieldsFilled"
import showAlert from "../../../../../../../services/showAlert"
import handleClose from "./handleClose"

export default async (models, controller, isEdit, closeModal, setUpdateFieldsTable) => {
  if (models.T_DTD_FLD.FIELDTYPE.VALUE === "K") {
    if (!models.T_DTD_ATR.ENTITY.ID) return showAlert({
      text: "The field Entity was not filled",
      title: "Failed to create field",
      titleType: "error"
    })
  }

  if (!await allRequiredFieldsFilled({
    models,
    entity: "T_DTD_ATR"
  })) return

  showAlert({
    type: "confirm",
    text: "Do you want to save?",
    title: "Field saving",
    handleConfirm: async () => {
      await controller.saveField(isEdit)
      handleClose(models, closeModal)
      setUpdateFieldsTable(s => !s)
    }
  })
}
