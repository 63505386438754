import models from "../models"
import RuntimeConfiguration from "../models/runtimeConfiguration"
import getTableFields from "../services/registration/getTableFields"
import recordIdExists from "../services/registration/recordIdExists"
import showAlert from "../services/showAlert"
import RequestController from "./RequestController"

export default class ScreenBuilderController extends RequestController {

  constructor() {
    super()

    this.modelsTemplate = models.getClone()
    this.models = () => this.modelsTemplate
    this.isAdminRequest = RuntimeConfiguration.getIsDeveloperConfigurationMode()
  }

  modelsTemplate = null
  models = null

  async getTableData(ID) {
    return await super.load("T_DTD_TAB", ID).then(([r]) => r)
  }

  async getTableRecords(ID) {
    return await super.load(ID)
  }

  findMaxOrderBy(fields) {
    let maxOrderby = this.isAdminRequest ? 0 : 10000
    for (const id in fields) {
      const orderByValue = fields[id].T_DTD_ATR?.ORDERBY
      if (orderByValue > maxOrderby) maxOrderby = orderByValue
    }

    return maxOrderby + 1
  }

  //Field
  async saveField(isEdit) {
    const { ID, FIELDTYPE, T_DTD_OPT } = this.models().T_DTD_FLD
    const ATR = { ...this.models().T_DTD_ATR }

    for (const key in ATR) {
      if (ATR[key] === null) delete ATR[key]
    }

    const tableID = this.models().T_DTD_TAB.ID

    const fieldID = isEdit ? ID : super.formatID(ID)

    if (ATR.NAVIGATION) FIELDTYPE.VALUE = "N"

    const ORDERBY = ATR.ORDERBY ?? 9999

    const fieldPath = `T_DTD_TAB/${tableID}/T_DTD_FLD`
    const fieldRecord = {
      ID: fieldID,
      FIELDTYPE
    }

    await super.save(fieldPath, fieldRecord)

    const promises = []

    const attributes = {
      ...ATR,
      ORDERBY,
      TAB: await this.getTABValue(),
      CARDVISIBLE: ATR.CARDVISIBLE ?? ATR.VISIBLE ?? false,
      CARDORDERBY: ATR.CARDORDERBY ?? ORDERBY,
      GRIDVISIBLE: ATR.GRIDVISIBLE ?? ATR.VISIBLE ?? false,
      GRIDORDERBY: ATR.GRIDORDERBY ?? ORDERBY
    }

    const attributePath = `T_DTD_TAB/${tableID}/T_DTD_FLD/${fieldID}/T_DTD_ATR`

    for (const attribute in attributes) {
      const attributeRecord = {
        ID: attribute,
        VALUE: attributes[attribute]
      }

      promises.push(super.save(attributePath, attributeRecord))
    }

    if (FIELDTYPE.VALUE === "L") {
      const optionPath = `T_DTD_TAB/${tableID}/T_DTD_FLD/${fieldID}/T_DTD_OPT`

      for (const option in T_DTD_OPT) {
        const optionRecord = {
          ID: option,
          VALUE: T_DTD_OPT[option]
        }

        promises.push(super.save(optionPath, optionRecord))
      }
    }

    await Promise.all(promises)

    const alertText = isEdit ? "Field edited successfully" : "Field created successfully"

    showAlert({
      text: alertText,
      title: "Field saved",
      titleType: "success"
    })
  }

  async getTABValue() {
    const { TAB } = this.models().T_DTD_ATR
    if (!TAB) return {}

    const tableData = await getTableFields({ entityName: this.models().T_DTD_TAB.ID }) as any

    for (const key in tableData.T_DTD_FLD) {
      const currentTab = tableData.T_DTD_FLD[key].T_DTD_ATR.TAB ?? {}
      if (currentTab.DESCRIPTION === TAB) return currentTab
    }

    return {
      DESCRIPTION: TAB,
      ORDERBY: 999
    }
  }

  async deleteField() {
    const tableID = this.models().T_DTD_TAB.ID

    const fieldID = this.models().T_DTD_FLD.ID

    await super.delete(`T_DTD_TAB/${tableID}/T_DTD_FLD`, { ID: fieldID })

    showAlert({
      text: "Field deleted successfully",
      title: "Field deleted",
      titleType: "success"
    })
  }

  async deleteOption() {
    const tableID = this.models().T_DTD_TAB.ID

    const fieldID = this.models().T_DTD_FLD.ID

    const optionID = this.models().T_DTD_OPT.VALUE

    await super.delete(`T_DTD_TAB/${tableID}/T_DTD_FLD/${fieldID}/T_DTD_OPT`, { ID: optionID })

    showAlert({
      text: "Option deleted successfully",
      title: "Option deleted",
      titleType: "success"
    })
  }

  //Table
  async saveTable() {
    const tableID = super.formatID(this.models().T_DTD_TAB.ID)

    if (await recordIdExists("T_DTD_TAB", tableID))
      return showAlert({
        text: "Table name already exists",
        title: "Failed to create table",
        titleType: "error"
      })

    const tableDocument = await this.createTableObject()

    const fields = tableDocument.T_DTD_FLD
    //const transactions = tableDocument.T_DTD_TNS

    delete tableDocument.T_DTD_FLD
    //delete tableDocument.T_DTD_TNS

    if (this.models().T_DTD_TAB.TYPE?.VALUE === "RegistrationDetail") await this.detailTabCreation()
    else delete this.models().T_DTD_TAB.MASTERTAB

    await super.save("T_DTD_TAB", tableDocument)

    const fieldsPromises = []
    const attributesPromises = []

    for (const field in fields) {
      const currentField = fields[field]

      const promise = super.save(`T_DTD_TAB/${tableDocument.ID}/T_DTD_FLD`, {
        ID: field,
        FIELDTYPE: currentField.FIELDTYPE
      })

      fieldsPromises.push(promise)

      for (const attribute in currentField.T_DTD_ATR) {
        const promise = super.save(`T_DTD_TAB/${tableDocument.ID}/T_DTD_FLD/${field}/T_DTD_ATR`, {
          ID: attribute,
          VALUE: currentField.T_DTD_ATR[attribute]
        })

        attributesPromises.push(promise)
      }
    }

    await Promise.all(fieldsPromises)
    await Promise.all(attributesPromises)

    showAlert({
      text: "Table created successfully",
      title: "Table created",
      titleType: "success"
    })
  }

  async createTableObject() {
    const { ID, ENTITY, PKSOURCE, PKTYPE, TYPE, FILTER, HELPDOC, MASTERTAB } = this.models().T_DTD_TAB

    const obj = {
      ID: super.formatID(ID),
      ENTITY,
      PKSOURCE,
      PKTYPE: PKTYPE ?? { VALUE: "S", LABEL: "String" },
      TYPE: TYPE ?? {
        LABEL: "Registration",
        VALUE: "Registration"
      },
      T_DTD_FLD: await this.getTableDefaultFields(),
      T_DTD_TNS: { [this.getTransactionID()]: { DESCRIPTION: ENTITY } }
    } as any

    if (this.isAdminRequest)
      obj.T_DTD_FLD.EXTERNALKEY = {
        FIELDTYPE: {
          LABEL: "String",
          VALUE: "S"
        },
        T_DTD_ATR: {
          EDITABLE: true,
          FIELDDESCRIPTION: "External Key",
          ORDERBY: 999,
          REQUIRED: false,
          SIZE: {
            LABEL: "Small",
            VALUE: "S"
          },
          VISIBLE: true,
          TAB: {
            DESCRIPTION: "Log",
            ORDERBY: 999
          },
          CARDVISIBLE: true,
          CARDORDERBY: 999,
          GRIDVISIBLE: true,
          GRIDORDERBY: 999
        }
      }

    if (FILTER) obj.FILTER = FILTER
    if (HELPDOC) obj.HELPDOC = HELPDOC
    if (Object.keys(MASTERTAB ?? {}).length > 0) obj.MASTERTAB = MASTERTAB

    if (PKSOURCE.VALUE === "A")
      obj.T_DTD_FLD.ID.FIELDTYPE = {
        LABEL: "String",
        VALUE: "S"
      }

    return obj
  }

  async getTableDefaultFields() {
    const rluData = await this.getTableData("T_SET_RLU")

    return {
      ID: this.createPKObject(),
      CREATED_BY: {
        FIELDTYPE: {
          VALUE: "K",
          LABEL: "Foreign Key"
        },
        T_DTD_ATR: {
          ORDERBY: 993,
          FIELDDESCRIPTION: "Created By",
          ENTITY: rluData,
          LABEL: "USERNAME",
          EDITABLE: false,
          VISIBLE: true,
          REQUIRED: false,
          TAB: {
            DESCRIPTION: "Log",
            ORDERBY: 999
          },
          GROUPING: "Create",
          CARDVISIBLE: true,
          CARDORDERBY: 993,
          GRIDVISIBLE: true,
          GRIDORDERBY: 993
        }
      },
      IMG: {
        FIELDTYPE: {
          VALUE: "A",
          LABEL: "Attachment"
        },
        T_DTD_ATR: {
          ORDERBY: 3,
          FIELDDESCRIPTION: "Record image",
          EDITABLE: true,
          VISIBLE: true,
          REQUIRED: false,
          CARDVISIBLE: true,
          CARDORDERBY: 3,
          GRIDVISIBLE: true,
          GRIDORDERBY: 3
        }
      },
      DESCRIPTION: {
        FIELDTYPE: {
          VALUE: "S",
          LABEL: "String"
        },
        T_DTD_ATR: {
          ORDERBY: 2,
          FIELDDESCRIPTION: "Description",
          EDITABLE: true,
          VISIBLE: true,
          REQUIRED: false,
          CARDVISIBLE: true,
          CARDORDERBY: 2,
          GRIDVISIBLE: true,
          GRIDORDERBY: 2
        }
      },
      CREATED_AT: {
        FIELDTYPE: {
          VALUE: "T",
          LABEL: "Timestamp"
        },
        T_DTD_ATR: {
          ORDERBY: 994,
          FIELDDESCRIPTION: "Created At",
          EDITABLE: false,
          VISIBLE: true,
          REQUIRED: false,
          TAB: {
            DESCRIPTION: "Log",
            ORDERBY: 999
          },
          GROUPING: "Create",
          CARDVISIBLE: true,
          CARDORDERBY: 994,
          GRIDVISIBLE: true,
          GRIDORDERBY: 994
        }
      },
      UPDATED_BY: {
        FIELDTYPE: {
          VALUE: "K",
          LABEL: "Foreign Key"
        },
        T_DTD_ATR: {
          ORDERBY: 995,
          FIELDDESCRIPTION: "Updated By",
          ENTITY: rluData,
          LABEL: "USERNAME",
          EDITABLE: false,
          VISIBLE: true,
          REQUIRED: false,
          TAB: {
            DESCRIPTION: "Log",
            ORDERBY: 999
          },
          GROUPING: "Update",
          CARDVISIBLE: true,
          CARDORDERBY: 995,
          GRIDVISIBLE: true,
          GRIDORDERBY: 995
        }
      },
      UPDATED_AT: {
        FIELDTYPE: {
          VALUE: "T",
          LABEL: "Timestamp"
        },
        T_DTD_ATR: {
          ORDERBY: 996,
          FIELDDESCRIPTION: "Updated At",
          EDITABLE: false,
          VISIBLE: true,
          REQUIRED: false,
          TAB: {
            DESCRIPTION: "Log",
            ORDERBY: 999
          },
          GROUPING: "Update",
          CARDVISIBLE: true,
          CARDORDERBY: 996,
          GRIDVISIBLE: true,
          GRIDORDERBY: 996
        }
      }
    }
  }

  createPKObject() {
    const { PKTYPE, PKSOURCE } = this.models().T_DTD_TAB

    const obj = {
      FIELDTYPE: PKTYPE ?? { VALUE: "S", LABEL: "String" },
      T_DTD_ATR: {
        ORDERBY: 1,
        FIELDDESCRIPTION: "ID",
        EDITABLE: PKSOURCE.VALUE === "M",
        REQUIRED: false,
        VISIBLE: true,
        CARDVISIBLE: true,
        CARDORDERBY: 1,
        GRIDVISIBLE: true,
        GRIDORDERBY: 1
      }
    } as any

    if (PKTYPE?.VALUE === "F") obj.T_DTD_ATR.DECIMAL = 2

    return obj
  }

  getTransactionID() {
    const { ID } = this.models().T_DTD_TAB
    const formattedID = ID.replace(/^T_/, "").replace(/_/g, "")
    return super.formatID(formattedID)
  }

  async detailTabCreation() {
    const { ID, MASTERTAB, ENTITY } = this.models().T_DTD_TAB

    const tableID = super.formatID(ID)

    await super.save(`T_DTD_TAB/${MASTERTAB.ID}/T_DTD_FLD`, {
      ID: tableID,
      FIELDTYPE: {
        LABEL: "RegistrationDetail",
        VALUE: "E"
      }
    })

    const attributes = {
      FIELDDESCRIPTION: ENTITY,
      ORDERBY: 9999,
      EDITABLE: true,
      REQUIRED: false,
      VISIBLE: true,
      TAB: {
        DESCRIPTION: ENTITY,
        ORDERBY: 9999
      }
    }

    const attributesPromises = []

    for (const attribute in attributes) {
      const attributeValue = attributes[attribute]

      const promise = super.save(`T_DTD_TAB/${MASTERTAB.ID}/T_DTD_FLD/${tableID}/T_DTD_ATR`, {
        ID: attribute,
        VALUE: attributeValue
      })

      attributesPromises.push(promise)
    }

    await Promise.all(attributesPromises)
  }

  async deleteTable() {
    const tableRecords = await this.getTableRecords(this.models().T_DTD_TAB.ID) as any
    const tableHasRecords = tableRecords.length > 0

    if (tableHasRecords)
      return showAlert({
        text: "The table has records",
        title: "Failed to delete table",
        titleType: "error"
      })

    const { ID, T_DTD_TNS, TYPE } = this.models().T_DTD_TAB
    const transactionID = Object.keys(T_DTD_TNS)[0]

    const promisesArray = [this.deleteNavigation(transactionID), super.delete("T_DTD_TAB", { ID })]

    if (TYPE?.VALUE === "RegistrationDetail")
      promisesArray.push(this.deleteFromMasterTable())

    await Promise.all(promisesArray)

    showAlert({
      text: "Table deleted successfully",
      title: "Table deleted",
      titleType: "success"
    })
  }

  async deleteFromMasterTable() {
    const { ID, MASTERTAB } = this.models().T_DTD_TAB

    await super.delete(`T_DTD_TAB/${MASTERTAB.ID}/T_DTD_FLD`, { ID })
  }

  async deleteNavigation(transactionID) {
    const navigations = await super.load("T_DTD_NAV", this.isAdminRequest ? null : "9999") as any

    for (const item of navigations) {
      if (item.T_DTD_LNC && transactionID in item.T_DTD_LNC) {
        delete item.T_DTD_LNC[transactionID]
        await super.delete("T_DTD_NAV", { ID: item.ID })
        await super.save("T_DTD_NAV", { ...item })
        break
      }
    }
  }

  async addNavigation() {
    const transactionID = Object.keys(this.models().T_DTD_TAB.T_DTD_TNS)[0]

    if (!this.models().MENU && !this.isAdminRequest)
      this.models().MENU = {
        DEPTH: 0,
        DESCRIPTION: "Personalized",
        MASTERID: 0,
        ORDERBY: 999,
        ID: "9999",
        T_DTD_LNC: {}
      }

    if (!this.models().MENU.T_DTD_LNC) this.models().MENU.T_DTD_LNC = {}

    this.models().MENU.T_DTD_LNC[transactionID] = {
      ACTIVE: true,
      ATTRIBUTE: this.models().T_DTD_TAB.ID,
      DESCRIPTION: this.models().T_DTD_TAB.ENTITY,
      ONMOB: false,
      TRANSACTION: transactionID,
      TYPE: "Registration"
    }

    delete this.models().MENU.open
    delete this.models().MENU.SUB_MENUS

    await Promise.all([
      super.save("T_DTD_NAV", { ...this.models().MENU }),
      super.save("T_DTD_TAB", {
        ...this.models().T_DTD_TAB,
        NAVID: this.models().MENU.ID,
        ORDERBY: 999
      })
    ])
  }

  async saveTabs({ fields, tabs }) {
    const orderedTabs = tabs.reduce((result, tab, index) => {
      result[tab] = {
        DESCRIPTION: tab,
        ORDERBY: index + 1
      }
      return result
    }, {})

    const tabsPromises = []

    const tableID = this.models().T_DTD_TAB.ID

    for (const field of fields) {
      const { id, tab } = field

      const doesFieldHasTab = tabs.includes(tab.DESCRIPTION)

      if (doesFieldHasTab) {
        const promise = super.save(`T_DTD_TAB/${tableID}/T_DTD_FLD/${id}/T_DTD_ATR`, {
          ID: "TAB",
          VALUE: orderedTabs[tab.DESCRIPTION]
        })

        tabsPromises.push(promise)
      }
    }

    await Promise.all(tabsPromises)
  }

  async saveOrderedFields({ fields, attribute }) {
    const tableID = this.models().T_DTD_TAB.ID

    const fieldsPromises = fields.map((field, index) => {
      return super.save(`T_DTD_TAB/${tableID}/T_DTD_FLD/${field.ID}/T_DTD_ATR`, {
        ID: attribute,
        VALUE: index + 1
      })
    })

    await Promise.all(fieldsPromises)
  }

  async saveTrigger() {
    const ID = this.models().T_DTD_TRG.ID
    const isEdit = this.models()._REGISTRATION_DATA.T_DTD_TRG.operation === "UPD"
    const triggerID = isEdit ? ID : super.formatID(ID)

    const tableID = this.models().T_DTD_TAB.ID

    const data = {
      ... this.models().T_DTD_TRG,
      ID: triggerID
    }

    const entity = `T_DTD_TAB/${tableID}/T_DTD_TRG`

    await super.save(entity, { ...data })

    return triggerID
  }

  async deleteTrigger() {
    const ID = this.models().T_DTD_TRG.ID
    const tableID = this.models().T_DTD_TAB.ID

    await super.delete(`T_DTD_TAB/${tableID}/T_DTD_TRG`, { ID })

    showAlert({
      text: "Trigger deleted successfully",
      title: "Trigger deleted",
      titleType: "success"
    })
  }

  async saveFunction() {
    const ID = this.models().T_DTD_FNC.ID
    const isEdit = this.models()._REGISTRATION_DATA.T_DTD_FNC.operation === "UPD"
    const functionID = isEdit ? ID : super.formatID(ID)

    const tableID = this.models().T_DTD_TAB.ID

    const data = {
      ... this.models().T_DTD_FNC,
      ID: functionID
    }

    const entity = `T_DTD_TAB/${tableID}/T_DTD_FNC`

    await super.save(entity, {
      ...data,
      T_DTD_FLD: {}
    })

    return functionID
  }

  async deleteFunction() {
    const ID = this.models().T_DTD_FNC.ID
    const tableID = this.models().T_DTD_TAB.ID

    await super.delete(`T_DTD_TAB/${tableID}/T_DTD_FNC`, { ID })

    showAlert({
      text: "Function deleted successfully",
      title: "Function deleted",
      titleType: "success"
    })
  }

  async saveParameter() {
    const ID = this.models().T_DTD_FLD.ID
    const isEdit = this.models()._REGISTRATION_DATA.T_DTD_FLD.operation === "UPD"
    const fieldID = isEdit ? ID : super.formatID(ID)

    delete this.models().T_DTD_FLD.ID

    const tableID = this.models().T_DTD_TAB.ID

    this.models().T_DTD_ATR.TAB = this.models().T_DTD_ATR.TAB
      ? {
        DESCRIPTION: this.models().T_DTD_ATR.TAB,
        ORDERBY: 999
      }
      : {}

    if (!this.models().T_DTD_ATR.ORDERBY)
      this.models().T_DTD_ATR.ORDERBY = 999

    const functionID = this.models().T_DTD_FNC.ID

    await super.save(`T_DTD_TAB/${tableID}/T_DTD_FNC/${functionID}/T_DTD_FLD`, {
      ID: fieldID,
      ... this.models().T_DTD_FLD
    })

    const attributesPromises = []

    for (const attribute in this.models().T_DTD_ATR) {
      const attributeValue = this.models().T_DTD_ATR[attribute]

      if (attributeValue !== null && attributeValue !== undefined) {
        const promise = super.save(`T_DTD_TAB/${tableID}/T_DTD_FNC/${functionID}/T_DTD_FLD/${fieldID}/T_DTD_ATR`, {
          ID: attribute,
          VALUE: attributeValue
        })

        attributesPromises.push(promise)
      }
    }

    await Promise.all(attributesPromises)

    showAlert({
      text: "Parameter saved successfully",
      title: "Parameter saved",
      titleType: "success"
    })
  }

  async deleteParameter() {
    const fieldID = this.models().T_DTD_FLD.ID
    const tableID = this.models().T_DTD_TAB.ID
    const functionID = this.models().T_DTD_FNC.ID

    await super.delete(`T_DTD_TAB/${tableID}/T_DTD_FNC/${functionID}/T_DTD_FLD`, { ID: fieldID })

    showAlert({
      text: "Parameter deleted successfully",
      title: "Parameter deleted",
      titleType: "success"
    })
  }
}
