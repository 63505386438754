import dbRequest from "../../../../../../../../../../../../services/dbRequest/dbRequest"

const getDashboardsRequest = async () => {
  const dashboardsMenu = {
    ID: "dashboards",
    IDACCESS: "dashboards",
    DESCRIPTION: "Dashboards",
    icon: "arrow-right",
    T_DTD_FNC: {}
  }

  const dashboards = await dbRequest.loadRecords("T_RDB_DSB").execute() as any

  for (const dashboard of dashboards) {
    const { ID, DESCRIPTION } = dashboard

    dashboardsMenu.T_DTD_FNC[ID] = {
      ID,
      DESCRIPTION,
      ACTIVE: true
    }
  }

  return dashboardsMenu
}

export default getDashboardsRequest
