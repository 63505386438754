import React, { createContext, useState } from "react"

export const GridModeContext = createContext()

const GridModeProvider = ({ children }) => {

  const [mode, setMode] = useState("table")

  const changeMode = () => setMode(s => s === "table" ? "form" : "table")

  return (
    <GridModeContext.Provider value={{
      mode,
      setMode,
      changeMode
    }}>
      {children}
    </GridModeContext.Provider>
  )
}

export default GridModeProvider
