import globalDate from "../../../../global/globalDate"
import LoginData from "../../../../models/loginData"
import dbRequest from "../../../../services/dbRequest/dbRequest"
import showAlert from "../../../../services/showAlert"

interface FormattedEvent {
  DESCRIPTION: string
  ISALLDAY: boolean
  ENDTIME: string
  STARTTIME: string
  ID?: string
  UID?: object
  COMMENTS?: string
  LOCATION?: string
  RECURRENCERULE?: string
  GROUPUID?: object
  STATUS?: string
}

const handleSave = async ({ eventData }) => {
  const { Id, Description, EndTime, IsAllDay, Location, RecurrenceRule, StartTime, Subject } = eventData

  const formattedEvent: FormattedEvent = {
    DESCRIPTION: Subject,
    ISALLDAY: IsAllDay,
    ENDTIME: new globalDate(EndTime).toISO(),
    STARTTIME: new globalDate(StartTime).toISO(),
    STATUS: "Completed"
  }

  if (Id) formattedEvent.ID = Id
  if (Description) formattedEvent.COMMENTS = Description
  if (Location) formattedEvent.LOCATION = Location
  if (RecurrenceRule) formattedEvent.RECURRENCERULE = RecurrenceRule

  let type = "own"

  if (type == "own")
    formattedEvent.UID = {
      ID: LoginData.getLoginData().ID,
      DESCRIPTION: LoginData.getLoginData().DESCRIPTION,
      IMG: LoginData.getLoginData().IMG
    }

  else if (type == "group")
    formattedEvent.GROUPUID = LoginData.getLoginData().GROUPUID

  else if (type == "other") {
    formattedEvent.UID = {
      ID: "ID_OUTRO_USUARIO",
      DESCRIPTION: "DESCRIPTION_OUTRO_USUARIO",
      IMG: "LoginData.getLoginData().IMG_OUTRO_USUARIO"
    }

    formattedEvent.STATUS = "Aguardando Confirmação"
  }

  await dbRequest
    .saveRecords("T_REC_CAL")
    .record(formattedEvent)
    .execute()

  showAlert({
    text: "Event saved successfully",
    titleType: "success"
  })
}

export default handleSave