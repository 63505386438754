import React, { createContext, useState } from "react"
import ScreenBuilderController from "../../../../controllers/ScreenBuilderController"

export const ControllerContext = createContext()

const ControllerProvider = ({ children }) => {
  const [controller] = useState(new ScreenBuilderController())

  return (
    <ControllerContext.Provider
      value={{
        controller,
        models: controller.models()
      }}
    >
      {children}
    </ControllerContext.Provider>
  )
}

export default ControllerProvider
