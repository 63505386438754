export const filterTypes = [
  {
    VALUE: "JAVASCRIPT",
    LABEL: "JavaScript"
  },
  {
    VALUE: "FIREBASE",
    LABEL: "Firebase"
  }
]

export default filterTypes
