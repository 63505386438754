import { AntDesign } from "@expo/vector-icons"
import styled from "styled-components/native"
import globalStyles from "../../../../global/globalStyles"

export const BtnType = styled.KeyboardAvoidingView`
  position: absolute;
  top: 10px;
  left: 20px;
  z-index: 1;
`

export const ReturnBtn = styled.TouchableOpacity`
  justify-content: center;
  align-items: center;
`
export const ReturnIcon = styled(AntDesign)`
  color: ${globalStyles.colors.primary};
  font-size: 38px;
`
