import RuntimeConfiguration from "../../../../../../../models/runtimeConfiguration"
import showAlert from "../../../../../../../services/showAlert"

export default (controller, models, closeModal) => {
  const tableID = models.T_DTD_TAB.ID

  const isAdmin = RuntimeConfiguration.getIsDeveloperConfigurationMode()

  if (!tableID) return getAlert("No table selected")

  if (!tableID.includes("Z_") && !isAdmin)
    return getAlert("It is not possible to launch a native table")

  const { ID, TYPE } = models.MENU

  if (!ID) return getAlert("No menu selected")

  if (TYPE?.VALUE !== "Records") return getAlert("Invalid menu")

  if (models.T_DTD_TAB.TYPE.VALUE !== "Registration") return getAlert("Only master tables can be added to the menu")

  showAlert({
    type: "confirm",
    text: "Do you want do add the table to the selected menu?",
    title: "Manage menu",
    handleConfirm: async () => {
      await controller.addNavigation()
      showAlert({
        text: "The table will be displayed after restarting the application",
        title: "Table added successfully",
        titleType: "success"
      })
      models.MENU.reset()
      closeModal("manageMenu")
    }
  })
}

const getAlert = text => showAlert({
  text,
  title: "Failed to add table to menu",
  titleType: "error"
})
