import React from "react"
import { Modal, ActivityIndicator } from "react-native"
import globalStyles from "../../../../../global/globalStyles"
import { Container } from "./styles"

export default function LoadingModal({ isLoading }) {
  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={isLoading}
    >
      <Container>
        <ActivityIndicator size='large' color={globalStyles.colors.primary} />
      </Container>
    </Modal>
  )
}
