import React from "react"
import { useTranslation } from "react-i18next"
import LoginData from "../../../../../models/loginData"
import * as S from "./styles"

interface ActionButtonProps {
  icon: React.ReactNode
  disabled?: boolean
  text: string
  renderText?: boolean
  variant?: "white" | null
  action: () => void
}

export default function ActionButton({ icon, renderText, text, variant, action, disabled = false }: ActionButtonProps) {
  const { t } = useTranslation()

  const isSystemAdmin = LoginData.getLoginData().ADMIN
  if (isSystemAdmin && ![t("Refresh"), t("Next record"), t("Previous record")].includes(text)) disabled = false

  return renderText ? (
    <S.Container onClick={action} disabled={disabled}>
      <S.Button variant={variant} disabled={disabled}>
        {icon}
      </S.Button>

      <span>{t(text)}</span>
    </S.Container>
  ) : (
    <S.Button onClick={action} title={t(text)} variant={variant} disabled={disabled}>
      {icon}
    </S.Button>
  )
}
