import { useMemo } from "react"

const getNestedValue = (obj, field) => {
  const value = obj[field]

  if (value && typeof value === "object") {
    return Object.values(value).join(" ")
  }

  return value
}

const useFilterRecords = (records, searchText, controller) => {
  const filteredRecords = useMemo(() => {
    if (!searchText) return records

    const lowerSearchText = searchText.toLowerCase()

    return records.filter((record) => {
      return Object.keys(record).some((field) => {
        const fieldValue = getNestedValue(record, field)

        return (
          fieldValue &&
          fieldValue.toString().toLowerCase()
            .includes(lowerSearchText)
        )
      })
    })

  }, [records, searchText])

  if (filteredRecords.length === 1) controller.uniqueRecord = filteredRecords[0]

  return filteredRecords
}

export default useFilterRecords
