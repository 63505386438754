import { AntDesign, Feather, Ionicons } from "@expo/vector-icons"
import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { addAdditionalFilters } from "../../../../../../../redux/redux-reducers/order-reducer"
import { defaultTheme } from "../../../../../../../styles/themes/default"
import * as S from "./styles"

export default function SelectionInputFilter({ getDataFunction, inputName, fieldname, iconName, multiple = false, setUpdate }) {

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [isOpen, setIsOpen] = useState(false)
  const [data, setData] = useState([])
  const [itemsSelected, setItemsSelected] = useState(multiple ? [] : "General")
  const dropdownRef = useRef(null)

  const toggleDropdown = async () => {
    if (!isOpen) {
      setData(await getDataFunction())
    }
    setIsOpen(!isOpen)
  }

  const handleSelectField = (item) => {
    if (!multiple) {
      if (itemsSelected.includes(item)) {
        setItemsSelected(data[0].VALUE)

        dispatch(addAdditionalFilters({
          VALUE: "General",
          LABEL: "General"
        }))
      } else {
        setItemsSelected(item)

        dispatch(addAdditionalFilters({
          VALUE: item,
          LABEL: "General"
        }))
      }
      setUpdate(state => !state)
      return
    }

    let aux = []
    const isExists = itemsSelected.includes(item)

    if (isExists) aux = itemsSelected.filter(i => i !== item)
    else aux = [...itemsSelected, item]

    setItemsSelected(aux)
    dispatch(addAdditionalFilters({
      VALUE: aux,
      LABEL: fieldname
    }))
    setUpdate(state => !state)
  }

  const renderListItem = (item) => {
    const isSelected = itemsSelected.includes(item.VALUE)

    return (
      <S.DropdownListItem
        key={item.fieldId}
        onClick={() => handleSelectField(item.VALUE)}
      >
        <S.Checkbox isSelected={isSelected}>
          <Feather name="check" size={14} color={isSelected ? defaultTheme["white"] : defaultTheme["gray-700"]} />
        </S.Checkbox>
        <span>{t(item.LABEL || item.VALUE)}</span>
      </S.DropdownListItem>
    )
  }

  return (
    <S.Container>
      {multiple && <S.FiltersCounter>{itemsSelected.length}</S.FiltersCounter>}
      <S.FilterButton onClick={toggleDropdown}>
        <Ionicons name={iconName} size={16} color={defaultTheme["gray-600"]} />
      </S.FilterButton>

      <S.Title >{t(multiple ? inputName : itemsSelected)}</S.Title>

      {isOpen && (
        <S.DropdownList isOpen={isOpen}>
          <S.DropdownTitle>{t("Search by:")}</S.DropdownTitle>
          <S.DropdownListItemsContainer ref={dropdownRef}>
            {data.map(renderListItem)}
          </S.DropdownListItemsContainer>
        </S.DropdownList>
      )}

      <AntDesign
        name="caretcircleoup"
        size={18}
        color={defaultTheme["gray-600"]}
        style={{
          position: "absolute",
          right: 8,
          transform: [{ rotate: !isOpen ? "180deg" : "0deg" }],
          cursor: "pointer"
        }}
        onClick={toggleDropdown}
      />
    </S.Container>
  )
}
