import getDoubleClickEvent from '../../../../utils/getDoubleClickEvent'

export default function processSelectedQuery(state, {payload}) {
  if (payload === "new") {
    state.modals.queryModal.form = {}
    state.modals.queryModal.mode = "new"
    state.dashboardData.selectedQuery = { connections: [] }
    state.modals.queryModal.isVisible = true
  }
  if (state.dashboardData.selectedQuery?.ID === payload.ID) {
    getDoubleClickEvent(doubleClickEvent => {
      if (doubleClickEvent.isActive) {
        state.modals.queryModal.mode = "edit"
        state.modals.queryModal.form = payload
        state.modals.queryModal.isVisible = true
        doubleClickEvent.isActive = false
      }
    })
  } else state.dashboardData.selectedQuery = payload
}