import { Platform } from "react-native"
import styled from "styled-components/native"
import normalize from "../../../styles/normalizeSize"
import { defaultTheme } from "../../../styles/themes/default"

export const Container = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: ${defaultTheme["cyan-300"]};
`

export const Logo = styled.Image.attrs({
  resizeMode: "contain",
  source: require("../../images/logoMusa.png")
})`
  width: ${Platform.select({
  web: 200,
  native: normalize(250)
})}px;
  height:  ${Platform.select({
  web: 200,
  native: normalize(250)
})}px;
`
