import { useEffect, useState } from "react"
import getTableFields from "../../../../services/registration/getTableFields"

export default function useRegistration(props) {

  const { entityName, record } = props

  const [selectedItem, setSelectedItem] = useState(null)
  const [recordsAmount, setRecordsAmount] = useState(null)
  const [searchText, setSearchText] = useState("")
  const [updateTable, setUpdateTable] = useState(false)
  const [updateForm, setUpdateForm] = useState(false)

  useEffect(() => {
    getTableFields({ entityName })
      .then(fields => {
        populateModels({
          fields,
          ...props
        })
      })
  }, [record])

  return {
    selectedItem,
    setSelectedItem,
    recordsAmount,
    setRecordsAmount,
    searchText,
    setSearchText,
    updateTable,
    setUpdateTable,
    updateForm,
    setUpdateForm
  }
}

function populateModels({ models, entityName, fields, record = null, changeMode, mode, setUpdateForm }) {

  const tableData = models._REGISTRATION_DATA[entityName]

  tableData.fields = fields

  if (record) {
    const detailTabs = fields.filter(({ FIELDTYPE, T_DTD_ATR }) => FIELDTYPE.VALUE === "E" && T_DTD_ATR.VISIBLE)

    for (const table of detailTabs) {
      const tableID = table.ID
      const recordID = record.ID
      const masterTable = tableData.masterTable

      tableData.detailTabsPath[tableID] = `${models._REGISTRATION_DATA[masterTable].detailTabsPath[entityName]}/${recordID}/${tableID}`
    }

    models[entityName].reset()
    models[entityName].populate(record)

    tableData.operation = "UPD"
    mode === "table" && changeMode()
    setUpdateForm(s => !s)
  }
}
