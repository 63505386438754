import LoginData from "../../../../../models/loginData"

export default ({ models, entity }) => {
  const tableData = models._REGISTRATION_DATA[entity]
  const record = { ...models[entity] }

  tableData.originalRecord = record
  tableData.operation = "UPD"
  tableData.state = "AFTER"

  if (entity === "T_SET_RLU" && LoginData.getLoginData().ID === record.ID)
    LoginData.setLoginData({ ...record })
}
