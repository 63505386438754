import styled from "styled-components"
import { defaultTheme } from "../../../../../../styles/themes/default"

export const MultiSelect = styled.input<MultiSelectProps>`
  background-color: ${({ isEditable }) =>
    isEditable ? defaultTheme.white : defaultTheme["gray-200"]};
  color: ${defaultTheme["gray-700"]};
  font-size: ${defaultTheme.formInput.fontSize}px;
  width: 500px;
  max-width: 1000px;
  border: 1px solid ${defaultTheme["gray-300"]};
  border-radius: 10px;
  height: auto;
  min-height: ${({ isEditable }) =>
    isEditable
      ? defaultTheme.formInput.height + 10
      : defaultTheme.formInput.height}px;
  outline: none;
  display: block;
  resize: vertical;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100px;

  &:disabled {
    background-color: ${defaultTheme.black};
    color: ${defaultTheme["gray-400"]};
    cursor: not-allowed;
  }
`

interface TitleProps {
  isRequired: boolean
  isEditable: boolean
  disabled: boolean
}

interface MultiSelectProps {
  isEditable: boolean
  disabled: boolean
}

export const Title = styled.p<TitleProps>`
  color: ${defaultTheme["cyan-800"]};
  font-size: ${defaultTheme.formInput.fontSize}px;
  font-weight: ${({ isRequired }) => (isRequired ? "bold" : "normal")};
  line-height: 20px;

  &::after {
    content: "*";
    color: ${defaultTheme["cyan-800"]};
    display: ${({ isRequired }) => (isRequired ? "inline" : "none")};
  }
`

export const Row = styled.div``
