import storageRequest from "../../../../../../../../../services/storageRequest"

export default async function saveFiles({ models, entity, detailTabPath }) {
  const { ID } = models[entity]
  const attachmentFields = models._REGISTRATION_DATA[entity].files

  for (const field in attachmentFields) {
    const fieldFiles = models._REGISTRATION_DATA[entity].files[field]

    const formattedFilesPromises = fieldFiles.map(file => {
      if (file.URL) return file

      const record = file.devicePath ? file : new Blob([file], { type: file.type })

      return storageRequest
        .saveFiles(`${detailTabPath}/${ID}/${field}/${file.name || file.NAME}`)
        .file(record)
        .execute((res: any) => {
          const result = {
            NAME: file.name || file.NAME,
            SIZE: file.size || file.SIZE,
            TYPE: file.type || file.TYPE,
            URL: res.url
          }

          return result
        })
    })

    const filesArray = await Promise.all(formattedFilesPromises)

    models._REGISTRATION_DATA[entity].files[field] = filesArray
    models[entity][field] = filesArray
  }
}
