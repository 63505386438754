import getTableFields from "../../../../services/registration/getTableFields"

const getGridFields = async ({ entity, models }) => {
  const fields = await getTableFields({ entityName: entity })

  const tableData = models._REGISTRATION_DATA[entity]
  tableData.fields = fields

  const visibleFields = fields.filter(({ FIELDTYPE, T_DTD_ATR }) => {
    const isDetailTab = FIELDTYPE.VALUE === "E"
    const isVisible = T_DTD_ATR.VISIBLE

    return !isDetailTab && isVisible
  })

  const sortedFields = visibleFields.sort((a, b) => a.T_DTD_ATR.GRIDORDERBY - b.T_DTD_ATR.GRIDORDERBY)

  const detailTabs = fields.filter(({ FIELDTYPE }) => FIELDTYPE.VALUE === "E")

  return {
    sortedFields,
    detailTabs
  }
}

export default getGridFields
