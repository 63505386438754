import styled from "styled-components"
import { Feather } from "@expo/vector-icons"
import { defaultTheme } from '../../../../../styles/themes/default'

interface IconProps {
  isFocused: boolean
  theme: typeof defaultTheme
}

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  justify-content: center;
  width: 100%;
`

export const InputContainer = styled.div`
  align-items: center;
  border: 1px solid ${({ theme }) => theme["gray-300"]};
  border-radius: 15px;
  display: flex;
  gap: 1rem;
  height: 2.5rem;
  padding: 0 1rem;
  width: 75%;

  &:focus-within {
    border-color: ${({ theme }) => theme["cyan-300"]};
  }
`

export const Input = styled.input`
  border: none;
  color: ${({ theme }) => theme["gray-700"]};
  font-size: 0.875rem;
  outline: none;
  width: 100%;

  ::placeholder {
    color: ${({ theme }) => theme["gray-300"]};
  }
`

export const LoginButton = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme["cyan-300"]};
  border-radius: 15px;
  color: ${({ theme }) => theme["white"]};  
  display: flex;
  font-size: 0.875rem;
  font-weight: bold;
  height: 2.5rem;
  justify-content: center;
  width: 75%;

  &:hover {
    background-color: ${({ theme }) => theme["cyan-500"]};
    transition: 0.2s ease-in;
    scale: 1.01;
  }
`

export const InputIcon = styled(Feather)
  .attrs(({ theme, isFocused }: IconProps) => ({
    color: theme[isFocused ? "cyan-300" : "gray-300"],
    size: 16
  }))``;
