import getTableFields from "../../../../../../services/registration/getTableFields"

export default async function getDetailFields(entityName) {

  const fields = await getTableFields({ entityName: entityName })

  const filteredFields = fields.sort((a, b) => a?.T_DTD_ATR?.CARDORDERBY - b?.T_DTD_ATR?.CARDORDERBY)

  const allFields = filteredFields
    .filter(item => item.FIELDTYPE.VALUE === "E" && item.T_DTD_ATR.CARDVISIBLE)
    .map(item => {
      item.FIELDTYPE.VALUE = "R"
      return item
    })

  return allFields

}
