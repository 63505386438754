import { collection, doc, getDoc, getDocs, query, where, limit, startAfter, or, and, orderBy, startAt, endAt } from "firebase/firestore/lite"
import checkSession from "../../checkSession"
import { dbConnection } from "../../dbConnection/"
import _id from "../getDocumentId"

export default async function loadRecords(admin, _collection, whereArr, searchObj, lastDocument, hasLimit, filterPeriod) {

  const data = []

  if (!await checkSession(admin)) new Error("Session expired")

  const documentId = whereArr.find(i => i.field === "ID")?.value
  const collectionRef = collection(dbConnection(admin), _collection)

  if (documentId) {
    const docSnapshot = await getDoc(doc(collectionRef, documentId))
    if (!docSnapshot.exists()) return null

    return [{
      ID: docSnapshot.id,
      ...docSnapshot.data()
    }]
  }

  let q = query(collectionRef)

  q = buildDynamicFilter(q, whereArr, searchObj)

  q = lastDocument ? query(q, startAfter(lastDocument)) : q

  q = filterPeriod ? query(q, orderBy(filterPeriod.FIELD), startAt(filterPeriod.STARTING_AT), endAt(filterPeriod.ENDING_AT)) : q

  q = hasLimit ? query(q, limit(30)) : q

  const querySnapshot = await getDocs(q)

  data.push(...querySnapshot.docs.map(doc => ({
    ID: doc.id,
    ...doc.data()
  })))

  lastDocument = querySnapshot.docs[querySnapshot.docs.length - 1]

  return hasLimit ? {
    data: data,
    lastDocument: lastDocument
  } : data
}

function buildDynamicFilter(collectionRef, whereArr = [], searchObj) {

  if (!whereArr.length && !searchObj) return collectionRef

  let q = collectionRef

  const conditionsWhere = whereArr.map(condition => {
    return where(condition.field, condition.operator, condition.value)
  })

  let conditionsFilter = null
  if (searchObj) {
    conditionsFilter = and(
      where(searchObj.field, ">=", searchObj.searchText),
      where(searchObj.field, "<=", searchObj.searchText + "\uf8ff")
    )
  }

  let filters = null

  if (conditionsWhere.length && conditionsFilter) {
    filters = or(...conditionsWhere, conditionsFilter)
  } else if (conditionsWhere.length) {
    filters = conditionsWhere.length > 1 ? and(...conditionsWhere) : conditionsWhere[0]
  } else if (conditionsFilter) {
    filters = conditionsFilter
  }

  return query(q, filters)

}
