import styled from "styled-components"
import { defaultTheme } from "../../../../../../../../styles/themes/default"
import { Feather, EvilIcons } from '@expo/vector-icons'

export const Container = styled.div`
  background-color: ${defaultTheme["white"]};
  border-radius: 10px;
  height: auto;
  overflow: hidden;
  padding: 10px;
  position: relative;
  width: 50%;
`

export const Header = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 70px;
  margin-left: 10px;
`

export const Title = styled.span`
  color: ${defaultTheme["gray-700"]};
  font-size: 20px;
  font-weight: 500;
`

export const Text = styled.p`
  color: ${defaultTheme["gray-600"]};
  font-size: 14px;
`

export const ModalContent = styled.div`
  align-items: center;
  display: flex;
  height: 200px;
  justify-content: space-evenly;
  width: 100%;
`

export const ImageContainer = styled.div`
  align-items: center;
  background-color: ${defaultTheme["gray-100"]};
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 200px;

  &:hover::after {
    background-color: ${defaultTheme["white"]};
    content: '';
    height: 100%;
    left: 0;
    opacity: 0.5;
    position: absolute;
    top: 0;
    transition: opacity 0.5s ease;
    width: 100%;

    .trash-icon {
      opacity: 1;
    }
  }

  &:hover .trash-icon {
    opacity: 1;
  }
`

export const RecordImage = styled.img`
  aspect-ratio: 1;
  height: 100%;
  object-fit: contain;
  transition: opacity 0.5s ease;
  width: 100%;
`

export const ImageIcon = styled(EvilIcons).attrs({
  color: defaultTheme["gray-400"],
  name: "image",
  size: 100,
})``

export const DeleteContainer = styled.div`
  align-items: center;
  background-color: ${defaultTheme["white"]}; 
  border-radius: 50%;
  color: black;
  display: flex;
  inset: 60px;
  justify-content: center;
  opacity: 0; 
  padding: 5px;
  position: absolute;
  transition: opacity 0.3s ease;
  z-index: 2; 
`

export const DeleteIcon = styled(Feather).attrs({
  color: defaultTheme["red-500"],
  name: "trash-2",
  size: 36,
})``

interface FileSelectionContainerProps {
  isDragging: boolean
}

export const FileSelectionContainer = styled.div<FileSelectionContainerProps>`
  align-items: center;
  background-color: ${({ isDragging }) => isDragging ? defaultTheme["gray-200"] : defaultTheme["white"]};
  border: 1.5px dashed ${defaultTheme["gray-300"]};
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  justify-content: center;
  position: relative;
  transition: background-color 0.3s ease;
  width: 55%;
`

export const FileInput = styled.input`
  display: none;
`

export const InputLabel = styled.label`
  background-color: ${defaultTheme["white"]};
  border-radius: 5px;
  box-shadow: 2px 0 5px #00000029;
  color: ${defaultTheme["orange-500"]};
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  padding: 5px 10px;

  &:hover {
    background-color: ${defaultTheme["orange-500"]};
    color: ${defaultTheme["white"]};
    transition: background-color 0.3s ease, color 0.3s ease;
  }
`

export const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 40px;
  height: 50px;
  justify-content: center;
  margin-top: 20px;
  padding-right: 20px;
  width: 100%;
`

const BaseButton = styled.button`
  width: 110px;
  height: 35px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  box-shadow: 2px 0 5px ${defaultTheme["shadow"]};
  transition: background-color 0.5s ease, color 0.5s ease;
`

export const SaveButton = styled(BaseButton)`
  background-color: ${defaultTheme["cyan-300"]};
  color: ${defaultTheme["white"]};

  &:hover {
    background-color: ${defaultTheme["cyan-500"]};
  }
`

export const CancelButton = styled(BaseButton)`
  background-color: ${defaultTheme["gray-200"]};
  color: ${defaultTheme["gray-700"]};

  &:hover {
    background-color: ${defaultTheme["gray-500"]};
    color: ${defaultTheme["white"]};
  }
`
