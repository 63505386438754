import { removeAdditionalFilters, updateShippingValue } from "../../../../redux/redux-reducers/order-reducer"
import showAlert from "../../../../services/showAlert"

const handleConfirm = async ({ controller, screenProps, dispatch, setIsLoading }) => {
  screenProps.name !== "Order Summary" && setIsLoading(true)
  dispatch(
    updateShippingValue(
      {
        value: controller.models().T_MOV_HDR.SHIPPINGVALUE,
        type: controller.models().T_MOV_HDR.SHIPPINGTYPE?.VALUE
      })
  )
  if (screenProps.collectionName === "T_REC_ITE") {
    if (controller.addedProducts.length === 0) {
      setIsLoading(false)
      return showAlert({
        text: "Add at least one item to advance",
        title: "No products registered",
        titleType: "warning"
      })
    }
    controller.models().T_MOV_ITE = controller.addedProducts
    dispatch(removeAdditionalFilters())
    await controller.startChangeScreen(null, setIsLoading)
  } else await controller.startChangeScreen(null, setIsLoading)
}

export default handleConfirm
