import { Ionicons } from "@expo/vector-icons"
import styled from "styled-components"
import { defaultTheme } from "../../styles/themes/default"

export const Container = styled.div`
  background-color: ${defaultTheme["gray-200"]};
  display: flex;
  flex: 1;
  position: relative;
  flex-direction: row;
`

export const Icon = styled(Ionicons).attrs({ name: "information-circle-outline" })`
  color: ${defaultTheme["cyan-800"]};
  font-size: 16px;
`

export const TextView = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 100%;
  gap: 2px;
  `

export const TextViewContent = styled.div`
    display: flex;
    flex-direction: "row";
    align-items: "center";
  `

export const Text = styled.p`
  color: ${defaultTheme["cyan-800"]};
  font-size: 14px;
`

export const Tab = styled.div`
  background-color: ${defaultTheme["gray-200"]};
  width: 100%;
  height: auto;
  padding: 10px;
  gap: 5px;
  justify-content: center;
  border-top: 1px solid ${defaultTheme["gray-300"]};
`

export const ContentButton = styled.button`
  background-color: ${defaultTheme["cyan-300"]};
  margin: 5px;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  padding: 6px 20px;
  border-radius: 6px;
  box-shadow: 0px 2px 4px ${defaultTheme["shadow"]};
`
