import { Feather } from "@expo/vector-icons"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import LauncherComposerController from "../../../controllers/LauncherComposerController"
import Permissions from "../../../models/permissions"
import formValidation from "../../../services/formValidation"
import showAlert from "../../../services/showAlert"
import { defaultTheme } from "../../../styles/themes/default"
import DynamicCard from "../../components/cards/DynamicCard"
import Form from "../../components/Form"
import HeaderComposer from "../../components/HeaderComposer"
import List from "../../components/List"
import OrderSideBar from "../OrderComposerScreen/OrderSideBar"
import LoadingModal from "../OrderComposerScreen/OrderSideBar/LoadingModal"
import * as S from "./styles"
import handleSave from "./utils/handleSave"

export default function LauncherComposerScreen({ route, navigation }) {

  const { t } = useTranslation()
  const screenProps = route.params

  const [controller] = useState(route.params.controller)
  const [isLoading, setIsLoading] = useState(false)
  const [updateForm, setUpdateForm] = useState(false)
  const [applyFilters, setApplyFilters] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [update, setUpdate] = useState(false)

  controller.navigation = navigation
  const masterEntity = controller.OrderComposerController.masterEntity

  const showConfirmButton = screenProps.collectionName.includes("TAB_") && !controller.isLastTab

  const hasPermissionToEdit = Permissions.getPermission("UPDATE")

  const handleConfirm = async () => {
    setIsLoading(true)
    if (formValidation({
      entity: masterEntity,
      models: controller.models(),
      fields: controller.formFields
    })) {
      await controller.startChangeScreen(null, setIsLoading)
    } else setIsLoading(false)
  }

  const handleNewDocument = async () => {
    showAlert({
      type: "confirm",
      title: "Do you want to start a new document?",
      text: "All unsaved data will be lost.",
      titleType: "question",
      handleConfirm: async () => {
        setIsLoading(true)
        controller.models()[masterEntity].reset()
        const tabName = controller.OrderComposerController.tabName
        const setScreens = controller.OrderComposerController.setScreens

        const launcherController = new LauncherComposerController()

        launcherController.setScreens = setScreens
        launcherController.navigation = navigation

        if (controller.detailTabPath) {
          const detailTabPathParts = controller.detailTabPath.split("/")
          const masterTableEntity = detailTabPathParts[0]
          const masterId = detailTabPathParts[1]

          launcherController.models()[masterTableEntity].ID = masterId
        }

        await launcherController.initialize({
          entity: masterEntity,
          tabName: tabName,
          detailTabPath: controller?.detailTabPath
        })
        launcherController.resetNavigation({
          initialParams: launcherController.initialScreenParams
        })
        setIsLoading(false)
      }
    })
  }

  const getFields = () => {
    if (hasPermissionToEdit || !controller.models()[masterEntity]?.ID) return controller.formFields
    return controller.formFields.map(field => {
      const updatedField = {
        ...field,
        T_DTD_ATR: { ...field.T_DTD_ATR }
      }
      updatedField.T_DTD_ATR.EDITABLE = false
      return updatedField
    })
  }

  if (isLoading) return <LoadingModal isLoading={isLoading} />
  return (
    <S.Container>
      <HeaderComposer
        masterEntity={masterEntity}
        screenProps={screenProps}
        controller={controller}
        navigation={navigation}
        searchText={searchText}
        setSearchText={setSearchText}
        setIsLoading={setIsLoading}
        handleFinalize={() => handleSave({
          masterEntity,
          models: controller.models(),
          fields: controller.formFields,
          setIsLoading,
          handleNewDocument,
          detailTabPath: controller.detailTabPath,
          setUpdateForm
        })}
        handleNewDocument={handleNewDocument}
      />

      <S.Content>
        <S.ButtonsContainer style={{ justifyContent: "flex-end" }}>
          {showConfirmButton && <S.ConfirmButton onClick={handleConfirm}>
            <S.IconContainer>
              <Feather name="check" size={16} color={defaultTheme["cyan-700"]} />
            </S.IconContainer>
            {t("Confirm")}
          </S.ConfirmButton>}
        </S.ButtonsContainer>

        {screenProps.collectionName.includes("TAB_") ?
          <S.FieldsView>
            <Form
              entity={masterEntity}
              models={controller.models()}
              staticFields={getFields()}
              hideTabs={true}
              setUpdateForm={setUpdateForm}
              updateForm={updateForm}
            />
          </S.FieldsView>
          :
          <S.ListContainer style={{ paddingTop: showConfirmButton ? 40 : 0 }}>
            <List
              controller={controller}
              changeScreen={async i => {
                setIsLoading(true)
                await controller.startChangeScreen(i, setIsLoading)
              }}
              Card={DynamicCard}
              searchText={searchText}
              applyFilters={applyFilters}
              update={update}
            />
          </S.ListContainer>
        }
      </S.Content>

      <OrderSideBar
        masterEntity={masterEntity}
        controller={controller}
        jumpTo={screenName => {
          //TODO
          navigation.navigate(screenName)
        }}
      />

    </S.Container>
  )
}
