import React from "react"
import { useTranslation } from "react-i18next"
import * as S from "./styles"
import generateChildLines from "./utils/generateChildLines"
import handlePress from "./utils/handlePress"

export default function HierarchicalRow(props) {
  const { t } = useTranslation()

  const { rowData, selectedItem, viewType, isTheSameEntity = false } = props

  const { ID, DESCRIPTION, ANALYTICAL } = rowData

  const shouldntRenderAnalyticalItem = ANALYTICAL && viewType === "readOnly" && isTheSameEntity

  if (shouldntRenderAnalyticalItem) return

  const isSelected = ID === selectedItem?.ID

  return (
    <S.Container>
      <S.Row {...props} onPress={() => handlePress(props)}>
        <S.Icon {...props} isSelected={isSelected} />

        <S.Text isSelected={isSelected}>{`${ID} - ${t(DESCRIPTION)}`}</S.Text>
      </S.Row>

      {Number(ID) > 0 && generateChildLines(props)}
    </S.Container>
  )
}
