const getPadronizedData = ({ records = [], models, entity }) => {

  const formattedData = records.map(record => {
    const updatedRecord = {}

    for (const key in record) {
      updatedRecord[key] = getValue(record, key)
      updatedRecord.ALTID = record.ID
    }

    models._REGISTRATION_DATA[entity].formattedRecords[record.ID] = { ...record }

    return updatedRecord
  })

  return transformData(formattedData)
}
function getValue(record, key) {
  const value = record[key]
  if (key === "MASTERID") return value.ID

  if (typeof value === "object")
    return value?.[0]?.URL ?? value?.LABEL ?? ""
  return value
}

function transformData(data) {
  const map = {}
  const roots = []

  // Create a map of all items by ID
  data.forEach(item => {
    if (item.ID !== "0")
      map[item.ID] = {
        ...item,
        subtasks: []
      }
  })

  // Populate subtasks and roots
  data.forEach(item => {
    if (item.MASTERID && map[item.MASTERID]) {
      map[item.MASTERID].subtasks.push(map[item.ID])
    } else if (map[item.ID]) {
      roots.push(map[item.ID])
    }
  })

  return roots
}

export default getPadronizedData
