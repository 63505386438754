import { useEffect, useState } from "react"

export default function useSetChart(data: (string | number)[][], isVisible: boolean) {

  const [actualValue, setActualValue] = useState(6)

  useEffect(() => {
    setActualValue(
      !data
        ? 1
        : data.length - 1 > 6
          ? 6
          : data.length - 1
    )
  }, [isVisible])

  return {
    actualValue,
    setActualValue
  }
}
