import React from "react"
import { useDispatch } from "react-redux"
import useAppSelector from "../../../../redux/hooks/useAppSelector"
import { backInStack } from "../../../../redux/redux-reducers/dashboard-reducer"
import * as S from "./styles.web"

export default function ReturnButton() {

  const dispatch = useDispatch()
  const depthStack = useAppSelector(({ dashboardReducer }) => dashboardReducer.depthStack)

  return (
    <S.BtnType style={depthStack.length === 0 && { display: "none" }}>
      <S.ReturnBtn onPress={() => dispatch(backInStack())}>
        <S.ReturnIcon name="arrowleft" />
      </S.ReturnBtn>
    </S.BtnType>
  )
}
