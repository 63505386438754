import getDoubleClickEvent from "../../../../utils/getDoubleClickEvent"

export default function processSelectedSerie(state, { payload }) {

  const { selectedSerie } = payload

  if (payload === "new") {
    state.dashboardData.selectedSerie = null
    state.modals.serieModal.mode = "new"
    state.modals.serieModal.isVisible = true
  } else {
    if (selectedSerie) {
      state.dashboardData.selectedSerie = selectedSerie
      getDoubleClickEvent(doubleClickEvent => {
        if (doubleClickEvent.isActive) {
          state.modals.serieModal.mode = "edit"
          state.modals.serieModal.isVisible = true
        }
      })
    }
  }
}
