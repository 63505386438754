import styled from 'styled-components'
import { defaultTheme } from '../../../../../../styles/themes/default'

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const Title = styled.p`
  color: ${defaultTheme["cyan-800"]};
  font-size: 0.75rem;
  font-weight: 600;
  margin-left: 10px;
`

export const Input = styled.input`
  border: none;
  color: ${defaultTheme["gray-500"]};
  font-size: 0.875rem;
  font-weight: 500;
  background-color: ${defaultTheme["white"]};
  border-radius: 16px;
  outline: none;
  width: 280px;
  height: 23px;
  padding: 0 10px;
  height: ${defaultTheme.formInput.height}px;

  ::placeholder {
    color: ${defaultTheme["gray-300"]};
  }
`

export const SelectionInput = styled.select`
  border: none;
  color: ${defaultTheme["gray-500"]};
  font-size: 0.875rem;
  font-weight: 500;
  background-color: ${defaultTheme["white"]};
  border-radius: 16px;
  outline: none;
  width: 280px;
  height: 23px;
  padding: 0 10px;
  height: ${defaultTheme.formInput.height}px;

  ::placeholder {
    color: ${defaultTheme["gray-300"]};
  }
`