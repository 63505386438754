export default ({ fields }) => {
  return fields.reduce((acc, field) => {
    const { ID, FIELDTYPE, T_DTD_ATR } = field

    acc[ID] = {
      FIELDTYPE,
      ...T_DTD_ATR
    }

    return acc
  }, {})
}
