import RuntimeConfiguration from "../../../../../../models/runtimeConfiguration"
import showAlert from "../../../../../../services/showAlert"
import getDoubleClickEvent from "../../../../../../utils/getDoubleClickEvent"

export default (fieldData, models, setIsEdit, openModal) => {
  models.T_DTD_FLD.populate({ ...fieldData })

  getDoubleClickEvent(doubleClickEvent => {
    if (doubleClickEvent.isActive) {
      if (!fieldData.ID.includes("Z_") && !RuntimeConfiguration.getIsDeveloperConfigurationMode())
        return showAlert({
          text: "It is not possible to edit a native field",
          title: "Failed to edit field",
          titleType: "error"
        })

      doubleClickEvent.isActive = false
      models.T_DTD_ATR.populate({
        ...fieldData.T_DTD_ATR,
        TAB: fieldData.T_DTD_ATR.TAB?.DESCRIPTION ?? ""
      })
      setIsEdit(true)
      openModal("attributes")
    }
  })
}
