import React, { useContext, useState } from "react"
import Observable from "../../../../../models/Observable"
import { GridModeContext } from "../../../ScreenFactory/contexts/GrideModeProvider"
import DynamicField from "./components/DynamicField"
import Container from "./styles"
import handleRowPress from "./utils/handleRowPress"

const selectionObserver = new Observable()

export default function TableRow(props) {
  const { viewType, fields, rowData, rowIndex } = props

  const changeMode = viewType === "registration" ? useContext(GridModeContext).changeMode : () => null

  const [isSelected, setIsSelected] = useState(false)

  const subscriber = () => {
    setIsSelected(false)
  }

  const updateObserver = () => {
    selectionObserver.notify()
    selectionObserver.unsubscribeAll()
    selectionObserver.subscribe(subscriber)
  }

  const handlePress = () => {
    handleRowPress({
      props,
      callback: changeMode
    })
    updateObserver()
    setIsSelected(true)
  }

  return (
    <Container onPress={handlePress}>
      {fields.map(field => (
        <DynamicField
          key={field.ID}
          fieldData={field}
          rowData={rowData}
          rowIndex={rowIndex}
          isSelected={isSelected}
        />
      ))}
    </Container>
  )
}
