import showAlert from "../../../../../../../services/showAlert"

export default async ({ fields, tabs, closeModal, controller }) => {
  if (tabs.length === 0) return closeModal("orderTabs")

  showAlert({
    text: "Do you want to save?",
    type: "confirm",
    title: "Tabs ordering",
    handleConfirm: async () => {
      await controller.saveTabs({
        fields,
        tabs
      })

      closeModal("orderTabs")

      showAlert({
        text: "Data saved successfully",
        title: "Tabs ordering",
        titleType: "success"
      })
    }
  })
}
