import { GridComponent } from "@syncfusion/ej2-react-grids"
import LoginData from "../../../../../../models/loginData"
import dbRequest from "../../../../../../services/dbRequest/dbRequest"
import showAlert from "../../../../../../services/showAlert"
import setDefaultSettings from "./setDefaultSettings"

interface IHandleDelete {
  entity: string
  gridRef: React.RefObject<GridComponent> | null
  handleRefresh: () => void
  models: any
  selectedSetting: string
  setSelectedSetting: any
  t: any
  settings: any
  setUpdate: any
}

const handleDelete = async ({
  entity,
  gridRef,
  handleRefresh,
  models,
  selectedSetting,
  setSelectedSetting,
  t,
  settings,
  setUpdate
}: IHandleDelete) => {
  showAlert({
    type: "confirm",
    titleType: "question",
    title: "Do you want to delete setting?",
    text: `${t("Selected setting")}: ${selectedSetting}`,
    handleConfirm: () => {
      try {
        const newSettings = settings.filter(s => s.DESCRIPTION !== selectedSetting)
        dbRequest
          .saveRecords(`T_DTD_TAB/${entity}/T_DTD_FLT`)
          .record({
            ID: LoginData.getLoginData().ID,
            CUSTOMSETTINGS: newSettings
          })
          .execute()
          .then(() => {
            setSelectedSetting("Default")
            setDefaultSettings({
              models,
              entity,
              gridRef,
              handleRefresh
            })
            setUpdate(s => !s)
          })
      } catch (error) {
        showAlert({
          titleType: "error",
          text: error,
          title: "Failed to delete"
        })
      }

    }
  })
}

export default handleDelete
