import React from "react"
import Text from "./styles"

export default function Integer({ fieldValue, isSelected }) {

  return (
    <Text isSelected={isSelected}>
      {fieldValue}
    </Text>
  )

}
