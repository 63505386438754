import showAlert from '../../../../../../../services/showAlert'

const tableModeValidation = (props) => {
  const { models, entityName, changeMode } = props

  const isIDFieldFilled = Boolean(models[entityName].ID)

  const tableData = models._REGISTRATION_DATA[entityName]

  if (isIDFieldFilled) {
    tableData.operation = "UPD"
    tableData.originalRecord = { ...models[entityName] }
    changeMode()
  }
  else
    showAlert({
      text: "No record selected",
      title: "Failed to open form",
      titleType: "error"
    })
}

export default tableModeValidation