import styled from "rn-css"

export const Container = styled.View`
  background-color: #fff;

  width: 200px;
  padding: 8px;

  border-radius: 4;

  cursor: grab;

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
`

export const Text = styled.Text`
  font-size: 12px;

  color: #2e2e2e;
`
