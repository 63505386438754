import dbRequest from "../../../../../../../../../../../../../../services/dbRequest/dbRequest"

export default async ID => {
  const tables = await dbRequest
    .loadRecords("T_DTD_TAB")
    .where("MASTERTAB.ID", "==", ID)
    .execute()

  const functionsPromises = []

  for (const table of tables) {
    const promise = dbRequest.loadRecords(`T_DTD_TAB/${table.ID}/T_DTD_FNC`).execute()
    functionsPromises.push(promise)
  }

  const functions = await Promise.all(functionsPromises)

  tables.forEach((item, index) => {
    item.T_DTD_FNC = functions[index] ?? []
  })

  return tables
}
