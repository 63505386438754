import { Fontisto, Feather } from "@expo/vector-icons"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import dbRequest from "../../../../../services/dbRequest/dbRequest"
import { defaultTheme } from "../../../../../styles/themes/default"
import CloseButton from "../../../CloseButton/"
import getCustomSettings from "../../../Grid/utils/getCustomSettings"
import ModalWrapper from "../../../Modal"
import * as S from "./styles"
import applySettings from "./utils/applySettings"
import handleDelete from "./utils/handleDelete"
import handleSave from "./utils/handleSave"
import setDefaultSettings from "./utils/setDefaultSettings"

export default function CustomSettings({ entity, gridRef, handleRefresh, models }) {

  const { t } = useTranslation()

  const [settings, setSettings] = useState([])
  const [selectedSetting, setSelectedSetting] = useState("Default")
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [description, setDescription] = useState("")
  const [update, setUpdate] = useState(false)

  useEffect(() => {
    getCustomSettings({ entity }).then(res => setSettings(res?.[0]?.CUSTOMSETTINGS ?? []))
  }, [update])

  const handleChange = async (event) => {
    setSelectedSetting(event.target.value)
    const setting = settings.find(s => s.DESCRIPTION === event.target.value)

    if (setting) {
      const savedProperties = JSON.parse(setting.PROPERTIES)

      const fields = models._REGISTRATION_DATA[entity].fields
      models._REGISTRATION_DATA[entity].savedProperties = savedProperties

      applySettings({
        settings: savedProperties,
        fields,
        gridRef
      })
    } else {
      setDefaultSettings({
        models,
        entity,
        gridRef,
        handleRefresh
      })
    }
  }

  return (
    <S.Container>
      <S.Title>{t("Settings")}:</S.Title>
      <S.Input onChange={handleChange} value={selectedSetting}>
        <S.Label>{t("Default")}</S.Label>
        {settings.map((setting, index) => (
          <S.Label key={index}>{setting.DESCRIPTION}</S.Label>
        ))}
      </S.Input>

      <S.ActionButton title={t("Save Settings")} onClick={() => {
        handleSave({
          selectedSetting,
          description,
          settings,
          entity,
          dbRequest,
          models,
          gridRef,
          isModalVisible,
          setDescription,
          setSelectedSetting,
          setIsModalVisible,
          setUpdate
        })
      }}>
        <Fontisto name="save" size={10} color={defaultTheme["white"]} />
      </S.ActionButton>

      <S.ActionButton
        title={t("Delete Settings")}
        onClick={() => handleDelete({
          entity,
          gridRef,
          handleRefresh,
          models,
          selectedSetting,
          setSelectedSetting,
          t,
          settings,
          setUpdate
        })}
        disabled={selectedSetting === "Default"}
        style={{ backgroundColor: defaultTheme[selectedSetting === "Default" ? "gray-300" : "red-400"] }}>
        <Feather name="trash-2" size={14} color={defaultTheme["white"]} />
      </S.ActionButton>

      {isModalVisible &&
        <ModalWrapper isVisible={isModalVisible}>
          <S.ModalContainer>
            <CloseButton callbackFunction={() => {
              setDescription("")
              setIsModalVisible(false)
            }} />

            <S.ModalTitle>{t("New Setting")}</S.ModalTitle>

            <S.InputContainer>
              <S.InputTitle>{t("Description")}</S.InputTitle>
              <S.InputDescription
                value={description}
                onChange={event => setDescription(event.target.value)}
                placeholder={t("Setting description")}
              />
            </S.InputContainer>

            <S.ButtonsContainer>
              <S.Button onClick={() => handleSave({
                selectedSetting,
                description,
                settings,
                entity,
                dbRequest,
                models,
                gridRef,
                isModalVisible,
                setDescription,
                setSelectedSetting,
                setIsModalVisible,
                setUpdate,
                handleRefresh
              })}>
                {t("Confirm")}
              </S.Button>

              <S.Button onClick={() => setIsModalVisible(false)} style={{ backgroundColor: defaultTheme["red-400"] }}>
                {t("Cancel")}
              </S.Button>
            </S.ButtonsContainer>

          </S.ModalContainer>
        </ModalWrapper>}
    </S.Container>
  )
}

