import styled from "styled-components"
import { defaultTheme } from "../../../../../../styles/themes/default"

export const Container = styled.div``

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

interface TitleProps {
  isRequired: boolean
}

export const Title = styled.p<TitleProps>`
  color: ${defaultTheme["cyan-800"]};
  font-size: 14px;
  font-weight: ${({ isRequired }) => isRequired ? "bold" : "normal"};
  line-height: 20px;

  &::after {
    content: "*";
    color: ${defaultTheme["cyan-800"]};
    display: ${({ isRequired }) => isRequired ? "inline" : "none"};
  }
`

interface InputProps {
  isEditable: boolean
}

export const Input = styled.input<InputProps>`
  background-color: ${({ isEditable }) => defaultTheme[isEditable ? "white" : "gray-200"]};
  color: ${defaultTheme["gray-700"]};
  font-size: ${defaultTheme.formInput.fontSize}px;
  border: 1px solid ${defaultTheme["gray-300"]};
  border-radius: 10px;
  padding: 0 10px;
  height: ${defaultTheme.formInput.height}px;
  outline: none;

  ::placeholder {
    color: ${defaultTheme["gray-300"]};
  }
`

export const Text = styled.p`
  color: ${defaultTheme["cyan-700"]};
  font-size: 14px;
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 4px;
`