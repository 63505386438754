import { useEffect, useState } from "react"
import fetchTableData from "../utils/fetchTableData"

export default function useTable(props) {
  const { updateTable } = props

  const [tableData, setTableData] = useState({})
  const [isFetchingTableData, setIsFetchingTableData] = useState(false)

  useEffect(() => {
    setIsFetchingTableData(true)
    fetchTableData(props)
      .then(data => {
        setTableData(data)
        setIsFetchingTableData(false)
      })
  }, [updateTable])

  return {
    isFetchingTableData,
    tableData,
    setTableData
  }
}
