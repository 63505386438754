import { FontAwesome } from "@expo/vector-icons"
import styled from "styled-components"
import { defaultTheme } from "../../../../../../../../../../../styles/themes/default"

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 70px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 16px 20px 30px;
  background-color: ${defaultTheme["gray-200"]};
`

export const Title = styled.h2`
  font-size: 20px;
  color: ${defaultTheme["gray-700"]};
  font-weight: 600;
`

export const SearchContainer = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const SearchInput = styled.input`
  background-color: ${defaultTheme["gray-100"]};
  color: ${defaultTheme["gray-900"]};

  width: 200px;
  padding: 0 4px;

  border-bottom: 1px solid ${defaultTheme["gray-400"]};
  outline-style: none;

  font-size: 14px;
`

export const SearchIcon = styled(FontAwesome)`
  color: ${defaultTheme["gray-900"]};
  font-size: 16px;
`

export const ButtonsView = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
`

export const SaveText = styled.span`
  font-size: 14px;
  color: ${defaultTheme["white"]};
`

export const CloseButton = styled.div`
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const LoadingContainer = styled.div`
  align-items: center;
  align-self: center;
  background-color: ${defaultTheme["white"]};
  border-radius: 6px;
  color: ${defaultTheme["gray-900"]};
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 500;
  gap: 10px;
  height: 16%;
  justify-content: center;
  width: 16%;
`

export const SaveButtonContainer = styled.div`
  align-items: center;
  background-color: ${defaultTheme["gray-100"]};
  border-radius: 14px;
  cursor: pointer;
  display: flex;
  gap: 6px;
  justify-content: center;
  padding: 3px 10px 3px 3px;
  transition: opacity 0.2s;

  &:active {
    opacity: 0.3;
  }

  &:hover {
    box-shadow: 0px 4px 8px #00000019;
  }

  span {
    color: ${defaultTheme["gray-500"]};
    font-size: 0.875rem;
  }
`

export const SaveButton = styled.button`
  align-items: center;
  background-color: ${({ variant, disabled }) => {
    if (disabled) return defaultTheme["gray-300"]
    return defaultTheme[variant === "white" ? "white" : "cyan-300"]
  }};
  border-radius: 50%;  
  display: flex;
  height: 26px;
  justify-content: center;
  width: 26px;


  transition: opacity 0.2s;

  &:active {
    opacity: 0.3;
  }
`
