import { createAsyncThunk } from '@reduxjs/toolkit'
import showAlert from '../../../../services/showAlert'
import dbRequest from '../../../../services/dbRequest/dbRequest'
import RuntimeConfiguration from '../../../../models/runtimeConfiguration'

export const deleteQuery = createAsyncThunk(
  "dashboardBuilder/deleteQuery",
  async ({selectedQuery, queryPath}, thunkAPI) => {

    const isAdminMode = RuntimeConfiguration.getDashboardBuilderAdminMode()

    if (!selectedQuery?.ID) {
      return thunkAPI.rejectWithValue(showAlert({
        title: "No query were selected.",
        titleType: "warning",
        text: "Please select the query to be deleted."
      }))
    }

    try {
      const response = await new Promise((resolve, reject) => {
        showAlert({
          title: "This action cannot be undone.",
          titleType: "warning",
          text: "Do you want to delete the query?",
          type: "confirm",
          handleConfirm: async () => {
            try {
              const request = dbRequest.deleteRecords(queryPath).record(selectedQuery)
              
              if(isAdminMode) request = request.admin()
              
              await request.execute(() => {
                showAlert({
                  title: "Success",
                  text: "Query deleted successfully.",
                  titleType: "success",
                })
                resolve({deletedQuery: selectedQuery})
              })
            } catch (error) {
              reject(error)
            }
          }
        })
      })

      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(showAlert({
        title: "Error when trying to delete query.",
        titleType: "error",
        text: "Please try again.",
      }))
    }
  }
)