import React from "react"
import { Platform } from "react-native"
import getFieldToRender from "./utils/getFieldToRender"
import screenBuilderValidation from "./utils/screenBuilderValidation"

export default function DynamicField({ field, models, updateForm, setUpdateForm }) {
  const { T_DTD_ATR, FIELDTYPE } = field

  const isFieldVisible = T_DTD_ATR.VISIBLE
  if (!isFieldVisible) return

  const shouldRenderField = screenBuilderValidation(models, field)
  if (!shouldRenderField) return

  const fieldProps = {
    field,
    models,
    setUpdateForm,
    updateForm,
    width: sizeConvert(field)
  }

  const FieldComponent = getFieldToRender(field)

  if (FieldComponent) return <FieldComponent {...fieldProps} />

  console.log(`Invalid FIELDTYPE for ${T_DTD_ATR.FIELDDESCRIPTION}:`, FIELDTYPE.VALUE)
  return <></>
}

function sizeConvert({ T_DTD_ATR, FIELDTYPE }) {
  if (!["S", "M"].includes(FIELDTYPE.VALUE) || !T_DTD_ATR.SIZE) return 160

  switch (T_DTD_ATR.SIZE.VALUE) {
    case "M": return 300
    case "L": return Platform.OS === "web" ? 800 : 300
    default: return 160
  }
}
