export default function getIconName(metadata) {
  const [fileExtension] = metadata.toLowerCase().match(/\.\w+$/)

  switch (fileExtension) {
    case ".csv": return "file-csv"

    case ".pdf": return "file-pdf"

    case ".doc":
    case ".txt": return "file-text"

    case ".xml":
    case ".xls":
    case ".xlsx": return "file-excel"

    case ".jpeg":
    case ".jpg":
    case ".png":
    case ".svg": return "file-image"

    default: return null
  }
}
