import showAlert from "../../../../../../../services/showAlert"
import defaultValueValidation from "./defaultValueValidation"

export default (models, setUpdateTable, resetModel) => {
  const { VALUE, LABEL, DEFAULTVALUE } = models.T_DTD_OPT

  if (!VALUE) return showAlert({
    text: "Invalid option value",
    title: "Failed to save option",
    titleType: "error"
  })
  if (!LABEL) return showAlert({
    text: "Invalid option label",
    title: "Failed to save option",
    titleType: "error"
  })

  if (!models.T_DTD_FLD.T_DTD_OPT) models.T_DTD_FLD.T_DTD_OPT = {}

  if (!DEFAULTVALUE) {
    models.T_DTD_FLD.T_DTD_OPT[VALUE] = { LABEL }
    setUpdateTable(s => !s)
  }

  else {
    const hasDefaulValue = defaultValueValidation(models)

    if (hasDefaulValue) return showAlert({
      text: "Only one option can be default",
      title: "Failed to save option",
      titleType: "error"
    })

    models.T_DTD_FLD.T_DTD_OPT[VALUE] = {
      LABEL,
      DEFAULTVALUE: true
    }

    setUpdateTable(s => !s)
  }

  resetModel()
}
