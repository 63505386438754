import { Platform } from "react-native"
import dbRequest from "../../../../../../../../services/dbRequest/dbRequest"
import detailTabHasRecords from "../../../../../../../../services/registration/detailTabHasRecords"
import foreignKeyValidation from "../../../../../../../../services/registration/foreignKeyValidation"
import runTriggers from "../../../../../../../../services/registration/runTriggers"
import showAlert from "../../../../../../../../services/showAlert"

const handleDelete = async ({ gridRef, models, entity, closeModal, modalVisible, detailTabPath, setUpdateTable, setIsLoading, changeLoadingModalVisibility = (s) => !s }) => {

  const tableData = models._REGISTRATION_DATA[entity]

  if (modalVisible) {
    return tableData.operation === "INS"
      ? showAlert({
        text: "No record selected",
        title: "Failed to delete",
        titleType: "error"
      })
      : showAlert({
        text: "Do you want to delete the current record?",
        type: "confirm",
        titleType: "question",
        handleConfirm: async () => {
          await deleteRecord({
            models,
            entity,
            records: [{ ...models[entity] }],
            detailTabPath,
            changeLoadingModalVisibility
          })
          closeModal()
        }
      })
  }

  const selectedRecords = Platform.OS === "web" ? gridRef.current.getSelectedRecords() : [{ ...models[entity] }]

  if (selectedRecords.length === 0)
    return showAlert({
      text: "No record selected",
      title: "Failed to delete",
      titleType: "error"
    })

  showAlert({
    text: "Do you want to delete the selected record(s)?",
    type: "confirm",
    titleType: "question",
    handleConfirm: async () => {
      Platform.OS !== "web" && setIsLoading(true)
      await deleteRecord({
        models,
        entity,
        records: selectedRecords,
        detailTabPath,
        changeLoadingModalVisibility
      }).then((isDeletedRecords) => {
        Platform.OS !== "web" && setIsLoading(false)

        Platform.OS === "web" ?
          isDeletedRecords ? gridRef.current.deleteRecord() : null
          : setUpdateTable(s => !s)
      })
    }
  })
}

export default handleDelete

async function deleteRecord({ models, entity, records, detailTabPath, changeLoadingModalVisibility }) {
  changeLoadingModalVisibility()
  const failedRecords = []

  const tableData = models._REGISTRATION_DATA[entity]

  tableData.operation = "DEL"

  for (const record of records) {
    tableData.state = "BEFORE"

    models[entity].reset()
    models[entity].populate(record)

    const isForeignKey = await foreignKeyValidation({
      entity,
      record
    })
    if (isForeignKey) {
      failedRecords.push({
        record,
        reason: "Foreign key constraint"
      })
      continue
    }

    const allTriggersValid = await runTriggers(models, entity)
    if (!allTriggersValid) {
      failedRecords.push({
        record,
        reason: "Triggers failed"
      })
      break
    }

    const hasDetailRecords = await detailTabHasRecords({
      entity,
      models
    })
    if (hasDetailRecords) {
      failedRecords.push({
        record,
        reason: "Detail records found"
      })
      continue
    }

    await dbRequest
      .deleteRecords(detailTabPath)
      .record(record)
      .execute()

    tableData.state = "AFTER"

    await runTriggers(models, entity)
  }

  const hasFailedRecords = failedRecords.length > 0

  if (hasFailedRecords) {
    const failureMessages = failedRecords
      .map(({ record, reason }) => `ID: ${record.ID} - Reason: ${reason}`)
      .join("\n")

    showAlert({
      text: failureMessages,
      title: `Failed to delete ${failedRecords.length} record(s)`,
      titleType: "warning"
    })

    changeLoadingModalVisibility()
    return false
  } else {
    showAlert({
      text: "All selected records have been deleted",
      title: "Record deletion",
      titleType: "success"
    })

    changeLoadingModalVisibility()
    return true
  }

}

