import LoginData from "../../../../../../models/loginData"

const addNativeFunctions = ({ functions, entity, permissions, mode }) => {
  const addFunction = ({ DESCRIPTION, EXPRESSION, SELECTLINE = false, ONLYFORM = false }) => {
    functions.push({
      ACTIVE: true,
      DESCRIPTION,
      EXPRESSION,
      ORDERBY: 1,
      SELECTLINE,
      TYPE: {
        LABEL: "Native",
        VALUE: "N"
      },
      ONLYFORM
    })
  }

  const { ADMIN, EXPORT_REPORTS } = LoginData.getLoginData()

  if (EXPORT_REPORTS)
    addFunction({
      DESCRIPTION: "Export to .xls extension",
      EXPRESSION: "fnc_exportSpreadsheet"
    })

  if (["T_SET_RLU", "T_SET_UGR"].includes(entity) && ADMIN)
    addFunction({
      DESCRIPTION: "Access configuration",
      EXPRESSION: "fnc_access",
      SELECTLINE: true
    })

  if (entity === "T_REC_PTN" && (ADMIN || permissions.CREATE) && mode !== "grid")
    addFunction({
      DESCRIPTION: "Import ReceitaWS data",
      EXPRESSION: "fnc_receitaWS",
      ONLYFORM: true
    })

  if (entity === "T_SET_TNS") {
    addFunction({
      DESCRIPTION: "Configure header",
      EXPRESSION: "T_MOV_HDR/T_SET_HDR",
      SELECTLINE: true
    })
    addFunction({
      DESCRIPTION: "Configure items",
      EXPRESSION: "T_MOV_ITE/T_SET_ITE",
      SELECTLINE: true
    })
  }
}

export default addNativeFunctions
