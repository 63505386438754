import dbRequest from "./dbRequest/dbRequest"

export default class EmailBuilder {

  constructor(padronizedData) {
    this.attachments = []
    this.emailTemplate = padronizedData
  }

  static addEmail(emailData) {

    const padronizedData = {
      INCLUSIONDATE: emailData.INCLUSIONDATE,
      STATUS: emailData.STATUS,
      SENDERID: emailData.SENDERID,
      EMAIL: emailData.EMAIL,
      SUBJECT: emailData.SUBJECT,
      MESSAGE: emailData.MESSAGE,
      ROUTINE: emailData.ROUTINE,
      ROUNTINEKEY: emailData.ROUNTINEKEY
    }

    return new EmailBuilder(padronizedData)
  }

  addAttachment(attachmentData) {
    const padronizedData = {
      NAME: attachmentData.NAME,
      ATTACHMENT: btoa(String.fromCharCode.apply(null, attachmentData.ATTACHMENT))
    }

    this.attachments.push(padronizedData)
    return this
  }

  async send() {
    const emailID = await this.saveEmail()
    this.saveAttachments(emailID)
  }

  async saveEmail() {
    return await dbRequest
      .saveRecords("T_TSK_MSG")
      .record({ ...this.emailTemplate })
      .execute()
  }

  async saveAttachments(emailID) {
    for (let i = 0; i < this.attachments.length; i++) {

      this.attachments[i].IDMSG = emailID

      await dbRequest
        .saveRecords("T_TSK_ATT")
        .record(this.attachments[i])
        .execute()
    }
  }
}
