import { MaterialCommunityIcons, Fontisto } from "@expo/vector-icons"
import React, { useState } from "react"
import AceEditor from "react-ace"
import { useTranslation } from "react-i18next"
import dbRequest from "../../../../../../../../../../../../../services/dbRequest/dbRequest"
import showAlert from "../../../../../../../../../../../../../services/showAlert"
import { defaultTheme } from "../../../../../../../../../../../../../styles/themes/default"
import ModalWrapper from "../../../../../../../../../../../Modal"
import * as S from "./styles"
import "ace-builds/src-noconflict/mode-javascript"
import "ace-builds/src-noconflict/snippets/javascript"
import "ace-builds/src-noconflict/theme-monokai"
import "ace-builds/src-noconflict/ext-language_tools"

export default function Modal({
  modalVisible,
  models,
  selectedField,
  setSelectedField,
  setModalVisible,
  setUpdateList,
  entity
}) {
  const { t } = useTranslation()
  const [expression, setExpression] = useState(selectedField.expression)

  const handleSaveClick = async () => {
    showAlert({
      title: "Save expression",
      titleType: "question",
      text: "Are you sure you want to save this expression?",
      type: "confirm",
      handleConfirm: async () => {
        await dbRequest
          .saveRecords(
            `T_DTD_TAB/${entity}/T_SET_HDR/${models.T_SET_TNS.ID}/T_DTD_FLD/${selectedField.id}/T_DTD_ATR/`
          )
          .record({
            ID: "EXPRESSION",
            VALUE: expression
          })
          .execute()
        setUpdateList((s) => !s)
        closeModal()
        showAlert({
          title: "Success",
          titleType: "success",
          text: "Expression saved successfully",
          type: "ok"
        })
      }
    })
  }

  const handleChange = (newValue) => {
    setExpression(newValue)
  }

  function closeModal() {
    setModalVisible(false)
    setExpression("")
    setSelectedField(null)
  }

  return (
    <ModalWrapper isVisible={modalVisible}>
      <S.ModalContainer>
        <S.HeaderContainer>

          <S.Title>
            {t("Default value of")} {t(selectedField.description)}
          </S.Title>
          <S.ButtonsView>
            <S.SaveButtonContainer
              onClick={handleSaveClick}>
              <S.SaveButton>
                <Fontisto name="save" size={12} color={defaultTheme["white"]} />
              </S.SaveButton>
              <span>{t("Save Changes")}</span>
            </S.SaveButtonContainer>
            <S.CloseButton onClick={closeModal}>
              <MaterialCommunityIcons name="close" color={defaultTheme["gray-700"]} size={22} />
            </S.CloseButton>
          </S.ButtonsView>
        </S.HeaderContainer>

        <AceEditor
          placeholder={t("Type your expression here")}
          mode={"javascript"}
          theme="monokai"
          name="expression"
          fontSize={14}
          lineHeight={21}
          id="textarea"
          value={expression}
          onChange={handleChange}
          setOptions={{
            enableLiveAutocompletion: true,
            enableSnippets: true,
            showLineNumbers: true
          }}
          style={{
            width: "100%",
            padding: "0px",
            height: "300px"
          }}
        ></AceEditor>
      </S.ModalContainer>
    </ModalWrapper>
  )
}
