import { FontAwesome } from "@expo/vector-icons"
import styled from "rn-css"
import globalStyles from "../../../../../../global/globalStyles"

export const Container = styled.View``

export const Row = styled.TouchableOpacity`
  flex-direction: row;

  padding-left: ${({ padding }) => padding}px;
  margin: 6px;

  gap: 10px;
`

export const Text = styled.Text`
  color: ${({ isSelected }) => isSelected ? globalStyles.colors.secondary : globalStyles.colors.primary};

  font-size: ${globalStyles.sizes.fontSize}px;
`
export const Icon = styled(FontAwesome).attrs(({ rowData }) => ({
  name: rowData.ANALYTICAL
    ? "file-text"
    : rowData.open
      ? "folder-open"
      : "folder"
}))`
  color: ${({ isSelected }) => isSelected ? globalStyles.colors.secondary : globalStyles.colors.primary};

  font-size: 20px;

  margin-left: 10px;
` 
