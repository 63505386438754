import styled from "rn-css"
import globalStyles from "../../../../../../../global/globalStyles"

export const Container = styled.View`
  background-color: ${globalStyles.colors.tertiary};

  flex: 3;
  justify-content: center;

  padding: 5px;
  gap: 3px;

  border: 1px solid lightgray;
  border-radius: 4px;
`

export const Row = styled.View`
  flex-direction: row;
`

export const Text = styled.Text`
  font-size: 14px;

  color: ${globalStyles.colors.primary};
`

export const BoldText = styled(Text)`
  font-weight: bold;
`
