import React from "react"
import Text from "./styles"

export default function Hours({ fieldValue, isSelected }) {
  const formattedTime = minutesToTime(fieldValue)

  return <Text isSelected={isSelected}>{formattedTime}</Text>
}

function minutesToTime(minutes) {
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60
  return `${String(hours).padStart(2, "0")}:${String(remainingMinutes).padStart(2, "0")}`
}
