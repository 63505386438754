import styled from "rn-css"
import globalStyles from "../../../global/globalStyles"

export const Container = styled.View`
  background-color: ${globalStyles.colors.background};
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  padding: 2px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`

export const Row = styled.View`
  flex-direction: row;
`
