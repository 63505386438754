import { AntDesign } from "@expo/vector-icons"
import React from "react"
import { useTranslation } from "react-i18next"
import { defaultTheme } from "../../../../../styles/themes/default"
import * as S from "./styles"

export default function SuggestionsList({ suggestions, onSelectSuggestion }) {

  const { t } = useTranslation()

  return (
    <S.SuggestionsWrapper>
      {suggestions.map((suggestion, index) => (
        <S.SuggestionItem key={index} onClick={() => {
          onSelectSuggestion(suggestion)
        }}>
          {t(suggestion.DESCRIPTION)}
          <AntDesign name="caretright" size={12} color={defaultTheme["gray-500"]} style={{
            position: "absolute",
            right: 10
          }} />
        </S.SuggestionItem>
      ))}
    </S.SuggestionsWrapper>
  )
}
