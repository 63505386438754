import styled from "styled-components"
import { defaultTheme } from "../../../../../../../styles/themes/default"

export const InformationContainer = styled.div`
  display: flex;
  height: 100%;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 5px;
  margin-top: 10px;
`

export const FieldContainer = styled.div`
  width: 100%;
  max-width: 250px;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  gap: 2px;
`

export const FieldLabel = styled.label`
  color: ${defaultTheme["cyan-700"]};
  font-weight: 500;
  font-size: 14px;
`

export const FieldValue = styled.p`
  background-color: ${defaultTheme["white"]};
  color: ${defaultTheme["gray-600"]};
  font-size: 14px;
  outline: none;
  padding-left: 3px;
`

export const EditButton = styled.button`
  position: absolute;
  right: 10px;
  top: 10px;
  width: 26px;
  height: 26px;
  border: none;
  border-radius: 6px;
  box-shadow: 3px 0 5px #00000029;
  background-color: ${defaultTheme["white"]};
  cursor: pointer;
  transition: background-color 0.4s ease;

  &:hover {
    background-color: ${defaultTheme["cyan-700"]};
    &::after {
      content: "Habilitar/Desabilitar edição";
      position: absolute;
      width: 120px;
      top: -20px;
      left: 50%;
      transform: translate(-50%, -50%);
      color: ${defaultTheme["gray-700"]};
      font-weight: 500;
      background-color: ${defaultTheme["white"]};
      padding: 5px 10px;
      box-shadow: 5px 0 5px #00000029;
      border-radius: 4px;
      font-size: 12px;
    }
  }
`

export const ResetPasswordButton = styled(EditButton)`
  right: 45px;

  &:hover {
    background-color: ${defaultTheme["red-400"]};
    &::after {
      content: "Redefinir senha";
      position: absolute;
      width: max-content;
      top: -20px;
      left: 50%;
      transform: translate(-50%, -50%);
      color: ${defaultTheme["gray-700"]};
      font-weight: 500;
      background-color: ${defaultTheme["white"]};
      padding: 5px 10px;
      box-shadow: 5px 0 5px #00000029;
      border-radius: 4px;
      font-size: 12px;
    }
  }
`

export const InputField = styled.input`
  font-size: 12px;
  color: ${defaultTheme["gray-700"]};
  padding: 5px 10px;
  border: 0.5px solid ${defaultTheme["gray-400"]};
  border-radius: 12px;
`
