import { Entypo } from "@expo/vector-icons"
import styled from "styled-components"
import { defaultTheme } from "../../../../../../styles/themes/default"

export const Container = styled.div`
  align-items: center;
  background-color: ${defaultTheme["white"]};
  border: 1px solid ${defaultTheme["gray-300"]};
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  height: ${defaultTheme.formInput.height}px;
  justify-content: center;
  padding: 0 8px;
`

export const Title = styled.p`
  color: ${defaultTheme["gray-700"]};
  font-size: 14px;
`

interface CheckboxProps {
  isActive: boolean
  isEditable?: boolean
}

export const Box = styled.button<CheckboxProps>`
  align-items: center;
  background-color: ${({ isActive }) => defaultTheme[isActive ? "cyan-800" : "white"]};
  border: 1px solid ${defaultTheme["gray-700"]};
  border-radius: 50%;
  border-width: ${({ isActive }) => isActive ? 0 : 1}px;
  display: flex;
  cursor: ${({ isEditable }) => isEditable ? 'pointer' : 'not-allowed'};
  height: 14px;
  justify-content: center;
  width: 14px;
`

export const Icon = styled(Entypo).attrs(({
  color: defaultTheme["white"],
  size: 10
}))<CheckboxProps>`
  display: ${({ isActive }) => isActive ? 'block' : 'none'};
`
