const handleSelect = ({
  fieldName,
  setSelectedFiles,
  setPreview,
  event
}) => {
  const files = [...event.target.files]

  if (files.length > 0) {
    const file = files[0]
    setSelectedFiles(prev => ({
      ...prev,
      [fieldName]: file
    }))
    const reader = new FileReader()
    reader.onloadend = () => setPreview(reader.result)
    reader.readAsDataURL(file)
  }

}

export default handleSelect
