const actionsType = [
  {
    VALUE: "",
    LABEL: "None"
  },
  {
    VALUE: "U",
    LABEL: "Update"
  },
  {
    VALUE: "E",
    LABEL: "Navigation"
  },
  {
    VALUE: "L",
    LABEL: "Level"
  }
]

export default actionsType
