import getDoubleClickEvent from "../../../../utils/getDoubleClickEvent"

export default function processSelectedFilter(state, { payload }) {
  const { selectedFilter } = payload
  if (selectedFilter) {
    getDoubleClickEvent(doubleClickEvent => {
      if (doubleClickEvent.isActive) {
        state.modals.filterModal.selectedFilter = selectedFilter
        state.modals.filterModal.mode = "edit"
        state.modals.filterModal.isVisible = true
      }
    })
  } else {
    state.modals.filterModal.mode = "new"
    state.modals.filterModal.isVisible = true
  }
}
