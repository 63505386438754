import RuntimeConfiguration from "../../models/runtimeConfiguration"
import dbRequest from "../dbRequest/dbRequest"

export default async function recordIdExists(entityName, recordID) {
  const id = String(recordID)

  let requestByID = dbRequest.loadRecords(entityName).where("ID", "==", id)
  let requestByModifiedID = dbRequest.loadRecords(entityName).where("ID", "==", `Z_${id}`)

  if (RuntimeConfiguration.getIsDeveloperConfigurationMode()) {
    requestByID = requestByID.admin()
    requestByModifiedID = requestByModifiedID.admin()
  }

  const [record1, record2] = await Promise.all([requestByID.execute(), requestByModifiedID.execute()])

  return record1 ?? record2
}
