import LoginData from "../../../../../../../../models/loginData"
import dbRequest from "../../../../../../../../services/dbRequest/dbRequest"
import getTableFields from "../../../../../../../../services/registration/getTableFields"

export default async function getFields({ entityName, t, models }) {
  const tableFields = models._REGISTRATION_DATA[entityName].fields ?? await getTableFields({
    entityName,
    fetchOptions: false
  })

  const filters = await dbRequest
    .loadRecords(`T_DTD_TAB/${entityName}/T_DTD_FLT`)
    .where("ID", "==", LoginData.getLoginData().ID)
    .execute((r) => r ? Object.keys(r[0].FILTERS ?? {}) : [])

  const visibleFields = tableFields
    .filter(({ ID, FIELDTYPE, T_DTD_ATR }) => !filters.includes(ID) && T_DTD_ATR.VISIBLE && FIELDTYPE.VALUE !== "E")
    .map(({ ID, T_DTD_ATR }) => ({
      id: ID,
      description: T_DTD_ATR.FIELDDESCRIPTION
    }))

  visibleFields.sort((a, b) => t(a.description).localeCompare(t(b.description)))

  return visibleFields
}
