import React from "react"
import OrderStatus from "./OrderStatus"
import * as S from "./styles.web"
import Summary from "./Summary"

const OrderSideBar = ({ controller, jumpTo, masterEntity }) => {

  return (
    <S.Container>
      <OrderStatus models={controller.models()} entity={masterEntity} />

      <S.DivisorLine />

      <Summary models={controller.models()} jumpTo={jumpTo} controller={controller} entity={masterEntity} />

    </S.Container>
  )
}

export default OrderSideBar

