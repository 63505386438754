import detailTabHasRecords from "../../../../../services/registration/detailTabHasRecords"
import foreignKeyValidation from "../../../../../services/registration/foreignKeyValidation"
import runTriggers from "../../../../../services/registration/runTriggers"
import showAlert from "../../../../../services/showAlert"
import executeDelete from "./utils/delete/executeDelete"
import hierarchicalRecordValidation from "./utils/delete/hierarchicalRecordValidation"

export default async function deleteRecord(props) {
  const { models, entityName } = props

  const hasSelectedRecord = models[entityName].ID || models._REGISTRATION_DATA[entityName].operation !== "INS"
  hasSelectedRecord
    ? showAlert({
      type: "confirm",
      title: "This action cannot be undone",
      titleType: "warning",
      text: "Do you want to delete the record?",
      handleConfirm: async () => await handleDelete(props)
    })
    : showAlert({
      text: "No record selected",
      title: "Failed to delete",
      titleType: "error"
    })
}

async function handleDelete({ mode, models, entityName, changeMode, setUpdateTable, callback }) {
  const selectedItem = models[entityName]

  if (entityName === "T_SET_RLU")
    return showAlert({
      text: "A user cannot be deleted, only deactivated",
      title: "Failed to delete",
      titleType: "error"
    }) //Migrate to user screen

  callback()

  models._REGISTRATION_DATA[entityName].operation = "DEL"
  models._REGISTRATION_DATA[entityName].state = "BEFORE"

  const isForeignKey = await foreignKeyValidation({
    entity: entityName,
    record: selectedItem
  })
  if (isForeignKey) return callback()

  if (models._REGISTRATION_DATA[entityName].type === "Hierarchic") {
    const hasChildRecords = await hierarchicalRecordValidation(entityName, selectedItem)
    if (hasChildRecords) return callback()
  }

  const allTriggersValid = await runTriggers(models, entityName)
  if (!allTriggersValid) return callback()

  const hasDetailRecords = await detailTabHasRecords({
    entity: entityName,
    models
  })
  if (hasDetailRecords) return callback()

  await executeDelete(models, entityName)

  models._REGISTRATION_DATA[entityName].state = "AFTER"
  await runTriggers(models, entityName)

  models[entityName].reset()

  showAlert({
    text: "Record deleted successfully",
    title: "Record deletion",
    titleType: "success"
  })

  callback()
  mode === "form" ? changeMode() : setUpdateTable(s => !s)
}
