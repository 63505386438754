import RuntimeConfiguration from '../../../../models/runtimeConfiguration'
import dbRequest from '../../../../services/dbRequest/dbRequest';

export default async function getDashboardQueries(dashboardId) {
  const isAdminMode = RuntimeConfiguration.getDashboardBuilderAdminMode()
  try {
    const dataPath = `T_RDB_DSB/${dashboardId}/DATA`;

    let mainCollections = dbRequest.loadRecords(dataPath);

    if (isAdminMode) mainCollections = mainCollections.admin();

    mainCollections = await mainCollections.execute();

    mainCollections = await Promise.all(
      mainCollections.map(async (mainCollection) => {
        mainCollection.connections = await getConnections(mainCollection.ID, dataPath);
        return mainCollection;
      })
    );

    return mainCollections;
  } catch (error) {
    console.error("Error getting dashboard data:", error);
    throw error;
  }
}

const getConnections = async (collectionId, basePath) => {
  const isAdminMode = RuntimeConfiguration.getDashboardBuilderAdminMode()

  const connectionPath = `${basePath}/${collectionId}/CONNECTIONS`;
  let connectionsRequest = dbRequest.loadRecords(connectionPath);

  if (isAdminMode) connectionsRequest = connectionsRequest.admin();

  const connections = await connectionsRequest.execute();

  if (connections.length === 0) {
    return [];
  }

  return Promise.all(
    connections.map(async (connection) => {
      connection.connections = await getSubConnections(connection.ID, connectionPath);
      return connection;
    })
  );
};

const getSubConnections = async (connectionId, basePath) => {
  const isAdminMode = RuntimeConfiguration.getDashboardBuilderAdminMode()

  const subConnectionPath = `${basePath}/${connectionId}/CONNECTIONS`;
  let subConnectionsRequest = dbRequest.loadRecords(subConnectionPath);

  if (isAdminMode) subConnectionsRequest = subConnectionsRequest.admin();

  const subConnections = await subConnectionsRequest.execute();

  if (subConnections.length === 0) {
    return [];
  }

  return Promise.all(
    subConnections.map(async (subConnection) => {
      subConnection.connections = await getSubConnections(subConnection.ID, subConnectionPath);
      return subConnection;
    })
  );
};
