import showAlert from "../showAlert"
import getTableFields from "./getTableFields"

interface Props {
	entity: string
	models: {
		entity: object
		_REGISTRATION_DATA: object
	}
}

export default async function allRequiredFieldsFilled({ models, entity }: Props) {
	const fields = models._REGISTRATION_DATA[entity].fields ?? await getTableFields({ entityName: entity })

	const requiredFields = fields.filter(({ FIELDTYPE, T_DTD_ATR }) => {
		return T_DTD_ATR.REQUIRED && T_DTD_ATR.VISIBLE && FIELDTYPE.VALUE !== "E"
	})

	for (const field of requiredFields) {
		const currentField = models[entity][field.ID] ?? ""
		if (
			(typeof currentField === "object" && Object.keys(currentField).length === 0) ||
			(typeof currentField === "string" && !currentField)
		) {
			showAlert({
				title: "Required field not filled",
				titleType: "error",
				text: `${field.T_DTD_ATR.FIELDDESCRIPTION}`
			})
			return false
		}
	}

	return true
}
