import LoginData from "../../models/loginData"
import RuntimeConfiguration from "../../models/runtimeConfiguration"
import dbRequest from "./dbRequest"

export default class userConfiguration {
  static async setMddData(userId) {
    if (!userId) return

    const userData = await dbRequest
      .loadRecords("-USER_RELATIONSHIP")
      .admin()
      .where("ID", "==", userId)
      .execute(r => r[0])

    RuntimeConfiguration.setUserMddData(userData)
  }

  static async setBranchData(userId) {
    if (!userId) return

    const userData = await dbRequest
      .loadRecords("T_SET_RLU")
      .where("ID", "==", userId)
      .execute(r => r[0])

    delete userData.CREATED_AT
    delete userData.CREATED_BY
    delete userData.UPDATED_AT
    delete userData.UPDATED_BY

    LoginData.setLoginData(userData)
  }
}
