import React from "react"
import * as S from "./styles"

export default function Field({ field, tableRecords }) {
  const { FIELDTYPE, T_DTD_ATR } = field

  const hasFunction = T_DTD_ATR.FUNCTION && T_DTD_ATR.FUNCTION?.VALUE !== "none"
  const isHourField = FIELDTYPE.VALUE === "H"
  const isCurrencyField = FIELDTYPE.VALUE === "C"
  let formattedValue = ""

  if (hasFunction) {
    const result = handleOperation(tableRecords, field)
    if (!isNaN(result)) {
      formattedValue = isHourField
        ? minutesToTime(result)
        : formatNumber(result, T_DTD_ATR.DECIMAL)
    }
  }

  return (
    <S.Container style={isCurrencyField && { justifyContent: "space-between" }}>
      <S.Text>{hasFunction && isCurrencyField ? "R$ " : ""}</S.Text>

      <S.Text>{formattedValue}</S.Text>
    </S.Container>
  )
}

function handleOperation(tableRecords, field) {
  const { ID, T_DTD_ATR } = field

  const operation = T_DTD_ATR.FUNCTION.VALUE

  const total = tableRecords.reduce((accumulator, record) => accumulator + (record[ID] || 0), 0)

  return operation === "sum" ? total : total / tableRecords.length
}

function formatNumber(number, decimalPlaces) {
  const options = {
    style: "decimal",
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces
  }
  const formattedNumber = new Intl.NumberFormat("pt-BR", options).format(number)
  return formattedNumber
}

function minutesToTime(minutes) {
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60
  return `${String(hours).padStart(2, "0")}:${String(remainingMinutes).padStart(2, "0")}`
}
