export default ({ models, entityName, setUpdateForm }) => {
  const tableData = models._REGISTRATION_DATA[entityName]

  models[entityName].reset()

  if (tableData.operation === "UPD") models[entityName].populate({ ...tableData.originalRecord })
  else tableData.operation = "INS"

  setUpdateForm(s => !s)
}
