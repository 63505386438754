import RuntimeConfiguration from "../../../../../../models/runtimeConfiguration"
import showAlert from "../../../../../../services/showAlert"

export default (controller, setSearchText, setUpdateTablesTable) => {
  const models = controller.models()

  if (!models.T_DTD_TAB.ID) return showAlert({
    text: "No table selected",
    title: "Failed to delete",
    titleType: "error"
  })

  if (!models.T_DTD_TAB.ID.includes("Z_") && !RuntimeConfiguration.getIsDeveloperConfigurationMode())
    return showAlert({
      text: "It is not possible to delete a native table",
      title: "Failed to delete",
      titleType: "error"
    })

  showAlert({
    type: "confirm",
    text: "Do you want to delete the selected table?",
    title: "This action cannot be undone",
    titleType: "warning",
    handleConfirm: async () => {
      await controller.deleteTable()
      models.T_DTD_TAB.reset()
      setSearchText("")
      setUpdateTablesTable(s => !s)
    }
  })
}
