import styled from "styled-components"
import { defaultTheme } from "../../../../../styles/themes/default"

interface ButtonProps {
  variant?: "white" | null
  disabled?: boolean
}

export const Container = styled.div<ButtonProps>`
  align-items: center;
  background-color: ${defaultTheme["gray-100"]};
  border-radius: 9px;
  cursor: pointer;
  display: flex;
  gap: 5px;
  justify-content: center;
  padding: 2.7px 9px 2.7px 2.7px;
  pointer-events: ${({ disabled }) => disabled ? "none" : "all"};
  transition: opacity 0.2s;

  &:active {
    opacity: 0.3;
  }

  span {
    color: ${defaultTheme["gray-500"]};
    font-size: 0.78rem;
  }
`

export const Button = styled.button<ButtonProps>`
  background-color: ${({ variant, disabled }) => {
    if (disabled) return defaultTheme["gray-300"]
    return defaultTheme[variant === "white" ? "white" : "cyan-300"]
  }};
  border-radius: 50%;
  display: flex;
  height: 24px;
  justify-content: center;
  align-items: center;
  width: 24px;

  pointer-events: ${({ disabled }) => disabled ? "none" : "all"};

  transition: opacity 0.2s;

  &:active {
    opacity: 0.3;
  }
`
