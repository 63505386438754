import LauncherComposerController from "../../../../controllers/LauncherComposerController"
import Navigation from "../../../../models/navigation"
import showAlert from "../../../../services/showAlert"

export default async function openRecord({ entity, entityName, models, setIsLoading }) {
  setIsLoading(true)
  const record = { ...models[entity] }

  if (!record) {
    setIsLoading(false)
    return showAlert({
      text: "No record selected",
      title: "Failed to open order",
      titleType: "error"
    })
  }

  const navigationController = new LauncherComposerController() as any

  navigationController.models()[entity].populate(record)
  await navigationController.initialize({
    entity,
    tabName: `${entityName.replace("Panel", "")} - ${record.ID}`,
    isExistingDocument: true
  })

  navigationController.jumpScreenStatus = true

  setIsLoading(false)

  Navigation.createTab({
    component: "NavigationComposer",
    params: {
      attribute: null,
      controller: navigationController,
      isInitialScreen: true,
      initialParams: navigationController.initialScreenParams,
      existingDocument: record,
      RootParentID: "Navigations"
    },
    tabName: `${entityName.replace("Panel", "")} - ${record.ID}`
  })

}
