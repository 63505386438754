import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import * as S from "./styles"

export default function TableDataArea({ models }) {
  const { t } = useTranslation()

  const [tableData, setTableData] = useState({
    id: "",
    description: "",
    pkSource: ""
  })

  useEffect(() => {
    setTableData({
      id: models.ID,
      description: models.ENTITY,
      pkSource: models.PKSOURCE?.LABEL
    })
  }, [models.ID])

  return (
    <S.Container>
      <S.Row>
        <S.BoldText>{t("Table ID")}: </S.BoldText>
        <S.Text>{tableData.id}</S.Text>
      </S.Row>

      <S.Row>
        <S.BoldText>{t("Description")}: </S.BoldText>
        <S.Text>{t(tableData.description)}</S.Text>
      </S.Row>

      <S.Row>
        <S.BoldText>{t("Primary Key Source")}: </S.BoldText>
        <S.Text>{t(tableData.pkSource)}</S.Text>
      </S.Row>
    </S.Container>
  )
}
