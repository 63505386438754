import { TextMask } from "react-native-masked-text"
import styled from "rn-css"
import globalStyles from "../../../../../../../../../../global/globalStyles"

const textProps = isSelected => `
  color: ${isSelected ? globalStyles.colors.tertiary : globalStyles.colors.primary};
  font-size: 12px;
  text-align: center;
`

export const Text = styled.Text.attrs({ numberOfLines: 1 })`
  ${({ isSelected }) => textProps(isSelected)}
`

export const TextFieldMask = styled(TextMask)`
  ${({ isSelected }) => textProps(isSelected)}
`

