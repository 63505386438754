import styled from "styled-components"

export const ButtonWrapper = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 6px;
  padding-right: 30px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: transparent;
  cursor: pointer;
  border-style: none;
  opacity: 1;
  transition: opacity .16s ease-in-out;

  &:active {
    opacity: 0.3;
  }
`

export const MenuButton = styled.div`
  display: flex;
  align-items: center;
  width: 50px;
  height: 14%;
`

export const MenuText = styled.p`
  color: ${({ theme, isSelected }) => isSelected ? theme["cyan-300"] : theme["gray-700"]};
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
  
  transition: opacity .3s ease-in-out;
  opacity: ${({ menuIsExpanded }) => menuIsExpanded ? 1 : 0};
  cursor: pointer;
  &:active {
    opacity: 0.3;
  }
`

export const SelectedMenuHighlighter = styled.div`
  position: absolute;
  height: 100%;
  width: 5px;
  left: 0;
  border-radius: 0 2px 2px 0;
  background-color: ${({ theme }) => theme["cyan-300"]};
`
