import React from "react"
import { useTranslation } from "react-i18next"
import * as S from "./styles"

const RecordsCount = ({ records }) => {
  const { t } = useTranslation()

  return <S.Container>{t("Records found")}: {records.length}</S.Container>
}

export default RecordsCount
