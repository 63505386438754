import styled from "styled-components/native"
import globalStyles from "../../../global/globalStyles"

export const Container = styled.View`
  flex: 1;
  margin: 6px 0 0 63px;
`

export const Content = styled.View`
  background-color: ${globalStyles.colors.background};

  padding: 0 5px;

  flex: 1;
  flex-direction: column;
`

export const SelectedDashboardInfo = styled.View`
  width: 100%;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background-color: #fff;
`

export const DashboardArea = styled.View`
  flex: 1;
  flex-direction: row;
  gap: 3px;
`

export const DividingLine = styled.View`
  background-color: #DCDCDC;

  height: 3px;
  width: 100%;
`

export const Title = styled.Text`
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #2e2e2e;
`

export const SelectedView = styled.View`
  flex-direction: row;
  align-items: center;
  align-self: center;
  padding: 10px;
  margin: 10px 0px;
  border-radius: 4px;
  background-color: ${globalStyles.colors.tertiary};
  gap: 20px;
`

export const SelectedField = styled.View`
  background-color: #fff;
  padding: 3px 10px;
  border-radius: 4px;
`

export const SelectedText = styled.Text`
  font-size: 16px;
  font-weight: 600;
  color: #3498DB;
`
export const TextInput = styled.TextInput`
  font-size: 16px;
  font-weight: 400;
  color: #2e2e2e;
`

export const ButtonsView = styled.View`
  position: absolute;
  right: 20px;
  bottom: 10px;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`

export const SaveButton = styled.TouchableOpacity`
  padding: 5px 10px;
  border-radius: 4px;
  background-color: #3498DB;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
`

export const ConfigureDashboardFields = styled(SaveButton)`
  background-color: ${globalStyles.colors.editButton};
`

export const DashboardLauncher = styled(ConfigureDashboardFields)``

export const ButtonTitle = styled.Text`
  color: ${globalStyles.colors.tertiary};
  font-weight: 500;
`

export const WarningView = styled.View`
  background-color: ${globalStyles.colors.removeButton};

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 22px;
`

export const Text = styled.Text`
  color: ${globalStyles.colors.tertiary};

  font-size: 12px;
`

