import PriceTableData from "../../../../../../models/priceTableData"

const getDefaultProductAmount = async (newAllFields, product) => {

  if (newAllFields.ITEMAMOUNT) return newAllFields.ITEMAMOUNT
  const defaultAmount = PriceTableData.getItemPrice(product.ID)

  newAllFields.ITEMAMOUNT = Number(defaultAmount?.PRICE)
  if (defaultAmount?.STOCK) newAllFields.STOCK = defaultAmount.STOCK
}

export default getDefaultProductAmount
