import { createAsyncThunk } from "@reduxjs/toolkit"
import RuntimeConfiguration from "../../../../models/runtimeConfiguration"
import dbRequest from "../../../../services/dbRequest/dbRequest"
import removeFieldsFromObject from "../../../../services/removeFieldsFromObject"
import showAlert from "../../../../services/showAlert"
import getDashboardFields from "./getDashboardFields"

const saveField = createAsyncThunk(
  "dashboardBuilder/saveField",
  async ({ path, T_DTD_FLD, T_DTD_ATR }, thunkAPI) => {
    const isAdminMode = RuntimeConfiguration.getDashboardBuilderAdminMode()

    if (!T_DTD_ATR?.FIELDDESCRIPTION) return thunkAPI.rejectWithValue(showAlert({
      title: "Unable to save field",
      titleType: "warning",
      text: "The description field is required."
    }))

    let saveRequest = dbRequest.saveRecords(`${path}/T_DTD_FLD`).record(T_DTD_FLD)

    if (isAdminMode) saveRequest = saveRequest.admin()

    const fieldID = await saveRequest.execute((r) => r.ID)

    const formattedFields = removeFieldsFromObject(T_DTD_ATR)
    for (const key in formattedFields) {
      let saveRequest = dbRequest.saveRecords(`${path}/T_DTD_FLD/${fieldID}/T_DTD_ATR`).record({
        ID: key,
        VALUE: formattedFields[key]
      })

      if (isAdminMode) saveRequest = saveRequest.admin()

      await saveRequest.execute()
    }

    const updatedFields = await getDashboardFields(path)

    return { updatedFields }
  }
)

export default saveField
