import styled from "styled-components"

interface ContainerProps {
  selectedTab: {
    tabName: string
    isDetail: boolean
  }
}

export const Container = styled.div<ContainerProps>`
  align-content: ${({ selectedTab }) => selectedTab.isDetail ? "normal" : "flex-start"};
  display: flex;
  height: 300px;
  flex: 1;
  flex-wrap: wrap;
  gap: 18px;
  overflow-y: auto; 
  padding: ${({ selectedTab }) => selectedTab.isDetail ? 0 : 15}px;
  width: 100%;
  min-height: 100%; 
`
