import React, { useContext } from "react"
import showAlert from "../../../../../../../../services/showAlert"
import Form from "../../../../../../../components/Form/index.web"
import ModalWrapper from "../../../../../../../components/Modal"
import { ControllerContext } from "../../../../../contexts/ControllerProvider"
import renderButtons from "../../../utils/renderButtons"
import handleDelete from "../../utils/handleDelete"
import handleSave from "../../utils/handleSave"
import * as S from "./styles"

export default function FormModal({ modalVisible, setModalVisible, setUpdateTable }) {
  const { controller, models } = useContext(ControllerContext)

  const closeModal = () => { setModalVisible(false) }

  const resetModel = () => {
    models.T_DTD_TRG.reset()
    models._REGISTRATION_DATA.T_DTD_TRG.operation = "INS"
  }

  const buttons = [
    {
      iconName: "save",
      divTitle: "Save changes",
      onPress: () => handleSave(controller, setUpdateTable, closeModal, resetModel)
    },
    {
      iconName: "trash",
      divTitle: "Delete selected record",
      onPress: async () => {
        if (models._REGISTRATION_DATA.T_DTD_TRG.operation !== "UPD")
          showAlert({
            text: "No trigger selected",
            title: "Failed to delete trigger",
            titleType: "error"
          })

        else await handleDelete(controller, setUpdateTable, closeModal, resetModel)
      }
    },
    {
      iconName: "close",
      divTitle: "Close",
      onPress: () => { closeModal() }
    }
  ]

  return (
    <ModalWrapper isVisible={modalVisible}>
      <S.Container>
        {renderButtons(buttons)}

        <Form entity="T_DTD_TRG" models={models} screenBuilder />
      </S.Container>
    </ModalWrapper>
  )
}
