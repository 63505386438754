import showAlert from "../../../../../../../services/showAlert"

export default async (controller, selectedOption, models, resetModel, setUpdateTable) => {
  if (!selectedOption) return showAlert({
    text: "No option selected",
    title: "Failed to delete option",
    titleType: "error"
  })

  showAlert({
    type: "confirm",
    text: "Do you want to delete the selected option?",
    title: "This action cannot be undone",
    titleType: "warning",
    handleConfirm: async () => {
      await controller.deleteOption()

      delete models.T_DTD_FLD.T_DTD_OPT[models.T_DTD_OPT.VALUE]
      resetModel()
      setUpdateTable(s => !s)
    }
  })
}
