import MaterialIcons from "@expo/vector-icons/MaterialIcons"; import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { VirtualizedList, ActivityIndicator, View, Platform, Text, StyleSheet } from "react-native"
import globalStyles from "../../../global/globalStyles"
import { defaultTheme } from "../../../styles/themes/default"
import RecordsCount from "./component/RecordsCount"
import useListManager from "./hook/useListManager"

const List = ({ controller, applyFilters, Card, showDefaultFields, searchText, update, changeScreen }) => {

  const { t } = useTranslation()

  const {
    loadData,
    isLoading,
    renderItem,
    getItem,
    getItemCount,
    extractListKeys,
    records,
    hasMoreToLoad,
    loadMore
  } = useListManager(controller, changeScreen, Card, searchText, applyFilters, update, showDefaultFields)

  useEffect(() => {
    loadData()
  }, [applyFilters])

  if (isLoading) {
    return (
      <View
        style={styles.countContainer}
      >
        <ActivityIndicator size="large" color={globalStyles.colors.primary} />
      </View>
    )
  } else return (
    <>
      <RecordsCount records={records} />
      <VirtualizedList
        data={records}
        initialNumToRender={10}
        renderItem={renderItem}
        getItem={getItem}
        getItemCount={getItemCount}
        keyExtractor={extractListKeys}
        onEndReached={loadMore}
        onEndReachedThreshold={0.5}
        ListFooterComponent={
          hasMoreToLoad ? (
            <ActivityIndicator size="small" color={globalStyles.colors.primary} />
          ) : null
        }
        ListEmptyComponent={() => (
          <View style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            marginTop: 50
          }}>
            <MaterialIcons name="info-outline" size={36} color={defaultTheme["gray-400"]} />
            <Text style={{
              color: defaultTheme["gray-400"],
              fontSize: 16,
              fontWeight: "600"
            }}>{t("No records found.")}</Text>
          </View>
        )}
        contentContainerStyle={{
          paddingTop: Platform.select({
            web: 20,
            native: 10
          }),
          paddingBottom: 20
        }}
      />
    </>
  )
}

const styles = StyleSheet.create({
  countContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    padding: 10,
    borderRadius: 10,
    backgroundColor: defaultTheme["white"],
    elevation: 5,
    shadowOffset: {
      width: 0,
      height: 1
    },
    shadowOpacity: 1,
    shadowColor: Platform.select({
      android: "#000",
      ios: "#dadada"
    })
  }
})

export default List
