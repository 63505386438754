import { deleteApp, initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore/lite"
import { getFunctions } from "firebase/functions"
import { getStorage } from "firebase/storage"

const currentConnection = "P"

const connections = {
  D: { // DEV
    apiKey: "AIzaSyCfBVY76qh8pak796CJd0pTitaEX_YD16s",
    authDomain: "musa-data-dictionary.firebaseapp.com",
    projectId: "musa-data-dictionary",
    storageBucket: "musa-data-dictionary.appspot.com",
    messagingSenderId: "640134384029",
    appId: "1:640134384029:web:4d41478d1c8c7ee7dc4774",
    measurementId: "G-2H8ZHMR8QN"
  },
  P: { // PROD
    apiKey: "AIzaSyCHTcmCWl0oOyKBAOoj7dgGucGn2bXTKTY",
    authDomain: "mdd-prod.firebaseapp.com",
    projectId: "mdd-prod",
    storageBucket: "mdd-prod.appspot.com",
    messagingSenderId: "483059334543",
    appId: "1:483059334543:web:b45650adf3d7614518dffa",
    measurementId: "G-HWS6E7H2GF"
  }
}

let dbConnectionRemote = null
let authRemote = null
let remoteFunctions = null

let localApp = null
let firebaseConfigLocal = null
let dbConnectionLocal = null
let authLocal = null
let localFunctions = null
let localStorage = null

const dbConnection = (admin: boolean) => {
  if (admin) return dbConnectionRemote
  if (dbConnectionLocal) return dbConnectionLocal
  return dbConnectionRemote
}
const auth = () => authLocal ?? authRemote

export const setBranchConfig = (config: object) => firebaseConfigLocal = config

export const initBranchConnection = async () => {
  if (localApp) await deleteApp(localApp)
  localApp = initializeApp(firebaseConfigLocal, "local")
  dbConnectionLocal = getFirestore(localApp)
  authLocal = getAuth(localApp)
  localFunctions = getFunctions(localApp)
  localStorage = getStorage(localApp)
}

  ; (() => {
    const app = initializeApp(connections[currentConnection], "remote")
    dbConnectionRemote = getFirestore(app)
    authRemote = getAuth(app)
    remoteFunctions = getFunctions(app)
  })()

export { localFunctions, remoteFunctions, dbConnection, auth, dbConnectionRemote, authRemote, dbConnectionLocal, authLocal, localStorage }
