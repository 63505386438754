/* eslint-disable no-case-declarations */
const getFormattedRecords = ({ records = [], models, entity }) => {

  return records.map((record) => {
    const updatedRecord = {}

    for (const key in record) {
      updatedRecord[key] = getValue({
        key,
        value: record[key],
        fields: models._REGISTRATION_DATA[entity].fields
      })
      updatedRecord.ALTID = record.ID
    }

    models._REGISTRATION_DATA[entity].formattedRecords[record.ID] = {
      ...record
    }

    return updatedRecord
  })
}

function getValue({ key, value, fields }) {

  const matchingField = fields.find(field => field.ID === key)

  if (matchingField) {
    const { FIELDTYPE } = matchingField

    switch (FIELDTYPE.VALUE) {
      case "H":
        if (!value) value = 0
        if (typeof value === "string") value = value.trim() === "" ? 0 : value
        const minutes = Number(value)
        const hours = Math.floor(minutes / 60)
        const remainingMinutes = minutes % 60
        return `${String(hours).padStart(2, "0")}:${String(remainingMinutes).padStart(2, "0")}`
      case "I":
      case "F":
      case "C": return Number(value) ?? 0
      case "A": return value?.[0]?.URL
      case "K":
      case "N": {
        if (Object.keys(value).length === 0) return "--"
        return value?.DESCRIPTION || value?.LABEL || value
      }
      case "L": return value?.LABEL || value
      case "O": {
        if (Array.isArray(value)) return value.map(item => item.LABEL).join(", ")
        return value?.LABEL || value
      }
      case "T":
      case "D": {
        const date = new Date(value)
        if (!isNaN(date.getTime())) {
          return date
        } else return null
      }
      default:
        return value
    }
  }
}

export default getFormattedRecords
