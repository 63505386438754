import styled, { createGlobalStyle } from "styled-components"
import globalStyles from "../../../global/globalStyles"
import { defaultTheme } from "../../../styles/themes/default"

export const ResetStyles = createGlobalStyle`
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
`

export const ModalContainer = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;

  width: 50%;
  height: auto;
  max-height: 60%;

  background-color: ${defaultTheme["gray-200"]};

  overflow: hidden;

  border-radius: 5px;
`

export const CloseButton = styled.button`

  position: absolute;
  right: 10px;
  top: 10px;

  display: flex;
  align-items: center;
  padding:  3px;
  
  background-color: ${defaultTheme["white"]};

  border-radius: 5px;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);


  &:hover {
    transform: scale(1.05);
  }

  z-index: 2;
`
export const HeaderContainer = styled.header`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  
  padding: 15px 50px 5px 15px ;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1;
`

export const ModalTitle = styled.h1`
  font-family: Arial, sans-serif;
  color: ${defaultTheme["gray-700"]};
  font-size: 20px;
  font-weight: 500;
  margin: 0px;
`

export const Body = styled.div`
  width: 100%;
  height: 100%;
  
`

export const FormView = styled.div`
  overflow: scroll;
  width: calc(100% + 16px);
  height: calc(100% + 16px);

  background-color: ${defaultTheme["white"]};
  
`

export const HeaderButtonsView = styled.div`
  display: flex;
  flex-direction: row-reverse;
`

export const SaveButton = styled.button`
  background-color: transparent;
  color: ${defaultTheme["cyan-500"]};
  font-size: 14px;
  font-weight: 500;

  border-radius: 2px;

  padding: 4px 6px;

  &:hover {
    color: ${defaultTheme["white"]};
    background-color: ${defaultTheme["cyan-500"]};
  }
`
