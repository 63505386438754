import Attachment from "../components/Fields/Attachment"
import Checkbox from "../components/Fields/Checkbox"
import Currency from "../components/Fields/Currency"
import Date from "../components/Fields/Date"
import Datetime from "../components/Fields/Datetime"
import Decimal from "../components/Fields/Decimal"
import Hours from "../components/Fields/Hours"
import Integer from "../components/Fields/Interger"
import Search from "../components/Fields/Search"
import Selection from "../components/Fields/Selection"
import Text from "../components/Fields/Text"

export default function getFieldToRender({ fieldData }) {
  const Fields = {
    "A": Attachment, // Attachment
    "B": Checkbox, // Boolean
    "C": Currency, // Currency
    "D": Date, // Date
    "F": Decimal, // Float
    "H": Hours, // Hour
    "I": Integer, // Integer
    "K": Search, // Key (Foreign Key)
    "L": Selection, // List
    "M": Text, // Multiline
    "N": Search, // Navigation
    "S": Text, // String
    "T": Datetime // Timestamp
  }

  return Fields[fieldData.FIELDTYPE.VALUE]
}
