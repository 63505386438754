import React from "react"
import { useTranslation } from "react-i18next"
import idValidation from "../../utils/idValidation"
import AccessibilityBox from "../AccessibilityBox"
import useSearch from "../hooks/useSearch"
import SearchModal from "./components/SearchModal"
import * as S from "./styles.web"

export default function Search(props) {
  const { t } = useTranslation()
  const { field, models, setUpdateForm } = props

  //idValidation(field, models)

  const { EDITABLE, REQUIRED, FIELDDESCRIPTION, HELPTEXT } = field.T_DTD_ATR

  const customHookData = useSearch(props)
  const { inputData, setInputData, modalVisible, handleSearch, handlePress } = customHookData

  const hasValue = Boolean(inputData.id || inputData.description)

  function handleChange(event: React.ChangeEvent<HTMLInputElement>, field: string) {
    setInputData(state => ({
      ...state,
      [field]: event.target.value
    }))
  }

  function handleBlur(field: string) {
    handleSearch(field)
    setUpdateForm(s => !s)
  }

  return (
    <S.Container>
      <S.Row>
        <S.Title isRequired={REQUIRED}>{t(FIELDDESCRIPTION)}</S.Title>

        <AccessibilityBox text={HELPTEXT} />
      </S.Row>

      <S.Row>
        <S.InputContainer isEditable={EDITABLE}>
          <S.Input
            placeholder={t("ID")}
            width={80}
            disabled={!EDITABLE}
            value={inputData.id}
            onChange={e => handleChange(e, "id")}
            onBlur={() => handleBlur("id")}
          />

          <S.Button onClick={handlePress} disabled={!EDITABLE} isEditable={EDITABLE}>
            <S.Icon name={hasValue ? "close" : "search"} />
          </S.Button>

        </S.InputContainer>

        <S.InputContainer isEditable={EDITABLE}>
          {inputData.img?.[0]?.URL && (
            <S.ImageContainer>
              <S.Image src={inputData.img[0].URL} />
            </S.ImageContainer>
          )}

          <S.Input
            placeholder={t("Description")}
            width={200}
            disabled={!EDITABLE}
            value={inputData.description}
            onChange={e => handleChange(e, "description")}
            onBlur={() => handleBlur("description")}
          />
        </S.InputContainer>
      </S.Row>

      {modalVisible && <SearchModal {...props} {...customHookData} />}
    </S.Container>
  )
}
