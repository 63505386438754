const removeMenusWithoutSubmenus = (menuData) => {
  const newMenuData = menuData.map((menuItem) => ({
    ...menuItem,
    SUB_MENUS: menuItem.SUB_MENUS ? [...menuItem.SUB_MENUS] : [],
  }));

  for (let i = newMenuData.length - 1; i >= 0; i--) {
    const menuItem = newMenuData[i];

    if (menuItem.isLauncher) continue;

    if (menuItem.SUB_MENUS && menuItem.SUB_MENUS.length > 0)
      menuItem.SUB_MENUS = removeMenusWithoutSubmenus(menuItem.SUB_MENUS);
      
    if (!menuItem.SUB_MENUS || menuItem.SUB_MENUS.length === 0)
      newMenuData.splice(i, 1);
  }

  return newMenuData;
}

export default removeMenusWithoutSubmenus;
