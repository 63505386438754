import dbRequest from "../../../../../../../services/dbRequest/dbRequest"

export default async ({ models, entityName }) => {
  const tableData = models._REGISTRATION_DATA[entityName]

  const detailTabs = await dbRequest
    .loadRecords(`T_DTD_TAB/${entityName}/T_DTD_FLD`)
    .execute(fields => fields.filter(({ FIELDTYPE }) => FIELDTYPE.VALUE === "E"))

  for (const table of detailTabs) {
    const tableID = table.ID
    const recordID = (models[entityName].ID).toString()

    tableData.detailTabsPath[tableID] = `${entityName}/${recordID}/${tableID}`
  }
}
