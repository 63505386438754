import { MaterialIcons } from "@expo/vector-icons"
import React, { useEffect, useState } from "react"
import { View } from "react-native"
import { useDispatch } from "react-redux"
import globalStyles from "../../../../../../global/globalStyles"
import useAppSelector from "../../../../../../redux/hooks/useAppSelector"
import { closeModal } from "../../../../../../redux/redux-reducers/dashboardBuilder-reducer"
import saveSerie from "../../../../../../redux/redux-reducers/dashboardBuilder-reducer/utils/saveSerie"
import ModalWrapper from "../../../../../components/Modal"
import * as S from "../modalStyles"
import actionsType from "./utils/actionsType"
import getEntitiesList from "./utils/getEntitiesList"
import { seriesType } from "./utils/seriesType"

const formTemplate = {
  ID: "",
  DESCRIPTION: "",
  COLOR: "",
  SERIETYPE: {},
  GROUPING: {},
  VALUE: {},
  ACTIONEVENT: {
    TYPE: {
      VALUE: "",
      LABEL: "None"
    },
    PARAMETER: "",
    TRANSACTIONID: ""
  }
}

export default function SerieModal() {

  const dispatch = useDispatch()

  const { name, isVisible, mode } = useAppSelector(({ dashboardBuilderReducer }) => dashboardBuilderReducer.modals.serieModal)
  const { selectedDashboard, selectedLevel, selectedGadget, selectedSerie } = useAppSelector(({ dashboardBuilderReducer }) => dashboardBuilderReducer.dashboardData)

  const [groupingFields, setGroupingFields] = useState([])
  const [accumulatorFields, setAccumulatorFields] = useState([])
  const [entitiesList, setEntitiesList] = useState([])
  const [form, setForm] = useState(formTemplate)

  const getGroupingAndAccumulatorFields = () => {
    if (!selectedGadget.ID) return

    const formattedArray = (array) => {
      return array.map(item => {
        const newObj = {}
        if (item?.SUBFIELD) {
          newObj.VALUE = item.ID
          newObj.LABEL = `${item.FIELDDESCRIPTION} > ${item.SUBFIELD.LABEL}`
        }
        else {
          newObj.VALUE = item.ID
          newObj.LABEL = item.FIELDDESCRIPTION
        }
        return newObj
      })
    }
    setGroupingFields(formattedArray(selectedGadget.GROUPING))
    setAccumulatorFields(formattedArray(selectedGadget.ACCUMULATOR))
  }

  useEffect(() => {
    if (selectedGadget) getGroupingAndAccumulatorFields()

      ; (async () => setEntitiesList(await getEntitiesList()))()
  }, [selectedGadget])

  useEffect(() => {
    if (mode === "edit" && selectedSerie) setForm({ ...selectedSerie })
    else setForm(formTemplate)
  }, [selectedSerie, mode])

  const changeForm = (key, value) => {
    setForm({
      ...form,
      [key]: value
    })
  }

  const submitForm = () => {
    dispatch(saveSerie({
      form,
      isComboChart: selectedGadget?.GADGET?.VALUE === "ComboChart",
      path: `T_RDB_DSB/${selectedDashboard.ID}/T_RDB_LVL/${selectedLevel.ID}/T_RDB_GDG/${selectedGadget.ID}/T_RDB_SRS`
    }))
  }

  return (
    <ModalWrapper isVisible={isVisible}>
      <S.Container style={{
        height: "auto",
        width: "75%"
      }}>
        <S.CloseButton onPress={() => dispatch(closeModal({ modalName: name }))}>
          <MaterialIcons name="close" size={20} color="#333333" />
        </S.CloseButton>

        <S.Title>{mode === "new" ? "Add New" : "Edit"} Serie</S.Title>

        <S.AddButton
          style={{
            position: "absolute",
            top: 12,
            right: 50
          }}
          onPress={submitForm}>
          <S.TitleButton>Save Serie {mode === "edit" && "Changes"}</S.TitleButton>
        </S.AddButton>

        <S.SelectedView>
          <View style={{
            flexDirection: "row",
            alignItems: "center",
            gap: 3
          }}>
            <S.SelectedText>ID:</S.SelectedText>
            <S.SelectedField>
              <S.TextInput
                style={{ width: 120 }}
                value={form?.ID}
                placeholder='Serie Id.'
                placeholderTextColor={"gray"}
                onChangeText={(tx) => changeForm("ID", tx)}
                editable={mode === "new"}
              />
            </S.SelectedField>
          </View>
          <View style={{
            flexDirection: "row",
            alignItems: "center",
            gap: 3
          }}>
            <S.SelectedText>Description:</S.SelectedText>
            <S.SelectedField>
              <S.TextInput
                value={form?.DESCRIPTION}
                placeholder='Serie Description'
                placeholderTextColor={"gray"}
                onChangeText={(tx) => changeForm("DESCRIPTION", tx)}
              />
            </S.SelectedField>
          </View>

          <View style={{
            flexDirection: "row",
            alignItems: "center",
            gap: 3
          }}>
            <S.SelectedText>Color:</S.SelectedText>
            <S.SelectedField style={{
              borderColor: form?.COLOR,
              borderWidth: 3
            }}
            >
              <S.TextInput
                value={form?.COLOR}
                placeholder='Serie Color'
                placeholderTextColor={"gray"}
                maxLength={7}
                onChangeText={(tx) => {
                  if (!tx.includes("#")) tx = "#" + tx
                  changeForm("COLOR", tx)
                }}
              />
            </S.SelectedField>
          </View>

        </S.SelectedView>

        <S.SelectedView style={{ marginTop: 0 }}>
          <View style={{
            flexDirection: "row",
            alignItems: "center",
            gap: 3
          }}>
            <S.SelectedText>Serie Type:</S.SelectedText>
            <S.SelectedField style={{ backgroundColor: selectedGadget?.GADGET?.VALUE !== "ComboChart" ? globalStyles.colors.background : "#fff" }}>
              <S.ListInput
                data={seriesType}
                value={form?.SERIETYPE?.VALUE}
                dropdownPosition='bottom'
                labelField="LABEL"
                valueField="VALUE"
                placeholder={"Select Serie type"}
                disable={selectedGadget?.GADGET?.VALUE !== "ComboChart"}
                onChange={async (item) => changeForm("SERIETYPE", item)} />
            </S.SelectedField>
          </View>
          <View style={{
            flexDirection: "row",
            alignItems: "center",
            gap: 3
          }}>
            <S.SelectedText>Group Label:</S.SelectedText>
            <S.SelectedField>
              <S.ListInput
                data={groupingFields}
                value={form?.GROUPING?.VALUE}
                dropdownPosition="bottom"
                labelField="LABEL"
                valueField="VALUE"
                placeholder={"Select Group Label"}
                onChange={async (item) => changeForm("GROUPING", item)}
                onFocus={() => getGroupingAndAccumulatorFields()}
              />
            </S.SelectedField>
          </View>
          <View style={{
            flexDirection: "row",
            alignItems: "center",
            gap: 3
          }}>
            <S.SelectedText>Group Value:</S.SelectedText>
            <S.SelectedField>
              <S.ListInput
                data={accumulatorFields}
                value={form?.VALUE?.VALUE}
                dropdownPosition='bottom'
                labelField="LABEL"
                valueField="VALUE"
                placeholder={"Select Group Value"}
                onChange={async (item) => changeForm("VALUE", item)}
              />
            </S.SelectedField>
          </View>

        </S.SelectedView>

        <S.SelectedView style={{ marginTop: 0 }}>
          <View style={{
            flexDirection: "row",
            alignItems: "center",
            gap: 3
          }}>
            <S.SelectedText>Action Type:</S.SelectedText>
            <S.SelectedField>
              <S.ListInput
                data={actionsType}
                value={form.ACTIONEVENT?.TYPE?.VALUE}
                search
                searchPlaceholder='Search'
                dropdownPosition='bottom'
                labelField="LABEL"
                valueField="VALUE"
                placeholder={"Select Action Type"}
                onChange={async (item) => {
                  const newActionEvent = { ...form.ACTIONEVENT }
                  newActionEvent.TYPE = item
                  changeForm("ACTIONEVENT", newActionEvent)
                }}
              />
            </S.SelectedField>
          </View>

          <View style={{
            flexDirection: "row",
            alignItems: "center",
            gap: 3
          }}>
            <S.SelectedText>Action Parameter:</S.SelectedText>
            <S.SelectedField style={[!form?.ACTIONEVENT?.TYPE?.VALUE && { backgroundColor: "#EDEDED" }]}>
              <S.TextInput
                value={form?.ACTIONEVENT?.PARAMETER}
                placeholder='Action Parameter'
                placeholderTextColor={"gray"}
                editable={form?.ACTIONEVENT?.TYPE?.VALUE}
                onChangeText={(tx) => {
                  const newActionEvent = { ...form.ACTIONEVENT }
                  newActionEvent.PARAMETER = tx
                  changeForm("ACTIONEVENT", newActionEvent)
                }}
              />
            </S.SelectedField>
          </View>

          <View style={{
            flexDirection: "row",
            alignItems: "center",
            gap: 3
          }}>
            <S.SelectedText>Navigate To:</S.SelectedText>
            <S.SelectedField style={[form?.ACTIONEVENT?.TYPE?.VALUE !== "E" && { backgroundColor: "#EDEDED" }]}>
              <S.ListInput
                data={entitiesList}
                value={form?.ACTIONEVENT?.NAVIGATETO?.ID}
                dropdownPosition='bottom'
                labelField="ENTITY"
                valueField="ID"
                placeholder={"Select Group Value"}
                onChange={async (item) => {
                  const newActionEvent = { ...form.ACTIONEVENT }
                  newActionEvent.NAVIGATETO = item
                  changeForm("ACTIONEVENT", newActionEvent)
                }}
              />
            </S.SelectedField>
          </View>
        </S.SelectedView>

      </S.Container>
    </ModalWrapper>
  )
}
