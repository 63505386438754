import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  addedProducts: [],
  totalizerValues: {
    totalAmount: 0,
    totalQuantity: 0,
    totalWeight: 0,
    totalExttax: 0,
    shippingValue: 0
  },
  additionalFilters: []
}

const calculateTotalizers = (state) => {
  state.totalizerValues.totalAmount = state.totalizerValues.shippingValue
  state.totalizerValues.totalWeight = state.totalizerValues.totalExttax = 0

  state.totalizerValues.totalQuantity = state.addedProducts.reduce((total, item) => {
    const updatedItem = {
      ...item,
      TOTALGROSSWEIGHT: item.TOTALGROSSWEIGHT || 0
    }

    state.totalizerValues.totalAmount += updatedItem.AMOUNT
    state.totalizerValues.totalWeight += updatedItem.TOTALGROSSWEIGHT
    state.totalizerValues.totalExttax += updatedItem.EXTTAX

    return total + 1
  }, 0)
}

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setProduct: (state, { payload }) => {
      const existingIndex = state.addedProducts.findIndex(item => item.ID === payload.T_MOV_ITE.ID)
      const movIteTemplate = payload.T_MOV_ITE
      movIteTemplate.ITEMID = payload.ITEMID

      if (existingIndex !== -1) state.addedProducts[existingIndex] = movIteTemplate
      else state.addedProducts.push(movIteTemplate)

      calculateTotalizers(state)

    },
    removeProduct: (state, { payload }) => {
      const newArray = state.addedProducts.filter(item => item.ID != payload.newAllFields.ID)
      state.addedProducts = newArray
      calculateTotalizers(state)
    },
    populateProducts: (state, { payload }) => {
      state.addedProducts = payload
      calculateTotalizers(state)
    },
    resetState: (state) => initialState,
    addAdditionalFilters: (state, { payload }) => {
      const index = state.additionalFilters.findIndex(item => {
        return item.LABEL === payload.LABEL
      })

      if (index !== -1) {
        if (payload.VALUE.length === 0) state.additionalFilters.splice(index, 1)
        else state.additionalFilters[index] = payload
      } else if (payload.VALUE.length > 0) {
        state.additionalFilters.push(payload)
      }
    },
    removeAdditionalFilters: (state) => {
      if (state.additionalFilters.length > 0) state.additionalFilters = []
    },
    updateShippingValue: (state, { payload }) => {
      const shippingValue = Number(payload.value)
      if (payload.type === "N") {
        if (state.totalizerValues.shippingValue != shippingValue) {
          state.totalizerValues.totalAmount -= state.totalizerValues.shippingValue
          state.totalizerValues.totalAmount += shippingValue
          state.totalizerValues.shippingValue = shippingValue
        }
      } else if (payload.type === "I") {
        state.totalizerValues.totalAmount -= state.totalizerValues.shippingValue
        state.totalizerValues.shippingValue = 0
      }
    }
  }
})

export const { setProduct, removeProduct, populateProducts, resetState, addAdditionalFilters, removeAdditionalFilters, updateShippingValue } = orderSlice.actions
export const orderReducer = orderSlice.reducer
