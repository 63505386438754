import { useState, useEffect } from "react"
import getFormData from "../../../../services/registration/getFormData"

export default function useForm({ entity, staticFields, updateForm, models, screenBuilder }) {
  const [formData, setFormData] = useState({ fields: [], tabs: [] })

  useEffect(() => {
    (async () => {
      await getFormData({
        entity,
        staticFields: await staticFields,
        models,
        screenBuilder
      })
        .then(({ orderedFields, uniqueTabs }) => {
          setFormData({ fields: orderedFields, tabs: uniqueTabs })
        })
    })()
  }, [updateForm])

  return formData
}
