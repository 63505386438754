import AsyncStorage from "@react-native-async-storage/async-storage"
import { Platform } from "react-native"

const LOCAL_STORAGE_KEY = "userLogin"
const ASYNC_STORAGE_KEY = "@userLogin"
const ASYNC_STORAGE_KEY_PSW = "@userPassword"
const ASYNC_STORAGE_KEY_KEEPLOGGED = "@userKeepLogged"

const isWeb = Platform.OS === "web"

export default async function storeEmail(email: string, password: string, keepLogged: boolean) {
  if (isWeb) localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(email))
  else {
    await AsyncStorage.setItem(ASYNC_STORAGE_KEY, email)
    await AsyncStorage.setItem(ASYNC_STORAGE_KEY_PSW, password)
    await AsyncStorage.setItem(ASYNC_STORAGE_KEY_KEEPLOGGED, keepLogged.toString())
  }
}
