export default ({ models, viewType, entityName }) => {
  if (viewType === "readOnly") return []

  return Object.entries(models._REGISTRATION_DATA[entityName].filtersValues)
    .filter(([, value]) => {
      switch (typeof value) {
        case "object": return Object.keys(value || {}).length > 0
        case "boolean": return true
        default: return value !== null && value !== "" /* && !isNaN(value) #Lucas: comentado pq estava bloqueando filtrar por periodo*/
      }
    })
    .map(([key, value]) => ({
      key: key.replace(/_INI|_FIN/g, ""),
      value
    })
    )
}
