export default code => {
  switch (code) {
    case "CNPJ_NOT_FOUND": return {
      text: "CNPJ not found",
      title: "Failed to get partner data",
      titleType: "error"
    }
    case "MAX_REQUESTS_EXCEEDED": return {
      text: "Maximum number of requests exceeded",
      title: "Failed to get partner data",
      titleType: "error"
    }
    default: return {
      text: "All available fields were populated",
      title: "Data imported successfully",
      titleType: "success"
    }
  }
}
