import React from "react"
import FieldMask from "./styles"

export default function Datetime({ fieldValue, isSelected }) {
  const datetimeValue = fieldValue ? new globalDate(fieldValue).toTimestamp() : ""

  return (
    <FieldMask
      value={datetimeValue}
      isSelected={isSelected}
    />
  )

}
