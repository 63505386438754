import showAlert from "../../../../../../../../../services/showAlert"

export default function manualIdValidation({ models, entity }) {
  const tableData = models._REGISTRATION_DATA[entity]

  if (tableData.manualID && !models[entity].ID) {
    showAlert({
      text: "Provide an ID",
      title: "Manual ID",
      titleType: "error"
    })

    return false
  }

  return true
}
