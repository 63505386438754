import dbRequest from "../../../../../../../../../services/dbRequest/dbRequest"
import allRequiredFieldsFilled from "../../../../../../../../../services/registration/allRequiredFieldsFilled"
import runTriggers from "../../../../../../../../../services/registration/runTriggers"
import showAlert from "../../../../../../../../../services/showAlert"
import storageRequest from "../../../../../../../../../services/storageRequest"
import existingIdValidation from "./existingIdValidation"
import idCreationValidation from "./idCreationValidation"
import manualIdValidation from "./manualIdValidation"
import populateDetailTabsPath from "./populateDetailTabsPath"
import saveFiles from "./saveFiles"

const handleSave = async ({
  models,
  entity,
  detailTabPath,
  setUpdateForm,
  setIsLoading = (boolean) => null
}) => {

  const props = {
    models,
    entity,
    detailTabPath
  }

  const isIdValid = manualIdValidation(props)
  if (!isIdValid) return

  showAlert({
    type: "confirm",
    text: "Do you want to save the record?",
    title: "Record saving",
    handleConfirm: async () => {
      const tableData = models._REGISTRATION_DATA[entity]

      setIsLoading(true)

      const areAllRequiredFieldsFilled = await allRequiredFieldsFilled(props)
      if (!areAllRequiredFieldsFilled) return setIsLoading(false)

      const doesIdExists = await existingIdValidation(props)

      if (doesIdExists) {
        setUpdateForm(s => !s)
        setIsLoading(false)
        return
      }

      tableData.state = "BEFORE"

      const areAllTriggersValid = await runTriggers(models, entity)
      if (!areAllTriggersValid) return setIsLoading(false)

      await idCreationValidation(props)
      await populateDetailTabsPath(props)

      const hasSelectedImage = Boolean(models[entity].IMG?.file)
      const hasSavedImage = Boolean(models._REGISTRATION_DATA[entity].originalRecord.IMG?.[0]?.URL)
      const isImageDeleted = models[entity].IMG?.file === null

      if (hasSelectedImage || (hasSavedImage && isImageDeleted)) {
        if (hasSelectedImage)
          await saveImage(props)
        else
          models[entity].IMG = []
      }

      const { files } = models._REGISTRATION_DATA[entity]
      const hasFiles = Object.keys(files).length > 0

      if (hasFiles)
        await saveFiles(props)

      if (hasFiles || hasSelectedImage || (hasSavedImage && isImageDeleted)) {
        const updatedRecord = await dbRequest
          .saveRecords(detailTabPath)
          .record({ ...models[entity] })
          .execute()

        models[entity].populate(updatedRecord)
      }

      tableData.originalRecord = { ...models[entity] }
      tableData.operation = "UPD"
      tableData.state = "AFTER"

      await runTriggers(models, entity)

      setUpdateForm(s => !s)
      setIsLoading(false)

      showAlert({
        text: "Record saved successfully",
        title: "Record saving",
        titleType: "success"
      })
    }
  })
}

export default handleSave

const saveImage = async ({ models, entity, detailTabPath }) => {
  const { ID, IMG } = models[entity]
  const { file } = IMG
  const record = new Blob([file], { type: file.type })

  const value = await storageRequest
    .saveFiles(`${detailTabPath}/${ID}/IMG/${file.name || file.NAME}`)
    .file(record)
    .execute((res: any) => {
      const result = {
        NAME: file.name || file.NAME,
        SIZE: file.size || file.SIZE,
        TYPE: file.type || file.TYPE,
        URL: res.url
      }

      return result
    })

  models[entity].IMG = [value]
}
