import { createAsyncThunk } from "@reduxjs/toolkit"
import RuntimeConfiguration from "../../../../models/runtimeConfiguration"
import dbRequest from "../../../../services/dbRequest/dbRequest"
import showAlert from "../../../../services/showAlert"
import { ChartData, ChartDataArray } from "../../../../views/screens/DashboardsBuilderScreen/utils/ChartData"

const messages = {
  DESCRIPTION: "The Description field is required.",
  CHARTTYPE: "The Chart Type field is required.",
  DATAID: "The Linked Query field is required.",
  GROUPING: "It is necessary to define at least one field in the grouping."
}

const defaultMatrix = "000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000"

export const saveGadget = createAsyncThunk(
  "dashboardBuilder/saveGadget",
  async ({ form, path, selectedLevel }, thunkAPI) => {
    const isAdminMode = RuntimeConfiguration.getDashboardBuilderAdminMode()

    const { gadgets } = selectedLevel

    const gadget = { ...form }
    const _id = gadget.ID || getNextId(gadgets).toString()
    let _series = null

    if (gadget?.series?.length) {
      _series = gadget.series
      delete gadget?.series
    } else _series = []

    const checkDescription = validateGadgetProperty(gadget?.DESCRIPTION, thunkAPI, messages.DESCRIPTION)
    if (checkDescription) return checkDescription

    const checkGadgetValue = validateGadgetProperty(gadget?.GADGET?.VALUE, thunkAPI, messages.CHARTTYPE)
    if (checkGadgetValue) return checkGadgetValue

    const checkDataId = validateGadgetProperty(gadget?.DATAID, thunkAPI, messages.DATAID)
    if (checkDataId) return checkDataId

    const checkGrouping = validateGadgetProperty(gadget?.GROUPING?.length, thunkAPI, messages.GROUPING)
    if (checkGrouping) return checkGrouping

    let saveRequest = dbRequest
      .saveRecords(path)
      .record({
        ...gadget,
        ID: _id,
        MATRIX: gadget?.MATRIX || defaultMatrix
      })

    if (isAdminMode) saveRequest = saveRequest.admin()

    await saveRequest.execute()

    const gadgetPosition = Number(_id)
    if (!gadget?.ID) ChartDataArray.getArr().push(new ChartData(Number(gadgetPosition)))
    ChartDataArray.getPosition(gadgetPosition - 1).setModel({
      ...gadget,
      ID: _id,
      MATRIX: gadget?.MATRIX || defaultMatrix
    })

    return {
      savedGadget: {
        ...gadget,
        ID: _id,
        series: _series,
        MATRIX: gadget?.MATRIX || defaultMatrix
      },
      selectedLevel
    }
  }
)

function validateGadgetProperty(property, thunkAPI, message) {
  if (!property) {
    return thunkAPI.rejectWithValue(showAlert({
      title: "Unable to save gadget",
      titleType: "warning",
      text: message
    }))
  }
}

function getNextId(data) {
  if (data?.length === 0 || data === null) return 1
  const uniqueIds = new Set()

  data.forEach(item => {
    if (item && typeof item === "object" && "ID" in item) {
      uniqueIds.add(parseInt(item["ID"]))
    }
  })

  const maxId = Math.max(...[...uniqueIds])

  return maxId + 1
}
