import React, { useState } from "react"
import Panel from "../../../../Panel"
import Table from "../../../../Table"
import DetailTabView from "./styles"

export default function DetailTabReadOnly({ controller, field }) {
  const entityName = field.ID

  const entityPath = controller.models()._REGISTRATION_DATA[field.TABLENAME].detailTabsPath[entityName]

  const [selectedItem, setSelectedItem] = useState(null)
  const [recordsAmount, setRecordsAmount] = useState(null)
  const [searchText, setSearchText] = useState("")
  const [updateTable, setUpdateTable] = useState(false)

  const props = {
    controller,
    entityName,
    viewType: "readOnly",
    selectedItem,
    setSelectedItem,
    recordsAmount,
    setRecordsAmount,
    searchText,
    setSearchText,
    updateTable,
    setUpdateTable,
    entityPath
  }

  return (
    <DetailTabView>
      <Panel {...props} />

      <Table
        {...props}
        selectedCallback={obj => setSelectedItem(obj)}
        entityPath={entityPath}
      />
    </DetailTabView>
  )
}
