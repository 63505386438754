import { Entypo } from "@expo/vector-icons"
import styled from "rn-css"
import globalStyles from "../../../../../../../../../../global/globalStyles"

export const Container = styled.View`
  background-color: ${({ isMarked }) => isMarked ? globalStyles.colors.secondary : "transparent"};

  justify-content: center;
  align-items: center;

  width: 16px;
  height: 16px;

  border: ${({ isMarked }) => isMarked ? 0 : 2}px solid ${({ isSelected }) => isSelected ? globalStyles.colors.tertiary : "gray"};
  border-radius: 2px;
`

export const Icon = styled(Entypo).attrs({ name: "check" })`
  color: ${globalStyles.colors.tertiary};
  font-size: 12px;

  display: ${({ isMarked }) => isMarked ? "flex" : "none"};
`
