import { Entypo } from "@expo/vector-icons"
import { Platform } from "react-native"
import styled from "styled-components/native"
import globalStyles from "../../../../../global/globalStyles"

const isWeb = Platform.OS === "web"

export const Container = styled.View`
  background-color: ${globalStyles.colors.tertiary};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: center;
  height: ${isWeb ? globalStyles.sizes.inputHeight :40}px;
  padding: 0 5px;
  border-radius: 4px;
`

export const Title = styled.Text`
  color: ${globalStyles.colors.primary};
  font-weight: 500;
  font-size: ${globalStyles.sizes.fontSize}px;
  margin-left: 4px;
`

export const Box = styled.TouchableOpacity`
  background-color: ${({ isActive }) => isActive ? globalStyles.colors.secondary : "transparent"};
  width: ${isWeb ? 14 : 26}px;
  height: ${isWeb ? 14 : 26}px;
  justify-content: center;
  align-items: center;
  border: 1px solid gray;
  border-width: ${({ isActive }) => isActive ? 0 : 1}px;
  border-radius: 2px;
`

export const Icon = styled(Entypo)`
  display: ${({ isActive }) => isActive ? "flex" : "none"};
  color: ${globalStyles.colors.tertiary};
  font-size: ${isWeb ? 12 : 14}px;
`
