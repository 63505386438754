import styled from "rn-css"
import globalStyles from "../../../../../../global/globalStyles"

const DetailTabView = styled.View`
  flex: 1;
  min-height: 370px;
  padding: 3px;
  border-radius: 3px;
  background-color: ${globalStyles.colors.tertiary};
`

export default DetailTabView
