import { Platform } from "react-native"

export const colors = {
  "black": "#000000",
  "white": "#FFFFFF",
  "white-transparent-61": "#FFFFFF9C",
  "gray-100": "#F5F5F5",
  "gray-200": "#EBEBEB",
  "gray-300": "#CCCCCC",
  "gray-400": "#C9C9C9",
  "gray-500": "#888888",
  "gray-600": "#707070",
  "gray-700": "#535353",
  "gray-900": "#2E2E2E",
  "cyan-300": "#51DACF",
  "cyan-500": "#00ACC1",
  "cyan-700": "#2B7F86",
  "cyan-800": "#297D85",
  "red-500": "#FF1A1A"
}

export default {
  colors: {
    //MAIN COLORS
    primary: "#297D85",
    secondary: "#51DACF",
    tertiary: "#f5f5f5",
    quaternary: "#297D85",
    background: "#EBEBEB",
    placeholder: "#CECECE",
    success: "#2ECC71",
    //BUTTONS
    addButton: "#51DACF",
    cancelButton: "#FF6663",
    editButton: "#f3852b",
    buttonIcon: "#FFFFFF",
    removeButton: "#d54545",
    returnButton: "#f3852b",
    switchButton: "#2260BD",
    //INPUTS
    inputsBackground: "#FFFFFF",
    //TEXTS
    textTitle: "#fff",
    textCard: "#2E2E2E",
    //OPACITYS
    opacity: "rgba(0, 0, 0, 0.8)",
    opacity2: "rgba(0, 0, 0, 0.85)",
    opacity060: "rgba(0, 0, 0, 0.60)",
    //CARDS
    cards: "#fffafa",
    //GRID
    gridRowColor: "#CEDADA",
    tab: "#2E2E2E",
    selectedTab: "#51DACF",
    formBackground: "#DEE5E5"
  },
  sizes: {
    fontSize: 14,
    inputHeight: `${Platform.OS === "web" ? 26 : 36}`
  },
  shadow: {
    shadowColor: "#2e2e2e",
    shadowOffset: {
      width: 0,
      height: 3
    },
    shadowOpacity: 0.20,
    shadowRadius: 3.00,
    elevation: 7
  }
}

