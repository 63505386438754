import { FontAwesome } from "@expo/vector-icons"
import styled from "styled-components"
import { defaultTheme } from "../../../../../styles/themes/default"

export const Container = styled.div`
  align-items: center;
  background-color: ${defaultTheme["gray-100"]};
  border-radius: 9px;
  display: flex;
  gap: 5.4px;
  justify-content: center;
  height: 24.8px;
  width: 32.4px;
  cursor: pointer;
`

export const IconContainer = styled.div`
  align-items: center;
  background-color: ${defaultTheme["cyan-300"]};
  border-radius: 50%;
  display: flex;
  height: 14.4px;
  justify-content: center;
  width: 14.4px;
`

export const Dropdown = styled.ul`
  position: absolute;
  right: 0;
  top: 28.8px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 9px;
  width: 225px;
  max-height: 270px;
  padding: 7.2px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 9px;
  border: 1px solid ${defaultTheme["cyan-300"]};
  box-shadow: 0px 3.6px 3.6px rgba(0, 0, 0, 0.25);
  background-color: ${defaultTheme["white"]};
  z-index: 999;
`

export const DropdownItem = styled.li`
  width: 100%;
  padding-bottom: 4.5px;
  padding-top: 4.5px;
  padding-left: 5.4px;
  border-radius: 1.8px;
  border-bottom: 1px solid ${defaultTheme["gray-300"]};
  cursor: pointer;
  color: ${defaultTheme["gray-700"]};
  font-size: 12.6px;
  font-weight: 500;
  transition: background-color 0.3s ease;
  ::before {
    content: "›  ";
  }
  &:hover {
    border-color: ${defaultTheme["cyan-500"]};
  }
`

export const Icon = styled(FontAwesome).attrs({
  name: "ellipsis-h",
  color: defaultTheme["white"],
  size: 12.6
})``