export default (errorCode) => {
  switch (errorCode) {
    case "auth/invalid-email": return "Invalid email address"
    case "auth/user-disabled": return "This account has been disabled by an administrator"
    case "auth/user-not-found": return "User not found. Please check your email address"
    case "auth/wrong-password": return "Incorrect password. Please try again"
    case "auth/email-already-in-use": return "Email is already in use. Please choose a different one"
    case "auth/too-many-requests": return "Too many unsuccessful login attempts. Please try again later"
    case "auth/invalid-custom-token": return "Invalid custom token format or token is invalid"
    case "auth/custom-token-mismatch": return "Custom token corresponds to a different audience"
    case "auth/invalid-credential": return "Invalid auth credential. Malformed or expired"
    case "auth/invalid-grant": return "User credential is no longer valid. Please sign in again"
    case "auth/missing-password": return "Password must be provided"
    case "auth/missing-email": return "Email must be provided"
    case "auth/missing-phone-number": return "Phone number must be provided"
    case "auth/missing-verification-code": return "Verification code must be provided"
    case "auth/invalid-phone-number": return "Invalid phone number"
    case "auth/invalid-verification-code": return "Invalid verification code"
    case "auth/invalid-action-code": return "Invalid action code"
    case "auth/expired-action-code": return "Action code has expired"
    case "auth/invalid-id-token": return "Invalid Firebase ID token"
    default: return "An unexpected error occurred. Please try again later"
  }
}
