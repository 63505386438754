import RuntimeConfiguration from "../models/runtimeConfiguration"
import dbRequest from "../services/dbRequest/dbRequest"

export default class RequestController {

  constructor() {
    this.isAdminRequest = RuntimeConfiguration.getIsDeveloperConfigurationMode()
  }

  formatID(ID) {
    let formattedID = ID.replace(/\s/g, "").replace(/[0-9]/g, "") // Remove white spaces and numbers (0-9)

    formattedID = formattedID.replace(/[^a-zA-Z_]/g, "").toUpperCase() // Remove special characters, except '_'; Convert to uppercase

    return this.isAdminRequest ? formattedID : `Z_${formattedID}`
  }

  adminValidation(request) {
    if (this.isAdminRequest) request = request.admin()
    return request
  }

  async load(tableID, recordID = null) {
    let request = dbRequest.loadRecords(tableID)

    if (recordID) request = request.where("ID", "==", recordID)

    request = this.adminValidation(request)

    return await request.execute()
  }

  async save(tableID, record) {
    let request = dbRequest.saveRecords(tableID).record({ ...record })

    request = this.adminValidation(request)

    await request.execute()
  }

  async update(tableID, record) {
    let request = dbRequest.updateRecords(tableID).record({ ...record })

    request = this.adminValidation(request)

    await request.execute()
  }

  async delete(tableID, record) {
    let request = dbRequest.deleteRecords(tableID).record({ ...record })

    request = this.adminValidation(request)

    await request.execute()
  }
}
