import styled from "styled-components"
import { defaultTheme } from "../../../../../styles/themes/default"

export const Container = styled.div`
  position: relative;
  height: auto;
  padding: 15px;
  border-radius: 10px;
  
  background-color: ${defaultTheme["white"]};
  box-shadow: 5px 5px 15px #00000029;
`

export const ModalTitle = styled.h1`
  color: ${defaultTheme["gray-700"]};
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  letter-spacing: .8px;
  margin: 0px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
`

interface ButtonProps {
  canPressButton: boolean
}

export const OptionButton = styled.button<ButtonProps>`
  position: relative;
  background-color: ${({ canPressButton }) => canPressButton ? "transparent" : defaultTheme["gray-100"]};
  display: flex;
  flex-direction: column;
  padding-top: 25px;
  align-items: center;
  height: 130px;
  width: 130px;
  border: none;
  border-radius: 5px;
  outline: none;
  cursor: ${({ canPressButton }) => canPressButton ? "pointer" : "not-allowed"};
  box-shadow: 3px 3px 8px #00000029;

  &:hover {
    outline: ${({ canPressButton }) => canPressButton ? "2px solid " + defaultTheme["cyan-300"] : "none"}; 
  }
`

export const OptionTitle = styled.span`
  color: ${defaultTheme["gray-700"]};
  font-weight: 500;
  text-align: center;
  margin: 0px;

  position: absolute;
  bottom: 8px;
`
