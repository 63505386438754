import React, { useRef, useState } from "react"
import Navigation from "../../../../../../models/navigation"
import { defaultTheme } from "../../../../../../styles/themes/default"
import copyRecord from "../../../../../screens/LauncherPanelScreen/utils/copyRecord"
import deleteRecord from "../../../../../screens/LauncherPanelScreen/utils/deleteRecord"
import insertRecords from "../../../../../screens/LauncherPanelScreen/utils/insertRecords"
import openRecord from "../../../../../screens/LauncherPanelScreen/utils/openRecord"
import Grid from "../../../../Grid"
import LoadingAnimation from "../../../../LoadingAnimation"
import ModalWrapper from "../../../../Modal"
import FormModal from "./Modal"
import * as S from "./styles.web"
import handleCopy from "./utils/ButtonsFunctions/handleCopy"
import handleDelete from "./utils/ButtonsFunctions/handleDelete"
import handleInsert from "./utils/ButtonsFunctions/handleInsert"

export default function DetailTab({ models, field }) {
  const gridRef = useRef(null)

  const { ID, TABLENAME, FIELDTYPE, T_DTD_ATR } = field

  const [modalVisible, setModalVisible] = useState(false)
  const [updateGrid, setUpdateGrid] = useState(false)
  const [updateForm, setUpdateForm] = useState(false)

  const [loadingFormConfiguration, setLoadingFormConfiguration] = useState(false)

  const registrationProps = {
    callback: null,
    closeModal: () => setModalVisible(false),
    entity: ID,
    detailTabPath: models._REGISTRATION_DATA[TABLENAME].detailTabsPath[ID],
    field,
    gridRef,
    modalVisible,
    models,
    screenType: "registrationDetail",
    setModalVisible,
    setUpdateForm,
    setUpdateGrid,
    updateForm,
    updateGrid,
    setLoadingFormConfiguration
  }

  const launcherProps = {
    entity: ID,
    entityName: T_DTD_ATR.FIELDDESCRIPTION,
    models,
    navigation: Navigation,
    gridRef,
    setIsLoading: setLoadingFormConfiguration,
    screenType: "orderPanel",
    detailTabPath: models._REGISTRATION_DATA[TABLENAME].detailTabsPath[ID]
  }

  const registrationFunctions = {
    handleInsert: () => handleInsert(registrationProps),
    handleDelete: ({ changeLoadingModalVisibility }) => handleDelete({
      ...registrationProps,
      changeLoadingModalVisibility
    }),
    handleCopy: () => handleCopy(registrationProps)
  }

  const launcherFunctions = {
    handleInsert: () => insertRecords(launcherProps),
    handleDelete: () => deleteRecord(launcherProps),
    handleOpen: () => openRecord(launcherProps),
    handleCopy: () => copyRecord(launcherProps)
  }

  const gridProps = FIELDTYPE.LABEL === "Launcher Panel Detail" ? {
    ...launcherProps,
    functions: launcherFunctions,
    rowDoubleClickCallback: launcherFunctions.handleOpen
  } : {
    ...registrationProps,
    functions: registrationFunctions,
    rowDoubleClickCallback: () => setModalVisible(true),
    screenType: "registrationDetail"
  }

  return (
    <S.Container>
      <Grid {...gridProps} />

      {modalVisible && <FormModal {...gridProps} />}

      <ModalWrapper isVisible={loadingFormConfiguration}>
        <S.LoadingModal>
          <LoadingAnimation size="large" color={defaultTheme["cyan-800"]} />
        </S.LoadingModal>
      </ModalWrapper>
    </S.Container>
  )
}
