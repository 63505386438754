import RuntimeConfiguration from "../../../../models/runtimeConfiguration"

export default async () => {
  const activeBranches = RuntimeConfiguration.getUserMddData().BRANCHID

  const activeBranchesKeys = Object.keys(activeBranches)

  const allBranches = RuntimeConfiguration.getAllBranches()

  const branchs = allBranches.filter(({ ID }) => activeBranchesKeys.includes(ID))
    .map(({ ID, BRANCHNAME }) => ({
      label: BRANCHNAME,
      value: ID
    }))

  const branchsFormatted = {}

  branchs.forEach(({ label, value }) => {
    branchsFormatted[value] = label
  })

  return branchsFormatted
}
