import styled from "rn-css"
import globalStyles from "../../../../../../global/globalStyles"

const Container = styled.View`
  background-color: ${globalStyles.colors.tertiary};

  width: 25%;
  max-height: 60%;
  flex: 1;

  align-self: center;

  padding: 10px;
  gap: 5px;

  border-radius: 10px;
`

export default Container
