import dbRequest from "../../../../../../../services/dbRequest/dbRequest"
import showAlert from "../../../../../../../services/showAlert"

export default async (entityName, selectedItem) => {
  const data = await dbRequest
    .loadRecords(entityName)
    .where("MASTERID.ID", "==", selectedItem.ID)
    .execute()

  const hasChildRecords = data.length > 0

  if (!hasChildRecords) return false

  showAlert({
    text: "The record has child records",
    title: "Failed to delete",
    titleType: "error"
  })

  return true
}
