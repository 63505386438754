import Permissions from "../../../../models/permissions"

export default (component, viewType, mode) => {
  const componentsVisibility = {
    fieldsButton: ["onlyForm"].includes(viewType) || ["registration"].includes(viewType) && mode === "form",
    filtersArea: mode === "table" && ["registration", "orderPanel", "tab"].includes(viewType),
    filtersButton: ["registration", "orderPanel", "tab"].includes(viewType) && mode === "table",
    idSourceInput: Permissions.getPermission("MANUALID") && (mode === "form" || viewType === "onlyForm"),
    moreOptionsButton: !["readOnly", "onlyForm"].includes(viewType),
    searchInput: mode === "table" || viewType !== "registration",
    totalizer: viewType !== "onlyForm"
  }

  return componentsVisibility[component]
}
