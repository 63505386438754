import { httpsCallable } from "firebase/functions"
import { localFunctions } from "../../dbConnection/"

export default async function getReport(id, params) {
  const bigqueryFunction = httpsCallable(localFunctions, "getReport")

  const result = await bigqueryFunction({
    id: id,
    params: params
  })

  return result.data
}
