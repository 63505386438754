import { useEffect, useState } from "react"
import MenuController from "../../../../controllers/MenuController"
import Observable from "../../../../models/Observable"
import menuConstructor from "../../../../services/menuConstructor"
import getBranchs from "../utils/getBranchs"

export const menuDrawerObserver = new Observable()

export function useMenu() {

  const [controller] = useState(new MenuController())
  const [menuOptions, setMenuOptions] = useState([])
  const [update, setUpdate] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [currentMenu, setCurrentMenu] = useState([])
  const [showMoreOptionsModal, setShowMoreOptionsModal] = useState(false)
  const [branchs, setBranches] = useState([])

  menuDrawerObserver.unsubscribeAll()
  const updateMenu = () => setUpdate(s => !s)
  menuDrawerObserver.subscribe(updateMenu)

  useEffect(() => {
    setIsLoading(true)
    menuConstructor().then((response) => {
      setMenuOptions(response)
      setCurrentMenu(response)
      getBranchs().then(setBranches)
      setIsLoading(false)
    })
  }, [update])

  return {
    controller,
    menuOptions,
    isLoading,
    currentMenu,
    setCurrentMenu,
    showMoreOptionsModal,
    setShowMoreOptionsModal,
    branchs
  }
}
