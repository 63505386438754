import styled from "rn-css"
import globalStyles from "../../../../../global/globalStyles"

export const CenteredView = styled.View`
  background-color: ${globalStyles.colors.formBackground};

  align-items: center;
  justify-content: center;

  width: 100%;
  height: 90px;
`

export const Container = styled(CenteredView)`
  flex-direction: row;

  justify-content: space-around;
`

export const FormContainer = styled.View`
  width: 90%;
  height: 100%;
`

export const Button = styled.TouchableOpacity`
  background-color: ${globalStyles.colors.primary};

  justify-content: center;
  align-items: center;

  width: 85px;
  height: 35px;

  border-radius: 4px;
`

export const ButtonText = styled.Text`
  color: ${globalStyles.colors.tertiary};
  font-size: 14px;
`
