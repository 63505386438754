import CurrencyInput from "react-native-currency-input"
import styled from "styled-components"
import styledRN from "styled-components/native"
import { defaultTheme } from "../../../../styles/themes/default"

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-self: center;
  width: 95%;
  height: max-content;
  gap: 15px;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 14px;
  border: 1px solid ${defaultTheme["gray-400"]};
  background-color: ${defaultTheme["white"]};
`

export const FieldContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 45px;
`

export const FieldTitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${defaultTheme["cyan-700"]};
  font-weight: lighter;
  font-size: 14px;
  margin-left: 5px;
  margin-bottom: 3px;
`

export const FieldValue = styled.div`
  padding: 5px 5px 5px 10px;
  font-size: 12px;
  color: ${defaultTheme["gray-700"]};
  background-color: ${defaultTheme["gray-200"]};
  outline: 1px solid ${defaultTheme["gray-400"]};
  border-radius: 6px;
`

export const InfoButton = styled.button`
  position: absolute;
  width: 20px;
  height: 20px;
  top: -5px;
  right: -8px;
  border-radius: 50%;
  border: none;
  background-color: ${defaultTheme["cyan-700"]};

  color: ${defaultTheme["white"]};
  font-weight: 600;
  cursor: pointer;

  &:active {
    opacity: .8;
  }
`

export const QuantityInputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 26px;
  padding: 0 5px;
  background-color: ${defaultTheme["white"]};
  outline: 1px solid ${defaultTheme["gray-400"]};
  border-radius: 6px;
`

export const DecreaseButton = styled.button`
  width: 24px;
  height: 24px;
  color: ${defaultTheme["white"]};
  font-size: 16px;
  font-weight: 600;
  border-radius: 50%;
  border: none;
  background-color: ${defaultTheme["red-400"]};
  cursor: pointer;

  &:active {
    opacity: .8;
  }
`

export const IncreaseButton = styled(DecreaseButton)`
  background-color: ${defaultTheme["cyan-700"]};
`

export const QuantityInput = styledRN(CurrencyInput)`
  width: 70%;
  height: 100%;
  border: none;
  text-align: center;
`

export const MarkerContainer = styled.div`
  position: absolute;
  right: 15px;
  top: 4px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: transparent;
`

export const RemoveButton = styled.button`
  position: absolute;
  right: 15px;
  top: 4px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15%;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
  background-color: ${defaultTheme["red-400"]};
  cursor: pointer;
  border: none;
`
