function applyMobileFilters(menus) {

  function filterSubMenus(subMenus) {
    return subMenus.filter(menu => {
      if (menu.isLauncher) {
        if (menu.ONMOB === undefined || !menu.ONMOB) {
          return false
        }
      }

      if (menu.SUB_MENUS && menu.SUB_MENUS.length > 0) {
        menu.SUB_MENUS = filterSubMenus(menu.SUB_MENUS)
      }

      return true
    })
  }

  return filterSubMenus(menus)
}

export default applyMobileFilters
