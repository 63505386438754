import { utils, writeFile } from "xlsx"
import getPadronizedData from "./getPadronizedData"
import getTableData from "./getTableData"

export default async function handleExport({ entityName, controller }) {
  const tableData = await getTableData({
    entityName,
    controller
  })

  const padronizedData = getPadronizedData(tableData)

  const wb = utils.book_new()
  const ws = utils.json_to_sheet([])

  const { header } = tableData

  utils.sheet_add_json(ws, padronizedData, {
    origin: "A1",
    header
  })

  utils.book_append_sheet(wb, ws, `${entityName}`)

  writeFile(wb, `${entityName}.xlsx`)
}
