import dbRequest from "../../../../services/dbRequest/dbRequest"
import formValidation from "../../../../services/formValidation"
import runTriggers from "../../../../services/registration/runTriggers"
import showAlert from "../../../../services/showAlert"

const handleSave = async ({ masterEntity, models, fields, setIsLoading, handleNewDocument, detailTabPath, setUpdateForm }) => {
  if (formValidation({
    entity: masterEntity,
    models,
    fields
  })) {
    try {
      showAlert({
        type: "confirm",
        text: "Do you want to save the record?",
        title: "Record saving",
        titleType: "question",
        handleConfirm: async () => {
          setIsLoading(true)
          models._REGISTRATION_DATA[masterEntity].operation = "INS"
          models._REGISTRATION_DATA[masterEntity].state = "BEFORE"

          const allTriggersValid = await runTriggers(models, masterEntity)
          if (!allTriggersValid) return null

          await dbRequest
            .saveRecords(detailTabPath || masterEntity)
            .record({ ...models[masterEntity] })
            .execute(response => {
              models[masterEntity].ID = response.ID
            })

          models._REGISTRATION_DATA[masterEntity].state = "AFTER"
          await runTriggers(models, masterEntity)
          setUpdateForm(s => !s)
          setIsLoading(false)
          await handleNewDocument()
        }
      })
    } catch (error) {
      showAlert({
        text: error.message,
        title: "Error",
        titleType: "error"
      })
    }
  } else setIsLoading(false)
}

export default handleSave
