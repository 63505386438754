import { useEffect, useState } from "react"
import evaluateFunction from "../../../../../../../../services/evaluateFunction"
import { Field } from "../../../../../../../../services/registration/getTableFields"

interface UseDatetimeProps {
  models: any
  field: Field
  updateForm: boolean
  setUpdateForm: (s) => void
}

export default function useDatetime({ models, field, updateForm, setUpdateForm }: UseDatetimeProps) {
  const { ID, TABLENAME } = field

  const ATR = field.T_DTD_ATR

  const [state, setState] = useState("")

  const updateValues = (value: string | null) => {
    models[TABLENAME][ID] = formatValue(value)
    setState(formatValue(value) ?? "")
  }

  const onBlur = () => {
    models[TABLENAME][ID] = formatValue(state)
    setUpdateForm(s => !s)
  }

  useEffect(() => {
    if (ATR.EXPRESSION) {
      evaluateFunction(ATR.EXPRESSION, models)
        .then(r => {
          if (r) updateValues(r)
        })
      return
    }

    updateValues(models[TABLENAME][ID] || ATR.DEFAULTVALUE || "")
  }, [updateForm])

  return {
    state,
    setState,
    onBlur
  }
}

function formatValue(value: string): string {
  if (value) {
    return value.length === 19 // YYYY-MM-DDTHH:MM:SS
      ? value
      : `${value}:00`
  }

  return null
}
