import allRequiredFieldsFilled from "../../../../../../../../../../../services/registration/allRequiredFieldsFilled"
import showAlert from "../../../../../../../../../../../services/showAlert"

export default async (controller, setUpdateTable, callback) => {
  const models = controller.models()

  if (!await allRequiredFieldsFilled({
    models,
    entity: "T_DTD_ATR"
  })) return

  showAlert({
    type: "confirm",
    text: "Do you want to save?",
    title: "Parameter saving",
    handleConfirm: async () => {
      await controller.saveParameter()
      models.T_DTD_FLD.reset()
      models.T_DTD_ATR.reset()
      callback()
      setUpdateTable(s => !s)
    }
  })
}
