import { MaterialCommunityIcons } from "@expo/vector-icons"
import styled from "rn-css"
import globalStyles from "../../../../global/globalStyles"

export const Container = styled.View`
  flex: 1;
`

export const Row = styled.View`
  flex-direction: row;
  align-items: center;

  margin-top: 5px;
  gap: 10px;
`

export const SearchView = styled.View`
  flex-direction: row;
`

export const SearchTextInput = styled.TextInput`
  background-color: ${globalStyles.colors.tertiary};
  color: ${globalStyles.colors.primary};

  width: 250px;
  height: 25px;

  padding: 3px;

  font-size: ${globalStyles.sizes.fontSize}px;

  border-radius: 3px;

  outline-style: none;
`

export const SearchButton = styled.TouchableOpacity`
  background-color: transparent;

  position: absolute;

  right: 2px;
`

export const SearchIcon = styled(MaterialCommunityIcons)`
  color: ${globalStyles.colors.primary};

  font-size: 24px;
`

export const SelectView = styled.View`
  background-color: ${globalStyles.colors.tertiary};

  flex: 1;

  justify-content: center;
  align-items: center;

  margin-top: 2px;
`

export const SelectText = styled.Text`
  color: grey;

  font-size: 20px;
`

export const InfoView = styled.View`
  flex-direction: row;

  height: 80px;

  margin: 5px 0 0 5px;
  gap: 5px;
`

export const ButtonsView = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;

  gap: 4px;
`

export const Button = styled.TouchableOpacity`
  background-color: ${globalStyles.colors.primary};

  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 2px;

  border-radius: 2px;
`

export const ButtonText = styled.Text`
  color: ${globalStyles.colors.tertiary};

  font-size: ${globalStyles.sizes.fontSize}px;
`
