/* eslint-disable react/display-name */
import { useIsFocused } from "@react-navigation/native"
import React, { memo, useCallback, useEffect, useState } from "react"
import Observable from "../../../../models/Observable"
import { useAdditionalFilters } from "./useAdditionalFilters"
import useFilterRecords from "./useFilterRecords"
import { usePagination } from "./usePagination"

export const updateListOberserver = new Observable()

export default function useListManager(
  controller,
  changeScreen,
  Card,
  searchText,
  applyFilters,
  update,
  showDefaultFields
) {
  const { applyFilters: filterAdditionalFilters } = useAdditionalFilters(controller)
  const { paginate, loadMore, hasMoreToLoad, loadingMoreData, resetPagination } = usePagination(20)
  const isFocused = controller.screenName === "Order Summary" && useIsFocused()

  const [allRecords, setAllRecords] = useState([])
  const [records, setRecords] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const loadData = async (reset = false) => {
    setIsLoading(true)

    if (reset) resetPagination()

    let data = await controller.getRecords(setIsLoading)

    if (applyFilters) {
      data = filterAdditionalFilters(data)
    }

    setAllRecords(data)
    setRecords(paginate(data))

    setIsLoading(false)
  }

  const filteredRecords = useFilterRecords(allRecords, searchText, controller)

  useEffect(() => {
    setRecords((prevRecords) => {
      const paginatedFilteredRecords = paginate(filteredRecords)
      if (JSON.stringify(prevRecords) === JSON.stringify(paginatedFilteredRecords)) {
        return prevRecords
      }
      return paginatedFilteredRecords
    })
  }, [filteredRecords, paginate])

  const ListItem = memo(({ item }) => (
    <Card
      item={item}
      controller={controller}
      onHandlePress={changeScreen}
      showDefaultFields={showDefaultFields}
    />
  ))

  const renderItem = useCallback(({ item }) => <ListItem item={item} />, [ListItem])
  const getItem = useCallback((_data, index) => _data[index], [])
  const getItemCount = useCallback((_data) => _data.length, [])
  const extractListKeys = useCallback((item, index) => index.toString(), [])

  const handleLoadMore = () => {
    if (hasMoreToLoad && !loadingMoreData) {
      loadMore(allRecords, setRecords)
    }
  }

  useEffect(() => {
    if (isFocused) {
      loadData(true)
    }
  }, [update, isFocused])

  return {
    renderItem,
    getItem,
    getItemCount,
    extractListKeys,
    records,
    isLoading,
    loadData,
    hasMoreToLoad,
    loadingMoreData,
    loadMore: handleLoadMore
  }
}
