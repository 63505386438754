import evaluateFunction from "../../../../../../../services/evaluateFunction"

export default async ({ field, models, orderedOptions, setSelectedOption }) => {
  const { ID, TABLENAME, T_DTD_ATR } = field

  const response = await evaluateFunction(T_DTD_ATR.EXPRESSION, models)

  if (response) {
    for (const option of orderedOptions) {
      const { LABEL, VALUE } = option

      if ([LABEL, VALUE].includes(response)) {
        const obj = {
          LABEL,
          VALUE
        }

        setSelectedOption(obj)
        models[TABLENAME][ID] = obj
      }
    }
  } else console.log(`${ID} EXPRESSION field returned ${response}`)
}
