import { useState, useEffect } from "react"
import dbRequest from "../../../../../../services/dbRequest/dbRequest"
import evaluateFunction from "../../../../../../services/evaluateFunction"
import showAlert from "../../../../../../services/showAlert"
import filterRecords from "../Search/utils/filterRecords"

export default function useSearch({ models, field, updateForm }) {
  const { ID, TABLENAME } = field

  const ATR = field.T_DTD_ATR

  const isEditable = ATR.EDITABLE

  const [inputData, setInputData] = useState({
    id: "",
    description: "",
    img: []
  })
  const [modalVisible, setModalVisible] = useState(false)
  const [staticRecords, setStaticRecords] = useState(null)
  const [masterTableRecords, setMasterTableRecords] = useState(null)

  async function handleSearch(inputType) {
    if (!isEditable) return
    if (!inputData.id && !inputData.description) return

    const filteredRecords = await filterRecords({
      fieldData: field,
      searchedValue: inputData[inputType],
      records: masterTableRecords
    })

    const hasNoMatchingRecords = filteredRecords.length === 0

    if (hasNoMatchingRecords)
      return showAlert({
        text: "Check the provided data",
        title: "No matching records",
        titleType: "warning"
      })

    const hasSingleMatchingRecord = filteredRecords.length === 1

    if (hasSingleMatchingRecord) return populateFields(filteredRecords[0])

    setStaticRecords(filteredRecords)
    setModalVisible(true)
  }

  const handlePress = () => {
    if (!isEditable) return
    if (inputData.id || inputData.description) clearValues()
    else setModalVisible(true)
  }

  function clearValues() {
    models[TABLENAME][ID] = ID === "ID" ? null : {}

    setInputData({
      id: "",
      description: "",
      img: []
    })

    setStaticRecords(null)
  }

  async function populateFields(record: any = {}) {
    if (ID === "ID") {
      const recordValue = typeof record === "string"
        ? await filterRecords({
          fieldData: field,
          searchedValue: record,
          records: masterTableRecords
        }).then(([r]) => r)
        : record

      models[TABLENAME][ID] = recordValue.ID

      setInputData({
        id: recordValue.ID,
        description: recordValue.DESCRIPTION,
        img: recordValue.IMG
      })

      return
    }

    models[TABLENAME][ID] = {
      ID: record.ID,
      DESCRIPTION: record.DESCRIPTION ?? "",
      IMG: record.IMG ?? []
    }

    setInputData({
      id: record.ID,
      description: record.DESCRIPTION,
      img: record.IMG
    })
  }

  useEffect(() => {
    if (!masterTableRecords)
      dbRequest.loadRecords(ATR.ENTITY.ID).execute(setMasterTableRecords)

    if (ATR.EXPRESSION) {
      evaluateFunction(ATR.EXPRESSION, models)
        .then(async id => {
          if (!id) return clearValues()
          const [record] = await filterRecords({
            fieldData: field,
            searchedValue: id.toString(),
            records: masterTableRecords
          })

          populateFields(record)
        })
      return
    }

    const modelValue = models[TABLENAME][ID]

    if (modelValue?.ID || typeof modelValue === "string") populateFields(modelValue)
    else clearValues()
  }, [updateForm])

  return {
    inputData,
    setInputData,
    modalVisible,
    setModalVisible,
    handleSearch,
    handlePress,
    masterTableRecords,
    populateFields,
    staticRecords
  }
}
