import styled from "styled-components/native"
import globalStyles from "../../../../../../../../../../../global/globalStyles"

const Container = styled.View`
  background-color: ${globalStyles.colors.background};
  padding: 5px 0;
  gap: 2px;
`

export default Container
