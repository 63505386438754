import React, { useContext, useState } from "react"
import Panel from "../../../../components/Panel"
import Table from "../../../../components/Table"
import { ControllerContext } from "../../contexts/ControllerProvider"
import { ModalsContext } from "../../contexts/ModalsProvider"
import { UpdateContext } from "../../contexts/UpdateProvider"
import CreateFieldModal from "../Modals/CreateField"
import CreateTranslationModal from "../Modals/CreateTranslation"
import ManageAttributesModal from "../Modals/ManageAttributes"
import ManageFunctionsModal from "../Modals/ManageFunctions"
import ManageListOptionsModal from "../Modals/ManageListOptions"
import ManageMenuModal from "../Modals/ManageMenu"
import ManageTriggersModal from "../Modals/ManageTriggers"
import OrderCardFieldsModal from "../Modals/OrderCardFields"
import OrderFieldsModal from "../Modals/OrderFields"
import OrderTabsModal from "../Modals/OrderTabs"
import * as S from "../styles"
import FunctionsButton from "./components/FunctionsButton"
import TableDataArea from "./components/TableDataArea"
import addField from "./utils/addField"
import getFieldsArray from "./utils/getFieldsArray"
import handleDelete from "./utils/handleDelete"
import handleFieldSelect from "./utils/handleFieldSelect"
import { useTranslation } from "react-i18next"

export default function FieldArea() {
  const { t } = useTranslation()

  const { controller, models } = useContext(ControllerContext)
  const { modalsVisible, openModal } = useContext(ModalsContext)
  const { updateFieldsTable, setUpdateFieldsTable, setUpdateTablesTable } = useContext(UpdateContext)

  const [isEdit, setIsEdit] = useState(false)
  const [recordsAmount, setRecordsAmount] = useState(null)

  const functions = {
    insertRecord: () => addField(models, setIsEdit, openModal),
    deleteRecord: () => handleDelete(controller, setUpdateFieldsTable)
  }

  return (
    <S.Container>
      <S.InfoView>
        <TableDataArea models={models.T_DTD_TAB} />

        <FunctionsButton controller={controller} openModal={openModal} setUpdateTablesTable={setUpdateTablesTable} />
      </S.InfoView>

      <Panel
        showSearchInput={false}
        entityName={"T_DTD_TAB"}
        controller={controller}
        viewType="readOnly"
        functions={functions}
        recordsAmount={recordsAmount}
        models={models}
      />

      {models.T_DTD_TAB.ID
        ? <Table
          entityName={"T_DTD_FLD"}
          controller={controller}
          viewType="readOnly"
          setRecordsAmount={setRecordsAmount}
          staticRecords={getFieldsArray({ ID: models.T_DTD_TAB.ID })}
          updateTable={updateFieldsTable}
          selectedCallback={obj => { handleFieldSelect(obj, models, setIsEdit, openModal) }}
          screenBuilder={true}
        />
        : <S.SelectView>
          <S.SelectText>{t("Select a table")}</S.SelectText>
        </S.SelectView>
      }

      {modalsVisible.field && <CreateFieldModal />}

      {modalsVisible.translation && <CreateTranslationModal />}

      {modalsVisible.trigger && <ManageTriggersModal />}

      {modalsVisible.function && <ManageFunctionsModal />}

      {modalsVisible.attributes && <ManageAttributesModal isEdit={isEdit} />}

      {modalsVisible.options && <ManageListOptionsModal />}

      {modalsVisible.manageMenu && <ManageMenuModal />}

      {modalsVisible.orderCardFields && <OrderCardFieldsModal />}

      {modalsVisible.orderFields && <OrderFieldsModal />}

      {modalsVisible.orderTabs && <OrderTabsModal />}
    </S.Container>
  )
}
