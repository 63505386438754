import RuntimeConfiguration from "../../../../../../../models/runtimeConfiguration"
import dbRequest from "../../../../../../../services/dbRequest/dbRequest"

export default async function getVariablesList({ variablesPath }) {
  const isAdminMode = RuntimeConfiguration.getDashboardBuilderAdminMode()

  const request = dbRequest.loadRecords(variablesPath)

  if (isAdminMode) request.admin()

  const list = await request.execute()

  return list
}
