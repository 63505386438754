const handleApplyFilters = async ({ controller, setIsLoading }) => {

  if (controller?.uniqueRecord) {
    setIsLoading(s => !s)
    await controller.startChangeScreen(controller?.uniqueRecord, setIsLoading)
  }

}

export default handleApplyFilters
