import recordIdExists from "../../../../../../../services/registration/recordIdExists"
import showAlert from "../../../../../../../services/showAlert"

export default async ({ models, entityName, setUpdateForm }) => {
  const tableData = models._REGISTRATION_DATA[entityName]

  if (!tableData.manualID || tableData.operation === "UPD") return false

  const existingID = await recordIdExists(entityName, models[entityName].ID)

  if (existingID) {
    models[entityName].ID = null
    setUpdateForm(s => !s)
    showAlert({
      text: "Record ID already exists",
      title: "Failed to save",
      titleType: "error"
    })
    return true
  }

  return false
}
