import dbRequest from "../dbRequest/dbRequest"

const detailTabHasRecords = async ({ entity, models }) => {
  const { fields, detailTabsPath } = models._REGISTRATION_DATA[entity]
  const detailTabs = fields.filter(({ FIELDTYPE }) => FIELDTYPE.VALUE === "E")

  for (const table of detailTabs) {
    const detailTabPath = detailTabsPath[table.ID]

    const doesTableHasRecords = await dbRequest
      .loadRecords(detailTabPath)
      .execute(([r]) => Boolean(r))

    if (doesTableHasRecords) return true
  }

  return false
}

export default detailTabHasRecords
