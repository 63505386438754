import React from "react"
import { useTranslation } from "react-i18next"
import * as S from "../styles"

const renderButtons = buttons => {

  const { t } = useTranslation()

  return (
    <S.Row>
      {buttons.map(({ iconName, divTitle, onPress }, index) => (
        <div title={t(divTitle)} key={index}>
          <S.Button onPress={onPress}>
            <S.Icon name={iconName} />
          </S.Button>
        </div>
      )
      )}
    </S.Row>
  )
}

export default renderButtons
