import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import Form from "../../../../../components/Form/index.web"
import ModalWrapper from "../../../../../components/Modal"
import Table from "../../../../../components/Table"
import { ControllerContext } from "../../../contexts/ControllerProvider"
import { ModalsContext } from "../../../contexts/ModalsProvider"
import renderButtons from "../utils/renderButtons"
import * as S from "./styles"
import getOptionsArray from "./utils/getOptionsArray"
import handleDelete from "./utils/handleDelete"
import handleSave from "./utils/handleSave"

export default function ManageListOptionsModal() {
  const { t } = useTranslation()

  const { controller, models } = useContext(ControllerContext)
  const { modalsVisible, closeModal } = useContext(ModalsContext)

  const [updateForm, setUpdateForm] = useState(false)
  const [updateTable, setUpdateTable] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)

  const buttons = [
    {
      iconName: "plus",
      divTitle: "Add new option",
      onPress: () => { resetModel() }
    },
    {
      iconName: "check",
      divTitle: "Add option",
      onPress: () => { handleSave(models, setUpdateTable, resetModel) }
    },
    {
      iconName: "trash",
      divTitle: "Delete selected option",
      onPress: () => handleDelete(controller, selectedOption, models, resetModel, setUpdateTable)
    },
    {
      iconName: "close",
      divTitle: "Close",
      onPress: () => {
        models.T_DTD_OPT.reset()
        closeModal("options")
      }
    }
  ]

  const resetModel = () => {
    models.T_DTD_OPT.reset()
    setSelectedOption(null)
    setUpdateForm(s => !s)
  }

  const handleSelect = obj => {
    setSelectedOption({ ...obj })
    models.T_DTD_OPT.populate({ ...obj })
    models._REGISTRATION_DATA.T_DTD_OPT.operation = "UPD"
    setUpdateForm(s => !s)
  }

  const infoText = `${t("Changes will be applied when saving the field configuration")} (${t("except for delete")})`

  return (
    <ModalWrapper isVisible={modalsVisible.options}>
      <S.Container maxHeight={70} width={60} >
        {renderButtons(buttons)}

        <S.InfoText>{infoText}</S.InfoText>

        <S.FormView>
          <Form models={models} entity={"T_DTD_OPT"} screenBuilder={true} updateForm={updateForm} />
        </S.FormView>

        <S.TableView>
          <Table
            entityName={"T_DTD_OPT"}
            controller={controller}
            viewType="readOnly"
            staticRecords={getOptionsArray(models.T_DTD_FLD.T_DTD_OPT)}
            screenBuilder={true}
            updateTable={updateTable}
            selectedCallback={handleSelect}
          />
        </S.TableView>
      </S.Container>
    </ModalWrapper >
  )
}
