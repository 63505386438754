import update from "immutability-helper"
import React, { useCallback } from "react"
import { useDrop } from "react-dnd"
import { View } from "react-native"
import globalStyles from "../../../../../../../../global/globalStyles"
import ItemTypes from "../../../../../../../components/Panel/components/FiltersConfiguration/components/ItemTypes"
import Card from "./components/Card"
import * as S from "./styles"

export default function DestinyBinList({
  destinyArray,
  setDestinyArray,
  alternativeDestinyArray,
  setAlternativeDestinyArray,
  setOriginArray
}) {

  const [{ canDrop: canDropPrimary, isOver: isOverPrimary }, dropPrimary] = useDrop({
    accept: ItemTypes.CARD,
    drop: () => ({ name: "PrimaryDustbin" }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  })

  const [{ canDrop: canDropAlternative, isOver: isOverAlternative }, dropAlternative] = useDrop({
    accept: ItemTypes.CARD,
    drop: () => ({ name: "AlternativeDustbin" }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  })

  const isActivePrimary = canDropPrimary && isOverPrimary
  const isActiveAlternative = canDropAlternative && isOverAlternative

  const getBackgroundColor = (isActive, canDrop) => {
    if (isActive) return "#bbf7d0"
    if (canDrop) return "#ffedd5"
    return globalStyles.colors.background
  }

  const moveCardPrimary = useCallback((dragIndex, hoverIndex) => {
    setDestinyArray((prevCards) => update(prevCards, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, prevCards[dragIndex]]
      ]
    }))
  }, [])

  const moveCardAlternative = useCallback((dragIndex, hoverIndex) => {
    setAlternativeDestinyArray((prevCards) => update(prevCards, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, prevCards[dragIndex]]
      ]
    }))
  }, [])

  const renderCard = (field, index, moveCard, setArray, isAccumulatorField = false) => (
    <Card
      key={field.ID}
      fieldData={field}
      index={index}
      moveCard={moveCard}
      setOriginArray={setOriginArray}
      setDestinyArray={setArray}
      isAccumulatorField={isAccumulatorField}
    />
  )

  return (
    <S.Container>
      <View style={{
        flex: .5,
        gap: 5
      }}>
        <S.Header>
          <S.Title>Grouping Fields</S.Title>
          <S.ColumnTitle>Sub Field</S.ColumnTitle>
        </S.Header>

        <S.List
          ref={dropPrimary}
          data-testid="primary-dustbin"
          style={{ backgroundColor: getBackgroundColor(isActivePrimary, canDropPrimary) }}
          contentContainerStyle={{ gap: 8 }}
        >
          {destinyArray?.map((field, i) => renderCard(field, i, moveCardPrimary, setDestinyArray))}
        </S.List>
      </View>

      <View style={{
        flex: .5,
        gap: 5
      }}>
        <S.Header style={{
          width: "100%",
          justifyContent: "left"
        }}>
          <S.Title>Accumulator Fields</S.Title>
          <View style={{
            width: "60%",
            flexDirection: "row",
            justifyContent: "space-around",
            marginLeft: 20
          }}>
            <S.ColumnTitle>Function</S.ColumnTitle>
            <S.ColumnTitle>Sub Field</S.ColumnTitle>
          </View>
        </S.Header>

        <S.List
          ref={dropAlternative}
          data-testid="alternative-dustbin"
          style={{ backgroundColor: getBackgroundColor(isActiveAlternative, canDropAlternative) }}
          contentContainerStyle={{ gap: 8 }}
        >
          {alternativeDestinyArray?.map((field, i) => renderCard(field, i, moveCardAlternative, setAlternativeDestinyArray, true))}
        </S.List>
      </View>
    </S.Container>
  )
}
