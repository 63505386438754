import styled from "rn-css"
import globalStyles from "../../../../../../../../../../global/globalStyles"

export const Container = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 3px;
`

export const Text = styled.Text`
  color: ${({ isSelected }) => isSelected ? globalStyles.colors.tertiary : globalStyles.colors.primary};
  font-size: 12px;
`
