import LoginData from "../../../../../../models/loginData"
import dbRequest from "../../../../../../services/dbRequest/dbRequest"
import addNativeFunctions from "./addNativeFunctions"

const getOptions = async ({ entity, models, mode }) => {
  const tableFunctions = await dbRequest
    .loadRecords(`T_DTD_TAB/${entity}/T_DTD_FNC`)
    .execute() as any

  const { permissions } = models._REGISTRATION_DATA[entity]

  const enabledFunctions = tableFunctions.filter(({ ACTIVE, ID, ONLYFORM = false }) => {
    const isFunctionActive = ACTIVE
    const isUserAdmin = LoginData.getLoginData().ADMIN
    const doesUserHasPermission = isUserAdmin || permissions[ID]
    const isNotOnlyForm = mode === "grid" ? !ONLYFORM : true

    return isFunctionActive && doesUserHasPermission && isNotOnlyForm
  })

  addNativeFunctions({
    functions: enabledFunctions,
    entity,
    permissions,
    mode
  })

  return enabledFunctions.map(option => ({
    ...option,
    text: option.DESCRIPTION,
    iconCss: option.EXPRESSION === "fnc_exportSpreadsheet" ? "e-icons e-export-excel" : "e-icons e-chevron-right"
  }))
}

export default getOptions
