import { FontAwesome } from "@expo/vector-icons"
import styled from "rn-css"
import globalStyles from "../../../../../global/globalStyles"

export const Container = styled.View`
  flex-direction: row;
`

export const Input = styled.TextInput`
  color: ${globalStyles.colors.primary};
  background-color: ${globalStyles.colors.tertiary};
  font-size: 16px;
  border-radius: 3px;
  width: 300px;
  height: 25px;
  padding: 3px;
  margin: 5px;
  outline-style: none;
`

export const Button = styled.TouchableOpacity`
  position: absolute;
  right: 20;
  align-self: center;
`

export const Icon = styled(FontAwesome)`
  color: ${globalStyles.colors.primary};
  font-size: 16px;
`
