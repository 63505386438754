import { useEffect, useState } from "react"
import evaluateFunction from "../../../../../../services/evaluateFunction"

export default function useHours({ models, field, updateForm, setUpdateForm }) {
  const { ID, TABLENAME, T_DTD_ATR } = field

  const [state, setState] = useState("")

  const changeValues = (value: string | number) => {
    const time = typeof value === "number" ? minutesToTime(value) : value
    setState(time)
    models[TABLENAME][ID] = value
  }

  const onBlur = () => {
    if (state && state.includes(":")) {
      models[TABLENAME][ID] = timeToMinutes(state)
    } else {
      models[TABLENAME][ID] = ""
    }
    setUpdateForm(s => !s)
  }

  useEffect(() => {
    if (T_DTD_ATR.EXPRESSION) {
      evaluateFunction(T_DTD_ATR.EXPRESSION, models)
        .then(r => {
          changeValues(r)
        })
      return
    }

    const modelValue = models[TABLENAME][ID]

    changeValues(modelValue ?? T_DTD_ATR.DEFAULTVALUE ?? "")
  }, [updateForm])

  return {
    state,
    setState,
    onBlur
  }
}

function minutesToTime(minutes: number) {
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60
  return `${String(hours).padStart(2, "0")}:${String(remainingMinutes).padStart(2, "0")}`
}

function timeToMinutes(time: string) {
  const [hours, minutes] = time.split(":")
  return Number(hours) * 60 + Number(minutes)
}
