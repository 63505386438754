import styled from "styled-components"

export const Container = styled.div`
  margin-left: 73px;
  margin-top: 8px;
  height: 93%;
`

export const Toolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  background-color: ${({ theme }) => theme["gray-200"]};
`