import { getDownloadURL, ref, uploadBytes } from "firebase/storage"
import { localStorage } from "../../dbRequest/dbConnection/index.web"
import showAlert from "../../showAlert"

export default async function saveFiles({ path, file }) {
  try {
    const storage = localStorage
    const storageRef = ref(storage, path)
    
    return uploadBytes(storageRef, file).then(async (snapshot) => ({
      url: await getDownloadURL(snapshot.ref),
      update: snapshot.metadata.updated
    }))
    
  } catch (error) {
    console.error(error)
    showAlert({
      title: "File upload failed.",
      type: "ok",
      titleType: "error",
      text: error
    })
  }

}
