export const htmlString = `
<!DOCTYPE html>
<html lang="pt-br">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Resultado</title>
<link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet">
<style>
    /* Estilo para fixar o título no topo */
    .fixed-title {
        position: fixed;
        top: 0;
        left: 50%; /* Para centralizar */
        transform: translateX(-50%);
        width: 100%;
        background-color: white;
        z-index: 1000; /* Para garantir que fique acima do resto do conteúdo */
        padding: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    /* Estilo para o conteúdo abaixo do título */
    .content {
        padding-top: 30px; /* Altura do título */
    }

    /* Estilo para a contagem de registros */
    .registros-count {
        font-size: 14px;
        font-weight: 500;
        padding: 8px;
    }

    /* Estilo para a tabela */
    table {
        width: 100%;
        border-collapse: collapse;
    }

    th, td {
        border: 1px solid #ccc;
        padding: 8px;
        text-align: left;
    }

    th {
        background-color: #f2f2f2;
    }

    /* Estilo para alternar cores das linhas */
    tr:nth-child(even) {
        background-color: #f2f2f2;
    }
</style>
</head>
<body class="bg-gray-400 p-8">

<div class="max-w-screen-md mx-auto bg-white shadow-md rounded-md p-6">
    <!-- Título fixo -->
    <h1 class="text-3xl font-semibold text-center mb-6 fixed-title">Resultado da execução da Expression</h1>
    <!-- Contagem de registros -->
    <p class="registros-count text-center">Total de registros: <span id="totalRegistros"></span></p>
    <!-- Conteúdo abaixo do título -->
    <div class="content">
        <!-- Tabela para exibir os objetos -->
        <table>
            <thead>
                <tr id="tableHeaders">
                    <!-- Cabeçalho da tabela preenchido dinamicamente -->
                </tr>
            </thead>
            <tbody id="tableBody">
                <!-- Corpo da tabela preenchido dinamicamente -->
            </tbody>
        </table>
    </div>
</div>

<script>
    // Função para exibir o array de objetos na forma de tabela
    function exibirArrayDeObjetosComoTabela(array) {
        var tableHeaders = document.getElementById('tableHeaders');
        var tableBody = document.getElementById('tableBody');
        var totalRegistros = document.getElementById('totalRegistros');

        // Limpa o conteúdo atual da tabela
        tableHeaders.innerHTML = '';
        tableBody.innerHTML = '';

        // Verifica se há pelo menos um objeto no array
        if (array.length > 0) {
            // Obtém as chaves do primeiro objeto para os cabeçalhos da tabela
            var keys = Object.keys(array[0]);

            // Preenche os cabeçalhos da tabela
            keys.forEach(function(key) {
                var th = document.createElement('th');
                th.textContent = key;
                tableHeaders.appendChild(th);
            });

            // Preenche o corpo da tabela com os valores dos objetos
            array.forEach(function(obj) {
                var tr = document.createElement('tr');
                keys.forEach(function(key) {
                    var td = document.createElement('td');
                    td.textContent = obj[key];
                    tr.appendChild(td);
                });
                tableBody.appendChild(tr);
            });

            // Atualiza a contagem de registros
            totalRegistros.textContent = array.length;
        }
    }

    // Array de objetos substituído
    var arrayDeObjetos = REPLACE_ARRAY_HERE;

    // Chamada para exibir o array de objetos na forma de tabela
    exibirArrayDeObjetosComoTabela(arrayDeObjetos);
</script>

</body>
</html>

`

