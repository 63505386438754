import { FontAwesome5 } from "@expo/vector-icons"
import React, { Fragment, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Navigation from "../../../../../models/navigation"
import Permissions from "../../../../../models/permissions"
import { defaultTheme } from "../../../../../styles/themes/default"
import Tab from "./components/Tab"
import * as S from "./styles"
import handleCloseTab from "./utils/handleCloseTab"
import handleTabPress from "./utils/handleTabPress"

export default function TabsContainer({ state, navigation, setSelectedTabName }) {
  const tabs = state.routes

  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const visibleTabs = tabs.filter((tab) => !["Overview"].includes(tab.name) && tab.params.screenType !== "Reports").slice(0, 7)
  const hiddenTabs = tabs.filter((tab) => !["Overview"].includes(tab.name) && tab.params.screenType !== "Reports").slice(7)

  const renderTabs = () => {
    return visibleTabs.map((tab, index) => {
      if (["Overview"].includes(tab.name) && tab.params.screenType !== "Reports") return null
      return (
        <Tab
          key={index}
          tabIndex={index}
          tabData={tab}
          state={state}
          navigation={navigation}
          setSelectedTabName={setSelectedTabName}
        />
      )
    })
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      const isCtrlOrCmdPressed = event.ctrlKey || event.metaKey
      const isKeyArrowLeft = event.key === "ArrowLeft"
      const isKeyArrowRight = event.key === "ArrowRight"

      const isShiftPressed = event.shiftKey
      const isKeyEsc = event.key === "Escape"

      const updatedState = navigation.getState()
      const updatedTabs = updatedState.routes
      const currentTabIndex = updatedState.index

      if (isShiftPressed && isKeyEsc) {
        event.preventDefault()
        navigation.navigate(updatedTabs[currentTabIndex - 1])
        setSelectedTabName(updatedTabs[currentTabIndex - 1].params?.RootParentID ?? "Overview")
        Navigation.closeTab(updatedTabs[currentTabIndex].name)
      }

      if (isCtrlOrCmdPressed && (isKeyArrowLeft || isKeyArrowRight)) {
        if (currentTabIndex === 0 || currentTabIndex === updatedTabs.length - 1) return

        const newTab = isKeyArrowLeft ? updatedTabs[currentTabIndex - 1] : updatedTabs[currentTabIndex + 1]

        if (["Overview"].includes(newTab.name) && newTab.params.screenType !== "Reports") return

        const event = navigation.emit({
          type: "tabPress",
          target: newTab.key,
          canPreventDefault: true
        })

        if (!event.defaultPrevented) {
          Permissions.setCurrentRoutine(newTab.params?.transaction)
          setSelectedTabName(newTab.params.RootParentID)
          navigation.navigate(newTab.name, newTab.params)
        }
      }
    }

    window.addEventListener("keydown", handleKeyDown)

    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, [])

  const onCloseTab = (tabData, tabIndex) => {
    handleCloseTab({
      navigation,
      state,
      tabData,
      isTabSelected: state.index === (tabIndex + 8),
      setSelectedTabName
    })
  }

  const onTabPress = (tabData, tabIndex) => {
    handleTabPress({
      navigation,
      state,
      tabData,
      isTabSelected: state.index === (tabIndex + 8),
      setSelectedTabName
    })
  }

  return (
    <Fragment>
      {/* <ScrollButton direction="left" {...commonProps} /> */}

      <S.Container>
        {renderTabs()}
        {hiddenTabs.length >= 1 && (
          <S.DropdownContainer>
            <S.DropdownButton onClick={toggleDropdown} isOpen={isOpen && hiddenTabs.length > 0}>
              <S.IconContainer>
                <FontAwesome5 name="ellipsis-h" size={8} color={defaultTheme["gray-500"]} />
              </S.IconContainer>
            </S.DropdownButton>
            <S.DropdownList isOpen={isOpen}>
              {hiddenTabs.map((tab, index) => (
                <S.DropdownListItem
                  key={tab.name}
                  index={index}
                  tabsLength={hiddenTabs.length}
                  onClick={() => onTabPress(tab, index)}
                  isTabSelected={state.index === (index + 8)}
                >
                  <S.TextContainer>{t(tab.name)}</S.TextContainer>
                  <S.CloseButton onClick={() => onCloseTab(tab, index)}>
                    <svg width="8" height="8" viewBox="0 0 9 9" xmlns="http://www.w3.org/2000/svg">
                      <line x1="1" y1="1" x2="8" y2="8" stroke={defaultTheme["gray-500"]} strokeWidth="1.5" strokeLinecap="round" />
                      <line x1="1" y1="8" x2="8" y2="1" stroke={defaultTheme["gray-500"]} strokeWidth="1.5" strokeLinecap="round" />
                    </svg>
                  </S.CloseButton>
                </S.DropdownListItem>
              ))}
            </S.DropdownList>
          </S.DropdownContainer>
        )}
      </S.Container>

      {/* <ScrollButton direction="right" {...commonProps} /> */}
    </Fragment>
  )
}
