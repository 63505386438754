import styled from "rn-css"
import globalStyles from "../../../global/globalStyles"

export const Container = styled.View`
  flex: 1;

  height: 100%;
`

export const Title = styled.Text`
  color: #2e2e2e;

  font-size: 14px;
  font-weight: 500;

  padding-left: 8px;
  margin: 8px 0 4px;
`

export const List = styled.ScrollView`
  background-color: ${globalStyles.colors.background};

  padding: 8px;

  border: 1px dashed lightgrey;
  border-radius: 10px;
`
