import React, { useEffect, useState } from "react"

import RenderDetailTabsList from "./components/RenderDetailTabsList"
import RenderList from "./components/RenderList"
import Container from "./styles"
import defaultButtons from "./utils/defaultButtons"
import Permissions from "../../../../../../../../../../../../../models/permissions"

export default function RenderPermissions({ item, permissions, models, entity }) {
  const [buttons, setButtons] = useState([])
  const [functions, setFunctions] = useState([])

  const transactionId = item.ID

  const populatePermissions = (arr, allPermission) => arr.map(i => {
    const currentPermission = allPermission.find(({ TRANSACTION }) => TRANSACTION === transactionId)
    return {
      ...i,
      isMarked: currentPermission?.[i.ID]
    }
  })

  const populateDashRepPermissions = (allPermission) => {
    const tempArray = []
    for (const fnc in item.T_DTD_FNC) {

      const fncObject = item.T_DTD_FNC[fnc]
      const currentPermission = allPermission.find(permission => permission?.[fncObject.ID])
      tempArray.push({
        ...fncObject,
        isMarked: currentPermission?.[fncObject.ID]
      })
    }
    return tempArray
  }

  const selectedRecord = models._REGISTRATION_DATA[entity].formattedRecords[models[entity].ID]

  const configurationData = {
    entity,
    record: selectedRecord
  }

  useEffect(() => {
    Permissions.getRoutines(configurationData)
      .then(allPermission => {
        if (["dashboards", "reports"].includes(item.ID)) {
          const dashRepPermissions = populateDashRepPermissions(allPermission)
          setButtons(dashRepPermissions)
          return
        }

        const activeFunctions = item.T_DTD_FNC.reduce((result, item) => {
          if (item.ACTIVE) result.push(item)
          return result
        }, [])

        const defaultButtonsPermissions = populatePermissions(defaultButtons(), allPermission)
        setButtons(defaultButtonsPermissions)

        const functionsPermissions = populatePermissions(activeFunctions, allPermission)
        setFunctions(functionsPermissions)
      })
  }, [models[entity].ID])

  return (
    <Container>
      <RenderList items={buttons} callback={setButtons} permissions={permissions} transactionId={transactionId} />

      <RenderList items={functions} callback={setFunctions} permissions={permissions} transactionId={transactionId} />

      <RenderDetailTabsList masterTab={item} permissions={permissions} models={models} entity={entity} />
    </Container>
  )
}
