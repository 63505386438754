import RuntimeConfiguration from "../../../../../../models/runtimeConfiguration"
import showAlert from "../../../../../../services/showAlert"

export default (controller, setUpdateFieldsTable) => {
  const models = controller.models()
  const { ID } = models.T_DTD_FLD

  if (!ID) return showAlert({
    text: "No field selected",
    title: "Failed to delete field",
    titleType: "error"
  })

  if (ID === "ID")
    return showAlert({
      text: "It is not possible to delete a PK field",
      title: "Failed to delete field",
      titleType: "error"
    })

  if (!ID.includes("Z_") && !RuntimeConfiguration.getIsDeveloperConfigurationMode())
    return showAlert({
      text: "It is not possible to delete a native field",
      title: "Failed to delete field",
      titleType: "error"
    })

  showAlert({
    type: "confirm",
    text: "Do you want to delete the selected field?",
    title: "This action cannot be undone",
    titleType: "warning",
    handleConfirm: async () => {
      await controller.deleteField()
      setUpdateFieldsTable(s => !s)
      models.T_DTD_FLD.reset()
    }
  })
}

