const handleDrop = ({ event, setIsDragging, fieldName, setSelectedFiles, setPreview }) => {
  event.preventDefault()
  setIsDragging(false)
  const files = [...event.dataTransfer.files]

  if (files.length > 0) {
    const file = files[0]
    setSelectedFiles(prev => ({
      ...prev,
      [fieldName]: file
    }))
    const reader = new FileReader()
    reader.onloadend = () => setPreview(reader.result)
    reader.readAsDataURL(file)
  }
}

export default handleDrop
