import React from 'react'
import * as S from "./styles"
import { useDispatch } from 'react-redux'
import useAppSelector from '../../../../../redux/hooks/useAppSelector'
import { setSelectedLevel } from '../../../../../redux/redux-reducers/dashboardBuilder-reducer'
import { deleteLevel } from '../../../../../redux/redux-reducers/dashboardBuilder-reducer/utils/deleteLevel'

export default function DashboardInterface() {

  const dispatch = useDispatch()

  const { levels, selectedLevel, selectedDashboard } = useAppSelector(({ dashboardBuilderReducer }) => dashboardBuilderReducer.dashboardData)

  return (
    <S.Container>
      <S.Header>
        <S.Title>Levels ({levels.length})</S.Title>
        <S.ButtonsView>
          <S.AddLevelButton onPress={() => dispatch(setSelectedLevel("new"))}>
            <S.TitleButton>New Level</S.TitleButton>
          </S.AddLevelButton>
          <S.DeleteLevelButton onPress={() => dispatch(deleteLevel({ selectedLevel, selectedDashboard }))}>
            <S.DeleteIcon name="trash-alt" />
          </S.DeleteLevelButton>
        </S.ButtonsView>
      </S.Header>
      <S.UnderLine />

      <S.ListArea contentContainerStyle={{ gap: 8 }}>
        {
          levels.map((item) => (
            <S.Card key={item.ID} isSelected={item.ID === selectedLevel?.ID} onPress={() => dispatch(setSelectedLevel({selectedLevel: item}))}>
              <S.CardFieldArea>
                <S.CartText style={{ fontWeight: "600" }}>Level: <S.CartText>{item.ID}</S.CartText></S.CartText>
              </S.CardFieldArea>
              <S.CardFieldArea>
                <S.CartText style={{ fontWeight: "600" }}>Description: <S.CartText>{item.DESCRIPTION}</S.CartText></S.CartText>
              </S.CardFieldArea>
              <S.CardFieldArea>
                <S.CartText style={{ fontWeight: "600" }}>Gadgets: <S.CartText>{item?.gadgets?.length || 0}</S.CartText></S.CartText>
              </S.CardFieldArea>
            </S.Card>
          ))
        }
      </S.ListArea>

    </S.Container>
  )
}