import dbRequest from "../../../../../../../../../../../../services/dbRequest/dbRequest"
import showAlert from "../../../../../../../../../../../../services/showAlert"

export default async ({ entity, closeModal, permissions, gridRef, models }) => {
  const selectedRecord = gridRef?.current?.getSelectedRecords()[0] || models[entity]

  const [record] = await dbRequest
    .loadRecords(entity)
    .where("ID", "==", selectedRecord.ID.toString())
    .execute() as any

  for (const key in record.T_SET_ACC) {
    const hasPreviousPermissions = Object.keys(record.T_SET_ACC[key]).length > 0

    if (!permissions[key] && hasPreviousPermissions)
      permissions[key] = {}

    permissions[key] = {
      ...record.T_SET_ACC[key],
      ...permissions[key]
    }
  }

  await dbRequest
    .saveRecords(entity)
    .record({
      ...record,
      T_SET_ACC: { ...permissions }
    })
    .execute()

  showAlert({
    text: "Access configurations will be applied after the next login",
    title: "Saved successfully",
    titleType: "success"
  })

  closeModal()
}
