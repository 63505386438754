import getDoubleClickEvent from "../../../../utils/getDoubleClickEvent"

export default function processSelectedField(state, { payload }) {
  const { field, path } = payload

  if (field) {
    state.modals.fieldModal.selectedField = field
    if (field?.T_DTD_OPT) state.modals.fieldModal.options = field?.T_DTD_OPT
    getDoubleClickEvent(doubleClickEvent => {
      if (doubleClickEvent.isActive) {
        state.modals.fieldModal.mode = "edit"
        state.modals.fieldModal.isVisible = true
        state.modals.fieldModal.isSaved = true
        state.modals.fieldModal.path = path
      }
    })
  } else {
    state.modals.fieldModal.mode = "new"
    state.modals.fieldModal.isVisible = true
    state.modals.fieldModal.path = path
  }
}
