import { getDownloadURL, listAll, ref } from "firebase/storage"
import { localStorage } from "../../dbRequest/dbConnection/index.web"

export default async function loadFiles({ path }) {
  const storage = localStorage
  const storageRef = ref(storage, path)

  return await listAll(storageRef)
    .then(async (res) => {
    const filePromises = res.items.map(async (itemRef) => {
      return {
        name: itemRef.name,
        url: await getDownloadURL(itemRef)
      }
    })

    return Promise.all(filePromises)
  })
}
