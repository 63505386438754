import React from "react"
import globalStyles from "../../../global/globalStyles"
import Loader from "./styles.web"

interface LoadingProps {
  size?: "small" | "medium" | "large";
  color?: string;
}

export default function LoadingAnimation({ size = "small", color = globalStyles.colors.secondary }: LoadingProps) {
  const webLoaderSize = convertSizeToPixels(size)
  
  return <Loader size={webLoaderSize} color={color}/>
}

function convertSizeToPixels(size: LoadingProps["size"]): number {
  switch(size){
    case "small": return 10
    case "medium": return 20
    case "large": return 30
    default: throw new Error(`Invalid loading animation size: ${size}`)
  }
}
