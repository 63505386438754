import React, { useEffect, useRef } from "react"
import * as ReactDOM from "react-dom/client"
import { StyleSheet } from "react-native"
import { Platform } from "react-native"
import Modal from "react-native-modal"
import { MenuProvider } from "react-native-popup-menu"
import Container from "./styles"

export default function ModalWrapper({ isVisible, children, onBackdropPress = () => null, backgroundTransparent = false }) {

  if (Platform.OS !== "web")
    return (
      <Modal isVisible={isVisible} onBackdropPress={onBackdropPress} deviceWidth={-1}>
        {children}
      </Modal>
    )

  const modalContainerRef = useRef(null)
  const rootRef = useRef(null)

  useEffect(() => {
    const modalContainer = document.getElementById("web-modal")
    modalContainerRef.current = modalContainer

    const modalElement = document.createElement("div")
    modalContainer.appendChild(modalElement)

    const root = ReactDOM.createRoot(modalElement)
    if (isVisible) rootRef.current = root

    return () => {
      modalContainer.removeChild(modalElement)
      if (root.isMounted) root.unmount()
    }
  }, [isVisible])

  useEffect(() => {
    const root = rootRef.current
    if (root)
      root.render(
        <Container>
          <MenuProvider skipInstanceCheck style={style.provider}>
            {children}
          </MenuProvider>
        </Container>
      )
  }, [isVisible])

  useEffect(() => {
    const root = rootRef.current
    if (root) {
      root.render(
        <Container style={backgroundTransparent && { backgroundColor: "transparent" }}>
          <MenuProvider skipInstanceCheck style={style.provider}>
            {children}
          </MenuProvider>
        </Container>
      )
    }
  }, [children])

  return null
}

const style = StyleSheet.create({
  provider: {
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center"
  }
})
