import React from "react"
import Text from "./styles"

export default function Decimal({ fieldData, fieldValue = 0, isSelected }) {
  const ATR = fieldData.T_DTD_ATR

  const decimalPlacesAmount = ATR.DECIMAL ?? 2

  const value = fieldValue?.toLocaleString("pt-BR", {
    maximumFractionDigits: decimalPlacesAmount,
    minimumFractionDigits: decimalPlacesAmount
  })

  return (
    <Text isSelected={isSelected}>
      {value}
    </Text>
  )
}
