import showAlert from "../../../../../../../services/showAlert"

export default function manualIdValidation({ models, entityName }) {
  const tableData = models._REGISTRATION_DATA[entityName]

  if (tableData.manualID && !models[entityName].ID) {
    showAlert({
      text: "Provide an ID",
      title: "Manual ID",
      titleType: "error"
    })

    return false
  }

  return true
}
