import ModalWrapper from "../../../../../Modal"
import * as S from "./styles"

export default function DynamicModal({ isVisible, children }) {
  return (
    <ModalWrapper isVisible={isVisible}>
      <S.Container>
        {children}
      </S.Container>
    </ModalWrapper>
  )
}
