import { Platform } from "react-native"
import models from "../models"
import dbRequest from "../services/dbRequest/dbRequest"
import evaluateFunction from "../services/evaluateFunction"
import getTableFields from "../services/registration/getTableFields"
import JumpScreenController from "./JumpScreenController"
import DefaultController from "./orderControllers/DefaultController"
import HeaderController from "./orderControllers/HeaderController"

export default class LauncherController {
  constructor() {
    this.modelsTemplate = models.getClone()
    this.models = () => this.modelsTemplate
    this.orderPrototype = {}
    this.jumpScreenStatus = false
    this.sideBarFields = []
    this.headerFormFields = []
    this.tabName = null
    this.initialScreenParams = {}
    this.masterEntity = null
    this.setScreens = null
    this.detailTabPath = null
  }

  async initialize({ entity, tabName, isExistingDocument = false, detailTabPath = null }) {
    this.orderPrototype = {}
    this.masterEntity = entity
    this.tabName = tabName
    this.detailTabPath = detailTabPath

    const initialScreen = (await this.getEntityFields(entity)).filter(({ ID }) => ID === "TNSID")
    const initialScreenEntity = initialScreen[0].T_DTD_ATR.ENTITY.ID
    const screenId = initialScreen[0].ID

    const initialScreenFields = await this.fetchAndSortFields(initialScreenEntity)

    this.orderPrototype[screenId] = this.createOrderPrototype({
      collectionName: initialScreenEntity,
      HDRfield: {
        ID: initialScreen[0].ID,
        LABEL: initialScreen[0].T_DTD_ATR.LABEL
      },
      name: initialScreen[0].T_DTD_ATR.FIELDDESCRIPTION,
      screenOrder: 0,
      entityFields: initialScreenFields,
      filters: initialScreen[0].T_DTD_ATR.FILTER,
      where: initialScreen[0].T_DTD_ATR?.WHERE,
      saved: isExistingDocument ? true : false,
      initialScreen: true,
      detailTabPath,
      canEdit: false
    })
    this.orderPrototype[screenId].controller = new DefaultController(this.orderPrototype[screenId])
    this.initialScreenParams.name = initialScreen[0].T_DTD_ATR.FIELDDESCRIPTION
    this.initialScreenParams.params = this.orderPrototype[screenId]

    if (this.setScreens) {
      this.setScreens((prevState) => {
        if (prevState.some(screen => screen.name === initialScreen[0].T_DTD_ATR.FIELDDESCRIPTION)) return prevState
        else return [...prevState, this.initialScreenParams]
      })
    }

    await this.getTriggers({ entity })

    this.jumpScreenStatus = false
  }

  async fetchAndSortFields(entityName) {
    const fields = await getTableFields({ entityName })
    return fields.sort((a, b) => a.T_DTD_ATR.CARDORDERBY - b.T_DTD_ATR.CARDORDERBY)
  }

  createOrderPrototype({
    collectionName,
    HDRfield,
    filters = null,
    where = null,
    name,
    entityFields,
    screenOrder,
    saved = false,
    formFields = null,
    defaultValue = null,
    initialScreen = false,
    isLastTab = false,
    canEdit
  }) {

    return {
      id: 0,
      name,
      collectionName,
      HDRfield,
      filters: filters,
      where: where,
      models: this.models,
      orderComposerController: this,
      entityFields,
      saved,
      formFields: formFields,
      screenOrder,
      defaultValue,
      initialScreen,
      isLastTab,
      detailTabPath: this.detailTabPath,
      canEdit
    }
  }

  async changeScreen(item, controller, setIsLoading) {

    if (controller.isInitialScreen && !controller.saved) {
      await this.getNavigationFields(item.ID)
    }
    controller.saved = true

    const shouldExecuteTriggers = await this.executeTriggers(this.models(), {
      ENTITY: controller.collectionName,
      RECORD: item
    })

    if (shouldExecuteTriggers) {
      await controller.saveValues(item)

      if (!this.jumpScreenStatus) {
        await JumpScreenController.start(this.orderPrototype, this.navigation, this)
        this.navigateToNextScreen()
      } else {
        this.navigateToNextScreen()
      }
    }

    setIsLoading(s => !s)
  }

  async getNavigationFields(transactionId, existingOrder) {

    const saved = existingOrder ?? false

    let navigationFields = await this.getFilteredNavigationFields(transactionId).then(fields => {
      return fields.filter(field => field.T_DTD_ATR.VISIBLE).sort((a, b) => a?.T_DTD_ATR?.ORDERBY - b?.T_DTD_ATR?.ORDERBY)
    })

    this.sideBarFields = navigationFields.filter(item => item.FIELDTYPE.VALUE === "N")

    this.headerFormFields = navigationFields
      .filter(({ FIELDTYPE }) => FIELDTYPE.VALUE !== "N")
      .sort((a, b) => a.T_DTD_ATR.ORDERBY - b.T_DTD_ATR.ORDERBY)

    if (!navigationFields.length) {
      navigationFields = await this.getFallbackFields(this.masterEntity)
    }

    await this.createOrderTemplate(navigationFields, transactionId, saved)

    const newScreens = [...this.generateScreenArray()]

    await this.setScreens((prevState) => {
      const combinedScreens = [...prevState, ...newScreens]
      const uniqueScreens = combinedScreens.filter((screen, index, self) =>
        index === self.findIndex((s) => s.name === screen.name)
      ).sort((a, b) => {
        if (saved) {
          if (a.params.isLastTab) return -1
          if (b.params.isLastTab) return 1
          return 0
        }

        return a.screenOrder - b.screenOrder
      })
      return uniqueScreens
    })
  }

  async getFilteredNavigationFields(id) {
    const settingsEntity = "T_SET_HDR"

    return await getTableFields({
      collectionPath: `${this.masterEntity}/${settingsEntity}/${id}`,
      entityName: this.masterEntity
    })
  }

  async getFallbackFields(entityName) {
    const navigationFields = await getTableFields({
      entityName,
      fetchOptions: false
    })
    this.sideBarFields = navigationFields.filter(item => item.FIELDTYPE.VALUE === "N")
    return navigationFields.filter(item => item.FIELDTYPE.VALUE === "N" && item.ID !== "TNSID")
  }

  async createOrderTemplate(navigationFields, transactionId, saved) {

    const prototypePromises = navigationFields
      .filter(item => item.FIELDTYPE.VALUE === "N" && item.ID !== "TNSID")
      .map(async (field, index) => {
        const HDRfield = {
          ID: field.ID,
          LABEL: field.T_DTD_ATR.LABEL
        }

        const entityId = field.T_DTD_ATR.ENTITY.ID
        const screenId = field.ID
        const entityFields = await this.getEntityFields(entityId)
        this.orderPrototype[screenId] = this.createOrderPrototype({
          collectionName: entityId,
          HDRfield,
          name: field.T_DTD_ATR.FIELDDESCRIPTION,
          screenOrder: index + 1,
          entityFields,
          saved,
          filters: field.T_DTD_ATR.FILTER,
          where: field.T_DTD_ATR?.WHERE,
          defaultValue: field.T_DTD_ATR.EXPRESSION,
          canEdit: field.T_DTD_ATR.EDITABLE
        })
        this.orderPrototype[screenId].controller = new DefaultController(this.orderPrototype[screenId])
        this.setScreens(prevState => this.addScreen(prevState, field.T_DTD_ATR.FIELDDESCRIPTION, this.orderPrototype[screenId]))
      })

    await Promise.all(prototypePromises)

    const entries = Object.entries(this.orderPrototype)

    entries.sort((a, b) => a[1].screenOrder - b[1].screenOrder)
    this.orderPrototype = Object.fromEntries(entries)

    const tabs = navigationFields
      .filter(item => item.FIELDTYPE.VALUE !== "N")
      .map(({ T_DTD_ATR }) => T_DTD_ATR?.TAB?.DESCRIPTION ? T_DTD_ATR?.TAB : { DESCRIPTION: "Additional Details" })

    const sortedTabs = tabs.sort((a, b) => a.ORDERBY - b.ORDERBY).map(({ DESCRIPTION }) => DESCRIPTION)

    const uniqueTabs = [...new Set(sortedTabs)]

    uniqueTabs.forEach((tabName, index) => {
      const tabFields = this.headerFormFields.filter(field => {
        return field.T_DTD_ATR?.TAB?.DESCRIPTION === tabName || (!field.T_DTD_ATR?.TAB?.DESCRIPTION && tabName === "Additional Details")
      }).map(field => {
        return {
          ...field,
          T_DTD_ATR: {
            ...field.T_DTD_ATR,
            TAB: { DESCRIPTION: "Main" }
          }
        }
      })

      const isLastTab = index === uniqueTabs.length - 1

      this.orderPrototype[`TAB_${tabName}`] = this.createOrderPrototype({
        collectionName: `TAB_${tabName}`,
        HDRfield: {
          ID: "TAB",
          LABEL: tabName
        },
        name: tabName,
        screenOrder: Object.keys(this.orderPrototype).length,
        entityFields: [],
        saved,
        formFields: tabFields,
        isLastTab
      })

      this.orderPrototype[`TAB_${tabName}`].controller = new HeaderController(this.orderPrototype[`TAB_${tabName}`])
    })

  }

  addScreen(prevState, name, params) {
    const newScreens = [...prevState]
    if (!newScreens.find(screen => screen.name === name)) {
      newScreens.push({
        name,
        params
      })
    }
    return newScreens
  }

  generateScreenArray() {
    const screens = Object.values(this.orderPrototype)
      .filter(element => element.name !== "Transaction")
      .map(element => ({
        name: element.name,
        params: element
      }))

    return screens
  }

  navigateToNextScreen() {
    const nextScreenName = this.getNextScreenName()

    if (Platform.OS === "web") {
      this.navigation.navigate(this.tabName, { screen: nextScreenName })
    } else {
      this.navigation.navigate(nextScreenName)
    }
  }

  getNextScreenName() {
    let nextScreenName = Object.values(this.orderPrototype).find(screen => !screen.controller.saved)?.controller.screenName
    if (!nextScreenName) {
      nextScreenName = Object.values(this.orderPrototype).find(screen => screen.controller.isLastTab)?.controller.screenName
    }
    return nextScreenName
  }

  async getEntityFields(entity) {
    return (await getTableFields({ entityName: entity }))
      .sort((a, b) => (a?.T_DTD_ATR?.CARDORDERBY ?? Infinity) - (b?.T_DTD_ATR?.CARDORDERBY ?? Infinity))
  }

  async getTriggers({ entity }) {
    this.triggers = (await dbRequest
      .loadRecords(`T_DTD_TAB/${entity}/T_DTD_TRG_NAV`)
      .execute()).sort((a, b) => a.ORDERBY - b.ORDERBY)
  }

  async executeTriggers(models, params) {
    for (const trigger of this.triggers) {
      if (trigger.ACTIVE && !(await evaluateFunction(trigger.EXPRESSION, models, params))) {
        return false
      }
    }
    return true
  }

  async goToScreen(selectedItem) {
    await this.models().T_MOV_HDR.populate(selectedItem)
    //await this.getTriggers()
  }

  resetNavigation({ initialParams, launcherController }) {

    if (Platform.OS === "web") {
      if (this.setScreens) {
        this.setScreens((prevState) => {
          if (prevState.length > 1) {
            return prevState.filter(screen => screen.name === initialParams.name)
          } else return prevState
        })
      }
      this.navigation.reset({
        index: 0,
        routes: [{
          name: initialParams.name,
          params: initialParams.params
        }]
      })
    } else {
      this.navigation.reset({
        index: 0,
        routes: [
          {
            name: "Document",
            params: {
              attribute: null,
              controller: launcherController,
              isInitialScreen: true,
              initialParams: launcherController.initialScreenParams,
              existingDocument: null,
              RootParentID: "Navigations"
            }
          }
        ]
      })

    }
  }
}
