import { AntDesign } from "@expo/vector-icons"
import { Platform } from "react-native"
import styled from "styled-components/native"
import globalStyles from "../../../../../global/globalStyles"

export const Container = styled.View`
  width: ${Platform.OS === "web" ? 80 : 100}%;
  height: 80%;
  align-items: center;
  padding: 2px;
  border-radius: 10px;
  background-color: ${globalStyles.colors.tertiary};
`
