// eslint-disable-next-line no-unsafe-optional-chaining
import { MaterialIcons } from "@expo/vector-icons"
import React, { useEffect, useState } from "react"
import { View } from "react-native"
import { useDispatch } from "react-redux"
import useAppSelector from "../../../../../../redux/hooks/useAppSelector"
import { closeModal } from "../../../../../../redux/redux-reducers/dashboardBuilder-reducer"
import { saveFilter } from "../../../../../../redux/redux-reducers/dashboardBuilder-reducer/utils/saveFilter"
import ModalWrapper from "../../../../../components/Modal"
import * as S from "../modalStyles"
import filterTypes from "./utils/filterTypes"
import operatorTypes from "./utils/operatorTypes"
import validateFilter from "./utils/validateFilter"

export default function FilterModal() {

  const dispatch = useDispatch()

  const { isVisible, name, mode, selectedFilter } = useAppSelector(({ dashboardBuilderReducer }) => dashboardBuilderReducer.modals.filterModal)
  const { selectedQuery, selectedDashboard } = useAppSelector(({ dashboardBuilderReducer }) => dashboardBuilderReducer.dashboardData)

  const [modalForm, setModalForm] = useState({
    TYPE: isCustomQuery ? filterTypes[0] : {},
    OPERATOR: {},
    FIELD: "",
    VALUE: "",
    STARTING_AT: "",
    ENDING_AT: ""
  })

  useEffect(() => {
    if (selectedFilter) {
      setModalForm({
        TYPE: filterTypes.find(item => item.VALUE === selectedFilter.TYPE.VALUE),
        OPERATOR: operatorTypes.find(item => item.VALUE === selectedFilter.OPERATOR.VALUE),
        FIELD: selectedFilter.FIELD ?? "",
        VALUE: selectedFilter?.VALUE ?? "",
        STARTING_AT: selectedFilter?.STARTING_AT ?? "",
        ENDING_AT: selectedFilter?.ENDING_AT ?? ""
      })
    }
  }, [selectedFilter])

  const handleChange = (fieldName, value) => {
    setModalForm({
      ...modalForm,
      [fieldName]: value
    })
  }

  const handleSaveFilter = () => {
    if (validateFilter(modalForm)) {
      let updatedQuery = { ...selectedQuery }

      const saveFilterField = modalForm.TYPE.VALUE === "FIREBASE" ? "WHERE" : "FILTER"

      let prevFilters = []
      if (updatedQuery?.[saveFilterField]) prevFilters = [...updatedQuery[saveFilterField]]

      const filterIndex = prevFilters.findIndex(filter => filter.FIELD === modalForm.FIELD)

      if (modalForm.OPERATOR?.VALUE === "period") delete modalForm.VALUE
      else {
        delete modalForm.STARTING_AT
        delete modalForm.ENDING_AT
      }

      if (filterIndex === -1) prevFilters.push(modalForm)
      else prevFilters[filterIndex] = modalForm

      updatedQuery[saveFilterField] = prevFilters

      delete updatedQuery?.connections

      dispatch(saveFilter({
        updatedQuery,
        path: `T_RDB_DSB/${selectedDashboard.ID}/DATA`
      }))
    }
  }

  const isPeriodFilter = modalForm.OPERATOR?.VALUE === "period" ? true : false
  const isCustomQuery = selectedQuery?.ENTITY?.ID === "Custom" ? true : false

  return (
    <ModalWrapper isVisible={isVisible}>
      <S.Container style={{
        width: "50%",
        height: "40%"
      }}>
        <S.CloseButton onPress={async () => {
          setModalForm({
            TYPE: {},
            OPERATOR: {},
            FIELD: "",
            VALUE: "",
            STARTING_AT: "",
            ENDING_AT: ""
          })
          dispatch(closeModal({ modalName: name }))
        }}>
          <MaterialIcons name="close" size={20} color="#333333" />
        </S.CloseButton>

        <S.Title>{mode === "new" ? "Create New" : "Edit"} Filter</S.Title>

        <S.AddButton
          style={{
            position: "absolute",
            right: 50,
            top: 12
          }}
          onPress={handleSaveFilter}
        >
          <S.TitleButton>Save</S.TitleButton>
        </S.AddButton>

        <View style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center"
        }}>
          <S.SelectedView>
            <View style={{
              flexDirection: "row",
              alignItems: "center",
              gap: 3
            }}>
              <S.SelectedText>Filter type:</S.SelectedText>
              <S.SelectedField>
                {mode === "new" && !isCustomQuery ? (
                  <S.ListInput
                    data={filterTypes}
                    value={modalForm.TYPE?.VALUE}
                    dropdownPosition="bottom"
                    labelField="LABEL"
                    valueField="VALUE"
                    placeholder={"Select type"}
                    onChange={(item) => {
                      handleChange("TYPE", item)
                    }}
                  />
                ) : (
                  <S.TextInput value={isCustomQuery ? "JavaScript" : modalForm?.TYPE?.LABEL} editable={false} />
                )}
              </S.SelectedField>
            </View>
            <View style={{
              flexDirection: "row",
              alignItems: "center",
              gap: 3
            }}>
              <S.SelectedText>Field Name:</S.SelectedText>
              <S.SelectedField>
                <S.TextInput
                  value={modalForm.FIELD}
                  placeholder="Field Name"
                  placeholderTextColor={"gray"}
                  onChangeText={(tx) => handleChange("FIELD", tx)}
                />
              </S.SelectedField>

            </View>
          </S.SelectedView>

          <S.SelectedView>
            <View style={{
              flexDirection: "row",
              alignItems: "center",
              gap: 3
            }}>
              <S.SelectedText>Operator type:</S.SelectedText>
              <S.SelectedField>

                <S.ListInput
                  data={operatorTypes}
                  value={modalForm.OPERATOR?.VALUE}
                  dropdownPosition="bottom"
                  labelField="LABEL"
                  valueField="VALUE"
                  placeholder={"Select Operator"}
                  onChange={(item) => {
                    handleChange("OPERATOR", item)
                  }}
                />

              </S.SelectedField>
            </View>
            <View style={{
              flexDirection: "row",
              alignItems: "center",
              gap: 3
            }}>
              <S.SelectedText>Value:</S.SelectedText>
              <S.SelectedField style={[isPeriodFilter && { backgroundColor: "#EDEDED" }]}>
                <S.TextInput
                  value={modalForm?.VALUE}
                  editable={!isPeriodFilter}
                  placeholder="Value"
                  placeholderTextColor={"gray"}
                  onChangeText={(tx) => handleChange("VALUE", tx)}
                />
              </S.SelectedField>
            </View>
          </S.SelectedView>
          <S.SelectedView>
            <View style={{
              flexDirection: "row",
              alignItems: "center",
              gap: 3
            }}>
              <S.SelectedText>Starting At:</S.SelectedText>
              <S.SelectedField style={[!isPeriodFilter && { backgroundColor: "#EDEDED" }]}>
                <S.TextInput
                  value={modalForm?.STARTING_AT}
                  editable={isPeriodFilter}
                  placeholder="Starting At"
                  placeholderTextColor={"gray"}
                  onChangeText={(tx) => handleChange("STARTING_AT", tx)}
                />
              </S.SelectedField>
            </View>
            <View style={{
              flexDirection: "row",
              alignItems: "center",
              gap: 3
            }}>
              <S.SelectedText>Ending At:</S.SelectedText>
              <S.SelectedField style={[!isPeriodFilter && { backgroundColor: "#EDEDED" }]}>
                <S.TextInput
                  value={modalForm?.ENDING_AT}
                  editable={isPeriodFilter}
                  placeholder="Ending At"
                  placeholderTextColor={"gray"}
                  onChangeText={(tx) => handleChange("ENDING_AT", tx)}
                />
              </S.SelectedField>
            </View>
          </S.SelectedView>
        </View>
      </S.Container>
    </ModalWrapper>
  )
}
