import { Platform } from "react-native"
import OrderComposerController from "../../../../../controllers/OrderComposerController"
import Navigation from "../../../../../models/navigation"
import showAlert from "../../../../../services/showAlert"

const handleCancelOrder = (navigation, controller) => {
  showAlert({
    type: "confirm",
    title: controller.models().T_MOV_HDR?.ID ? "Deseja iniciar um novo lançamento?" : "Deseja cancelar esse lançamento?",
    titleType: "question",
    handleConfirm: async () => {
      if (Platform.OS !== "web") {
        controller.models().T_MOV_HDR.reset()
        controller.models().T_MOV_ITE = []

        await controller.OrderComposerController.initialize()
        controller.OrderComposerController.jumpScreenStatus = false

        navigation.navigate("Transaction")
      } else {
        const controller = new OrderComposerController()
        await controller.initialize()

        controller.tabName = "Pedido de venda"

        Navigation.createTab({
          component: "OrderComposer",
          params: {
            attribute: "T_MOV_HDR",
            controller: controller,
            transaction: "MOVSO"
          },
          tabName: "Pedido de venda"
        })
      }
    },
    handleReject: () => null
  })

}

export default handleCancelOrder
