import showAlert from "../../../../../../services/showAlert"

export default (models, setIsEdit, openModal) => {

  if (!models.T_DTD_TAB.ID)
    return showAlert({
      text: "No table selected",
      title: "Failed to insert field",
      titleType: "error"
    })

  models.T_DTD_FLD.reset()
  setIsEdit(false)
  openModal("field")
}
