import showAlert from '../../../../../../../services/showAlert'

const insertValidation = (callback) => {
  showAlert({
    title: "All changes will be lost",
    titleType: "warning",
    text: "Would you like to return?",
    type: "confirm",
    handleConfirm: () => callback()
  })
}

export default insertValidation