import LoginData from "../../../../../../models/loginData"
import dbRequest from "../../../../../../services/dbRequest/dbRequest"
import addNativeFunctions from "./addNativeFunctions"

export default async (entityName, models, mode) => {
  const tableFunctions = await dbRequest
    .loadRecords(`T_DTD_TAB/${entityName}/T_DTD_FNC`)
    .execute()

  const { permissions } = models._REGISTRATION_DATA[entityName]

  const enabledFunctions = tableFunctions.filter(({ ACTIVE, ID, ONLYFORM = false }) => {
    const isFunctionActive = ACTIVE
    const isUserAdmin = LoginData.getLoginData().ADMIN
    const doesUserHasPermission = isUserAdmin || permissions[ID]
    const isNotOnlyForm = mode === "grid" ? !ONLYFORM : true

    return isFunctionActive && doesUserHasPermission && isNotOnlyForm
  })

  addNativeFunctions({
    functions: enabledFunctions,
    entityName,
    mode
  })

  return enabledFunctions
}
