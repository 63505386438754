import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import RuntimeConfiguration from "../../../../../../models/runtimeConfiguration"
import menuConstructor from "../../../../../../services/menuConstructor"
import ModalWrapper from "../../../../../components/Modal"
import { ControllerContext } from "../../../contexts/ControllerProvider"
import { ModalsContext } from "../../../contexts/ModalsProvider"
import renderButtons from "../utils/renderButtons"
import { Container } from "./styles"
import handleConfirm from "./utils/handleConfirm"
import handleDelete from "./utils/handleDelete"
import renderMenu from "./utils/renderMenu"

export default function ManageMenuModal() {
  const { t } = useTranslation()

  const isAdmin = RuntimeConfiguration.getIsDeveloperConfigurationMode()

  const { controller, models } = useContext(ControllerContext)
  const { modalsVisible, closeModal } = useContext(ModalsContext)

  const [menuOptions, setMenuOptions] = useState([])
  const [, setUpdate] = useState(false)

  useEffect(() => { menuConstructor().then(setMenuOptions) }, [])

  const buttons = [
    {
      iconName: "save",
      divTitle: "Add to menu",
      onPress: () => { handleConfirm(controller, models, closeModal) }
    },
    {
      iconName: "trash",
      divTitle: "Remove from menu",
      onPress: () => { handleDelete(controller, models, closeModal) }
    },
    {
      iconName: "close",
      divTitle: "Close",
      onPress: () => {
        closeModal("manageMenu")
        models.MENU.reset()
      }
    }
  ]

  return (
    <ModalWrapper isVisible={modalsVisible.manageMenu}>
      <Container>
        {renderButtons(buttons)}

        {renderMenu(menuOptions, models, setUpdate, t, isAdmin)}
      </Container>
    </ModalWrapper>
  )
}
