import { MaterialIcons } from "@expo/vector-icons"
import React from "react"
import globalStyles from "../../../../../global/globalStyles"
import * as S from "./styles"

export default function Button({ iconName, isVisible, handlePress = () => null }) {

  if (isVisible())
    return (
      <S.Container onPress={handlePress}>
        {iconName === "undo"
          ? <MaterialIcons name="undo" size={18} color={globalStyles.colors.primary} />
          : <S.Icon name={iconName} />
        }
      </S.Container>
    )
}
