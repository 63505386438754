import React from "react"
import RenderField from "./components/RenderField"
import Grouping from "./components/Grouping"
import * as S from "./styles.web"

export default function BuildForm({
  models,
  fields,
  selectedTab = { tabName: "Main", isDetail: false },
  setUpdateForm,
  updateForm,
  screenBuilder
}) {
  const props = {
    models,
    setUpdateForm,
    updateForm
  }

  const fieldsToRender = fields.filter(({ ID, T_DTD_ATR }) => {
    const doesFieldBelongToTab = T_DTD_ATR.TAB?.DESCRIPTION === selectedTab.tabName
    const shouldRenderField = (ID === "ID" && screenBuilder) || !["ID", "IMG", "DESCRIPTION"].includes(ID)

    return doesFieldBelongToTab && shouldRenderField
  })

  return (
    <S.Container selectedTab={selectedTab}>
      {fieldsToRender.map(item => item.FIELDS
        ? <Grouping key={item.GROUPNAME} group={item} props={props} />
        : <RenderField key={item.ID} field={item} props={props} />
      )}
    </S.Container>
  )
}
