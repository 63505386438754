import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import showAlert from "../../../../../../../../services/showAlert"
import Form from "../../../../../../../components/Form/index.web"
import ModalWrapper from "../../../../../../../components/Modal"
import { ControllerContext } from "../../../../../contexts/ControllerProvider"
import renderButtons from "../../../utils/renderButtons"
import handleDelete from "../../utils/handleDelete"
import handleSave from "../../utils/handleSave"
import ParametersModal from "./components/ParametersModal"
import * as S from "./styles"

export default function FormModal({ modalVisible, setModalVisible, setUpdateTable }) {
  const { t } = useTranslation()

  const { controller, models } = useContext(ControllerContext)

  const [paramsModalVisible, setParamsModalVisible] = useState(false)

  const closeModal = () => { setModalVisible(false) }

  const resetModel = () => {
    models.T_DTD_FNC.reset()
    models._REGISTRATION_DATA.T_DTD_FNC.operation = "UPD"
  }

  const buttons = [
    {
      iconName: "save",
      divTitle: "Save changes",
      onPress: () => handleSave(controller, setUpdateTable, closeModal, resetModel)
    },
    {
      iconName: "trash",
      divTitle: "Delete selected record",
      onPress: async () => {
        if (models._REGISTRATION_DATA.T_DTD_FNC.operation !== "UPD")
          showAlert({
            text: "No function selected",
            title: "Failed to delete function",
            titleType: "error"
          })

        else await handleDelete(controller, setUpdateTable, closeModal, resetModel)
      }
    },
    {
      iconName: "close",
      divTitle: "Close",
      onPress: () => { closeModal() }
    }
  ]

  return (
    <>
      <ModalWrapper isVisible={modalVisible}>
        <S.Container>
          <S.Row>
            {renderButtons(buttons)}

            {models._REGISTRATION_DATA.T_DTD_FNC.operation === "UPD" &&
              <S.Button onPress={() => { setParamsModalVisible(true) }}>
                <S.ButtonText>{t("Parameters")}</S.ButtonText>
              </S.Button>
            }
          </S.Row>

          <Form entity="T_DTD_FNC" models={models} screenBuilder={true} />
        </S.Container>
      </ModalWrapper>

      {paramsModalVisible &&
        <ParametersModal
          paramsModalVisible={paramsModalVisible}
          setParamsModalVisible={setParamsModalVisible}
        />
      }
    </>
  )
}
