import React, { useRef, useState } from "react"
import modelTemplate from "../../../models"
import { defaultTheme } from "../../../styles/themes/default"
import Grid from "../../components/Grid"
import LoadingAnimation from "../../components/LoadingAnimation"
import ModalWrapper from "../../components/Modal"
import * as S from "./styles.web"
import copyRecord from "./utils/copyRecord"
import deleteRecord from "./utils/deleteRecord"
import insertRecords from "./utils/insertRecords"
import openRecord from "./utils/openRecord"

export default function LauncherPanelScreen({ navigation, route }) {

  const entity = route.params.attribute
  const entityName = route.params.tabName

  const [models] = useState(modelTemplate.getClone())
  const [isLoading, setIsLoading] = useState(false)
  const gridRef = useRef(null)

  const props = {
    entity,
    entityName,
    models,
    navigation,
    gridRef,
    setIsLoading
  }

  const functions = {
    handleInsert: () => insertRecords(props),
    handleDelete: ({ changeLoadingModalVisibility }) => deleteRecord({
      ...props,
      changeLoadingModalVisibility
    }),
    handleOpen: () => openRecord(props),
    handleCopy: () => copyRecord(props)
  }

  return (
    <S.Container>
      <Grid
        entity={entity}
        functions={functions}
        gridRef={gridRef}
        models={models}
        screenType="orderPanel"
        rowDoubleClickCallback={functions.handleOpen}
      />

      <ModalWrapper isVisible={isLoading}>
        <S.LoadingContainer>
          <LoadingAnimation size="large" color={defaultTheme["cyan-700"]} />
        </S.LoadingContainer>
      </ModalWrapper>

    </S.Container>

  )
}
