import React, { useState } from "react"
import RenderMenu from "./components/RenderMenu"
import RenderModal from "./components/RenderModal"

export default function MoreOptionsButton({ models, entityName, setUpdateForm, mode }) {
  const [selectedOption, setSelectedOption] = useState(null)
  const [modalVisible, setModalVisible] = useState(false)

  const closeModal = () => { setModalVisible(false) }

  const props = {
    entityName,
    models,
    closeModal,
    selectedOption,
    setSelectedOption
  }

  return (
    <>
      <RenderMenu {...props} setModalVisible={setModalVisible} mode={mode} />

      {modalVisible && <RenderModal {...props} isVisible={modalVisible} setUpdateForm={setUpdateForm} />}
    </>
  )
}
