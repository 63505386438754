import React from "react"
import Access from "./DynamicModal/components/Access"
import ConfigurationModal from "./DynamicModal/components/ConfigurationModal"
import ParametersForm from "./DynamicModal/components/ParametersForm"
import ReceitasWS from "./DynamicModal/components/ReceitaWS"

export default function ModalContent(props) {

  const { TYPE, EXPRESSION, DESCRIPTION } = props.selectedOption

  if (TYPE.VALUE === "E") return // Opção para abrir uma nova aba

  if (TYPE.VALUE === "N") { //Native functions
    if (EXPRESSION === "fnc_receitaWS") return <ReceitasWS {...props} />

    if (EXPRESSION === "fnc_access") return <Access {...props} />

    //if (EXPRESSION === "fnc_importSpreadsheet") 
    //  return <ImportSpreadSheet entityName={entityName} controller={controller} />
    //
  }

  if (["Configure header", "Configure items"].includes(DESCRIPTION)) { // Header and Items personalization
    return <ConfigurationModal {...props} />
  }

  if (TYPE.VALUE === "F") // Functions with expression to evaluate
    return <ParametersForm {...props} />
}
