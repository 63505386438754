import React from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { useTranslation } from "react-i18next"
import Card from "./components/Card"
import Header from "./components/Header"
import * as S from "./styles"

export default function DraggableList({ data, setData, title, cardValueAttribute = null, onSave, onClose }) {
  const { t } = useTranslation()

  const moveCard = (fromIndex, toIndex) => {
    const updatedData = [...data]
    const [movedCard] = updatedData.splice(fromIndex, 1)
    updatedData.splice(toIndex, 0, movedCard)
    setData(updatedData)
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <S.Container>
        <Header title={title} onSave={onSave} onClose={onClose} />

        <S.Title>{t("Description")}</S.Title>

        <S.List contentContainerStyle={{ gap: 8 }}>
          {data.map((item, index) => (
            <Card
              key={index}
              cardData={item}
              cardIndex={index}
              moveCard={moveCard}
              cardValueAttribute={cardValueAttribute}
            />
          ))}
        </S.List>
      </S.Container>
    </DndProvider>
  )
}
