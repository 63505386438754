import filterRecords from "../../views/components/Form/components/Inputs/Search/utils/filterRecords"
import evaluateFunction from "../evaluateFunction"
import getTableFields, { Field } from "./getTableFields"

interface Props {
	entity: string
	staticFields: Field[] | null
	models: any
	screenBuilder: boolean
}

export default async function getFormData({ entity, staticFields, models, screenBuilder }: Props) {
	if (!staticFields) {
		staticFields = models._REGISTRATION_DATA[entity].fields ?? await getTableFields({
			entityName: entity,
			isScreenBuilderRequest: screenBuilder
		})
	}

	for (const field of staticFields) {
		if (field.T_DTD_ATR?.EXPRESSION) {
			await evaluateFunction(field.T_DTD_ATR.EXPRESSION, models)
				.then(async (r) => {
					if (["K", "N"].includes(field.FIELDTYPE.VALUE)) {

						if (r !== null && r !== undefined && r !== "") {
							const [recordValue] = await filterRecords({
								fieldData: field,
								searchedValue: r,
								records: null
							})

							if (recordValue !== null) {
								const objectValue = {
									ID: recordValue.ID,
									DESCRIPTION: recordValue.DESCRIPTION,
									IMG: recordValue?.IMG ?? []
								}

								models[entity][field.ID] = objectValue
							}
						}
					}
					else models[entity][field.ID] = r
				})
		}
	}

	staticFields = staticFields.sort((a, b) => a.T_DTD_ATR.ORDERBY - b.T_DTD_ATR.ORDERBY)

	staticFields.forEach((field) => {
		//field.T_DTD_ATR = getFieldAttributes(field)
		field.TABLENAME = entity
	})

	populateEmptyTabs(staticFields)

	const orderedFields = orderFiels(staticFields)

	const uniqueTabs = await getUniqueTabs(staticFields, entity, models)

	return {
		orderedFields,
		uniqueTabs
	}
}

function populateEmptyTabs(fields: Field[]) {
	fields.forEach((field) => {
		const { FIELDTYPE, T_DTD_ATR } = field

		if (!T_DTD_ATR.TAB?.DESCRIPTION && T_DTD_ATR.VISIBLE)
			T_DTD_ATR.TAB =
				FIELDTYPE.VALUE === "E" || FIELDTYPE.VALUE === "R"
					? {
						DESCRIPTION: T_DTD_ATR.FIELDDESCRIPTION,
						ORDERBY: 999
					}
					: {
						DESCRIPTION: "Main",
						ORDERBY: 0
					}
	})
}

function orderFiels(fields: Field[]) {
	const fieldsWithoutGroup = []
	const groupsMap = new Map()

	fields.forEach((item) => {
		const groupName = item.T_DTD_ATR.GROUPING
		if (!groupName) fieldsWithoutGroup.push(item)
		else {
			if (!groupsMap.has(groupName)) groupsMap.set(groupName, [])
			groupsMap.get(groupName).push(item)
		}
	})

	const groupsArray = []

	groupsMap.forEach((groupFields, groupName) => {
		const minOrderBy = Math.min(...groupFields.map((field: Field) => field.T_DTD_ATR.ORDERBY))
		const groupTab = groupFields[0].T_DTD_ATR.TAB
		groupsArray.push({
			FIELDS: groupFields,
			T_DTD_ATR: {
				ORDERBY: minOrderBy,
				TAB: groupTab
			},
			GROUPNAME: groupName
		})
	})

	const mergedArray = fieldsWithoutGroup.concat(groupsArray)

	const sortedArray = mergedArray.sort((a, b) => a.T_DTD_ATR.ORDERBY - b.T_DTD_ATR.ORDERBY)

	return sortedArray.flatMap((item) => {
		if (!("FIELDS" in item)) return item

		const { FIELDS, GROUPNAME } = item

		const tabs = FIELDS.map(({ T_DTD_ATR }) => T_DTD_ATR.TAB?.DESCRIPTION)
		const uniqueTabs = [...new Set(tabs)]

		return uniqueTabs.reduce((acc: object[], tab) => {
			const minOrderBy = Math.min(...FIELDS.map(({ T_DTD_ATR }) => T_DTD_ATR.ORDERBY))
			const groupFields = FIELDS.filter(({ T_DTD_ATR }) => T_DTD_ATR.TAB?.DESCRIPTION === tab)

			acc.push({
				FIELDS: groupFields,
				T_DTD_ATR: {
					ORDERBY: minOrderBy,
					TAB: { DESCRIPTION: tab }
				},
				GROUPNAME: GROUPNAME
			})

			return acc
		}, [])
	})
}

async function getUniqueTabs(fields: Field[], entity: string, models) {
	const detailTabs = fields
		.filter(({ FIELDTYPE }) => FIELDTYPE.VALUE === "E" || FIELDTYPE.VALUE === "R")
		.map((field) => ({
			fieldDescription: field.T_DTD_ATR.FIELDDESCRIPTION,
			fieldId: field.ID
		}))

	const transactionType = models?._REGISTRATION_DATA[entity]?.operation

	const visibleFields = fields.filter(({ FIELDTYPE, T_DTD_ATR }) => {
		return transactionType === "INS" ? T_DTD_ATR.VISIBLE && FIELDTYPE.VALUE !== "E" : T_DTD_ATR.VISIBLE
	})

	const tabs = visibleFields.map(({ ID, T_DTD_ATR }) => {
		return ["ID", "IMG", "DESCRIPTION"].includes(ID)
			? null
			: T_DTD_ATR.TAB ?? { DESCRIPTION: "Main" }
	})

	const sortedTabs = tabs.filter(Boolean)
		.sort((a, b) => a.ORDERBY - b.ORDERBY)
		.map(({ DESCRIPTION }) => DESCRIPTION)

	const uniqueTabs = [...new Set(sortedTabs)]

	return await uniqueTabs.map((tabName) => ({
		tabName,
		isDetail: detailTabs.some(({ fieldDescription }) => fieldDescription === tabName),
		id: detailTabs.find(({ fieldDescription }) => fieldDescription === tabName)?.fieldId
	}))
}
