const getBrands = async (controller) => {
  const data = controller.recordsList

  const brands = data.map(item => item.TAG).filter(item => item !== null)
  let uniqueBrands = [...new Set(brands)]
  uniqueBrands = uniqueBrands.map(item => {
    return {
      VALUE: item,
      LABEL: item
    }
  })

  return uniqueBrands
}

export default getBrands
