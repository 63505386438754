export default class BaseController {

  models: object

  navigate = (s: string, o?: object) => null
  updateList = () => null
  changeScreen = () => null

  persistScreen: () => null
  getPersistence: () => null
  getPersistenceName: () => null

  screenName: () => string
}
