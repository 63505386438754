import React from 'react'
import { useTranslation } from 'react-i18next'
import DateInput from './components/Date'
import DatetimeInput from './components/Datetime'
import FloatInput from './components/Float'
import IntegerInput from './components/Integer'
import SearchInput from './components/Search'
import SelectionInput from './components/SelectionInput'
import StringInput from './components/String'
import * as S from './styles'

export default function IdInput({ entity, models, updateForm, setUpdateForm }) {
  const { t } = useTranslation()

  const tableData = models._REGISTRATION_DATA[entity]

  const InputsMap = {
    "D": DateInput,
    "F": FloatInput,
    "I": IntegerInput,
    "K": SearchInput,
    "L": SelectionInput,
    "S": StringInput,
    "T": DatetimeInput
  }

  const InputComponent = InputsMap[tableData.pkType]

  return (
    <S.Container>
      <S.Title>{t("ID")}</S.Title>

      <InputComponent
        entity={entity}
        models={models}
        updateForm={updateForm}
        setUpdateForm={setUpdateForm}
      />
    </S.Container>
  )
}