import React, { useContext, useEffect, useState } from "react"
import DraggableList from "../../../../../components/DraggableList"
import ModalWrapper from "../../../../../components/Modal"
import { ControllerContext } from "../../../contexts/ControllerProvider"
import { ModalsContext } from "../../../contexts/ModalsProvider"
import Container from "./styles"
import getFieldsArray from "./utils/getFieldsArray"
import handleConfirm from "./utils/handleConfirm"

export default function OrderFieldsModal() {
  const { controller, models } = useContext(ControllerContext)
  const { modalsVisible, closeModal } = useContext(ModalsContext)

  const [fields, setFields] = useState([])

  useEffect(() => { getFieldsArray(models).then(setFields) }, [])

  const handleSave = () => {
    handleConfirm({
      fields,
      closeModal,
      controller
    })
  }

  const handleClose = () => { closeModal("orderFields") }

  return (
    <ModalWrapper isVisible={modalsVisible.orderFields}>
      <Container>
        <DraggableList
          data={fields}
          setData={setFields}
          title="Fields ordering"
          cardValueAttribute="DESCRIPTION"
          onSave={handleSave}
          onClose={handleClose}
        />
      </Container>
    </ModalWrapper>
  )
}
