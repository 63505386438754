import React, { createContext, useRef, useState } from "react"
import modelTemplate from "../../../../models"

export const RegistrationContext = createContext()

const RegistrationProvider = ({ children }) => {
  const [mode, setMode] = useState("grid")
  const [models] = useState(modelTemplate.getClone())
  const [updateForm, setUpdateForm] = useState(false)
  const [loadingFormConfiguration, setLoadingFormConfiguration] = useState(false)

  const gridRef = useRef(null)

  const toggleMode = () => setMode(prevMode => prevMode === "grid" ? "form" : "grid")

  return (
    <RegistrationContext.Provider value={{
      mode,
      toggleMode,
      models,
      updateForm,
      setUpdateForm,
      gridRef,
      loadingFormConfiguration,
      setLoadingFormConfiguration
    }}>
      {children}
    </RegistrationContext.Provider>
  )
}

export default RegistrationProvider
