import React, { useEffect, useState, useCallback } from "react"
import { View } from "react-native"
import * as S from "./styles"
import { functionsTypeArray } from "./utils/functionsTypeArray"
import getFieldsOfField from "./utils/getFieldsOfField"

export default function Card({
  fieldData,
  setOriginArray,
  setDestinyArray,
  isAccumulatorField
}) {

  const { ID, FIELDDESCRIPTION, FIELDTYPE, ENTITY, SUBFIELD, FUNCTION, ISDETAILSCREENFIELD = false } = fieldData

  const [fields, setFields] = useState([])
  const [selectedSubField, setSelectedSubField] = useState(SUBFIELD || null)
  const [selectedFunction, setSelectedFunction] = useState(FUNCTION || null)

  useEffect(() => {
    if (["K", "N"].includes(FIELDTYPE) && ENTITY) {
      const loadFields = async () => {
        const response = await getFieldsOfField(ENTITY.ID)
        setFields(response)
      }
      loadFields()
    }
  }, [ENTITY, FIELDTYPE])

  const updateFieldInArray = useCallback((fieldUpdater) => {
    setDestinyArray(state => state.map(field =>
      field.ID === ID ? {
        ...field,
        ...fieldUpdater,
        ID: fieldUpdater?.SUBFIELD?.ID ? `${ID}.${fieldUpdater.SUBFIELD.ID}` : field.ID
      } : field
    ))
  }, [ID, setDestinyArray])

  const handleFunctionChange = useCallback((newFunction) => {
    setSelectedFunction(newFunction)
    updateFieldInArray({ FUNCTION: newFunction })
  }, [updateFieldInArray])

  const handleSubFieldChange = useCallback((newSubField) => {
    setSelectedSubField(newSubField)
    updateFieldInArray({ SUBFIELD: newSubField })
  }, [])

  const onRemoveField = useCallback(() => {
    setDestinyArray(state => state.filter(field => field.ID !== ID))
  }, [])

  const addField = useCallback(() => {
    if (!["K", "N"].includes(FIELDTYPE)) {
      setOriginArray(state => {
        const newState = [...state, { ...fieldData }]
        newState.sort((a, b) => a.FIELDDESCRIPTION.localeCompare(b.FIELDDESCRIPTION))
        return newState
      })
    }
  }, [])

  const fieldDescription = ISDETAILSCREENFIELD ? `${ENTITY.LABEL} > ${FIELDDESCRIPTION}` : FIELDDESCRIPTION

  return (
    <S.Container>
      <div title={fieldDescription} style={{ width: "20%" }}>
        <S.Text numberOfLines={1}>{fieldDescription}</S.Text>
      </div>
      <S.ButtonsView>
        <View style={{
          width: "45%",
          alignItems: "center"
        }}>
          {isAccumulatorField && (
            <S.ListInput
              data={functionsTypeArray}
              value={selectedFunction?.VALUE}
              dropdownPosition="bottom"
              valueField="VALUE"
              labelField="LABEL"
              style={{ width: 120 }}
              containerWidth={120}
              placeholder={"Select"}
              onChange={(item) => handleFunctionChange(item)}
            />
          )}
        </View>
        <View style={{
          width: "45%",
          alignItems: "center"
        }}>
          {["K", "N"].includes(FIELDTYPE) && (
            <S.ListInput
              data={fields}
              value={selectedSubField?.ID}
              dropdownPosition="bottom"
              labelField="LABEL"
              valueField="ID"
              placeholder={"Select"}
              search
              onChange={(item) => handleSubFieldChange(item)}
            />
          )}
        </View>
        <View style={{
          width: "10%",
          alignItems: "center"
        }}>
          <S.DeleteButton
            onPress={() => {
              onRemoveField()
              addField()
            }}
          >
            <S.DeleteIcon name="trash" />
          </S.DeleteButton>
        </View>
      </S.ButtonsView>
    </S.Container>
  )
}

