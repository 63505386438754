import Tagify from "@yaireo/tagify"
import React, { useRef, useEffect } from "react"
import { useTranslation } from "react-i18next"
import AccessibilityBox from "../AccessibilityBox"
import useSelection from "../hooks/useSelections"
import * as S from "./styles.web"
import "@yaireo/tagify/dist/tagify.css"
import "./style.css"

export default function MultiSelect({ models, field, updateForm }) {
  const { options } = useSelection({
    models,
    field,
    updateForm
  })

  const { t } = useTranslation()

  const { ID, TABLENAME, T_DTD_ATR } = field
  const { EDITABLE, REQUIRED, FIELDDESCRIPTION, HELPTEXT } = T_DTD_ATR

  const optionsData = options.map((option) => option.LABEL)
  const tagifyRef = useRef(null)

  useEffect(() => {
    const tagify = new Tagify(tagifyRef.current, {
      whitelist: optionsData,
      enforceWhitelist: true,
      dropdown: {
        enabled: 0,
        maxItems: isFinite
      }
    })

    tagify.on("change", (e) => {
      const selectedValues = e.detail.value ? JSON.parse(e.detail.value) : []
      const formattedValues = []

      for (const option of options) {
        for (const selectedValue of selectedValues) {
          if (option.LABEL === selectedValue.value) {
            formattedValues.push({
              VALUE: option.VALUE,
              LABEL: option.LABEL
            })
          }
        }
      }

      models[TABLENAME][ID] = formattedValues
    })

    return () => {
      tagify.destroy()
    }
  }, [optionsData, EDITABLE])

  return (
    <div>
      <S.Row>
        <S.Title
          isEditable={EDITABLE}
          disabled={!EDITABLE}
          isRequired={REQUIRED}
        >
          {t(FIELDDESCRIPTION)}
        </S.Title>
        <AccessibilityBox text={HELPTEXT} />
      </S.Row>
      <S.MultiSelect
        isEditable={EDITABLE}
        disabled={!EDITABLE}
        ref={tagifyRef}
        value={
          Array.isArray(models[TABLENAME][ID]) ? models[TABLENAME][ID].map((value) => value.LABEL) : []
        }
        placeholder={t("Select or type to search...")}
      />
    </div>
  )
}
