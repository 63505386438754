import Ionicons from "@expo/vector-icons/Ionicons"
import React, { useCallback, useContext } from "react"
import { useTranslation } from "react-i18next"
import { defaultTheme } from "../../../../../../../../../../../styles/themes/default"
import { SearchTextContext } from "../../contexts/SearchTextContext"
import Card from "./components/Card"
import * as S from "./styles"

export default function NonVisibleFieldsList(props) {
  const { t } = useTranslation()

  const { nonVisibleFields } = props

  const { searchText, setSearchText } = useContext(SearchTextContext)

  const renderCard = useCallback((field) => {
    return (
      <Card
        {...props}
        key={field.id}
        fieldData={field}
      />
    )
  }, [])

  const filteredFields = nonVisibleFields.filter(({ description }) => {
    const searchedValue = searchText.toUpperCase()
    const fieldDescription = t(description).toUpperCase()
    return fieldDescription.includes(searchedValue)
  })

  return (
    <S.Container>
      <S.Header>
        <Ionicons name="eye-off-outline" size={24} color={defaultTheme["gray-700"]} />
        <S.Title>{t("Hidden Fields")}</S.Title>

        <S.SearchContainer>
          <S.SearchInput
            placeholder={t("Search")}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <Ionicons name="search-outline" size={22} color={defaultTheme["gray-500"]} style={{
            position: "absolute",
            right: 7,
            zIndex: 10
          }} />
        </S.SearchContainer>
      </S.Header>

      <S.ListContainer>
        <S.List>
          {filteredFields.map(renderCard)}
        </S.List>
      </S.ListContainer>
    </S.Container >
  )
}
