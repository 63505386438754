import styled from "styled-components"
import styledNative from "styled-components/native"
import { InputField } from "../../../../defaultInputsStyles"
import { defaultTheme } from "../../../../../../styles/themes/default"

export const Container = styled.div``

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

interface TitleProps {
  isRequired: boolean
}

export const Title = styled.p<TitleProps>`
  color: ${defaultTheme["cyan-800"]};
  font-size: ${defaultTheme.formInput.fontSize}px;
  font-weight: ${({ isRequired }) => isRequired ? "bold" : "normal"};
  line-height: 20px;

  &::after {
    content: "*";
    color: ${defaultTheme["cyan-800"]};
    display: ${({ isRequired }) => isRequired ? "inline" : "none"};
  }
`

interface InputProps {
  isEditable: boolean
  isNegative: boolean
}

export const Input = styledNative(InputField)
  .attrs<InputProps>(({ isEditable }) => ({
    editable: isEditable,
    focusable: isEditable,
    keyboardType: "numeric",
    placeholderTextColor: defaultTheme["gray-300"],
    selectTextOnFocus: true
  }))<InputProps>`
    background-color: ${({ isEditable }) => defaultTheme[isEditable ? "white" : "gray-200"]};
    color: ${({ isNegative }) => defaultTheme[isNegative ? "red-500" : "gray-700"]};
    font-size: ${defaultTheme.formInput.fontSize}px;
    border: 1px solid ${defaultTheme["gray-300"]};
    border-radius: 10px;
    padding: 0 10px;
    height: ${defaultTheme.formInput.height}px;
    outline: none;
    text-align: right;
`
