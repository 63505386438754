interface doubleClickEvent {
  isActive: boolean,
  ID: NodeJS.Timeout
}

const doubleClickEvent: doubleClickEvent = {
  isActive: false,
  ID: null
}

export default function getDoubleClickEvent(callback: (arg0: doubleClickEvent) => void): void {

  clearTimeout(doubleClickEvent.ID)
  callback(doubleClickEvent)

  doubleClickEvent.isActive = true
  doubleClickEvent.ID = setTimeout(() => { doubleClickEvent.isActive = false }, 400)
}
