import { Platform } from "react-native"
import LoginData from "../models/loginData"

export default class QueryBuilder {

  constructor(query) { this._query = query }

  static padronize = (query, controller) => { return new QueryBuilder(padronizeQuery(query, controller)) }
  padronize = (controller) => {
    this._query = padronizeQuery(this._query, controller)
    return this
  }

  static prepare = (query, arr = []) => { return new QueryBuilder(prepareQuery(query, arr)) }
  prepare = (arr = []) => {
    this._query = prepareQuery(this._query, arr)
    return this
  }

  static replace = (query, obj = {}) => { return new QueryBuilder(queryReplacement(query, obj)) }
  replace = (obj = {}) => {
    this._query = queryReplacement(this._query, obj)
    return this
  }

  build = () => this._query
}

function padronizeQuery(query, controller = null) {
  query = Platform.OS === "web"
    ? query
    : query.replace(/NVL/g, "IFNULL")

  query = query.replace(/\{get_CODUSU\}/g, LoginData.getLoginData().ID)

  if (controller) {
    let updatedQuery = replaceModelsOcurrence(query, controller)
    if (updatedQuery) query = updatedQuery
  }
  return query
}

function prepareQuery(query, arr = []) {
  for (let i = 0; i < arr.length; i++) { query = query.replace("?", arr[i]) }
  return query
}

function queryReplacement(query, obj = {}) {
  return Object.entries(obj)
    .reduce((acc, [key, value]) => {
      const res = new RegExp(`:${key}\\b`, "g")
      return acc.replace(res, value ? typeof value === "string" ? `${value}` : value : null)
    }, query)
    .replace(/(:\b\w+\b)/g, null)
}

function replaceModelsOcurrence(str, controller) {
  while (str.indexOf("{models") !== -1) {
    const modelsOcurrenceIndex = str.indexOf("{models")
    if (modelsOcurrenceIndex === -1) return null

    const firstDotIndex = str.indexOf(".", modelsOcurrenceIndex)
    if (firstDotIndex === -1) return null

    const secondDotIndex = str.indexOf(".", firstDotIndex + 1)
    if (secondDotIndex === -1) return null

    const nextWordStartIndex = secondDotIndex + 1
    let nextWordEndIndex = str.indexOf("}", nextWordStartIndex)
    if (nextWordEndIndex === -1) return null

    const wordBetweenDots = str.substring(firstDotIndex + 1, secondDotIndex)
    const wordAfterSecondDot = str.substring(nextWordStartIndex, nextWordEndIndex)
    let reg = new RegExp(`{models.${wordBetweenDots}.${wordAfterSecondDot}}`, "g")

    str = str.replace(reg, controller.models()[wordBetweenDots][wordAfterSecondDot] ?? null)
  }
  return str
}
