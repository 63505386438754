import { Feather, MaterialCommunityIcons } from "@expo/vector-icons"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import LoginData from "../../../../../../../models/loginData"
import User from "../../../../../../../services/dbRequest/user"
import showAlert from "../../../../../../../services/showAlert"
import { defaultTheme } from "../../../../../../../styles/themes/default"
import * as S from "./styles"

export default function UserForm({ userData }) {

  const { t } = useTranslation()

  const [isEditing, setIsEditing] = useState(false)
  const [isHovering, setIsHovering] = useState({
    resetPasswordButton: false,
    editButton: false
  })

  const [values, setValues] = useState({
    FIRSTNAME: userData.FIRSTNAME,
    LASTNAME: userData.LASTNAME,
    PHONENUMBER: userData?.PHONENUMBER || ""
  })

  const handleChange = (event, field) => {
    setValues({
      ...values,
      [field]: event.target.value
    })
  }

  const handleResetPassword = async () => {
    try {
      showAlert({
        title: "Reset Password",
        text: "Do you want to reset your password?",
        titleType: "question",
        type: "confirm",
        handleConfirm: async () => {
          await User.resetPassword(userData.EMAIL)
          showAlert({
            text: "Check your inbox",
            title: "Email sent",
            titleType: "success"
          })
        }
      })
    } catch (error) {
      console.log(error)
      showAlert({
        text: error.message,
        title: "Error",
        titleType: "error"
      })
    }
  }

  return (
    <S.InformationContainer>
      <S.ResetPasswordButton
        name='resetPasswordButton'
        content={t("Reset Password")}
        onClick={handleResetPassword}
        onMouseEnter={() => setIsHovering({
          ...isHovering,
          resetPasswordButton: true
        })}
        onMouseLeave={() => setIsHovering({
          ...isHovering,
          resetPasswordButton: false
        })}
      >
        <MaterialCommunityIcons name="lock-reset" size={18} color={isHovering.resetPasswordButton ? defaultTheme["white"] : defaultTheme["red-400"]} />
      </S.ResetPasswordButton>
      <S.EditButton
        name='editButton'
        content={isEditing ? t("Save") : t("Edit")}
        onClick={() => {
          if (isEditing) {
            const userCurrentData = LoginData.getLoginData()
            userCurrentData.FIRSTNAME = values.FIRSTNAME
            userCurrentData.LASTNAME = values.LASTNAME
            userCurrentData.PHONENUMBER = values.PHONENUMBER

            LoginData.setLoginData({ ...userCurrentData })

            setIsEditing(false)
          } else setIsEditing(true)
        }}
        onMouseEnter={() => setIsHovering({
          ...isHovering,
          editButton: true
        })}
        onMouseLeave={() => setIsHovering({
          ...isHovering,
          editButton: false
        })}
      >
        <Feather name={isEditing ? "check" : "edit"} size={16} color={isHovering.editButton ? defaultTheme["white"] : defaultTheme["cyan-700"]} />
      </S.EditButton>

      <S.FieldContainer>
        <S.FieldLabel>{t("First Name")}</S.FieldLabel>
        {isEditing ? (
          <S.InputField
            type="text"
            value={values.FIRSTNAME}
            onChange={event => handleChange(event, "FIRSTNAME")}
          />
        ) : (
          <S.FieldValue>{values.FIRSTNAME}</S.FieldValue>
        )}
      </S.FieldContainer>
      <S.FieldContainer>
        <S.FieldLabel>{t("Last Name")}</S.FieldLabel>
        {isEditing ? (
          <S.InputField
            type="text"
            value={values.LASTNAME}
            onChange={event => handleChange(event, "LASTNAME")}
          />
        ) : (
          <S.FieldValue>{values.LASTNAME}</S.FieldValue>
        )}
      </S.FieldContainer>
      <S.FieldContainer>
        <S.FieldLabel>{t("Email Address")}</S.FieldLabel>
        <S.FieldValue>{userData.EMAIL}</S.FieldValue>
      </S.FieldContainer>
      <S.FieldContainer>
        <S.FieldLabel>{t("Phone Number")}</S.FieldLabel>
        {isEditing ? (
          <S.InputField
            type="text"
            value={values?.PHONENUMBER}
            onChange={event => handleChange(event, "PHONENUMBER")}
          />
        ) : (
          <S.FieldValue>{values?.PHONENUMBER || "--"}</S.FieldValue>
        )}
      </S.FieldContainer>
      <S.FieldContainer>
        <S.FieldLabel>{t("Role")}</S.FieldLabel>
        <S.FieldValue>{userData?.ROLE}</S.FieldValue>
      </S.FieldContainer>
      <S.FieldContainer>
        <S.FieldLabel>{t("Group")}</S.FieldLabel>
        <S.FieldValue>{userData?.GROUPUID?.LABEL || "--"}</S.FieldValue>
      </S.FieldContainer>
      <S.FieldContainer>
        <S.FieldLabel>{t("Manager")}</S.FieldLabel>
        <S.FieldValue>{userData?.SUPERIORUID?.LABEL || "--"}</S.FieldValue>
      </S.FieldContainer>
      <S.FieldContainer>
        <S.FieldLabel>{t("Company")}</S.FieldLabel>
        <S.FieldValue>{userData?.COMPANYID?.LABEL || "--"}</S.FieldValue>
      </S.FieldContainer>
      <S.FieldContainer>
        <S.FieldLabel>{t("City")}</S.FieldLabel>
        <S.FieldValue>{userData?.CITY?.LABEL || "--"}</S.FieldValue>
      </S.FieldContainer>
    </S.InformationContainer>
  )
}

