export default ({ entityName, models }) => {
  const { fields } = models._REGISTRATION_DATA[entityName]

  const filteredFields = fields.filter(({ FIELDTYPE, T_DTD_ATR }) => {
    const shouldExportField = !["A", "E", "R"].includes(FIELDTYPE.VALUE)
    const isFieldVisible = T_DTD_ATR.VISIBLE

    return shouldExportField && isFieldVisible
  })

  return filteredFields.map(({ ID, T_DTD_ATR }) => ({
    ID,
    DESCRIPTION: T_DTD_ATR.FIELDDESCRIPTION
  }))
}
