import { createAsyncThunk } from "@reduxjs/toolkit"
import RuntimeConfiguration from "../../../../models/runtimeConfiguration"
import dbRequest from "../../../../services/dbRequest/dbRequest"
import showAlert from "../../../../services/showAlert"

const deleteOptionList = createAsyncThunk(
  "dashboardBuilder/deleteOptionList",
  async ({ path, selectedOption }, thunkAPI) => {

    const isAdminMode = RuntimeConfiguration.getDashboardBuilderAdminMode()

    if (!selectedOption) {
      return thunkAPI.rejectWithValue(showAlert({
        title: "No option were selected.",
        titleType: "warning",
        text: "Please select the option to be deleted."
      }))
    }

    try {
      const response = await new Promise((resolve, reject) => {
        showAlert({
          title: "This action cannot be undone.",
          titleType: "warning",
          text: "Do you want to delete the option?",
          type: "confirm",
          handleConfirm: async () => {
            try {
              const request = dbRequest.deleteRecords(path).record(selectedOption)

              if (isAdminMode) request.admin()

              await request.execute(() => {
                showAlert({
                  title: "Success",
                  text: "Option deleted successfully.",
                  titleType: "success"
                })
              })

              const updateOptions = await dbRequest.loadRecords(path).execute(response => {
                return response.map(item => {
                  return {
                    VALUE: item.ID,
                    LABEL: item.VALUE.LABEL,
                    DEFAULTVALUE: item.VALUE.DEFAULTVALUE
                  }
                })
              })

              resolve({
                updateOptions
              })
            } catch (error) {
              reject(error)
            }
          }
        })
      })

      return response
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(showAlert({
        title: "Error when trying to delete option.",
        titleType: "error",
        text: "Please try again."
      }))
    }
  }
)

export default deleteOptionList
