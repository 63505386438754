import RuntimeConfiguration from "../../../../../../../models/runtimeConfiguration"
import dbRequest from "../../../../../../../services/dbRequest/dbRequest"
import showAlert from "../../../../../../../services/showAlert"
import { menuDrawerObserver } from "../../../../../../components/MenuDrawer/hooks/useMenu"

export default async function saveNavigation({ selectedDashboard, models, closeModal }) {

  const isAdmin = RuntimeConfiguration.getDashboardBuilderAdminMode()

  const transactionID = selectedDashboard.ID

  if (!models.MENU && !isAdmin) {
    models.MENU = {
      DEPTH: 0,
      DESCRIPTION: "Personalized",
      MASTERID: 0,
      ORDERBY: 999,
      ID: "9999",
      T_DTD_LNC: {}
    }
  }

  if (!models.MENU.T_DTD_LNC) models.MENU.T_DTD_LNC = {}

  models.MENU.T_DTD_LNC[transactionID] = {
    ACTIVE: true,
    ATTRIBUTE: selectedDashboard.ID,
    DESCRIPTION: selectedDashboard.DESCRIPTION,
    ONMOB: false,
    TRANSACTION: transactionID,
    TYPE: "Dashboard"
  }

  delete models.MENU.open
  delete models.MENU.SUB_MENUS

  let navRequest = dbRequest.saveRecords("T_DTD_NAV").record({ ...models.MENU })
  let tabRequest = dbRequest.saveRecords("T_RDB_DSB").record({ ...selectedDashboard, NAVID: models.MENU.ID, ORDERBY: 999 })

  if (isAdmin) {
    navRequest = navRequest.admin()
    tabRequest = tabRequest.admin()
  }

  try {
    await Promise.all([navRequest.execute(), tabRequest.execute()])
    menuDrawerObserver.notify()
    closeModal()
    showAlert({
      text: "Dashboard included in navigation successfully.",
      title: "Dashboard saved",
      titleType: "success"
    })

  } catch (error) {
    console.log(error)
    showAlert({
      text: "Something went wrong. Please try again.",
      title: "Dashboard not saved",
      titleType: "error"
    })
  }

}
