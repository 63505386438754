import showAlert from "../../../../../services/showAlert"

export default async function validationFields(controller, entityName, idDashboard, idfilial) {

  let isValid = false
  let allFields = []
  let dates = []

  let ini = null
  let fin = null

  let formatedRequiredFields = []
  allFields.filter(field => field.REQUIRED === "true")
    .map(field => {
      if (field.TIPCAMPO === "X") {
        ini = field.NOMECAMPO + "_INI"
        fin = field.NOMECAMPO + "_FIN"
        dates.push(field)
      }
      formatedRequiredFields.push({
        NOMECAMPO: field.NOMECAMPO,
        DESCRCAMPO: field.DESCRCAMPO
      })
    })

  if (formatedRequiredFields.length === 0) isValid = true

  if (dates.length > 0) {
    if (!(controller.models()?.[entityName]?.[ini]) || !(controller.models()?.[entityName]?.[fin]))
      showAlert({
        text: "Enter a valid period",
        title: "Invalid period",
        titleType: "error"
      })
    else {
      isValid = true
    }
  }

  let valid = []
  formatedRequiredFields.map(field => {
    if (field.NOMECAMPO === "PERIODO") return
    if (
      field.NOMECAMPO in controller.models()[entityName] === false || controller.models()[entityName][field.NOMECAMPO] === ("" || undefined)
    ) {
      showAlert({
        text: `${field.T_DTD_ATR.FIELDDESCRIPTION}`,
        title: "Required field not filled",
        titleType: "error"
      })
    } else {
      valid.push(field.NOMECAMPO)
      if (valid.length === formatedRequiredFields.length) isValid = true
    }
  })
  return isValid
}
