import LoginData from "../../../../models/loginData"
import dbAccess from "../../../../services/dbRequest/dbAccess"
import userConfiguration from "../../../../services/dbRequest/userConfiguration"

export default async function updateBranch(newBranch) {
  await dbAccess.connectBranch(newBranch)

  const { ID } = LoginData.getLoginData()
  await userConfiguration.setBranchData(ID)
}
