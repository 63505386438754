import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import globalStyles from "../../../../../../../../../global/globalStyles"
import LoadingAnimation from "../../../../../../../LoadingAnimation"
import RenderPermissions from "./components/RenderPermissions"
import * as S from "./styles"
import getRoutines from "./utils/getRoutines"

export default function RenderRoutines({ entityName, models, permissions }) {
  const { t } = useTranslation()

  const [routines, setRoutines] = useState([])
  const [isFetchingTransactions, setIsFetchingTransactions] = useState(false)

  const handlePress = (index) => {
    setRoutines(state => {
      const newState = [...state]
      const currentRoutine = newState[index]

      newState[index] = {
        ...currentRoutine,
        icon: currentRoutine.icon === "arrow-drop-down" ? "arrow-right" : "arrow-drop-down",
        open: !currentRoutine.open
      }

      return newState
    })
  }

  useEffect(() => {
    setIsFetchingTransactions(true)
    getRoutines()
      .then(routines => {
        setRoutines(routines)
        setIsFetchingTransactions(false)
      })
  }, [])

  return (
    <S.Container contentContainerStyle={{ flex: 1 }}>
      {isFetchingTransactions ? (
        <S.LoadingContainer>
          <LoadingAnimation size='medium' color={globalStyles.colors.primary} />
        </S.LoadingContainer>
      ) : (
        routines.map((routine, index) => (
          <S.RoutineContainer key={index}>
            <S.Button index={index} onPress={() => handlePress(index)}>
              <S.Icon name={routine.icon} />

              <S.Text>{t(routine.DESCRIPTION)}</S.Text>
            </S.Button>

            {routine["open"] && (
              <RenderPermissions
                item={routine}
                permissions={permissions}
                models={models}
                entityName={entityName}
              />
            )}
          </S.RoutineContainer>
        )))
      }
    </S.Container>
  )
}
