import LoginData from "../../../../../../models/loginData"
import RuntimeConfiguration from "../../../../../../models/runtimeConfiguration"
import dbRequest from "../../../../../../services/dbRequest/dbRequest"
import getTableFields from "../../../../../../services/registration/getTableFields"

export default async ({ entityName, screenBuilder }) => {
  const isAdminRequest = RuntimeConfiguration.getIsDeveloperConfigurationMode()

  let request = dbRequest.loadRecords(`T_DTD_TAB/${entityName}/T_DTD_FLT`).where("ID", "==", LoginData.getLoginData().ID)

  if (isAdminRequest && screenBuilder) request = request.admin()

  let filters = await request.execute()

  if (!filters) return []

  filters = filters[0].FILTERS ?? {}

  const filtersIDs = Object.keys(filters)
  if (filtersIDs.length === 0) return []

  const tableFields = await getTableFields({ entityName })

  const visibleFilters = tableFields.filter(({ ID }) => ID in filters)

  for (const field of visibleFilters) {
    const filter = filters[field.ID]

    field.isFilter = true

    field.T_DTD_ATR = {
      ...field.T_DTD_ATR,
      REQUIRED: filter.REQUIRED,
      ORDERBY: filter.ORDERBY,
      EDITABLE: true,
      TAB: { DESCRIPTION: "Main" }
    }

    delete field.T_DTD_ATR.GROUPING
    delete field.T_DTD_ATR.EXPRESSION
    delete field.T_DTD_ATR.DEFAULTVALUE
  }

  return visibleFilters
}
