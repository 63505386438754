import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { GoogleChartWrapperChartType } from "react-google-charts"
import { ChartAttributesProps } from "../../../libs/Dashboard/types/types"

type ModalDataAction = {
  isVisible: boolean
  data?: (string | number)[][] | null
  attributes?: ChartAttributesProps | null
  chartType?: GoogleChartWrapperChartType | null
}

type Chart = Record<string, {
  isUpdated: boolean,
  selectedRow: object | null
}>

type DashboardState = {
  mainUpdate: boolean
  currentLevel: string
  depthStack: Array<{ previousLevel: string, chartParams: object }>
  dashboardParams: object
  modal: ModalDataAction
  charts: Chart,
  data: object
}

interface SelectedRowProps {
  serieName: string
  row: number,
  chartId: string
}

const initialState: DashboardState = {
  currentLevel: "1",
  mainUpdate: true,
  modal: { isVisible: false },
  depthStack: [],
  dashboardParams: {},
  charts: {},
  data: {}
}

const dashboardSlice = createSlice({
  name: "dashboardReducer",
  initialState,
  reducers: {
    setInitialState: (state, { payload }: PayloadAction<{ charts: Chart }>) => {
      const { charts } = payload

      return {
        ...state,
        charts
      }
    },
    setData: (state, { payload }: any) => {
      state.data = payload
    },
    forceUpdate: (state) => { state.mainUpdate = !state.mainUpdate },
    updateThisChart: (state, { payload }: PayloadAction<string>) => {
      const chartId = payload

      state.charts[chartId].isUpdated = !state.charts[chartId].isUpdated
    },
    backInStack: (state) => {
      const withdrawnItem = state.depthStack.pop()

      if (withdrawnItem) {
        state.currentLevel = withdrawnItem.previousLevel
        state.dashboardParams = withdrawnItem.previousLevel === "1" ? {} : withdrawnItem.chartParams
      }
    },
    resetState: (state) => {
      state.currentLevel = "1"
      state.depthStack = []
      state.dashboardParams = {}
      state.mainUpdate = !state.mainUpdate
    },
    updateCharts: (state, { payload }: PayloadAction<{ chartIdArr: string[], idacaoArr: string[], chartParams: object, selectedRow: SelectedRowProps }>) => {
      const { chartIdArr, idacaoArr, chartParams, selectedRow } = payload
      const { charts, dashboardParams } = state

      chartIdArr.forEach((chartId) => {
        const chart = charts[chartId]
        chart.selectedRow = null
      })

      charts[selectedRow.chartId].selectedRow = charts[selectedRow.chartId].selectedRow?.row === selectedRow.row ? null : selectedRow

      idacaoArr.forEach(() => {

        state.dashboardParams = { ...chartParams }

        for (const key in chartParams) {
          if (JSON.stringify(chartParams[key]) === JSON.stringify(dashboardParams[key])) delete state.dashboardParams[key]
        }

      })

      chartIdArr.forEach((chartId) => {
        const chart = charts[chartId]
        if (chartId !== selectedRow.chartId) chart.isUpdated = !chart.isUpdated
      })
    },
    updateActions: (state, { payload }: PayloadAction<{ newLevel: string, chartParams: object, previousLevel: string }>) => {
      const { newLevel, chartParams, previousLevel } = payload

      state.currentLevel = newLevel
      state.dashboardParams = chartParams
      state.depthStack.push({
        previousLevel,
        chartParams
      })
    },
    getModalData: (state, { payload }: PayloadAction<ModalDataAction>) => {
      const { isVisible, data, attributes, chartType } = payload

      state.modal = {
        data,
        isVisible,
        attributes,
        chartType
      }
    }
  }
})

export const { forceUpdate, backInStack, resetState, updateActions, updateCharts, getModalData, setInitialState, updateThisChart, setData } = dashboardSlice.actions
export const dashboardReducer = dashboardSlice.reducer
