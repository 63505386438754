import { MaterialIcons } from "@expo/vector-icons"
import React, { useState } from "react"
import { Text } from "react-native"
import { useDispatch } from "react-redux"
import useAppSelector from "../../../../../../redux/hooks/useAppSelector"
import { closeModal, openFilterModal, setSelectedFilter } from "../../../../../../redux/redux-reducers/dashboardBuilder-reducer"
import ModalWrapper from "../../../../../components/Modal"
import * as S from "../modalStyles"
import deleteFilter from "./utils/deleteFilter"

export default function FiltersConfgurationModal() {

  const dispatch = useDispatch()

  const [focusedIndex, setFocusedIndex] = useState(null)

  const { isVisible, name, filters } = useAppSelector(({ dashboardBuilderReducer }) => dashboardBuilderReducer.modals.filtersConfigurationModal)
  const { selectedQuery, selectedDashboard } = useAppSelector(({ dashboardBuilderReducer }) => dashboardBuilderReducer.dashboardData)

  return (
    <ModalWrapper isVisible={isVisible}>
      <S.Container style={{ width: "40%" }}>
        <S.CloseButton onPress={async () => {
          dispatch(closeModal({ modalName: name }))
        }}>
          <MaterialIcons name="close" size={20} color="#333333" />
        </S.CloseButton>

        <S.Title>Filters Configuration</S.Title>

        <S.Header style={{
          flexDirection: "row-reverse",
          marginVertical: 10,
          marginRight: 20
        }}>
          <S.ButtonsView>

            <S.AddButton onPress={() => dispatch(openFilterModal())}>
              <S.TitleButton>New Filter</S.TitleButton>
            </S.AddButton>

            <S.DeleteButton onPress={() => deleteFilter({
              selectedFilter: filters[focusedIndex],
              selectedQuery,
              selectedDashboard,
              dispatch
            })}>
              <S.DeleteIcon name="trash-alt" />
            </S.DeleteButton>
          </S.ButtonsView>
        </S.Header>

        <S.ListArea contentContainerStyle={{ gap: 5 }}>
          {
            filters.length > 0 ? filters.map((item, index) => (
              <S.Card
                key={index}
                isSelected={index === focusedIndex}
                onPress={() => {
                  setFocusedIndex(index)
                  dispatch(setSelectedFilter({ selectedFilter: item }))
                }}
              >
                <S.CardFieldArea>
                  <S.CartText style={{ fontWeight: "600" }}>
                    Type: <S.CartText>{item.TYPE?.LABEL}</S.CartText>
                  </S.CartText>
                </S.CardFieldArea>
                <S.CardFieldArea>
                  <S.CartText style={{ fontWeight: "600" }}>
                    Field: <S.CartText>{item?.FIELD}</S.CartText>
                  </S.CartText>
                </S.CardFieldArea>
                <S.CardFieldArea>
                  <S.CartText style={{ fontWeight: "600" }}>
                    Operator: <S.CartText>{item.OPERATOR?.LABEL}</S.CartText>
                  </S.CartText>
                </S.CardFieldArea>
              </S.Card>
            ))
              :
              <Text style={{
                textAlign: "center",
                marginTop: 30,
                color: "#5e5e5e"
              }}>No filters to be displayed.</Text>
          }
        </S.ListArea>
      </S.Container>
    </ModalWrapper>
  )
}
