import { initialState } from '..'

export default function processSelectedDashboard(state, {payload}) {
  if (payload === "new") {
    state.modals.dashboardModal.form = {}
    state.modals.dashboardModal.form.DESCRIPTION = ""
    state.dashboardData = initialState.dashboardData
  }
  else state.modals.dashboardModal.form = state.dashboardData.selectedDashboard

  state.modals.dashboardModal.isVisible = true
  state.modals.dashboardModal.mode = payload
}