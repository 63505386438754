import { useEffect } from "react"
import deleteRecord from "../utils/ButtonsFunctions/deleteRecord"
import insertRecord from "../utils/ButtonsFunctions/insertRecord"
import saveRecord from "../utils/ButtonsFunctions/saveRecord"
import switchMode from "../utils/ButtonsFunctions/switchMode"

export default function useKeyboardShortcuts(props) {
  const { mode, setUpdateTable } = props

  useEffect(() => {
    const handleKeyDown = (event) => {
      const isCtrlPressed = event.ctrlKey
      const isKeyI = event.key === "i" || event.key === "I"
      const isKeyD = event.key === "d" || event.key === "D"
      const isKeyS = event.key === "s" || event.key === "S"
      const isKeyR = event.key === "r" || event.key === "R"
      const isKeySpace = event.key === " "

      if (isCtrlPressed && (isKeyI || isKeyD || isKeyS || isKeyR || isKeySpace)) {
        event.preventDefault()
        if (isKeyI) insertRecord(props)
        if (isKeyD) deleteRecord(props)
        if (isKeyS && mode === "form") saveRecord(props)
        if (isKeyR && mode === "table") setUpdateTable(s => !s)
        if (isKeySpace) switchMode(props)
      }
      else {
        if (event.key === "Insert") insertRecord(props)
        if (event.key === "Delete") deleteRecord(props)
      }
    }

    window.addEventListener("keydown", handleKeyDown)

    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, [mode])
}
