import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { FiltersContext } from "../../contexts/FiltersContext"
import * as S from "./styles"
import handleSave from "./utils/handleSave"

export default function Header({ closeModal, entityName, setUpdateFilters, controller, setUpdateTable }) {
  const { t } = useTranslation()

  const { filters } = useContext(FiltersContext)

  return (
    <S.Container>
      <S.Title>{t("Filters Configuration")}</S.Title>

      <S.ButtonsView>
        <S.SaveButton
          onPress={() => handleSave({
            closeModal,
            entityName,
            filters,
            setUpdateFilters,
            controller,
            setUpdateTable
          })}
        >
          <S.SaveText>{t("Save")}</S.SaveText>
        </S.SaveButton>

        <S.CloseButton onPress={closeModal}>
          <S.Icon name="close" />
        </S.CloseButton>
      </S.ButtonsView>
    </S.Container >
  )
}
