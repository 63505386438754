import LoginData from "../../../../models/loginData"
import dbRequest from "../../../../services/dbRequest/dbRequest"

interface Props {
  entity: string
}

const getCustomSettings = async ({ entity }: Props) => {
  const userId = LoginData.getLoginData().ID
  const settings = await dbRequest
    .loadRecords(`T_DTD_TAB/${entity}/T_DTD_FLT`)
    .where("ID", "==", userId)
    .execute()

  return settings
}

export default getCustomSettings
