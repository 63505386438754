import Permissions from "../../../../../models/permissions"
import { populateProducts } from "../../../../../redux/redux-reducers/order-reducer"
import showAlert from "../../../../../services/showAlert"
import navigateToITE from "./navigateToITE"

export default async ({ entity, controller, dispatch, setIsLoading }) => {
  const models = controller.models()
  const record = { ...models[entity] }

  // setPercentageLoading(prev => ({
  //   ...prev,
  //   isVisible: true
  // }))

  setIsLoading(true)

  if (!record) return showAlert({
    text: "No record selected",
    title: "Failed to open order",
    titleType: "error"
  })

  if (
    !Permissions.getPermission("CREATE") &&
    !Permissions.getPermission("UPDATE") &&
    !Permissions.getPermission("READ")
  ) return

  // setPercentageLoading(prev => ({
  //   ...prev,
  //   percentage: 10,
  //   stepTitle: "Searching Products..."
  // }))

  const orderItems = await controller.getItemsOrders(record.ID)
  dispatch(populateProducts(orderItems))

  // setPercentageLoading(prev => ({
  //   ...prev,
  //   percentage: 20,
  //   stepTitle: "Populating Products..."
  // }))

  await navigateToITE({ record, navigation: controller, orderItems, setIsLoading })
}
