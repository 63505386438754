import React from "react"
import HeaderItem from "./components/HeaderItem"
import Container from "./styles"
import sortRecords from "./utils/sortRecords"

export default function TableHeader({ entityName, models, fields = [], filteredRecords, setTableData }) {
  const handleSort = (field) => {
    const { columnName, sorting } = models._REGISTRATION_DATA[entityName].headerSortingData

    const newSortingDirection = columnName === field.ID
      ? (sorting === "ASC" ? "DESC" : "ASC")
      : "ASC"

    models._REGISTRATION_DATA[entityName].headerSortingData = {
      columnName: field.ID,
      sorting: newSortingDirection
    }

    const records = sortRecords({
      selectedField: field,
      filteredRecords,
      models,
      entityName
    })

    setTableData(state => ({
      ...state,
      records
    }))
  }

  return (
    <Container>
      {fields.map((field, index) => <HeaderItem key={index} itemData={field} onPress={() => handleSort(field)} />)}
    </Container>
  )
}
