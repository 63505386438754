import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import evaluateFunction from "../../../../../../services/evaluateFunction"
import * as S from "./styles"

export default function DescriptionInput({ entity, models, updateForm, setUpdateForm }) {
  const { t } = useTranslation()

  const tableData = models._REGISTRATION_DATA[entity]
  const isListPKType = tableData.pkType === "L"
  const isAnInsertion = tableData.operation === "INS"

  const fieldData = tableData.fields.find(field => field.ID === "ID")

  const { T_DTD_ATR, T_DTD_OPT } = fieldData

  const [value, setValue] = useState("")
  const [options, setOptions] = useState([])

  const handleChange = event => {

    const selectedItem = options.find(option => option.VALUE === event.target.value)

    if (isListPKType) {
      models[entity].ID = event.target.value
      models[entity].DESCRIPTION = selectedItem?.LABEL
      setUpdateForm(s => !s)
    }
    else setValue(event.target.value)
  }

  useEffect(() => {
    if (isListPKType) {
      setValue(models[entity].ID || "")

      if (T_DTD_ATR.CUSTOMLIST)
        evaluateFunction(T_DTD_ATR.CUSTOMLIST, models)
          .then(r => setOptions(getSortedOptionsArray(r)))
      else setOptions(getSortedOptionsArray(T_DTD_OPT))
    } else setValue(models[entity].DESCRIPTION || "")
  }, [updateForm])

  return (
    <S.Container>
      <S.Title>{t("Description")}</S.Title>

      {isListPKType
        ? (
          <S.SelectionInput
            disabled={!isAnInsertion}
            value={value}
            onChange={handleChange}
          >
            <option value="">{t("Select")}</option>
            {options.map(item => <option key={item.VALUE} value={item.VALUE}>{t(item.LABEL)}</option>)}
          </S.SelectionInput>
        ) : (
          <S.Input
            disabled={tableData.pkType === "K"}
            value={value}
            placeholder={t("Description")}
            onChange={handleChange}
            onBlur={() => models[entity].DESCRIPTION = value}
          />
        )}
    </S.Container>
  )
}

const getSortedOptionsArray = optionsObj => Object.entries(optionsObj ?? {})
  // @ts-ignore
  .map(([id, data]) => ({
    VALUE: id,
    ...data
  }))
  .sort((a, b) => a.ORDERBY - b.ORDERBY)
