import dbRequest from "../../../../../../../../../services/dbRequest/dbRequest"

export default async function idCreationValidation({ models, entity, detailTabPath }) {
  const hasId = Boolean(models[entity].ID)
  if (!hasId) delete models[entity].ID

  const hasSelectedImage = Boolean(models[entity].IMG?.file)

  if (hasSelectedImage) {
    var imageData = { ...models[entity].IMG }
    delete models[entity].IMG
  }

  const updatedRecord = await dbRequest
    .saveRecords(detailTabPath)
    .record({ ...models[entity] })
    .execute() as any

  models[entity].populate(hasSelectedImage ? {
    ...updatedRecord,
    IMG: imageData
  } : updatedRecord)
}
