const handleSelect = ({ event, setFileData }) => {
  const files = [...event.target.files]

  if (files.length > 0) {
    const file = files[0]
    const reader = new FileReader()
    reader.onloadend = () => {
      setFileData(state => ({
        ...state,
        file,
        preview: reader.result
      }))
    }
    reader.readAsDataURL(file)
  }
}

export default handleSelect
