import LoginData from "../../../../../../models/loginData"
import showAlert from "../../../../../../services/showAlert"

const handleSave = async ({
  selectedSetting,
  description,
  settings,
  entity,
  dbRequest,
  gridRef,
  isModalVisible,
  setDescription,
  setSelectedSetting,
  setIsModalVisible,
  setUpdate,
  models
}) => {
  try {
    const existingIndex = settings.findIndex(s => s.DESCRIPTION === selectedSetting)
    const isNewSetting = existingIndex === -1

    if (selectedSetting === "Default" && !isModalVisible) return setIsModalVisible(true)

    if (description && settings.some(s => s.DESCRIPTION === description)) {
      return showAlert({
        type: "ok",
        title: "Could not save.",
        text: "A configuration with that description already exists, please provide another name.",
        titleType: "warning"
      })
    }

    showAlert({
      type: "confirm",
      titleType: "question",
      title: !isNewSetting ? "Do you want to overwrite the setting?" : "Do you want to save the new setting?",
      text: !isNewSetting ? `Selected setting: ${selectedSetting}` : "",
      handleConfirm: async () => {
        if (!description && isNewSetting && selectedSetting === "Default") {
          return showAlert({
            text: "Invalid description",
            title: "Failed to save",
            titleType: "error"
          })
        }

        const persistData = { ...JSON.parse(gridRef.current.getPersistData()) }
        const columnsData = gridRef.current.columns

        persistData.columns = columnsData.map(col => {
          return {
            ...col,
            field: col.field,
            headerText: col.headerText,
            formatter: col.formatter ? col.formatter : null
          }
        })

        models._REGISTRATION_DATA[entity].savedProperties = persistData

        if (!isNewSetting) settings[existingIndex].PROPERTIES = JSON.stringify(persistData)
        else {
          settings.push({
            PROPERTIES: JSON.stringify(persistData),
            DESCRIPTION: description
          })
        }

        await dbRequest
          .saveRecords(`T_DTD_TAB/${entity}/T_DTD_FLT`)
          .record({
            ID: LoginData.getLoginData().ID,
            CUSTOMSETTINGS: settings
          })
          .execute()

        showAlert({
          titleType: "success",
          title: "Saved successfully."
        })

        gridRef.current.setProperties(persistData)
        //gridRef.current.refresh()
        setIsModalVisible(false)
        setSelectedSetting(description || selectedSetting)
        setDescription("")
        setUpdate(s => !s)
      }
    })
  } catch (error) {
    showAlert({
      titleType: "error",
      text: error,
      title: "Failed to save"
    })
  }
}

export default handleSave
