import styled from "styled-components"
import { defaultTheme } from "../../../../../../../styles/themes/default"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`

export const Title = styled.p`
  color: ${defaultTheme["cyan-800"]};
  font-size: ${defaultTheme.formInput.fontSize}px;
  font-weight: 500;
`

export const DividingLine = styled.div`
  background-color: ${defaultTheme["gray-300"]};
  border-radius: 3px;
  height: 1px;
  width: 45%;
`

export const FieldsContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
`
