export class ChartData {

  constructor(id) {
    this.id = id
    this.color = (() => {
      const colors = ["#ff306c", "#006ec2", "#00cf2d", "#ffa200", "#450073", "#f018f0", "#ffe74c", "#e04f0b", "#31d4e0", "#8e1cc7", "#000000"]

      return colors[id - 1]
    })()
  }

  getModel() { return this.model }

  setModel(r) { this.model = r }

  getID() { return this.id }

  getColor() { return this.color }

}

export class ChartDataArray {

  static arr = []
  static dashboardSeleceted = null
  static selectedChart = null

  static getArr() { return this.arr }
  static clearArr() { this.arr = [] }

  static getPosition(position) { return this.arr[position] }

  static getDashboardSelected() { return this.dashboardSeleceted }
  static setDashboardSelected(item) { this.dashboardSeleceted = item }

  static getSelectedChart() { return this.selectedChart }
  static setSelectedChart(id) { this.selectedChart = id }

}
