import formValidation from "../../services/formValidation"
import showAlert from "../../services/showAlert"

export default class HeaderController {

  constructor(params) {
    this.models = () => params.orderComposerController.models()
    this.OrderComposerController = params.orderComposerController
    this.masterEntity = this.OrderComposerController?.masterEntity ?? "T_MOV_HDR"
    this.detailTabPath = params.detailTabPath

    this.saved = params.saved

    this.screenName = params.name
    this.collectionName = params.collectionName

    this.formFields = params.formFields
    this.isLastTab = params.isLastTab
  }

  startChangeScreen = async (_, setIsLoading) => {
    if (formValidation({
      entity: this.masterEntity,
      models: this.models(),
      fields: this.formFields
    })) this.OrderComposerController.changeScreen({}, this, setIsLoading)
    else setIsLoading(s => !s)
  }

  async saveValues() { return null }
}
