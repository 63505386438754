import getDoubleClickEvent from "../../../../utils/getDoubleClickEvent"

export default function processSelectedConnection(state, { payload }) {
  const { connection, masterPath, entityName, collectionPath } = payload

  if (connection) {
    getDoubleClickEvent(doubleClickEvent => {
      if (doubleClickEvent.isActive) {
        state.dashboardData.selectedConnection = connection
        state.modals.connectionModal.mode = "edit"
        state.modals.connectionModal.form = connection
        state.modals.connectionModal.masterPath = `${masterPath}/CONNECTIONS/${connection.ID}`
        state.modals.connectionModal.fieldPath = connection.FIELDPATH
        state.modals.connectionModal.isVisible = true
      }
    })
  } else {
    state.modals.connectionModal.mode = "new"
    state.modals.connectionModal.masterPath = masterPath
    state.modals.connectionModal.collectionPath = collectionPath
    state.modals.connectionModal.isVisible = true
    state.modals.connectionModal.form = {}
    state.modals.connectionModal.entityName = entityName
    state.dashboardData.selectedConnection = { connections: [] }
  }
}
