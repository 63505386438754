import React, { useRef, useState, useEffect } from "react"
import LoginData from "../../../../../../../../models/loginData"
import * as S from "./styles"

export default function StringInput({ entity, models, updateForm, setUpdateForm }) {
  const [value, setValue] = useState(models[entity].ID || "")
  const inputRef = useRef(null)

  const tableData = models._REGISTRATION_DATA[entity]

  const handlePress = () => {
    tableData.manualID = !tableData.manualID
    delete models[entity].ID
    setUpdateForm(s => !s)
  }

  useEffect(() => {
    setValue(models[entity].ID || "")

    if (inputRef.current && tableData.manualID)
      inputRef.current.focus()
  }, [updateForm])

  const isSystemAdmin = LoginData.getLoginData().ADMIN
  const hasPermissionToModifyId = isSystemAdmin || tableData.permissions.MANUALID
  const isAnInsertion = tableData.operation === "INS"

  return (
    <S.InputContainer>
      <S.Input
        disabled={!tableData.manualID || tableData.operation === "UPD"}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() => models[entity].ID = value}
        placeholder="ID"
        ref={inputRef}
      />

      <S.Button
        onClick={handlePress}
        style={{ visibility: (hasPermissionToModifyId && isAnInsertion) ? "visible" : "hidden" }}
      >
        <S.Icon />
      </S.Button>
    </S.InputContainer>
  )
}
