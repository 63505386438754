import Feather from "@expo/vector-icons/Feather"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import LoginData from "../../../models/loginData"
import Observable from "../../../models/Observable"
import RuntimeConfiguration from "../../../models/runtimeConfiguration"
import { defaultTheme } from "../../../styles/themes/default"
import ModalWrapper from "../Modal"
import * as S from "./styles"
import getImageSource from "./utils/getImageSource"
import handleDrop from "./utils/handleDrop"
import handleRemove from "./utils/handleRemove"
import handleSave from "./utils/handleSave"
import handleSelect from "./utils/handleSelect"

export const branchLogoObserver = new Observable()

const allowedFileTypes = ".jpeg, .jpg, .pdf, .png"

export default function BranchLogo() {
  const { t } = useTranslation()

  const fileInputRef = useRef(null)

  // @ts-ignore
  const branchId = RuntimeConfiguration.getCurrentBranchData().ID
  const isUserAdmin = LoginData.getLoginData().ADMIN

  const [modalVisible, setModalVisible] = useState(false)
  const [isDragging, setIsDragging] = useState(false)
  const [logoSource, setLogoSource] = useState()
  const [selectedFile, setSelectedFile] = useState(null)
  const [previewImage, setPreviewImage] = useState(null)

  useEffect(() => {
    getImageSource().then(setLogoSource)
  }, [])

  const openModal = () => setModalVisible(true)
  const closeModal = () => setModalVisible(false)

  const handleDragOver = (event) => {
    event.preventDefault()
    setIsDragging(true)
  }

  const handleDragLeave = () => {
    setIsDragging(false)
  }

  const handleCloseModal = () => {
    setSelectedFile(null)
    setPreviewImage(null)
    closeModal()
  }

  branchLogoObserver.unsubscribeAll()
  const updateBranchLogo = () => {
    getImageSource().then(setLogoSource)
  }
  branchLogoObserver.subscribe(updateBranchLogo)

  return (
    <>
      <S.OpenModalButton
        onClick={openModal}
        disabled={!isUserAdmin}
        style={{ cursor: isUserAdmin ? "pointer" : "auto" }}
      >
        <S.BranchLogo
          src={logoSource}
          style={{
            width: 50,
            height: 50
          }}
        />

        <S.IconContainer className="edit-icon">
          <Feather name="edit-2" size={15} color="orange" />
        </S.IconContainer>
      </S.OpenModalButton>

      <ModalWrapper isVisible={modalVisible} onBackdropPress={closeModal}>
        <S.ModalContainer>
          <S.ModalHeader>
            <S.ModalTitle>{t("Company Logo")}</S.ModalTitle>
            <S.ModalText>{t("Select your company logo.")}</S.ModalText>
          </S.ModalHeader>

          <S.ModalBody>
            <S.LogoContainer
              onClick={() =>
                handleRemove({
                  setPreviewImage,
                  setSelectedFile
                })
              }
            >
              <S.BranchLogo src={previewImage || logoSource} />
              <S.RemoveImageIcon className="trash-icon">
                <Feather
                  name="trash-2"
                  size={36}
                  color={defaultTheme["red-500"]}
                />
              </S.RemoveImageIcon>
            </S.LogoContainer>

            <S.SelectFileContainer
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={(event) =>
                handleDrop({
                  event,
                  setIsDragging,
                  setSelectedFile,
                  setPreviewImage
                })
              }
              isDragging={isDragging}
            >
              <input
                ref={fileInputRef}
                accept={allowedFileTypes}
                type="file"
                id="fileInput"
                onChange={(event) =>
                  handleSelect({
                    event,
                    setSelectedFile,
                    setPreviewImage,
                    inputRef: fileInputRef
                  })
                }
                style={{ display: "none" }}
              />
              <S.ButtonLabel htmlFor="fileInput">
                {t("Choose file")}
              </S.ButtonLabel>
              <S.TextLabel
                htmlFor="fileInput"
                style={{
                  textDecoration: "none",
                  fontWeight: 400,
                  marginTop: 5
                }}
              >
                {t("or drag and drop")}
              </S.TextLabel>

              <S.SupportedFileTypes>
                SVG, PNG, JPEG or JPG (max. 800x400px)
              </S.SupportedFileTypes>
            </S.SelectFileContainer>
          </S.ModalBody>

          <S.ButtonsContainer>
            <S.SaveButton
              onClick={() =>
                handleSave({
                  t,
                  branchId,
                  selectedFile,
                  setLogoSource,
                  setModalVisible,
                  setSelectedFile,
                  setPreviewImage
                })
              }
            >
              {t("Save")}
            </S.SaveButton>
            <S.CancelButton onClick={handleCloseModal}>
              {t("Cancel")}
            </S.CancelButton>
          </S.ButtonsContainer>
        </S.ModalContainer>
      </ModalWrapper>
    </>
  )
}
