import styled from "styled-components"
import { defaultTheme } from "../../../../styles/themes/default"

export const Container = styled.div`
  position: fixed;
  right: 0;
  width: 25%;
  height: calc(100% - 70px); 
  display: flex;
  flex-direction: column;
  background-color: ${defaultTheme["white"]};
  border-radius: 36px 0 0 36px;
  box-shadow: -3px 0px 10px #00000029;
  border-top: 1px solid #E5E5E5;
  z-index: 400;
`

export const DivisorLine = styled.div`
  width: 75%;
  height: 1px;
  display: flex;
  align-self: center;
  margin-top: 20px;
  margin-bottom: 6px;
  border-radius: 10px;
  background-color: ${defaultTheme["gray-400"]};
`

/* export const SearchInput = styled(Input)`
  width: 200px;
  height: 35px;
  padding-right: 40px;
  font-size: ${globalStyles.sizes.fontSize};
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
`

export const SearchButton = styled.TouchableOpacity`
  width: 30px;
  height: 80%;
  border-radius: 4px;

  position: absolute;
  right: 2px;
  
  justify-content: center;
  align-items: center;

  background-color: ${globalStyles.colors.primary};
`

export const SeachIcon = styled(Ionicons)`
  color: ${globalStyles.colors.tertiary};
  font-size: 18px;
`

export const DivisorLine = styled.View`
  width: 90%;
  height: 1px;
  align-self: center;
  margin-top: 6px;
  margin-bottom: 6px;
  border-radius: 10px;
  background-color: ${globalStyles.colors.tertiary};
`

export const ActionButtonsView = styled.View`
  flex-direction: row;
  align-self: center;
  gap: 5px;
`

export const ButtonText = styled.Text`
  font-size: ${globalStyles.sizes.fontSize}px;
  color: ${globalStyles.colors.tertiary};
  font-weight: 600;
`

export const CancelButton = styled.TouchableOpacity`
  height: 30px;
  width: 100px;
  align-self: center;
  justify-content: center;
  align-items: center;
  padding: 0px 6px;
  margin: 8px 0;
  border-radius: 3px;  
  background-color: ${globalStyles.colors.secondary};
`

export const MultiSelectionInput = styled(MultiSelect)`
  width: 200px;
  height: ${globalStyles.sizes.inputHeight}px;
  border-radius: 3px;
  padding: 4px;
  margin-left: 5px;

  font-size: ${globalStyles.sizes.fontSize}px;

  background-color: ${globalStyles.colors.inputsBackground};
` */
