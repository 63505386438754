import React, { useState } from "react"
import DashBoardController from "../../../controllers/DashBoardController"
import Dashboard from "../../../libs/Dashboard"
import Container from "./styles.web"

export default function DashBoardScreen(route) {

  const [controller] = useState(new DashBoardController())

  return (
    <Container>
      <Dashboard
        idDashboard={route.route.params.attribute}
        controller={controller}
        entityName={"DASHBOARD"}
      />
    </Container>
  )
}
