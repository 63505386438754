import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex: 1;
  margin-left: 63px;
  overflow: hidden;
  background-color: ${({ theme }) => theme["gray-200"]};
`

export default Container
