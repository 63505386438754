import React, { useState } from "react"
import { useDispatch } from "react-redux"
import useAppSelector from "../../../../../redux/hooks/useAppSelector"
import { setSelectedDashboard } from "../../../../../redux/redux-reducers/dashboardBuilder-reducer"
import deleteDashboard from "../../../../../redux/redux-reducers/dashboardBuilder-reducer/utils/deleteDashboard"
import { fetchDashboard } from "../../../../../redux/redux-reducers/dashboardBuilder-reducer/utils/fetchDashboard"
import Panel from "../../../../components/Panel"
import Table from "../../../../components/Table"
import { Container } from "./styles"

export default function Grid({ controller }) {

  const dispatch = useDispatch()

  const [recordsAmount, setRecordsAmount] = useState(0)
  const [searchText, setSearchText] = useState("")

  const { updateTable, dashboardData } = useAppSelector(({ dashboardBuilderReducer }) => dashboardBuilderReducer)

  const props = {
    controller,
    entityName: "T_RDB_DSB",
    dispatch,
    recordsAmount,
    setRecordsAmount,
    searchText,
    setSearchText
  }

  const functions = {
    insertRecord: () => dispatch(setSelectedDashboard("new")),
    deleteRecord: () => { dispatch(deleteDashboard(dashboardData.selectedDashboard)) }
  }

  return (
    <Container>
      <Panel {...props} viewType="table" functions={functions} />
      <Table {...props} updateTable={updateTable} screenBuilder={true} selectedCallback={(selectedDashboard) => {
        dispatch(fetchDashboard(selectedDashboard))
      }} />
    </Container >
  )
}
