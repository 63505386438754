import dbRequest from "../../../../services/dbRequest/dbRequest"
import allRequiredFieldsFilled from "../../../../services/registration/allRequiredFieldsFilled"
import runTriggers from "../../../../services/registration/runTriggers"
import showAlert from "../../../../services/showAlert"
import storageRequest from "../../../../services/storageRequest"
import existingIDValidation from "./save/existingIDValidation"
import idCreationValidation from "./save/idCreationValidation"
import manualIdValidation from "./save/manualIdValidation"
import populateDetailTabsPath from "./save/populateDetailTabsPath"
import populateModels from "./save/populateModels"
import userScreenValidation from "./save/userScreenValidation"

const handleSave = (props) => {
  const isIdValid = manualIdValidation({ ...props })
  if (!isIdValid) return

  showAlert({
    type: "confirm",
    text: "Do you want to save the record?",
    title: "Record saving",
    handleConfirm: () => saveRecord({ ...props })
  })
}

export default handleSave

export async function saveRecord(props) {
  const { models, entity, setUpdateForm, changeLoadingModalVisibility } = props

  changeLoadingModalVisibility()

  const areAllRequiredFieldsFilled = await allRequiredFieldsFilled(props)
  if (!areAllRequiredFieldsFilled) return changeLoadingModalVisibility()

  const doesIdExists = await existingIDValidation(props)
  if (doesIdExists) return changeLoadingModalVisibility()

  const isUserValid = await userScreenValidation(props) //Migrate to user screen
  if (!isUserValid) return changeLoadingModalVisibility()

  const tableData = models._REGISTRATION_DATA[entity]

  tableData.state = "BEFORE"

  const areAllTriggersValid = await runTriggers(models, entity)
  if (!areAllTriggersValid) return changeLoadingModalVisibility()

  await idCreationValidation(props)
  await populateDetailTabsPath(props)

  const hasSelectedImage = Boolean(models[entity].IMG?.file)
  const hasSavedImage = Boolean(
    models._REGISTRATION_DATA[entity].originalRecord.IMG?.[0]?.URL
  )
  const isImageDeleted = models[entity].IMG?.file === null

  if (hasSelectedImage || (hasSavedImage && isImageDeleted)) {
    if (hasSelectedImage) await saveImage(props)
    else models[entity].IMG = []
  }

  const { files } = models._REGISTRATION_DATA[entity]
  const hasFiles = Object.keys(files).length > 0

  if (hasFiles) await saveFiles(props)

  if (hasFiles || hasSelectedImage || (hasSavedImage && isImageDeleted)) {
    const updatedRecord = await dbRequest
      .saveRecords(entity)
      .record({ ...models[entity] })
      .execute()

    models[entity].populate(updatedRecord)
  }

  populateModels(props)

  await runTriggers(models, entity)
  changeLoadingModalVisibility()
  showAlert({
    text: "Record saved successfully",
    title: "Record saving",
    titleType: "success"
  })

  setUpdateForm((s) => !s)
}

const saveImage = async ({ models, entity, changeLoadingModalVisibility }) => {
  const { ID, IMG } = models[entity]
  const { file } = IMG
  const record = new Blob([file], { type: file.type })
  console.log(changeLoadingModalVisibility)

  const value = await storageRequest
    .saveFiles(`${entity}/${ID}/IMG/${file.name || file.NAME}`)
    .file(record)
    .execute((res: any) => {
      const result = {
        NAME: file.name || file.NAME,
        SIZE: file.size || file.SIZE,
        TYPE: file.type || file.TYPE,
        URL: res.url
      }

      return result
    })

  models[entity].IMG = [value]
}

const saveFiles = async ({ models, entity }) => {
  const { ID } = models[entity]
  const attachmentFields = models._REGISTRATION_DATA[entity].files

  for (const field in attachmentFields) {
    const fieldFiles = models._REGISTRATION_DATA[entity].files[field]

    const formattedFilesPromises = fieldFiles.map((file) => {
      if (file.URL) return file

      const record = file.devicePath
        ? file
        : new Blob([file], { type: file.type })

      return storageRequest
        .saveFiles(`${entity}/${ID}/${field}/${file.name || file.NAME}`)
        .file(record)
        .execute((res: any) => {
          const result = {
            NAME: file.name || file.NAME,
            SIZE: file.size || file.SIZE,
            TYPE: file.type || file.TYPE,
            URL: res.url
          }

          return result
        })
    })

    const filesArray = await Promise.all(formattedFilesPromises)

    models._REGISTRATION_DATA[entity].files[field] = filesArray
    models[entity][field] = filesArray
  }
}
