import styled from "styled-components"
import { defaultTheme } from "../../../../../../../../../styles/themes/default"

export const Container = styled.div`
  align-items: center;
  border: 2px dashed ${defaultTheme["gray-300"]};
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  height: 100px;
  justify-content: center;
  margin-top: 20px;
  position: relative;
  width: 100%;
`

export const Text = styled.p`
  color: ${defaultTheme["cyan-800"]};
  font-size: 16px;
`

export const HelpDiv = styled.div`
  align-items: center;
  display: flex;
  margin-top: 6px;
`

export const FormatsText = styled.p`
  color: ${defaultTheme["gray-500"]};
  font-size: 10px;
`

export const Input = styled.input`
  display: none;
`

export const Label = styled.label`
  color: ${defaultTheme["cyan-500"]};
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
`
