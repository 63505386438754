const handleDrop = ({ event, setIsDragging, setFileData }) => {
  event.preventDefault()
  setIsDragging(false)
  const files = [...event.dataTransfer.files]

  if (files.length > 0) {
    const file = files[0]
    const reader = new FileReader()
    reader.onloadend = () => {
      setFileData(state => ({
        ...state,
        file,
        preview: reader.result
      }))
    }
    reader.readAsDataURL(file)
  }
}

export default handleDrop
