import recordIdExists from "../../../../../services/registration/recordIdExists"
import showAlert from "../../../../../services/showAlert"

export default async ({ models, entity, setUpdateForm }) => {
  const tableData = models._REGISTRATION_DATA[entity]

  if (!tableData.manualID || tableData.operation === "UPD") return false

  const existingID = await recordIdExists(entity, models[entity].ID)

  if (existingID) {
    models[entity].ID = null
    setUpdateForm(s => !s)
    showAlert({
      text: "Record ID already exists",
      title: "Failed to save",
      titleType: "error"
    })
    return true
  }

  return false
}
