import { deleteObject, ref} from "firebase/storage"
import { localStorage } from "../../dbRequest/dbConnection/index.web"
import showAlert from "../../showAlert"

export default async function deleteFiles({ path }) {
  try {
    const storage = localStorage
    const storageRef = ref(storage, path)
  
    await deleteObject(storageRef).then(() => {
      console.log("File deleted successfully")
    })
    
  } catch (error) {
    console.error(error)
    showAlert({
      title: "File delete failed.",
      type: "ok",
      titleType: "error",
      text: error
    })
  }

}
