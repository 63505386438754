import React, { useContext, useState } from "react"
import getDoubleClickEvent from "../../../../../../utils/getDoubleClickEvent"
import ModalWrapper from "../../../../../components/Modal"
import Table from "../../../../../components/Table"
import { ControllerContext } from "../../../contexts/ControllerProvider"
import { ModalsContext } from "../../../contexts/ModalsProvider"
import renderButtons from "../utils/renderButtons"
import FormModal from "./components/FormModal"
import * as S from "./styles"
import handleDelete from "./utils/handleDelete"

export default function ManageFunctionsModal() {
  const { controller, models } = useContext(ControllerContext)
  const { modalsVisible, closeModal } = useContext(ModalsContext)

  const [modalVisible, setModalVisible] = useState(false)
  const [updateTable, setUpdateTable] = useState(false)

  const resetModel = () => {
    models.T_DTD_FNC.reset()
    models._REGISTRATION_DATA.T_DTD_FNC.operation = "INS"
  }

  const buttons = [
    {
      iconName: "plus",
      divTitle: "Add new record",
      onPress: () => {
        resetModel()
        setModalVisible(true)
      }
    },
    {
      iconName: "trash",
      divTitle: "Delete selected record",
      onPress: () => handleDelete(controller, setUpdateTable, resetModel)
    },
    {
      iconName: "close",
      divTitle: "Close",
      onPress: () => {
        resetModel()
        closeModal("function")
      }
    }
  ]

  const handleSelect = obj => {
    models.T_DTD_FNC.reset()
    models.T_DTD_FNC.populate({ ...obj })

    getDoubleClickEvent((doubleClickEvent) => {
      if (doubleClickEvent.isActive) {
        doubleClickEvent.isActive = false
        models._REGISTRATION_DATA.T_DTD_FNC.operation = "UPD"
        setModalVisible(true)
      }
    })
  }

  return (
    <>
      <ModalWrapper isVisible={modalsVisible.function}>
        <S.Container>
          {renderButtons(buttons)}

          <Table
            entityName="T_DTD_FNC"
            controller={controller}
            entityPath={`T_DTD_TAB/${models.T_DTD_TAB.ID}/T_DTD_FNC`}
            screenBuilder
            updateTable={updateTable}
            selectedCallback={handleSelect}
          />
        </S.Container>
      </ModalWrapper>

      {modalVisible && <FormModal modalVisible={modalVisible} setModalVisible={setModalVisible} setUpdateTable={setUpdateTable} />}
    </>
  )
}
