import dbRequest from "../../../../../../../../../../services/dbRequest/dbRequest"

export default () => {
  return dbRequest
    .loadRecords("T_RDB_REP")
    .execute(reportsData => {
      const reports = {
        ID: "reports",
        IDACCESS: "reports",
        DESCRIPTION: "Reports",
        icon: "arrow-right",
        T_DTD_FNC: {}
      }

      reportsData.forEach(report => {
        const { T_DTD_TNS } = report

        Object.entries(T_DTD_TNS)
          .forEach(([transactionId, transaction]) => {
            reports.T_DTD_FNC[transactionId] = {
              ID: transactionId,
              DESCRIPTION: transaction.DESCRIPTION,
              ACTIVE: true
            }
          })
      })

      return reports
    })
}
