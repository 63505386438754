import { Entypo } from "@expo/vector-icons"
import styled from "rn-css"
import globalStyles from "../../../../../../../../../../../../../global/globalStyles"

export const Row = styled.View`
  flex-direction: row;
  gap: 3px;
`

export const CheckBox = styled.TouchableOpacity`
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
  border: 2px solid gray;
  border-radius: 2px ;
`

export const CheckIcon = styled(Entypo)`
  color: ${globalStyles.colors.tertiary};
  font-size: 14px;
`

export const CheckText = styled.Text`
  color: ${globalStyles.colors.primary};
`
