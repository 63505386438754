import showAlert from "./showAlert"

export default function getItemById(array, id) {
  try {
    let record = array.find(item => item.ID === id)
    return record
  } catch (error) {
    showAlert({
      title: "Error",
      titleType: "error",
      text: "Error getting item by ID"
    })

    return null
  }
}
