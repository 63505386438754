import React from "react"
import { useTranslation } from "react-i18next"
import * as S from "./styles.web"

export default function AccessibilityBox({ text }) {
  const { t } = useTranslation()

  return text && (
    <S.Container title={t(text)}>
      <S.Icon />
    </S.Container>
  )
}
