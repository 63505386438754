const processFiltersList = (filters) => {
  let { javaScriptFilters = [], firebaseFilters = [] } = filters

  javaScriptFilters = javaScriptFilters.map(filter => {
    return {
      ...filter,
      TYPE: {
        VALUE: "JAVASCRIPT",
        LABEL: "JavaScript"
      }
    }
  })

  firebaseFilters = firebaseFilters.map(filter => {
    return {
      ...filter,
      TYPE: {
        VALUE: "FIREBASE",
        LABEL: "Firebase"
      }
    }
  })

  return [...javaScriptFilters, ...firebaseFilters]
}

export default processFiltersList
