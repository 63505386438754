import showAlert from "../../../../../../services/showAlert"

const handleFileSelect = ({ e, setTextCode }) => {
  const selectedFile = e.target.files[0]
  const allowedExtensions = [".js"]
  const fileExtension = selectedFile.name
    .slice(selectedFile.name.lastIndexOf("."))
    .toLowerCase()

  if (allowedExtensions.includes(fileExtension)) {
    const reader = new FileReader()
    reader.onload = (event) => {
      const fileContent = event.target.result
      if (typeof fileContent === "string") {
        setTextCode(fileContent)
      } else {
        const decoder = new TextDecoder()
        const decodedContent = decoder.decode(fileContent)
        setTextCode(decodedContent)
      }
    }
    reader.readAsArrayBuffer(selectedFile)
    e.target.value = null
  } else {
    showAlert({
      title: "Please select a .js file",
      titleType: "warning"
    })
  }
}

export default handleFileSelect
