import { MaterialCommunityIcons, Fontisto } from "@expo/vector-icons"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { defaultTheme } from "../../../../../../../../../../../styles/themes/default"
import { LoadingContainer } from "../../../../../../../../../../screens/PanelScreen/styles.web"
import LoadingAnimation from "../../../../../../../../../LoadingAnimation/index.web"
import ModalWrapper from "../../../../../../../../../Modal"
import * as S from "./styles"
import handleSave from "./utils/handleSave"

export default function Header({
  selectedOption,
  visibleFields,
  nonVisibleFields,
  closeModal,
  models,
  entity
}) {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(false)

  return (
    <S.Container>
      <S.Title>{t(selectedOption.DESCRIPTION)}</S.Title>
      <S.ButtonsView>
        <S.SaveButtonContainer
          onClick={() => {
            handleSave({
              selectedOption,
              visibleFields,
              nonVisibleFields,
              closeModal,
              transactionID: models.T_SET_TNS.ID,
              setIsLoading,
              entity
            })
          }}
        >
          <S.SaveButton>
            <Fontisto name="save" size={12} color={defaultTheme["white"]} />
          </S.SaveButton>
          <span>{t("Save Changes")}</span>
        </S.SaveButtonContainer>

        <S.CloseButton onClick={closeModal}>
          <MaterialCommunityIcons name="close" color={defaultTheme["gray-700"]} size={22} />
        </S.CloseButton>
      </S.ButtonsView>

      {isLoading &&
        (<ModalWrapper isVisible={isLoading}>
          <LoadingContainer>
            <LoadingAnimation size="medium" color={defaultTheme["white"]} />
          </LoadingContainer>
        </ModalWrapper>)
      }
    </S.Container>
  )
}
