import styled from "styled-components"
import { defaultTheme } from "../../../../../styles/themes/default"

export const Container = styled.div`
  position: relative;
  height: 60%;
  padding: 10px 20px 0px 20px;
`

export const Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: ${defaultTheme["cyan-700"]};
`

export const Content = styled.div`
  display: flex;
  height: 90%;
  flex-direction: column;
  margin-top: 10px;
  overflow: scroll;
`

export const FieldView = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: start;
  flex-direction: column;
  width: 100%;
  margin-bottom: 12px;
`

export const Key = styled.span`
  font-size: 14px;
  font-weight: lighter;
  color: ${defaultTheme["cyan-700"]};
  margin-left: 3px;
  margin-bottom: 3px;
  width: 90%;
`

export const Value = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
  font-weight: lighter;
  color: ${defaultTheme["gray-700"]};
  width: 94%;
  padding: 6px 0 6px 10px;
  border: .3px solid ${defaultTheme["gray-500"]};
  border-radius: 12px;
  background-color: ${defaultTheme["gray-200"]};
`

export const EditButton = styled.button`
  position: absolute;
  right: 24px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  &:active {
    opacity: .8;
  }
`
