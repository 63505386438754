import styled from "styled-components"
import { defaultTheme } from "../../../styles/themes/default"

export const CloseButton = styled.button`

  position: absolute;
  right: 10px;
  top: 10px;

  display: flex;
  align-items: center;
  padding:  5px;
  
  background-color: ${defaultTheme["white"]};

  border: none;
  border-radius: 5px;
  box-shadow: 2px 0 5px #00000029;

  z-index: 2;
  cursor: pointer;
`
