import React, { useState, useEffect } from 'react'
import * as S from './styles'

export default function DateInput({ entity, models, updateForm, setUpdateForm }) {
  const [value, setValue] = useState(models[entity].ID || "")

  const tableData = models._REGISTRATION_DATA[entity]

  useEffect(() => {
    setValue(getStateValue(models[entity].ID || ""))
  }, [updateForm])

  const isAnInsertion = tableData.operation === "INS"

  return (
    <S.Input
      type='date'
      editable={isAnInsertion}
      disabled={!isAnInsertion}
      value={value}
      onChange={(e) => setValue(getStateValue(e.target.value))}
      onBlur={() => models[entity].ID = getModelValue(value)}
    />
  )
}

function getStateValue(value: string): string {
  return value ? value.substring(0, 10) : "" // YYYY-MM-DD
}

function getModelValue(value: string): string | null {
  return value ? `${value.substring(0, 10)}T00:00:00` : null
}
