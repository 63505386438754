import Entypo from "@expo/vector-icons/Entypo"
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons"
import MaterialIcons from "@expo/vector-icons/MaterialIcons";
import React from "react"
import { useTranslation } from "react-i18next"
import evaluateFunction from "../../../../../../../services/evaluateFunction"
import { defaultTheme } from "../../../../../../../styles/themes/default"
import CloseButton from "../../../../../CloseButton"
import handleFileSelect from "../../utils/handleFileSelect"
import * as S from "./../../styles"

const ModalButtons = ({ textCode, setTextCode, fileInputRef, setIsExportModalOpen, closeModal }) => {
    const { t } = useTranslation()

    return (
        <S.ButtonContainer>
            <S.Button onClick={() => evaluateFunction(textCode)}>
                <Entypo name="controller-play" size={20} color={defaultTheme["white"]} />
                {t("Run")}
            </S.Button>
            <S.ClearButton onClick={() => setTextCode("")}>
                <MaterialCommunityIcons name="broom" size={20} color={defaultTheme["white"]} />
                {t("Clear")}
            </S.ClearButton>
            <S.FileInputButton onClick={() => fileInputRef.current.click()}>
                <MaterialIcons name="file-upload" size={18} color={defaultTheme["white"]} />
                <span style={{ marginLeft: "5px" }}>{t("Import your file")}</span>
            </S.FileInputButton>
            <S.FileInputButton onClick={() => setIsExportModalOpen(true)}>
                <MaterialIcons name="file-download" size={18} color={defaultTheme["white"]} />
                <span style={{ marginLeft: "5px" }}>{t("Export your file")}</span>
            </S.FileInputButton>
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={(e) => handleFileSelect({
                    e,
                    setTextCode
                })}
            />
            <CloseButton callbackFunction={closeModal} />
        </S.ButtonContainer>
    )
}

export default ModalButtons
