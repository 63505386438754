import dbRequest from "../../../../../../../../../../../../services/dbRequest/dbRequest"
import showAlert from "../../../../../../../../../../../../services/showAlert"

const handleSave = async ({ selectedOption, visibleFields, nonVisibleFields, closeModal, transactionID, setIsLoading, entity }) => {
  const { DESCRIPTION } = selectedOption

  showAlert({
    text: "Do you want to save changes?",
    type: "confirm",
    title: DESCRIPTION,
    handleConfirm: async () => {
      setIsLoading(true)
      const fields = [...visibleFields, ...nonVisibleFields]

      fields.forEach((field, index) => {
        field.order = index + 1
      })

      const fieldsPromises = []
      const attributesPromises = []

      const attributesToSave = {
        EDITABLE: "editable",
        REQUIRED: "required",
        VISIBLE: "visible",
        CARDVISIBLE: "visible",
        ORDERBY: "order",
        CARDORDERBY: "order"
      }

      for (const field of fields) {
        if (field.id !== "TNSID" && (["N", "K"].includes(field.type))) {
          const FIELDTYPE = field.type === "N"
            ? {
              LABEL: "Navigation",
              VALUE: "N"
            } : {
              LABEL: "Foreign Key",
              VALUE: "K"
            }

          const fieldPromise = dbRequest
            .saveRecords(`T_DTD_TAB/${entity}/T_SET_HDR/${transactionID}/T_DTD_FLD`)
            .record({
              ID: field.id,
              FIELDTYPE
            })
            .execute()

          fieldsPromises.push(fieldPromise)
        }

        for (const attribute in attributesToSave) {
          const attributePromise = dbRequest
            .saveRecords(`T_DTD_TAB/${entity}/T_SET_HDR/${transactionID}/T_DTD_FLD/${field.id}/T_DTD_ATR`)
            .record({
              ID: attribute,
              VALUE: field[attributesToSave[attribute]]
            })
            .execute()

          attributesPromises.push(attributePromise)
        }
      }

      await Promise.all(fieldsPromises)
      await Promise.all(attributesPromises)

      showAlert({
        text: "Configurations saved successfully",
        title: DESCRIPTION,
        titleType: "success"
      })

      setIsLoading(false)
      closeModal()
    }
  })
}

export default handleSave
