import styled from "rn-css"
import globalStyles from "../../../../../../../../global/globalStyles"

export const Container = styled.View`
  background-color: ${globalStyles.colors.background};

  max-height: 50%;
  width: 50%;
  flex: 1;

  align-self: center;

  padding: 10px;

  border-radius: 4px;
`

export const Row = styled.View`
  flex-direction: row;
  align-items: center;

  gap: 3px;
`

export const Button = styled.TouchableOpacity`
  background-color: ${globalStyles.colors.tertiary};

  padding: 2px 7px;

  border: 1px solid ${globalStyles.colors.primary};
  border-radius: 4px;
`

export const ButtonText = styled.Text`
  color: ${globalStyles.colors.primary};

  font-size: 14px;
`
