import React from "react"
import { useTranslation } from "react-i18next"
import showAlert from "../../../../../../../../../../../services/showAlert"
import CloseButton from "../../../../../../../../../CloseButton/index.web"
import * as S from "./styles"
import handleSave from "./utils/handleSave"

export default function Header({ entity, models, closeModal, permissions, gridRef }) {
  const { t } = useTranslation()

  const handleConfirm = () => {
    showAlert({
      type: "confirm",
      text: "Do you want to save?",
      title: "Access configuration",
      handleConfirm: () => handleSave({
        entity,
        closeModal,
        permissions,
        gridRef,
        models
      })
    })
  }

  return (
    <S.Container>
      {t("Access Configuration")}

      <S.ButtonsView>
        <S.SaveButton onClick={handleConfirm}>{t("Save")}</S.SaveButton>

        <CloseButton callbackFunction={closeModal} />
      </S.ButtonsView>
    </S.Container>
  )
}
