import RuntimeConfiguration from "../../models/runtimeConfiguration"
import dbRequest from "../dbRequest/dbRequest"

const isScreenBuilderAdminMode = RuntimeConfiguration.getIsDeveloperConfigurationMode()
const isDashboardBuilderAdminMode = RuntimeConfiguration.getDashboardBuilderAdminMode()

interface Props {
	collectionPath?: string
	entityName: string
	isScreenBuilderRequest?: boolean
	fetchOptions?: boolean
}

export interface Field {
	ID: string
	FIELDTYPE: ListField
	T_DTD_ATR: FieldAttributes
	T_DTD_OPT?: object
	TABLENAME: string
}

interface FieldAttributes {
	AGGREGATE: Array<ListField>
	CARDORDERBY: number
	CARDVISIBLE: boolean
	CREATED_AT: string
	CREATED_BY: ForeignKeyField
	EDITABLE: boolean
	FIELDDESCRIPTION: string
	GRIDVISIBLE: boolean
	GRIDORDERBY: number
	ORDERBY: number
	REQUIRED: boolean
	UPDATED_BY: ForeignKeyField
	UPDATED_AT: string
	VISIBLE: boolean
	ONFOCUS?: boolean
	CUSTOMLIST?: string
	DECIMAL?: number
	DEFAULTVALUE?: unknown
	ENTITY?: ForeignKeyField
	EXPRESSION?: string
	FILTER?: string
	FUNCTION?: ListField
	GROUPING?: string
	HELPTEXT?: string
	LABEL?: string
	MASK?: ListField
	SIZE?: ListField
	TAB?: Tab
}

interface ListField {
	LABEL: string
	VALUE: string
}
interface ForeignKeyField {
	ID: string
	DESCRIPTION: string
}

interface Attribute {
	ID: keyof FieldAttributes
	VALUE: unknown
}

interface Tab {
	DESCRIPTION?: string
	ORDERBY?: number
}

export default async function getTableFields({
	collectionPath,
	entityName,
	isScreenBuilderRequest = false,
	fetchOptions = true
}: Props) {
	const isAdminRequest = (isScreenBuilderAdminMode && isScreenBuilderRequest) || (isDashboardBuilderAdminMode && isScreenBuilderRequest)

	const path = collectionPath || entityName

	let fieldsRequest = dbRequest.loadRecords(`T_DTD_TAB/${path}/T_DTD_FLD`)

	if (isAdminRequest) fieldsRequest = fieldsRequest.admin()

	const fields: Field[] = await fieldsRequest.execute() as any

	for (const field of fields) {
		let attributesRequest = dbRequest.loadRecords(`T_DTD_TAB/${path}/T_DTD_FLD/${field.ID}/T_DTD_ATR`)

		if (isAdminRequest) attributesRequest = attributesRequest.admin()

		const attributes: Attribute[] = await attributesRequest.execute() as any

		//@ts-ignore
		field.T_DTD_ATR = {}
		field.TABLENAME = entityName

		for (const attribute of attributes) {
			const { ID, VALUE } = attribute
			//@ts-ignore
			field.T_DTD_ATR[ID] = VALUE
		}

		//field.T_DTD_ATR = getFieldAttributes(field)
		if (["L", "O"].includes(field.FIELDTYPE.VALUE) && !field.T_DTD_ATR.CUSTOMLIST && fetchOptions) {
			field.T_DTD_OPT = {}

			let optionsRequest = dbRequest.loadRecords(`T_DTD_TAB/${path}/T_DTD_FLD/${field.ID}/T_DTD_OPT`)

			if (isAdminRequest) optionsRequest = optionsRequest.admin()

			const options = await optionsRequest.execute() as any

			for (const option of options) {
				const { ID, VALUE } = option
				field.T_DTD_OPT[ID] = VALUE
			}
		}
	}

	return fields
}
