import { createAsyncThunk } from "@reduxjs/toolkit"
import RuntimeConfiguration from "../../../../models/runtimeConfiguration"
import dbRequest from "../../../../services/dbRequest/dbRequest"
import showAlert from "../../../../services/showAlert"

const saveFieldListOption = createAsyncThunk(
  "dashboardBuilder/saveFieldListOption",
  async ({ option, path }, thunkAPI) => {
    const isAdminMode = RuntimeConfiguration.getDashboardBuilderAdminMode()

    if (!option?.LABEL || !option.VALUE) return thunkAPI.rejectWithValue(showAlert({
      title: "Unable to save option",
      titleType: "warning",
      text: "The fields must be filled in."
    }))

    let saveRequest = dbRequest.saveRecords(path).record({
      ID: option.VALUE,
      VALUE: {
        LABEL: option.LABEL,
        DEFAULTVALUE: option.DEFAULTVALUE
      }
    })

    if (isAdminMode) saveRequest = saveRequest.admin()

    await saveRequest.execute()

    showAlert({
      title: "Success",
      text: "Option saved successfully.",
      titleType: "success"
    })

    let updateOptionsRequest = dbRequest.loadRecords(path)

    if (isAdminMode) updateOptionsRequest = updateOptionsRequest.admin()

    const updateOptions = await updateOptionsRequest.execute(response => {
      return response.map(item => {
        return {
          VALUE: item.ID,
          LABEL: item.VALUE.LABEL,
          DEFAULTVALUE: item.VALUE.DEFAULTVALUE
        }
      })
    })

    return { updateOptions }
  }
)

export default saveFieldListOption
