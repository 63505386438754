import { Entypo } from "@expo/vector-icons"
import styled from "styled-components"
import { defaultTheme } from "../../../../../../../../../../../../../../../styles/themes/default"

export const Row = styled.div`
  align-items: center;
  display: flex;
  gap: 3px;

  span {
    color: ${defaultTheme["cyan-800"]};
    font-size: 14px;
  }
`

interface CheckboxProps {
  isMarked: boolean
}

export const CheckBox = styled.button<CheckboxProps>`
  align-items: center;
  background-color: ${({ isMarked }) => isMarked ? defaultTheme["cyan-300"] : "transparent"};
  border: ${({ isMarked }) => isMarked ? "0" : "2px"} solid gray;
  border-radius: 2px ;
  display: flex;
  height: 18px;
  justify-content: center;
  margin-left: 30px;
  width: 18px;

`

export const CheckIcon = styled(Entypo)
  .attrs({
    color: defaultTheme["white"],
    name: "check",
    size: 14
  })``
