import dbRequest from "../../../../../../../services/dbRequest/dbRequest"
import User from "../../../../../../../services/dbRequest/user"
import showAlert from "../../../../../../../services/showAlert"

export default async ({ models, entityName }) => {
  if (entityName !== "T_SET_RLU") return true

  const { ACTIVE, ADMIN, ID, DESCRIPTION, EMAIL } = models[entityName]

  const hasAdminUsers = await hasRemainingAdminUsers(models, entityName)

  if ((!ACTIVE || !ADMIN) && !hasAdminUsers)
    return showAlert({
      text: "Minimum number of active administrators reached",
      title: "Failed to save",
      titleType: "error"
    })

  let isValid = true

  if (models._REGISTRATION_DATA[entityName].operation === "INS") {
    const uid = await User.create({
      name: DESCRIPTION,
      email: EMAIL,
      isActive: ACTIVE
    })
    uid ? models[entityName].ID = uid : isValid = false
  }

  else if (ACTIVE !== models._REGISTRATION_DATA[entityName].originalRecord.ACTIVE)
    ACTIVE ? isValid = await User.enableUser(ID) : await User.disableUser(ID)

  return isValid || showAlert({
    text: "Max users reached. Verify your contract info",
    title: "Failed to save",
    titleType: "error"
  })
}

async function hasRemainingAdminUsers(models, entityName) {
  const users = await dbRequest
    .loadRecords(entityName)
    .where("ACTIVE", "==", true)
    .where("ADMIN", "==", true)
    .execute()

  if (users.length === 1 && users[0].ID === models[entityName].ID) return false

  return users.length > 0
}
