export default props => {
  const { models, entityName, changeMode } = props

  const selectedItem = models[entityName]

  const fieldsToDelete = ["ID", "CREATED_BY", "CREATED_AT", "UPDATED_BY", "UPDATED_AT"]

  for (const field of fieldsToDelete) {
    delete selectedItem[field]
    delete models[entityName][field]
  }

  const { fields } = models._REGISTRATION_DATA[entityName]

  for (const field of fields) {
    const { ID, FIELDTYPE } = field
    if (FIELDTYPE.VALUE === "A") {
      delete models[entityName][ID]
      delete selectedItem[ID]
      delete models._REGISTRATION_DATA[entityName].files[ID]
    }
  }

  models._REGISTRATION_DATA[entityName].operation = "INS"
  models._REGISTRATION_DATA[entityName].originalRecord = { ...selectedItem }

  changeMode()
}
