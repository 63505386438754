import React, { useContext, useState } from "react"
import getDoubleClickEvent from "../../../../../../../../../../utils/getDoubleClickEvent"
import ModalWrapper from "../../../../../../../../../components/Modal"
import Table from "../../../../../../../../../components/Table"
import { ControllerContext } from "../../../../../../../contexts/ControllerProvider"
import renderButtons from "../../../../../utils/renderButtons"
import AttributesModal from "../AttributesModal"
import FieldModal from "../FieldModal"
import * as S from "./styles"
import getParameters from "./utils/getParameters"
import handleDelete from "./utils/handleDelete"

export default function ParametersModal({ paramsModalVisible, setParamsModalVisible }) {

  const { controller, models } = useContext(ControllerContext)

  const [fieldModalVisible, setFieldModalVisible] = useState(false)
  const [attributesModalVisible, setAttributesModalVisible] = useState(false)
  const [updateTable, setUpdateTable] = useState(false)

  const buttons = [
    {
      iconName: "plus",
      divTitle: "Add parameter",
      onPress: () => {
        models.T_DTD_FLD.reset()
        models._REGISTRATION_DATA.T_DTD_FLD.operation = "INS"
        models.T_DTD_ATR.reset()
        setFieldModalVisible(true)
      }
    },
    {
      iconName: "trash",
      divTitle: "Delete parameter",
      onPress: async () => await handleDelete(controller, setUpdateTable)
    },
    {
      iconName: "close",
      divTitle: "Close",
      onPress: () => { setParamsModalVisible(false) }
    }
  ]

  const handleSelect = obj => {
    models.T_DTD_FLD.reset()
    models.T_DTD_ATR.reset()
    models.T_DTD_FLD.populate({ ...obj })
    models.T_DTD_ATR.populate({
      ...obj.T_DTD_ATR,
      TAB: obj.T_DTD_ATR.TAB.DESCRIPTION ?? ""
    })

    getDoubleClickEvent((doubleClickEvent) => {
      if (doubleClickEvent.isActive) {
        doubleClickEvent.isActive = false
        models._REGISTRATION_DATA.T_DTD_FLD.operation = "UPD"
        setAttributesModalVisible(true)
      }
    })
  }

  return (
    <>
      <ModalWrapper isVisible={paramsModalVisible}>
        <S.Container>
          {renderButtons(buttons)}

          <Table
            entityName={"T_DTD_FLD"}
            controller={controller}
            staticRecords={getParameters(models)}
            selectedCallback={handleSelect}
            updateTable={updateTable}
            screenBuilder
          />
        </S.Container>
      </ModalWrapper>

      {fieldModalVisible &&
        <FieldModal
          fieldModalVisible={fieldModalVisible}
          setFieldModalVisible={setFieldModalVisible}
          setAttributesModalVisible={setAttributesModalVisible}
        />
      }

      {attributesModalVisible &&
        <AttributesModal
          attributesModalVisible={attributesModalVisible}
          setAttributesModalVisible={setAttributesModalVisible}
          setFieldModalVisible={setFieldModalVisible}
          setUpdateTable={setUpdateTable}
        />
      }
    </>
  )
}
