import LoginData from "../models/loginData"

export default (models) => {
  const status = models.T_MOV_HDR.STATUS
  const transaction = models.T_SET_TNS
  const { permissions } = models._REGISTRATION_DATA.T_MOV_HDR

  const isAdmin = LoginData.getLoginData().ADMIN
  const hasPermissionToCreate = permissions.CREATE
  const hasPermissionToUpdate = permissions.UPDATE

  if (!isAdmin && !hasPermissionToCreate && !hasPermissionToUpdate) return false

  const isOrderOpened = status?.VALUE !== "C"

  if (isOrderOpened) return true

  const canModifyFinishedOrder = transaction?.CHANGEFINDOC

  if (!canModifyFinishedOrder) return false

  return isAdmin || hasPermissionToUpdate
}
