import styled from "styled-components"
import { defaultTheme } from "../../../../../../../../../../../styles/themes/default"

export const Container = styled.div`
  height: 100%;

  flex: 1;
  background-color: ${defaultTheme["white"]};
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 50px;
  padding: 0 10px 0 16px;
  background-color: ${defaultTheme["gray-200"]}70;
`

export const Title = styled.h2`
  font-size: 14px;
  font-weight: 600;
  color: ${defaultTheme["gray-700"]};
`

export const SearchContainer = styled.div`
  position: relative;
  display: flex;
  flex: .7;
  flex-direction: row;
  align-items: center;
  margin-left: 5px;
`

export const SearchInput = styled.input`
  width: 100%;
  height: 30px;
  padding: 0 7px;
  background-color: ${defaultTheme["white"]};
  color: ${defaultTheme["gray-700"]};
  border-radius: 14px;
  border: none;
  outline: 1px solid ${defaultTheme["gray-400"]};
  transition: outline 0.2s ease-in-out;
  ::placeholder {
    color: ${defaultTheme["gray-400"]};
  }
  :focus {
    outline: 2.2px solid ${defaultTheme["cyan-300"]};
  }
`

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 360px; 
  overflow-y: auto; 
`

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 8px 28px 50px;
  flex-shrink: 0; 
`
