import React, { useEffect, useState } from 'react'
import * as S from './styles'
import PhotoManagerModal from './components/Modal'

export default function ImageInput({ entity, models, updateForm }) {
  const tableData = models._REGISTRATION_DATA[entity]

  const [modalVisible, setModalVisible] = useState(false)
  const [fileData, setFileData] = useState({
    file: null,
    preview: models[entity].IMG?.[0]?.URL,
    source: models[entity].IMG?.[0]?.URL
  })

  useEffect(() => {
    setFileData({
      file: null,
      preview: models[entity].IMG?.[0]?.URL,
      source: models[entity].IMG?.[0]?.URL
    })
  }, [updateForm])

  return (
    <S.Container
      onClick={() => setModalVisible(true)}
      disabled={tableData.pkType === "K"}
      style={{ pointerEvents: tableData.pkType === "K" ? "none" : "auto" }}
    >
      {fileData.source
        ? <S.Image src={fileData.source} />
        : <S.ImageIcon />
      }

      <PhotoManagerModal
        modalVisible={modalVisible}
        closeModal={() => setModalVisible(false)}
        models={models}
        entity={entity}
        fileData={fileData}
        setFileData={setFileData}
      />
    </S.Container>
  )
}