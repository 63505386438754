import React from "react"
import { Platform, ScrollView, StyleSheet, View } from "react-native"
import globalStyles from "../../../global/globalStyles"
import { ControllerProps, DashboardResponse } from "../types/types"
import CheckChartsTypes from "./components/CheckChartsTypes"

interface DashboardGeneratorProps {
  idfilial: number
  entityName: string
  positionStyle: "absolute" | "fixed"
  sideBarVisible: boolean
  controller: ControllerProps
  dashboard: DashboardResponse[]
  dashboardData: []
}

export default function DashboardGenerator({ dashboard, controller, entityName, positionStyle, idfilial, dashboardData }: DashboardGeneratorProps) {

  return dashboard.map(chart => {

    if (!chart.gadget) return

    return (
      <View key={chart.chartId}
        style={
          [Platform.OS === "web" && styled(chart, positionStyle).chartView, {
            ...globalStyles.shadow,
            margin: 10
          }]} >
        <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={{ flex: Platform.OS === "web" ? 1 : 0 }}>
          <CheckChartsTypes
            controller={controller}
            idfilial={idfilial}
            entityName={entityName}
            chartId={chart.chartId}
            chartIdArr={dashboard.map(id => id.chartId)}
            chartData={dashboard[Number(chart.chartId) - 1].gadget}
            dashboardData={dashboardData}
          />
        </ScrollView>
      </View >
    )
  }
  )
}

const styled = (props: DashboardResponse, positionStyle) => StyleSheet.create({
  chartView: {
    position: positionStyle,
    backgroundColor: globalStyles.colors.tertiary,
    top: `${props.initialPosition[0] * (100 / 12) - 1}%`,
    left: `${props.initialPosition[1] * (100 / 12)}%`,
    width: `${props.sizeX * (100 / 12) - .6}%`,
    height: `${props.sizeY * (100 / 12) - 1}%`,
    borderRadius: 5
  }
})
