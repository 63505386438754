export default `
  .e-control-wrapper.e-mask.e-input-group.e-valid-input           {
    border: none !important;
    margin: 0;
  }

  .sc-aNeao.eYeria.e-control.e-maskedtextbox.e-lib.e-input {
    margin: 0;
  }

  .e-control-wrapper.e-mask.e-input-group.e-valid-input::before,
  .e-control-wrapper.e-mask.e-input-group.e-valid-input::after {
    display: none;
  }
`