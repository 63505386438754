import { MaterialIcons } from "@expo/vector-icons"
import React, { useEffect, useState } from "react"
import { Text } from "react-native"
import { useDispatch } from "react-redux"
import useAppSelector from "../../../../../../redux/hooks/useAppSelector"
import { closeModal } from "../../../../../../redux/redux-reducers/dashboardBuilder-reducer"
import showAlert from "../../../../../../services/showAlert"
import getDoubleClickEvent from "../../../../../../utils/getDoubleClickEvent"
import ModalWrapper from "../../../../../components/Modal"
import * as S from "../modalStyles"
import deleteVariable from "./utils/deleteVariable"
import getVariablesList from "./utils/getVariablesList"
import VariableForm from "./VariableForm"

export default function VariablesModal() {

  const dispatch = useDispatch()

  const [formIsVisible, setFormIsVisible] = useState(false)
  const [variablesList, setVariablesList] = useState([])
  const [selectedVariable, setSelectedVariable] = useState(null)
  const [update, setUpdate] = useState(false)

  const { name, isVisible } = useAppSelector(
    ({ dashboardBuilderReducer }) => dashboardBuilderReducer.modals.variablesModal
  )

  const { selectedDashboard, selectedQuery } = useAppSelector(
    ({ dashboardBuilderReducer }) => dashboardBuilderReducer.dashboardData
  )

  useEffect(() => {
    if (selectedQuery) {
      const variablesPath = `T_RDB_DSB/${selectedDashboard.ID}/DATA/${selectedQuery.ID}/VARIABLES`
      getVariablesList({ variablesPath }).then((response) => setVariablesList(response))
    }
  }, [selectedQuery, formIsVisible, update])

  const handleSelectVariable = (variable) => {
    setSelectedVariable(variable)
    getDoubleClickEvent(doubleClickEvent => {
      if (doubleClickEvent.isActive) setFormIsVisible(true)
    })
  }

  const handleDeleteVariable = () => {
    if (!selectedVariable) return showAlert({
      title: "Unable to delete",
      text: "Please select a variable",
      titleType: "warning"
    })
    showAlert({
      title: "Are you sure?",
      text: "This operation cannot be undone",
      titleType: "warning",
      type: "confirm",
      handleConfirm: async () => {
        await deleteVariable({
          variable: selectedVariable,
          variablesPath: `T_RDB_DSB/${selectedDashboard.ID}/DATA/${selectedQuery.ID}/VARIABLES`
        })
        setSelectedVariable(null)
        setUpdate(s => !s)
      }
    })
  }

  return (
    <ModalWrapper isVisible={isVisible}>
      <S.Container style={{
        width: "40%",
        height: "48%"
      }}>

        <S.CloseButton onPress={() => dispatch(closeModal({ modalName: name }))}>
          <MaterialIcons name="close" size={20} color="#333333" />
        </S.CloseButton>

        <S.Title>Variables Configuration</S.Title>

        <S.Header style={{
          flexDirection: "row-reverse",
          marginVertical: 10,
          marginRight: 20
        }}>
          <S.ButtonsView>

            <S.AddButton onPress={() => setFormIsVisible(true)}>
              <S.TitleButton>New Variable</S.TitleButton>
            </S.AddButton>

            <S.DeleteButton onPress={handleDeleteVariable}>
              <S.DeleteIcon name="trash-alt" />
            </S.DeleteButton>
          </S.ButtonsView>
        </S.Header>

        <S.ListArea contentContainerStyle={{ gap: 5 }}>
          {
            variablesList.length > 0 ? variablesList.map((item) => (
              <S.Card
                key={item.ID}
                isSelected={item.ID === selectedVariable?.ID}
                onPress={() => handleSelectVariable(item)}
              >
                <S.CardFieldArea style={{ width: "30%" }}>
                  <S.CartText style={{ fontWeight: "600" }}>
                    Variable ID: <S.CartText>{item?.ID}</S.CartText>
                  </S.CartText>
                </S.CardFieldArea>
                <S.CardFieldArea style={{ width: "65%" }}>
                  <S.CartText style={{ fontWeight: "600" }} numberOfLines={1}>
                    Description: <S.CartText>{item?.DESCRIPTION}</S.CartText>
                  </S.CartText>
                </S.CardFieldArea>
              </S.Card>
            ))
              :
              <Text style={{
                textAlign: "center",
                marginTop: 30,
                color: "#5e5e5e"
              }}>No variables to be displayed.</Text>
          }
        </S.ListArea>

      </S.Container>

      {
        (selectedVariable || formIsVisible) &&
        <ModalWrapper isVisible={formIsVisible}>
          <VariableForm
            isVisible={formIsVisible}
            setFormIsVisible={setFormIsVisible}
            selectedVariable={selectedVariable}
            setSelectedVariable={setSelectedVariable}
            variablesPath={`T_RDB_DSB/${selectedDashboard.ID}/DATA/${selectedQuery.ID}/VARIABLES`}
          />
        </ModalWrapper>
      }
    </ModalWrapper>

  )
}
