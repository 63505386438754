import React from "react"
import { FontAwesome6 } from "@expo/vector-icons"
import { useTranslation } from "react-i18next"
import downloadFile from "../ButtonsContainer/utils/downloadFile"
import * as S from "./styles.web"
import getIconName from "./utils/getIconName"
import { defaultTheme } from "../../../../../../../../../styles/themes/default"

export default function FileCard({ file, handleDeleteFile, index }) {
  const { t } = useTranslation()

  const { name, NAME, size, SIZE, URL } = file

  const iconName = getIconName(name || NAME)
  const isDefault = index === 0

  return (
    <S.FilesListCard>
      <S.IconContainer>
        <FontAwesome6 name={iconName} size={24} color={defaultTheme["gray-900"]} />
      </S.IconContainer>

      <S.FileInfoView>
        <S.FileName>{name || NAME}</S.FileName>
        <S.FileInfoText>{size || SIZE}Kb</S.FileInfoText>
      </S.FileInfoView>

      <S.ButtonsView>
        <S.DefaultItemMark isDefault={isDefault}>
          {t("Default")}
        </S.DefaultItemMark>

        <S.Button onClick={() => downloadFile(file)} style={{ visibility: URL ? "visible" : "hidden" }}>
          <div title={t("Download")}>
            <FontAwesome6 name="download" size={18} color={defaultTheme["cyan-500"]} />
          </div>
        </S.Button>

        <S.Button onClick={handleDeleteFile}>
          <div title={("Delete")}>
            <FontAwesome6 name="trash-alt" size={18} color={defaultTheme["red-500"]} />
          </div>
        </S.Button>
      </S.ButtonsView>
    </S.FilesListCard>
  )
}
