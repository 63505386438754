import React, { useContext, useState } from "react"
import { GridModeContext } from "../../../ScreenFactory/contexts/GrideModeProvider"
import HierarchicalRow from "./HierarchicalRow"

export default function HierarchicalTree(props) {
  const { records, viewType } = props

  const { changeMode } = viewType === "registration" ? useContext(GridModeContext) : () => null

  const [, setUpdate] = useState(false)

  const rowProps = {
    ...props,
    changeMode,
    setUpdate
  }

  const shouldRenderRow = ({ ID, MASTERID }) => Number(ID) === 0 ? viewType === "readOnly" : MASTERID?.ID === "0"

  return records.map((item, index) => shouldRenderRow(item) && <HierarchicalRow key={index} {...rowProps} rowData={item} padding={0} />)
}
