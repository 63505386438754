import styled from "styled-components"
import { defaultTheme } from "../../../../styles/themes/default"

export const Container = styled.div`
  display: flex;

  width: 100%;

  padding: 8px 20px;
  justify-content: space-between;
  align-items: center;

  & > *:nth-child(1) {
    gap: 10px;

  }

  & > *:nth-child(3) {
    gap: 10px;

  }


  & > *:nth-child(5) {
    gap: 10px;
  }
`

export const ItemsContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`

export const ClearFilteringButton = styled.button`
  text-align: center;
  background-color: ${defaultTheme["white"]};
  border-radius: 9px;
  height: 24.8px;
  padding: 0 9px;
  color: ${defaultTheme["gray-600"]};
  font-size: 0.7875rem;
`

