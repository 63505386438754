const goBackToPreviousMenu = ({
  menuOptions,
  setCurrentMenu,
  setNavigationPath,
  navigationPath,
  t
}) => {
  if (navigationPath.length > 0) {
    const updatedPath = [...navigationPath]
    updatedPath.pop()
    setNavigationPath(updatedPath)

    let newMenu = menuOptions
    for (const label of updatedPath) {
      const nextMenu = newMenu.find(menu => t(menu.DESCRIPTION) === label)?.SUB_MENUS
      if (nextMenu) {
        newMenu = nextMenu
      }
    }
    setCurrentMenu(newMenu)
  } else {
    setCurrentMenu(menuOptions)
  }
}

export default goBackToPreviousMenu
