import React from "react"
import { ImgTemplate } from "../../../../Grid/components/ImgTemplate"

const applySettings = ({ settings, fields, gridRef }) => {

  // Etapa 1: Limpar as configurações atuais
  gridRef.current.clearSorting()
  gridRef.current.clearFiltering()
  //gridRef.current.clearGrouping()
  gridRef.current.setProperties({
    columns: [],
    sortSettings: { columns: [] },
    filterSettings: { columns: [] },
    //groupSettings: { columns: [] },
    pageSettings: { currentPage: 1 }
  })

  // Etapa 2: Aplicar as colunas
  settings.columns.forEach(col => {
    const matchingField = fields.find(field => field.ID === col.field)

    if (matchingField) {
      col.allowEditing = true
      const { FIELDTYPE, T_DTD_ATR } = matchingField

      col.headerText = T_DTD_ATR.FIELDDESCRIPTION

      switch (FIELDTYPE.VALUE) {
        case "B":
          col.type = "boolean"
          col.displayAsCheckBox = true
          break

        case "I":
          col.type = "number"
          break

        case "C":
          col.formatter = (fieldData, data) => {
            const { field } = fieldData
            const value = data[field]

            if (!value && value !== 0) return 0

            return new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
              maximumFractionDigits: T_DTD_ATR.DECIMAL ?? 2,
              minimumFractionDigits: T_DTD_ATR.DECIMAL ?? 2
            }).format(value)
          }
          break

        case "F":
          col.type = "number"
          col.formatter = (fieldData, data) => {
            const { field } = fieldData
            let value = data[field]

            if (!value && value !== 0) value = 0

            return new Intl.NumberFormat("pt-BR", {
              style: "decimal",
              minimumFractionDigits: T_DTD_ATR.DECIMAL ?? 2,
              maximumFractionDigits: T_DTD_ATR.DECIMAL ?? 2
            }).format(value)
          }
          break

        case "A":
          col.template = (props) => <ImgTemplate {...props} />
          break

        case "D":
          col.type = "date"
          col.format = "dd/MM/yyyy"
          break

        case "T":
          col.type = "dateTime"
          col.format = "dd/MM/yyyy HH:mm:ss"
          break

        default:
          col.type = "string"
          break
      }
    }
  })

  gridRef.current.setProperties({
    columns: settings.columns
    //groupSettings: { columns: settings.groupSettings.columns }
  })

  //gridRef.current.refreshColumns()

  // Etapa 3: Aplicar filtros e ordenações após as colunas estarem configuradas
  if (settings.filterSettings && settings.filterSettings.columns) {
    settings.filterSettings.columns.forEach(filter => {
      gridRef.current.filterByColumn(filter.field, filter.operator, filter.value)
    })
  }

  if (settings.sortSettings && settings.sortSettings.columns) {
    gridRef.current.setProperties({
      sortSettings: { columns: settings.sortSettings.columns }
    })
  }

  if (settings.groupSettings && settings.groupSettings.columns) {
    settings.groupSettings.columns.forEach(groupColumn => {
      const column = settings.columns.find(col => col.field === groupColumn)

      if (column && column.field) {
        // Verifica se a coluna já está agrupada
        const isAlreadyGrouped = gridRef.current.groupSettings.columns.includes(groupColumn)

        if (!isAlreadyGrouped) {
          gridRef.current.groupColumn(groupColumn)
        } else {
          console.log(`A coluna '${groupColumn}' já está agrupada.`)
        }
      } else {
        console.error(`Campo inválido ou nulo no agrupamento: ${groupColumn}`)
      }
    })
  }

  // Atualizar o grid após aplicar todas as configurações
  gridRef.current.refreshColumns()
}

export default applySettings
