import React from "react"
import * as S from "./styles"

export default function Text({ fieldValue = "", fieldData, isSelected }) {
  const ATR = fieldData.T_DTD_ATR

  const maskType = fieldValue.length >= 14 ? "cnpj" : "cpf"

  function getFieldToRender() {
    const commonProps = {
      value: fieldValue,
      isSelected
    }

    switch (ATR.MASK) {
      case "cnpj_cpf": return <S.TextFieldMask {...commonProps} type={maskType} />
      case "zip-code": return <S.TextFieldMask {...commonProps} type="zip-code" />
      case "phone": return <S.TextFieldMask {...commonProps} type="cel-phone" />
      default: return (
        <S.Text isSelected={isSelected}>
          {ATR.MASK === "password" ? fieldValue.replace(/./g, "*") : fieldValue}
        </S.Text>
      )
    }
  }

  return (
    <div title={fieldValue} style={containerStyle}>
      {getFieldToRender()}
    </div>
  )
}

const containerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  width: "100%",
}
