import React, { useContext } from "react"
import showAlert from "../../../../../../../../../../services/showAlert"
import Form from "../../../../../../../../../components/Form/index.web"
import ModalWrapper from "../../../../../../../../../components/Modal"
import { ControllerContext } from "../../../../../../../contexts/ControllerProvider"
import renderButtons from "../../../../../utils/renderButtons"
import * as S from "./styles"

export default function FieldModal({ fieldModalVisible, setFieldModalVisible, setAttributesModalVisible }) {
  const { controller, models } = useContext(ControllerContext)

  const buttons = [
    {
      iconName: "check",
      divTitle: "Confirm",
      onPress: () => {
        models.T_DTD_FLD.ID
          ? setAttributesModalVisible(true)
          : showAlert({
            text: "Id",
            title: "Required field not filled",
            titleType: "error"
          })
      }
    },
    {
      iconName: "close",
      divTitle: "Close",
      onPress: () => {
        models.T_DTD_FLD.reset()
        models.T_DTD_ATR.reset()
        setFieldModalVisible(false)
      }
    }
  ]

  return (
    <ModalWrapper isVisible={fieldModalVisible}>
      <S.Container>
        {renderButtons(buttons)}

        <Form entity={"T_DTD_FLD"} models={models} screenBuilder={true} />
      </S.Container>
    </ModalWrapper>
  )
}
