export default function getQueryAndConnectionsEntities({ selectedQuery }) {
  const entities = []

  if (selectedQuery.ENTITY.ID !== "Custom") entities.push(selectedQuery.ENTITY)

  function traverse(obj) {
    if (obj && typeof obj === "object") {
      if (obj.ENTITY.ID === "Custom") {
        return entities.push({
          ...obj.ENTITY,
          queryID: obj.ID
        })
      }
      if (obj.ENTITY && obj.ENTITY.ID && obj?.CONNECTIONTYPE?.VALUE === "detail") {
        entities.push({
          ...obj.ENTITY,
          isDetailScreen: true
        })
      }

      if (obj.connections) {
        obj.connections.forEach(connection => {
          traverse(connection)
        })
      }
    }
  }

  traverse(selectedQuery)

  entities.push({
    ID: "VARIABLES",
    LABEL: "Variables"
  })

  return entities
}
