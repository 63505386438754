import styled from "rn-css"

export const Container = styled.View`
  flex: 1;
`

export const ButtonsView = styled.View`
  width: 30%;
  top: 2px;
  left: 0;
  position: absolute;
  flex-direction: row;
`

export const TransactionView = styled.View`
  top: 2px;
  right: 50px;
  position: absolute;
`
