import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import evaluateFunction from "../../../../../../services/evaluateFunction"
import { defaultTheme } from "../../../../../../styles/themes/default"
import { CurrencyField, FieldContent, FieldTitle, FieldValue, InputContainer } from "./styles"

export default function RenderField({ field, item, selectedUnit, handleCalculation, defaultAmount, editAmountNeg, isOrderScreen, models, params }) {

  const { t } = useTranslation()

  if (field.FIELDTYPE.VALUE === "E" || field.FIELDTYPE.VALUE === "G" || field.FIELDTYPE.VALUE === "A" || !field.T_DTD_ATR.CARDVISIBLE) return

  const [value, setValue] = useState(0)

  async function execExpression() {
    const response = await evaluateFunction(field.T_DTD_ATR.EXPRESSION, models, params)
    field.FIELDTYPE.VALUE === "C" ?
      setValue(`R$${response?.toLocaleString("pt-BR", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      })}`)
      :
      setValue(response)
  }

  useEffect(() => {
    (async () => {

      switch (field.FIELDTYPE.VALUE) {
        case "B":
          if (field.T_DTD_ATR?.EXPRESSION) execExpression()
          else setValue(item[field.ID] ? "Yes" : "No")
          break
        case "L":
          if (field.T_DTD_ATR?.EXPRESSION) execExpression()
          else setValue(item[field.ID]?.LABEL)
          break
        case "K":
        case "N": {
          if (field.T_DTD_ATR?.EXPRESSION) execExpression()
          else setValue(item[field.ID].LABEL)
          break
        }
        case "C":
          if (field.T_DTD_ATR?.EXPRESSION) execExpression()
          else setValue(`R$ ${Number(item?.[field.ID])?.toLocaleString("pt-BR", {
            maximumFractionDigits: !isNaN(selectedUnit?.VALUEDEC) ? selectedUnit?.VALUEDEC ?? 2 : 2,
            minimumFractionDigits: !isNaN(selectedUnit?.VALUEDEC) ? selectedUnit?.VALUEDEC ?? 2 : 2
          })}`)

          break
        default:
          if (field.T_DTD_ATR?.EXPRESSION) execExpression()
          else setValue(item[field.ID])
          break
      }
    })()
  }, [params])

  function sizeConvert(size) {
    switch (size?.toUpperCase()) {
      case "M": return "33%"
      case "L": return "50%"
      default: return "18%"
    }
  }

  return (
    <FieldContent style={{ width: sizeConvert(field.T_DTD_ATR?.SIZE?.VALUE) }}>
      <FieldTitle>{t(field.T_DTD_ATR.FIELDDESCRIPTION)}</FieldTitle>
      {
        field.ID === "AMOUNTNEG" && !isOrderScreen ?
          <InputContainer style={{ backgroundColor: editAmountNeg ? defaultTheme["white"] : defaultTheme["gray-200"] }}>
            <CurrencyField
              value={isOrderScreen ? value : defaultAmount}
              type="money"
              prefix="R$"
              editable={editAmountNeg}
              precision={!isNaN(selectedUnit?.VALUEDEC) ? selectedUnit?.VALUEDEC ?? 2 : 2}
              onChangeValue={r => handleCalculation("change-unit-price", r)}
            />
          </InputContainer>
          : <FieldValue>{value}</FieldValue>
      }
    </FieldContent>
  )
}
