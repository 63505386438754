import { Field } from "../../../../services/registration/getTableFields"

const getAggregates = (fields: Field[]) => {
  const fieldsWithAggregates = fields.filter(({ T_DTD_ATR }) => T_DTD_ATR.AGGREGATE)

  const aggregates = {
    Sum: [],
    Average: [],
    Min: [],
    Max: [],
    Count: [],
    TrueCount: [],
    FalseCount: []
  }

  for (const field of fieldsWithAggregates) {
    const { ID, T_DTD_ATR } = field

    for (const item of T_DTD_ATR.AGGREGATE) {
      aggregates[item.VALUE].push(field)
    }
  }

  for (const key in aggregates) {
    if (aggregates[key].length === 0)
      delete aggregates[key]
  }

  return Object.keys(aggregates)
    .map(operation => ({
      operation,
      fields: aggregates[operation]
    }))
}

export default getAggregates
