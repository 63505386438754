import i18n from "i18next"
import getDashboardsRequest from "./getDashboardsRequest"
import getReportsRequest from "./getReportsRequest"
import getTablesRequest from "./getTablesRequest"

export default async () => {
  const tablesRequest = getTablesRequest() as any
  const dashboardsRequest = getDashboardsRequest() as any
  const reportsRequest = getReportsRequest() as any

  const [
    tablesTransactions,
    dashboardsTransactions,
    reportsTransactions
  ] = await Promise.all([
    tablesRequest,
    dashboardsRequest,
    reportsRequest
  ])

  const allTransactions = [...tablesTransactions, dashboardsTransactions, reportsTransactions]

  const alphabeticallyOrderedTransactions = allTransactions.sort((a, b) => i18n.t(a.DESCRIPTION).localeCompare(i18n.t(b.DESCRIPTION)))

  return alphabeticallyOrderedTransactions
}
