import React, { useContext } from "react"
import Form from "../../../../../components/Form/index.web"
import ModalWrapper from "../../../../../components/Modal"
import { ControllerContext } from "../../../contexts/ControllerProvider"
import { ModalsContext } from "../../../contexts/ModalsProvider"
import { UpdateContext } from "../../../contexts/UpdateProvider"
import renderButtons from "../utils/renderButtons"
import Container from "./styles"
import handleSave from "./utils/handleSave"

export default function CreateTableModal() {
  const { controller, models } = useContext(ControllerContext)
  const { modalsVisible, closeModal } = useContext(ModalsContext)
  const { setUpdateTablesTable } = useContext(UpdateContext)

  const buttons = [
    {
      iconName: "save",
      divTitle: "Save",
      onPress: () => { handleSave(models, controller, setUpdateTablesTable, closeModal) }
    },
    {
      iconName: "close",
      divTitle: "Close",
      onPress: () => {
        models.T_DTD_TAB.reset()
        closeModal("table")
      }
    }
  ]

  return (
    <ModalWrapper isVisible={modalsVisible.table}>
      <Container>
        {renderButtons(buttons)}

        <Form entity={"T_DTD_TAB"} models={models} screenBuilder={true} />
      </Container>
    </ModalWrapper>
  )
}
