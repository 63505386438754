import showAlert from "../../../../../../services/showAlert"

export default ({ models, entityName }) => {
  const filters = models._REGISTRATION_DATA[entityName].filterFields

  const requiredFilters = filters.filter(filter => filter.T_DTD_ATR.REQUIRED)

  if (requiredFilters.length === 0) return true

  for (const filter of requiredFilters) {
    if (!fieldHasValue(models, filter, entityName)) {
      showAlert({
        text: `${filter.T_DTD_ATR.FIELDDESCRIPTION}`,
        title: "Required filter not filled",
        titleType: "error"
      })
      return false
    }
  }

  return true
}

const fieldHasValue = (models, filter, entityName) => {
  const { ID, FIELDTYPE } = filter

  const isDate = ["D", "T"].includes(FIELDTYPE.VALUE)
  const tableFilters = models._REGISTRATION_DATA[entityName].filtersValues

  if (isDate) return tableFilters[`${ID}_INI`] && tableFilters[`${ID}_FIN`]

  const value = tableFilters[ID] ?? {}

  switch (typeof value) {
    case "boolean": return true
    case "object": return Object.keys(value).length > 0
    default: return value
  }
}
