import update from "immutability-helper"
import React, { useCallback, useContext, useEffect } from "react"
import { useDrop } from "react-dnd"
import { useTranslation } from "react-i18next"
import globalStyles from "../../../../../../../global/globalStyles"
import { FiltersContext } from "../../contexts/FiltersContext"
import ItemTypes from "../ItemTypes"
import Card from "./components/Card"
import * as S from "./styles"
import getFilters from "./utils/getFilters"

export default function FiltersList({ entityName, controller }) {
  const { t } = useTranslation()
  const { filters, setFilters } = useContext(FiltersContext)

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.CARD,
    drop: () => ({ name: "Dustbin" }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  }))

  const isActive = canDrop && isOver

  let backgroundColor = globalStyles.colors.background

  if (isActive)
    backgroundColor = "#bbf7d0"
  else if (canDrop)
    backgroundColor = "#ffedd5"

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setFilters((prevCards) => update(prevCards, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, prevCards[dragIndex]]
      ]
    }))
  }, [])

  const renderCard = useCallback((filter, index) => {
    return (
      <Card
        key={filter.id}
        filterData={filter}
        index={index}
        moveCard={moveCard}
      />
    )
  }, [])

  useEffect(() => {
    getFilters({
      entityName,
      models: controller.models()
    }).then(setFilters)
  }, [])

  const hasFilters = filters.length > 0

  return (
    <S.Container>
      <S.Header>
        <S.Title>{t("Filters")}</S.Title>

        {hasFilters && <S.Title>{t("Required")}?</S.Title>}
      </S.Header>

      <S.List
        ref={drop}
        data-testid="dustbin"
        style={{ backgroundColor }}
        contentContainerStyle={{ gap: 8 }}
      >
        {filters.map((filter, i) => renderCard(filter, i))}
      </S.List>
    </S.Container>
  )
}

