export default options => {
  const aux = []

  for (const key in options) {
    const currentField = options[key]

    const { LABEL, DEFAULTVALUE = false } = currentField

    aux.push({
      LABEL,
      VALUE: key,
      DEFAULTVALUE
    })
  }

  return aux
}
