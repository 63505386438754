import getTableFields from "../../../../../../../services/registration/getTableFields"

export default async (models) => {
  const fields = await getTableFields({
    entityName: models.T_DTD_TAB.ID,
    fetchOptions: false,
    isScreenBuilderRequest: true
  })

  const formattedFields = fields
    .filter(({ T_DTD_ATR }) => T_DTD_ATR.CARDVISIBLE)
    .map(({ ID, T_DTD_ATR }) => ({
      ID,
      DESCRIPTION: T_DTD_ATR.FIELDDESCRIPTION,
      ORDERBY: T_DTD_ATR.CARDORDERBY
    }))

  return formattedFields.sort((a, b) => a.CARDORDERBY - b.CARDORDERBY)
}
