import styled from "styled-components"
import { Feather } from "@expo/vector-icons"
import { defaultTheme } from "../../../../../../../../../styles/themes/default"

export const Container = styled.div`
  align-items: center;
  align-self: center;
  background-color: ${defaultTheme["white"]};
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  padding: 0.625rem 1rem;
  width: 30%;
`

export const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const Title = styled.p`
  color: ${defaultTheme["gray-700"]};
  font-size: 18px;
  font-weight: 600;
`

export const CloseButton = styled.button`
  background-color: transparent;
  border-radius: 4px;
  padding: 1px;

  &:hover {
    box-shadow: 2px 2px 5px #00000029;
    scale: 1.02;
  }
`

export const SendButton = styled.button`
  align-items: center;
  background-color: ${defaultTheme["cyan-300"]};
  border-radius: 15px;
  color: ${defaultTheme["white"]};
  display: flex;
  font-size: 0.875rem;
  font-weight: bold;
  gap: 0.5rem;
  height: 2.5rem;
  justify-content: center;
  width: 25%;

  &:hover {
    scale: 1.05;
    transition: 0.2s ease-in;
  }
`

export const InputContainer = styled.div`
  align-items: center;
  border: 1px solid ${defaultTheme["gray-300"]};
  border-radius: 15px;
  display: flex;
  gap: 1rem;
  height: 2.5rem;
  padding: 0 1rem;
  width: 100%;

  &:focus-within {
    border-color: ${defaultTheme["cyan-300"]};
  }
`

export const Input = styled.input`
  border: none;
  color: ${defaultTheme["gray-700"]};
  font-size: 0.875rem;
  outline: none;
  width: 100%;

  ::placeholder {
    color: ${defaultTheme["gray-300"]};
  }
`

interface IconProps {
  isFocused: boolean
}

export const InputIcon = styled(Feather)
  .attrs(({ isFocused }: IconProps) => ({
    color: defaultTheme[isFocused ? "cyan-300" : "gray-300"],
    size: 16
  }))``