import dbRequest from "../../../../services/dbRequest/dbRequest"

export default async function getFields(idReport) {
  try {
    const path = `T_RDB_REP/${idReport}/T_DTD_FLD`

    let fieldsRequest = dbRequest.loadRecords(path)
    const fields = await fieldsRequest.execute()

    for (const field of fields) {
      let attributesRequest = dbRequest.loadRecords(`${path}/${field.ID}/T_DTD_ATR`)

      const attributes = await attributesRequest.execute()

      field.T_DTD_ATR = {}
      field.TABLENAME = "REPORT"

      for (const attribute of attributes) {
        const { ID, VALUE } = attribute
        field.T_DTD_ATR[ID] = VALUE
      }

      if (field.FIELDTYPE.VALUE === "L" && !field.T_DTD_ATR.CUSTOMLIST) {
        field.T_DTD_OPT = {}

        let optionsRequest = dbRequest.loadRecords(`${path}/${field.ID}/T_DTD_OPT`)

        const options = await optionsRequest.execute()

        for (const option of options) {
          const { ID, VALUE } = option
          field.T_DTD_OPT[ID] = VALUE
        }
      }
    }

    return fields
  } catch (error) {
    console.error("Error getting report fields:", error)
    throw error
  }
}

