import React, { useContext } from "react"
import Form from "../../../../../../../../../components/Form/index.web"
import ModalWrapper from "../../../../../../../../../components/Modal"
import { ControllerContext } from "../../../../../../../contexts/ControllerProvider"
import { ModalsContext } from "../../../../../../../contexts/ModalsProvider"
import renderButtons from "../../../../../utils/renderButtons"
import * as S from "./styles"
import handleSave from "./utils/handleSave"

export default function AttributesModal({ attributesModalVisible, setAttributesModalVisible, setFieldModalVisible, setUpdateTable }) {
  const { controller, models } = useContext(ControllerContext)
  const { openModal } = useContext(ModalsContext)

  const isListField = models.T_DTD_FLD.FIELDTYPE.VALUE === "L"

  const buttons = [
    {
      iconName: "save",
      divTitle: "Save",
      onPress: async () => await handleSave(controller, setUpdateTable, closeModals)
    },
    {
      iconName: "close",
      divTitle: "Close",
      onPress: () => { setAttributesModalVisible(false) }
    }
  ]

  const closeModals = () => {
    setAttributesModalVisible(false)
    setFieldModalVisible(false)
  }

  if (isListField)
    buttons.splice(1, 0, {
      iconName: "list-ul",
      divTitle: "Manage list options",
      onPress: () => { openModal("options") }
    })

  return (
    <ModalWrapper isVisible={attributesModalVisible}>
      <S.Container>
        {renderButtons(buttons)}

        <Form entity={"T_DTD_ATR"} models={models} screenBuilder={true} />
      </S.Container>
    </ModalWrapper>
  )
}
