import dbRequest from "../../../../../services/dbRequest/dbRequest"
import allRequiredFieldsFilled from "../../../../../services/registration/allRequiredFieldsFilled"
import runTriggers from "../../../../../services/registration/runTriggers"
import showAlert from "../../../../../services/showAlert"
import storageRequest from "../../../../../services/storageRequest"
import existingIDValidation from "./utils/save/existingIDValidation"
import idCreationValidation from "./utils/save/idCreationValidation"
import manualIdValidation from "./utils/save/manualIdValidation"
import populateDetailTabsPath from "./utils/save/populateDetailTabsPath"
import populateModels from "./utils/save/populateModels"
import userScreenValidation from "./utils/save/userScreenValidation"

export default async function saveRecord(props) {
  const isIdValid = manualIdValidation(props)
  if (!isIdValid) return

  showAlert({
    type: "confirm",
    text: "Do you want to save the record?",
    title: "Record saving",
    handleConfirm: () => handleSave(props)
  })
}

export async function handleSave(props) {
  const { models, entityName, setUpdateForm, callback = () => null } = props

  callback()

  const areAllRequiredFieldsFilled = await allRequiredFieldsFilled({
    entity: entityName,
    models
  })
  if (!areAllRequiredFieldsFilled) return callback()

  const doesIdExists = await existingIDValidation(props)
  if (doesIdExists) return callback()

  const isUserValid = await userScreenValidation(props) //Migrate to user screen
  if (!isUserValid) return callback()

  const tableData = models._REGISTRATION_DATA[entityName]

  tableData.state = "BEFORE"

  const areAllTriggersValid = await runTriggers(models, entityName)
  if (!areAllTriggersValid) return callback()

  await idCreationValidation(props)
  await populateDetailTabsPath(props)

  const { files } = models._REGISTRATION_DATA[entityName]
  const hasFiles = Object.keys(files).length > 0

  if (hasFiles) {
    await saveFiles(props)

    const updatedRecord = await dbRequest
      .saveRecords(entityName)
      .record({ ...models[entityName] })
      .execute()

    models[entityName].populate({ ...updatedRecord })
  }

  populateModels(props)

  await runTriggers(models, entityName)

  callback()
  showAlert({
    text: "Record saved successfully",
    title: "Record saving",
    titleType: "success"
  })

  setUpdateForm(s => !s)
}

const saveFiles = async ({ models, entityName }) => {
  const { ID } = models[entityName]
  const attachmentFields = models._REGISTRATION_DATA[entityName].files

  for (const field in attachmentFields) {
    const fieldFiles = models._REGISTRATION_DATA[entityName].files[field]

    const formattedFilesPromises = fieldFiles.map(file => {
      if (file.URL) return file

      const record = file.devicePath ? file : new Blob([file], { type: file.type })

      return storageRequest
        .saveFiles(`${entityName}/${ID}/${field}/${file.name || file.NAME}`)
        .file(record)
        .execute(res => {
          const result = {
            NAME: file.name || file.NAME,
            SIZE: file.size || file.SIZE,
            TYPE: file.type || file.TYPE,
            URL: res.url
          }

          return result
        })
    })

    const filesArray = await Promise.all(formattedFilesPromises)

    models._REGISTRATION_DATA[entityName].files[field] = filesArray
    models[entityName][field] = filesArray
  }
}
