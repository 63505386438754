import showAlert from "../../../../../services/showAlert"
import handleDuplicate from "./utils/copy/handleDuplicate"

export default function copyRecord(props) {
  const { models, entityName } = props

  models[entityName].ID
    ? showAlert({
      type: "confirm",
      title: "Record duplication",
      text: "Do you want to duplicate this record?",
      handleConfirm: () => { handleDuplicate(props) }
    })
    : showAlert({
      text: "No record selected",
      title: "Failed to duplicate",
      titleType: "error"
    })
}
