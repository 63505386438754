import { createAsyncThunk } from "@reduxjs/toolkit"
import RuntimeConfiguration from "../../../../models/runtimeConfiguration"
import dbRequest from "../../../../services/dbRequest/dbRequest"
import showAlert from "../../../../services/showAlert"

export const saveConnection = createAsyncThunk(
  "dashboardBuilder/saveConnection",
  async (payload, thunkAPI) => {
    const isAdminMode = RuntimeConfiguration.getDashboardBuilderAdminMode()
    const { connection, masterPath, entityName, fieldPath, collectionPath } = payload

    try {
      if (!connection?.ID) return thunkAPI.rejectWithValue(showAlert({
        title: "Unable to save connection",
        titleType: "warning",
        text: "The ID is required."
      }))

      let connectionData = {}

      connectionData.FIELDPATH = fieldPath ? `${fieldPath + "." + connection.ID}` : connection.ID
      if (connection.CONNECTIONTYPE.VALUE === "detail") connectionData.COLLECTIONPATH = `${collectionPath}/${connection.ID}`

      connectionData = {
        ...connectionData,
        ...connection
      }

      const connectionPath = `${masterPath}/CONNECTIONS/`

      let saveRequest = dbRequest.saveRecords(connectionPath).record(connectionData)

      if (isAdminMode) saveRequest = saveRequest.admin()

      await saveRequest.execute()

      return {
        connection: {
          ...connectionData,
          connecitons: []
        },
        masterPath: `${connectionPath}${connection.ID}`
      }
    } catch (error) {
      showAlert({
        title: "Error when trying to save connection",
        titleType: "error",
        text: "Please try again."
      })
      console.error(error)
      return thunkAPI.rejectWithValue(error.message || "An error occurred while saving the connection.")
    }
  }
)

export default saveConnection
