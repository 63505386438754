export default ({ models, entityName }) => {
  const modelsKeys = Object.keys(models[entityName])

  const fieldsToIgnore = [
    "ORDERBY",
    "CREATED_AT",
    "CREATED_BY",
    "UPDATED_AT",
    "UPDATED_BY"
  ]

  const fieldsToCompare = modelsKeys.filter(key => {
    if (fieldsToIgnore.includes(key)) return false

    const currentField = models[entityName][key]
    const doesCurrentFieldHasValue = Boolean(currentField)

    return doesCurrentFieldHasValue
  })

  return {
    fieldsToCompare
  }
}
