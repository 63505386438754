import globalDate from "../../../../global/globalDate"

interface formatStringProps {
  value: string;
  formatType: "cnpj" | "cpf" | "phone" | "zip-code" | "currency" | "boolean" | "date" | "date-time" | undefined;
}

export default function formatString({value, formatType}: formatStringProps){
  
  switch (formatType) {
    case "cnpj": return formatCNPJ(value)

    case "cpf": return formatCPF(value)

    case "phone": return formatPhoneNumber(value)

    case "zip-code": return formatCEP(value)

    case "currency": return formatCurrency(value)

    case "boolean": return value ? "Sim" : "Não"

    case "date": return new globalDate(value).toLocal()

    case "date-time": return new globalDate(value).toTimestamp()

    default:
      return value 
  }
}

function formatCPF(cpf: string): string {
  cpf = cpf.replace(/\D/g, "")
  cpf = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
  return cpf
}

function formatCNPJ(cnpj: string): string {
  cnpj = cnpj.replace(/\D/g, "")

  cnpj = cnpj.replace(/^(\d{2})(\d)/, "$1.$2")
  cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
  cnpj = cnpj.replace(/\.(\d{3})(\d)/, ".$1/$2")
  cnpj = cnpj.replace(/(\d{4})(\d)/, "$1-$2")

  return cnpj
}

function formatPhoneNumber(phoneNumber: string): string {

  phoneNumber = phoneNumber.replace(/\D/g, "")

  // Formatação para números de telefone com 11 dígitos (DDD + 9 dígitos)
  if (phoneNumber.length === 11) {
    return phoneNumber.replace(/^(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3")
  }
  // Formatação para números de telefone com 10 dígitos (DDD + 8 dígitos)
  else if (phoneNumber.length === 10) {
    return phoneNumber.replace(/^(\d{2})(\d{4})(\d{4})$/, "($1) $2-$3")
  }
  else {
    return phoneNumber
  }
}

function formatCEP(cep: string): string {
  cep = cep.replace(/\D/g, "")
  return cep.replace(/^(\d{5})(\d{3})$/, "$1-$2")
}

function formatCurrency(value: number | string): string {
  if (typeof value === "number") {
    return value.toLocaleString("pt-BR", { style: "currency",
currency: "BRL" })
  } else return `R$ ${value}`
}

