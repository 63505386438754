const checkIfHasPromotion = async (controller, newAllFields, product, setEditAmountNeg) => {
  const promotions = await controller.promotions
  const obj = { ...newAllFields }

  if (promotions.length < 1) return

  for (const promotion of promotions) {
    let promotionalAmount = obj.ITEMAMOUNT
    if (promotion.ACTIVE && promotion?.VALUE > 0) {
      if (promotion.VALUETYPE.VALUE === "I") {
        promotionalAmount *= promotion.VALUE
      } else {
        promotionalAmount = promotion.VALUE
      }
      obj.IDPRM = promotion.ID
      obj.ITEMAMOUNT = promotionalAmount
      setEditAmountNeg(false)
    }
  }

  for (const promotion of promotions) {
    let promotionalAmount = obj.ITEMAMOUNT
    for (const itemPromotion of promotion.T_SET_IPR) {
      if (itemPromotion.ID.includes(product.ID)) {
        const promotionPerQuantity = itemPromotion.T_SET_RPR.sort((a, b) => Number(b.ID) - Number(a.ID))
        for (const range of promotionPerQuantity) {
          const quantityToApply = Number(range.ID)
          if (obj.TOTALQUANTITY >= quantityToApply) {
            if (range.VALUETYPE.VALUE === "I") {
              promotionalAmount *= range.VALUE
            } else {
              promotionalAmount = range.VALUE
            }
            obj.IDPRM = promotion.ID
            obj.ITEMAMOUNT = promotionalAmount
            setEditAmountNeg(false)
          }
        }
      }
    }
  }
  newAllFields = obj
}

export default checkIfHasPromotion
