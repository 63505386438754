import copyRecord from "./copyRecord"
import deleteRecord from "./deleteRecord"
import insertRecord from "./insertRecord"
import saveRecord from "./saveRecord"
import switchMode from "./switchMode"
import undoChanges from "./undoChanges"

export default function getFunctions(props) {
  return {
    switchMode: async () => await switchMode(props),
    insertRecord: () => insertRecord(props),
    deleteRecord: async (callback) => {
      props.callback = callback
      deleteRecord(props)
    },
    copyRecord: () => copyRecord(props),
    saveRecord: async (callback) => {
      props.callback = callback
      await saveRecord(props)
    },
    undoChanges: () => undoChanges(props)
  }
}
