import React, { useRef } from "react"
import { useTranslation } from "react-i18next"
import CloseButton from "../../../../../../CloseButton/index.web"
import Grid from "../../../../../../Grid"
import ModalWrapper from "../../../../../../Modal"
import * as S from "./styles.web"

export default function SearchModal({ field, models, modalVisible, setModalVisible, staticRecords = null, populateFields, setUpdateForm }) {
  const { t } = useTranslation()

  const gridRef = useRef(null)

  const currentEntity = field.TABLENAME
  const foreignEntity = field.T_DTD_ATR.ENTITY.ID
  const modalTitle = field.T_DTD_ATR.ENTITY.ENTITY || models._REGISTRATION_DATA[foreignEntity].description

  const closeModal = () => setModalVisible(false)

  return (
    <ModalWrapper isVisible={modalVisible}>
      <S.Container>
        <CloseButton callbackFunction={closeModal} />

        <S.Title>{t(modalTitle)}</S.Title>

        <Grid
          entity={foreignEntity}
          models={models}
          gridRef={gridRef}
          screenType="searchModal"
          rowDoubleClickCallback={record => {
            populateFields(record)
            setUpdateForm(s => !s)
            closeModal()
          }}
          staticFilters={field.T_DTD_ATR.FILTER}
        />
      </S.Container>
    </ModalWrapper>
  )
}

