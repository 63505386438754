import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { View } from "react-native"
import { useDispatch } from "react-redux"
import DashboardBuilderController from "../../../controllers/DashboardBuilderController"
import RuntimeConfiguration from "../../../models/runtimeConfiguration"
import useAppSelector from "../../../redux/hooks/useAppSelector"
import { changeFormValue, openFieldListModal, resetState } from "../../../redux/redux-reducers/dashboardBuilder-reducer"
import { saveDashboard } from "../../../redux/redux-reducers/dashboardBuilder-reducer/utils/saveDashboard"
import DashboardInterface from "./components/DashboardInterface"
import DashboardQueries from "./components/DashboardQueries"
import Grid from "./components/Grid"
import ConfigureOptionsListModal from "./components/Modals/ConfigureOptionsListModal"
import ConnectionModal from "./components/Modals/ConnectionModal"
import DashboardLauncher from "./components/Modals/DashboardLauncher"
import EditGroupingModal from "./components/Modals/EditGroupingModal"
import FieldListModal from "./components/Modals/FieldListModal"
import FieldModal from "./components/Modals/FieldModal"
import FilterModal from "./components/Modals/FilterModal"
import FiltersConfgurationModal from "./components/Modals/FiltersConfigurationModal"
import GadgetModal from "./components/Modals/GadgetModal"
import LevelModal from "./components/Modals/LevelModal"
import QueryModal from "./components/Modals/QueryModal"
import SerieModal from "./components/Modals/SerieModal"
import VariablesModal from "./components/Modals/VariablesModal"
import * as S from "./styles"

export default function DashboardsBuilderScreen(route) {
  const { t } = useTranslation()
  const isAdminMode = RuntimeConfiguration.getDashboardBuilderAdminMode()

  const dispatch = useDispatch()

  const controller = new DashboardBuilderController()

  const { selectedDashboard, fields } = useAppSelector(({ dashboardBuilderReducer }) => dashboardBuilderReducer.dashboardData)
  const { form, mode, isVisible, name } = useAppSelector(({ dashboardBuilderReducer }) => dashboardBuilderReducer.modals.dashboardModal)

  const [dashboardLauncherIsVisible, setDashboardLauncherIsVisible] = useState(false)

  useEffect(() => {
    dispatch(resetState())
  }, [isAdminMode])

  return (
    <S.Container>
      {isAdminMode &&
        <S.WarningView>
          <S.Text>{t("Attention! You are modifying the Data Dictionary")}</S.Text>
        </S.WarningView>
      }
      <S.Content>

        <Grid controller={controller} route={route} />

        <S.DividingLine />

        {selectedDashboard || isVisible ?
          <>
            <S.SelectedDashboardInfo>
              <S.Title>{mode === "new" ? "Create New" : "Edit"} Dashboard</S.Title>

              <S.SelectedView>
                <View style={{
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 3,
                  display: form?.ID ? "flex" : "none"
                }}>
                  <S.SelectedText>ID:</S.SelectedText>
                  <S.SelectedField>
                    <S.TextInput
                      value={form?.ID}
                      editable={false}
                    />
                  </S.SelectedField>
                </View>
                <View style={{
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 3
                }}>
                  <S.SelectedText>Description:</S.SelectedText>
                  <S.SelectedField>
                    <S.TextInput
                      value={form?.DESCRIPTION}
                      onChangeText={(tx) => dispatch(changeFormValue({
                        modalName: name,
                        fieldName: "DESCRIPTION",
                        value: tx
                      }))}
                    />
                  </S.SelectedField>
                </View>
              </S.SelectedView>

              <S.ButtonsView>
                <S.ConfigureDashboardFields onPress={() => dispatch(openFieldListModal({
                  title: "Dashboard Field List",
                  fields,
                  path: `T_RDB_DSB/${selectedDashboard.ID}`
                }))}>
                  <S.ButtonTitle>Configure Filters</S.ButtonTitle>
                </S.ConfigureDashboardFields>

                <S.DashboardLauncher onPress={() => setDashboardLauncherIsVisible(true)}>
                  <S.ButtonTitle>Launcher</S.ButtonTitle>
                </S.DashboardLauncher>

                <S.SaveButton onPress={() => dispatch(saveDashboard(form))}>
                  <S.ButtonTitle>Save Changes</S.ButtonTitle>
                </S.SaveButton>
              </S.ButtonsView>

            </S.SelectedDashboardInfo>

            <S.DividingLine />

            <S.DashboardArea>
              <DashboardQueries controller={controller} />
              <DashboardInterface controller={controller} />
            </S.DashboardArea>
          </>
          :
          <></>
        }
      </S.Content>

      {dashboardLauncherIsVisible && <DashboardLauncher
        dashboardLauncherIsVisible={dashboardLauncherIsVisible}
        selectedDashboard={selectedDashboard}
        models={controller.models()}
        closeModal={() => setDashboardLauncherIsVisible(false)}
      />}
      <FieldListModal controller={controller} />
      <FieldModal controller={controller} />
      <ConfigureOptionsListModal controller={controller} />
      <VariablesModal />
      <FiltersConfgurationModal />
      <FilterModal />
      <QueryModal />
      <ConnectionModal />
      <LevelModal />
      <GadgetModal />
      <EditGroupingModal />
      <SerieModal />
    </S.Container >
  )
}
