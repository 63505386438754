import { MaterialIcons } from "@expo/vector-icons"
import React, { useEffect, useState } from "react"
import { View } from "react-native"
import showAlert from "../../../../../../../services/showAlert"
import * as S from "../../modalStyles"
import saveVariable from "../utils/saveVariable"

export default function VariableForm({ setFormIsVisible, selectedVariable, setSelectedVariable, variablesPath }) {

  const [form, setForm] = useState({
    ID: "",
    DESCRIPTION: "",
    EXPRESSION: ""
  })

  useEffect(() => {
    selectedVariable && setForm({
      ID: selectedVariable.ID,
      DESCRIPTION: selectedVariable?.DESCRIPTION ?? "",
      EXPRESSION: selectedVariable.EXPRESSION
    })
  }, [selectedVariable])

  const changeForm = (key, value) => {
    setForm({
      ...form,
      [key]: value
    })
  }

  const handleSaveVariable = async () => {
    if (!form.ID || !form.EXPRESSION || !form.DESCRIPTION) {
      return showAlert({
        title: "Unable to save",
        text: "All fields are required",
        titleType: "warning"
      })
    }

    await saveVariable({
      variable: form,
      variablesPath
    })

    setForm({
      ID: "",
      DESCRIPTION: "",
      EXPRESSION: ""
    })
    setFormIsVisible(false)
    setSelectedVariable(null)
  }

  return (
    <S.Container style={{
      width: "45%",
      height: "48%"
    }}>
      <S.CloseButton onPress={() => {
        setSelectedVariable(null)
        setForm({
          ID: "",
          DESCRIPTION: "",
          EXPRESSION: ""
        })
        setFormIsVisible(s => !s)
      }}>
        <MaterialIcons name="close" size={20} color="#333333" />
      </S.CloseButton>

      <S.Title>{selectedVariable ? "Edit" : "Create New"} Variable</S.Title>

      <S.AddButton
        onPress={handleSaveVariable}
        style={{
          position: "absolute",
          top: 12,
          right: 50
        }}
      >
        <S.TitleButton>Save</S.TitleButton>
      </S.AddButton>

      <S.SelectedView style={{
        flex: 1,
        flexDirection: "column",
        width: "100%",
        gap: 10
      }}>
        <View style={{
          width: "100%",
          flexDirection: "row",
          gap: 20
        }}>

          <View style={{
            flexDirection: "row",
            alignItems: "center",
            gap: 3
          }}>
            <S.SelectedText>ID:</S.SelectedText>
            <S.SelectedField>
              <S.TextInput
                value={form.ID}
                editable={!selectedVariable}
                placeholder="Variable ID"
                placeholderTextColor={"gray"}
                onChangeText={(tx) => changeForm("ID", tx.trim())}
              />
            </S.SelectedField>
          </View>
          <View style={{
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
            gap: 3
          }}>
            <S.SelectedText>Description:</S.SelectedText>
            <S.SelectedField>
              <S.TextInput
                value={form.DESCRIPTION}
                editable={!selectedVariable}
                placeholder="Variable Description"
                placeholderTextColor={"gray"}
                onChangeText={(tx) => changeForm("DESCRIPTION", tx)}
              />
            </S.SelectedField>
          </View>
        </View>
        <View style={{
          flex: 1,
          width: "100%"
        }}>
          <S.SelectedText>Expression:</S.SelectedText>
          <S.SelectedField style={{
            backgroundColor: "transparent",
            height: "90%",
            paddingLeft: 0
          }}>
            <S.TextBoxInput
              value={form.EXPRESSION}
              placeholder="Enter the expression in javascript"
              placeholderTextColor={"gray"}
              onChangeText={(tx) => changeForm("EXPRESSION", tx)}
              multiline
              textAlignVertical="top"
              style={{
                backgroundColor: "#fff",
                borderRadius: 8
              }}
            />
          </S.SelectedField>
        </View>
      </S.SelectedView>

    </S.Container>
  )
}
