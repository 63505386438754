import styled from "styled-components"
import { defaultTheme } from "../../../styles/themes/default"

export const Container = styled.div`
  background-color: ${defaultTheme["gray-200"]};
  display: flex;
  flex: 1;
  overflow: hidden;
  margin-left: 63px;
`

export const LoadingContainer = styled.div`
  align-items: center;
  align-self: center;
  background-color: ${defaultTheme["white"]};
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 15%;
  justify-content: center;
  width: 14%;
`
