import dbRequest from "../../../../services/dbRequest/dbRequest"
import runTriggers from "../../../../services/registration/runTriggers"
import showAlert from "../../../../services/showAlert"

export default async function deleteRecord({ models, entity, setIsLoading, gridRef, detailTabPath = null, changeLoadingModalVisibility }) {
  const selectedRecords = gridRef.current.getSelectedRecords()

  const hasSelectedRecords = selectedRecords.length > 0

  if (!hasSelectedRecords)
    return showAlert({
      text: "No record selected",
      title: "Failed to delete",
      titleType: "warning"
    })

  showAlert({
    type: "confirm",
    text: "Do you want to delete the selected record(s)?",
    title: "This action cannot be undone",
    titleType: "warning",
    handleConfirm: async () => {
      changeLoadingModalVisibility()
      for (const record of selectedRecords) {
        models._REGISTRATION_DATA[entity].operation = "DEL"
        models._REGISTRATION_DATA[entity].state = "BEFORE"

        const allTriggersValid = await runTriggers(models, entity)
        if (!allTriggersValid) {
          setIsLoading(false)
          return
        }

        await dbRequest
          .deleteRecords(detailTabPath || entity)
          .record({ ...record })
          .execute()

        models._REGISTRATION_DATA[entity].state = "AFTER"
        await runTriggers(models, entity)

      }

      models[entity].reset()
      gridRef.current.deleteRecord()
      gridRef.current.selectedRecords = null
      changeLoadingModalVisibility()
      setIsLoading(false)

      showAlert({
        text: "Record(s) deleted",
        titleType: "success"
      })

    }
  })
}

