import LauncherComposerController from "../../../../controllers/LauncherComposerController"
import Navigation from "../../../../models/navigation"
import dbRequest from "../../../../services/dbRequest/dbRequest"
import runTriggers from "../../../../services/registration/runTriggers"
import showAlert from "../../../../services/showAlert"

export default async function copyRecord({ models, setIsLoading, gridRef, entity, entityName, detailTabPath = null }) {
  const selectedRecords = gridRef.current.getSelectedRecords()
  if (selectedRecords.length === 0)
    return showAlert({
      text: "No record selected",
      title: "Failed to duplicate",
      titleType: "warning"
    })

  if (selectedRecords.length > 1)
    return showAlert({
      text: "Only one record can be duplicated at a time",
      title: "Failed to duplicate",
      titleType: "warning"
    })

  const record = { ...models[entity] }

  showAlert({
    type: "confirm",
    text: "Do you want to duplicate this record?",
    title: "Record duplication",
    handleConfirm: async () => {
      setIsLoading(true)

      delete record.ID
      delete record.CREATED_BY
      delete record.CREATED_AT

      models._REGISTRATION_DATA[entity].operation = "INS"
      models._REGISTRATION_DATA[entity].state = "BEFORE"

      const allTriggersValid = await runTriggers(models, entity)
      if (!allTriggersValid) {
        setIsLoading(false)
        return
      }

      const newId = await dbRequest
        .saveRecords(detailTabPath || entity)
        .record({ ...record })
        .execute((r: any) => r.ID)

      record.ID = newId

      const navigationController = new LauncherComposerController() as any
      navigationController.models()[entity].populate(record)
      await navigationController.initialize({
        entity,
        tabName: `${entityName.replace("Panel", "")} - ${record.ID}`,
        isExistingDocument: true
      })

      navigationController.jumpScreenStatus = true

      models._REGISTRATION_DATA[entity].state = "AFTER"
      await runTriggers(models, entity)

      setIsLoading(false)

      Navigation.createTab({
        component: "NavigationComposer",
        params: {
          attribute: null,
          controller: navigationController,
          isInitialScreen: true,
          initialParams: navigationController.initialScreenParams,
          existingDocument: record,
          RootParentID: "Navigations"
        },
        tabName: `${entityName.replace("Panel", "")} - ${record.ID}`
      })
    }

  })
}
