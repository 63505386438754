import { MaterialIcons } from "@expo/vector-icons"
import styled from "rn-css"
import globalStyles from "../../../../../../../../../global/globalStyles"

export const Container = styled.ScrollView`
  background-color: ${globalStyles.colors.background};

  width: 100%;

  border: 1px solid ${globalStyles.colors.placeholder};
  border-radius: 4px;
`

export const LoadingContainer = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
`

export const RoutineContainer = styled.View``

export const Button = styled.TouchableOpacity.attrs({
  activeOpacity: 1
})`
  background-color: ${({ index }) => index % 2 ? globalStyles.colors.tertiary : globalStyles.colors.gridRowColor};

  flex-direction: row;
  align-items: center;

  gap: 4px;
`

export const Icon = styled(MaterialIcons)`
  color: ${globalStyles.colors.primary};

  font-size: 26px;
`

export const Text = styled.Text`
  color: ${globalStyles.colors.primary};

  font-size: 14px;
  font-weight: bold;
`

