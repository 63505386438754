export const chartTypes = [
  {
    LABEL: "Area Chart",
    VALUE: "AreaChart"
  },
  {
    LABEL: "Bar Chart",
    VALUE: "BarChart"
  },
  {
    LABEL: "Combo Chart",
    VALUE: "ComboChart"
  },
  {
    LABEL: "Label Chart",
    VALUE: "LabelChart"
  },
  {
    LABEL: "Pie Chart",
    VALUE: "PieChart"
  },
  {
    LABEL: "Scatter Chart",
    VALUE: "ScatterChart"
  },
  {
    LABEL: "Table Chart",
    VALUE: "TableChart"
  },
]