import LoginData from "../../../../../../../../models/loginData"
import dbRequest from "../../../../../../../../services/dbRequest/dbRequest"
import showAlert from "../../../../../../../../services/showAlert"

export default function handleSave({ filters, entityName, closeModal, setUpdateFilters, controller, setUpdateTable }) {
  showAlert({
    text: "Do you want to save changes?",
    type: "confirm",
    title: "Filters configuration",
    handleConfirm: async () => {
      const userID = LoginData.getLoginData().ID
      const models = controller.models()

      const data = filters.reduce((acc, filter, index) => {
        acc[filter.id] = {
          ORDERBY: index + 1,
          REQUIRED: filter.required ?? false
        }

        return acc
      }, {})

      await dbRequest
        .saveRecords(`T_DTD_TAB/${entityName}/T_DTD_FLT`)
        .record({
          ID: userID,
          FILTERS: { ...data }
        })
        .execute()

      showAlert({
        text: "Filters saved successfully",
        title: "Filters configuration",
        titleType: "success"
      })

      models._REGISTRATION_DATA[entityName].filtersValues = {}
      setUpdateFilters(s => !s)
      setUpdateTable(s => !s)
      closeModal()
    }
  })
}
