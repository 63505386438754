import { FontAwesome } from "@expo/vector-icons"
import styled from "rn-css"
import normalize from "../../../../../../../styles/normalizeSize"
import { defaultTheme, fontFamily } from "../../../../../../../styles/themes/default"
import { Input } from "../../../../../defaultInputsStyles"

export const Container = styled.View`
  background-color: ${defaultTheme["white"]};

  width: 100%;
  height: auto;

  padding: ${normalize(15)}px;
  gap: ${normalize(20)}px;

  border-radius: 10px;
`

export const Title = styled.Text`
  color: ${defaultTheme["gray-700"]};
  font-family: ${fontFamily["medium"]};
  text-align: center;
  font-size: ${normalize(20)}px;
`

export const InputContainer = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
`

export const SearchTextInput = styled(Input).attrs({
  autoFocus: true,
  selectTextOnFocus: true,
  maxLength: 18,
  inputMode: "numeric"
})`
  background-color: ${defaultTheme["white"]};
  color: ${defaultTheme["gray-700"]};

  font-family: ${fontFamily["regular"]};
  font-size: ${normalize(18)}px;

  width: 100%;
  height: ${normalize(45)}px;

  padding: ${normalize(8)}px;

  border-width: 1px;
  border-color: ${defaultTheme["gray-300"]};
  border-radius: 10px;

`

export const ClearButton = styled.TouchableOpacity`
  position: absolute;

  right: ${normalize(12)}px;
`

export const Icon = styled(FontAwesome)`
  color: ${defaultTheme["gray-400"]};
  font-size: ${normalize(20)}px;
`

export const ButtonsView = styled.View`
  flex-direction: row;
  justify-content: space-around;
`

const Button = styled.TouchableOpacity`
  justify-content: center;
  align-items: center;

  width: ${normalize(110)}px;
  height: ${normalize(40)}px;

  border-radius: 8px;
`

export const CancelButton = styled(Button)`
  background-color: ${defaultTheme["red-400"]} ;
`

export const ConfirmButton = styled(Button)`
  background-color: ${defaultTheme["cyan-300"]};
`

export const ButtonText = styled.Text`
  color: ${defaultTheme["white"]};
  font-family: ${fontFamily["bold"]};
  font-size: 16px;
`
