import React from "react"
import Field from "./components/Field"
import Container from "./styles"

export default function Footer({ fields, tableRecords }) {

  const tableWithoutRecords = fields.length === 0
  const fieldsWithFunctions = fields.filter(({ T_DTD_ATR }) => T_DTD_ATR.FUNCTION)
  const tableWithoutFunctions = fieldsWithFunctions.length === 0

  if (tableWithoutRecords || tableWithoutFunctions) return

  const visibleFields = fields.filter(({ T_DTD_ATR }) => T_DTD_ATR.VISIBLE)

  return (
    <Container>
      {visibleFields.map(field => <Field key={field.ID} field={field} tableRecords={tableRecords} />)}
    </Container>
  )
}
