const handleSave = ({ setFileData, closeModal, models, entity }) => {
  setFileData(state => {
    models[entity].IMG = {
      ...state,
      source: state.preview
    }

    return {
      ...state,
      source: state.preview
    }
  })

  closeModal()
}

export default handleSave
