import { defaultTheme } from "../../../../../styles/themes/default"
import LoadingAnimation from "../../../LoadingAnimation/index.web"
import ModalWrapper from "../../../Modal"
import { Container } from "./styles"

export default function LoadingModal({ isVisible }) {
  return (
    <ModalWrapper isVisible={isVisible}>
      <Container>
        <LoadingAnimation color={defaultTheme["cyan-800"]} size="large" />
      </Container>
    </ModalWrapper>
  )
}
