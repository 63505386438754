import { createAsyncThunk } from "@reduxjs/toolkit"
import RuntimeConfiguration from "../../../../models/runtimeConfiguration"
import dbRequest from "../../../../services/dbRequest/dbRequest"
import showAlert from "../../../../services/showAlert"

export const saveLevel = createAsyncThunk(
  "dashboardBuilder/saveLevel",
  async ({ form, selectedDashboard, levels }, thunkAPI) => {
    const isAdminMode = RuntimeConfiguration.getDashboardBuilderAdminMode()

    const level = { ...form }
    const _id = level.ID || getNextId(levels).toString()
    let _gadgets = null

    if (level?.gadgets?.length) _gadgets = level.gadgets
    else _gadgets = []
    delete level?.gadgets

    if (!form?.DESCRIPTION) return thunkAPI.rejectWithValue(showAlert({
      title: "Unable to save level",
      titleType: "warning",
      text: "The Description field is required."
    }))

    let saveRequest = dbRequest
      .saveRecords(`T_RDB_DSB/${selectedDashboard.ID}/T_RDB_LVL`)
      .record({
        ...level,
        ID: _id
      })

    if (isAdminMode) saveRequest = saveRequest.admin()

    await saveRequest.execute()

    return {
      ...level,
      ID: _id,
      gadgets: _gadgets
    }
  }
)

function getNextId(data) {
  if (!data?.length) return 1
  // Inicializa um conjunto para armazenar todos os IDs únicos
  const uniqueIds = new Set()

  // Extrai os IDs do primeiro nível do array
  data.forEach(item => {
    if (item && typeof item === "object" && "ID" in item) {
      uniqueIds.add(parseInt(item["ID"]))
    }
  })

  // Encontra o maior ID
  const maxId = Math.max(...[...uniqueIds])

  // Retorna o próximo ID em sequência
  return maxId + 1
}
