export default function getFormattedRecords(records) {
  const masterIDs = records.reduce((acc, item) => {
    if (item.MASTERID)
      acc[item.ID] = item.MASTERID
    return acc
  }, {})

  const formattedRecords = [...records]

  for (const item of formattedRecords)
    formatRecord(item, masterIDs)

  return formattedRecords
}

function formatRecord(record, masterIDs) {
  if ("MASTERID" in record) {
    const doesMasterIdHasMasterId = Boolean(masterIDs[record.MASTERID.ID])

    if (doesMasterIdHasMasterId) {
      record.MASTERID.MASTERID = masterIDs[record.MASTERID.ID]
      formatRecord(record.MASTERID.MASTERID, masterIDs)
    }
  }
}
