import LoginData from "../../../../../../models/loginData"
import dbRequest from "../../../../../../services/dbRequest/dbRequest"
import showAlert from "../../../../../../services/showAlert"
import { homeScreenObserver } from "../../../../../screens/HomeScreen/index.web"

const handleRemove = ({
  t,
  setPreview,
  fieldName,
  setSelectedFiles
}) => {
  showAlert({
    titleType: "warning",
    type: "confirm",
    text: t("Do you want to remove the image?"),
    title: t("Remove image"),
    handleConfirm: async () => {
      const userData = LoginData.getLoginData()

      try {

        if (fieldName === "userPhoto") userData.IMG = null
        if (fieldName === "overviewBackground") userData.BACKGROUND = null

        setPreview(null)
        setSelectedFiles(prev => ({
          ...prev,
          [fieldName]: null
        }))

        LoginData.setLoginData({ ...userData })
        await dbRequest.updateRecords("T_SET_RLU").record(userData)
          .execute()

        homeScreenObserver.notify()

        showAlert({
          text: "",
          title: t("Image removed successfully"),
          titleType: "success"
        })
      } catch (error) {
        console.log(error)
        showAlert({
          text: error.message,
          title: t("Failed to remove image"),
          titleType: "error"
        })
      }
    }
  })
}

export default handleRemove
