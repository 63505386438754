import { createAsyncThunk } from '@reduxjs/toolkit'
import dbRequest from '../../../../services/dbRequest/dbRequest'
import { getDashboadID } from './getDashboardID'
import showAlert from '../../../../services/showAlert'
import RuntimeConfiguration from '../../../../models/runtimeConfiguration'


export const saveDashboard = createAsyncThunk(
  "dashboardBuilder/saveDashboard",
  async (dashboard, thunkAPI) => {
    const isAdminMode = RuntimeConfiguration.getDashboardBuilderAdminMode()
    if (!dashboard?.DESCRIPTION) return thunkAPI.rejectWithValue(showAlert({
      title: "Unable to save dashboard",
      titleType: "warning",
      text: "The description field is required."
    }))

    let dashboardId = dashboard?.ID || await getDashboadID()
    let dashboardTransaction = dashboard?.T_DTD_TNS || {
      [dashboardId]: {
        DESCRIPTION: dashboard.DESCRIPTION
      }
    }
    let saveRequest = dbRequest.saveRecords("T_RDB_DSB").record({ ...dashboard, ID: dashboardId, T_DTD_TNS: dashboardTransaction })

    if (isAdminMode) saveRequest = saveRequest.admin()

    await saveRequest.execute()

    return { ...dashboard, ID: dashboardId, T_DTD_TNS: dashboardTransaction }
  }
)