export default () => ([
  {
    ID: "READ",
    DESCRIPTION: "Read",
    isMarked: false
  },
  {
    ID: "CREATE",
    DESCRIPTION: "Create",
    isMarked: false
  },
  {
    ID: "UPDATE",
    DESCRIPTION: "Update",
    isMarked: false
  },
  {
    ID: "DELETE",
    DESCRIPTION: "Delete",
    isMarked: false
  },
  {
    ID: "MANUALID",
    DESCRIPTION: "Manual ID",
    isMarked: false
  }
])
