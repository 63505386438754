/* eslint-disable import/prefer-default-export */
import { createGlobalStyle } from "styled-components"
import { defaultTheme } from "./themes/default"

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px white inset !important;
      box-shadow: 0 0 0 1000px white inset !important;
    }

    button {
      border: none;
      cursor: pointer;
    }

    ::selection {
      background-color: ${defaultTheme["cyan-500"]} !important;
      color: ${defaultTheme["white"]} !important;
  }

    scrollbar-color: ${defaultTheme["gray-400"]} ${defaultTheme["white"]};
    scrollbar-width: thin; /* Mostra a barra de rolagem ao passar o mouse */

    &::-webkit-scrollbar {
      width: 12px; /* Esconde a barra de rolagem por padrão */
    }

    &::-webkit-scrollbar-track {
      background: ${defaultTheme["white"]};
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${defaultTheme["gray-400"]};
      border-radius: 10px;

      &:hover {
        background: ${defaultTheme["gray-700"]};
      }
    }

    .custom-alert-title { 
      font-size: 24px;
      color: ${defaultTheme["gray-700"]};
    }

    /* Estilo para o botão de confirmação do showAlert */
    .custom-alert-confirm-button {
      background-color: ${defaultTheme["cyan-300"]}; 
      color: white; 
      padding: 8px 20px; 
      margin-right: 15px;
      border: none; 
      border-radius: 5px; 
      cursor: pointer; 
      font-size: 16px; 
      transition: background-color 0.3s; 
      font-weight: 500;
    }

    .custom-alert-confirm-button:hover {
      background-color: ${defaultTheme["cyan-500"]};
    }
    
    /* Estilo para o botão de rejeitar do showAlert */
    .custom-alert-deny-button {
      background-color: #F65C51; 
      color: white; 
      padding: 8px 20px; 
      margin-right: 15px;
      border: none; 
      border-radius: 5px; 
      cursor: pointer; 
      font-size: 16px; 
      font-weight: 500;
      transition: background-color 0.3s; 
    }

    .custom-alert-deny-button:hover {
      background-color: #e53935; 
    }
    body, html, h1, h2, h3, h4, h5, h6, p, a, ul, ol, li, table, th, td, form, input, button, textarea {
    font-family: 'Roboto', sans-serif;
  }
  }
`
