import { DashboardResponse, Gadgets, Levels } from "../types/types"

const MATRIX_SIZE = 144

export default function getDashboard(levels: Levels, currentLevel: string) {
  //if (Platform.OS === "web")
  return getDashboardWeb(levels, currentLevel)
  //return  getDashboardMob(idDashboard)
}

const getMatrices = (gadgets: object) => {

  const matrices: string[] = []

  for (const idGadget in gadgets)
    matrices.push(gadgets[idGadget].MATRIX)

  return matrices
}

const createMatrix = (matrices: string[]) => {
  let matrix = ""

  for (let i = 0; i < MATRIX_SIZE; i++) {
    let noHasZero = false
    for (let j = 0; j < matrices.length; j++)
      if (matrices[j].charAt(i) !== "0") {
        matrix += matrices[j].charAt(i)
        noHasZero = true
      }
    if (!noHasZero) matrix += "0"
  }

  return matrix
}

function populateDashboard(matrix: string, gadgets: object) {
  const dashboardValues: string[] = []
  const dashboard: DashboardResponse[] = []

  for (let i = 0; i < MATRIX_SIZE; i++) {
    if (matrix[i] !== "0" && !dashboardValues.includes(matrix[i])) {
      for (let j = MATRIX_SIZE; j > 0; j--) {
        if (matrix[i] === matrix[j]) {
          dashboard.push({
            initialPosition: [Math.floor(i / 12), i % 12],
            sizeX: j % 12 - i % 12 + 0.98,
            sizeY: Math.floor(j / 12) - Math.floor(i / 12) + 0.98,
            chartId: matrix[i],
            gadget: gadgets[matrix[i]]
          })
          break
        }
      }
      dashboardValues.push(matrix[i])
    }
  }

  return dashboard.sort((a, b) => Number(a.chartId) - Number(b.chartId))
}

function getDashboardWeb(levels: Levels, currentLevel: string) {

  const gadgets = levels[currentLevel]

  const matrices = getMatrices(gadgets)

  const matrix = createMatrix(matrices)

  const dashboard = populateDashboard(matrix, gadgets)

  return dashboard
}

/*  function getDashboardMob(idDashboard: string, currentLevel : string): Promise<MatrixProps[]> {
  const ID_CABITE = `this.idDashboard=${idDashboard} AND this.currentLevel =${currentLevel }`
  let matrices = []
  let matrices =  apiRequest.loadRecords("TDB_GDG")
    .where(ID_CABITE)
    .execute()

  matrices = matrices.map((item: { LOCALIZATION: string }) => item.LOCALIZATION)

  let matrix = ""

  for (let i = 0; i < MATRIX_SIZE; i++) {
    for (let j = 0; j < matrices.length; j++) {
      if (matrices[j].charAt(i) !== "0") {
        matrix += matrices[j].charAt(i)
      }
    }
  }

  const dashboardValues: string[] = []
  const allDashboards: MatrixProps[] = []

  for (let i = 0; i < MATRIX_SIZE; i++) {
    if (matrix[i] !== "0" && !dashboardValues.includes(matrix[i])) {
      dashboardValues.push(matrix[i])
    }
  }

  for (let i = 0; i < dashboardValues.length; i++) {
    allDashboards.push({ value: dashboardValues[i] })
  }

  return allDashboards
} */
