import React from "react"
import { useTranslation } from "react-i18next"
import screenBuilderValidation from "../DynamicField/utils/screenBuilderValidation"
import RenderField from "../RenderField"
import * as S from "./styles.web"

export default function Grouping({ group, props }) {
  const { t } = useTranslation()

  const { GROUPNAME, FIELDS, TABLENAME } = group

  if (TABLENAME === "T_DTD_ATR")
    FIELDS.forEach(field => field.T_DTD_ATR.VISIBLE = screenBuilderValidation(props.controller, field))

  const visibleFields = FIELDS.filter(({ T_DTD_ATR }) => T_DTD_ATR.VISIBLE)

  const hasVisibleFields = visibleFields.length > 0

  return hasVisibleFields && (
    <S.Container>
      <S.Title>{t(GROUPNAME)}</S.Title>

      <S.DividingLine />

      <S.FieldsContainer>
        {visibleFields.map((field) => <RenderField key={field.ID} field={field} props={props} />)}
      </S.FieldsContainer>
    </S.Container>
  )
}
