import showAlert from "../../../../../../../../services/showAlert"

export default ({ controller, openModal }) => {
  const models = controller.models()

  if (!models.T_DTD_TAB.ID) 
  return showAlert({
    text: "No table selected",
    title: "Failed to order tabs",
    titleType: "error"
  })
  openModal("orderTabs")
}
