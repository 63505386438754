import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import appJson from "../../../../app.json"
import LoginForm from "./components/LoginForm"
import RecoverPasswordModal from "./components/Modal"
import * as S from "./styles.web"

export default function LoginScreen({ navigation }) {
  const { t } = useTranslation()

  const [modalVisible, setModalVisible] = useState(false)

  return (
    <S.LoginScreenContainer>
      <S.FormContainer>
        <S.Title>{t("Welcome")}</S.Title>
        <S.Text>{t("Enter your credentials to access your account.")}</S.Text>

        <LoginForm navigation={navigation} />

        <S.RecoverPasswordText>
          {t("Forgot your password?")} <button onClick={() => setModalVisible(true)}>{t("Recover")}</button>
        </S.RecoverPasswordText>

        <S.InfoText href="https://www.musatech.com.br/" target="_blank">
          {t("Developed by")} Doal - musatech.com.br
        </S.InfoText>
        <S.AppVersion>v. {appJson.expo.version}</S.AppVersion>

        <S.LogoContainer>
          <S.LogoImage
            src={require("../../images/logo-musa-login.png")}
            alt="Musa Logo"
          />
        </S.LogoContainer>
      </S.FormContainer>
      <S.ImageCarousel />

      <RecoverPasswordModal isVisible={modalVisible} closeModal={() => setModalVisible(false)} />
    </S.LoginScreenContainer>
  )
}
