const operatorTypes = [
  {
    VALUE: "period",
    LABEL: "Period"
  },
  {
    VALUE: "==",
    LABEL: "Equals"
  },
  {
    VALUE: "!=",
    LABEL: "Not equals"
  },
  {
    VALUE: ">",
    LABEL: "Greater than"
  },
  {
    VALUE: ">=",
    LABEL: "Greater than or equals"
  },
  {
    VALUE: "<",
    LABEL: "Less than"
  },
  {
    VALUE: "<=",
    LABEL: "Less than or equals"
  }
]

export default operatorTypes
