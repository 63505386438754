import { Feather } from "@expo/vector-icons"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import LoginData from "../../../../../models/loginData"
import { defaultTheme } from "../../../../../styles/themes/default"
import ModalWrapper from "../../../Modal"
import UserForm from "./components/UserForm"
import * as S from "./styles"
import getOverviewBrackground from "./utils/getOverviewBrackground"
import getUserPhoto from "./utils/getUserPhoto"
import handleDrop from "./utils/handleDrop"
import handleRemove from "./utils/handleRemove"
import handleSelect from "./utils/handleSelect"
import onSaveUserData from "./utils/onSaveUserData"

const allowedFileTypes = ".jpeg, .jpg, .pdf, .png"

export default function UserArea({ menuIsExpanded }) {
  const { t } = useTranslation()

  const userData = LoginData.getLoginData()

  const [modalVisible, setModalVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState(null)
  const [previewBackground, setPreviewBackground] = useState(null)
  const [selectedFiles, setSelectedFiles] = useState({
    overviewBackground: null,
    userPhoto: null
  })
  const [isDragging, setIsDragging] = useState(false)

  const handleOpenModal = () => setModalVisible(true)

  const handleCloseModal = () => {
    setSelectedFiles({
      overviewBackground: null,
      userPhoto: null
    })
    setPreviewImage(null)
    setPreviewBackground(null)
    setModalVisible(false)
  }

  const handleDragOver = (event) => {
    event.preventDefault()
    setIsDragging(true)
  }

  const handleDragLeave = () => {
    setIsDragging(false)
  }

  const handleSave = () => {
    onSaveUserData({
      t,
      userData,
      selectedFiles,
      setLogoSource: setPreviewImage,
      setModalVisible,
      setSelectedFiles,
      setPreviewImage
    })
  }

  return (
    <S.UserArea onClick={handleOpenModal}>
      <S.ImageContainer>
        <S.ImageContainer>
          <S.UserImage src={getUserPhoto(userData?.IMG?.[0]?.URL)} />
        </S.ImageContainer>
      </S.ImageContainer>

      <S.UserContent>
        <S.UserFullName menuIsExpanded={menuIsExpanded}>{userData.FIRSTNAME} {userData.LASTNAME}</S.UserFullName>
        <S.UserEmail menuIsExpanded={menuIsExpanded}>{userData.EMAIL}</S.UserEmail>
      </S.UserContent>

      <ModalWrapper isVisible={modalVisible}>
        <S.ModalContainer>

          <S.ModalTitle>{t("My Profile")}</S.ModalTitle>

          <S.ModalContent>
            <S.LeftContainer>
              <S.LeftContent>
                <S.TitleContent>{t("User Photo")}</S.TitleContent>
                <S.ImageContainer
                  style={{
                    width: "70%",
                    height: "auto",
                    position: "absolute",
                    bottom: "6%"
                  }}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={(event) => handleDrop({
                    event,
                    setIsDragging,
                    fieldName: "userPhoto",
                    setSelectedFiles,
                    setPreview: setPreviewImage
                  })}
                  isDragging={isDragging}
                >
                  <S.UserImage src={previewImage || getUserPhoto(userData.IMG?.[0]?.URL)} />

                  <S.UploadOptionsContainer className='edit-icon'>
                    <S.UploadButton htmlFor='fileInput'>
                      <Feather name="upload" size={14} color={defaultTheme["gray-700"]} />
                      <span>{t("Click to upload")}</span>
                    </S.UploadButton>

                    <S.UploadButton>
                      <Feather name="move" size={14} color={defaultTheme["gray-700"]} />
                      <span>{t("or drag and drop here")}</span>
                    </S.UploadButton>

                    <S.UploadButton onClick={() => handleRemove({
                      t,
                      fieldName: "userPhoto",
                      setPreview: setPreviewImage,
                      setSelectedFiles
                    })}>
                      <Feather name="trash-2" size={14} color={defaultTheme["gray-700"]} />
                      <span>{t("Remove")}</span>
                    </S.UploadButton>

                    <input
                      accept={allowedFileTypes}
                      type="file"
                      id="fileInput"
                      onChange={(event) => handleSelect({
                        event,
                        fieldName: "userPhoto",
                        setSelectedFiles,
                        setPreview: setPreviewImage
                      })}
                      style={{ display: "none" }}
                    />
                  </S.UploadOptionsContainer>
                </S.ImageContainer>
              </S.LeftContent>

              <S.LeftContent>
                <S.TitleContent>{t("Background theme")}</S.TitleContent>
                <S.ImageContainer
                  style={{
                    width: "90%",
                    height: "80%"
                  }}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={(event) => handleDrop({
                    event,
                    setIsDragging,
                    setSelectedFiles,
                    fieldName: "overviewBackground",
                    setPreview: setPreviewBackground
                  })}
                  isDragging={isDragging}
                >
                  <S.OverviewBackground src={previewBackground || getOverviewBrackground(userData?.BACKGROUND?.URL)} />

                  <S.UploadOptionsContainer className='edit-icon'>
                    <S.UploadButton htmlFor='backgroundInput'>
                      <Feather name="upload" size={14} color={defaultTheme["gray-700"]} />
                      <span>{t("Click to upload")}</span>
                    </S.UploadButton>
                    <S.UploadButton>
                      <Feather name="move" size={14} color={defaultTheme["gray-700"]} />
                      <span>{t("or drag and drop here")}</span>
                    </S.UploadButton>
                    <S.UploadButton onClick={() => handleRemove({
                      t,
                      fieldName: "overviewBackground",
                      setPreview: setPreviewBackground,
                      setSelectedFiles
                    })}>
                      <Feather name="trash-2" size={14} color={defaultTheme["gray-700"]} />
                      <span>{t("Remove")}</span>
                    </S.UploadButton>
                    <input
                      accept={allowedFileTypes}
                      type="file"
                      id="backgroundInput"
                      onChange={(event) => handleSelect({
                        event,
                        fieldName: "overviewBackground",
                        setSelectedFiles,
                        setPreview: setPreviewBackground
                      })}
                      style={{ display: "none" }}
                    />
                  </S.UploadOptionsContainer>
                </S.ImageContainer>
              </S.LeftContent>
            </S.LeftContainer>

            <S.RightContent>

              <S.TitleContent>{t("Informations")}</S.TitleContent>

              <UserForm userData={userData} />

            </S.RightContent>
          </S.ModalContent>

          <S.ButtonsContainer>
            <S.SaveButton onClick={handleSave}>{t("Save")}</S.SaveButton>
            <S.CancelButton onClick={handleCloseModal}>{t("Cancel")}</S.CancelButton>
          </S.ButtonsContainer>

        </S.ModalContainer>
      </ModalWrapper>
    </S.UserArea>
  )
}
