/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from "@reduxjs/toolkit"
import RuntimeConfiguration from "../../../../models/runtimeConfiguration"
import dbRequest from "../../../../services/dbRequest/dbRequest"
import showAlert from "../../../../services/showAlert"

export const saveFilter = createAsyncThunk(
  "dashboardBuilder/saveFilter",
  async ({ updatedQuery, path, isDelete = false }) => {

    const isAdminMode = RuntimeConfiguration.getDashboardBuilderAdminMode()

    const queryID = updatedQuery.ID
    let saveRequest = dbRequest.saveRecords(path).record(updatedQuery)

    if (isAdminMode) saveRequest = saveRequest.admin()

    await saveRequest.execute()

    if (isDelete) showAlert({
      title: "Filter deleted",
      titleType: "success",
      text: "The filter was successfully deleted."
    })
    else showAlert({
      title: "Filter saved",
      titleType: "success",
      text: "The filter was successfully saved."
    })

    return {
      updatedQuery,
      queryID
    }
  }
)
