import showAlert from "../../../../../../../../services/showAlert"

const handleUndo =  ({ models, entity, setUpdateForm }) => {
  showAlert({
    type: "confirm",
    text: "Do you want to undo changes?",
    title: "Unsaved changes will be lost",
    titleType: "warning",
    handleConfirm: () => {
      const tableData = models._REGISTRATION_DATA[entity]
      models[entity].reset()
      models[entity].populate({ ...tableData.originalRecord })
      setUpdateForm(s => !s)
    }
  })
}

export default handleUndo
