import getTableFields from '../../../../../../../../../../../services/registration/getTableFields'

export default async function getFieldsOfField(collectionName){
  const fieldsResponse = await getTableFields({entityName: collectionName, fetchOptions: false})
  const filteredFields = fieldsResponse.filter(item => !["K", "E", "N"].includes(item.FIELDTYPE.VALUE))

  const formattedFields = filteredFields.map(item => {
    return {
      ID: item.ID,
      LABEL: item.T_DTD_ATR.FIELDDESCRIPTION
    }
  })

  return formattedFields
}