import styled from "styled-components"
import { defaultTheme } from "../../../../../styles/themes/default"

export const Container = styled.div`
  align-self: center;
  background-color: ${defaultTheme["white"]};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  padding: 30px 0;
  width: 15%;
`
