/* eslint-disable no-prototype-builtins */
export default async function generateChartData(chartSettings, dashboardData, chartParams, setChartValue, selectedRow) {
  const { series, DATAID, GROUPING, ACCUMULATOR } = chartSettings

  const data = [["labels"]]

  createSeriesName(series, data)

  let chartData = filterData(chartSettings, DATAID, dashboardData, chartParams, selectedRow)

  chartData = dataGrouping(chartData, GROUPING, ACCUMULATOR)

  setChartValue(chartData)

  await createSeriesValues(series, data, chartData, selectedRow, chartParams)

  return data
}

const filterData = (chartSettings, DATAID, dashboardData, chartParams, selectedRow) => {

  if ((selectedRow?.chartId === chartSettings.ID || Object.keys(chartParams).length === 0)) return dashboardData[DATAID]

  let data = dashboardData[DATAID]

  const validFilters = Object.keys(chartParams)
    .filter(filter => {
      if (filter.includes(".")) return data[0].hasOwnProperty(filter.split(".")[0])
      else return data[0].hasOwnProperty(filter)
    })

  data = data.filter(record => {
    let anyFilterMatches = false
    for (let i = 0; i < validFilters.length; i++) {
      const filter = validFilters[i]

      const recordValue = getField(record, filter, true)
      const expectedValue = chartParams[filter]
      const isMatch = recordValue === expectedValue

      if (isMatch) {
        anyFilterMatches = true
        break
      }
    }
    return anyFilterMatches
  })

  return data
}

const getField = (obj, id, subField, isDetailScreenField) => {
  if (subField || isDetailScreenField) return getValueFromPath(obj, id)
  else return obj[id]
}

const getValueFromPath = (obj, path) => {
  const keys = path.split(".")

  return keys.reduce((acc, key) => acc && acc[key], obj)
}

const createSeriesName = (serie, data) => {
  for (const id in serie) {
    data[0].push(serie[id].DESCRIPTION)
    if (serie[id].COLOR) data[0].push({ role: "style" })
  }
}

const createSeriesValues = async (serie, data, dashboardData, selectedRow, chartParams) => {
  const tempArray = []

  for (let i = 0; i < dashboardData.length; i++) {
    const objTemp = {}

    for (const id in serie) {
      objTemp.grupo = `${dashboardData[i][serie[id].GROUPING.VALUE]}`
    }

    for (const id in serie) {
      const fieldName = `valor${id} `
      objTemp[fieldName] = dashboardData[i][serie[id].VALUE.VALUE]
      if (Object.keys(chartParams).length > 0 && (selectedRow !== null && selectedRow !== undefined)) {
        const selectedRowValue = chartParams[serie[id].GROUPING.VALUE]
        if (serie[id].DESCRIPTION === selectedRow.serieName && selectedRowValue === dashboardData[i][serie[id].GROUPING.VALUE]) {
          objTemp[`color${id}`] = serie[id].COLOR
        } else {
          objTemp[`color${id}`] = "#C2C2C2"
        }
      } else objTemp[`color${id}`] = serie[id].COLOR
    }

    tempArray.push(objTemp)
  }

  const ordenateData = orderByGroup(tempArray)

  for (let i = 0; i < ordenateData.length; i++) {
    const newArray = Object.values(ordenateData[i])

    if (newArray.includes(undefined)) {
      newArray.pop()
    }

    data.push(newArray)
  }
}

function orderByGroup(array) {
  return array.sort((a, b) => {
    if (a.grupo < b.grupo) return -1
    if (a.grupo > b.grupo) return 1
    return 0
  })
}

const dataGrouping = (data, groupingFields, accumulatorsFields) => {

  const grouping = data.reduce((accumulator, item) => {
    const key = groupingFields.map(group => {
      if (group?.SUBFIELD) return getValueFromPath(item, group.ID)
      else return item[group.ID]
    }).join("_")

    if (!accumulator[key]) {
      accumulator[key] = {}
      accumulatorsFields.forEach(({ ID }) => {
        accumulator[key][ID] = 0
      })

      groupingFields.forEach(group => {
        if (group?.SUBFIELD) {
          const fieldKey = group.ID
          accumulator[key][fieldKey] = getValueFromPath(item, group.ID)
        } else accumulator[key][group.ID] = item[group.ID]
      })
    }

    accumulatorsFields.forEach(({ ID, SUBFIELD, FUNCTION, ISDETAILSCREENFIELD }) => {

      let fieldValue = getField(item, ID, SUBFIELD, ISDETAILSCREENFIELD)

      if (FUNCTION.VALUE === "count") accumulator[key][ID]++
      else if (FUNCTION.VALUE === "avg") {
        if (accumulator[key][`${ID}_count`] === undefined) {
          accumulator[key][`${ID}_sum`] = 0
          accumulator[key][`${ID}_count`] = 0
        }
        accumulator[key][`${ID}_sum`] += fieldValue
        accumulator[key][`${ID}_count`]++
      } else accumulator[key][ID] += fieldValue
    })

    return accumulator
  }, {})

  // Calcular a média para os grupos que têm média
  Object.values(grouping).forEach(group => {
    accumulatorsFields.forEach(({ ID, FUNCTION }) => {
      if (FUNCTION.VALUE === "avg") {
        if (group[`${ID}_count`] > 0) {
          group[ID] = group[`${ID}_sum`] / group[`${ID}_count`]
        } else {
          group[ID] = 0 // Defina como 0 se não houver itens para evitar divisão por zero
        }
        delete group[`${ID}_sum`]
        delete group[`${ID}_count`]
      }
    })
  })

  return Object.values(grouping)
}

