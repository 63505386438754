import showAlert from "../../../../../../../../services/showAlert"

export default function handleDelete({ index, callback }) {
  showAlert({
    text: "Do you want to delete the selected file?",
    type: "confirm",
    title: "Delete",
    handleConfirm: () => {
      callback(state => {
        const newArray = [...state]
        newArray.splice(index, 1)

        return newArray
      })
    }
  })
}
