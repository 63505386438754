import React, { useContext, useState } from "react"
import { GridModeContext } from "../ScreenFactory/contexts/GrideModeProvider"
import FiltersArea from "./components/FiltersArea"
import FiltersConfiguration from "./components/FiltersConfiguration"
import IdSourceInput from "./components/IdSourceInput"
import MoreOptionsButton from "./components/MoreOptionsButton"
import SearchInput from "./components/SearchInput"
import Totalizer from "./components/Totalizer"
import * as S from "./styles"
import renderButtons from "./utils/renderButtons"
import shouldRender from "./utils/shouldRender"

export default function Panel(props) {
  const { showSearchInput = true, viewType } = props

  const commonProps = {
    ...props,
    models: props.controller.models(),
  }

  const mode = viewType === "registration" ? useContext(GridModeContext).mode : "table"

  const [updateFilters, setUpdateFilters] = useState(false)

  const isVisible = component => shouldRender(component, viewType, mode)

  return (
    <>
      <S.Container>
        <S.Row>
          {renderButtons(commonProps)}

          {isVisible("searchInput") && showSearchInput && <SearchInput {...commonProps} />}

          {isVisible("filtersButton") && <FiltersConfiguration {...commonProps} setUpdateFilters={setUpdateFilters} />}

          {isVisible("idSourceInput") && <IdSourceInput {...commonProps} />}
        </S.Row>

        <S.Row>
          {/* {isVisible("fieldsButton") && <FieldsConfiguration {...commonProps} />} */}

          {isVisible("moreOptionsButton") && <MoreOptionsButton {...commonProps} />}

          {isVisible("totalizer") && <Totalizer {...commonProps} />}
        </S.Row>
      </S.Container>

      {isVisible("filtersArea") && <FiltersArea {...commonProps} updateFilters={updateFilters} />}
    </>
  )
}
