import { useEffect, useState } from 'react'
import * as S from './styles'

export default function SearchInput({ gridRef }) {
  const [searchText, setSearchText] = useState("")

  const handleSearch = (event) => setSearchText(event.target.value)

  const clearInput = () => setSearchText("")

  useEffect(() => {
    if (gridRef.current) {
      //gridRef.current.searchSettings.hierarchyMode = "Both"
      gridRef.current.search(searchText)
    }
  }, [searchText])

  return (
    <S.Container>
      <S.Input placeholder="Search" value={searchText} onChange={handleSearch} />

      <S.Button onClick={clearInput}>
        <S.Icon name={searchText ? "close" : "search1"} />
      </S.Button>
    </S.Container>
  )
}
