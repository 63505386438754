import React, { useRef } from "react"
import { useTranslation } from "react-i18next"
import { defaultTheme } from "../../../../../styles/themes/default"
import * as S from "./styles"

export default function ColumnsManager({ entity, gridRef }) {
  const { t } = useTranslation()

  const columnChooserButtonRef = useRef(null)

  const managerId = `${entity}-column-manager`

  const handleColumnChooser = () => {
    gridRef.current.columnChooserModule.openColumnChooser()

    const buttonPosition = columnChooserButtonRef.current.getBoundingClientRect()

    const columnChooserElement = document.querySelector(".e-ccdlg") as HTMLElement
    columnChooserElement.style.top = `${buttonPosition.bottom - 60}px`
    columnChooserElement.style.left = `${buttonPosition.left - 150}px`
  }

  return (
    <S.Container
      id={managerId}
      onClick={handleColumnChooser}
      ref={columnChooserButtonRef}
    >
      <style>
        {classesStyles}
      </style>
      {t("Columns Visibility")}
    </S.Container>
  )
}

const classesStyles = `
  .e-cc.e-control.e-btn.e-lib.e-cc_okbtn.e-primary.e-flat {
    color: ${defaultTheme["cyan-800"]} !important;
  }

  .e-ccsearch.e-cc.e-input {
    padding: 0 !important;
  }

  .e-cc-searchdiv.e-cc.e-input-group {
    margin-top: 4px !important; 
    margin-left: 6px !important; 
    width: 90% !important;
  }
`
