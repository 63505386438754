import { MaterialCommunityIcons } from "@expo/vector-icons"
import styled from "rn-css"
import globalStyles from "../../../../../../../global/globalStyles"

export const Container = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const Title = styled.Text`
  font-size: 20px;
  color: #2e2e2e;
  font-weight: 500;
`

export const ButtonsView = styled.View`
  flex-direction: row;
  gap: 30px;
  align-items: center;
`

export const SaveButton = styled.TouchableOpacity`
  background-color: ${globalStyles.colors.switchButton};
  height: 28px;
  width: 75px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
`

export const SaveText = styled.Text`
  font-size: 14px;
  color: ${globalStyles.colors.tertiary};
`

export const CloseButton = styled.TouchableOpacity`
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
  height: 28px;
  width: 28px;
  justify-content: center;
  align-items: center;
`

export const Icon = styled(MaterialCommunityIcons)`
  color: #2E2E2E;
  font-size: 20px;
`
