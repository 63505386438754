import React, { useEffect, useState } from "react"
import RenderPermissions from "../.."
import { Button, Icon, Text } from "../../../../../RenderRoutines/styles"
import Container from "./styles"
import getDetailTabs from "./utils/getDetailTabs"

export default function RenderDetailTabsList({ masterTab, permissions, models, entityName }) {

  const [detailTabs, setDetailTabs] = useState([])
  const [update, setUpdate] = useState(false)

  const handlePress = item => {
    item["icon"] === "arrow-drop-down" ? item["icon"] = "arrow-right" : item["icon"] = "arrow-drop-down"
    item["open"] = !(item["open"] ?? false)
    setUpdate(s => !s)
  }

  useEffect(() => { getDetailTabs(masterTab.ID).then(setDetailTabs) }, [])

  return detailTabs.map((item, index) => {
    if (!item["icon"]) item["icon"] = "arrow-right"

    const currentTransaction = Object.keys(item.T_DTD_TNS ?? {})[0]

    return currentTransaction && (
      <Container key={index}>
        <Button index={index} onPress={() => { handlePress(item) }}>
          <Icon name={item["icon"]} />

          <Text>{item.T_DTD_TNS[currentTransaction].DESCRIPTION}</Text>
        </Button>

        {item["open"] && <RenderPermissions item={item} permissions={permissions} isDetail={true} models={models} entityName={entityName} />}
      </Container>
    )
  })
}
