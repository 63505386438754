import getDoubleClickEvent from "../../../../utils/getDoubleClickEvent"

export default function processSelectedGadget(state, { payload }) {

  const { selectedGadget } = payload

  if (selectedGadget) {
    state.dashboardData.selectedGadget = selectedGadget
    getDoubleClickEvent(doubleClickEvent => {
      if (doubleClickEvent.isActive) {
        state.modals.gadgetModal.mode = "edit"
        state.modals.gadgetModal.form = selectedGadget
        state.modals.gadgetModal.isVisible = true
      }
    })
  } else {
    state.modals.gadgetModal.mode = "new"
    state.modals.gadgetModal.isVisible = true
    state.modals.gadgetModal.form = {}
    state.dashboardData.selectedGadget = { series: [] }
  }
}
