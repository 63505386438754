export default function applyMask(value, mask) {
  let result = ""
  let valueIndex = 0
  let shiftMode = null

  for (let i = 0; i < mask.length; i++) {
    const maskChar = mask[i]
    const currentValue = value[valueIndex]

    if (valueIndex >= value.length) {
      break
    }

    switch (maskChar) {
      case "0":
        if (/\d/.test(currentValue)) {
          result += currentValue
          valueIndex++
        } else {
          throw new Error("Invalid input for mask: expected digit.", {
            cause: currentValue
          })
        }
        break

      case "9":
        if (/\d/.test(currentValue) || currentValue === " ") {
          result += currentValue
          valueIndex++
        } else {
          result += " "
        }
        break

      case "#":
        if (/[+-]|\d|\s/.test(currentValue)) {
          result += currentValue
          valueIndex++
        }
        break

      case "L":
        if (/[a-zA-Z]/.test(currentValue)) {
          result += currentValue
          valueIndex++
        } else {
          throw new Error("Invalid input for mask: expected letter.", {
            cause: currentValue
          })
        }
        break

      case "?":
        if (/[a-zA-Z\s]/.test(currentValue)) {
          result += currentValue
          valueIndex++
        } else {
          result += " "
        }
        break

      case "&":
        if (currentValue) {
          result += currentValue
          valueIndex++
        } else {
          throw new Error("Invalid input for mask: expected character.", {
            cause: currentValue
          })
        }
        break

      case "C":
        result += currentValue || " "
        valueIndex++
        break

      case "A":
        if (/[a-zA-Z0-9]/.test(currentValue)) {
          result += currentValue
          valueIndex++
        } else {
          throw new Error("Invalid input for mask: expected alphanumeric.", {
            cause: currentValue
          })
        }
        break

      case "a":
        if (/[a-zA-Z0-9\s]/.test(currentValue)) {
          result += currentValue
          valueIndex++
        } else {
          result += " "
        }
        break

      case "<":
        shiftMode = "lower"
        break

      case ">":
        shiftMode = "upper"
        break

      case "|":
        shiftMode = null
        break

      case "\\":
        i++
        if (i < mask.length) {
          result += mask[i]
        }
        break

      default:
        if (shiftMode === "lower") {
          result += maskChar.toLowerCase()
        } else if (shiftMode === "upper") {
          result += maskChar.toUpperCase()
        } else {
          result += maskChar
        }

        if (maskChar === currentValue) {
          valueIndex++
        }
    }
  }

  return result
}
