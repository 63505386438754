import models from "../models"
import dbRequest from "../services/dbRequest/dbRequest"
import showAlert from "../services/showAlert"
import BaseController from "./BaseController"

export default class ReportController extends BaseController {

  constructor() {
    super()
    this.modelsTemplate = models.getClone()
    this.models = () => this.modelsTemplate
    this.screenName = () => "Relatório"
  }

  static async generate(id: string, obj: object) { await this.getReport(id, obj) }

  static async getReport(id: string, obj: object) {
    return await dbRequest
      .getReport(id)
      .record(obj)
      .execute(async (r: { base64Response: string }): Promise<Uint8Array> => {
        const decodedString = atob(r.base64Response)
        const bufferData = await JSON.parse(decodedString).data
        const uint8Array = new Uint8Array(bufferData)
        return uint8Array
      })
  }

  static generatePDF(report: BlobPart) {
    const url = URL.createObjectURL(new Blob([report], { type: "application/pdf" }))
    window.open().document.write("<iframe width=\"100%\" height=\"100%\" src=\"" + url + "\"></iframe>")
  }

  async generate(id: string, obj: object) {
    try {

      const report = await ReportController.getReport(id, obj) as BlobPart
      if (typeof report === "string") console.log(report)
      ReportController.generatePDF(report)
    }
    catch (e) {
      showAlert({
        text: "Incorrect parameters or corrupted report",
        title: "Failed to generate report",
        titleType: "error"
      })
    }
  }
}
