import styled from "styled-components"
import styledNative from "styled-components/native"
import { defaultTheme } from "../../../../../../../../styles/themes/default"
import { InputField } from "../../../../../../defaultInputsStyles"

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 4px;
`

interface InputProps {
  editable?: boolean
  negative?: boolean
}

export const Input = styledNative(InputField)
  .attrs<InputProps>(({ editable }) => ({
    editable: editable,
    focusable: editable,
    keyboardType: "numeric",
    placeholderTextColor: defaultTheme["gray-300"],
    selectTextOnFocus: true
  })) <InputProps>`
  background-color: ${({ editable }) => editable ? defaultTheme["white"] : "transparent"};
  border: none;
  border-radius: 18px;
  color: ${({ negative }) => defaultTheme[negative ? "red-500" : "gray-500"]};;
  cursor: ${({ editable }) => editable ? "auto" : "default"};
  font-size: 1rem;
  font-weight: 600;
  height: ${defaultTheme.formInput.height}px;
  outline: none;
  padding: 2px 10px;
  width: 170px;
`
