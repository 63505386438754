import handleDoubleClick from "./handleDoubleClick"

export default props => {
  const { controller, entityName, rowData, viewType, selectedCallback = () => null, setSelectedItem, setUpdate } = props

  const models = controller.models()

  setSelectedItem(rowData)

  if (viewType === "registration") {
    selectedCallback(rowData)
    populateModels(models, entityName, rowData)
  }

  rowData.open = !rowData.open
  setUpdate(s => !s)

  handleDoubleClick(props)
}

const populateModels = (models, entityName, rowData) => {
  const value = { ...rowData }
  delete value.open

  models[entityName].reset()
  models[entityName].populate(value)
  models._REGISTRATION_DATA[entityName].originalRecord = { ...value }
}
