import dbRequest from "../services/dbRequest/dbRequest"
import LoginData from "./loginData"

export default class Permissions {

  static setCurrentRoutine(r) { this.current = r }
  static getCurrentRoutine() { return this["T" + this.current] }

  static getPermissions(id) {
    return this["T" + id]
  }

  static getPermission(permission) {
    if (LoginData.getLoginData().ADMIN) return true
    return this["T" + this.current]?.[permission]
  }

  static async initialize() {
    Object.keys(this)
      .forEach((key) => {
        if (key.startsWith("T"))
          delete this[key]
      })

    const routines = await this.getRoutines()

    for (const r of routines) {
      this["T" + r.TRANSACTION] = r
    }
  }

  static async getRoutines(data = {}) {
    const { record, entity } = data

    let userPermissions = {}
    let userGroupPermissions = {}

    if (!record) {
      const { T_SET_ACC = {}, GROUPUID = {} } = LoginData.getLoginData()

      userPermissions = T_SET_ACC
      userGroupPermissions = await getAccess("T_SET_UGR", GROUPUID.ID)
    } else {
      const groupId = entity === "T_SET_UGR" ? record.ID : record.GROUPUID?.ID
      userGroupPermissions = await getAccess("T_SET_UGR", groupId)

      if (entity === "T_SET_RLU")
        userPermissions = await getAccess(entity, record.ID)
    }

    const allPermissions = mergePermissions(userPermissions, userGroupPermissions)

    return Object.keys(allPermissions)
      .map(permission => ({
        TRANSACTION: permission,
        ...allPermissions[permission]
      }))
  }
}

async function getAccess(entityName, id) {
  return await dbRequest
    .loadRecords(entityName)
    .where("ID", "==", id || null)
    .execute(r => {
      if (r) return r[0]?.T_SET_ACC ?? {}
      else return {}
    })
}

function mergePermissions(userPermissions, userGroupPermissions) {
  const result = { ...userPermissions }

  for (const transaction in userGroupPermissions) {
    const userRoutine = userPermissions[transaction]
    const groupRoutine = userGroupPermissions[transaction]

    if (typeof userRoutine === "object" && typeof groupRoutine === "object") {
      // If both values are objects, recursively merge them
      result[transaction] = mergePermissions(userRoutine, groupRoutine)
    } else {
      // If the key doesn't exist in obj1, or the value in obj1 is not an object, set the value from obj2
      result[transaction] = userRoutine || groupRoutine
    }
  }

  return result
}
