import { Ionicons } from "@expo/vector-icons"
import styledWeb from "styled-components"
import styled from "styled-components/native"
import globalStyles from "../../../../global/globalStyles"

export const Container = styled.View`
  flex: 1;
`

export const ButtonsView = styled.View`
  position: absolute;
  top: 2px;
  right: 0px;
  z-index: 1;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding-right: 10px;
`

export const Loading = styled.ActivityIndicator.attrs(() => ({
  size: "large",
  color: globalStyles.colors.primary
})
)``

export const LoadingView = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`

export const TextView = styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  gap: 2px;
`

export const Icon = styled(Ionicons).attrs({ name: "information-circle-outline" })`
  color: ${globalStyles.colors.primary};
  font-size: 16px;
`

export const Text = styled.Text`
  color: ${globalStyles.colors.primary};
  font-size: 14px;
`

export const Title = styledWeb.h1`
  font-family: Arial, sans-serif;
  color: #333333;
  font-size: 14px;
  font-weight: 600;
  margin-left: 5px;
  padding: 10px;
`

export const CloseButton = styledWeb.button`
  display: flex;
  align-items: center;
  padding:  3px;
  margin-top: 5px;
  
  background-color: ${globalStyles.colors.tertiary};

  border-radius: 5px;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);


  &:hover {
    transform: scale(1.05);
  }

  z-index: 2;
`
