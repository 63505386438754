import getImageSource from "./getImageSource"

const handleRemove = async ({
  setPreviewImage,
  setSelectedFile
}) => {
  setPreviewImage(await getImageSource(true))
  setSelectedFile(null)
}

export default handleRemove
