import React from "react"
import RenderTabs from "./components/RenderTabs"
import useForm from "./hooks/useForm"
import * as S from "./styles.web"

export default function Form({
  entity,
  hideTabs = false,
  models,
  screenBuilder = false,
  setUpdateForm = () => null,
  staticFields = null,
  updateForm = false
}) {

  const { fields, tabs } = useForm({
    entity,
    models,
    screenBuilder,
    staticFields,
    updateForm
  })

  const hasTabs = tabs.length > 0

  return (
    <S.Container>
      {hasTabs && (
        <RenderTabs
          fields={fields}
          hideTabs={hideTabs}
          models={models}
          setUpdateForm={setUpdateForm}
          tabs={tabs}
          updateForm={updateForm}
          screenBuilder={screenBuilder}
        />  
      )}
    </S.Container>
  )
}

