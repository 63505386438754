import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Form from "../../../Form"
import LoadingAnimation from "../../../LoadingAnimation"
import * as S from "./styles"
import allRequiredFiltersFilled from "./utils/allRequiredFiltersFilled"
import getFilters from "./utils/getFilters"

export default function FiltersArea({ updateFilters, entityName, models, setUpdateTable }) {
  const { t } = useTranslation()

  const tableData = models._REGISTRATION_DATA[entityName]

  const [isFetchingFilters, setIsFetchingFilters] = useState([])

  async function fetchFilters() {
    setIsFetchingFilters(true)

    getFilters({
      entityName,
      models
    }).then(r => {
      tableData.filtersValues = {}
      tableData.filterFields = r
      setIsFetchingFilters(false)
    })
  }

  useEffect(() => {
    fetchFilters()
  }, [updateFilters])

  const applyFilters = () => {
    const isValid = allRequiredFiltersFilled({
      models,
      entityName
    })

    if (isValid) setUpdateTable(s => !s)
  }

  const hasFilters = models._REGISTRATION_DATA[entityName].filterFields.length > 0

  return isFetchingFilters ? (
    <S.CenteredView>
      <LoadingAnimation size='medium' />
    </S.CenteredView>
  ) : hasFilters ? (
    <S.Container>
      <S.FormContainer>
        <Form
          entityName={entityName}
          models={models}
          staticFields={getFilters({
            entityName,
            models
          })}
          updateForm={updateFilters}
          isFilters={true}
        />
      </S.FormContainer>

      <S.Button onPress={applyFilters}>
        <S.ButtonText>{t("Apply")}</S.ButtonText>
      </S.Button>
    </S.Container>
  ) : <></>
}
