import AsyncStorage from "@react-native-async-storage/async-storage"
import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import { Platform } from "react-native"
import de from "./de.json"
import en from "./en.json"
import es from "./es.json"
import fr from "./fr.json"
import ja from "./ja.json"
import pt from "./pt.json"

const LANGUAGE_KEY = "user-language"

const getStoredLanguage = async () => {
  try {
    if (Platform.OS === "web") {
      return localStorage.getItem(LANGUAGE_KEY) || null
    } else {
      return await AsyncStorage.getItem(LANGUAGE_KEY)
    }
  } catch (error) {
    console.error("Error reading storage:", error)
    return null
  }
}

export const initializeI18n = async () => {
  try {
    const storedLanguage = await getStoredLanguage()

    const resources = {
      en: { translation: en },
      pt: { translation: pt },
      es: { translation: es },
      de: { translation: de },
      fr: { translation: fr },
      ja: { translation: ja }
    }

    await i18n
      .use(initReactI18next)
      .init({
        compatibilityJSON: "v3",
        lng: storedLanguage || "pt",
        fallbackLng: "pt",
        resources,
        react: {
          useSuspense: false
        },
        interpolation: {
          escapeValue: false
        }
      })
  } catch (error) {
    console.error("Error initializing i18n:", error)
  }
}

export const changeLanguage = async (value) => {
  try {
    const currentLanguage = i18n.language
    if (currentLanguage !== value) {
      await i18n.changeLanguage(value)
      if (Platform.OS === "web") {
        localStorage.setItem(LANGUAGE_KEY, value)
      } else {
        await AsyncStorage.setItem(LANGUAGE_KEY, value)
      }
    }
  } catch (error) {
    console.error("Error changing language:", error)
  }
}

export default i18n
