import getFormattedRecords from "./getFormattedRecords"

export default (entityName, tableRecords, filters, fieldsData, models, isHierarchic) => {
  if (isHierarchic) {
    const formattedRecords = getFormattedRecords(tableRecords)

    const filteredRecords = getFilteredRecords(entityName, formattedRecords, filters, fieldsData, models)
    const recordsToRender = extractMasterIDs(filteredRecords)

    const openedRecords = tableRecords.filter(({ ID }) => recordsToRender.has(ID))
    openedRecords.forEach(i => i.open = true)
    return openedRecords
  }

  return getFilteredRecords(entityName, tableRecords, filters, fieldsData, models)
}

function getFilteredRecords(entityName, tableRecords, filters, fieldsData, models) {
  return tableRecords.filter(record => filters.every(({ key, value }) => {
    switch (typeof value) {
      case "object": {
        if (value.ID) return record[key]?.ID === value.ID //Search field
        if (value.VALUE === null) return true
        return record[key]?.VALUE === value.VALUE || record[key] === value.VALUE //List and boolean field
      }

      default: {
        const fieldHasMask = fieldsData[key]?.MASK
        const isDate = ["D", "T"].includes(fieldsData[key]?.FIELDTYPE.VALUE)

        if (isDate) {
          const initialDate = models._REGISTRATION_DATA[entityName].filtersValues[`${key}_INI`]
          const finalDate = models._REGISTRATION_DATA[entityName].filtersValues[`${key}_FIN`]
          const checkedDate = record[key]

          return checkDateInRange(initialDate, finalDate, checkedDate)
        }

        return formatValue(record[key], fieldHasMask).includes(formatValue(value, fieldHasMask))
      }
    }
  }))
}

function extractMasterIDs(records, result = new Set()) {
  for (const item of records) {
    result.add(item.ID)
    if ("MASTERID" in item) extractMasterIDs([item.MASTERID], result)
  }

  return result
}

const formatValue = (str = "", fieldHasMask = false) => {
  const maskRegex = /[()/.\-\\]/g
  const formattedValue = fieldHasMask ? str.replace(maskRegex, "") : str
  return String(formattedValue).toUpperCase()
}

const checkDateInRange = (initialDate, finalDate, checkedDate) => {
  let initialDateObj = null
  let finalDateObj = null
  const checkedDateObj = new Date(checkedDate)

  if (initialDate) initialDateObj = new Date(getDateString(initialDate).trim() + "T00:00:00")

  if (finalDate) finalDateObj = new Date(getDateString(finalDate).trim() + "T23:59:59")

  if (initialDateObj && finalDateObj)
    return checkedDateObj >= initialDateObj && checkedDateObj <= finalDateObj

  return initialDateObj
    ? checkedDateObj >= initialDateObj
    : checkedDateObj <= finalDateObj
}

const getDateString = (date) => {
  const [datePart] = date.split("T")
  return datePart
    .split("/")
    .reverse()
    .join("-")
}

