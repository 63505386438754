import React from "react"
import SelectionInputFilter from "./components/SelectionInput"
import Container from "./styles"
import getBrands from "./utils/getBrands"
import getProductGroups from "./utils/getProductGroups"

const generalFilters = [
  { VALUE: "General" },
  { VALUE: "Promotion" },
  { VALUE: "Replenishment" },
  { VALUE: "Stock" }
]

export default function AdditionalItem({ controller, setUpdate = () => null }) {

  return (
    <Container>

      <SelectionInputFilter
        inputName={"Brand"}
        fieldname={"TAG"}
        iconName={"storefront-outline"}
        getDataFunction={() => getBrands(controller)} setUpdate={setUpdate}
        multiple
      />
      <SelectionInputFilter
        inputName={"Group"}
        fieldname={"GROUPITEMID"}
        iconName={"layers"}
        getDataFunction={() => getProductGroups(controller)} setUpdate={setUpdate}
        multiple
      />
      <SelectionInputFilter
        iconName={"list"}
        getDataFunction={() => generalFilters}
        setUpdate={setUpdate}
      />

    </Container>
  )
}
