import React, { useState, useEffect } from "react"
import * as S from "./styles"

export default function FloatInput({ entity, models, updateForm, setUpdateForm }) {
  const [value, setValue] = useState(models[entity].ID || "")

  const tableData = models._REGISTRATION_DATA[entity]
  const fieldData = tableData.fields.find(field => field.ID === "ID")

  useEffect(() => {
    setValue(models[entity].ID || "")
  }, [updateForm])

  const isAnInsertion = tableData.operation === "INS"

  const isNegative = parseFloat(value) < 0

  return (
    <S.InputContainer>
      <S.Input
        value={value}
        placeholder="ID"
        precision={fieldData.T_DTD_ATR.DECIMAL ?? 2}
        negative={isNegative}
        editable={isAnInsertion}
        onChangeValue={setValue}
        onBlur={() => models[entity].ID = value}
      />
    </S.InputContainer>
  )
}
