import React from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet } from "react-native"
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
  renderers
} from "react-native-popup-menu"
import globalStyles from "../../../../../../../global/globalStyles"
import Icon from "./styles"
import functions from "./utils/functions"

export default function FunctionsButton(props) {
  const { t } = useTranslation()

  const { Popover } = renderers

  return (
    <Menu renderer={Popover} rendererProps={{ placement: "bottom" }}>
      <MenuTrigger style={styles.menuTrigger}>
        <Icon name="ellipsis-h" />
        <Icon name="caret-down" />
      </MenuTrigger>

      <MenuOptions style={styles.menuOptions}>
        {functions(props)
          .sort((a, b) => t(a.DESCRIPTION).localeCompare(t(b.DESCRIPTION)))
          .map((item, index) => (
            <MenuOption
              key={index}
              style={styles.contentText}
              onSelect={item.onPress}
              text={t(`${item.DESCRIPTION}`)}
            />
          ))}
      </MenuOptions>
    </Menu>
  )
}

const styles = StyleSheet.create({
  menuTrigger: {
    backgroundColor: globalStyles.colors.tertiary,
    flexDirection: "row",
    alignItems: "center",
    height: 25,
    margin: 5,
    paddingHorizontal: 10,
    borderRadius: 3,
    gap: 5
  },
  menuOptions: {
    minWidth: 300,
    width: "auto",
    padding: 10
  },
  menuOption: { textAlign: "center" },
  contentText: { fontSize: globalStyles.sizes.fontSize }
})
