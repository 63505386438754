const handleSelect = ({
  setSelectedFile,
  setPreviewImage,
  event,
  inputRef
}) => {
  const files = [...event.target.files]

  if (files.length > 0) {
    const file = files[0]
    setSelectedFile(file)
    const reader = new FileReader()
    reader.onloadend = () => setPreviewImage(reader.result)
    reader.readAsDataURL(file)
  }
  if (inputRef.current) {
    inputRef.current.value = null
  }

}

export default handleSelect
