import NavigationController from "../NavigationController"

interface controller {
  navigate: (arg0: string, arg1: any) => void,
  updateList: (any) => any,
  changeScreen: () => void
}

export default function navigationDecorator(controller: controller) {
  controller.navigate = (name: string, props = {}): void => NavigationController.navigate(name, props)
  controller.updateList = (): void => { console.log("Method 'updateList' was not overwrited") }
  controller.changeScreen = async (): Promise<void> => controller.updateList((s: boolean) => !s)
}
