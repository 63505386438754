import { FontAwesome } from "@expo/vector-icons"
import styled from "rn-css"
import globalStyles from "../../../../../global/globalStyles"

export const Row = styled.View`
  flex-direction: row;

  padding: 5px;

  gap: 7px;
`

export const Button = styled.TouchableOpacity`

  background-color: ${globalStyles.colors.tertiary};

  height: 25px;
  width: 25px;

  justify-content: center;
  align-items: center;

  border: 1px solid ${globalStyles.colors.primary};
  border-radius: 4px;
`

export const Icon = styled(FontAwesome)`
  color: ${globalStyles.colors.primary};

  font-size: 16px;
`
