/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from "@reduxjs/toolkit"
import RuntimeConfiguration from "../../../../models/runtimeConfiguration"
import dbRequest from "../../../../services/dbRequest/dbRequest"
import showAlert from "../../../../services/showAlert"

export const saveQuery = createAsyncThunk(
  "dashboardBuilder/saveQuery",
  async (query, thunkAPI) => {
    const isAdminMode = RuntimeConfiguration.getDashboardBuilderAdminMode()
    const queryForm = { ...query.form }

    if (!queryForm?.ID && !queryForm.ENTITY?.ID) return thunkAPI.rejectWithValue(showAlert({
      title: "Unable to save query",
      titleType: "warning",
      text: "The ID and Entity fields are required."
    }))

    let saveRequest = dbRequest.saveRecords(query.path)
    let loadRequest = dbRequest.loadRecords(query.path)

    if (isAdminMode) saveRequest = saveRequest.admin()

    if (queryForm?.ID) {
      saveRequest = saveRequest.record({ ...queryForm })
    } else {
      saveRequest = saveRequest
        .record({
          ID: queryForm.ID,
          ENTITY: {
            ID: queryForm.ENTITY.ID,
            LABEL: queryForm.ENTITY.ENTITY
          }
        })
    }

    await saveRequest.execute()

    const queryData = await loadRequest.where("ID", "==", queryForm.ID).execute(([r]) => r)

    return {
      ...queryData,
      connections: []
    }
  }
)
