import { Feather, AntDesign } from "@expo/vector-icons"
import { useFocusEffect } from "@react-navigation/native"
import React, { useState, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { populateProducts, removeAdditionalFilters, resetState, updateShippingValue } from "../../../redux/redux-reducers/order-reducer"
import hasPermissionToUpdate from "../../../services/hasPermissionToUpdate"
import { defaultTheme } from "../../../styles/themes/default"
import DynamicCard from "../../components/cards/DynamicCard"
import DynamicItemCard from "../../components/cards/DynamicItemCard"
import Form from "../../components/Form"
import List from "../../components/List"
import AdditionalItemFilters from "./components/AdditionalItemFilters"
import Header from "./components/Header"
import OrderSideBar from "./OrderSideBar/index.web"
import LoadingModal from "./OrderSideBar/LoadingModal"
import updateObservation from "./OrderSideBar/utils/updateObservation"
import * as S from "./styles.web"
import handleConfirm from "./utils/handleConfirm"

export default function OrderComposerScreen({ route, navigation }) {

  const { t } = useTranslation()
  const screenProps = route.params
  const dispatch = useDispatch()

  const [controller] = useState(route.params.controller)
  const [searchText, setSearchText] = useState("")
  const [applyFilters, setApplyFilters] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [update, setUpdate] = useState(false)

  controller.navigation = navigation

  const handleReturn = async () => {
    if (screenProps.collectionName === "T_MOV_ITE") navigation.navigate(controller.OrderComposerController.tabName, { screen: "Products" })
    else if (screenProps.collectionName === "T_REC_ITE") navigation.navigate(controller.OrderComposerController.tabName, { screen: "Order Summary" })
    else {
      if (controller.models().T_MOV_HDR.ID) dispatch(populateProducts(controller.models().T_MOV_ITE))
      await controller.startChangeScreen("cancel", setIsLoading, navigation)
    }
  }

  const getCard = () => {
    if (["T_REC_ITE", "T_MOV_ITE"].includes(screenProps.collectionName)) return DynamicItemCard
    else return DynamicCard
  }

  useFocusEffect(useCallback(() => {
    controller.updateList = setUpdate
    if (controller.models().T_MOV_ITE?.length > 0) dispatch(populateProducts(controller.models().T_MOV_ITE))
    if (screenProps.collectionName === "T_SET_TNS") dispatch(resetState())
  }, []))

  const canUpdate = hasPermissionToUpdate(controller.models())

  const showConfirmButton = ["T_REC_ITE"].includes(screenProps.collectionName) || screenProps.collectionName.includes("TAB_")
  const showReturnButton = ["T_REC_ITE", "T_MOV_ITE"].includes(screenProps.collectionName) && controller.models().T_MOV_HDR?.ID
  const showAdditionalItemFilters = ["T_REC_ITE"].includes(screenProps.collectionName)

  if (isLoading) return <LoadingModal isLoading={isLoading} />
  return (
    <S.Container>
      <Header
        screenProps={screenProps}
        controller={controller}
        setApplyFilters={setApplyFilters}
        setUpdate={setUpdate}
        setIsLoading={setIsLoading}
        navigation={navigation}
        searchText={searchText}
        setSearchText={setSearchText}
      />
      <S.Content>
        <S.ButtonsContainer style={{ justifyContent: showConfirmButton && !showReturnButton ? "flex-end" : "space-between" }}>
          {showReturnButton && <S.ReturnButton onClick={handleReturn}>
            <AntDesign name="arrowleft" size={20} color={defaultTheme["white"]} />
          </S.ReturnButton>}

          {showAdditionalItemFilters && <AdditionalItemFilters controller={controller} setUpdate={setUpdate} />}

          {showConfirmButton && <S.ConfirmButton onClick={() => {
            handleConfirm({
              controller,
              screenProps,
              dispatch,
              setIsLoading
            })
          }}>
            <S.IconContainer>
              <Feather name="check" size={16} color={defaultTheme["cyan-700"]} />
            </S.IconContainer>
            {t("Confirm")}
          </S.ConfirmButton>}
        </S.ButtonsContainer>

        {screenProps.collectionName.includes("TAB_") ?
          <S.FieldsView>
            <Form entity="T_MOV_HDR" models={controller.models()} staticFields={controller.formFields} hideTabs={true} />
          </S.FieldsView>
          :
          <S.ListContainer style={{ paddingTop: (showConfirmButton && !showReturnButton) || ["T_MOV_ITE", "T_REC_ITE"].includes(screenProps.collectionName) ? 40 : 0 }}>
            <List
              controller={controller}
              changeScreen={async i => {
                setIsLoading(true)
                await controller.startChangeScreen(i, setIsLoading)
              }}
              Card={getCard()}
              searchText={searchText}
              applyFilters={applyFilters}
              update={update}
            />
          </S.ListContainer>
        }

      </S.Content>

      <OrderSideBar
        controller={controller}
        jumpTo={screenName => {
          dispatch(removeAdditionalFilters())
          navigation.navigate(screenName)
        }}
      />
    </S.Container >
  )
}

