import styled from "styled-components"
import { defaultTheme } from "../../../../../../../../../../../../../styles/themes/default"

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 36px;
  padding: 0 20px;
  border-radius: 12px;
  cursor: grab;
  
  background-color: ${defaultTheme["white"]};
  border: .3px solid ${defaultTheme["gray-500"]};
  box-shadow: 0px 4px 8px #00000029;
`

export const Text = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: ${defaultTheme["cyan-700"]};
`
