import dbRequest from "../../../../../../../../../../services/dbRequest/dbRequest"

export default async () => {
  const formattedTables = await dbRequest
    .loadRecords("T_DTD_TAB")
    .execute(tablesData => {
      const validTables = tablesData.filter(({ T_DTD_TNS, ID, TYPE }) => {
        const doesTableHasTransactions = Boolean(T_DTD_TNS)
        const isNotDataDictionaryTable = !ID.includes("T_DTD")
        const isMasterTable = TYPE.VALUE !== "RegistrationDetail"

        return doesTableHasTransactions && isNotDataDictionaryTable && isMasterTable
      })

      return validTables.flatMap(table => {
        const { ID, T_DTD_TNS } = table

        return Object.entries(T_DTD_TNS)
          .map(([transactionId, transaction]) => ({
            ID,
            IDACCESS: transactionId,
            DESCRIPTION: transaction.DESCRIPTION,
            icon: "arrow-right"
          }))
      })
    })

  const functionsPromises = []

  for (const table of formattedTables) {
    const promise = dbRequest.loadRecords(`T_DTD_TAB/${table.ID}/T_DTD_FNC`).execute()
    functionsPromises.push(promise)
  }

  const functions = await Promise.all(functionsPromises)

  formattedTables.forEach((item, index) => {
    item.T_DTD_FNC = functions[index] ?? []
  })

  return formattedTables
}
