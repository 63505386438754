import { MaskedTextBoxComponent } from "@syncfusion/ej2-react-inputs"
import styled from "styled-components"
import { defaultTheme } from "../../../../../../styles/themes/default"

export const Container = styled.div``

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

interface TitleProps {
  isRequired: boolean
}

export const Title = styled.p<TitleProps>`
  color: ${defaultTheme["cyan-800"]};
  font-size: ${defaultTheme.formInput.fontSize}px;
  font-weight: ${({ isRequired }) => isRequired ? "bold" : "normal"};
  line-height: 20px;

  &::after {
    content: "*";
    color: ${defaultTheme["cyan-800"]};
    display: ${({ isRequired }) => isRequired ? "inline" : "none"};
  }
`

interface InputProps {
  isEditable: boolean
  width: number
}

export const Input = styled.input<InputProps>`
  background-color: ${({ isEditable }) => defaultTheme[isEditable ? "white" : "gray-200"]};
  color: ${defaultTheme["gray-700"]};
  font-size: ${defaultTheme.formInput.fontSize}px;
  width: ${({ width }) => width}px;
  border: 1px solid ${defaultTheme["gray-300"]};
  border-radius: 10px;
  padding: 0 10px;
  height: ${defaultTheme.formInput.height}px;
  outline: none;

  ::placeholder {
    color: ${defaultTheme["gray-300"]};
  }
`

export const MaskedInput = styled(MaskedTextBoxComponent) <InputProps>`
  background-color: ${({ isEditable }) => defaultTheme[isEditable ? "white" : "gray-200"]} !important;
  color: ${defaultTheme["gray-700"]} !important;
  width: ${({ width }) => width}px;
  font-size: ${defaultTheme.formInput.fontSize}px !important;
  border: 1px solid ${defaultTheme["gray-300"]} !important;
  border-radius: 10px !important;
  padding: 0 10px !important;
  height: ${defaultTheme.formInput.height}px !important;
  outline: none !important;

  ::placeholder {
    color: ${defaultTheme["gray-300"]};
  }
`
