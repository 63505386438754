import React from "react"
import { useTranslation } from "react-i18next"
import useAppSelector from "../../../../../redux/hooks/useAppSelector"
import * as S from "./styles"
import formatNumber from "./utils/formatNumber"

export default function OrderStatus({ models, entity = "T_MOV_HDR" }) {
  const { t } = useTranslation()

  const totalizerValues = useAppSelector(({ orderReducer }) => orderReducer.totalizerValues)

  return (
    <S.Container>
      <S.Title>{t("Order Information")}</S.Title>

      <S.Content>
        <S.FieldView style={{ width: entity !== "T_MOV_HDR" && "80%" }}>
          <S.Key>ID</S.Key>
          <S.Value title={models[entity].ID ?? "--"}>{models[entity].ID ?? "--"}</S.Value>
        </S.FieldView>

        {entity === "T_MOV_HDR" &&
          <>
            <S.FieldView>
              <S.Key>{t("Status")}</S.Key>
              <S.Value>{t(models[entity].STATUS.LABEL)}</S.Value>
            </S.FieldView>

            <S.FieldView>
              <S.Key>{t("Qt. Products")}: </S.Key>
              <S.Value>{totalizerValues.totalQuantity ?? 0}</S.Value>
            </S.FieldView>

            <S.FieldView>
              <S.Key>{t("Weight")}: </S.Key>
              <S.Value>{totalizerValues.totalWeight?.toFixed(3) ?? 0}</S.Value>
            </S.FieldView>

            <S.FieldView>
              <S.Key>{t("Shipping cost")}:</S.Key>
              <S.Value>R$ {formatNumber(models[entity].SHIPPINGVALUE ?? 0)}</S.Value>
            </S.FieldView>

            <S.FieldView>
              <S.Key>{t("Total")}:</S.Key>
              <S.Value>R$ {formatNumber(totalizerValues.totalAmount ?? 0)}</S.Value>
            </S.FieldView>
          </>
        }
      </S.Content>

    </S.Container>
  )
}
