import dbRequest from "../../../../../../../services/dbRequest/dbRequest"

export default async ({ entityName, models }) => {
  const data = models._REGISTRATION_DATA

  const { masterTable } = data[entityName]

  const entityPath = masterTable ? data[masterTable].detailTabsPath[entityName] : entityName

  return await dbRequest.loadRecords(entityPath).execute()
}
