import React from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet } from "react-native"
import globalStyles from "../../../../../../../../../../../../../global/globalStyles"
import * as S from "./styles"

export default function RenderList({ items, callback, permissions, transactionId }) {

  const { t } = useTranslation()

  const handlePress = (item, index) => {
    const aux = [...items]
    const newValue = !aux[index].isMarked
    aux[index].isMarked = newValue

    if (!permissions[transactionId])
      permissions[transactionId] = {}
    permissions[transactionId][item.ID] = newValue

    callback(aux)
  }

  return items.map((item, index) => (
    <S.Row key={index}>
      <S.CheckBox onPress={() => handlePress(item, index)} style={item.isMarked && styles.showCheck}>
        <S.CheckIcon name='check' style={!item.isMarked && { display: "none" }} />
      </S.CheckBox>

      <S.CheckText>{t(item.DESCRIPTION)}</S.CheckText>
    </S.Row>
  ))
}

const styles = StyleSheet.create({
  showCheck: {
    backgroundColor: globalStyles.colors.secondary,
    borderWidth: 0
  }
})
