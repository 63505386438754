import {
  Aggregate,
  ColumnChooser,
  ColumnMenu,
  ExcelExport,
  Filter,
  Page,
  Reorder,
  Resize,
  Selection,
  Sort,
  Toolbar
} from "@syncfusion/ej2-react-treegrid"

const services = [
  Aggregate,
  ColumnChooser,
  ColumnMenu,
  ExcelExport,
  Filter,
  Page,
  Reorder,
  Resize,
  Selection,
  Sort,
  Toolbar
]

export default services
