import styled from "styled-components"

export const Container = styled.div`
  align-items: center;
  align-self: center;
  background-color: ${({ theme }) => theme["white"]};
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  padding: 0.625rem 1rem;
  width: 30%;
`

export const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const Title = styled.p`
  color: ${({ theme }) => theme["gray-700"]};
  font-size: 18px;
  font-weight: 600;
`

export const CloseButton = styled.button`
  background-color: transparent;
  border-radius: 4px;
  padding: 1px;

  &:hover {
    box-shadow: 2px 2px 5px #00000029;
    scale: 1.02;
  }
`

export const SendButton = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme["cyan-300"]};
  border-radius: 15px;
  color: ${({ theme }) => theme["white"]};
  display: flex;
  font-size: 0.875rem;
  font-weight: bold;
  gap: 0.5rem;
  height: 2.5rem;
  justify-content: center;
  width: 25%;

  &:hover {
    scale: 1.05;
    transition: 0.2s ease-in;
  }
`
