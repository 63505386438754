import { Feather } from "@expo/vector-icons"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import LoginData from "../../../../../models/loginData"
import { removeAdditionalFilters } from "../../../../../redux/redux-reducers/order-reducer"
import { defaultTheme } from "../../../../../styles/themes/default"
import NavigationPathViewer from "../../NavigationPathViewer/index.web"
import handleCancelOrder from "../../OrderSideBar/utils/handleCancelOrder"
import handleConfirm from "../../utils/handleConfirm"
import * as S from "./styles"
import handleApplyFilters from "./utils/handleApplyFilters"

export default function Header({ screenProps, controller, setIsLoading, navigation, searchText, setSearchText }) {

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { name, screenOrder, collectionName } = screenProps
  const { permissions } = controller.models()._REGISTRATION_DATA.T_MOV_HDR

  const isSystemAdmin = LoginData.getLoginData().ADMIN
  const hasPermissionToCreate = permissions.CREATE
  const hasPermissionToUpdate = permissions.UPDATE

  const isSearchInputDisable = collectionName === "T_MOV_ITE" || collectionName.includes("TAB_")

  const isNewOrderButtonDisable = name === "Transaction" && (!hasPermissionToCreate || !isSystemAdmin)

  const validateConfirmOrderButton = () => {
    if (controller?.isLastTab) return false
    if (!["T_REC_ITE", "T_MOV_ITE"].includes(collectionName))
      return true

    const isOrderClosed = controller.models().T_MOV_HDR.STATUS.VALUE === "C"
    if (isOrderClosed) return true

    return !isSystemAdmin && !hasPermissionToCreate && !hasPermissionToUpdate
  }

  const isConfirmOrderButtonDisable = validateConfirmOrderButton()

  return (
    <S.Container>
      <S.Content>
        <S.TitleContainer>
          {`${screenOrder >= 0 ? `${screenOrder + 1}. ` : ""}`} {t(name)}
        </S.TitleContainer>

        <S.InputContainer>
          <S.TextInput
            disabled={isSearchInputDisable}
            isDisabledSearchInput={isSearchInputDisable}
            placeholder={t("Enter search")}
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value)
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (!["T_MOV_ITE", "T_REC_ITE"].includes(controller.listEntityName())) {
                  handleApplyFilters({
                    controller,
                    setIsLoading
                  })
                }
              }
            }}
            autoFocus
          />
          <S.ClearTextButton onClick={() => setSearchText("")}>
            <Feather name={searchText ? "x" : "search"} size={18} color={defaultTheme["gray-500"]} />
          </S.ClearTextButton>
        </S.InputContainer>

        <S.ButtonsContainer>
          <S.Button
            isDisable={isConfirmOrderButtonDisable}
            disabled={isConfirmOrderButtonDisable}
            onClick={async () => await handleConfirm({
              controller,
              screenProps,
              dispatch,
              setIsLoading
            })}>
            <S.IconContainer isDisable={isConfirmOrderButtonDisable}>
              <Feather name="check" size={16} color="white" />
            </S.IconContainer>
            {t("Finalize document")}
          </S.Button>
          <S.Button
            disabled={isNewOrderButtonDisable}
            isDisable={isNewOrderButtonDisable}
            onClick={() => handleCancelOrder(navigation, controller)}
          >
            <S.IconContainer
              isDisable={isNewOrderButtonDisable}>
              <Feather name="plus" size={16} color="white" />
            </S.IconContainer>
            {t("New Order")}
          </S.Button>
        </S.ButtonsContainer>
      </S.Content>

      <NavigationPathViewer
        navigationScreens={controller.OrderComposerController.orderPrototype}
        models={controller.models()}
        jumpTo={screenName => {
          dispatch(removeAdditionalFilters())
          navigation.navigate(screenName)
        }}
        focusScreenName={name}
      />
    </S.Container>
  )
}

