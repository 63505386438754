import React, { useState } from "react"
import { Dimensions, View } from "react-native"
import { RenderHTML } from "react-native-render-html"
import globalStyles from "../../../../../global/globalStyles"
import ModalWrapper from "../../../../../views/components/Modal"
import ExpandableButton from "../../ExpandableButton"
import * as S from "../styles.web"
import useConstructHTML from "./hooks/useConstructHTML"

export default function LabelChart({ models, chartData, entityName, chartId }) {

  const [isVisible, setIsVisible] = useState(false)

  const props = {
    models,
    chartData,
    entityName,
    chartId
  }

  return (
    <S.Container>
      <S.ButtonsView>
        <ExpandableButton isVisible={isVisible} onPress={() => setIsVisible(true)} />
      </S.ButtonsView>

      <RenderHTML contentWidth={Dimensions.get("window").width} source={useConstructHTML(props)} />

      <ModalWrapper onBackdropPress={() => setIsVisible(false)} isVisible={isVisible}>
        <View style={{
          flex: 1,
          backgroundColor: globalStyles.colors.tertiary
        }}>
          <RenderHTML contentWidth={Dimensions.get("window").width} source={useConstructHTML(props)} />
        </View>
      </ModalWrapper>
    </S.Container>
  )
}
