import React, { useState } from "react"
import { useContext } from "react"
import Panel from "../../../../components/Panel"
import Table from "../../../../components/Table"
import { ControllerContext } from "../../contexts/ControllerProvider"
import { ModalsContext } from "../../contexts/ModalsProvider"
import { UpdateContext } from "../../contexts/UpdateProvider"
import CreateTableModal from "../Modals/CreateTable"
import * as S from "../styles"
import handleDelete from "./utils/handleDelete"
import handleTableSelect from "./utils/handleTableSelect"

export default function TableArea() {
  const { controller, models } = useContext(ControllerContext)
  const { modalsVisible, openModal } = useContext(ModalsContext)
  const { updateTablesTable, setUpdateTablesTable, setUpdateFieldsTable } = useContext(UpdateContext)

  const [searchText, setSearchText] = useState("")
  const [recordsAmount, setRecordsAmount] = useState(null)

  const functions = {
    insertRecord: () => {
      models.T_DTD_TAB.reset()
      models.T_DTD_FLD.reset()
      openModal("table")
    },
    deleteRecord: () => { handleDelete(controller, setSearchText, setUpdateTablesTable) }
  }

  const commonProps = {
    entityName: "T_DTD_TAB",
    controller,
    viewType: "readOnly",
    searchText,
    models
  }

  return (
    <S.Container style={{ flex: 1.7 }}>
      <Panel {...commonProps} functions={functions} recordsAmount={recordsAmount} setSearchText={setSearchText} />

      <Table
        {...commonProps}
        screenBuilder={true}
        updateTable={updateTablesTable}
        setRecordsAmount={setRecordsAmount}
        selectedCallback={obj => { handleTableSelect(obj, models, setUpdateFieldsTable) }}
      />

      {modalsVisible.table && <CreateTableModal />}
    </S.Container>
  )
}
