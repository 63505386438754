import evaluateFunction from "../../../../../../../services/evaluateFunction"

export default async (field, models) => {
  const { T_DTD_ATR, T_DTD_OPT } = field

  if (T_DTD_ATR.CUSTOMLIST) {
    const customizedList = await evaluateFunction(T_DTD_ATR.CUSTOMLIST, models)
    return getSortedOptionsArray(customizedList)
  }

  return getSortedOptionsArray(T_DTD_OPT)
}

const getSortedOptionsArray = optionsObj => Object.entries(optionsObj ?? {})
  .map(([id, data]) => ({
    VALUE: id,
    ...data
  }))
  .sort((a, b) => a.ORDERBY - b.ORDERBY)
