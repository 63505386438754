import styled from "styled-components"
import { defaultTheme } from "../../../../../../styles/themes/default"

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 28px;
  border: .8px solid ${defaultTheme["gray-500"]};
  border-radius: 6px;
  background-color: ${defaultTheme["white"]};
`

export const SelectedValue = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  align-items: center;
  padding-left: 20px;
  font-size: 12px;
  font-weight: 500;
  color: ${defaultTheme["gray-700"]};
  cursor: pointer;
`

export const DropdownList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  width: 80px;
  list-style: none;
  margin: 0;
  padding: 0;
  border-radius: 6px;
  border: .8px solid ${defaultTheme["gray-500"]};
  background-color: ${defaultTheme["white"]};
`

export const DropdownListItem = styled.li`
  padding: 7px 0 7px 18px;
  font-size: 12px;
  color: ${defaultTheme["gray-700"]};
  cursor: pointer;

  &:hover {
    background-color: ${defaultTheme["gray-100"]};
  }
`
