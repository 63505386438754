import getTableFields from "../../../../../../services/registration/getTableFields"

export default async ({ ID }) => {
  const fields = await getTableFields({ entityName: ID })

  const fieldToRender = fields.filter(({ FIELDTYPE }) => FIELDTYPE.VALUE !== "E")

  const sortedFields = fieldToRender.sort((a, b) => a.T_DTD_ATR.ORDERBY - b.T_DTD_ATR.ORDERBY)

  return sortedFields.map(field => {
    return {
      ...field,
      FIELDESCRIPTION: field.T_DTD_ATR.FIELDDESCRIPTION
    }
  })
}
