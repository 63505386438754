import { MaterialIcons } from "@expo/vector-icons"
import styled from "styled-components"
import { defaultTheme } from "../../../../../../../../../../../styles/themes/default"

export const Container = styled.div`
  background-color: ${defaultTheme["white"]};
  border: 1px solid ${defaultTheme["gray-200"]};
  border-radius: 4px;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: auto;
  max-height: 80vh;
  overflow-y: scroll;
  width: 100%;
`

export const LoadingContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
`

interface ButtonProps {
  index: number
}

export const Button = styled.button<ButtonProps>`
  align-items: center;
  background-color: ${({ index }) => defaultTheme[index % 2 ? "white" : "gray-400"]};
  display: flex;
  gap: 4px;
  width: 100%;

  &:active {
    opacity: 0.7;
  }

  span {
    color: ${defaultTheme["cyan-800"]};
    font-size: 14px;
    font-weight: bold;
  }
`

export const Icon = styled(MaterialIcons)
  .attrs({
    color: defaultTheme["cyan-800"],
    size: 26
  })``

