import { FontAwesome5, Entypo } from "@expo/vector-icons"
import styled from "rn-css"
import globalStyles from '../../../../../../../../../../global/globalStyles'
import { Dropdown } from "react-native-element-dropdown"

export const Container = styled.View`
  background-color: #fff;
  padding: 4px 6px;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 30px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const Text = styled.Text`
  font-size: 12px;
  color: #2e2e2e;
`

export const ButtonsView = styled.View`
  width: 70%;
  flex-direction: row;
  align-items: center;
  height: 100%;
  gap: 5px;
`

export const DeleteButton = styled.TouchableOpacity``

export const DeleteIcon = styled(FontAwesome5)`
  color: ${globalStyles.colors.removeButton};
  font-size: 14px;
`

export const ListInput = styled(Dropdown).attrs(({containerWidth}) => ({
  maxHeight: 300,
  activeColor: "transparent",
  placeholderStyle: {
    textAlign: "center",
    fontSize: 12,
    color: "#2e2e2e"
  },
  iconColor: "#3498DB",
  iconStyle: { cursor: "pointer" },
  containerStyle: {
    width: containerWidth || 220,
    borderRadius: 3
  },
  searchPlaceholder: "Search",
  inputSearchStyle: {
    height: 30,
    width: "94%",
    fontSize: 12,
    color: "#2e2e2e",
    borderRadius: 2,
    outlineWidth: 0
  },
  selectedTextStyle: {
    color: "#2e2e2e",
    width: "100%",
    textAlign: "center",
    fontSize: 12,
  },
  selectedTextProps: {
    numberOfLines: 1
  }
}))`
  width: 150px;
  height: 24px;
  color: #2e2e2e;
  font-size: 12px;
  border-radius: 4px;
  padding: 6px;
  background-color: ${globalStyles.colors.tertiary};
  outline-width: 0;
`