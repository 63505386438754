import styled from "styled-components/native"

export const Button = styled.Pressable`
  width: ${(100 / 13)}%;
  height: ${(100 / 13)}%;
  position: absolute;
  top:${({ index }) => Math.floor(index / 12) * 8.4}%;
  left:${({ index }) => (index % 12) * 8.44}%;
  align-items: center;
  justify-content: center;
  background-color: white;
`

export const Title = styled.Text`
  color: #fff;
  font-size: 12px;
  font-weight: 500;
`

