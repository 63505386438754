import { useEffect, useRef, useState } from "react"
import LoginData from "../models/loginData"
import Navigation from "../models/navigation"
import showAlert from "./showAlert"

const useInactivityTimer = () => {
  const { MAXINACTIVITYTIME } = LoginData.getLoginData() // in minutes
  const maxInactivityTimeInMilliseconds = MAXINACTIVITYTIME * 60 * 1000

  const [isTabActive, setIsTabActive] = useState(!document.hidden)
  const timeoutRef = useRef(null)
  const logTimeoutRef = useRef(null)
  const lastActivityRef = useRef(Date.now())

  const resetTimer = () => {
    clearTimeout(timeoutRef.current)
    clearTimeout(logTimeoutRef.current)

    if (!isTabActive) return

    logTimeoutRef.current = setTimeout(() => {
      showAlert({
        text: "You will be disconnected in",
        title: "Inactivity detected",
        type: "timer",
        initialValue: 10,
        direction: "down",
        titleType: "warning",
        timeout: 10
      })
    }, maxInactivityTimeInMilliseconds - 10000)

    timeoutRef.current = setTimeout(() => {
      const timeSinceLastActivity = Date.now() - lastActivityRef.current

      if (timeSinceLastActivity >= maxInactivityTimeInMilliseconds) {
        const navigation = Navigation.getNavigation()

        showAlert({
          text: "You have been disconnected due to inactivity",
          title: "Session expired",
          titleType: "error"
        })

        LoginData.clear()
        navigation.navigate("Login")
        Navigation.closeTabs()
      }
    }, maxInactivityTimeInMilliseconds)
  }

  const handleActivity = () => {
    lastActivityRef.current = Date.now()
    resetTimer()
  }

  const handleVisibilityChange = () => {
    setIsTabActive(!document.hidden)
    if (!document.hidden)
      resetTimer()
  }

  useEffect(() => {
    if (!MAXINACTIVITYTIME) return

    resetTimer()
    window.addEventListener("mousemove", handleActivity)
    window.addEventListener("mousedown", handleActivity)
    window.addEventListener("keydown", handleActivity)
    window.addEventListener("scroll", handleActivity)
    window.addEventListener("touchstart", handleActivity)
    document.addEventListener("visibilitychange", handleVisibilityChange)

    return () => {
      clearTimeout(timeoutRef.current)
      clearTimeout(logTimeoutRef.current)
      window.removeEventListener("mousemove", handleActivity)
      window.removeEventListener("mousedown", handleActivity)
      window.removeEventListener("keydown", handleActivity)
      window.removeEventListener("scroll", handleActivity)
      window.removeEventListener("touchstart", handleActivity)
      document.removeEventListener("visibilitychange", handleVisibilityChange)
    }
  }, [MAXINACTIVITYTIME])

  return resetTimer
}

export default useInactivityTimer
