import models from "../models"
import BaseController from "./BaseController"

export default class DashBoardController extends BaseController {

  constructor() {
    super()
    this.modelsTemplate = models.getClone()
    this.models = () => this.modelsTemplate
    this.screenName = () => "Dashboards"
  }
}
