import React from "react"
import { useTranslation } from "react-i18next"
import AccessibilityBox from "../AccessibilityBox"
import useInputManager from "../hooks/useInputManager"
import * as S from "./styles.web"

export default function Currency({ models, field, updateForm, setUpdateForm }) {
  const { t } = useTranslation()

  const { EDITABLE, REQUIRED, FIELDDESCRIPTION, HELPTEXT, DECIMAL = 2, ONFOCUS } = field.T_DTD_ATR

  const { state, setState, onBlur } = useInputManager({
    models,
    field,
    updateForm,
    setUpdateForm
  })

  const isNegative = parseFloat(state) < 0

  return (
    <S.Container>
      <S.Row>
        <S.Title isRequired={REQUIRED}>{t(FIELDDESCRIPTION)}</S.Title>

        <AccessibilityBox text={HELPTEXT} />
      </S.Row>

      <S.Input
        value={state}
        placeholder={t(FIELDDESCRIPTION)}
        precision={DECIMAL}
        isEditable={EDITABLE}
        isNegative={isNegative}
        onBlur={onBlur}
        autoFocus={ONFOCUS}
        onChangeValue={setState}
      />
    </S.Container>
  )
}
