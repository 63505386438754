import models from "../models"
import LoginData from "../models/loginData"
import Permissions from "../models/permissions"
import dbRequest from "../services/dbRequest/dbRequest"
import applyMobileFilters from "../services/menuConstructor/utils/applyMobileFilters"
import createSubmenus from "../services/menuConstructor/utils/createSubmenus"
import getLauncherScreens from "../services/menuConstructor/utils/getLauncherScreens"
import getMenuMaxDepth from "../services/menuConstructor/utils/getMenuMaxDepth"
import getUserPermissions from "../services/menuConstructor/utils/getUserPermissions"
import removeMenusWithoutSubmenus from "../services/menuConstructor/utils/removeMenusWithoutSubmenus"
import getTableFields from "../services/registration/getTableFields"
import OrderComposerController from "./OrderComposerController"

export default class NavigationController {

  constructor() {
    this.modelsTemplate = models.getClone()
    this.models = () => this.modelsTemplate
    this.options = []
  }

  async getInitialTransactionParams() {
    const fields = await getTableFields({ entityName: "T_SET_TNS" })
    const sortedFields = fields.sort((a, b) => a.T_DTD_ATR.CARDORDERBY - b.T_DTD_ATR.CARDORDERBY)

    return {
      id: 0,
      name: "Transaction",
      collectionName: "T_SET_TNS",
      models: this.models,
      HDRfield: "TNSID",
      filters: null,
      entityFields: sortedFields
    }
  }

  async getScreens(setScreens, setIsLoading, routine = null) {

    const isSystemAdmin = LoginData.getLoginData().ADMIN
    const userPermissions = isSystemAdmin ? {} : await getUserPermissions()

    const recordsMenu = await dbRequest
      .loadRecords("T_DTD_NAV_NEW")
      .execute()

    const maxDepth = getMenuMaxDepth(recordsMenu)
    const formattedMenu = createSubmenus({
      menuData: recordsMenu,
      currentDepth: 0,
      maxDepth,
      parentCode: 0
    })

    const processedButtons = await getLauncherScreens({
      menuData: formattedMenu,
      userPermissions,
      routine
    })

    applyMobileFilters(processedButtons)

    const clearedButtons = removeMenusWithoutSubmenus(processedButtons)

    this.createRecordsScreen(clearedButtons, setScreens)

    this.options = clearedButtons
    setIsLoading(false)
  }

  async createRecordsScreen(navigationData, setScreens) {
    let screens = []

    const findLaunchers = (menu) => {
      menu.SUB_MENUS.forEach(subMenu => {
        if (subMenu.isLauncher) {
          screens.push(subMenu)
        }

        if (subMenu.SUB_MENUS && subMenu.SUB_MENUS.length > 0) {
          findLaunchers(subMenu)
        }
      })
    }

    navigationData.forEach(menu => {
      findLaunchers(menu)
    })

    setScreens(screens)
  }

  async removeUnauthorizedButtons(buttons, permissionsObj) {
    return buttons.filter(button => {
      const transactionId = button.TRANSACTION
      if (!permissionsObj[transactionId]) return false
      if (permissionsObj[transactionId]?.READ) return true
      else return false
    })
  }

  async initializeOrderComposer(transaction) {
    let controller = null
    controller = new OrderComposerController()
    await controller.initialize()

    Permissions.setCurrentRoutine(transaction)

    return controller
  }
}
