import { FontAwesome } from "@expo/vector-icons"
import styled from "rn-css"
import globalStyles from "../../../../../../../global/globalStyles"

const Icon = styled(FontAwesome)`
  color: ${globalStyles.colors.primary};
  font-size: 18px;
`

export default Icon
