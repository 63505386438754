import dbRequest from "../../../../../../../../../../../../services/dbRequest/dbRequest"

const getTablesRequest = async () => {
  const tables = await dbRequest.loadRecords("T_DTD_TAB").execute() as any

  const tablesMenu = []

  for (const table of tables) {
    const { ID, TYPE, ENTITY } = table

    const isNotDataDictionaryTable = !ID.includes("T_DTD")
    const isNotDetailTable = TYPE.VALUE !== "RegistrationDetail"

    if (isNotDataDictionaryTable && isNotDetailTable)
      tablesMenu.push({
        ID,
        IDACCESS: ID,
        DESCRIPTION: ENTITY,
        icon: "arrow-right"
      })
  }

  await Promise.all(
    tablesMenu.map(async (table: { ID: string, T_DTD_FNC: any }) => {
      const functions = await dbRequest.loadRecords(`T_DTD_TAB/${table.ID}/T_DTD_FNC`).execute()
      table.T_DTD_FNC = functions ?? []
    })
  )

  return tablesMenu
}

export default getTablesRequest
