import RuntimeConfiguration from "../../../../models/runtimeConfiguration"
import dbRequest from "../../../../services/dbRequest/dbRequest"

export default async function getDashboardFields(fieldsPath) {
  const isAdminMode = RuntimeConfiguration.getDashboardBuilderAdminMode()
  try {
    const path = `${fieldsPath}/T_DTD_FLD`

    let fieldsRequest = dbRequest.loadRecords(path)

    if (isAdminMode) fieldsRequest = fieldsRequest.admin()

    const fields = await fieldsRequest.execute()

    for (const field of fields) {
      let attributesRequest = dbRequest.loadRecords(`${path}/${field.ID}/T_DTD_ATR`)

      if (isAdminMode) attributesRequest = attributesRequest.admin()

      const attributes = await attributesRequest.execute()

      field.T_DTD_ATR = {}
      field.TABLENAME = "DASHBOARD"

      for (const attribute of attributes) {
        const { ID, VALUE } = attribute
        field.T_DTD_ATR[ID] = VALUE
      }

      if (field.FIELDTYPE.VALUE === "L" && !field.T_DTD_ATR.CUSTOMLIST) {
        field.T_DTD_OPT = {}

        let optionsRequest = dbRequest.loadRecords(`${path}/${field.ID}/T_DTD_OPT`)

        if (isAdminMode) optionsRequest = optionsRequest.admin()

        const options = await optionsRequest.execute()

        field.T_DTD_OPT = options.map(item => {
          return {
            VALUE: item.ID,
            LABEL: item.VALUE.LABEL,
            DEFAULTVALUE: item.VALUE.DEFAULTVALUE
          }
        })
      }
    }

    return fields
  } catch (error) {
    console.error("Error getting dashboard fields:", error)
    throw error
  }
}
