import React, { useEffect, useState } from "react"
import RenderPermissions from "../.."
import { Button, Icon } from "../../../../styles"
import Container from "./styles"
import getDetailTabs from "./utils/getDetailTabs"
import { useTranslation } from "react-i18next"

export default function RenderDetailTabsList({ masterTab, permissions, models, entity }) {
  const { t } = useTranslation()

  const [detailTabs, setDetailTabs] = useState([])

  const handlePress = (index) => {
    setDetailTabs(state => {
      const newState = [...state]
      newState[index]["open"] = !Boolean(newState[index]["open"])
      newState[index]["icon"] = newState[index]["icon"] === "arrow-drop-down"
        ? "arrow-right"
        : "arrow-drop-down"

      return newState
    })
  }

  useEffect(() => { getDetailTabs(masterTab.ID).then(setDetailTabs) }, [])

  return detailTabs.map((item, index) => {
    if (!item["icon"]) item["icon"] = "arrow-right"

    return (
      <Container key={index}>
        <Button index={index} onClick={() => { handlePress(index) }}>
          <Icon name={item["icon"]} />

          <span>{t(item.ENTITY)}</span>
        </Button>

        {item["open"] && (
          <RenderPermissions
            item={item}
            permissions={permissions}
            models={models}
            entity={entity}
          />
        )}
      </Container>
    )
  })
}
