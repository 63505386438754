import recordIdExists from "../../../../../../../../../services/registration/recordIdExists"
import showAlert from "../../../../../../../../../services/showAlert"

export default async function existingIdValidation({ models, entity, detailTabPath }) {
  const tableData = models._REGISTRATION_DATA[entity]

  if (!tableData.manualID && tableData.operation !== "UPD") {
    const existingId = await recordIdExists(detailTabPath, models[entity].ID)

    if (existingId) {
      models[entity].ID = null

      showAlert({
        text: "Record ID already exists",
        title: "Failed to save",
        titleType: "error"
      })

      return true
    }
  }
}
