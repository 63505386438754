import showAlert from "../../../../../../../services/showAlert"

export default async ({ fields, closeModal, controller }) => {
  if (fields.length === 0) return closeModal("orderFields")

  showAlert({
    text: "Do you want to save?",
    type: "confirm",
    title: "Fields ordering",
    handleConfirm: async () => {
      await controller.saveOrderedFields({
        fields,
        attribute: "ORDERBY"
      })

      await controller.saveOrderedFields({
        fields,
        attribute: "GRIDORDERBY"
      })

      closeModal("orderFields")

      showAlert({
        text: "Data saved successfully",
        title: "Fields ordering",
        titleType: "success"
      })
    }
  })
}
