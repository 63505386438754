import { useEffect, useState } from "react"
import getListContent from "../Selection/utils/getListContent"
import getOrderedOptions from "../Selection/utils/getOrderedOptions"
import handleExpression from "../Selection/utils/handleExpression"

export default function useSelection({ models, field, updateForm }) {

  const { ID, TABLENAME, T_DTD_ATR, FIELDTYPE } = field

  const [options, setOptions] = useState([])

  useEffect(() => {
    async function populateOptions() {
      const orderedOptions = await getOrderedOptions(field, models)

      const params = {
        field,
        models,
        orderedOptions,
        setSelectedOption: () => null
      }

      if (T_DTD_ATR.EXPRESSION) await handleExpression(params)

      const listContent = getListContent(params)
      setOptions(listContent)
      if (listContent.length === 1) models[TABLENAME][ID] = FIELDTYPE.VALUE === "O" ? [] : listContent[0]
    }

    populateOptions()
  }, [updateForm])

  return {
    options
  }
}
