/* eslint-disable import/prefer-default-export */
import { useState } from "react"

export const usePagination = (pageSize) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [hasMoreToLoad, setHasMoreToLoad] = useState(true)
  const [loadingMoreData, setLoadingMoreData] = useState(false)

  const paginate = (data) => data.slice(0, currentPage * pageSize)

  const loadMore = (allRecords, setRecords) => {
    if (!hasMoreToLoad || loadingMoreData) return

    setLoadingMoreData(true)

    const nextPage = currentPage + 1
    const startIndex = currentPage * pageSize
    const endIndex = nextPage * pageSize

    const newRecords = allRecords.slice(startIndex, endIndex)

    if (newRecords.length === 0) {
      setHasMoreToLoad(false) // Nenhum registro adicional disponível
    } else {
      setRecords((prev) => [...prev, ...newRecords])
      setCurrentPage(nextPage)
    }

    setLoadingMoreData(false)
  }

  const resetPagination = () => {
    setCurrentPage(1)
    setHasMoreToLoad(true)
    setLoadingMoreData(false)
  }

  return {
    paginate,
    loadMore,
    hasMoreToLoad,
    loadingMoreData,
    resetPagination // Função para resetar paginação
  }
}
