import { Entypo } from "@expo/vector-icons"
import React, { useState } from "react"
import evaluateFunction from "../../../../../../../services/evaluateFunction"
import showAlert from "../../../../../../../services/showAlert"
import * as S from "../../modalStyles"
import { htmlString } from "../utils/htmlString"

const ExpressionTextInput = ({ expression, setExpressionInForm }) => {

  const [expressionText, setExpressionText] = useState(expression || "")

  async function openNewTab(arrayToReplace) {
    if (!Array.isArray(arrayToReplace) || arrayToReplace.length === 0) {
      showAlert({
        titleType: "error",
        title: "Error running code.",
        text: "The returned value must be an array with at least one item."
      })
      return
    }

    var htmlWithArrayReplaced = htmlString.replace("REPLACE_ARRAY_HERE", JSON.stringify(arrayToReplace))

    var novaAba = window.open(htmlWithArrayReplaced)
    novaAba.document.open()
    novaAba.document.write(htmlWithArrayReplaced)
    novaAba.document.close()

    showAlert({
      titleType: "success",
      title: "Code executed successfully.",
      text: "The returned value was displayed in a new tab."
    })
  }

  return (
    <S.TextBoxInputArea>
      <div title='Execute Code'>
        <S.ExecuteButton onPress={async () => {
          try {
            let formattedExpressionText = expressionText
            const modelsArray = (expressionText.match(/models\.([^\s;]+)/g) || []).map(match => {
              return {
                name: match,
                field: match.split(".")[1].replace(/[^a-zA-Z0-9_]/g, "")
              }
            })

            if (modelsArray.length > 0) {
              for (const { field, name } of modelsArray) {
                const val = await new Promise((resolve) => {
                  const userValue = window.prompt(`Enter the value of ${field}`, "")
                  resolve(userValue)
                })
                formattedExpressionText = formattedExpressionText.replace(new RegExp(name, "g"), `'${val}'`)
              }
            }
            const response = await evaluateFunction(formattedExpressionText)
            openNewTab(response)
          } catch (error) {
            console.error(error)
            showAlert({
              titleType: "error",
              title: "Error running code.",
              text: "For more details, access the browser console (F12)."
            })
          }
        }}>
          <Entypo name="controller-play" size={18} color="#fff" />
          <S.TitleButton>Execute</S.TitleButton>
        </S.ExecuteButton>
      </div>
      <S.TextBoxInput
        value={expressionText}
        placeholder="Enter the expression in javascript"
        placeholderTextColor={"gray"}
        onChangeText={setExpressionText}
        multiline
        textAlignVertical="top"
        onBlur={() => setExpressionInForm(expressionText)}
      />
    </S.TextBoxInputArea >
  )
}

export default ExpressionTextInput
