import styled from "styled-components"
import { defaultTheme } from "../../../../../styles/themes/default"

export const Container = styled.div`
  background-color: ${defaultTheme["gray-200"]};
  height: 120px;
  max-height: 130px;
  padding: 26px 0 5px 40px;
  position: fixed;
  width: 100%;
  z-index: 300;
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

export const TitleContainer = styled.div`
  width: 20%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  font-size: 24px;
  font-weight: 500;
  color: ${defaultTheme["cyan-700"]};
`

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: auto;
  height: 28px;
`

interface TextInputProps {
  isDisabledSearchInput: boolean
}

export const TextInput = styled.input<TextInputProps>`
  width: 280px;
  height: 100%;

  border-radius: 10px;

  border: .8px solid ${defaultTheme["gray-400"]};
  background-color: ${({ isDisabledSearchInput }) => isDisabledSearchInput ? defaultTheme["gray-200"] : defaultTheme["white"]};

  color: ${defaultTheme["gray-700"]};
  font-size: 14px;

  padding-left: 10px;

  &:focus {
    outline: 2px solid ${defaultTheme["orange-500"]};
  }

  &::placeholder {
    color: ${defaultTheme["gray-300"]};
    font-size: 14px;
  }
`

export const ClearTextButton = styled.button`
  position: absolute;
  right: 10px;
  z-index: 10;
  background-color: transparent;
  border: none;
  cursor: pointer;
`

export const ButtonsContainer = styled.div`
  display: flex;
  margin-left: 20px;
  gap: 14px;
`

interface ButtonProps {
  isDisable: boolean
}

export const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 100%;
  padding: 4px 10px 4px 6px;
  background-color: ${defaultTheme["gray-100"]};
  border: none;
  border-radius: 17px;

  color: ${defaultTheme["gray-500"]};
  cursor: ${({ isDisable }) => isDisable ? "not-allowed" : "pointer"};
  &:active {
    background-color: ${defaultTheme["gray-200"]};
  }
`

export const IconContainer = styled.div<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background-color: ${({ isDisable }) => isDisable ? defaultTheme["gray-400"] : defaultTheme["cyan-300"]};
  border-radius: 50%;
`
