import styled from "rn-css"
import globalStyles from "../../../../../../../global/globalStyles"

const Container = styled.View`
  background-color: ${({ isSelected, index }) => isSelected
    ? globalStyles.colors.primary
    : index % 2 ? globalStyles.colors.gridRowColor : globalStyles.colors.tertiary
  };
  width: 250px;
  height: 25px;
  justify-content: center;
  align-items: center;
  border-right-width: 1px;
  border-right-color: ${({ isSelected }) => isSelected ? globalStyles.colors.tertiary : globalStyles.colors.primary + "60"};
`

export default Container
