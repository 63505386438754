import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import ModalWrapper from "../../../../../Modal"
import FileCard from "./components/FileCard/index.web"
import FileSelection from "./components/FileSelection"
import handleDelete from "./utils/handleDelete"
import * as S from "./styles.web"

export default function UploadModal({ modalVisible, setModalVisible, field, models }) {
  const { t } = useTranslation()

  const { ID, TABLENAME, T_DTD_ATR } = field
  const tableData = models._REGISTRATION_DATA[TABLENAME]

  const [filesPreview, setFilesPreview] = useState(tableData.files[ID] ?? models[TABLENAME][ID] ?? [])

  const getFormattedFiles = () => {
    return filesPreview.map(file => ({
      NAME: file.name || file.NAME,
      TYPE: file.type || file.TYPE,
      SIZE: file.size || file.SIZE,
      URL: file.URL || ""
    }))
  }

  const closeModal = () => {
    setModalVisible(false)
    tableData.files[ID] = [...filesPreview]
    models[TABLENAME][ID] = getFormattedFiles()
  }

  const allowMultipleFiles = T_DTD_ATR.MULTIPLEFILES
  const isFileListEmpty = filesPreview.length === 0

  const shouldSelectFile = allowMultipleFiles || isFileListEmpty

  const renderCard = (file, index) => (
    <FileCard
      key={index}
      index={index}
      file={file}
      handleDeleteFile={() => handleDelete({ index, callback: setFilesPreview })}
    />
  )

  return (
    <ModalWrapper isVisible={modalVisible}>
      <S.Container>
        <S.Title>{t("Change")} {t(T_DTD_ATR.FIELDDESCRIPTION)}</S.Title>

        <S.CloseButton onClick={closeModal}>
          <S.CloseIcon />
        </S.CloseButton>

        {shouldSelectFile && <FileSelection setFilesPreview={setFilesPreview} />}

        <S.SeparatorView>
          <S.SeparatorLine />
          <S.SeparatorText>{t("Files")}</S.SeparatorText>
          <S.SeparatorLine />
        </S.SeparatorView>

        <div style={{ overflowY: "scroll" }}>
          <S.FilesListContainer>
            {isFileListEmpty
              ? <S.PlaceholderList>{t("No files selected")}</S.PlaceholderList>
              : filesPreview.map(renderCard)
            }
          </S.FilesListContainer>
        </div>
      </S.Container>
    </ModalWrapper>
  )
}
