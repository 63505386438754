import dbRequest from "../../../../../../../services/dbRequest/dbRequest"

const getEntitiesList = async () => {
  const entitiesList = await dbRequest
    .loadRecords("T_DTD_TAB")
    .where("TYPE.VALUE", "==", "Registration")
    .execute()

  const formattedEntitiesList = entitiesList.filter((item) => item.T_DTD_TNS).map((item) => {
    return {
      ID: item.ID,
      TYPE: item.TYPE,
      ENTITY: item.ENTITY,
      TRANSACTION: Object.keys(item.T_DTD_TNS)[0]
    }
  })

  return formattedEntitiesList
}

export default getEntitiesList
