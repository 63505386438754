import styled from "styled-components"
import { defaultTheme } from "../../../styles/themes/default"

export const ContainerForm = styled.div`
  background-color: ${defaultTheme["gray-200"]};
  display: flex;
  flex: 1;
  flex-direction: column;
`

export const ContainerGrid = styled.div`
  background-color: ${defaultTheme["gray-200"]};
	box-sizing: border-box;
	height: 100%;
	padding-top: 6px;
	overflow: auto;
	width: 100%;
`

export const LoadingModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 80px;

  border-radius: 10px;

  background-color: ${defaultTheme["white"]};
`

