import styled from "styled-components"
import { defaultTheme } from "../../../../../../../../../../../../../styles/themes/default"

const Container = styled.div`
  background-color: ${defaultTheme["white"]};
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 5px 0;
`

export default Container
