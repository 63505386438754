export default ({ records, fields }) => {
  return records.map(record => {
    return fields.reduce((result, field) => {
      const { ID, DESCRIPTION } = field

      if (record[ID] !== null && record[ID] !== undefined)
        result[DESCRIPTION] = getValue(record[ID])

      return result
    }, {})
  })
}

function getValue(value) {
  switch (typeof value) {
    case "object": return value.LABEL ?? ""
    case "boolean": return value === true ? "S" : "N"
    default: return value
  }
}
