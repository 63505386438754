import styled from "styled-components"
import { defaultTheme } from "../../../../../styles/themes/default"

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`

export const TabsContainer = styled.div`
  display: flex;
  margin-bottom: 2px;
  overflow-x: auto;
  padding-left: 15px;
  background-color: ${defaultTheme["gray-200"]};
`

interface TabProps {
  isSelected: boolean
  index: number
}

export const Tab = styled.button<TabProps>`
  align-items: center;
  background-color: ${({ isSelected }) => defaultTheme[isSelected ? "white" : "gray-200"]};
  border-top-right-radius: 15px;
  box-shadow: 2px 0 5px ${defaultTheme["shadow"]};
  color: ${({ isSelected }) => defaultTheme[isSelected ? "cyan-700" : "gray-500"]};
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: 30px;
  margin-left: -2px;
  margin-top: 5px;
  min-width: 100px;
  padding: 0 10px;
  width: 100px;
  z-index: ${({ index }) => 100 - index};
`

export const TitleContainer = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const TabContent = styled.div`
  background-color: ${defaultTheme["white"]};
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  display: flex;
  flex: 1;
  overflow-y: auto;
  width: 100%;
`
