import { PayloadAction, createSlice } from "@reduxjs/toolkit"

type ScheduleState = {
  mainUpdate: boolean
  isVisible: boolean
  userSelected: number[] | null
  selectedDate: string | null
  viewMode: "schedule" | "calendar"
  modal: { isVisible: boolean }
}

const initialState: ScheduleState = {
  mainUpdate: false,
  isVisible: false,
  userSelected: null,
  selectedDate: new Date().toLocaleDateString("en-CA"),
  viewMode: "calendar",
  modal: { isVisible: false }
}

const scheduleSlice = createSlice({
  name: "scheduleReducer",
  initialState,
  reducers: {
    forceUpdate: (state) => { state.mainUpdate = !state.mainUpdate },
    setVisible: (state) => {
      state.isVisible = !state.isVisible
      state.userSelected = null
      state.viewMode = "calendar"
    },
    setModalVisible: ({ modal }) => { modal.isVisible = !modal.isVisible },
    changeViewMode: (state) => { state.viewMode = state.viewMode === "calendar" ? "schedule" : "calendar" },
    setUserSelected: (state, { payload }: PayloadAction<number[] | null>) => { state.userSelected = payload },
    setSelectedDate: (state, { payload }: PayloadAction<string | null>) => { state.selectedDate = payload }
  }
})

export const { forceUpdate, setVisible, setModalVisible, changeViewMode, setUserSelected, setSelectedDate } = scheduleSlice.actions
export const scheduleReducer = scheduleSlice.reducer
