/* eslint-disable no-prototype-builtins */
export default function generateTableData(chartSettings, dashboardData, chartParams, setChartValue, selectedRow) {

  const { series, DATAID, GROUPING, ACCUMULATOR, FILTER } = chartSettings

  const data = []

  let tableData = filterData(FILTER, DATAID, dashboardData, chartParams)

  tableData = dataGrouping(tableData, GROUPING, ACCUMULATOR)

  const fields = GROUPING.concat(ACCUMULATOR)
  const tableHeader = fields.map(field => field.FIELDDESCRIPTION)

  data.push(tableHeader)

  tableData.forEach(row => {
    const tableRow = []
    fields.forEach(field => tableRow.push(row[field.ID]))
    data.push(tableRow)
  })

  setChartValue(tableData)

  return data
}

const filterData = (filters = [], DATAID, dashboardData, chartParams) => {

  let data = dashboardData[DATAID]

  if (Object.keys(chartParams).length > 0) {

    const validFilters = filters.filter(filter => chartParams.hasOwnProperty(filter.FIELDDEST))

    data = data.filter(record => {
      return validFilters.every(filter => {
        const destField = filter.FIELDDEST
        const origField = filter.FIELDORIG
        return getField(record, origField) === chartParams[destField]
      })
    })
  }

  return data
}

const dataGrouping = (data, groupingFields, accumulatorsFields) => {

  let grouping = data.reduce((accumulator, item) => {
    const key = groupingFields.map(group => {
      if (group?.SUBFIELD) return getValueFromPath(item, group.ID)
      else return item[group.ID]
    }).join("_")

    if (!accumulator[key]) {
      accumulator[key] = {}
      accumulatorsFields.forEach(({ ID }) => {
        accumulator[key][ID] = 0
      })

      groupingFields.forEach(group => {
        if (group?.SUBFIELD) {
          const fieldKey = group.ID
          accumulator[key][fieldKey] = getValueFromPath(item, group.ID)
        } else accumulator[key][group.ID] = item[group.ID]
      })
    }
    accumulatorsFields.forEach(({ ID, SUBFIELD, FUNCTION }) => {

      let fieldValue = getField(item, ID, SUBFIELD)

      if (FUNCTION.VALUE === "count") accumulator[key][ID]++
      else if (FUNCTION.VALUE === "avg") {
        if (accumulator[key][`${ID}_count`] === undefined) {
          accumulator[key][`${ID}_sum`] = 0
          accumulator[key][`${ID}_count`] = 0
        }
        accumulator[key][`${ID}_sum`] += fieldValue
        accumulator[key][`${ID}_count`]++
      } else accumulator[key][ID] += fieldValue
    })

    return accumulator
  }, {})

  // Calcular a média para os grupos que têm média
  Object.values(grouping).forEach(group => {
    accumulatorsFields.forEach(({ ID, FUNCTION }) => {
      if (FUNCTION.VALUE === "avg") {
        if (group[`${ID}_count`] > 0) {
          group[ID] = group[`${ID}_sum`] / group[`${ID}_count`]
        } else {
          group[ID] = 0 // Defina como 0 se não houver itens para evitar divisão por zero
        }
        delete group[`${ID}_sum`]
        delete group[`${ID}_count`]
      }
    })
  })

  return Object.values(grouping)
}

const getField = (obj, id, subField) => {
  if (subField) return getValueFromPath(obj, id)
  else return obj[id]
}

const getValueFromPath = (obj, path) => {
  const keys = path.split("_")
  return keys.reduce((acc, key) => acc && acc[key], obj)
}
