import getTableFields from "../../../../../../../../../../../services/registration/getTableFields"

export default async models => {
  const tableId = models.T_DTD_TAB.ID
  const functionId = models.T_DTD_FNC.ID

  const fields = await getTableFields({
    entityName: tableId,
    isScreenBuilderRequest: true,
    collectionPath: `${tableId}/T_DTD_FNC/${functionId}`
  })

  const fieldToRender = fields.filter(({ FIELDTYPE }) => FIELDTYPE.VALUE !== "E")

  return fieldToRender.map(field => {
    return {
      ...field,
      FIELDESCRIPTION: field.T_DTD_ATR.FIELDDESCRIPTION
    }
  })
}
