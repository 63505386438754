import CurrencyInput from "react-native-currency-input"
import { TextMask } from "react-native-masked-text"
import styledRN from "rn-css"
import styled from "styled-components"
import { defaultTheme } from "../../../../../../styles/themes/default"

export const FieldContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 45px;
`

export const FieldTitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${defaultTheme["cyan-700"]};
  font-weight: lighter;
  font-size: 14px;
  margin-left: 5px;
  margin-bottom: 3px;
`

export const FieldValue = styled.div`
  padding: 5px 5px 5px 10px;
  font-size: 12px;
  color: ${defaultTheme["gray-700"]};
  background-color: ${defaultTheme["gray-200"]};
  outline: 1px solid ${defaultTheme["gray-400"]};
  border-radius: 6px;
`

export const FieldValueMask = styled(TextMask, FieldValue)`
`

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
`

/* export const CurrencyInput = styled.input`
  width: 100%;
  color: ${defaultTheme["gray-700"]};
  font-size: 12px;

  padding: 5px 5px 5px 30px;
  border: none;
  outline: 1px solid ${defaultTheme["gray-400"]};
  border-radius: 6px;
` */

export const CurrencyPrefix = styled.span`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  color: ${defaultTheme["gray-700"]};
`

export const CurrencyField = styledRN(CurrencyInput)`
  width: 100%;
  color: ${defaultTheme["gray-700"]};
  padding: 5px;
  border: none;
  outline: 1px solid ${defaultTheme["gray-400"]};
  font-size: 12px;
  border-radius: 6px;
`
