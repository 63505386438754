import { MaterialIcons } from "@expo/vector-icons"
import React, { useEffect, useState } from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { View } from "react-native"
import { useDispatch } from "react-redux"
import useAppSelector from "../../../../../../redux/hooks/useAppSelector"
import { closeModal } from "../../../../../../redux/redux-reducers/dashboardBuilder-reducer"
import { saveGadget } from "../../../../../../redux/redux-reducers/dashboardBuilder-reducer/utils/saveGadget"
import ModalWrapper from "../../../../../components/Modal"
import * as S from "../modalStyles"
import CollectionFieldsList from "./components/CollectionFieldsList"
import DestinyBinList from "./components/DestinyBinList"
import getQueryAndConnectionsEntities from "./utils/getQueryAndConnectionsEntities"

export default function EditGroupingModal() {

  const dispatch = useDispatch()

  const { name, isVisible, selectedQuery } = useAppSelector(({ dashboardBuilderReducer }) => dashboardBuilderReducer.modals.editGroupingModal)
  const { form } = useAppSelector(({ dashboardBuilderReducer }) => dashboardBuilderReducer.modals.gadgetModal)
  const { selectedGadget, selectedLevel, selectedDashboard } = useAppSelector(({ dashboardBuilderReducer }) => dashboardBuilderReducer.dashboardData)

  const [selectedCollection, setSelectedCollection] = useState(null)
  const [collections, setCollections] = useState([])
  const [collectionFields, setCollectionFields] = useState([])
  const [groupingFields, setGroupingFields] = useState([])
  const [accumulatorFields, setAccumulatorFields] = useState([])

  useEffect(() => {
    (async () => {
      setGroupingFields(form?.GROUPING?.length > 0 ? form?.GROUPING : [])
      setAccumulatorFields(form?.ACCUMULATOR?.length > 0 ? form?.ACCUMULATOR : [])
      selectedQuery && setCollections(getQueryAndConnectionsEntities({ selectedQuery }))
    })()
  }, [selectedQuery, selectedGadget])

  const handleSaveChanges = () => {
    const gadgetForm = {
      ...form,
      GROUPING: groupingFields,
      ACCUMULATOR: accumulatorFields
    }

    dispatch(saveGadget({
      form: gadgetForm,
      path: `T_RDB_DSB/${selectedDashboard.ID}/T_RDB_LVL/${selectedLevel.ID}/T_RDB_GDG`,
      selectedLevel
    }))

    setSelectedCollection(null)
    setGroupingFields([])
    setAccumulatorFields([])
    dispatch(closeModal({ modalName: name }))
  }

  return (
    <ModalWrapper isVisible={isVisible}>
      <DndProvider backend={HTML5Backend}>

        <S.Container style={{
          width: "50%",
          height: "75%"
        }}>

          <S.CloseButton onPress={() => {
            setSelectedCollection(null)
            dispatch(closeModal({ modalName: name }))
          }}>
            <MaterialIcons name="close" size={20} color="#333333" />
          </S.CloseButton>

          <S.Title>Edit Gadget Fields</S.Title>

          <S.SelectedView>
            <View style={{
              flexDirection: "row",
              alignItems: "center",
              gap: 3
            }}>
              <S.SelectedText>Collection:</S.SelectedText>
              <S.SelectedField>
                <S.ListInput
                  data={collections}
                  dropdownPosition='bottom'
                  labelField="LABEL"
                  valueField="ID"
                  placeholder={"Select collection"}
                  search
                  onChange={item => setSelectedCollection(item)}
                />
              </S.SelectedField>
            </View>
          </S.SelectedView>

          <S.Header style={{ flexDirection: "row-reverse" }}>
            <S.AddButton onPress={handleSaveChanges}>
              <S.TitleButton>Save Changes</S.TitleButton>
            </S.AddButton>
          </S.Header>

          <S.ListsView style={{ marginTop: 10 }}>
            <CollectionFieldsList
              selectedDashboard={selectedDashboard}
              mainCollection={selectedQuery}
              currentCollection={selectedCollection}
              originArray={collectionFields}
              setOriginArray={setCollectionFields}
              destinyArray={groupingFields}
              setDestinyArray={setGroupingFields}
              alternativeDestinyArray={accumulatorFields}
              setAlternativeDestinyArray={setAccumulatorFields}
            />
            <S.DividingLine />
            <DestinyBinList
              destinyArray={groupingFields}
              setDestinyArray={setGroupingFields}
              alternativeDestinyArray={accumulatorFields}
              setAlternativeDestinyArray={setAccumulatorFields}
              setOriginArray={setCollectionFields}
            />
          </S.ListsView>

        </S.Container>
      </DndProvider>
    </ModalWrapper>
  )
}
