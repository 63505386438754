import dbRequest from "../../../../../../../../../../../../../../../../services/dbRequest/dbRequest"

const getDetailTabs = async (masterTableId: string) => {
  const tables = await dbRequest
    .loadRecords("T_DTD_TAB")
    .where("MASTERTAB.ID", "==", masterTableId)
    .execute() as any

  await Promise.all(
    tables.map(async (table: { ID: string, T_DTD_FNC: any }) => {
      const functions = await dbRequest.loadRecords(`T_DTD_TAB/${table.ID}/T_DTD_FNC`).execute()
      table.T_DTD_FNC = functions ?? []
    })
  )

  return tables
}

export default getDetailTabs
