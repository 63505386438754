import React from "react"
import { ActivityIndicator } from "react-native"
import { defaultTheme } from "../../../styles/themes/default"
import * as S from "./styles"

const LoadingScreen = () => {
  return (
    <S.Container>
      <S.Logo />
      <ActivityIndicator size={"large"} color={defaultTheme["white"]} />
    </S.Container>
  )
}

export default LoadingScreen
