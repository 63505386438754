import React, { useCallback, useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { FiltersContext } from "../../contexts/FiltersContext"
import { SearchTextContext } from "../../contexts/SearchTextContext"
import Card from "./components/Card"
import * as S from "./styles"
import getFields from "./utils/getFields"

export default function FieldsList({ entityName, controller }) {
  const { t } = useTranslation()
  const { fields, setFields } = useContext(FiltersContext)
  const { searchText, setSearchText } = useContext(SearchTextContext)

  const [isFetchingFields, setIsFetchingFields] = useState(false)

  useEffect(() => {
    setIsFetchingFields(true)
    getFields({
      entityName,
      t,
      models: controller.models()
    })
      .then(r => {
        setFields(r)
        setIsFetchingFields(false)
      })
  }, [])

  const renderCard = useCallback((field) => {
    return (
      <Card
        key={field.id}
        fieldData={field}
      />
    )
  }, [])

  const clearInput = () => { setSearchText("") }

  const filteredFields = fields.filter(field => {
    const searchedValue = searchText.toUpperCase()
    const fieldDescription = t(field.description).toUpperCase()
    return fieldDescription.includes(searchedValue)
  })

  return (
    <S.Container>
      <S.Title>{t("Available")}</S.Title>

      <S.SearchContainer>
        <S.SearchInput
          value={searchText}
          onChangeText={setSearchText}
          selectTextOnFocus
          placeholder={`${t("Search")}`}
          placeholderTextColor="#C4C4C4"
          autoComplete="off"
        />

        <S.Button onPress={clearInput}>
          <S.Icon name={searchText === "" ? "search" : "times"} />
        </S.Button>
      </S.SearchContainer>

      {isFetchingFields ? (
        <S.LoadingContainer>
          <S.Loading />
        </S.LoadingContainer>
      ) : (
        <S.List contentContainerStyle={{ gap: 8 }}>
          {filteredFields.map(renderCard)}
        </S.List>
      )}
    </S.Container >
  )
}
