import Permissions from "../../../../../../models/permissions"

const handleTabPress = ({ navigation, tabData, isTabSelected, setSelectedTabName }) => {
  if (isTabSelected) {
    setSelectedTabName("Overview")
    return navigation.navigate("Overview")
  }

  const { name, key, params } = tabData

  const event = navigation.emit({
    type: "tabPress",
    target: key,
    canPreventDefault: true
  })

  if (!isTabSelected && !event.defaultPrevented) {
    Permissions.setCurrentRoutine(params.transaction)
    setSelectedTabName(params.RootParentID)
    navigation.navigate(name, params)
  }
}

export default handleTabPress
