import dbRequest from "../../../../../../../services/dbRequest/dbRequest"

export default async (models) => {
  const fields = await dbRequest.loadRecords(`T_DTD_TAB/${models.T_DTD_TAB.ID}/T_DTD_FLD`).execute()

  const fieldsPromises = []

  for (const field of fields) {
    const promise = dbRequest
      .loadRecords(`T_DTD_TAB/${models.T_DTD_TAB.ID}/T_DTD_FLD/${field.ID}/T_DTD_ATR`)
      .where("ID", "==", "TAB")
      .execute(r => {
        const tab = r ? r[0].VALUE : {}
        return {
          id: field.ID,
          tab
        }
      })

    fieldsPromises.push(promise)
  }

  const formattedFields = await Promise.all(fieldsPromises)

  const tabsDescription = formattedFields
    .filter(({ tab }) => tab?.DESCRIPTION)
    .sort((a, b) => a.tab.ORDERBY - b.tab.ORDERBY)
    .map(({ tab }) => tab.DESCRIPTION)

  const tabs = [...new Set(tabsDescription)]

  return {
    fields: formattedFields,
    tabs
  }
}
