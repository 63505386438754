import React from "react"
import { useTranslation } from "react-i18next"
import RuntimeConfiguration from "../../../models/runtimeConfiguration"
import FieldArea from "./components/FieldArea"
import TableArea from "./components/TableArea"
import ControllerProvider from "./contexts/ControllerProvider"
import ModalsProvider from "./contexts/ModalsProvider"
import UpdateProvider from "./contexts/UpdateProvider"
import * as S from "./styles"

export default function ScreenBuilderScreen() {
  const { t } = useTranslation()

  const isAdminMode = RuntimeConfiguration.getIsDeveloperConfigurationMode()

  return (
    <UpdateProvider>
      <ModalsProvider>
        <ControllerProvider>
          <S.Container>
            {isAdminMode &&
              <S.WarningView>
                <S.Text>
                  {t("Attention! You are modifying the Data Dictionary")}
                </S.Text>
              </S.WarningView>
            }

            <S.Content>
              <TableArea />

              <S.DividingLine />

              <FieldArea />
            </S.Content>
          </S.Container>
        </ControllerProvider>
      </ModalsProvider>
    </UpdateProvider>
  )
}
