export default function removeFieldsFromObject(obj, entityName = null, isNestedObject = false) {
  const fieldsToRemove = ["CREATED_BY", "CREATED_AT", "UPDATED_BY", "UPDATED_AT", "T_SET_ACC"]

  if (entityName === "T_SET_UGR") fieldsToRemove.pop()

  try {
    for (const key in obj) {
      const value = obj[key]

      if (isNestedObject && shouldDeleteField(value)) delete obj[key]

      if (typeof value === "object" && value !== null) {
        removeFieldsFromObject(value, entityName, true)

        for (const field of fieldsToRemove) {
          if (field in obj) delete obj[field]
        }
      }
    }

    return obj
  } catch (e) {
    console.error(e)
  }
}

const shouldDeleteField = value => typeof value === "string" && value.includes("base64")
