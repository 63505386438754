import styled from "styled-components"
import { MaterialIcons } from "@expo/vector-icons"
import { defaultTheme } from "../../../../../../../styles/themes/default"

export const Container = styled.div`
  background-color: ${defaultTheme["white"]};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 35%;
  max-height: 50%;
  padding: 15px;
  position: relative;
  width: 45%;
`

export const Title = styled.h1`
  color: ${defaultTheme["gray-900"]};
  font-size: 16px;
  font-weight: 600;
`

export const SeparatorView = styled.div`
  align-items: center;
  display: flex;
  height: 20px;
  justify-content: space-between;
  margin: 10px 0;
  width: 100%;
`

export const SeparatorLine = styled.div`
  background-color: ${defaultTheme["gray-300"]};
  border-radius: 1.5;
  height: 1px;
  width: 40%;
`

export const SeparatorText = styled.span`
  color: ${defaultTheme["gray-500"]};
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 500;
`

export const FilesListContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  width: 100%;  
`

export const PlaceholderList = styled.span`
  color: ${defaultTheme["gray-500"]};
  font-size: 12px;
  text-align: center;
`

export const CloseButton = styled.button`
  align-items: center;
  background-color: ${defaultTheme["white"]};
  border-radius: 5px;
  box-shadow: 2px 4px 8px ${defaultTheme["shadow"]};
  display: flex;
  padding:  3px;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;

  &:hover {
    transform: scale(1.05);
  }
`

export const CloseIcon = styled(MaterialIcons)
  .attrs({
    name: "close",
    size: 18,
    color: defaultTheme["gray-700"],
  })``
