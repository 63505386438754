import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { ActivityIndicator } from "react-native"
import ReportController from "../../../controllers/ReportController"
import Navigation from "../../../models/navigation"
import showAlert from "../../../services/showAlert"
import { defaultTheme } from "../../../styles/themes/default"
import CloseButton from "../../components/CloseButton/index.web"
import Form from "../../components/Form"
import ModalWrapper from "../../components/Modal"
import * as S from "./styles.web"
import getFields from "./utils/getFields"
import reportRequest from "./utils/reportRequest"
import validationFields from "./utils/validationFields"

export default function ReportScreen({ route }) {
  const { t } = useTranslation()

  const idReport = route.params.attribute
  const navigation = Navigation.getNavigation()

  const [controller] = useState(new ReportController())

  const [formFields, setFormFields] = useState([])
  const [isVisible, setIsVisible] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  async function generatePDF() {
    if (!validationFields(formFields, controller.models())) return showAlert({
      title: "Fill in all mandatory fields.",
      titleType: "warning"
    })
    const report = await reportRequest(idReport, controller.models())
    if (report) {
      const novaAba = window.open()
      novaAba.document.write(report)
    }
  }

  useEffect(() => {
    (async () => {
      setIsLoading(true)
      const fieldsData = await getFields(idReport)

      if (fieldsData.length > 0) setFormFields(fieldsData)
      else {
        generatePDF()
        setIsVisible(s => !s)
        navigation.goBack()
        Navigation.closeTab(route.params.tabName)
      }
      setIsLoading(false)
    })()
  }, [])

  return (
    <ModalWrapper isVisible={isVisible}>
      <S.ModalContainer>
      </S.ModalContainer>
      <S.ModalContainer>

        <CloseButton
          callbackFunction={() => {
            setIsVisible(s => !s)
            navigation.goBack()
            Navigation.closeTab(route.params.tabName)
          }}
        />

        <S.HeaderContainer>
          <S.ModalTitle>{t(route.params.tabName)}</S.ModalTitle>

          <S.HeaderButtonsView>

            <S.SaveButton onClick={generatePDF}>{t("Generate")}</S.SaveButton>
          </S.HeaderButtonsView>
        </S.HeaderContainer>

        <S.Body>
          {isLoading ?
            <ActivityIndicator size={25} color={defaultTheme["cyan-700"]} style={{ marginVertical: 40 }} />
            : <S.FormView>
              {formFields.length > 0 && <Form staticFields={formFields} entity={"REPORT"} models={controller.models()} hideTabs />}
            </S.FormView>}
        </S.Body>
      </S.ModalContainer>

    </ModalWrapper >
  )
}
