import React from "react"
import Text from "./styles"

export default function Selection({ fieldData, fieldValue, isSelected }) {
  const value = fieldData.ID === "ID" ? fieldValue : fieldValue.LABEL

  return (
    <div title={value} style={containerStyle}>
      <Text numberOfLines={1} isSelected={isSelected}>
        {value}
      </Text>
    </div>
  )
}

const containerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  width: "100%",
}
