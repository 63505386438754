import getTableFields from "../../../../../../../services/registration/getTableFields"

export default async function getFormFields() {

  const [fldFields, atrFields] = await Promise.all([
    getTableFields({ entityName: "T_DTD_FLD" }),
    getTableFields({ entityName: "T_DTD_ATR" })
  ])

  return {
    fldFields,
    atrFields
  }
}
