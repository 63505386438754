import { signInWithEmailAndPassword } from "firebase/auth"
import RuntimeConfiguration from "../../../models/runtimeConfiguration"
import {
  authRemote,
  initBranchConnection,
  setBranchConfig
} from "../dbConnection/"
import dbRequest from "../dbRequest"
import userConfiguration from "../userConfiguration"

export default class dbAccess {
  public static async logIn(email: string, password: string): Promise<boolean> {
    const request = await signInWithEmailAndPassword(
      authRemote,
      email,
      password
    )

    const userId = request.user.uid

    await dbRequest
      .saveSessionToken()
      .record({ ID: userId })
      .execute()

    if (userId === null) return false

    await userConfiguration.setMddData(userId)

    const userBranches = RuntimeConfiguration.getUserMddData().BRANCHID

    for (const branch in userBranches)
      if (!userBranches[branch]) delete userBranches[branch]

    const branchId = Object.keys(userBranches)[0]

    await this.connectBranch(branchId)

    await userConfiguration.setBranchData(userId)

    return true
  }

  public static async connectBranch(branchId: string) {
    const branchConfig = await this.getBranchConfig(branchId)

    RuntimeConfiguration.setCurrentBranchData({
      ...branchConfig,
      LOGO: branchConfig.LOGO?.URL ?? ""
    })

    await this.startBranchConnection(branchConfig.firebaseConfig)
  }

  private static async startBranchConnection(config: object) {
    setBranchConfig(config)
    await initBranchConnection()
  }

  private static async getBranchConfig(id: string) {
    let allBranches = RuntimeConfiguration.getAllBranches()

    if (!allBranches) {
      allBranches = await dbRequest.loadRecords("-BRANCHES").admin()
        .execute()

      RuntimeConfiguration.setAllBranches(allBranches)
    }

    const currentBranch = allBranches.find((branch) => branch.ID === id)

    return currentBranch as {
      firebaseConfig: object
      BRANCHNAME: string
      databaseURL: string
      LOGO: object
    }
  }
}
