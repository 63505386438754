import styled from "styled-components"
import { defaultTheme } from "../../../../../../../../../../../../../styles/themes/default"

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 36px;
  padding: 0 10px 0 20px;
  border-radius: 12px;
  cursor: grab;
  
  background-color: ${defaultTheme["white"]};
  border: .3px solid ${defaultTheme["gray-500"]};
  box-shadow: 0px 4px 8px #00000029;
`

export const Text = styled.p`
  width: 30%;
  font-size: 14px;
  font-weight: 600;
  color: ${defaultTheme["cyan-700"]};
  white-space: nowrap;
  overflow: hidden;         
  text-overflow: ellipsis;  
`

export const ButtonsContainer = styled.div`
  width: 65%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
`

export const ToogleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 22px;
  padding: 0px 8px;
  outline: ${({ isActive }) => (!isActive ? `.3px solid ${defaultTheme["gray-500"]}` : "none")};
  border: none;
  border-radius: 6px;
  cursor: pointer;
  background-color: ${({ isActive }) => isActive ? defaultTheme["cyan-500"] : defaultTheme["white"]};
  transition: all .2s ease-in-out;

  font-size: 10px;
  color: ${({ isActive }) => isActive ? defaultTheme["white"] : defaultTheme["gray-700"]};
  font-weight: 500;
  
  &:hover {
    background-color: ${defaultTheme["cyan-500"]};
    color: ${defaultTheme["white"]};
    outline: "none";
  }
`

export const InvisibleToogleContainer = styled(ToogleContainer)`
  visibility: hidden;
`

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  border-radius: 6px;
  cursor: pointer;
  background-color: ${defaultTheme["cyan-500"]};
`

/* export const Text = styled.Text`
  font-size: 12px;
  color: ${defaultTheme["gray-700"]};
  font-weight: 500;
`

export const ButtonsView = styled.View`
  width: 60%;
  gap: 35px;
  position: absolute;
  right: 55px;
  flex-direction: row;
  justify-content: end;
  align-items: center;
`

interface BoxProps {
  isActive: boolean
}

export const Box = styled.TouchableOpacity<BoxProps>`
  background-color: ${({ isActive }) =>
    isActive ? defaultTheme["cyan-500"] : "transparent"};
  width: 15px;
  height: 15px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${defaultTheme["gray-700"]};
  border-width: ${({ isActive }) => (isActive ? 0 : 1)}px;
  border-radius: 2px;
`

export const Icon = styled(Entypo)<BoxProps>`
  display: ${({ isActive }) => (isActive ? "flex" : "none")};
  color: ${defaultTheme["gray-100"]};
  font-size: 12px;
`

export const Button = styled.TouchableOpacity``

export const DeleteIcon = styled(FontAwesome5)`
  color: ${defaultTheme["red-400"]};
  font-size: 14px;
`
export const EditIcon = styled(FontAwesome5)`
  font-size: 12px;
`

export const ContainerDiv = styled.View`
  flex-direction: row;
  align-items: baseline;
  gap: 10px;
`
export const TextDiv = styled.View`
  width: 150px;
` */
