import styled from "styled-components"
import { defaultTheme } from "../../../styles/themes/default"

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 63px;
  margin-top: 6px;
  background-color: ${defaultTheme["white"]};
  overflow: scroll;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  height: 100%;
  padding-top: 130px;
  padding-left: 40px;
`

export const ButtonsContainer = styled.div`
  background-color: ${defaultTheme["white"]};
  width: 65%;
  position: fixed;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  gap: 40px;
  align-items: center;
  padding-left: 10px;
  padding-bottom: 10px;
  z-index: 100;
`

export const ReturnButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${defaultTheme["cyan-700"]};

  border: none;

  cursor: pointer;
  transition: opacity 0.2s;
  &:active {
    opacity: 0.6;
  }
`

export const ConfirmButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 30px;
  padding: 0 12px 0 6px;
  background-color: ${defaultTheme["cyan-700"]};
  border: none;
  border-radius: 17px;

  color: ${defaultTheme["white"]};
  cursor: ${({ isDisable }) => isDisable ? "not-allowed" : "pointer"};

  transition: opacity 0.2s;
  &:active {
    opacity: 0.6;
  }
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background-color: ${defaultTheme["white"]};
  border-radius: 50%;
`

export const ListContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  margin-top: 10px;
`

export const FieldsView = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  flex: 1;
`
