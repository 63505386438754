import { MaterialIcons } from "@expo/vector-icons"
import React from "react"
import Chart from "react-google-charts"
import { View } from "react-native"
import { useDispatch } from "react-redux"
import globalStyles from "../../../../../global/globalStyles"
import useAppSelector from "../../../../../redux/hooks/useAppSelector"
import { getModalData } from "../../../../../redux/redux-reducers/dashboard-reducer"
import ModalWrapper from "../../../../../views/components/Modal"
import SliderInput from "../../SliderInput"
import * as S from "../styles.web"
import useSetChart from "./hooks/useSetChart"

export default function ModalChart() {

  const dispatch = useDispatch()

  const { data, attributes, chartType, isVisible } = useAppSelector(({ dashboardReducer }) => dashboardReducer.modal)

  const { actualValue, setActualValue } = useSetChart(data, isVisible)

  return (
    <ModalWrapper isVisible={isVisible}>

      <View
        style={{
          height: "60%",
          width: "70%",
          paddingBottom: 10,
          borderRadius: 10,
          backgroundColor: globalStyles.colors.tertiary
        }}
      >
        <View style={{
          width: "100%",
          height: "auto",
          padding: 10,
          shadowColor: "#000",
          shadowOffset: {
            width: 0,
            height: 1
          },
          shadowOpacity: 0.25,
          shadowRadius: 3.84,
          zIndex: 1
        }}>
          <S.ButtonsView>
            {!["PieChart", "Table"].includes(chartType) &&
              <SliderInput
                value={actualValue}
                minValue={1}
                maxValue={!data ? 1 : data.length}
                onChange={setActualValue}
              />}

            <S.CloseButton onClick={() => dispatch(getModalData({ isVisible: false }))}>
              <MaterialIcons name="close" size={20} color="#333333" />
            </S.CloseButton>
          </S.ButtonsView>

          <S.Title>{attributes?.title}</S.Title>

        </View>

        <Chart
          data={data}
          chartType={chartType}
          height="100%"
          chartEvents={[{
            eventName: "error",
            callback: ({ google, chartWrapper }) => google.visualization.errors.removeAll(document.getElementById(chartWrapper.getContainerId()))
          }]}
          options={{
            width: "100%",
            pageSize: 200,
            ...attributes,
            hAxis: {
              viewWindow: {
                min: 0,
                max: chartType === "BarChart" ? undefined : actualValue
              },
              slantedText: true
            },
            vAxis: {
              viewWindow: {
                min: 0,
                max: chartType === "BarChart" ? actualValue : undefined
              }
            }
          }}
        />
      </View>
    </ModalWrapper>
  )
}
