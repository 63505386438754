import { FontAwesome } from "@expo/vector-icons"
import styled from "rn-css"
import globalStyles from "../../../../../global/globalStyles"

export const Container = styled.TouchableOpacity`
  background-color: ${globalStyles.colors.tertiary};
  width: 25px;
  height: 25px;
  margin: 5px;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
`

export const Icon = styled(FontAwesome)`
  color: ${globalStyles.colors.primary};
  font-size: ${props => props.name === "mobile-phone" ? 24 : 16}px;
`
