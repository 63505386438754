import React from "react"
//import ScreenFactory from "../../../ScreenFactory"
import AreaChart from "../../components/Charts/AreaChart"
import BarChart from "../../components/Charts/BarChart"
import ComboChart from "../../components/Charts/ComboChart"
import LabelChart from "../../components/Charts/LabelChart"
import PieChart from "../../components/Charts/PieChart"
import ScatterChart from "../../components/Charts/ScatterChart"
import TableChart from "../../components/Charts/TableChart/index.web"
import { ControllerProps, GadgetProps } from "../../types/types"
//import generateGridEvents from "../../utils/generateGridEvents"

interface CheckChartsTypesProps {
  idfilial: number
  entityName: string
  chartId: string
  chartIdArr: string[]
  chartData: GadgetProps
  controller: ControllerProps
}

export default function CheckChartsTypes(
  { chartData, controller, chartId, entityName, idfilial, chartIdArr, dashboardData }: CheckChartsTypesProps
) {

  const commonProps = {
    chartData,
    controller,
    chartId,
    entityName,
    idfilial,
    chartIdArr,
    dashboardData,
    models: controller.models()
  }

  const charts: { [key: string]: JSX.Element } = {
    AreaChart: <AreaChart {...commonProps} />,
    BarChart: <BarChart {...commonProps} />,
    ComboChart: <ComboChart {...commonProps} />,
    LabelChart: <LabelChart {...commonProps} />,
    PieChart: <PieChart {...commonProps} />,
    ScatterChart: <ScatterChart {...commonProps} />,
    TableChart: <TableChart {...commonProps}/>
  }

  return charts[chartData.GADGET.VALUE]
}
