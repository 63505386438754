import { ScheduleComponent, ViewsDirective, ViewDirective, TimelineViews, TimelineMonth, Day, Week, WorkWeek, Month, Year as YearView, TimelineYear,  Agenda, Inject, Resize, DragAndDrop } from '@syncfusion/ej2-react-schedule'
import * as S from './styles'
import { useRef, useEffect, useState } from 'react'
import handleSave from './utils/handleSave'
import fetchEvents from './utils/fetchEvents'
import handleDelete from './utils/handleDelete'

export default function CalendarScreen() {
  const calendarRef = useRef<ScheduleComponent | null>(null)

  const [eventsData, setEventsData] = useState([])

  const onActionComplete = (args) => {
    if (args.requestType === 'eventCreated' || args.requestType === 'eventChanged') {
      const eventData = args.data[0]
      
      handleSave({ eventData })
      return
    }

    if (args.requestType === 'eventRemoved') {
      const eventData = args.data[0]
      handleDelete({ eventData })
      return
    }
  }

  const eventSettings = {
    enableTooltip: true,
    dataSource: eventsData
  }

  useEffect(() => {
    fetchEvents().then(events => setEventsData(events))
  }, [])

  return (
    <S.Container>
      <S.Toolbar>Calendar</S.Toolbar>
      <ScheduleComponent
        ref={calendarRef}
        height={'99%'}
        width={'99.5%'}
        timezone="UTC"
        timeFormat='HH:mm'
        eventSettings={eventSettings}
        actionComplete={onActionComplete}
      >
        <ViewsDirective>
            <ViewDirective option='Day' />
            <ViewDirective option='Week' />
            {/* <ViewDirective option='WorkWeek' /> */}
            <ViewDirective option='Month' isSelected={true} />
            <ViewDirective option='Year' />
            <ViewDirective option='Agenda' />
            {/* <ViewDirective option='TimelineDay' displayName='Timeline Day' /> */}
            <ViewDirective option='TimelineWeek' displayName='Timeline Week' />
            {/* <ViewDirective option='TimelineMonth' displayName='Timeline Month' /> */}
            {/* <ViewDirective option='TimelineYear' displayName='Timeline Year' /> */}
        </ViewsDirective>
        <Inject services={[ Day, Week, WorkWeek, Month, YearView, Agenda, TimelineViews, TimelineMonth, TimelineYear, Resize, DragAndDrop]} />
      </ScheduleComponent>
    </S.Container>
  )
}