import { GridComponent } from "@syncfusion/ej2-react-grids"
import showAlert from "../../../../../../services/showAlert"

interface ExportProps {
  entity: string
  gridRef: React.RefObject<GridComponent> | null
}

const handleExport = ({ entity, gridRef }: ExportProps) => {
  showAlert({
    text: "Do you want to export the data?",
    type: "confirm",
    handleConfirm: () => {
      let gridData: any = []

      if (gridRef.current?.getFilteredRecords().length > 0) {
        gridData = gridRef.current.getFilteredRecords()

      }
      else if (gridRef.current.dataSource instanceof Array)
        gridData = gridRef.current.dataSource
      else if ("data" in gridRef.current.dataSource)
        gridData = gridRef.current.dataSource.data

      const processedData = [...gridData]
        .map((row: object) => {
          for (const field in row) {
            const value = row[field]
            row[field] = getValue(value)
          }
          return row
        })

      gridRef.current.excelExport({
        dataSource: processedData,
        fileName: `${entity}.xlsx`
      })
    }
  })
}

export default handleExport

const getValue = (value: any) => {
  switch (typeof value) {
    case "object":
      if (value instanceof Date) {
        return value.toLocaleString()
      }
      return value?.LABEL || value?.DESCRIPTION
    case "boolean":
      return value ? "Sim" : "Não"
    default:
      return value
  }
}

