import styled from "styled-components"
import { defaultTheme } from "../../../../../../../../../styles/themes/default"

export const FilesListCard = styled.div`
  align-items: center;
  background-color: ${defaultTheme["gray-200"]};
  border-radius: 4px;
  display: flex;
  gap: 15px;
  height: 50px;
  padding: 10px;
  width: 100%;
`

export const IconContainer = styled.div`
  align-items: center;
  display: flex;
  height: 26px;
  justify-content: center;
  width: 26px;
`

export const FileInfoView = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: space-between;
`

export const FileName = styled.span`
  color: ${defaultTheme["gray-900"]};
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 500;
`

export const FileInfoText = styled(FileName)`
  color: ${defaultTheme["gray-700"]};
  font-size: 12px;
`

export const ButtonsView = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  height: 100%;
  justify-content: flex-end;
`

export const Button = styled.button`
  border-radius: 2px;
  padding: 6px;

  &:active {
    transform: scale(1.05);
  }

  &:hover {
    background-color: ${defaultTheme["white"]};
    box-shadow: 2px 4px 8px ${defaultTheme["shadow"]};
  }
`

interface DefaultItemProps {
  isDefault: boolean
}

export const DefaultItemMark = styled.div<DefaultItemProps>`
  align-items: center;
  background-color: ${defaultTheme["cyan-500"]};
  border-radius: 10px;
  border-style: none;
  color: ${defaultTheme["white"]};
  display: ${({ isDefault }) => isDefault ? "flex" : "none"};
  font-size: 10px;
  font-weight: 600;
  gap: 5px;
  justify-content: center;
  padding: 4px 0px;
  width: 70px;  
`
