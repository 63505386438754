import React from "react"
import { useTranslation } from "react-i18next"
import idValidation from "../../utils/idValidation"
import AccessibilityBox from "../AccessibilityBox"
import useSelection from "../hooks/useSelections"
import * as S from "./styles.web"

export default function Selection({ models, field, updateForm, setUpdateForm }) {
  const { t } = useTranslation()

  const { options } = useSelection({
    models,
    field,
    updateForm
  })

  //idValidation(field, models)

  const { ID, TABLENAME, T_DTD_ATR } = field
  const { EDITABLE, REQUIRED, FIELDDESCRIPTION, HELPTEXT } = T_DTD_ATR

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedItem = options.find(option => option.VALUE === event.target.value)

    models[TABLENAME][ID] = {
      LABEL: selectedItem.LABEL,
      VALUE: selectedItem.VALUE
    }
    setUpdateForm(s => !s)
  }

  return (
    <S.Container>
      <S.Row>
        <S.Title isRequired={REQUIRED}>{t(FIELDDESCRIPTION)}</S.Title>

        <AccessibilityBox text={HELPTEXT} />
      </S.Row>

      <S.Input isEditable={EDITABLE} disabled={!EDITABLE} onChange={handleChange}>
        {options.map(({ VALUE, LABEL }) => (
          <S.Option
            key={VALUE}
            value={VALUE}
            label={t(LABEL)}
            selected={models[TABLENAME][ID]?.VALUE === VALUE}
          />
        ))}
      </S.Input>
    </S.Container>
  )
}
