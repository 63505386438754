import showAlert from "../../../../../../../../../services/showAlert"
import i18n from "i18next"

const handleRemove = ({ fileData, setFileData }) => {
  const t = (text: string) => i18n.t(text)

  if (!fileData.source && !fileData.preview)
    return showAlert({
      titleType: "error",
      text: t("No image selected"),
      title: t("Failed to remove image")
    })

  showAlert({
    titleType: "warning",
    type: "confirm",
    text: t("Do you want to remove the current image?"),
    title: t("Remove image"),
    handleConfirm: () => {
      setFileData(state => ({
        ...state,
        file: null,
        preview: null
      }))
    }
  })
}

export default handleRemove
