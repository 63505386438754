import LoginData from "../../../../models/loginData"
import dbRequest from "../../../../services/dbRequest/dbRequest"

const fetchEvents = async () => {
  const allEvents = await dbRequest.loadRecords("T_REC_CAL").execute() as any || []

  const { ID, GROUPUID } = LoginData.getLoginData()

  const filteredEvents = allEvents.filter(event => {
    const isUserEvent = event.UID?.ID === ID
    const isUserGroupEvent = event.GROUPUID?.ID === GROUPUID
    const isCompanyEvent = !event.UID?.ID && !event.GROUPUID?.ID

    return isUserEvent || isUserGroupEvent || isCompanyEvent
  })

  return filteredEvents.map(event => ({
    Id: event.ID,
    Subject: event.DESCRIPTION,
    StartTime: new Date(event.STARTTIME),
    EndTime: new Date(event.ENDTIME),
    IsAllDay: event.ISALLDAY,
    Location: event.LOCATION,
    RecurrenceRule: event.RECURRENCERULE,
    Description: event.COMMENTS,
  }))
}

export default fetchEvents
