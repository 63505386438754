import { MaterialIcons } from "@expo/vector-icons"
import React, { useState } from "react"
import { View } from "react-native"
import { useDispatch } from "react-redux"
import globalStyles from "../../../../../../global/globalStyles"
import useAppSelector from "../../../../../../redux/hooks/useAppSelector"
import { closeModal } from "../../../../../../redux/redux-reducers/dashboardBuilder-reducer"
import deleteOptionList from "../../../../../../redux/redux-reducers/dashboardBuilder-reducer/utils/deleteOptionList"
import saveFieldListOption from "../../../../../../redux/redux-reducers/dashboardBuilder-reducer/utils/saveFieldListOption"
import Form from "../../../../../components/Form"
import ModalWrapper from "../../../../../components/Modal"
import * as S from "../modalStyles"

export default function ConfigureOptionsListModal({ controller }) {

  const dispatch = useDispatch()

  const [focusedIndex, setFocusedIndex] = useState(null)
  const [update, setUpdate] = useState(false)

  const { isVisible, name } = useAppSelector(({ dashboardBuilderReducer }) => dashboardBuilderReducer.modals.configureOptionsListModal)
  const { options, selectedField } = useAppSelector(({ dashboardBuilderReducer }) => dashboardBuilderReducer.modals.fieldModal)
  const { selectedDashboard } = useAppSelector(({ dashboardBuilderReducer }) => dashboardBuilderReducer.dashboardData)

  return (
    <ModalWrapper isVisible={isVisible}>
      <S.Container style={{ width: "45%" }}>
        <S.CloseButton onPress={async () => {
          dispatch(closeModal({ modalName: name }))
        }}>
          <MaterialIcons name="close" size={20} color="#333333" />
        </S.CloseButton>

        <S.Title style={{ marginBottom: 5 }}>Configure Options List</S.Title>

        <View style={{
          height: 90,
          width: "100%",
          marginVertical: 10,
          borderRadius: 4,
          backgroundColor: globalStyles.colors.formBackground,
          padding: 4
        }}>
          <Form entityName={"T_DTD_OPT"} controller={controller} updateForm={update} />
        </View>

        <S.Header>
          <S.SubTitle>Options ({options.length})</S.SubTitle>
          <S.ButtonsView>

            <S.AddButton onPress={() => {
              dispatch(saveFieldListOption({
                option: { ...controller.models().T_DTD_OPT },
                path: `T_RDB_DSB/${selectedDashboard.ID}/T_DTD_FLD/${controller.models().T_DTD_FLD.ID}/T_DTD_OPT`
              }))
              controller.models().T_DTD_OPT.reset()
              setUpdate(s => !s)
            }}>
              <S.TitleButton>Save</S.TitleButton>
            </S.AddButton>

            <S.DeleteButton
              onPress={() => {
                dispatch(deleteOptionList({
                  path: `T_RDB_DSB/${selectedDashboard.ID}/T_DTD_FLD/${controller.models().T_DTD_FLD.ID}/T_DTD_OPT`,
                  selectedOption: { ID: options[focusedIndex].VALUE }
                }))
              }}
            >
              <S.DeleteIcon name="trash-alt" />
            </S.DeleteButton>
          </S.ButtonsView>
        </S.Header>

        <S.UnderLine />

        <S.ListArea contentContainerStyle={{ gap: 5 }}>
          {
            options.map((item, index) => (
              <S.Card
                key={index}
                isSelected={index === focusedIndex}
                onPress={() => setFocusedIndex(index)}
              >
                <S.CardFieldArea>
                  <S.CartText style={{ fontWeight: "600" }}>Value: <S.CartText>{item.VALUE}</S.CartText></S.CartText>
                </S.CardFieldArea>
                <S.CardFieldArea>
                  <S.CartText style={{ fontWeight: "600" }}>Label: <S.CartText>{item.LABEL}</S.CartText></S.CartText>
                </S.CardFieldArea>
                <S.CardFieldArea>
                  <S.CartText style={{ fontWeight: "600" }}>Is Default?: <S.CartText>{item.DEFAULTVALUE ? "Yes" : "No"}</S.CartText></S.CartText>
                </S.CardFieldArea>
              </S.Card>
            ))
          }
        </S.ListArea>

      </S.Container>
    </ModalWrapper >
  )
}
