import { MaterialCommunityIcons } from "@expo/vector-icons"
import styled from "styled-components"
import { defaultTheme } from "../../../../../../../../../styles/themes/default"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${defaultTheme["gray-100"]};
  height: 65%;
  width: 65%;
  border-radius: 10px;
  overflow: hidden;
`

export const CloseButton = styled.button`
  position: absolute;
  justify-content: center;
  align-items: center;
  z-index: 2;
  top: 5px;
  right: 5px;
  width: 25px;
  height: 25px;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
`

export const Icon = styled(MaterialCommunityIcons)`
  color: ${defaultTheme["gray-900"]};
  font-size: 20px;
`

export const ListsView = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`

export const DividingLine = styled.div`
  background-color: ${defaultTheme["gray-400"]};
  height: 100%;
  width: 1.5px;
`
