export default (fields, models) => {
  const requiredFields = []

  fields.forEach((field) => {
    const fieldName = field.ID
    if (field.T_DTD_ATR.REQUIRED) {
      if (field.FIELDTYPE.VALUE === "P") requiredFields.push(`${fieldName}_INI`, `${fieldName}_FIN`)
      else requiredFields.push(fieldName)
    }
  })

  return requiredFields.every((fieldName) => {
    const fieldValue = models.DASHBOARD[fieldName]
    return fieldValue !== null && fieldValue !== undefined
  })
}
