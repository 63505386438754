import models from "../models"
import showAlert from "../services/showAlert"
import ChartArray from "../views/screens/DashboardsBuilderScreen/utils/Chart"
import { ChartData, ChartDataArray } from "../views/screens/DashboardsBuilderScreen/utils/ChartData"

export default class DashboardBuilderController {

  constructor() {
    this.modelsTemplate = models.getClone()
    this.models = () => this.modelsTemplate
    this.screenName = () => "Dashboards"
  }

}