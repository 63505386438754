import showAlert from "../../../../../../../../services/showAlert"

const handleCopy = ({ gridRef, models, entity, setModalVisible, modalVisible, setUpdateForm }) => {
  const tableData = models._REGISTRATION_DATA[entity]

  if (modalVisible) {
    if (tableData.operation === "INS")
      return showAlert({
        text: "No record selected",
        title: "Failed to duplicate",
        titleType: "error"
      })

    const callback = () => {
      tableData.currentRecordIndex = null
      setUpdateForm(s => !s)
    }

    copyRecord({ record: { ...models[entity] }, models, entity, callback })
    return
  }

  const selectedRecords = gridRef.current.getSelectedRecords()

  if (selectedRecords.length === 0)
    return showAlert({
      text: "No record selected",
      title: "Failed to duplicate",
      titleType: "error"
    })

  if (selectedRecords.length > 1)
    return showAlert({
      text: "Only one record can be duplicated at a time",
      title: "Failed to duplicate",
      titleType: "error"
    })

  copyRecord({ record: selectedRecords[0], models, entity, callback: () => setModalVisible(true) })
}

export default handleCopy

function copyRecord({ record, models, entity, callback }) {
  showAlert({
    type: "confirm",
    title: "Record duplication",
    text: "Do you want to duplicate this record?",
    handleConfirm: () => {
      const fieldsToDelete = ["ID", "CREATED_BY", "CREATED_AT", "UPDATED_BY", "UPDATED_AT"]
      const tableData = models._REGISTRATION_DATA[entity]

      for (const field of fieldsToDelete)
        record[field] = null

      const { fields } = tableData

      for (const field of fields) {
        const { ID, FIELDTYPE } = field

        if (FIELDTYPE.VALUE === "A") {
          delete record[ID]
          delete tableData.files[ID]
        }
      }

      tableData.operation = "INS"
      tableData.originalRecord = { ...record }
      models[entity].populate({ ...record })

      callback()
    }
  })
}
