import showAlert from "../../../../../../../services/showAlert"

export default (models) => {
  const tableData = { ...models.T_DTD_TAB }

  const { ID, ENTITY, PKFIELDDESCRIPTION, TYPE = {}, MASTERTAB = {} } = tableData

  if (!ID) return showAlert(getAlert("Invalid table ID"))
  if (!ENTITY) return showAlert(getAlert("Invalid table name"))
  if (!PKFIELDDESCRIPTION) return showAlert(getAlert("Invalid primary key description"))
  if (TYPE.VALUE === "RegistrationDetail" && !MASTERTAB.ID) return showAlert(getAlert("A detail table must have a master table"))
  return true
}

const getAlert = text => ({
  text,
  title: "Failed to create table",
  titleType: "error"
})
