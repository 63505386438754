import { createAsyncThunk } from "@reduxjs/toolkit"
import RuntimeConfiguration from "../../../../models/runtimeConfiguration"
import dbRequest from "../../../../services/dbRequest/dbRequest"
import showAlert from "../../../../services/showAlert"
import ChartArray from "../../../../views/screens/DashboardsBuilderScreen/utils/Chart"
import { ChartDataArray } from "../../../../views/screens/DashboardsBuilderScreen/utils/ChartData"

export const saveDashboardLayout = createAsyncThunk(
  "dashboardBuilder/saveDashboardLayout",
  async ({ selectedDashboard, selectedLevel }, thunkAPI) => {
    const isAdminMode = RuntimeConfiguration.getDashboardBuilderAdminMode()
    try {
      // Verifica a área selecionada
      verifysSelectedArea()

      // Salva a área selecionada
      const matrixSlice = saveSelectedArea()

      const updatedGadgets = []
      let savePromises = []

      for (let i = 1; i <= matrixSlice.length; i++) {
        const tempModel = { ...ChartDataArray.getPosition(i - 1).getModel() }
        const _matrix = matrixSlice[i - 1]

        updatedGadgets.push({
          ...tempModel,
          MATRIX: _matrix
        })
        if (tempModel?.series) delete tempModel.series

        let savePromise = dbRequest
          .saveRecords(`T_RDB_DSB/${selectedDashboard.ID}/T_RDB_LVL/${selectedLevel.ID}/T_RDB_GDG`)
          .record({
            ...tempModel,
            MATRIX: _matrix
          })

        if (isAdminMode) savePromise = savePromise.admin()

        savePromise = savePromise.execute()

        savePromises.push(savePromise)
      }

      await Promise.all(savePromises)

      showAlert({
        titleType: "success",
        title: "Success",
        text: "Layout saved successfully."
      })

      return {
        updatedGadgets,
        selectedLevel
      }

    } catch (error) {
      console.error(error)
      return thunkAPI.rejectWithValue(error.message || "An error occurred while saving the layout.")
    }
  }
)

function verifysSelectedArea() {
  for (let i = 0; i < ChartDataArray.getArr().length; i++) {
    let isSelected = false
    for (let j = 0; j < 144; j++) {
      if (ChartArray.getPosition(j).getID() === ChartDataArray.getPosition(i).getID()) {
        isSelected = true
        break
      }
    }
    if (!isSelected) {
      showAlert({
        title: "Layout cannot be saved.",
        titleType: "warning",
        text: `The chart with ID: ${ChartDataArray.getPosition(i).getID()} was not defined in the layout.`
      })
      throw new Error(`The chart with ID: ${ChartDataArray.getPosition(i).getID()} was not defined in the layout.`)
    }
  }
}

function saveSelectedArea() {
  const matrixSlice = []
  for (let i = 0; i < ChartDataArray.getArr().length; i++) {
    let chartString = ""
    for (let j = 0; j < 144; j++) {
      if (ChartArray.getPosition(j).getID() === ChartDataArray.getPosition(i).getID())
        chartString += ChartArray.getPosition(j).getID()
      else
        chartString += "0"
    }
    matrixSlice.push(chartString)
  }

  return matrixSlice
}
