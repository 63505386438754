import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import showAlert from "../../../../../../../services/showAlert"
import { defaultTheme } from "../../../../../../../styles/themes/default"
import LoadingAnimation from "../../../../../LoadingAnimation"
import * as S from "./styles"
import fetchPartnerData from "./utils/fetchPartnerData"
import getAlert from "./utils/getAlert"

export default function ReceitasWS({ models, closeModal, setUpdateForm }) {
  const { t } = useTranslation()

  const [CNPJ, setCNPJ] = useState("")
  const [inputIsFocused, setInputIsFocused] = useState(false)
  const [isFetchingPartnerData, setIsFetchingPartnerData] = useState(false)

  const formatText = cnpj => {
    const numbers = extractNumbersFromCnpj(cnpj)
    const formattedCnpj = numbers.length === 14
      ? numbers.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{0,2})/, "$1.$2.$3/$4-$5")
      : cnpj

    setCNPJ(formattedCnpj)
  }

  const extractNumbersFromCnpj = cnpj => cnpj.replace(/\D/g, "")

  const handleSearch = async () => {
    if (CNPJ.length < 18) return showAlert({
      text: "Invalid CNPJ",
      title: "Failed to get partner data",
      titleType: "error"
    })

    setIsFetchingPartnerData(true)

    const responseCode = await fetchPartnerData(extractNumbersFromCnpj(CNPJ), models)
    showAlert(getAlert(responseCode))
    setUpdateForm(s => !s)
    setIsFetchingPartnerData(false)
    closeModal()
  }

  return (
    <S.Container>
      <S.Title>{t("Import Partner via ReceitaWS")}</S.Title>

      <S.InputContainer>
        <S.SearchTextInput
          value={CNPJ}
          onChangeText={formatText}
          placeholder={`${t("Digite o CNPJ")}`}
          onFocus={() => setInputIsFocused(true)}
          onBlur={() => setInputIsFocused(false)}
        />

        <S.ClearButton onPress={() => setCNPJ("")}>
          <S.Icon name={inputIsFocused && CNPJ !== "" ? "times" : "search"} />
        </S.ClearButton>
      </S.InputContainer>

      <S.ButtonsView>

        <S.CancelButton onPress={() => closeModal()}>
          <S.ButtonText>{t("Cancel")}</S.ButtonText>
        </S.CancelButton>

        <S.ConfirmButton onPress={handleSearch}>
          {isFetchingPartnerData
            ? <LoadingAnimation size='small' color={defaultTheme["white"]} />
            : <S.ButtonText>{t("Search")}</S.ButtonText>
          }

        </S.ConfirmButton>
      </S.ButtonsView>
    </S.Container>
  )
}
