import React from "react"
import { defaultTheme } from "../../../styles/themes/default"
import * as S from "./styles"

export default function CloseButton({ callbackFunction }) {
  return (
    <S.CloseButton onClick={callbackFunction}>
      <svg width="14" height="14" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
        <line x1="1" y1="1" x2="14" y2="14" stroke={defaultTheme["gray-700"]} strokeWidth="2" strokeLinecap="round" />
        <line x1="1" y1="14" x2="14" y2="1" stroke={defaultTheme["gray-700"]} strokeWidth="2" strokeLinecap="round" />
      </svg>
    </S.CloseButton>
  )
}
