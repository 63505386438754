import { MaterialIcons } from "@expo/vector-icons"
import React, { useEffect, useState } from "react"
import { View } from "react-native"
import { useDispatch } from "react-redux"
import useAppSelector from "../../../../../../redux/hooks/useAppSelector"
import { changeFormValue, closeModal, openEditGroupingModal, setSelectedSerie } from "../../../../../../redux/redux-reducers/dashboardBuilder-reducer"
import { deleteSerie } from "../../../../../../redux/redux-reducers/dashboardBuilder-reducer/utils/deleteSerie"
import { saveGadget } from "../../../../../../redux/redux-reducers/dashboardBuilder-reducer/utils/saveGadget"
import showAlert from "../../../../../../services/showAlert"
import ModalWrapper from "../../../../../components/Modal"
import * as S from "../modalStyles"
import { chartTypes } from "./utils/chartTypes"

export default function GadgetModal() {

  const dispatch = useDispatch()

  const { name, isVisible, mode, form } = useAppSelector(({ dashboardBuilderReducer }) => dashboardBuilderReducer.modals.gadgetModal)
  const { queries, selectedDashboard, selectedLevel, selectedGadget, selectedSerie } = useAppSelector(({ dashboardBuilderReducer }) => dashboardBuilderReducer.dashboardData)

  const [focusedIndex, setFocusedIndex] = useState(false)
  const [selectedQuery, setSelectedQuery] = useState(null)

  useEffect(() => {
    if (form?.DATAID && !selectedQuery) {
      setSelectedQuery(() => queries.filter(item => item.ID === form.DATAID)[0])
    }
  }, [form])

  return (
    <ModalWrapper isVisible={isVisible}>
      <S.Container>

        <S.CloseButton onPress={() => {
          setSelectedQuery(null)
          setFocusedIndex(false)
          dispatch(closeModal({ modalName: name }))
        }}>
          <MaterialIcons name="close" size={20} color="#333333" />
        </S.CloseButton>

        <S.Title>{mode === "new" ? "Add New" : "Edit"} Gadget</S.Title>

        <S.SelectedView>
          {mode === "edit" && <View style={{
            flexDirection: "row",
            alignItems: "center",
            gap: 3
          }}>
            <S.SelectedText>ID:</S.SelectedText>
            <S.SelectedField>
              <S.TextInput
                style={{ width: 50 }}
                value={form?.ID}
                editable={false}
              />
            </S.SelectedField>
          </View>}
          <View style={{
            flexDirection: "row",
            alignItems: "center",
            gap: 3
          }}>
            <S.SelectedText>Description:</S.SelectedText>
            <S.SelectedField>
              <S.TextInput
                value={form?.DESCRIPTION}
                placeholder='Gadget Description'
                placeholderTextColor={"gray"}
                onChangeText={(tx) => dispatch(changeFormValue({
                  modalName: name,
                  fieldName: "DESCRIPTION",
                  value: tx
                }))}
              />
            </S.SelectedField>
          </View>

        </S.SelectedView>

        <S.SelectedView style={{ marginTop: 0 }}>
          <View style={{
            flexDirection: "row",
            alignItems: "center",
            gap: 3
          }}>
            <S.SelectedText>Chart Type:</S.SelectedText>
            <S.SelectedField>
              <S.ListInput
                data={chartTypes}
                dropdownPosition='bottom'
                labelField="LABEL"
                valueField="VALUE"
                placeholder={form?.GADGET?.LABEL ?? "Select chart type"}
                onChange={async (item) => {
                  dispatch(changeFormValue({
                    modalName: name,
                    fieldName: "GADGET",
                    value: item
                  }))
                }}
              />
            </S.SelectedField>
          </View>

          <View style={{
            flexDirection: "row",
            alignItems: "center",
            gap: 3
          }}>
            <S.SelectedText>Linked Query:</S.SelectedText>
            <S.SelectedField>
              <S.ListInput
                data={queries || []}
                dropdownPosition='bottom'
                labelField="ID"
                valueField="ID"
                isEditable={true}
                placeholder={form?.DATAID ?? "Select Query"}
                search
                onChange={async (item) => {
                  setSelectedQuery(item)
                  dispatch(changeFormValue({
                    modalName: name,
                    fieldName: "DATAID",
                    value: item.ID
                  }))
                }}
              />
            </S.SelectedField>
          </View>
          <View style={{
            flexDirection: "row",
            alignItems: "center",
            gap: 3
          }}>
            <S.AddButton onPress={() => {
              if (!selectedQuery) return showAlert({
                titleType: "warning",
                title: "Unable to open field configuration",
                text: "Please select a query"
              })
              dispatch(openEditGroupingModal(selectedQuery))
            }}>
              <S.TitleButton>Configure Fields</S.TitleButton>
            </S.AddButton>
          </View>
        </S.SelectedView>

        <S.Header>
          <S.SubTitle>Series ({selectedGadget?.series?.length ?? 0})</S.SubTitle>
          <S.ButtonsView>

            <S.AddButton onPress={() => dispatch(saveGadget({
              form: form,
              path: `T_RDB_DSB/${selectedDashboard.ID}/T_RDB_LVL/${selectedLevel.ID}/T_RDB_GDG`,
              selectedLevel: selectedLevel
            }))}>
              <S.TitleButton>Save Gadget {mode === "edit" && "Changes"}</S.TitleButton>
            </S.AddButton>

            {mode === "edit" && <S.AddButton onPress={() => dispatch(setSelectedSerie("new"))}>
              <S.TitleButton>New Serie</S.TitleButton>
            </S.AddButton>}

            {
              selectedGadget?.series.length > 0 &&
              <S.DeleteButton onPress={() => dispatch(deleteSerie({
                selectedSerie,
                path: `T_RDB_DSB/${selectedDashboard.ID}/T_RDB_LVL/${selectedLevel.ID}/T_RDB_GDG/${selectedGadget.ID}/T_RDB_SRS`
              }))}>
                <S.DeleteIcon name="trash-alt" />
              </S.DeleteButton>
            }
          </S.ButtonsView>
        </S.Header>

        <S.UnderLine />

        <S.ListArea contentContainerStyle={{ gap: 5 }}>
          {
            selectedGadget?.series?.map((item, index) => (
              <S.Card
                key={index}
                isSelected={index === focusedIndex}
                onPress={() => {
                  setFocusedIndex(index)
                  dispatch(setSelectedSerie({ selectedSerie: item }))
                }}
              >
                <S.CardFieldArea style={{ width: "14%" }}>
                  <S.CartText style={{ fontWeight: "600" }}>ID: <S.CartText>{item.ID}</S.CartText></S.CartText>
                </S.CardFieldArea>
                <S.CardFieldArea style={{ width: "30%" }}>
                  <S.CartText style={{ fontWeight: "600" }}>Description: <S.CartText>{item.DESCRIPTION}</S.CartText></S.CartText>
                </S.CardFieldArea>
                <S.CardFieldArea style={{ width: "25%" }}>
                  <S.CartText style={{ fontWeight: "600" }}>Value Field: <S.CartText>{item.VALUE.LABEL}</S.CartText></S.CartText>
                </S.CardFieldArea>
                <S.CardFieldArea style={{ width: "25%" }}>
                  <S.CartText style={{ fontWeight: "600" }}>Label Field: <S.CartText>{item.GROUPING.LABEL}</S.CartText></S.CartText>
                </S.CardFieldArea>
              </S.Card>
            ))
          }
        </S.ListArea>
      </S.Container>
    </ModalWrapper>
  )
}
