import React, { useState, useEffect } from 'react'
import * as S from './styles'

export default function SelectionInput({ entity, models, updateForm, setUpdateForm }) {
  const [value, setValue] = useState(models[entity].ID || "")

  useEffect(() => {
    setValue(models[entity].ID || "")
  }, [updateForm])

  return (
    <S.InputContainer>
      <S.Input
        disabled={true}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder="ID"
      />
    </S.InputContainer>
  )
}
