import React, { useContext } from "react"
import dbRequest from "../../../../../../services/dbRequest/dbRequest"
import showAlert from "../../../../../../services/showAlert"
import Form from "../../../../../components/Form/index.web"
import ModalWrapper from "../../../../../components/Modal"
import { ControllerContext } from "../../../contexts/ControllerProvider"
import { ModalsContext } from "../../../contexts/ModalsProvider"
import renderButtons from "../utils/renderButtons"
import Container from "./styles"

export default function CreateFieldModal() {
  const { controller, models } = useContext(ControllerContext)
  const { modalsVisible, openModal, closeModal } = useContext(ModalsContext)

  async function exixstingIdValidation() {
    const fieldID = controller.formatID(models.T_DTD_FLD.ID)

    const tableFields = await dbRequest.loadRecords(`T_DTD_TAB/${models.T_DTD_TAB.ID}/T_DTD_FLD`).execute() as any

    const doesFieldIdExists = tableFields.find(({ ID }) => ID === fieldID)

    doesFieldIdExists
      ? showAlert({
        text: "Field ID already exists",
        title: "Failed to create field",
        titleType: "error"
      })
      : openModal("attributes")
  }

  const buttons = [
    {
      iconName: "check",
      divTitle: "Confirm",
      onPress: () => {
        models.T_DTD_FLD.ID
          ? exixstingIdValidation()
          : showAlert({
            text: "Provide an ID for the field",
            title: "Required field not filled",
            titleType: "error"
          })
      }
    },
    {
      iconName: "close",
      divTitle: "Close",
      onPress: () => {
        models.T_DTD_FLD.reset()
        closeModal("field")
      }
    }
  ]

  return (
    <ModalWrapper isVisible={modalsVisible.field}>
      <Container>
        {renderButtons(buttons)}

        <Form entity={"T_DTD_FLD"} models={models} screenBuilder={true} />
      </Container>
    </ModalWrapper>
  )
}
