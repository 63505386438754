import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import useAppSelector from "../../../../../redux/hooks/useAppSelector"
import generateChartAttributes from "../utils/generateChartAttributes"
import generateChartData from "../utils/generateChartData"
import generateChartEvents from "../utils/generateChartEvents"
import generateTableData from "../utils/generateTableData"

export default function useChartConstruct(
  { chartData, controller, chartId, entityName, chartIdArr, dashboardData }
) {

  const dispatch = useDispatch()

  const mainUpdate = useAppSelector(({ dashboardReducer }) => dashboardReducer.mainUpdate)
  const dashboardParams = useAppSelector(({ dashboardReducer }) => dashboardReducer.dashboardParams)
  const { selectedRow, isUpdated } = useAppSelector(({ dashboardReducer }) => dashboardReducer.charts[chartId])
  const currentLevel = useAppSelector(({ dashboardReducer }) => dashboardReducer.currentLevel)

  const [actualValue, setActualValue] = useState(0)
  const [data, setData] = useState([])
  const [chartValue, setChartValue] = useState({})

  const props = {
    chartValue,
    actualValue,
    chartId,
    chartIdArr,
    controller,
    dashboardParams,
    entityName,
    ...chartData,
    dispatch,
    currentLevel
  }

  useEffect(() => {
    (async () => {
      let updatedData = null
      if (chartData.GADGET.VALUE === "TableChart") updatedData = generateTableData(chartData, dashboardData, dashboardParams, setChartValue, selectedRow, chartId)
      else updatedData = await generateChartData(chartData, dashboardData, dashboardParams, setChartValue, selectedRow, chartId)
      setData(updatedData)

      setActualValue(updatedData.length - 1 > 6 ? 6 : updatedData.length - 1)
    })()
  }, [mainUpdate, isUpdated, chartData])

  useEffect(() => {
    (async () => {
      let updatedData = null
      if (chartData.GADGET.VALUE === "TableChart") updatedData = generateTableData(chartData, dashboardData, dashboardParams, setChartValue, selectedRow, chartId)
      else updatedData = await generateChartData(chartData, dashboardData, dashboardParams, setChartValue, selectedRow, chartId)
      setData(updatedData)
    })()
  }, [selectedRow])

  return {
    data,
    actualValue,
    setActualValue,
    chartEvents: generateChartEvents(props),
    attributes: generateChartAttributes(props)
  }
}
