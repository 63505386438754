import styled from "styled-components"
import { defaultTheme } from "../../../../../../../../../../../styles/themes/default"

export const Container = styled.div`
  align-items: center;
  align-self: flex-start;
  color: ${defaultTheme["gray-900"]};
  display: flex;
  font-size: 20px;
  font-weight: 500;
  justify-content: space-between;
  width: 90%;
`

export const ButtonsView = styled.div`
  align-items: center;
  display: flex;
  gap: 30px;
`

export const SaveButton = styled.button`
  align-items: center;
  background-color: ${defaultTheme["cyan-500"]};
  border-radius: 4px;
  box-shadow: 0px 4px 8px ${defaultTheme["shadow"]};
  color: ${defaultTheme["white"]};
  font-size: 14px;
  justify-content: center;
  padding: 3.5px 10px;
`
