import styled from "styled-components"
//@ts-ignore
import background from "../../images/background-login.jpg"

export const Container = styled.div`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: 100vh;
  width: 100%;
  padding: 25px 0 0 85px;
`

export const WelcomeArea = styled.div`
  position: relative;
  display: flex;
  height: fit-content;
  padding: 10px 20px;
  flex-direction: column;
  gap: 14px;


  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px); 
  -webkit-backdrop-filter: blur(10px); 

  border-radius: 6px;
`

export const DateInfoText = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme["gray-700"]};
  font-weight: 400;
`

export const GreetingsText = styled(DateInfoText)`
  font-size: 20px;
  font-weight: 600;
`

