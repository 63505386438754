import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons"
import React, { useState, useRef } from "react"
import AceEditor from "react-ace"
import { useTranslation } from "react-i18next"
import CloseButton from "../../../CloseButton"
import ModalWrapper from "../../../Modal"
import ModalButtons from "./components/ModalButtons"
import * as S from "./styles"
import "ace-builds/src-noconflict/mode-javascript"
import "ace-builds/src-noconflict/snippets/javascript"
import "ace-builds/src-noconflict/theme-monokai"
import "ace-builds/src-noconflict/ext-language_tools"
import handleSaveFile from "./utils/handleSaveFile"

export default function CodeRunnerModal({ closeModal }) {
  const { t } = useTranslation()
  const [textCode, setTextCode] = useState("")
  const [isActive, setIsActive] = useState(false)
  const [fileName, setFileName] = useState("")
  const [isExportModalOpen, setIsExportModalOpen] = useState(false)
  const fileInputRef = useRef(null)

  const closeExportModal = () => {
    setIsExportModalOpen(false)
  }
  return (
    <S.Container isActive={isActive}>
      <S.FlexContainer>

        <S.Title>{t("Code runner")}</S.Title>
        <ModalButtons textCode={textCode} setTextCode={setTextCode} fileInputRef={fileInputRef} setIsExportModalOpen={setIsExportModalOpen} closeModal={closeModal} />
        <S.FullScreenButton onClick={() => setIsActive((current) => !current)}>
          {isActive ? (
            <MaterialCommunityIcons name="fullscreen-exit" size={42} />
          ) : (
            <MaterialCommunityIcons name="fullscreen" size={42} />
          )}
        </S.FullScreenButton>

      </S.FlexContainer>

      <ModalWrapper isVisible={isExportModalOpen}>
        <S.ModalContainer>
          <CloseButton callbackFunction={closeExportModal} />

          <S.Row>
            <S.TitleModal htmlFor="modal">{t("File Name:")}</S.TitleModal>
            <S.Input
              id="modal"
              type="text"
              placeholder={t("Ex: Code")}
              value={fileName}
              onChange={(e) => setFileName(e.target.value)}
            />
            <S.ButtonModal onClick={() => handleSaveFile({
              fileName,
              textCode,
              setIsExportModalOpen,
              setFileName
            })}>
              {t("Save")}
            </S.ButtonModal>
          </S.Row>
        </S.ModalContainer>
      </ModalWrapper>

      <AceEditor
        placeholder={t("Type your code here")}
        mode={"javascript"}
        theme="monokai"
        name="code-runner"
        fontSize={14}
        lineHeight={21}
        onChange={(newMessage) => setTextCode(newMessage)}
        value={textCode}
        setOptions={{
          enableLiveAutocompletion: true,
          enableSnippets: true,
          showLineNumbers: true
        }}
        style={{
          width: "100%",
          padding: "0px",
          height: isActive ? "100vh" : "500px",
          borderRadius: "8px"
        }}
      />
    </S.Container>
  )
}
