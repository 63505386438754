import React from "react"
import { Field } from "../../../../../../../services/registration/getTableFields"
import Container from "./styles"
import getFieldToRender from "./utils/getFieldToRender"

interface DynamicFieldProps {
	fieldData: Field
	rowData: object
	rowIndex: number
	isSelected: boolean
}

export default function DynamicField({ fieldData, rowData, rowIndex, isSelected }: DynamicFieldProps) {
	const FieldComponent = getFieldToRender({ fieldData })

	const fieldValue = rowData[fieldData.ID] ?? ""

	return (
		<Container isSelected={isSelected} index={rowIndex}>
			<FieldComponent fieldValue={fieldValue} fieldData={fieldData} isSelected={isSelected} />
		</Container>
	)
}
