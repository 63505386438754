import React from "react"
import Header from "./components/Header"
import RenderRoutines from "./components/RenderRoutines"
import Container from "./styles"

export default function Access(props) {
  const permissions = {}

  return (
    <Container>
      <Header {...props} permissions={permissions} />

      <RenderRoutines {...props} permissions={permissions}/>
    </Container>
  )
}

