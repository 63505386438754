import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-left: 83px;
	width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme["white"]};
`

export const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`

export const DropdownButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #DBDADA;
  margin-bottom: 2px;
  margin-left: 12px;
  border-radius: 50%;
  border-bottom-left-radius: ${({ isOpen }) => (isOpen ? "0px" : "50%")};
  border-bottom-right-radius: ${({ isOpen }) => (isOpen ? "0px" : "50%")};
	transition: border-bottom-left-radius .7s ease-in-out, border-bottom-right-radius .7s ease-in-out; /* Transição suave */
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme["white"]};
  border-radius: 50%;
  width: 18px;
  height: 18px;
`

export const DropdownList = styled.ul`
  position: absolute;
  top: 29px;
  left: 12px;
  width: 180px;
  z-index: 1;
  list-style: none;
  margin: 0;
  overflow: hidden;
  max-height: ${({ isOpen }) => (isOpen ? "500px" : "0px")};
  transition: max-height .5s ease-in-out; 
`

export const DropdownListItem = styled.li`
  height: 34px;
  cursor: pointer;
  background-color: ${({ isTabSelected, theme }) => isTabSelected ? theme["cyan-300"] : "#DBDADA"};
  border-top-right-radius: ${({ index }) => (index === 0 ? "14px" : "0px")};
  border-bottom-left-radius: ${({ index, tabsLength }) => index === tabsLength - 1 ? "14px" : "0px"};
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ isTabSelected, theme }) => isTabSelected ? theme["white"] : theme["gray-500"]};
`

export const TextContainer = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
  margin-right: 25px;
  font-size: 14px;
  font-weight: bold;
`

export const CloseButton = styled.button`
  background-color: ${({ theme }) => theme["white"]};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0;
  position: absolute;
  width: 14px;
  height: 14px;
  right: 8px;
`

