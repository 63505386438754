import { Octicons } from "@expo/vector-icons"
import styled from "rn-css"
import globalStyles from "../../../../global/globalStyles"

export const Button = styled.TouchableOpacity``

export const Icon = styled(Octicons)`
  font-size: 20px;
  color: ${globalStyles.colors.primary};
`
