import RuntimeConfiguration from '../../../../../../../models/runtimeConfiguration'
import dbRequest from '../../../../../../../services/dbRequest/dbRequest'

const isAdminMode = RuntimeConfiguration.getDashboardBuilderAdminMode()

export default async function getMainEntitiesList(){
  let entitiesDataResquest = dbRequest.loadRecords("T_DTD_TAB")

  if(isAdminMode) entitiesDataResquest.admin()

  const entitiesData = await entitiesDataResquest.execute()

  const formattedEntitiesData = entitiesData.filter(item => item.TYPE.VALUE !== "RegistrationDetail").map((item) => {
    return {
      ID: item.ID,
      ENTITY: item.ENTITY,
      LABEL: `${item.ID} - ${item.ENTITY}`
    }
  })

  return formattedEntitiesData
}