import showAlert from "../../../../../../services/showAlert"

const handleSaveFile = ({ fileName, textCode, setIsExportModalOpen, setFileName }) => {
  if (!fileName) {
    return showAlert({
      title: "Please enter the file name.",
      titleType: "warning"
    })
  }
  const blob = new Blob([textCode], { type: "text/plain" })
  const url = URL.createObjectURL(blob)
  const a = document.createElement("a")
  a.href = url
  a.download = fileName.endsWith(".js") ? fileName : `${fileName}.js`
  document.body.appendChild(a)
  a.click()
  window.URL.revokeObjectURL(url)
  setIsExportModalOpen(false)
  setFileName("")
}

export default handleSaveFile
