import React from "react"
import { useDrag } from "react-dnd"
import ItemTypes from "../../../../../../../../../components/Panel/components/FiltersConfiguration/components/ItemTypes"
import * as S from "./styles"

export default function Card({ fieldData, currentCollection, setOriginArray, setDestinyArray, setAlternativeDestinyArray }) {

  const { ID, FIELDDESCRIPTION } = fieldData

  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.CARD,
    item: () => ({
      ID,
      FIELDDESCRIPTION
    }),
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult()
      if (item && dropResult) {
        removeField()
        if (dropResult.name === "PrimaryDustbin") {
          addField(setDestinyArray)
        } else if (dropResult.name === "AlternativeDustbin") {
          addField(setAlternativeDestinyArray)
        }
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId()
    })
  }))

  const removeField = () => {
    setOriginArray(state => state.filter(field => field.ID !== ID))
  }

  const addField = (setArray) => {
    const updateFieldData = { ...fieldData }
    updateFieldData.ID = currentCollection?.isDetailScreen ? `${currentCollection.ID}.${ID}` : ID
    updateFieldData.ENTITY = currentCollection?.isDetailScreen ? currentCollection : { ID: fieldData.ENTITY }
    updateFieldData.ISDETAILSCREENFIELD = currentCollection?.isDetailScreen || false,
      setArray(state => [
        ...state,
        { ...updateFieldData }
      ])
  }

  const cardOpacity = isDragging ? 0.4 : 1

  return (
    <div title={FIELDDESCRIPTION}>
      <S.Container ref={drag} data-testid={"card"} style={{ opacity: cardOpacity }}>
        <S.Text numberOfLines={1}>{FIELDDESCRIPTION}</S.Text>
      </S.Container>
    </div>
  )
}
