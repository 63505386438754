import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Platform, StyleSheet, View } from "react-native"
import { ActivityIndicator } from "react-native"
import Modal from "react-native-modal"
import globalStyles from "../../../../global/globalStyles"
import LoginData from "../../../../models/loginData"
import Button from "../components/Button"

export default function renderButtons({
  models,
  entityName,
  functions = {},
  hasToValidatePermissions = false,
  mode,
  setUpdateTable = () => null,
  viewType
}) {
  const screenPermissions = models._REGISTRATION_DATA[entityName].permissions

  const [isLoading, setIsLoading] = useState(false)

  const { switchMode, insertRecord, deleteRecord, copyRecord, saveRecord, undoChanges } = functions

  const { t } = useTranslation()

  const activeLoading = () => setIsLoading(s => !s)

  const getButtonPermission = (permission) => {
    if (LoginData.getLoginData().ADMIN || !hasToValidatePermissions) return true
    return Boolean(screenPermissions[permission])
  }

  const buttons = [
    {
      iconName: mode === "table" ? "wpforms" : "table",
      isVisible: () => switchMode && getButtonPermission("READ"),
      handlePress: () => switchMode(),
      title: mode === "table" ? "Switch to form mode" : "Switch to table mode"
    },
    {
      iconName: "plus",
      isVisible: () => insertRecord && getButtonPermission("CREATE"),
      handlePress: () => insertRecord(),
      title: "Add new record",
      keyboardShortcut: "Ctrl + I"
    },
    {
      iconName: "trash",
      isVisible: () => deleteRecord && getButtonPermission("DELETE"),
      handlePress: () => deleteRecord(activeLoading),
      title: "Delete record",
      keyboardShortcut: "Ctrl + D"
    },
    {
      iconName: "copy",
      isVisible: () => copyRecord && getButtonPermission("CREATE") && (mode === "table" || ["tab", "onlyForm", "orderPanel"].includes(viewType)),
      handlePress: () => copyRecord(),
      title: "Duplicate record"
    },
    {
      iconName: "refresh",
      isVisible: () => mode === "table" || ["tab", "orderPanel"].includes(viewType),
      handlePress: () => setUpdateTable(s => !s),
      title: "Refresh records",
      keyboardShortcut: "Ctrl + R"
    },
    {
      iconName: "save",
      isVisible: () => saveRecord && getVisibility() && (mode === "form" || ["tab", "onlyForm", "readOnly"].includes(viewType)),
      handlePress: () => saveRecord(),
      title: "Save record",
      keyboardShortcut: "Ctrl + S"
    },
    {
      iconName: "undo",
      isVisible: () => undoChanges && getVisibility() && (mode === "form" || ["tab", "onlyForm"].includes(viewType)),
      handlePress: () => undoChanges(),
      title: "Undo changes"
    }
  ]

  const getVisibility = () => {
    if (models._REGISTRATION_DATA[entityName].operation === "INS") return getButtonPermission("CREATE")
    if (models._REGISTRATION_DATA[entityName].operation === "UPD") return getButtonPermission("UPDATE")
    return true
  }

  return (
    <>
      {buttons.map(({ iconName, isVisible, handlePress, title, keyboardShortcut }, index) => {
        return Platform.OS === "web" ?
          <div key={index} title={`${t(title)} ${keyboardShortcut ? `(${keyboardShortcut})` : ""}`}>
            <Button
              iconName={iconName}
              isVisible={isVisible}
              handlePress={handlePress}
            />
          </div>
          :
          <Button
            key={index}
            iconName={iconName}
            isVisible={isVisible}
            handlePress={handlePress}
          />
      })}

      {isLoading &&
        <Modal isVisible={isLoading}>
          <View style={styled.alertContainer}>
            <ActivityIndicator color={globalStyles.colors.primary} size="large" />
          </View>
        </Modal>
      }
    </>
  )
}

const styled = StyleSheet.create({
  alertContainer: {
    width: "15%",
    height: "auto",
    padding: 30,
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
    backgroundColor: globalStyles.colors.tertiary
  }
})
