import styled from "rn-css"

const Container = styled.View`
  flex-direction: row;

  height: 1.5rem;

  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
`

export default Container
