import getFieldsToCompare from "./getFieldsToCompare"

export default ({ models, entityName }) => {
  const { fieldsToCompare } = getFieldsToCompare({
    models,
    entityName
  })

  const modifiedField = fieldsToCompare.find(field => {
    const currentModelValue = models[entityName][field]
    const oldModelValue = models._REGISTRATION_DATA[entityName].originalRecord[field]

    const compareValues = (value1, value2) => {
      // If both values are objects, recursively compare their properties
      if (typeof value1 === "object" && typeof value2 === "object") {
        const keys1 = Object.keys(value1 ?? {})
        const keys2 = Object.keys(value2 ?? {})

        // If number of keys differs, return true (modified)
        if (keys1.length !== keys2.length) return true

        // Iterate over keys and compare values recursively
        for (let key of keys1) {
          if (!(key in value2) || compareValues(value1[key], value2[key])) {
            return true // If key not in value2 or values differ, return true
          }
        }
        return false // All keys and their values are equal
      }

      // If one of the values is not an object, compare them directly
      return String(value1) !== String(value2)
    }

    // Compare the current and old model values recursively
    return compareValues(currentModelValue, oldModelValue)
  })

  return Boolean(modifiedField)
}
