import styled from "rn-css"
import globalStyles from "../../../../../../../global/globalStyles"

export const Container = styled.TouchableOpacity`
  background-color: ${globalStyles.colors.primary};
  width: 250px;
  height: 35px;
  justify-content: center;
  align-items: center;
  border-right-width: 1px;
  border-right-color: ${globalStyles.colors.gridRowColor};
`

export const Title = styled.Text`
  color: ${globalStyles.colors.tertiary};
  font-weight: 400;
`