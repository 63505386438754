import React, { useContext, useState } from "react"
import ModalWrapper from "../../../../../components/Modal"
import ScreenFactory from "../../../../../components/ScreenFactory"
import { ControllerContext } from "../../../contexts/ControllerProvider"
import { ModalsContext } from "../../../contexts/ModalsProvider"
import renderButtons from "../utils/renderButtons"
import * as S from "./styles"

export default function CreateTranslationModal() {

  const { controller, models } = useContext(ControllerContext)
  const { modalsVisible, setModalsVisible } = useContext(ModalsContext)

  const [updateGrid, setUpdateGrid] = useState(false)
  const [, setSelectedItem] = useState(null)

  const buttons = [
    {
      iconName: "plus",
      divTitle: "Add new record",
      onPress: () => clearModel()
    },
    {
      iconName: "trash",
      divTitle: "Delete selected record",
      onPress: () => null
    },
    {
      iconName: "save",
      divTitle: "Save changes",
      onPress: () => controller.saveTranslation()
    },
    {
      iconName: "close",
      divTitle: "Close",
      onPress: () => closeModal()
    }
  ]

  const closeModal = () => {
    models.T_DTD_I18N.reset()
    setModalsVisible(state => ({
      ...state,
      translation: false
    }))
  }

  const clearModel = () => {
    models.T_DTD_I18N.reset()
    setSelectedItem(null)
    setUpdateGrid(s => !s)
  }

  return (
    <ModalWrapper isVisible={modalsVisible.translation}>
      <S.Container>
        {renderButtons(buttons)}

        <S.FormView>
        </S.FormView>

        <S.TableView>
          <ScreenFactory
            entityName={"T_DTD_I18N"}
            controller={controller}
            requisitionType='personalized'
            query={`SELECT * FROM T_DTD_I18N this WHERE this.EN_US='${models.T_DTD_I18N.EN_US}'`}
            viewType='readOnly'
            update={updateGrid}
            selectedCallback={obj => {
              setSelectedItem(obj)
              models.T_DTD_I18N.populate(obj)
            }}
          />
        </S.TableView>
      </S.Container>
    </ModalWrapper>
  )
}
