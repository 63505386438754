import styled from "styled-components"
import { FontAwesome5 } from '@expo/vector-icons'
import { defaultTheme } from "../../../../../../../../styles/themes/default"

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 4px;
`

export const Input = styled.input`
  border: none;
  color: ${defaultTheme["gray-500"]};
  font-size: 1rem;
  font-weight: 600;
  background-color: transparent;
  outline: none;
  border-radius: 8px;
  padding: 2px 10px;
  width: 170px;
  height: ${defaultTheme.formInput.height}px;
  
  ::placeholder {
    color: ${defaultTheme["gray-300"]};
  }
  
  &:focus {
    background-color: ${defaultTheme["white"]};
  }
`

export const Button = styled.button`
  background-color: transparent;
`

export const Icon = styled(FontAwesome5)
  .attrs(({
    color: defaultTheme["gray-700"],
    name: "search",
    size: 16
  }))``