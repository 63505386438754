import { GridComponent } from "@syncfusion/ej2-react-grids"

interface ISetDefaultSettings {
  models: any
  entity: string
  gridRef: React.RefObject<GridComponent> | null
  handleRefresh: () => void
}

const setDefaultSettings = async ({ models, entity, gridRef, handleRefresh }: ISetDefaultSettings) => {
  const fields = models._REGISTRATION_DATA[entity]?.fields

  if (!gridRef.current) {
    console.warn("gridRef is not available")
    return
  }

  gridRef.current.setProperties({
    filterSettings: { columns: [] },
    sortSettings: { columns: [] },
    pageSettings: { currentPage: 1 },
    groupSettings: { columns: [] }
  })

  if (gridRef.current.columns && fields) {

    gridRef.current.columns.forEach((col) => {
      const matchingField = fields.find(field => field.ID === col.field)
      if (matchingField || col.field === "checkbox") {
        col.headerText = matchingField.T_DTD_ATR.FIELDDESCRIPTION || col.headerText
        col.visible = true
        col.width = null
      }
    })
    models._REGISTRATION_DATA[entity].savedProperties = null
    await handleRefresh()
  } else {
    console.warn("No columns or fields found.")
  }
}

export default setDefaultSettings
