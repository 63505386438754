import { MaterialCommunityIcons } from "@expo/vector-icons"
import styled from "styled-components"
import { defaultTheme } from "../../../../../../../../../styles/themes/default"

export const Container = styled.div`
  background-color: ${defaultTheme["gray-200"]};
  display: flex;
  align-self: center;
  align-items: center;
  width: 50%;
  flex-direction: column;
  border-radius: 10px;
  gap: 15px;
`

export const FormView = styled.div`
  overflow-y: auto;
  height: 200px;
  width: 100%;
  background-color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`

export const Row = styled.div`
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 0 10px;
`

export const Title = styled.h1`
  font-size: 20px;
  color: ${defaultTheme["gray-900"]};
  font-weight: 500;
`

export const ButtonsView = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
  align-items: center;
`

export const SaveButton = styled.button`
  background-color: ${defaultTheme["cyan-500"]};
  height: 28px;
  width: 75px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  display: flex;
  
  &:hover {
    box-shadow: 0px 4px 8px ${defaultTheme["shadow"]};
  }
`

export const SaveText = styled.span`
  font-size: 14px;
  color: ${defaultTheme["white"]};
`

export const CloseButton = styled.button`
  background-color: ${defaultTheme["white"]};
  border-radius: 4px;
  display: flex;
  height: 28px;
  width: 28px;
  justify-content: center;
  align-items: center;

  &:hover {
    box-shadow: 0px 4px 8px ${defaultTheme["shadow"]};
  }
`

export const Icon = styled(MaterialCommunityIcons).attrs({
  size: 20,
  color: defaultTheme["gray-900"]
})``
