import { FontAwesome } from "@expo/vector-icons"
import styled from "rn-css"
import globalStyles from '../../../../../../../../global/globalStyles'

export const Container = styled.View`
  height: 100%;
  gap: 10px;
  width: 200px;
`

export const Title = styled.Text`
  font-size: 14px;
  font-weight: 500;
  color: #2e2e2e;
`

export const SearchContainer = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const SearchInput = styled.TextInput`
  background-color: ${globalStyles.colors.tertiary};
  color: #2e2e2e;

  width: 200px;
  padding: 0 4px;
  
  border-bottom: 1px solid ${globalStyles.colors.placeholder};
  outline-style: none;

  font-size: 14px;
`

export const Button = styled.TouchableOpacity``

export const Icon = styled(FontAwesome)`
  color: #2e2e2e;
  font-size: 16px;
`

export const LoadingContainer = styled.View`
  flex: 1;
  justify-content: center;
`

export const Loading = styled.ActivityIndicator.attrs({
  color: globalStyles.colors.secondary,
  size: 30
})``

export const List = styled.ScrollView``

export const TextCollectionNotSelected = styled.Text`
  color: ${globalStyles.colors.placeholder};
  font-size: 12px;
  text-align: center;
`