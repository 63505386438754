import React, { useEffect, useState } from "react"
import Glyphs from "../Glyphs"

export default function GenerateGlyphs({ ChartArray, ChartDataArray, update, setUpdate }) {
  const [glyphs, setGlyphs] = useState([])

  function resize(hoverCoordinate) {
    const selectedX = ChartArray.selectedCoordinate % 12
    const selectedY = Math.floor(ChartArray.selectedCoordinate / 12)

    const hoveredX = hoverCoordinate % 12
    const hoveredY = Math.floor(hoverCoordinate / 12)

    const minX = Math.min(selectedX, hoveredX)
    const maxX = Math.max(selectedX, hoveredX)
    const minY = Math.min(selectedY, hoveredY)
    const maxY = Math.max(selectedY, hoveredY)

    for (let x = 0; x < 144; x++) {
      ChartArray.getPosition(x).setID((() => {
        const id = ChartArray.getPosition(x).getID()
        if (id === 0) return 0
        if (id !== 0 && id === ChartDataArray.getSelectedChart()) return 0
        return id
      })())
    }

    for (let x = minX; x <= maxX; x++) {
      for (let y = minY; y <= maxY; y++) {
        const id = ChartArray.getPosition(y * 12 + x).getID()
        if (id !== 0 && id !== ChartDataArray.getSelectedChart()) {
          for (let i = 0; i < 144; i++)
            if (ChartArray.getPosition(i).getID() === id) {
              ChartArray.getPosition(i).setID(0)
              ChartArray.getPosition(i).setColor("white")
            }
        }
        ChartArray.getPosition(y * 12 + x).setID(ChartDataArray.getSelectedChart())
        ChartArray.getPosition(y * 12 + x).setColor(ChartDataArray.getPosition(ChartDataArray.getSelectedChart() - 1)?.getColor())
      }
    }

    for (let x = 0; x < 144; x++) {
      ChartArray.getPosition(x).setForceUpdate(s => !s)
    }
  }

  function generateGlyphs() {
    return ChartArray.getArr().map((_, index) => (
      <Glyphs
        key={index}
        index={index}
        ChartArray={ChartArray}
        resizeCallback={resize}
        setUpdate={setUpdate}
      />
    ))
  }

  useEffect(() => {
    if (glyphs.length === 0) setGlyphs(generateGlyphs())
  }, [update])

  return <>{glyphs}</>
}
