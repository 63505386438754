import { MaterialIcons } from "@expo/vector-icons"
import { Entypo } from "@expo/vector-icons"
import React, { useEffect, useState } from "react"
import { View } from "react-native"
import { useDispatch } from "react-redux"
import globalStyles from "../../../../../../global/globalStyles"
import useAppSelector from "../../../../../../redux/hooks/useAppSelector"
import {
  changeFormValue,
  closeModal,
  openFieldListModal,
  openFiltersConfigurationModal,
  openVariablesModal,
  setSelectedConnection
} from "../../../../../../redux/redux-reducers/dashboardBuilder-reducer"
import { deleteConnection } from "../../../../../../redux/redux-reducers/dashboardBuilder-reducer/utils/deleteConnection"
import getDashboardFields from "../../../../../../redux/redux-reducers/dashboardBuilder-reducer/utils/getDashboardFields"
import { saveQuery } from "../../../../../../redux/redux-reducers/dashboardBuilder-reducer/utils/saveQuery"
import ModalWrapper from "../../../../../components/Modal"
import * as S from "../modalStyles"
import ExpressionTextInput from "./ExpressionTextInput"
import getMainEntitiesList from "./utils/getMainEntitiesList"

export default function QueryModal() {
  const dispatch = useDispatch()

  const [entitiesList, setEntitiesList] = useState([])
  const [focusedIndex, setFocusedIndex] = useState(false)
  const [queryFieldsList, setQueryFieldsList] = useState([])

  const { name, isVisible, mode, form } = useAppSelector(
    ({ dashboardBuilderReducer }) => dashboardBuilderReducer.modals.queryModal
  )

  const { selectedQuery, path, selectedConnection, selectedDashboard } = useAppSelector(
    ({ dashboardBuilderReducer }) => dashboardBuilderReducer.dashboardData
  )

  useEffect(() => {
    selectedQuery &&
      getDashboardFields(`T_RDB_DSB/${selectedDashboard.ID}/DATA/${selectedQuery.ID}`).then((response) =>
        setQueryFieldsList(response)
      )
  }, [selectedQuery])

  const setExpressionInForm = (value) => {
    dispatch(changeFormValue({
      modalName: name,
      fieldName: "EXPRESSION",
      value
    }))
  }

  return (
    <ModalWrapper isVisible={isVisible}>
      <S.Container style={{ height: form?.ENTITY?.ID === "Custom" ? "80%" : "50%" }}>
        <S.CloseButton onPress={() => dispatch(closeModal({ modalName: name }))}>
          <MaterialIcons name="close" size={20} color="#333333" />
        </S.CloseButton>

        <S.Title>{mode === "new" ? "Create New" : "Edit"} Query</S.Title>

        <S.SelectedView>
          <View style={{
            flexDirection: "row",
            alignItems: "center",
            gap: 3
          }}>
            <S.SelectedText>ID:</S.SelectedText>
            <S.SelectedField>
              <S.TextInput
                value={form?.ID}
                editable={mode === "new"}
                placeholder="Query Name"
                placeholderTextColor={"gray"}
                onChangeText={(tx) => dispatch(changeFormValue({
                  modalName: name,
                  fieldName: "ID",
                  value: tx.trim()
                }))}
              />
            </S.SelectedField>
          </View>
          <View style={{
            flexDirection: "row",
            alignItems: "center",
            gap: 3
          }}>
            <S.SelectedText>Entity:</S.SelectedText>
            <S.SelectedField>
              {mode === "new" ? (
                <S.ListInput
                  data={[
                    {
                      ID: "Custom",
                      ENTITY: "Custom",
                      LABEL: "Custom"
                    },
                    ...entitiesList
                  ]}
                  dropdownPosition="bottom"
                  labelField="LABEL"
                  valueField="ID"
                  isEditable={true}
                  placeholder={form?.ENTITY?.LABEL ?? "Select Entity"}
                  search
                  onChange={(item) => {
                    dispatch(changeFormValue({
                      modalName: name,
                      fieldName: "ENTITY",
                      value: item
                    }))
                  }}
                  onFocus={async () => !entitiesList.length && setEntitiesList(await getMainEntitiesList())}
                />
              ) : (
                <S.TextInput value={`${form?.ENTITY?.ID} - ${form?.ENTITY?.LABEL}`} editable={false} />
              )}
            </S.SelectedField>
          </View>
        </S.SelectedView>

        <S.Header>
          <View style={{
            flexDirection: "row",
            alignItems: "center",
            gap: 5
          }}>
            <S.SubTitle>
              {form?.ENTITY?.ID === "Custom" ? "Expression" : `Connections (${selectedQuery?.connections?.length})`}
            </S.SubTitle>
            {form?.ENTITY?.ID === "Custom" && <div title={`The returned value must be an array with at least one item.

To create parameters it is necessary to add the prefix "models." before the field name.`}>
              <Entypo name="info-with-circle" size={16} color={globalStyles.colors.primary} />
            </div>}
          </View>
          <S.ButtonsView>
            <S.AddButton
              style={{ backgroundColor: globalStyles.colors.editButton }}
              onPress={() => dispatch(openVariablesModal())}
            >
              <S.TitleButton>Configure Variables</S.TitleButton>
            </S.AddButton>
            <S.AddButton
              style={{ backgroundColor: globalStyles.colors.editButton }}
              onPress={() => dispatch(openFiltersConfigurationModal({
                javaScriptFilters: selectedQuery?.FILTER,
                firebaseFilters: selectedQuery?.WHERE
              }))}
            >
              <S.TitleButton>Configure Filters</S.TitleButton>
            </S.AddButton>
            {form?.ENTITY?.ID === "Custom" && (
              <S.AddButton
                style={{ backgroundColor: globalStyles.colors.editButton }}
                onPress={() =>
                  dispatch(
                    openFieldListModal({
                      title: "Query Fields List",
                      fields: queryFieldsList,
                      path: `T_RDB_DSB/${selectedDashboard.ID}/DATA/${selectedQuery.ID}`
                    })
                  )
                }
              >
                <S.TitleButton>Configure Fields</S.TitleButton>
              </S.AddButton>
            )}
            {mode === "new" || form?.ENTITY?.ID === "Custom" ? (
              <S.AddButton onPress={() => dispatch(saveQuery({
                form: form,
                path: path
              }))}>
                <S.TitleButton>Save Query</S.TitleButton>
              </S.AddButton>
            ) : (
              <>
                <S.AddButton
                  onPress={() =>
                    dispatch(
                      setSelectedConnection({
                        masterPath: `${path}/${selectedQuery.ID}`,
                        entityName: selectedQuery.ENTITY.ID,
                        collectionPath: `${selectedQuery.ENTITY.ID}/${"${ID}"}`
                      })
                    )
                  }
                >
                  <S.TitleButton>New Connection</S.TitleButton>
                </S.AddButton>
              </>
            )}
            {selectedQuery?.connections.length > 0 && (
              <S.DeleteButton
                onPress={() =>
                  dispatch(
                    deleteConnection({
                      selectedConnection,
                      connectionPath: `${path}/${selectedQuery.ID}/CONNECTIONS`
                    })
                  )
                }
              >
                <S.DeleteIcon name="trash-alt" />
              </S.DeleteButton>
            )}
          </S.ButtonsView>
        </S.Header>

        <S.UnderLine />

        {form?.ENTITY?.ID !== "Custom" ? (
          <S.ListArea contentContainerStyle={{ gap: 5 }}>
            {selectedQuery?.connections?.map((item, index) => (
              <S.Card
                key={index}
                isSelected={index === focusedIndex}
                onPress={() => {
                  setFocusedIndex(index)
                  dispatch(setSelectedConnection({
                    connection: item,
                    masterPath: `${path}/${selectedQuery.ID}`
                  }))
                }}
              >
                <S.CardFieldArea>
                  <S.CartText style={{ fontWeight: "600" }}>
                    Entity: <S.CartText>{item.ENTITY.LABEL}</S.CartText>
                  </S.CartText>
                </S.CardFieldArea>
                <S.CardFieldArea>
                  <S.CartText style={{ fontWeight: "600" }}>
                    Field: <S.CartText>{item.FIELD ?? item.ENTITY.ID}</S.CartText>
                  </S.CartText>
                </S.CardFieldArea>
                <S.CardFieldArea>
                  <S.CartText style={{ fontWeight: "600" }}>
                    Connection Type: <S.CartText>{item.CONNECTIONTYPE.LABEL}</S.CartText>
                  </S.CartText>
                </S.CardFieldArea>
              </S.Card>
            ))}
          </S.ListArea>
        ) : (
          <ExpressionTextInput expression={form?.EXPRESSION} setExpressionInForm={setExpressionInForm} />
        )}
      </S.Container>
    </ModalWrapper >
  )
}

