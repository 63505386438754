import React from "react"
import { useTranslation } from "react-i18next"
import AccessibilityBox from "../AccessibilityBox"
import usePeriod from "../hooks/usePeriod"
import * as S from "./styles"

export default function Period({ models, field, updateForm }) {
  const { t } = useTranslation()

  const { EDITABLE, REQUIRED, FIELDDESCRIPTION, HELPTEXT } = field.T_DTD_ATR

  const { isRequired, initial, final, commonProps, setFinal, setInitial } =
    usePeriod({
      models,
      field,
      updateForm
    })

  return (
    <S.Container>
      <S.Row>
        <S.Title isRequired={REQUIRED}>{t(FIELDDESCRIPTION)}</S.Title>
        <AccessibilityBox text={HELPTEXT} />
      </S.Row>

      <S.InputContainer>
        <S.Input
          type="date"
          placeholder={t(FIELDDESCRIPTION)}
          isEditable={EDITABLE}
          disabled={!EDITABLE}
          {...commonProps}
          value={initial}
          onChange={(e) => setInitial(e.target.value)}
        />

        <S.Text isRequired={isRequired} value={initial && final}>
          {t("until")}
        </S.Text>

        <S.Input
          type="date"
          placeholder={t(FIELDDESCRIPTION)}
          isEditable={EDITABLE}
          disabled={!EDITABLE}
          {...commonProps}
          value={final}
          onChange={(e) => setFinal(e.target.value)}
        />
      </S.InputContainer>
    </S.Container>
  )
}
1
