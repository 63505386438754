import styled from "styled-components"
import { FontAwesome } from '@expo/vector-icons';
import { defaultTheme } from "../../../../styles/themes/default";

export const Container = styled.div`
  display: flex;
  background-color: ${defaultTheme[`gray-200`]};
  width: 100%;
  padding: 15px 20px 8px;
  justify-content: space-between;
`

export const ItemsContainer = styled.div`
  display: flex;
  gap: 10px;
  height: 100%;
  align-items: flex-end;
`

export const ReturnButton = styled.button`
  align-items: center;
  background-color: ${defaultTheme["white"]};
  border: 1px solid ${defaultTheme["gray-600"]};
  border-radius: 50%;
  display: flex;
  height: 16px;
  justify-content: center;
  padding-right: 1px;
  width: 16px;
  align-self: flex-start;
`

export const ReturnIcon = styled(FontAwesome)
  .attrs(({
    color: defaultTheme["gray-600"],
    name: "caret-left",
    size: 14
  }))``
