import styled from "styled-components/native"
import globalStyles from "../../../global/globalStyles"

const Container = styled.View`
  flex: 1;
  padding: 5px 10px 5px 63px;
  background-color: ${globalStyles.colors.background};
`

export default Container
