export default function compareValues({ models, entityName, a, b, selectedField }) {
  const { ID } = selectedField
  const { sorting } = models._REGISTRATION_DATA[entityName].headerSortingData

  const isAscending = sorting === "ASC"

  if (a === null && b === null) return 0
  if (a === null) return 1
  if (b === null) return -1

  // If both values exist, compare them.
  const valueA = formatValue(a, ID)
  const valueB = formatValue(b, ID)

  if (isAscending) {
    if (valueA < valueB) return -1
    if (valueA > valueB) return 1
    return 0
  }

  if (valueA < valueB) return 1
  if (valueA > valueB) return -1
  return 0
}

function formatValue(value, ID) {
  const numericValue = Number(value)

  if (isNaN(numericValue) || ID === "ID")
    return String(value).toUpperCase()

  return numericValue
}
