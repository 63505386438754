import dbRequest from "../../../../../../../../../../services/dbRequest/dbRequest"
import showAlert from "../../../../../../../../../../services/showAlert"

export default async (entityName, models, closeModal, permissions) => {
  const [record] = await dbRequest
    .loadRecords(entityName)
    .where("ID", "==", models[entityName].ID)
    .execute()

  for (const key in record.T_SET_ACC) {
    if (!permissions[key] && Object.keys(record.T_SET_ACC[key]).length > 0) permissions[key] = {}
    permissions[key] = {
      ...record.T_SET_ACC[key],
      ...permissions[key]
    }
  }

  await dbRequest
    .updateRecords(entityName)
    .record({
      ...record,
      T_SET_ACC: { ...permissions }
    })
    .execute()

  showAlert({
    text: "Access configurations will be applied after the next login",
    title: "Saved successfully",
    titleType: "success"
  })
  closeModal()
}
