export default (models, field) => { //Specific function of ScreenBuilder
  const { ID, TABLENAME } = field

  if ( //Dont render PKTYPE field if PKSOURCE is "Automatic"
    TABLENAME === "T_DTD_TAB"
    && models.T_DTD_TAB.PKSOURCE?.VALUE === "A"
    && ID === "PKTYPE"
  ) return false

  if (TABLENAME !== "T_DTD_ATR") return true //Only validates in attributes form

  const hasId = ["DECIMAL", "ENTITY", "FUNCTION", "LABEL", "MASK", "SIZE", "MULTIPLEFILES"].includes(ID) //IDs to validate

  if (!hasId) return true

  const masterFieldType = models.T_DTD_FLD.FIELDTYPE.VALUE

  switch (masterFieldType) { //Block render based on current field creation
    case "A": return ["MULTIPLEFILES"].includes(ID)
    case "C":
    case "F": return ["DECIMAL", "FUNCTION"].includes(ID)
    case "I": return ["FUNCTION"].includes(ID)
    case "K": return ["ENTITY", "LABEL"].includes(ID)
    case "M": return ["SIZE"].includes(ID)
    case "S": return ["MASK", "SIZE"].includes(ID)
    default: return !hasId
  }
}
