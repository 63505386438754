import styled from "rn-css"
import globalStyles from "../../../../../../../../../../global/globalStyles"

const Text = styled.Text`
  color: ${({ isSelected }) => isSelected ? globalStyles.colors.tertiary : globalStyles.colors.primary};
  font-size: 12px;
  text-align: right;
  width: 100%;
  padding-right: 3px;
`

export default Text
