import showAlert from "./showAlert"

const formValidation = ({ entity, models, fields }) => {
  let isValid = false

  let formattedFields = []
  fields.filter(field => field.T_DTD_ATR.REQUIRED)
    .map(field => formattedFields.push({
      FIELDNAME: field.ID,
      FIELDDESCRIPTION: field.T_DTD_ATR.FIELDDESCRIPTION,
      FIELDTYPE: field.FIELDTYPE.VALUE
    }))

  if (formattedFields.length === 0) isValid = true

  let valid = []

  for (const field of formattedFields) {

    switch (field.FIELDTYPE) {
      case "K":
      case "N":
        if (verifyValue(models[entity][field.FIELDNAME].DESCRIPTION) === false) {
          showAlert({
            text: `${field.FIELDDESCRIPTION}`,
            title: "Required field not filled",
            titleType: "warning"
          })
        } else valid.push(field.FIELDNAME)
        break

      case "L":
        if (verifyValue(models[entity][field.FIELDNAME].LABEL) === false) {
          showAlert({
            text: `${field.FIELDDESCRIPTION}`,
            title: "Required field not filled",
            titleType: "warning"
          })
        } else valid.push(field.FIELDNAME)
        break

      case "O":
        if (models[entity][field.FIELDNAME].length === 0 || models[entity][field.FIELDNAME] === undefined) {
          showAlert({
            text: `${field.FIELDDESCRIPTION}`,
            title: "Required field not filled",
            titleType: "warning"
          })
        } else valid.push(field.FIELDNAME)
        break

      default:
        if (verifyValue(models[entity][field.FIELDNAME]) === false) {
          showAlert({
            text: `${field.FIELDDESCRIPTION}`,
            title: "Required field not filled",
            titleType: "warning"
          })
        } else valid.push(field.FIELDNAME)
        break
    }

    if (valid.length === formattedFields.length) isValid = true

  }
  return isValid

}

const verifyValue = (value) => {
  if (value === "" || value === undefined || value === null) return false
  return true
}

export default formValidation
