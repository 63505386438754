import styled from "styled-components"
import { defaultTheme } from "../../../../../styles/themes/default"

export const Container = styled.div`
  background-color: ${defaultTheme["white"]};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  height: 24.8px;
  padding: 0 9px;
`

export const Title = styled.span`
  color: ${defaultTheme["gray-500"]};
  font-size: 0.7875rem;
  margin-right: 9px;
`

export const Input = styled.select`
  background-color: ${defaultTheme["white"]};
  color: ${defaultTheme["gray-700"]};
  font-size: 0.7875rem;
  border: none;
  width: 144px;
  height: ${defaultTheme.formInput.height * 0.9}px;
  outline: none;

  ::placeholder {
    color: ${defaultTheme["gray-500"]};
    font-size: 0.7875rem;
  }
`

export const Label = styled.option``

export const ActionButton = styled.button`
  align-items: center;
  background-color: ${defaultTheme["cyan-300"]};
  border-radius: 45%;
  display: flex;
  height: 19.8px;
  justify-content: center;
  width: 19.8px;
  margin-left: 9px;

  transition: opacity 0.2s;

  &:active {
    opacity: 0.3;
  }
`

export const ModalContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 144px;
  width: 315px;
  padding: 9px;
  border-radius: 9px;
  background-color: ${defaultTheme["white"]};
`

export const ModalTitle = styled.span`
  color: ${defaultTheme["gray-700"]};
  text-align: center;
  font-size: 0.9rem;
  font-weight: 500;
`

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const InputTitle = styled.p`
  color: ${defaultTheme["cyan-800"]};
  font-size: ${defaultTheme.formInput.fontSize * 0.9}px;
  font-weight: bold;
  line-height: 18px;
  text-align: start;
  &::after {
    content: "*";
    color: ${defaultTheme["cyan-800"]};
    display: inline;
  }
`

export const InputDescription = styled.input`
  background-color: ${defaultTheme["white"]};
  color: ${defaultTheme["gray-700"]};
  font-size: ${defaultTheme.formInput.fontSize * 0.9}px;
  width: 81%;
  border: 1px solid ${defaultTheme["gray-300"]};
  border-radius: 9px;
  padding: 0 9px;
  height: 30.6px;
  outline: none;

  ::placeholder {
    color: ${defaultTheme["gray-300"]};
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 18px;
`

export const Button = styled.button`
  background-color: ${defaultTheme["cyan-700"]};
  border-radius: 9px;
  color: ${defaultTheme["white"]};
  font-size: 0.7875rem;
  font-weight: 500;
  height: 27px;
  width: 90px;
`
