import { useState } from "react"
import { useTranslation } from "react-i18next"
import ModalWrapper from "../../../../../../Modal"
import * as S from "./styles"
import handleDrop from "./utils/handleDrop"
import handleRemove from "./utils/handleRemove"
import handleSave from "./utils/handleSave"
import handleSelect from "./utils/handleSelect"

interface ModalProps {
  modalVisible: boolean
  models: any
  entity: string
  closeModal: () => void
  fileData: {
    file: File | null
    preview: string | null
    source: string | null
  }
  setFileData: (fileData) => void
}

export default function PhotoManagerModal({ modalVisible, closeModal, models, entity, fileData, setFileData }: ModalProps) {
  const { t } = useTranslation()

  const allowedFileTypes = ".jpeg, .jpg, .pdf, .png"

  const [isDragging, setIsDragging] = useState(false)

  const handleDragOver = (event) => {
    event.preventDefault()
    setIsDragging(true)
  }

  const handleDragLeave = () => {
    setIsDragging(false)
  }

  const handleCloseModal = () => {
    setFileData(state => ({ ...state, preview: state.source }))
    closeModal()
  }

  return (
    <ModalWrapper isVisible={modalVisible}>
      <S.Container>
        <S.Header>
          <S.Title>{t("Record Image")}</S.Title>
          <S.Text>{t("Select the record image")}</S.Text>
        </S.Header>

        <S.ModalContent>
          <S.ImageContainer onClick={() => handleRemove({ fileData, setFileData })}>
            {fileData.preview
              ? <S.RecordImage src={fileData.preview} />
              : <S.ImageIcon />
            }

            <S.DeleteContainer className='trash-icon'>
              <S.DeleteIcon />
            </S.DeleteContainer>
          </S.ImageContainer>

          <S.FileSelectionContainer
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={(event) => handleDrop({ event, setIsDragging, setFileData })}
            isDragging={isDragging}
          >
            <S.FileInput
              accept={allowedFileTypes}
              type="file"
              id="fileInput"
              onChange={(event) => handleSelect({ event, setFileData })}
            />
            <S.InputLabel htmlFor="fileInput">{t("Choose file")}</S.InputLabel>
            <S.Text>{t("or drag and drop")}</S.Text>
          </S.FileSelectionContainer>
        </S.ModalContent>

        <S.ButtonsContainer>
          <S.SaveButton onClick={() => handleSave({ fileData, setFileData, closeModal, models, entity })}>
            {t("Save")}
          </S.SaveButton>

          <S.CancelButton onClick={handleCloseModal}>
            {t("Cancel")}
          </S.CancelButton>
        </S.ButtonsContainer>
      </S.Container>
    </ModalWrapper>
  )
}