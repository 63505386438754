import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import useAppSelector from "../../../redux/hooks/useAppSelector"
import { setInitialState } from "../../../redux/redux-reducers/dashboard-reducer"
import { DashboardResponse, Fields } from "../types/types"
import dashboardRequest from "../utils/dashboardRequest"
import getDashboard from "../utils/getDashboard"

export default function useDashboard(idDashboard: string, models: any) {

  const mainUpdate = useAppSelector(({ dashboardReducer }) => dashboardReducer.mainUpdate)
  const currentLevel = useAppSelector(({ dashboardReducer }) => dashboardReducer.currentLevel)

  const dispatch = useDispatch()

  const [dashboard, setDashboard] = useState<DashboardResponse[]>([])
  const [dashboardData, setDashboardData] = useState({})
  const [fields, setFields] = useState<Fields>()
  const [isLoading, setIsLoading] = useState(true)
  const [isValid, setIsValid] = useState(true)

  useEffect(() => {
    (async () => {
      setIsLoading(true)
      const { dashboardFields, dashboardLevels, dashboardData, canShowDashboard } = await dashboardRequest(idDashboard, models)
      
      setFields(dashboardFields)
      setDashboardData(dashboardData)
      setIsValid(canShowDashboard)

      if(!canShowDashboard) {
        setIsLoading(false)
        setIsValid(canShowDashboard)
        return
      }

      const responseDashboard = getDashboard(dashboardLevels, currentLevel)
      setDashboard(responseDashboard)
        
      dispatch(setInitialState({
        charts: responseDashboard.reduce((acc, chart) => {
          return {
            ...acc,
            [chart.chartId]: { isUpdated: false }
          }
        }, {})
      }))
      setIsLoading(false)
    })()
  }, [currentLevel, mainUpdate])

  return {
    dashboard,
    dashboardData,
    fields,
    isLoading,
    isValid
  }
}
