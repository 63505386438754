import { MaterialIcons } from "@expo/vector-icons"
import React, { useEffect, useState } from "react"
import { View } from "react-native"
import RuntimeConfiguration from "../../../../../../models/runtimeConfiguration"
import menuConstructor from "../../../../../../services/menuConstructor"
import ModalWrapper from "../../../../../components/Modal"
import * as S from "../modalStyles"
import RenderMenuOptions from "./components/RenderMenuOptions"
import saveNavigation from "./utils/saveNavigation"

export default function DashboardLauncher({
  dashboardLauncherIsVisible,
  selectedDashboard,
  models,
  closeModal
}) {

  const isAdmin = RuntimeConfiguration.getDashboardBuilderAdminMode()

  const [menuOptions, setMenuOptions] = useState([])
  const [, setUpdate] = useState(false)

  useEffect(() => {
    menuConstructor().then((response) => {
      setMenuOptions((response))
    })
  }, [])

  return (
    <ModalWrapper isVisible={dashboardLauncherIsVisible}>
      <S.Container style={{
        height: "75%",
        width: "45%"
      }}>
        <S.CloseButton onPress={closeModal}>
          <MaterialIcons name="close" size={20} color="#333333" />
        </S.CloseButton>

        <S.Title>Dashboard Launcher</S.Title>

        <S.SelectedView>
          <S.SelectedField>
            <View style={{
              flexDirection: "row",
              alignItems: "center",
              gap: 3
            }}>
              <S.SelectedText>ID:</S.SelectedText>
              <S.TextInput
                value={selectedDashboard.ID}
                editable={false}
              />
            </View>
          </S.SelectedField>
          <S.SelectedField>
            <View style={{
              flexDirection: "row",
              alignItems: "center",
              gap: 3
            }}>
              <S.SelectedText>Description:</S.SelectedText>
              <S.TextInput
                value={selectedDashboard.DESCRIPTION}
                editable={false}
              />
            </View>
          </S.SelectedField>
        </S.SelectedView>

        <S.Header>
          <S.SubTitle>Set location for selected dashboard</S.SubTitle>
          <S.ButtonsView>
            <S.AddButton onPress={async () => await saveNavigation({
              selectedDashboard,
              models,
              closeModal
            })}>
              <S.TitleButton>Save</S.TitleButton>
            </S.AddButton>
            {/*  <S.DeleteButton onPress={() => null}>
              <S.DeleteIcon name="trash-alt" />
            </S.DeleteButton> */}
          </S.ButtonsView>
        </S.Header>

        <S.UnderLine />

        {menuOptions && <RenderMenuOptions
          options={menuOptions}
          models={models}
          setUpdate={setUpdate}
          isAdmin={isAdmin}
        />}

      </S.Container>
    </ModalWrapper >
  )
}
