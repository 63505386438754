import LoginData from "../../../../../../../../models/loginData"
import dbRequest from "../../../../../../../../services/dbRequest/dbRequest"
import getTableFields from "../../../../../../../../services/registration/getTableFields"

export default async function getFilters({ entityName, models }) {
  let filters = await dbRequest
    .loadRecords(`T_DTD_TAB/${entityName}/T_DTD_FLT`)
    .where("ID", "==", LoginData.getLoginData().ID)
    .execute()

  if (!filters) return []

  filters = filters[0].FILTERS

  const filtersIDs = Object.keys(filters ?? {})
  if (filtersIDs.length === 0) return []

  const fields = models._REGISTRATION_DATA[entityName].fields ?? await getTableFields({ entityName })

  const filterFields = fields.filter(({ ID }) => filtersIDs.includes(ID))

  filters = filterFields.map(({ ID, T_DTD_ATR }) => ({
    id: ID,
    description: T_DTD_ATR.FIELDDESCRIPTION,
    required: filters[ID].REQUIRED,
    order: filters[ID].ORDERBY
  }))

  filters.sort((a, b) => a.order - b.order)

  return filters
}
