import { Platform } from "react-native"
import CurrencyInput from "react-native-currency-input"
import { Dropdown } from "react-native-element-dropdown"
import { TextInputMask } from "react-native-masked-text"
import styled from "styled-components/native"
import globalStyles from "../../global/globalStyles"
import { fontFamily } from "../../styles/themes/default"

const commonProps = `
  color: ${globalStyles.colors.textCard};
  background-color: ${globalStyles.colors.inputsBackground};
  font-size: ${globalStyles.sizes.fontSize}px;
  border-radius: 3px;
  height: ${globalStyles.sizes.inputHeight}px;
  padding: 6px;
  ${Platform.OS === "web" ? "" : "font-family: " + fontFamily["regular"] + ";"}
  ${Platform.OS === "web" ? "outline-width: 0;" : ""}
`

export const Input = styled.TextInput`
  ${commonProps}
`

export const InputMask = styled(TextInputMask)`
  ${commonProps}
`

export const SelectionInput = styled(Dropdown)`
  ${commonProps}
`

export const InputField = styled(CurrencyInput)`
  ${commonProps}
`
