import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import * as S from "./styles"

export default function IdSourceInput({ entityName, models, setUpdateForm = () => null }) {
  const { t } = useTranslation()

  const tableData = models._REGISTRATION_DATA[entityName]

  const doesPkIsString = tableData.pkType === "S"

  const [state, setState] = useState(tableData.pkSource === "M")

  const handlePress = () => {
    tableData.manualID = !state
    delete models[entityName].ID
    setState(s => !s)
    setUpdateForm(s => !s)
  }

  return tableData.operation === "INS" && doesPkIsString && ( //tableData.operation === "INS" && doesPkIsString &&
    <S.Container>
      <S.Box isActive={state} onPress={handlePress}>
        <S.Icon name='check' isActive={state} />
      </S.Box>

      <S.Title>{t("Manual ID")}</S.Title>
    </S.Container>
  )
}
