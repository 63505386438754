import React, { useState, useEffect } from 'react'
import * as S from './styles'

export default function DatetimeInput({ entity, models, updateForm, setUpdateForm }) {
  const [value, setValue] = useState(models[entity].ID || "")

  const tableData = models._REGISTRATION_DATA[entity]

  useEffect(() => {
    setValue(formatValue(models[entity].ID || ""))
  }, [updateForm])

  const isAnInsertion = tableData.operation === "INS"

  return (
      <S.Input
        type='datetime-local'
        editable={isAnInsertion}
        disabled={!isAnInsertion}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() => models[entity].ID = formatValue(value)}
      />
  )
}

function formatValue(value: string): string {
  if (value) {
    return value.length === 19 // YYYY-MM-DDTHH:MM:SS
      ? value
      : `${value}:00`
  }

  return null
}
