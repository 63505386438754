/* eslint-disable import/prefer-default-export */
import RuntimeConfiguration from "../../../../models/runtimeConfiguration"
import dbRequest from "../../../../services/dbRequest/dbRequest"

export const getDashboadID = async () => {
  const isAdminMode = RuntimeConfiguration.getDashboardBuilderAdminMode()
  let collection = "T_RDB_DSB"
  let dashboardIdRequest = dbRequest.loadRecords("PRETTY_IDS").where("ID", "==", collection)

  if (isAdminMode) dashboardIdRequest = dashboardIdRequest.admin()

  let dashboardId = await dashboardIdRequest.execute(async (r) => {
    if (!r) r = [{
      autoIncrementField: 0,
      ID: collection
    }]
    let id = Number(r[0].autoIncrementField)
    id++

    r[0].autoIncrementField = id

    await dbRequest.saveRecords("PRETTY_IDS").record(r[0])
      .execute()

    return isAdminMode ? `DSB${id}` : `Z_DSB${id}`
  })

  return dashboardId
}
