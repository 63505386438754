import React, { useContext } from "react"
import { useDrag } from "react-dnd"
import { useTranslation } from "react-i18next"
import { FiltersContext } from "../../../../contexts/FiltersContext"
import ItemTypes from "../../../ItemTypes"
import * as S from "./styles"

export default function Card({ fieldData }) {
  const { t } = useTranslation()
  const { setFields, setFilters } = useContext(FiltersContext)
  const { id, description } = fieldData

  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.CARD,
    item: () => ({
      id,
      description
    }),
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult()
      if (item && dropResult) {
        removeField()
        addFilter()
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId()
    })
  }))

  const removeField = () => {
    setFields(state => {
      return state.filter(field => field.description !== description)
    })
  }

  const addFilter = () => {
    setFilters(state => {
      return [...state, fieldData]
    })
  }

  const cardOpacity = isDragging ? 0.4 : 1

  return (
    <div title={t(description)}>
      <S.Container ref={drag} data-testid={"card"} style={{ opacity: cardOpacity }}>
        <S.Text numberOfLines={1}>{t(description)}</S.Text>
      </S.Container>
    </div>
  )
}
