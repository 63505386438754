import React, { useContext, useRef } from "react"
import { useDrag, useDrop } from "react-dnd"
import { useTranslation } from "react-i18next"
import { FiltersContext } from "../../../../contexts/FiltersContext"
import ItemTypes from "../../../ItemTypes"
import * as S from "./styles"

export default function Card({ filterData, index, moveCard }) {
  const { t } = useTranslation()
  const ref = useRef(null)
  const { id, description, required } = filterData
  const { setFilters, setFields } = useContext(FiltersContext)

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      }
    },
    hover(item, monitor) {
      if (!ref.current) return

      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === undefined || hoverIndex === undefined) return
      if (dragIndex === hoverIndex) return

      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return

      moveCard(dragIndex, hoverIndex)

      item.index = hoverIndex
    }
  })
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => ({
      id,
      index
    }),
    collect: (monitor) => ({ isDragging: monitor.isDragging() })
  })

  const cardOpacity = isDragging ? 0 : 1

  drag(drop(ref))

  const handlePress = () => {
    setFilters(state => {
      const newState = [...state]
      newState[index].required = !newState[index].required

      return newState
    })
  }

  const removeFilter = () => {
    setFilters(state => {
      return state.filter(filter => filter.description !== description)
    })
  }

  const addField = () => {
    setFields(state => {
      const newState = [...state, {
        ...filterData,
        required: false
      }]
      newState.sort((a, b) => t(a.description).localeCompare(t(b.description)))
      return newState
    })
  }

  return (
    <S.Container ref={ref} style={{ opacity: cardOpacity }} data-handler-id={handlerId}>
      <S.Text>{t(description)}</S.Text>

      <S.ButtonsView>
        <S.Box isActive={required} onPress={handlePress}>
          <S.Icon name='check' isActive={required} />
        </S.Box>

        <S.DeleteButton onPress={() => {
          removeFilter()
          addField()
        }}>
          <S.DeleteIcon name="trash" />
        </S.DeleteButton>
      </S.ButtonsView>
    </S.Container>
  )
}
