import dbRequest from "../../../../../../../../../../services/dbRequest/dbRequest"

export default () => {
  return dbRequest
    .loadRecords("T_RDB_DSB")
    .execute(dashboardsData => {
      const dashboards = {
        ID: "dashboards",
        IDACCESS: "dashboards",
        DESCRIPTION: "Dashboards",
        icon: "arrow-right",
        T_DTD_FNC: {}
      }

      dashboardsData.forEach(dashboard => {
        const { T_DTD_TNS } = dashboard

        Object.entries(T_DTD_TNS)
          .forEach(([transactionId, transaction]) => {
            dashboards.T_DTD_FNC[transactionId] = {
              ID: transactionId,
              DESCRIPTION: transaction.DESCRIPTION,
              ACTIVE: true
            }
          })
      })

      return dashboards
    })
}
