import { doc, updateDoc } from "firebase/firestore/lite"
import globalDate from "../../../../global/globalDate"
import LoginData from "../../../../models/loginData"
import checkSession from "../../checkSession"
import { dbConnection } from "../../dbConnection"

export default async function updateRecords(admin, collection, record) {

  if (!await checkSession(admin)) new Error("Session expired")

  const _doc = doc(dbConnection(admin), collection, record.ID)
  const _id = record.ID
  delete record.ID

  if (record.SESSIONID === undefined || record.SESSIONID === null) {
    const creationDate = new globalDate().toISO()

    const { ID, DESCRIPTION, IMG = [] } = LoginData.getLoginData()
    const userObj = {
      ID,
      DESCRIPTION,
      IMG
    }

    record.UPDATED_BY = userObj
    record.UPDATED_AT = creationDate
  }

  await updateDoc(_doc, { ...record })

  return {
    ...record,
    ID: String(_id)
  }
}
