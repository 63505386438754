import { MaterialIcons } from "@expo/vector-icons"
import { MaterialCommunityIcons } from "@expo/vector-icons"
import React, { useState } from "react"
import { Text } from "react-native"
import { useDispatch } from "react-redux"
import useAppSelector from "../../../../../../redux/hooks/useAppSelector"
import { closeModal, openConfigureOptionsListModal, setSelectedField } from "../../../../../../redux/redux-reducers/dashboardBuilder-reducer"
import { deleteDashboardField } from "../../../../../../redux/redux-reducers/dashboardBuilder-reducer/utils/deleteDashboardField"
import ModalWrapper from "../../../../../components/Modal"
import * as S from "../modalStyles"

export default function FieldListModal({ controller }) {

  const dispatch = useDispatch()

  const [focusedIndex, setFocusedIndex] = useState(null)

  const { isVisible, name, fieldsArray, path, title } = useAppSelector(({ dashboardBuilderReducer }) => dashboardBuilderReducer.modals.fieldListModal)

  return (
    <ModalWrapper isVisible={isVisible}>
      <S.Container style={{ width: "45%" }}>
        <S.CloseButton onPress={async () => {
          dispatch(closeModal({ modalName: name }))
        }}>
          <MaterialIcons name="close" size={20} color="#333333" />
        </S.CloseButton>

        <S.Title>{title}</S.Title>

        <S.Header style={{
          flexDirection: "row-reverse",
          marginVertical: 10,
          marginRight: 20
        }}>
          <S.ButtonsView>

            <S.AddButton onPress={() => {
              controller.models().T_DTD_FLD.reset()
              controller.models().T_DTD_ATR.reset()
              dispatch(setSelectedField({
                field: null,
                path
              }))
            }}>
              <S.TitleButton>New Field</S.TitleButton>
            </S.AddButton>

            <S.DeleteButton onPress={() => {
              setFocusedIndex(null)
              dispatch(deleteDashboardField({
                path: `${path}`,
                selectedField: { ...controller.models().T_DTD_FLD }
              }))
              controller.models().T_DTD_FLD.reset()
              controller.models().T_DTD_ATR.reset()
            }}>
              <S.DeleteIcon name="trash-alt" />
            </S.DeleteButton>
          </S.ButtonsView>
        </S.Header>

        <S.ListArea contentContainerStyle={{ gap: 5 }}>
          {fieldsArray.length > 0 ?
            fieldsArray?.map((item, index) => (
              <S.Card
                key={index}
                isSelected={index === focusedIndex}
                onPress={() => {
                  setFocusedIndex(index)
                  controller.models().T_DTD_FLD.ID = item.ID
                  controller.models().T_DTD_FLD.FIELDTYPE = item.FIELDTYPE
                  controller.models().T_DTD_ATR.populate(item.T_DTD_ATR)
                  dispatch(setSelectedField({
                    field: item,
                    path: `${path}`
                  }))
                }}
              >
                <S.CardFieldArea style={{ width: "20%" }}>
                  <S.CartText style={{ fontWeight: "600" }}>ID: <S.CartText>{item.ID}</S.CartText></S.CartText>
                </S.CardFieldArea>
                <S.CardFieldArea style={{ width: "40%" }}>
                  <S.CartText style={{ fontWeight: "600" }}>Description: <S.CartText>{item.T_DTD_ATR.FIELDDESCRIPTION}</S.CartText></S.CartText>
                </S.CardFieldArea>
                <S.CardFieldArea style={{ width: "30%" }}>
                  <S.CartText style={{ fontWeight: "600" }}>Field Type: <S.CartText>{item.FIELDTYPE.LABEL}</S.CartText></S.CartText>
                </S.CardFieldArea>

                {item.FIELDTYPE.VALUE === "L" && <S.EditButton onPress={() => {
                  setFocusedIndex(index)
                  controller.models().T_DTD_FLD.ID = item.ID
                  controller.models().T_DTD_FLD.FIELDTYPE = item.FIELDTYPE
                  controller.models().T_DTD_ATR.populate(item.T_DTD_ATR)
                  dispatch(setSelectedField({ field: item }))
                  dispatch(openConfigureOptionsListModal())
                }}>
                  <MaterialCommunityIcons name="playlist-edit" size={16} color="#fff" />
                </S.EditButton>}
              </S.Card>
            )) :
            <Text style={{
              textAlign: "center",
              marginTop: 30,
              color: "#5e5e5e"
            }}>No fields to be displayed.</Text>
          }
        </S.ListArea>
      </S.Container>
    </ModalWrapper>
  )
}
