import {
  Aggregate,
  ColumnChooser,
  Edit,
  ExcelExport,
  Filter,
  Group,
  Page,
  Reorder,
  Resize,
  Selection,
  Sort,
  Toolbar
} from "@syncfusion/ej2-react-grids"

const services = [
  Aggregate,
  ColumnChooser,
  Edit,
  ExcelExport,
  Filter,
  Group,
  Page,
  Reorder,
  Resize,
  Selection,
  Sort,
  Toolbar
]

export default services
