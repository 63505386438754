import React, { useState } from "react"
import { useDispatch } from "react-redux"
import useAppSelector from "../../../../../redux/hooks/useAppSelector"
import { setSelectedQuery } from "../../../../../redux/redux-reducers/dashboardBuilder-reducer"
import { deleteQuery } from "../../../../../redux/redux-reducers/dashboardBuilder-reducer/utils/deleteQuery"
import * as S from "./styles"

export default function DashboardQueries({ controller }) {

  const dispatch = useDispatch()

  const { queries, selectedQuery, path } = useAppSelector(({ dashboardBuilderReducer }) => dashboardBuilderReducer.dashboardData)

  return (
    <S.Container>
      <S.Header>
        <S.Title>Queries ({queries.length})</S.Title>
        <S.ButtonsView>
          <S.AddQueryButton onPress={() => dispatch(setSelectedQuery("new"))}>
            <S.TitleButton>New Query</S.TitleButton>
          </S.AddQueryButton>
          <S.DeleteQueryButton onPress={() => dispatch(deleteQuery({
            selectedQuery,
            queryPath: path
          }))}>
            <S.DeleteIcon name="trash-alt" />
          </S.DeleteQueryButton>
        </S.ButtonsView>
      </S.Header>
      <S.UnderLine />

      <S.ListArea>
        {
          queries.map((item) => (
            <S.Card key={item.ID} isSelected={item.ID === selectedQuery?.ID} onPress={() => dispatch(setSelectedQuery(item))}>
              <S.CardFieldArea>
                <S.CartText style={{ fontWeight: "600" }}>ID: <S.CartText>{item.ID}</S.CartText></S.CartText>
              </S.CardFieldArea>
              <S.CardFieldArea>
                <S.CartText style={{ fontWeight: "600" }}>Entity ID: <S.CartText>{item.ENTITY.ID}</S.CartText></S.CartText>
              </S.CardFieldArea>
              <S.CardFieldArea>
                <S.CartText style={{ fontWeight: "600" }}>Entity: <S.CartText>{item.ENTITY.LABEL}</S.CartText></S.CartText>
              </S.CardFieldArea>
              <S.CardFieldArea>
                <S.CartText style={{ fontWeight: "600" }}>Connections: <S.CartText>{item.connections.length}</S.CartText></S.CartText>
              </S.CardFieldArea>
            </S.Card>
          ))
        }
      </S.ListArea>

    </S.Container>
  )
}
