import Navigation from "../../../../../../models/navigation"
import showAlert from "../../../../../../services/showAlert"
import compareModels from "../../../../ScreenFactory/utils/ButtonsFunctions/utils/switch/compareModels"

const handleCloseTab = ({ navigation, tabData, state, setSelectedTabName }) => {
  const { name, params } = tabData
  const { index, routes } = state

  const closeTab = () => {
    if (index === 1) {
      navigation.navigate("Overview")
      setSelectedTabName("Overview")
    } else {
      const previewTab = routes[index - 1]
      setSelectedTabName(previewTab?.params?.RootParentID ?? "Overview")
      navigation.navigate("Root", { screen: previewTab?.name ?? "Overview" })
    }

    Navigation.closeTab(name)
  }

  const isRegistrationScreen = params.type === "registration"

  if (isRegistrationScreen) {
    const entityName = params.attribute
    const models = params.controller.models()

    const hasModifiedField = compareModels({
      models,
      entityName
    })

    if (hasModifiedField)
      return showAlert({
        title: "All changes will be lost",
        titleType: "warning",
        text: "Would you like to close the tab?",
        type: "confirm",
        handleConfirm: () => closeTab()
      })
  }

  closeTab()
}

export default handleCloseTab
