import React, { useState, useEffect } from "react"
import * as S from "./styles"

export default function IntegerInput({ entity, models, updateForm, setUpdateForm }) {
  const [value, setValue] = useState(models[entity].ID || "")

  const tableData = models._REGISTRATION_DATA[entity]

  useEffect(() => {
    setValue(models[entity].ID || "")
  }, [updateForm])

  const isAnInsertion = tableData.operation === "INS"
  const isNegative = parseFloat(value) < 0

  return (
    <S.InputContainer>
      <S.Input
        editable={isAnInsertion}
        value={value}
        precision={0}
        onChangeValue={setValue}
        negative={isNegative}
        onBlur={() => models[entity].ID = value}
        placeholder="ID"
      />
    </S.InputContainer>
  )
}
