import styled from "rn-css"
import globalStyles from "../../../../../../global/globalStyles"

const Container = styled.View`
  background-color: ${globalStyles.colors.background};

  max-height: 65%;
  width: 65%;
  flex: 1;

  align-self: center;

  padding: 10px;
  gap: 5px;

  border-radius: 4px;
`

export default Container
