import Permissions from "../../../models/permissions"

const getUserPermissions = async () => {
  const userPermissions = await Permissions.getRoutines()

  return userPermissions.reduce((permissions, currentPermission) => {
    for (const key in currentPermission) {
      if (key !== "TRANSACTION") {
        if (["dashboards", "reports"].includes(currentPermission.TRANSACTION)) {
          permissions[key] = { READ: currentPermission[key] }
        } else {
          if (!permissions[currentPermission.TRANSACTION])
            permissions[currentPermission.TRANSACTION] = {}

          permissions[currentPermission.TRANSACTION][key] = currentPermission[key]
        }
      }
    }
    return permissions
  }, {})
}

export default getUserPermissions
