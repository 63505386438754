export default (field, models) => {
  const { ID, TABLENAME, isFilter } = field

  if (ID !== "ID" || isFilter) return

  const tableData = models._REGISTRATION_DATA[TABLENAME]

  if (models[TABLENAME][ID] && tableData.operation === "UPD") {
    field.T_DTD_ATR.EDITABLE = false
    return
  }

  const isManual = tableData.manualID ?? tableData.pkSource === "M"

  field.T_DTD_ATR.EDITABLE = isManual
  field.T_DTD_ATR.REQUIRED = isManual
}
