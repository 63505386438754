import { useEffect, useState } from "react"
import evaluateFunction from "../../../../../../../../services/evaluateFunction"
import { Field } from "../../../../../../../../services/registration/getTableFields"

interface UseDateProps {
  models: any
  field: Field
  updateForm: boolean
  setUpdateForm: (s) => void
}

export default function useDate({ models, field, updateForm, setUpdateForm }: UseDateProps) {

  const { ID, TABLENAME } = field

  const ATR = field.T_DTD_ATR

  const [state, setState] = useState("")

  const updateValues = (value: string) => {
    models[TABLENAME][ID] = getModelValue(value)
    setState(getStateValue(value))
  }

  const onBlur = () => {
    models[TABLENAME][ID] = getModelValue(state)
    setUpdateForm(s => !s)
  }

  useEffect(() => {
    if (ATR.EXPRESSION) {
      evaluateFunction(ATR.EXPRESSION, models)
        .then(r => {
          if (r) updateValues(r)
        })
      return
    }

    updateValues(models[TABLENAME][ID] || ATR.DEFAULTVALUE || "")
  }, [updateForm])

  return {
    state,
    setState,
    onBlur
  }
}

function getStateValue(value: string): string {
  return value ? value.substring(0, 10) : "" // YYYY-MM-DD
}

function getModelValue(value: string): string | null {
  return value ? `${value.substring(0, 10)}T00:00:00` : null
}
