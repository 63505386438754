import RuntimeConfiguration from "../../models/runtimeConfiguration"
import dbRequest from "../dbRequest/dbRequest"
import evaluateFunction from "../evaluateFunction"

export default async ({
  entityName,
  models,
  staticRecords,
  entityPath = null,
  viewType,
  additionalFilters,
  tableFilters,
  screenBuilder
}) => {
  const filtersArray = [...tableFilters]

  if (viewType === "readOnly" && additionalFilters) {
    const aux = await evaluateFunction(additionalFilters, models) ?? []

    filtersArray.push(...aux)
  }

  return staticRecords ?? await getRequest({
    entityPath,
    entityName,
    filtersArray,
    screenBuilder
  })
}

async function getRequest({ entityPath, entityName, filtersArray, screenBuilder }) {
  const isDashboardBuilderAdminRequest = RuntimeConfiguration.getDashboardBuilderAdminMode()
  const isScreenBuilderAdminRequest = RuntimeConfiguration.getIsDeveloperConfigurationMode()

  let request = entityPath
    ? dbRequest.loadRecords(entityPath)
    : dbRequest.loadRecords(entityName)

  if (filtersArray.length > 0) {
    for (const filter of filtersArray) {
      const { field, operator, value } = filter
      request = request.where(field, operator, value)
    }
  }

  if (screenBuilder && (isScreenBuilderAdminRequest || isDashboardBuilderAdminRequest)) request = request.admin()

  return request.execute()
}
