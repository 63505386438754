import getFormattedRecords from "./getFormattedRecords"

export default (filteredRecords, fieldsData, searchText, isHierarchic) => {
  const searchedValue = removeAccents(searchText)

  const values = new Set()

  function processRecord(record, shouldAdd) {
    const addValues = (id, desc) => { if (!values.has(id)) values.add(id).add(desc) }

    if (shouldAdd) {
      const id = removeAccents(record.ID)
      const description = removeAccents(record.DESCRIPTION)
      addValues(id, description)

      if ("MASTERID" in record && Number(record.MASTERID.ID) !== 0) {
        const masterId = removeAccents(record.MASTERID.ID)
        const masterDescription = removeAccents(record.MASTERID.DESCRIPTION)
        addValues(masterId, masterDescription)
        processRecord(record.MASTERID, true) // Recursive call
      }
    }
  }

  if (isHierarchic) {
    for (const record of filteredRecords) {
      const id = removeAccents(record.ID)
      const description = removeAccents(record.DESCRIPTION)
      const shouldAdd = id.includes(searchedValue) || description.includes(searchedValue)
      processRecord(record, shouldAdd)
    }

    const formattedRecords = getFormattedRecords(filteredRecords)

    const aux = formattedRecords.filter(({ ID, MASTERID = {} }) => {
      const isMasterIDNonZero = Number(MASTERID?.ID) !== 0

      if (values.has(ID) && isMasterIDNonZero) return values.has(MASTERID.ID)
      else return values.has(ID)
    })

    aux.forEach(i => i.open = true)
    return aux
  }

  else return filteredRecords.filter(record => {
    const recordFields = Object.keys(record)
      .map(recordKey => {
        const value = record?.[recordKey] ?? ""
        return removeAccents(typeof value === "object" ? (value.DESCRIPTION ?? value.LABEL) : value)
      })

    return recordFields.some(value => value?.includes(searchedValue))
  })
}

function removeAccents(text = "") {
  if (typeof text !== "string")
    text = text.toString()

  return text
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toUpperCase()
}
