import React, { useState } from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { useTranslation } from "react-i18next"
import ModalWrapper from "../../../Modal"
import Button from "../Button"
import FieldsList from "./components/FieldsList"
import FiltersList from "./components/FiltersList"
import Header from "./components/Header"
import { FiltersProvider } from "./contexts/FiltersContext"
import { SearchTextProvider } from "./contexts/SearchTextContext"
import * as S from "./styles"

export default function FiltersConfiguration({ entityName, setUpdateFilters, controller, setUpdateTable }) {
  const { t } = useTranslation()

  const [modalVisible, setModalVisible] = useState(false)

  const closeModal = () => {
    setModalVisible(false)
  }

  return (
    <>
      <div title={t("Screen filter settings")}>
        <Button
          iconName='filter'
          isVisible={() => true}
          handlePress={() => setModalVisible(true)}
        />
      </div>

      <ModalWrapper isVisible={modalVisible}>
        <S.ModalContent>
          <FiltersProvider>
            <SearchTextProvider>
              <DndProvider backend={HTML5Backend}>
                <S.Container>
                  <Header
                    setUpdateFilters={setUpdateFilters}
                    closeModal={closeModal}
                    entityName={entityName}
                    controller={controller}
                    setUpdateTable={setUpdateTable}
                  />

                  <S.ListsView>
                    <FieldsList entityName={entityName} controller={controller} />

                    <S.DividingLine />

                    <FiltersList entityName={entityName} controller={controller} />
                  </S.ListsView>
                </S.Container>
              </DndProvider>
            </SearchTextProvider>
          </FiltersProvider>
        </S.ModalContent>
      </ModalWrapper>
    </>
  )
}
