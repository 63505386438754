import allRequiredFieldsFilled from "../../../../../../../services/registration/allRequiredFieldsFilled"
import { handleSave } from "../../saveRecord"

export default async (props, callback) => {
  const { models, entityName } = props

  const requiredFieldsFilled = await allRequiredFieldsFilled({
    models,
    entity: entityName
  })
  if (!requiredFieldsFilled) return

  await handleSave(props)
  callback()
}
