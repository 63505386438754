import React, { useEffect } from "react"
import { View } from "react-native"
import CloseButton from "../../../CloseButton/index.web"
import Form from "../../../Form"
import ModalWrapper from "../../../Modal"
import { Container } from "./styles"

export default function ModalForm({ showModalForm, setShowModalForm, controller, entityFields }) {

  useEffect(() => {
    (async () => {
      await entityFields.map(field => {
        field.T_DTD_ATR.EDITABLE = false
        field.T_DTD_ATR.REQUIRED = false
      })
    })()
  }, [])

  const handleCloseModal = async () => {
    if (controller.listEntityName() !== "T_MOV_ITE" && controller.listEntityName() !== "T_REC_ITE") controller.models()[controller.listEntityName()].reset()
    setShowModalForm(s => !s)
  }

  return (
    <ModalWrapper isVisible={showModalForm}>
      <Container>
        <View style={{
          flex: 1,
          width: "100%"
        }}>
          <Form entity={controller.listEntityName()} models={controller.models()} staticFields={entityFields} />
        </View>
        <CloseButton callbackFunction={handleCloseModal} />

      </Container>
    </ModalWrapper>
  )
}
