import { FontAwesome5, Entypo } from "@expo/vector-icons"
import styled from "rn-css"
import globalStyles from "../../../../../../../../../global/globalStyles"

export const Container = styled.View`
  background-color: #fff;
  padding: 8px;
  cursor: grab;
  border-radius: 4;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const Text = styled.Text`
  font-size: 12px;
  color: #2e2e2e;
`

export const ButtonsView = styled.View`
  flex-direction: row;
  align-items: center;
  gap: 20px;
`

export const Box = styled.TouchableOpacity`
  background-color: ${({ isActive }) => isActive ? globalStyles.colors.secondary : "transparent"};
  width: 15px;
  height: 15px;
  justify-content: center;
  align-items: center;
  border: 1px solid gray;
  border-width: ${({ isActive }) => isActive ? 0 : 1}px;
  border-radius: 2px;
`

export const Icon = styled(Entypo)`
  display: ${({ isActive }) => isActive ? "flex" : "none"};
  color: ${globalStyles.colors.tertiary};
  font-size: 12px;
`

export const DeleteButton = styled.TouchableOpacity``

export const DeleteIcon = styled(FontAwesome5)`
  color: ${globalStyles.colors.removeButton};
  font-size: 14px;
`
