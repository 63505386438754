import styled from "styled-components"
import { defaultTheme } from "../../../../../styles/themes/default"

export const Container = styled.div`
  display: flex;
  position: absolute;
  right: -30px;
  bottom: 20px;
  align-items: center;
  justify-content: center;
  padding: 4px 6px;
  background-color: ${defaultTheme["white"]};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.35);
  border-radius: 8px;

  color: ${defaultTheme["gray-700"]};
  font-size: 14px;
  font-weight: 500;
  z-index: 100;
`
