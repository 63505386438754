import styled from "rn-css"
import globalStyles from "../../../../../../../../../../global/globalStyles"

export const Container = styled.View`
  background-color: ${globalStyles.colors.background};

  max-height: 33%;
  width: 33%;
  flex: 1;

  align-self: center;

  padding: 10px;
  gap: 5px;

  border-radius: 4px;
`
