import evaluateFunction from "../../../../../../services/evaluateFunction"
import showAlert from "../../../../../../services/showAlert"

export default async ({ option, models, callback }) => {
  const { EXPRESSION } = option
  const { fields } = models._REGISTRATION_DATA.PARAMS

  if (fields && fields.length > 0) {
    const requiredFields = fields.filter(({ T_DTD_ATR }) => T_DTD_ATR.REQUIRED)
    const sortedFields = requiredFields.sort((a, b) => a.T_DTD_ATR.ORDERBY - b.T_DTD_ATR.ORDERBY)

    for (const field of sortedFields) {
      if (models.PARAMS[field.ID] === null)
        return showAlert({
          text: `${field.T_DTD_ATR.FIELDDESCRIPTION}`,
          title: "Required parameter not filled",
          titleType: "error"
        })
    }
  }

  await evaluateFunction(EXPRESSION, models)
  models.PARAMS.reset()
  callback()
}
