import showAlert from "../../../../../../../services/showAlert"
import tableValidation from "./tableValidation"

export default (models, controller, setUpdateTablesTable, closeModal) => {
  const isTableValid = tableValidation(models)
  if (!isTableValid) return

  showAlert({
    type: "confirm",
    text: "Do you want to create the table?",
    title: "Table creation",
    handleConfirm: async () => {
      await controller.saveTable()
      models.T_DTD_TAB.reset()
      setUpdateTablesTable(s => !s)
      closeModal("table")
    }
  })
}
