import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  mode: "table"
}

const screenFactorySlice = createSlice({
  name: "screenFactoryReducer",
  initialState,
  reducers: {
    changeMode: (state) => { state.mode = state.mode === "table" ? "form" : "table" }
  }
})

export const { changeMode } = screenFactorySlice.actions
export const screenFactoryReducer = screenFactorySlice.reducer
