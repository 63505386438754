import React from "react"
import { useTranslation } from "react-i18next"
import AccessibilityBox from "../AccessibilityBox"
import useInputManager from "../hooks/useInputManager"
import * as S from "./styles.web"

export default function Checkbox({ models, field, updateForm, setUpdateForm }) {
  const { t } = useTranslation()

  const { ID, TABLENAME, T_DTD_ATR } = field
  const { EDITABLE, FIELDDESCRIPTION, HELPTEXT } = T_DTD_ATR
  const { state, setState } = useInputManager({ models, field, updateForm, setUpdateForm })

  const handlePress = () => {
    field.isFilter
      ? models._REGISTRATION_DATA[TABLENAME].filtersValues[ID] = !state
      : models[TABLENAME][ID] = !state
    setState(s => !s)
  }

  return (
    <S.Container>
      <S.Box
        disabled={!EDITABLE}
        isActive={Boolean(state)}
        isEditable={EDITABLE}
        onClick={handlePress}
      >
        <S.Icon name='check' isActive={state} />
      </S.Box>

      <S.Title>{t(FIELDDESCRIPTION)}</S.Title>

      <AccessibilityBox text={HELPTEXT} />
    </S.Container>
  )
}
