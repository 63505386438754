import styled from "styled-components"
import { defaultTheme } from "../../../../../styles/themes/default"

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: .4;
  padding: 20px 20px 0px 20px;
`

export const Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: ${defaultTheme["cyan-700"]};
`

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
`

export const FieldView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: start;
  flex-direction: column;
  width: 50%;
  margin-bottom: 8px;
`

export const Key = styled.span`
  font-size: 14px;
  font-weight: lighter;
  color: ${defaultTheme["cyan-700"]};
  margin-left: 3px;
  margin-bottom: 3px;
  width: 90%;
`

export const Value = styled.div`
  font-size: 12px;
  font-weight: lighter;
  color: ${defaultTheme["gray-700"]};
  width: 94%;
  padding: 6px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  border: .3px solid ${defaultTheme["gray-500"]};
  border-radius: 12px;
  background-color: ${defaultTheme["gray-200"]};
`
