import showAlert from "../../../../../services/showAlert"
import handleUndo from "./utils/undo/handleUndo"

export default function undoChanges(props) {
  showAlert({
    type: "confirm",
    text: "Do you want to undo changes?",
    title: "All changes will be lost",
    titleType: "warning",
    handleConfirm: () => { handleUndo(props) }
  })
}
