import dbRequest from "../dbRequest/dbRequest"

const foreignKeyValidation = async ({ entity, record }) => {
  const tables = await dbRequest.loadRecords("T_DTD_TAB").execute() as any

  for (const table of tables) {
    const tableId = table.ID

    const fields = await dbRequest.loadRecords(`T_DTD_TAB/${tableId}/T_DTD_FLD`).execute() as any

    for (const field of fields) {
      const fieldId = field.ID
      const isSearchField = ["N", "K"].includes(field.FIELDTYPE.VALUE)

      if (isSearchField) {
        const isTheSameEntity = await dbRequest
          .loadRecords(`T_DTD_TAB/${tableId}/T_DTD_FLD/${fieldId}/T_DTD_ATR`)
          .where("ID", "==", "ENTITY")
          .execute((r: any) => {
            if (!r) return false
            return r[0].VALUE.ID === entity
          })

        if (isTheSameEntity) {
          const isUsedAsForeignKey = await dbRequest
            .loadRecords(tableId)
            .where(`${fieldId}.ID`, "==", record.ID)
            .execute((r: any) => r.length > 0)

          if (isUsedAsForeignKey) return true
        }
      }
    }
  }

  return false
}

export default foreignKeyValidation
