import getTableFields from "../../../../../../../services/registration/getTableFields"

export default async function getEntityFields(entityName) {
  const entityFields = await getTableFields({ entityName: entityName })

  const filteredFields = entityFields.filter(item => ["E", "N", "K"].includes(item.FIELDTYPE.VALUE) && !["CREATED_BY", "UPDATED_BY"].includes(item.ID))
  const formattedFields = filteredFields.map((item) => {
    return {
      ID: item.ID,
      LABEL: item.T_DTD_ATR.DESCRIPTION,
      ENTITY: item.FIELDTYPE.VALUE === "E" ? {
        ID: item.ID,
        LABEL: item.T_DTD_ATR.FIELDDESCRIPTION
      } : {
        ID: item.T_DTD_ATR.ENTITY?.ID,
        LABEL: item.T_DTD_ATR.ENTITY?.LABEL
      },
      FIELDTYPE: item.FIELDTYPE.VALUE
    }
  })

  return formattedFields
}
