import { TextMask } from "react-native-masked-text"
import styled from "rn-css"
import globalStyles from "../../../../../../../../../../global/globalStyles"

const FieldMask = styled(TextMask).attrs({
  type: "datetime",
  options: { format: "DD/MM/YYYY" },
  selectTextOnFocus: true
})`
  color: ${({ isSelected }) => isSelected ? globalStyles.colors.tertiary : globalStyles.colors.primary};
  font-size: 12px;
  text-align: center;
`

export default FieldMask
