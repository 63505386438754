import { MaterialIcons } from "@expo/vector-icons"
import React, { useEffect, useState } from "react"
import { View } from "react-native"
import { useDispatch } from "react-redux"
import useAppSelector from "../../../../../../redux/hooks/useAppSelector"
import { changeFieldModalMode, closeModal } from "../../../../../../redux/redux-reducers/dashboardBuilder-reducer"
import saveField from "../../../../../../redux/redux-reducers/dashboardBuilder-reducer/utils/saveField"
import Form from "../../../../../components/Form"
import ModalWrapper from "../../../../../components/Modal"
import * as S from "../modalStyles"
import getFormFields from "./utils/getFormFields"

export default function FieldModal({ controller }) {

  const dispatch = useDispatch()

  const [formFields, setFormFields] = useState({})

  const { isVisible, name, mode, isSaved, path } = useAppSelector(({ dashboardBuilderReducer }) => dashboardBuilderReducer.modals.fieldModal)
  const { selectedDashboard } = useAppSelector(({ dashboardBuilderReducer }) => dashboardBuilderReducer.dashboardData)

  useEffect(() => {
    getFormFields().then(data => setFormFields(data))
  })

  return (
    <ModalWrapper isVisible={isVisible} >
      <S.Container style={[mode === "new" && {
        width: "18%",
        height: "30%"
      }]}>
        <S.CloseButton onPress={async () => {
          controller.models().T_DTD_FLD.reset()
          controller.models().T_DTD_ATR.reset()
          dispatch(closeModal({ modalName: name }))
        }}>
          <MaterialIcons name="close" size={20} color="#333333" />
        </S.CloseButton>

        <S.Title style={{ marginBottom: 5 }}>{mode === "new" ? "Select Field Type" : "Edit Field Attributes"}</S.Title>

        <View style={{
          flex: 1,
          marginTop: 10,
          borderRadius: 4
        }}>
          <Form
            entityName={mode === "new" ? "T_DTD_FLD" : "T_DTD_ATR"}
            controller={controller}
            updateForm={mode}
            staticFields={mode === "new" ? formFields.fldFields : formFields.atrFields}
          />
        </View>

        <S.ButtonsView style={[{ position: "absolute" }, mode === "new" ? {
          right: 15,
          bottom: 10
        } : {
          top: 10,
          right: 60
        }]}>

          <S.AddButton onPress={() => {
            if (mode === "new") dispatch(changeFieldModalMode({ ...controller.models().T_DTD_FLD }))
            else dispatch(saveField({
              path,
              selectedDashboard,
              T_DTD_FLD: { ...controller.models().T_DTD_FLD },
              T_DTD_ATR: { ...controller.models().T_DTD_ATR }
            }))
          }}>
            <S.TitleButton>{mode === "new" ? "Next" : "Save"}</S.TitleButton>
          </S.AddButton>

        </S.ButtonsView>

      </S.Container>
    </ModalWrapper >
  )
}
