import { createAsyncThunk } from "@reduxjs/toolkit"
import RuntimeConfiguration from "../../../../models/runtimeConfiguration"
import dbRequest from "../../../../services/dbRequest/dbRequest"
import showAlert from "../../../../services/showAlert"

const deleteDashboard = createAsyncThunk(
  "dashboardBuilder/deleteDashboard",
  async (selectedDashboard, thunkAPI) => {

    const isAdminMode = RuntimeConfiguration.getDashboardBuilderAdminMode()

    if (!selectedDashboard?.ID) {
      return thunkAPI.rejectWithValue(showAlert({
        title: "No dashboards were selected.",
        titleType: "warning",
        text: "Please select the dashboard to be deleted."
      }))
    }

    try {
      const response = await new Promise((resolve, reject) => {
        showAlert({
          title: "This action cannot be undone.",
          titleType: "warning",
          text: "Do you want to delete the dashboard?",
          type: "confirm",
          handleConfirm: async () => {
            try {
              let request = dbRequest.deleteRecords("T_RDB_DSB").record(selectedDashboard)

              if (isAdminMode) request = request.admin()

              await request.execute(async () => {

                showAlert({
                  title: "Success",
                  text: "Dashboard deleted successfully.",
                  titleType: "success"
                })

                // Remove dashboard from navigation
                let navigationRequest = dbRequest.loadRecords("T_DTD_NAV")
                navigationRequest = navigationRequest.where("ID", "==", isAdminMode ? null : "9999")

                const navigationResponse = await navigationRequest.execute()
                const transactionID = selectedDashboard.ID

                for (const item of navigationResponse) {
                  if (item.T_DTD_LNC && transactionID in item.T_DTD_LNC) {
                    delete item.T_DTD_LNC[transactionID]
                    await dbRequest
                      .deleteRecords("T_DTD_NAV")
                      .record({ ID: item.ID })
                      .execute()
                    await dbRequest
                      .saveRecords("T_DTD_NAV")
                      .record(item)
                      .execute(() => console.log("nav updated"))
                    break
                  }
                }

                resolve("success")
              })

            } catch (error) {
              console.error(error)
              reject(error)
            }
          }
        })
      })

      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(showAlert({
        title: "Error when trying to delete dashboard.",
        titleType: "error",
        text: "Please try again."
      }))
    }
  }
)

export default deleteDashboard
