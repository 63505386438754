import evaluateFunction from "../evaluateFunction"
import getTriggers from "./getTriggers"

export default async (models, entityName) => {

  const triggers = await getTriggers(entityName) ?? []

  const activeTrigegrs = triggers.filter(({ ACTIVE }) => ACTIVE === true)

  const orderedTrigegrs = activeTrigegrs.sort((a, b) => a.ORDERBY - b.ORDERBY)

  for (const trigger of orderedTrigegrs) {
    if (!await evaluateFunction(trigger.EXPRESSION, models)) {
      return false
    }
  }

  return true
}
