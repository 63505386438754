import React from "react"
import { useDrag, useDrop } from "react-dnd"
import { useTranslation } from "react-i18next"
import * as S from "./styles"

export default function Card({ cardData, cardIndex, moveCard, cardValueAttribute }) {
  const { t } = useTranslation()

  const cardType = "LIST_CARD"

  const cardText = cardValueAttribute ? cardData[cardValueAttribute] : cardData

  const [, ref] = useDrag({
    type: cardType,
    item: { index: cardIndex }
  })

  const [, drop] = useDrop({
    accept: cardType,
    hover: (draggedCard) => {
      if (draggedCard.index !== cardIndex) {
        moveCard(draggedCard.index, cardIndex)
        draggedCard.index = cardIndex
      }
    }
  })

  return (
    <S.Container ref={(node) => ref(drop(node))}>
      <S.Text>{t(cardText)}</S.Text>
    </S.Container>
  )
}
