/* eslint-disable import/prefer-default-export */
import { defaultTheme } from "./themes/default"

export const gridStyles = `
	.e-headertext {
		color: ${defaultTheme["cyan-800"]} !important;
		font-weight: medium !important;
	}

	.e-grid .e-headercell {
		height: 32px !important;
		border: none !important;
	}

	.e-grid .e-rowcell,
	.e-grid .e-rowcell span {
    padding: 2px 6px !important;
		font-size: 12px !important;
	}

	.e-grid .e-summaryrow .e-summarycell {
		padding: 0 !important;
	}

	th .e-checkbox-wrapper,
	td .e-checkbox-wrapper {
		width: 18px !important;
	}

	.e-headercell.e-centeralign.e-leftfreeze.e-freezeleftborder.e-defaultcursor,
	.e-rowcell.e-gridchkbox.e-leftfreeze.e-freezeleftborder {
  	border: none !important;
	}

	.e-grid .e-groupdroparea {
		color: ${defaultTheme["gray-500"]};
    background-color: ${defaultTheme["white"]};
		text-align: center;
		font-weight: 500;
		font-size: 14px;
		border: 2px dashed ${defaultTheme["gray-500"]};
		border-radius: 10px;
		width: 99%;
		margin: 0px auto;
	}

	.e-groupdroparea.e-lib.e-droppable.e-draggable.e-grouped {
		background-color: ${defaultTheme["white"]};
	}

	.e-grid-icon.e-groupheadercell {
		background-color: ${defaultTheme["cyan-800"]};
		
		&:hover {
			background-color: ${defaultTheme["cyan-800"]};
		}
	}

	.e-filtermenudiv.e-icons.e-icon-filter::before {
		font-size: 12px;
		color: ${defaultTheme["cyan-800"]};
	}
	
	.e-filtermenudiv.e-icons.e-icon-filter.e-filtered::before,
	.e-sortfilterdiv.e-icons.e-ascending.e-icon-ascending::before,
	.e-sortfilterdiv.e-icons.e-descending.e-icon-descending::before {
		font-size: 12px;
		color: ${defaultTheme["orange-500"]} !important;
	}

	/* Icone das opções do popup */
	.e-grid .e-filtered::before  {
		color: ${defaultTheme["gray-700"]} !important;
	}
	.e-grid .e-focused .e-filtered::before {
		color: ${defaultTheme["cyan-800"]} !important;
	}

	/* Botoes do popup */
	.e-grid .e-filter-popup button:nth-of-type(1) {
		background-color: ${defaultTheme["cyan-800"]} !important;
  	color: ${defaultTheme["white"]} !important;
	}
	.e-grid .e-filter-popup button:nth-of-type(2) {
		background-color: ${defaultTheme["gray-500"]} !important;
  	color: ${defaultTheme["white"]} !important;
	}

	/* Linha apresentada ao focar input */
	.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
	.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before {
		background-color: ${defaultTheme["cyan-300"]} !important;
	}

	.e-frame.e-icons {
		border-radius: 50% !important;
	}

	.e-frame.e-icons.e-check {
		background-color: ${defaultTheme["cyan-800"]} !important;
		border-color: ${defaultTheme["cyan-800"]} !important;
		border-radius: 50% !important;
		padding: 2px 0 !important;
	}

	.e-frame.e-icons.e-check::before {
		background-color: ${defaultTheme["cyan-800"]} !important;
		border-color: ${defaultTheme["cyan-800"]} !important;
		border-radius: 50% !important;
	}

	.e-gridpager {
		margin: 0 !important;
		padding: 0 1.5rem !important;
		display: flex !important;
		justify-content: space-between !important;
		align-items: center !important;
	}
	
	.e-parentmsgbar {
		margin: 0 !important;
		padding: 0 !important;
	}
	
	.e-pagercontainer {
		margin: 0 !important;
		padding: 0 !important;
	}

	.e-prev, 
	.e-next,
	.e-first,
  .e-last {
    margin-top: 0 !important;
    margin-top: 0 !important;
  }
	
	.e-link.e-numericitem.e-spacing.e-currentitem.e-active {
		background-color: ${defaultTheme["cyan-300"]};
	}

	.e-pagesizes {
		margin: 0 !important;
		padding: 0 !important;
	}
	
	.e-pagesizes .e-input-group {
		margin: 0 !important;
		padding: 0 !important;
	}

  .e-treecolumn-container {
    display: flex !important;
  }

	span.e-icons.e-treegridcollapse,
	span.e-icons.e-treegridexpand {
		padding: 0 !important;
	}
`
