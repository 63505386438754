import React from "react"
import HierarchicalRow from ".."

export default props => {
  const { records, padding, rowData } = props

  const { ID, ANALYTICAL, open } = rowData

  const isParent = ({ MASTERID }) => ID === MASTERID?.ID

  return open && !ANALYTICAL
    ? records.map((child, index) => isParent(child) && <HierarchicalRow key={index} {...props} rowData={child} padding={padding + 30} />)
    : null
}
