import RuntimeConfiguration from "../../../../../../../models/runtimeConfiguration"
import dbRequest from "../../../../../../../services/dbRequest/dbRequest"
import showAlert from "../../../../../../../services/showAlert"

export default async function saveVariable({ variable, variablesPath }) {
  const isAdminMode = RuntimeConfiguration.getDashboardBuilderAdminMode()
  try {
    const request = dbRequest.saveRecords(variablesPath).record(variable)

    if (isAdminMode) request.admin()

    await request.execute()

    showAlert({
      titleType: "success",
      title: "Variable saved",
      text: "Variable saved successfully"
    })

  } catch (error) {
    console.error(error)
    showAlert({
      titleType: "error",
      title: "Error when trying to save variable",
      text: "Please try again"
    })
  }

}
