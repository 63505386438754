import compareValues from "./compareValues"

export default function sortRecords({ selectedField, filteredRecords, models, entityName }) {
  const { ID, FIELDTYPE } = selectedField

  return [...filteredRecords].sort((a, b) => {
    const hasLabel = ["N", "K", "L"].includes(FIELDTYPE.VALUE)

    if (hasLabel) {
      const valueA = a[ID]?.LABEL
      const valueB = b[ID]?.LABEL

      if (valueA && valueB) return compareValues({
        a: valueA,
        b: valueB,
        models,
        entityName,
        selectedField
      })
      if (valueA) return -1
      if (valueB) return 1
    }

    return compareValues({
      a: a[ID],
      b: b[ID],
      models,
      entityName,
      selectedField
    })
  })
}
