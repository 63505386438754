export default class Navigation {

  static navigation
  static setTabs

  static getNavigation() { return this.navigation }
  static setNavigation(props) { this.navigation = props }

  static setTabsFunction(props) { this.setTabs = props }

  static createTab(props) {

    this.setTabs((state) => {
      const tabs = [...state]

      const tabExist = tabs.find(tab => tab.tabName === props.tabName)

      if (!tabExist) tabs.push(props)

      return tabs
    })

    props.params.tabName = props.tabName

    this.navigation.navigate("Root", {
      screen: props.tabName,
      params: props.params
    })
  }

  static closeTab(tabName) {
    this.setTabs((state) => {
      const tabs = [...state]
      const newTabs = tabs.filter(tab => tab.tabName !== tabName)
      return newTabs
    })
  }

  static closeTabs() {
    this.setTabs([])
  }
}
