import styled from 'styled-components'
import { EvilIcons } from '@expo/vector-icons'
import { defaultTheme } from '../../../../../../styles/themes/default'

export const Container = styled.button`
  align-items: center;
  background-color: ${defaultTheme["gray-100"]};
  border: 2px solid ${defaultTheme["cyan-300"]};
  border-radius: 50%;
  display: flex;
  height: 50px;
  justify-content: center;
  overflow: hidden;
  padding: 2px;
  position: relative;
  width: 50px;
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  aspect-ratio: 1;
`

export const ImageIcon = styled(EvilIcons)
  .attrs(({
    color: defaultTheme["gray-400"],
    name: "image",
    size: 36,
  }))``
