export default class Observable {

  observers: any[]

  constructor() { this.observers = [] }

  subscribe(f: () => void): void { this.observers.push(f) }

  exists(f: () => void): boolean {
    for (let i = 0; i < this.observers.length; i++)
      if (this.observers[i] === f)
        return true

    return false
  }

  unsubscribe(f: () => void): void { this.observers = this.observers.filter(subscriber => subscriber !== f) }

  unsubscribeAll(): void { this.observers = [] }

  notify(data: any): void { this.observers.forEach(o => o(data)) }

  length(): number { return this.observers.length }

  show(): void { console.log("Observers: ", this.observers) }

}
