import showAlert from '../../../../../../../services/showAlert'
import saveModifications from './saveModifications'

const updateValidation = (props, callback) => {
  showAlert({
    title: "Change detected in record",
    titleType: "warning",
    text: "Would you like to save before exiting?",
    type: "confirm",
    handleConfirm: () => saveModifications(props, callback),
    handleReject: () => callback()
  })
}

export default updateValidation