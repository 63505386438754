import showAlert from "../../../../../../../../../../../services/showAlert"

export default async (controller, setUpdateTable) => {
  const models = controller.models()
  const { ID } = models.T_DTD_FLD

  if (!ID) return showAlert({
    text: "No parameter selected",
    title: "Failed to delete parameter",
    titleType: "error"
  })

  showAlert({
    type: "confirm",
    text: "Do you want to delete the selected parameter?",
    title: "This action cannot be undone",
    titleType: "warning",
    handleConfirm: async () => {
      await controller.deleteParameter()
      models.T_DTD_FLD.reset()
      models.T_DTD_ATR.reset()
      setUpdateTable(s => !s)
    }
  })
}

