import React, { useContext } from "react"
import Form from "../../../../../components/Form/index.web"
import ModalWrapper from "../../../../../components/Modal"
import { ControllerContext } from "../../../contexts/ControllerProvider"
import { ModalsContext } from "../../../contexts/ModalsProvider"
import { UpdateContext } from "../../../contexts/UpdateProvider"
import renderButtons from "../utils/renderButtons"
import Container from "./styles"
import handleClose from "./utils/handleClose"
import handleConfirm from "./utils/handleConfirm"

export default function ManageAttributesModal({ isEdit }) {
  const { controller, models } = useContext(ControllerContext)
  const { modalsVisible, closeModal, openModal } = useContext(ModalsContext)
  const { setUpdateFieldsTable } = useContext(UpdateContext)

  const isListField = models.T_DTD_FLD.FIELDTYPE.VALUE === "L"

  const buttons = [
    {
      iconName: "save",
      divTitle: "Save",
      onPress: () => { handleConfirm(models, controller, isEdit, closeModal, setUpdateFieldsTable) }
    },
    {
      iconName: "close",
      divTitle: "Close",
      onPress: () => { handleClose(models, closeModal) }
    }
  ]

  if (isListField)
    buttons.splice(1, 0, {
      iconName: "list-ul",
      divTitle: "Manage list options",
      onPress: () => { openModal("options") }
    })

  return (
    <ModalWrapper isVisible={modalsVisible.attributes}>
      <Container>
        {renderButtons(buttons)}

        <Form entity={"T_DTD_ATR"} models={models} screenBuilder={true} />
      </Container>
    </ModalWrapper>
  )
}
