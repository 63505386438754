import styled from "rn-css"
import globalStyles from "../../../../../../../global/globalStyles"

const Container = styled.View`
  background-color: ${globalStyles.colors.tertiary};

  align-items: center;

  width: auto;
  min-width: 35%;
  height: 85%;
  max-width: 70%;

  padding: 10px;
  gap: 10px;

  border-radius: 10px;
`

export default Container
