import styled from "rn-css"
import globalStyles from "../../../../../../../global/globalStyles"

export const Container = styled.View`
  background-color: ${globalStyles.colors.primary};

  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  width: 250px;
  padding: 5px 2px;

  border-right-width: 1px;
  border-right-color:  ${globalStyles.colors.gridRowColor};
`

export const Text = styled.Text`
  color: ${globalStyles.colors.tertiary}
`