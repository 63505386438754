import styled from "rn-css"

export const Container = styled.ScrollView.attrs(({ isHorizontal }) => ({ horizontal: isHorizontal }))``

export const ListContainer = styled.View``

export const LoadingDiv = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
`
