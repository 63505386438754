import { NavigationProp } from "@react-navigation/native"
import React, { useState } from "react"
import LoginData from "../../../models/loginData"
import Observable from "../../../models/Observable"
import useInactivityTimer from "../../../services/useInactivityTimer"
import * as S from "./styles"
import getOverviewBackground from "./utils/getOverviewBackground"

interface HomeScreenProps {
	navigation: NavigationProp<Route>
	route: Route
}

interface Route {
	key: string
	name: string
	params?: object
}

export const homeScreenObserver = new Observable()

export default function HomeScreen(route: HomeScreenProps) {

	const [userData, setUserData] = useState(LoginData.getLoginData())

	useInactivityTimer()

	const daysOfWeek = [
		"Domingo", "Segunda-Feira", "Terça-Feira", "Quarta-Feira",
		"Quinta-Feira", "Sexta-Feira", "Sábado"
	]

	const monthsOfYear = [
		"Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
		"Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
	]

	function formatDate(date) {
		const dayOfWeek = daysOfWeek[date.getDay()]
		const day = date.getDate()
		const month = monthsOfYear[date.getMonth()]
		const year = date.getFullYear()

		return `${dayOfWeek}, ${day} de ${month} de ${year}`
	}

	homeScreenObserver.unsubscribeAll()
	const updateBackground = () => {
		const updatedUserData = { ...LoginData.getLoginData() }
		setUserData(updatedUserData)
	}
	homeScreenObserver.subscribe(updateBackground)

	return (
		<S.Container style={{ backgroundImage: `url(${getOverviewBackground(userData?.BACKGROUND?.URL)})` }}>
			<S.WelcomeArea>
				<S.DateInfoText>{formatDate(new Date())} </S.DateInfoText>
				<S.GreetingsText>Bem Vindo(a), {userData.FIRSTNAME}!</S.GreetingsText>
			</S.WelcomeArea>
		</S.Container>
	)
}
