import styled from "styled-components"
import globalStyles from "../../../../global/globalStyles"

export const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  margin-right: 20px;
`

export const InputSlider = styled.input`
  width: 100%;
  height: 4px;
  outline: none;
  border-radius: 4px;
  appearance: none;
  -webkit-appearance: none;
  background: ${globalStyles.colors.primary};
  cursor: pointer;

  ::-webkit-slider-thumb {
    width: 16px;
    height: 16px;
    cursor: pointer;
    border-radius: 50%;
    appearance: none;
    -webkit-appearance: none;
    background-color: ${globalStyles.colors.tertiary}; /* Sempre branca */
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  ::-moz-range-thumb {
    width: 16px;
    height: 16px;
    cursor: pointer;
    border-radius: 50%;
    background-color: ${globalStyles.colors.tertiary};
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  ::-ms-thumb {
    width: 16px;
    height: 16px;
    cursor: pointer;
    border-radius: 50%;
    background-color: ${globalStyles.colors.tertiary};
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  ::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    border-radius: 4px;
    background: linear-gradient(
      to right,
      ${globalStyles.colors.secondary},
      ${globalStyles.colors.primary}
    );  }

  ::-moz-range-track {
    width: 100%;
    height: 4px;
    border-radius: 4px;
    background: ${globalStyles.colors.primary};
  }

  ::-ms-track {
    width: 100%;
    height: 4px;
    border-radius: 4px;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  ::-ms-fill-lower {
    background: ${globalStyles.colors.primary};
    border-radius: 4px;
  }

  ::-ms-fill-upper {
    background: ${globalStyles.colors.primary};
    border-radius: 4px;
  }

  &:hover::-webkit-slider-thumb {
    transform: translateY(-50%) scale(1.1);
  }

  &:active::-webkit-slider-thumb {
    transform: translateY(-50%) scale(1.2);
  }

  &:focus::-webkit-slider-thumb {
    transform: translateY(-50%) scale(1.2);
  }
`

export const ActualValue = styled.div`
  position: absolute;
  top: 25px; /* Ajuste conforme necessário para a posição vertical */
  left: 50%;
  transform: translateX(-50%);
  background-color: ${globalStyles.colors.tertiary};
  color: ${globalStyles.colors.primary};
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  font-weight: bold;
  font-family: sans-serif;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  opacity: ${props => props.isVisible ? 1 : 0};
  pointer-events: none;
  transition: opacity 0.3s ease;
`
