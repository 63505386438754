import showAlert from "../../../../../../../services/showAlert"

export default async ({ fields, closeModal, controller }) => {
  if (fields.length === 0) return closeModal("orderCardFields")

  showAlert({
    text: "Do you want to save?",
    type: "confirm",
    title: "Card fields ordering",
    handleConfirm: async () => {
      await controller.saveOrderedFields({
        fields,
        attribute: "CARDORDERBY"
      })

      closeModal("orderFields")

      showAlert({
        text: "Data saved successfully",
        title: "Card fields ordering",
        titleType: "success"
      })
    }
  })
}
