import React from "react"
import { useTranslation } from "react-i18next"
import AccessibilityBox from "../../../../AccessibilityBox"
import * as S from "./styles"

export default function FileSelection({ setFilesPreview }) {
  const { t } = useTranslation()

  const allowedFileTypes = ".doc, .jpeg, .jpg, .pdf, .png, .txt, .xml, .xls, .xlsx"

  const helpText = `
- ${t("Image")}: .jpeg, .jpg, .png
- ${t("Microsoft Excel Spreadsheet")}: .xls, .xlsx
- ${t("Microsoft Word Document")}: .doc
- ${t("Plain Text File")}: .txt
- ${t("Portable Document Format")} (PDF): .pdf
- ${t("XML Document")}: .xml
`

  const handleSelect = (event) => {
    const files = [...event.target.files]

    setFilesPreview(prevFiles => [...prevFiles, ...files])
  }

  return (
    <S.Container>
      <S.Input
        accept={allowedFileTypes}
        type="file"
        id="fileInput"
        onChange={handleSelect}
      />

      <S.Label htmlFor="fileInput">{t("Choose files")}</S.Label>

      <S.HelpDiv>
        <S.FormatsText>{t("Supported formats")}</S.FormatsText>
        <AccessibilityBox text={helpText} />
      </S.HelpDiv>
    </S.Container>
  )
}
