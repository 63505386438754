import styled from "styled-components/native"
import globalStyles from "../../../global/globalStyles"

const Container = styled.View`
  background-color: ${globalStyles.colors.opacity060};

  height: 100%;
  width: 100%;

  position: absolute;

  z-index: 10;
`

export default Container
