import dbRequest from "../../../../../../../../../../services/dbRequest/dbRequest"

export default async (cnpj: string, models: any) => {
  const partnerData = await dbRequest.receitaWS(cnpj).execute() as { type: string, status: string }

  const { type, status } = partnerData

  if (type) return "MAX_REQUESTS_EXCEEDED"
  if (status === "ERROR") return "CNPJ_NOT_FOUND"

  await populateModels(partnerData, models)

  return "OK"
}

async function populateModels(partnerData, models) {
  const {
    bairro,
    cep,
    cnpj,
    complemento,
    email,
    fantasia,
    logradouro,
    municipio,
    nome,
    numero,
    situacao,
    telefone
  } = partnerData

  const cityData = await fetchCityData(municipio)

  models.T_REC_PTN.populate({
    ...models.T_REC_PTN,
    ACTIVE: situacao === "ATIVA",
    ADDRESS: `${logradouro}, ${numero}`,
    ADDRESS2: complemento,
    ADDRESS3: bairro,
    CITY: cityData ?? {},
    DESCRIPTION: nome,
    EMAIL: email,
    PARTNERNAME: fantasia,
    PRN: cnpj,
    PHONENUMBER: telefone,
    ZIPCODE: cep
  })
}

async function fetchCityData(city) {
  return await dbRequest
    .loadRecords("T_REC_CTY")
    .where("CITY", "==", city)
    .execute(([r]) => r)
}
