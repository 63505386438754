import styled from "styled-components"
import { defaultTheme } from "../../../../../styles/themes/default"

interface Props {
  isActive: boolean
}

export const Container = styled.div<Props>`
  position: relative;
  width: ${({ isActive }) => isActive ? "100vw" : "70%"};
  height: ${({ isActive }) => isActive ? "100vh" : "auto"};
  border-radius: 8px;
  padding: 10px;
  background-color: ${defaultTheme["white"]};
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  width: 95%;
  border-radius: 10px;
  padding: 3px;
`

export const Title = styled.h1`
  margin-right: auto;
  opacity: 0.9;
  font-weight: 400;
  font-size: 1.5rem;
  color: ${defaultTheme["gray-900"]};
`

export const ButtonContainer = styled.div`
  display: flex;
  gap: 6px;
  height: 30px;
`

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  font-weight: 400;
  padding: 3px 6px;
  background-color: ${defaultTheme["cyan-500"]};
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 
              0 1px 3px rgba(0, 0, 0, 0.08);
  transition: box-shadow 0.3s ease-in-out;
  color: ${defaultTheme["white"]};

  &:hover {
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.15), 
              0 3px 6px rgba(0, 0, 0, 0.1);
  }
`

export const ClearButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${defaultTheme["cyan-500"]};
  color: ${defaultTheme["white"]};
  font-weight: 400;
  padding: 3px 6px;
  border-radius: 4px;
  transition: transform 0.1s;
  margin-right: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 
              0 1px 3px rgba(0, 0, 0, 0.08);
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.15), 
              0 3px 6px rgba(0, 0, 0, 0.1);
  }
`

export const FullScreenButton = styled.button`
  margin-left: 20px;
  background-color: ${defaultTheme["white"]};
`

export const FileInputButton = styled.button`
  background-color: ${defaultTheme["cyan-500"]};
  color: ${defaultTheme["white"]};
  padding: 3px 6px;
  font-size: 0.875rem;
  font-weight: 400;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 
              0 1px 3px rgba(0, 0, 0, 0.08);
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.15), 
              0 3px 6px rgba(0, 0, 0, 0.1);
  }
`

export const ModalContainer = styled.div`
 background-color: ${defaultTheme["white"]};
  border-radius: 10px;
  display: flex;
  height: auto;
  padding: 15px;
  position: relative;
`

export const TitleModal = styled.label`
 display: flex;
 color: ${defaultTheme["cyan-800"]};
 font-size: ${defaultTheme.formInput.fontSize}px;
 line-height: 20px;
 margin-bottom: 3px;
 font-weight: 500;
`

export const Row = styled.div`
 margin: 3px 0px 0 0;

`

export const Input = styled.input`
  color: ${defaultTheme["gray-700"]};
  font-size: ${defaultTheme.formInput.fontSize}px;
  width: 200px;
  border: 1px solid ${defaultTheme["gray-300"]};
  border-radius: 10px;
  padding: 0 10px;
  height: ${defaultTheme.formInput.height}px;
  outline: none;
 

  ::placeholder {
    color: ${defaultTheme["gray-300"]};
  }
`
export const ButtonModal = styled.button`
  font-size: 0.875rem;
  font-weight: 400;
  padding: 3px 6px;
  background-color: ${defaultTheme["cyan-500"]};
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 
              0 1px 3px rgba(0, 0, 0, 0.08);
  transition: box-shadow 0.3s ease-in-out;
  color: ${defaultTheme["white"]};
  margin-left: 10px;

  &:hover {
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.15), 
              0 3px 6px rgba(0, 0, 0, 0.1);
  }
`
