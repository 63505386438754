export function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
  const invalidChars = ['+', 'e', '.', ',']

  if (invalidChars.includes(e.key))
    e.preventDefault()
}

export function handlePaste(e: React.ClipboardEvent<HTMLInputElement>) {
  const paste = e.clipboardData.getData('text')

  const invalidCharsRegex = /[-+e.,]/

  if (invalidCharsRegex.test(paste))
    e.preventDefault()
}