import { useEffect, useState } from "react"
import useAppSelector from "../../../../../../redux/hooks/useAppSelector"
import dbRequest from "../../../../../../services/dbRequest/dbRequest"
import QueryBuilder from "../../../../../../services/QueryBuilder"
import showAlert from "../../../../../../services/showAlert"

export default function useConstructHTML({ chartData, chartId, models }) {

  const update = useAppSelector(({ dashboardReducer }) => dashboardReducer.mainUpdate)
  const [chartValue, setChartValue] = useState([])
  /* 
    useEffect(() => {
      const replacedQuery = QueryBuilder.replace(chartData.SCRIPT, models.DASHBOARD).build()
  
      dbRequest
        .queryExecuter(replacedQuery)
        .bigQuery()
        .execute()
        .then(r => {
          r.code
            ? setChartValue(r)
            : showAlert({
              text: `${r.errors[0].reason}\n${r.message}`,
              title: `ID: ${chartId} - ${chartData.DESCRIPTION}`,
              titleType: "error"
            })
        })
    }, [update])
  
    chartValue.forEach(item => {
      Object.entries(item)
        .forEach(([key, value]) => {
          const regex = new RegExp(`{${key}}`, "g")
          if (regex.test(chartData.VALUE)) chartData.VALUE = chartData.VALUE.replace(regex, value)
        })
    }) */

  return { html: chartData.VALUE }
}
