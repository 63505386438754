import React from "react"
import * as S from "./styles"

export default function ImageField({ url, description }) {

  return (
    <S.Container>
      {url ?
        <S.Img src={url} />
        :
        <S.Placeholder>
          {description?.substring(0, 2).toUpperCase()}
        </S.Placeholder>
      }
    </S.Container>
  )
}
