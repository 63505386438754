import RuntimeConfiguration from "../../../../models/runtimeConfiguration"
import dbRequest from "../../../../services/dbRequest/dbRequest"
import showAlert from "../../../../services/showAlert"
import storageRequest from "../../../../services/storageRequest"
import getImageSource from "./getImageSource"

const handleSave = ({
  t,
  branchId,
  selectedFile,
  setLogoSource,
  setModalVisible,
  setSelectedFile,
  setPreviewImage
}) => {
  showAlert({
    titleType: "question",
    type: "confirm",
    text: t("Do you want to save the selected image?"),
    title: t("Save image"),
    handleConfirm: async () => {
      try {
        let savedFile = null
        if (selectedFile) {
           savedFile = await storageRequest
            .saveFiles(`-BRANCH/${branchId}/LOGO/${selectedFile.name}`)
            .file(selectedFile)
            .execute((res) => {
              return {
                ID: "LOGO",
                NAME: selectedFile.name || selectedFile.NAME,
                SIZE: selectedFile.size || selectedFile.SIZE,
                TYPE: selectedFile.type || selectedFile.TYPE,
                URL: res.url
              }
            })
        } else {
          savedFile = ""
        }
        const branchData = RuntimeConfiguration.getCurrentBranchData()

        await dbRequest
          .updateRecords("-BRANCHES")
          .admin()
          .record({
            ...branchData,
            LOGO: savedFile
          })
          .execute(() =>
            showAlert({
              text: "",
              title: t("Image saved successfully"),
              titleType: "success"
            })
          )

        RuntimeConfiguration.setCurrentBranchData({
          ...branchData,
          LOGO: savedFile.URL
        })
        setLogoSource(await getImageSource())

        setModalVisible(null)
        setSelectedFile(null)
        setPreviewImage(null)
      } catch (error) {
        showAlert({
          text: error.message,
          title: t("Failed to save image"),
          titleType: "error"
        })
      }
    }
  })
}

export default handleSave
