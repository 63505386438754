import styled from "styled-components"
import { defaultTheme } from "../../../styles/themes/default"

export const OpenModalButton = styled.button`
  position: relative;
  width: 50px;
  height: 50px;

  &:hover .edit-icon {
    opacity: 1;
  }

  &:hover::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    opacity: 0.5;
    transition: opacity 0.5s ease;
  }
`

export const BranchLogo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain; /* Garante que a imagem cubra totalmente o contêiner */
  aspect-ratio: 1; /* Mantém a proporção quadrada */
  transition: opacity 0.5s ease;
`

export const IconContainer = styled.div`
  position: absolute;
  inset: 12px;

  color: black;
  background: ${defaultTheme["white"]}; 
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  z-index: 2; 
  opacity: 0; 
  transition: opacity 0.3s ease;
`

export const ModalContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 50%;
  height: auto;
  padding: 10px;
  border-radius: 10px;
  background-color: ${defaultTheme["white"]};
`

export const ModalHeader = styled.div`
  height: 70px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  align-items: center;
`

export const ModalTitle = styled.span`
  font-size: 20px;
  font-weight: 500;
  color: ${defaultTheme["gray-700"]};
`

export const ModalText = styled.p`
  font-size: 14px;
  color: ${defaultTheme["gray-700"]};
  margin-left: 5px;
`

export const ModalBody = styled.div`
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`

interface FileContainerProps {
  isDragging: boolean
}

export const SelectFileContainer = styled.div<FileContainerProps>`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: ${({ isDragging }) => isDragging ? defaultTheme["gray-200"] : defaultTheme["white"]};
  transition: background-color 0.3s ease;

  width: 55%;
  height: 100%;

  border: 1.5px dashed ${defaultTheme["gray-300"]};
  border-radius: 6px;
`

export const ButtonLabel = styled.label`
  color: ${defaultTheme["white"]};

  background-color: ${defaultTheme["gray-white"]};
  color: #FFAA3A;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;

  box-shadow: 2px 0 5px #00000029;

  &:hover {
    background-color: #FFAA3A;
    color: ${defaultTheme["white"]};
    transition: background-color 0.5s ease, color 0.5s ease;
  }

  cursor: pointer;
`

export const TextLabel = styled.label`
  color: ${defaultTheme["gray-600"]};
  font-size: 14px;
`

export const SupportedFileTypes = styled.p`
  position: absolute;
  bottom: 8px;
  font-size: 12px;
  color: ${defaultTheme["gray-500"]};
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  margin-top: 20px;
  padding-right: 20px;
  gap: 40px;
`

export const SaveButton = styled.button`
  background-color: ${defaultTheme["cyan-300"]};
  color: ${defaultTheme["white"]};
  width: 110px;
  height: 35px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  box-shadow: 2px 0 5px #00000029;
  cursor: pointer;
  border: none;

  &:hover {
    background-color: ${defaultTheme["cyan-500"]};
    transition: background-color 0.5s ease;
  }
`

export const CancelButton = styled(SaveButton)`
  background-color: ${defaultTheme["gray-200"]};
  color: ${defaultTheme["gray-700"]};

  &:hover {
    background-color: ${defaultTheme["gray-500"]};
    color: ${defaultTheme["white"]};
    transition: background-color 0.5s ease, color 0.5s ease;
  }
`

export const LogoContainer = styled(OpenModalButton)`
  width: 200px;
  height: 200px;
  border-radius: 15px;
  background-color: ${defaultTheme["gray-100"]};

  &:hover .trash-icon {
    opacity: 1;
  }
`

export const RemoveImageIcon = styled.div`
  position: absolute;
  inset: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  background: ${defaultTheme["white"]}; 
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  z-index: 2; 
  opacity: 0; 
  transition: opacity 0.3s ease;

`
