import React from "react"
import { View } from "react-native"
import * as S from "./styles"

export default function RenderMenuOptions({ options, models, setUpdate, isAdmin, isChild = false }) {

  const handlePress = item => {
    item.open = !item.open
    models.MENU.reset()
    models.MENU.populate({ ...item })
    setUpdate(s => !s)
  }

  const isItemSelected = id => models.MENU.ID === id

  const handleRecursion = ({ SUB_MENUS = [], T_DTD_LNC = {} }) => {
    const optionsToRender = SUB_MENUS.length > 0 ? SUB_MENUS : Object.values(T_DTD_LNC ?? {})
    return <RenderMenuOptions
      models={models}
      options={optionsToRender}
      setUpdate={setUpdate}
      isChild={true}
    />
  }

  const getIconName = (button) => {
    const hasButtons = button?.SUB_MENUS?.length > 0

    if (hasButtons) return button.open ? "arrow-drop-down" : "arrow-right"

    return null
  }

  return (
    <S.ListArea isChild={isChild}>
      {
        options.map((option) => {
          const { ID, DESCRIPTION, DEPTH, open } = option

          if (DEPTH === 0)
            if ((isAdmin && ID === "9999") || (!isAdmin && ID !== "9999")) return

          if (!option?.SUB_MENUS?.length && !option?.T_DTD_LNC) return

          return (
            <View key={ID}>
              <S.CardContainer
                style={[isItemSelected(ID) && { backgroundColor: "#3498DB" }, isChild && { borderRadius: 0 }]}
                onPress={() => handlePress(option)}
              >
                {getIconName(option) && <S.Icon name={getIconName(option)} style={isItemSelected(ID) && { color: "#fff" }} />}
                <S.MenuTitle style={isItemSelected(ID) && { color: "#fff" }}>
                  {DESCRIPTION}
                </S.MenuTitle>

              </S.CardContainer>
              {
                open &&
                <View style={{ paddingLeft: 15 }}>
                  {handleRecursion(option)}
                </View>
              }
            </View>
          )
        })
      }

    </S.ListArea >
  )
}
