import styled from "styled-components"
import { EvilIcons } from "@expo/vector-icons"
import { defaultTheme } from "../../../../../../styles/themes/default"

export const Container = styled.div``

interface TitleProps {
  isRequired: boolean
}

export const Title = styled.p<TitleProps>`
  color: ${defaultTheme["cyan-800"]};
  font-size: ${defaultTheme.formInput.fontSize}px;
  font-weight: ${({ isRequired }) => isRequired ? "bold" : "normal"};
  line-height: 20px;

  &::after {
    content: "*";
    color: ${defaultTheme["cyan-800"]};
    display: ${({ isRequired }) => isRequired ? "inline" : "none"};
  }
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

interface EditProps {
  isEditable: boolean
}

export const InputContainer = styled.div<EditProps>`
  border: 1px solid ${defaultTheme["gray-300"]};
  height: ${defaultTheme.formInput.height}px;
  border-radius: 10px;
  padding: 0 10px;
  display: flex;
  background-color: ${({ isEditable }) => defaultTheme[isEditable ? "white" : "gray-200"]};
  align-items: center;
  gap: 6px;
  max-width: 222px;
`

interface InputProps {
  width: number
}

export const Input = styled.input<InputProps>`
  background-color: transparent;
  color: ${defaultTheme["gray-700"]};
  font-size: ${defaultTheme.formInput.fontSize}px;
  width: ${({ width }) => width}px;
  border: none;
  height: 100%;
  outline: none;

  ::placeholder {
    color: ${defaultTheme["gray-300"]};
  }
`

export const ImageContainer = styled.div`
  height: 22px;
  width: 22px;
  border-radius: 50%;
  overflow: hidden;
`

export const Image = styled.img`
  height: 100%;
  object-fit: contain;
  width: 100%;
`

export const Button = styled.button<EditProps>`
  background-color: transparent;
  cursor: ${({ isEditable }) => isEditable ? "pointer" : "not-allowed"};
`

export const Icon = styled(EvilIcons)
  .attrs(({
    color: defaultTheme["gray-700"],
    size: 22
  }))``
