import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet } from "react-native"
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
  renderers
} from "react-native-popup-menu"
import globalStyles from "../../../../../../../global/globalStyles"
import dbRequest from "../../../../../../../services/dbRequest/dbRequest"
import showAlert from "../../../../../../../services/showAlert"
import executeExpression from "../../utils/executeExpression"
import getOptions from "../../utils/getOptions"
import Icon from "./styles"

export default function RenderMenu({ entityName, setSelectedOption, setModalVisible, models, closeModal, mode }) {
  const { t } = useTranslation()

  const { Popover } = renderers

  const [options, setOptions] = useState([])

  const handlePress = item => {
    if (item.SELECTLINE && !models[entityName].ID) {
      showAlert({
        text: "The function requires a record to be selected",
        title: "No record selected",
        titleType: "error"
      })
      return
    }

    setSelectedOption(item)

    if (item.TYPE.VALUE === "F") {
      showAlert({
        type: "confirm",
        text: "Do you really want to execute the selected function?",
        title: `${item.DESCRIPTION}`,
        handleConfirm: async () => {
          const fields = await dbRequest.loadRecords(`T_DTD_TAB/${entityName}/T_DTD_FNC/${item.ID}/T_DTD_FLD`).execute()

          fields.length > 0
            ? setModalVisible(true)
            : await executeExpression({
              option: {
                ...item,
                fields
              },
              models,
              callback: closeModal
            })
        },
        handleReject: () => closeModal()
      })
      return
    }

    setModalVisible(true)
  }

  const shouldRenderOptions = () => {
    if (options.length === 1 && options[0].EXPRESSION === "fnc_receitaWS") return mode === "form"
    return options.length > 0
  }

  useEffect(() => { getOptions(entityName, models).then(setOptions) }, [])

  return (
    <Menu renderer={Popover} rendererProps={{ placement: "bottom" }}>
      <MenuTrigger style={styles.menuTrigger}>
        <Icon name="ellipsis-h" />
        <Icon name="caret-down" />
      </MenuTrigger>

      <MenuOptions style={styles.menuOptions}>
        {shouldRenderOptions()
          ? options.map((item, index) => (
            <MenuOption
              key={index}
              style={[
                styles.contentText,
                item.EXPRESSION === "fnc_receitaWS" && mode !== "form" && { display: "none" }
                //item.EXPRESSION === "fnc_exportSpreadsheet" && mode !== "table" && { display: "none" }
              ]}
              onSelect={() => handlePress(item)}
              text={t(`${item.DESCRIPTION}`)}
            />
          ))
          : <MenuOption style={styles.menuOption} text={t("No options")} />
        }
      </MenuOptions>
    </Menu>
  )
}

const styles = StyleSheet.create({
  menuTrigger: {
    backgroundColor: globalStyles.colors.tertiary,
    flexDirection: "row",
    alignItems: "center",
    height: 25,
    margin: 5,
    paddingHorizontal: 10,
    borderRadius: 3,
    gap: 5
  },
  menuOptions: {
    minWidth: 300,
    width: "auto",
    padding: 10
  },
  menuOption: { textAlign: "center" },
  contentText: { fontSize: globalStyles.sizes.fontSize }
})
