import React, { useContext, useEffect, useState } from "react"
import DraggableList from "../../../../../components/DraggableList"
import ModalWrapper from "../../../../../components/Modal"
import { ControllerContext } from "../../../contexts/ControllerProvider"
import { ModalsContext } from "../../../contexts/ModalsProvider"
import Container from "./styles"
import getTabsArray from "./utils/getTabsArray"
import handleConfirm from "./utils/handleConfirm"

export default function OrderTabsModal() {
  const { controller, models } = useContext(ControllerContext)
  const { modalsVisible, closeModal } = useContext(ModalsContext)

  const [tabs, setTabs] = useState([])
  const [fields, setFields] = useState([])

  useEffect(() => {
    getTabsArray(models)
      .then(({ fields, tabs }) => {
        setFields(fields)
        setTabs(tabs)
      })
  }, [])

  const handleSave = () => {
    handleConfirm({
      fields,
      tabs,
      closeModal,
      controller
    })
  }

  const handleClose = () => { closeModal("orderTabs") }

  return (
    <ModalWrapper isVisible={modalsVisible.orderTabs}>
      <Container>
        <DraggableList
          data={tabs}
          setData={setTabs}
          title="Tabs ordering"
          onSave={handleSave}
          onClose={handleClose}
        />
      </Container>
    </ModalWrapper>
  )
}
