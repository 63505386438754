import { createAsyncThunk } from '@reduxjs/toolkit'
import showAlert from '../../../../services/showAlert'
import dbRequest from '../../../../services/dbRequest/dbRequest'
import RuntimeConfiguration from '../../../../models/runtimeConfiguration'

export const deleteSerie = createAsyncThunk(
  "dashboardBuilder/deleteSerie",
  async ({ selectedSerie, path }, thunkAPI) => {

    const isAdminMode = RuntimeConfiguration.getDashboardBuilderAdminMode()

    if (!selectedSerie?.ID) {
      return thunkAPI.rejectWithValue(showAlert({
        title: "No serie were selected.",
        titleType: "warning",
        text: "Please select the serie to be deleted."
      }))
    }

    try {
      const response = await new Promise((resolve, reject) => {
        showAlert({
          title: "This action cannot be undone.",
          titleType: "warning",
          text: "Do you want to delete the serie?",
          type: "confirm",
          handleConfirm: async () => {
            try {
              const request = dbRequest.deleteRecords(path).record(selectedSerie)
              
              if(isAdminMode) request.admin()
              
              await request.execute(() => {
                showAlert({
                  title: "Success",
                  text: "Serie deleted successfully.",
                  titleType: "success",
                })
                resolve({ selectedSerie })
              })
            } catch (error) {
              console.error(error)

              reject(error)
            }
          }
        })
      })

      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(showAlert({
        title: "Error when trying to delete serie.",
        titleType: "error",
        text: "Please try again.",
      }))
    }
  }
)