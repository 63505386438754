import getDashboardsRequest from "./getDashboardsRequest"
import getReportsRequest from "./getReportsRequest"
import getTablesRequest from "./getTablesRequest"

export default async () => {
  const tablesRequest = getTablesRequest()
  const dashboardsRequest = getDashboardsRequest()
  const reportsRequest = getReportsRequest()

  const [
    tablesTransactions,
    dashboardsTransactions,
    reportsTransactions
  ] = await Promise.all([
    tablesRequest,
    dashboardsRequest,
    reportsRequest
  ])

  const allTransactions = [...tablesTransactions, dashboardsTransactions, reportsTransactions]

  const alphabeticallyOrderedTransactions = allTransactions.sort((a, b) => a.DESCRIPTION.localeCompare(b.DESCRIPTION))

  return alphabeticallyOrderedTransactions
}
