import { createAsyncThunk } from '@reduxjs/toolkit'
import showAlert from '../../../../services/showAlert'
import dbRequest from '../../../../services/dbRequest/dbRequest'
import getDashboardFields from './getDashboardFields'
import RuntimeConfiguration from '../../../../models/runtimeConfiguration'

export const deleteDashboardField = createAsyncThunk(
  "dashboardBuilder/deleteDashboardField",
  async ({ path, selectedField }, thunkAPI) => {

    const isAdminMode = RuntimeConfiguration.getDashboardBuilderAdminMode()

    if (!selectedField.ID) {
      return thunkAPI.rejectWithValue(showAlert({
        title: "No field were selected.",
        titleType: "warning",
        text: "Please select the field to be deleted."
      }))
    }

    try {
      const response = await new Promise((resolve, reject) => {
        showAlert({
          title: "This action cannot be undone.",
          titleType: "warning",
          text: "Do you want to delete the field?",
          type: "confirm",
          handleConfirm: async () => {
            try {
              const request = dbRequest.deleteRecords(`${path}/T_DTD_FLD`).record(selectedField)
              
              if(isAdminMode) request.admin()

              await request.execute(() => {
                showAlert({
                  title: "Success",
                  text: "Field deleted successfully.",
                  titleType: "success",
                })
              })

              const updatedFields = await getDashboardFields(path)

              resolve({ updatedFields })
            } catch (error) {
              console.log(error)
              reject(error)
            }
          }
        })
      })

      return response
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(showAlert({
        title: "Error when trying to delete field.",
        titleType: "error",
        text: "Please try again.",
      }))
    }
  }
)