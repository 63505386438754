import styled from "styled-components"
import { defaultTheme } from "../../../../../../../../styles/themes/default"

export const Container = styled.div`
  align-self: center;
  background-color: ${defaultTheme["gray-100"]};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 80%;
  padding: 10px 15px;
  position: relative;
  width: 80%;
`

export const Title = styled.h1`
  align-self: center;
  color: ${defaultTheme["gray-900"]};
  display: flex;
  font-size: 18px;
  font-weight: 500;
`
