import exportTable from "./exportTable"
import importTable from "./importTable"
import launcher from "./launcher"
import manageFunction from "./manageFunction"
import manageTrigger from "./manageTrigger"
import orderCardFields from "./orderCardFields"
import orderFields from "./orderFields"
import orderTabs from "./orderTabs"

export default (props) => {
  return [
    {
      DESCRIPTION: "Export table to JSON format",
      onPress: () => exportTable(props)
    },
    {
      DESCRIPTION: "Import table to the database",
      onPress: () => importTable(props)
    },
    {
      DESCRIPTION: "Launcher",
      onPress: () => launcher(props)
    },
    {
      DESCRIPTION: "Manage table functions",
      onPress: () => manageFunction(props)
    },
    {
      DESCRIPTION: "Manage table triggers",
      onPress: () => manageTrigger(props)
    },
    {
      DESCRIPTION: "Fields ordering",
      onPress: () => orderFields(props)
    },
    {
      DESCRIPTION: "Card fields ordering",
      onPress: () => orderCardFields(props)
    },
    {
      DESCRIPTION: "Tabs ordering",
      onPress: () => orderTabs(props)
    }
  ]
}
