import React from "react"
import FieldMask from "./styles"

export default function Date({ fieldValue, isSelected }) {
  const dateValue = fieldValue ? new globalDate(fieldValue).toLocal() : ""

  return (
    <FieldMask
      value={dateValue}
      isSelected={isSelected}
    />
  )
}
