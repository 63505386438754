import styled from "rn-css"
import globalStyles from "../../../../../../global/globalStyles"

export const Container = styled.View`
  background-color: ${globalStyles.colors.background};

  max-height: 65%;
  width: 35%;
  flex: 1;

  align-self: center;

  padding: 10px;
  gap: 5px;

  border-radius: 4px;
`

export const MenuButtonView = styled.View`
  background-color: ${globalStyles.colors.primary};
`

export const MenuButton = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 5px;
`

export const ButtonText = styled.Text`
  color: ${globalStyles.colors.tertiary};
  font-size: ${globalStyles.sizes.fontSize}px;
  font-weight: bold;
`
