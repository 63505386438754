import React, { createContext, useState } from "react"

export const UpdateContext = createContext()

const UpdateProvider = ({ children }) => {

  const [updateTablesTable, setUpdateTablesTable] = useState(false)
  const [updateFieldsTable, setUpdateFieldsTable] = useState(false)

  return (
    <UpdateContext.Provider
      value={{
        updateTablesTable,
        setUpdateTablesTable,
        updateFieldsTable,
        setUpdateFieldsTable
      }}
    >
      {children}
    </UpdateContext.Provider>
  )
}

export default UpdateProvider
