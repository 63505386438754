import dbRequest from "../../../../../../../../../services/dbRequest/dbRequest"

export default async function populateDetailTabsPath({ models, entity, detailTabPath }) {
  const tableData = models._REGISTRATION_DATA[entity]

  const detailTabs = await dbRequest
    .loadRecords(`T_DTD_TAB/${entity}/T_DTD_FLD`)
    .execute((fields: any) => fields.filter(({ FIELDTYPE }) => FIELDTYPE.VALUE === "E")) as any

  for (const table of detailTabs) {
    const tableID = table.ID
    const recordID = (models[entity].ID).toString()

    tableData.detailTabsPath[tableID] = `${detailTabPath}/${recordID}/${tableID}`

  }
}
