import Attachment from "../../../../Inputs/Attachment"
import Checkbox from "../../../../Inputs/Checkbox"
import Currency from "../../../../Inputs/Currency"
import Date from "../../../../Inputs/Date"
import DateTime from "../../../../Inputs/DateTime"
import Decimal from "../../../../Inputs/Decimal"
import DetailTab from "../../../../Inputs/DetailTab"
import DetailTabReadOnly from "../../../../Inputs/DetailTabReadOnly"
import Hours from "../../../../Inputs/Hours"
import Integer from "../../../../Inputs/Integer"
import MultiSelect from "../../../../Inputs/MultiSelect"
import Period from "../../../../Inputs/Period"
import Search from "../../../../Inputs/Search"
import Selection from "../../../../Inputs/Selection"
import Text from "../../../../Inputs/Text"
import TextBox from "../../../../Inputs/TextBox"

export default ({ TABLENAME, FIELDTYPE, isFilter }) => {
  const Inputs = {
    A: Attachment, // Attachment
    B: isFilter ? Selection : Checkbox, // Boolean
    C: Currency, // Currency
    D: isFilter ? Period : Date, // Date
    E: DetailTab, // Entity
    F: Decimal, // Float
    H: Hours, // Hour
    I: Integer, // Integer
    K: Search, // Key (Foreign Key)
    L: Selection, // List
    M: TextBox, // Multiline
    N: Search, // Navigation
    P: Period, // Period
    R: DetailTabReadOnly, // Read only
    S: Text, // String
    T: isFilter ? Period : DateTime, // Timestamp
    O: MultiSelect // MultiSelect
  }

  return Inputs[FIELDTYPE.VALUE]
}
