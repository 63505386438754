import { MaterialIcons } from "@expo/vector-icons"
import React, { useState } from "react"
import { View } from "react-native"
import { useDispatch } from "react-redux"
import useAppSelector from "../../../../../../redux/hooks/useAppSelector"
import { changeFormValue, closeModal, setSelectedConnection } from "../../../../../../redux/redux-reducers/dashboardBuilder-reducer"
import { deleteConnection } from "../../../../../../redux/redux-reducers/dashboardBuilder-reducer/utils/deleteConnection"
import { fetchDashboard } from "../../../../../../redux/redux-reducers/dashboardBuilder-reducer/utils/fetchDashboard"
import saveConnection from "../../../../../../redux/redux-reducers/dashboardBuilder-reducer/utils/saveConnection"
import ModalWrapper from "../../../../../components/Modal"
import * as S from "../modalStyles"
import getEntityFields from "./utils/getEntityFields"

export default function ConnectionModal() {

  const dispatch = useDispatch()

  const [focusedIndex, setFocusedIndex] = useState(null)
  const [entityFields, setEntityFields] = useState([])

  const { selectedConnection, selectedDashboard } = useAppSelector(({ dashboardBuilderReducer }) => dashboardBuilderReducer.dashboardData)
  const {
    isVisible,
    name,
    mode,
    form,
    entityName,
    masterPath,
    fieldPath,
    collectionPath
  } = useAppSelector(({ dashboardBuilderReducer }) => dashboardBuilderReducer.modals.connectionModal)

  return (
    <ModalWrapper isVisible={isVisible} backgroundTransparent>
      <S.Container>
        <S.CloseButton onPress={async () => {
          setEntityFields([])
          dispatch(fetchDashboard(selectedDashboard))
          dispatch(closeModal({ modalName: name }))
        }}>
          <MaterialIcons name="close" size={20} color="#333333" />
        </S.CloseButton>

        <S.Title>{mode === "new" ? "New" : "Edit"} Connection</S.Title>

        <S.SelectedView>
          <View style={{
            flexDirection: "row",
            alignItems: "center",
            gap: 3
          }}>
            <S.SelectedText>ID:</S.SelectedText>
            <S.SelectedField>
              {mode === "new" ?
                <S.ListInput
                  data={entityFields}
                  dropdownPosition='bottom'
                  labelField="ID"
                  valueField="ID"
                  isEditable={true}
                  placeholder={form?.ENTITY?.LABEL ?? "Select Field"}
                  search
                  onChange={item => {
                    dispatch(changeFormValue({
                      modalName: name,
                      fieldName: "ID",
                      value: item.ID
                    }))
                    dispatch(changeFormValue({
                      modalName: name,
                      fieldName: "ENTITY",
                      value: item.ENTITY
                    }))
                    dispatch(changeFormValue({
                      modalName: name,
                      fieldName: "CONNECTIONTYPE",
                      value: item.FIELDTYPE === "E" ?
                        {
                          VALUE: "detail",
                          LABEL: "Detail Screen"
                        } : {
                          VALUE: "field",
                          LABEL: "Update Field"
                        }
                    }))
                  }}
                  onFocus={async () => !entityFields.length && setEntityFields(await getEntityFields(entityName))}
                />
                :
                <S.TextInput
                  value={form?.ID}
                  editable={false}
                />
              }
            </S.SelectedField>

          </View>

          {form?.ID &&
            <>
              <View style={{
                flexDirection: "row",
                alignItems: "center",
                gap: 3
              }}>
                <S.SelectedText>Entity:</S.SelectedText>
                <S.SelectedField>
                  <S.TextInput
                    value={form?.ENTITY.LABEL}
                    editable={false}
                  />
                </S.SelectedField>
              </View>

              <View style={{
                flexDirection: "row",
                alignItems: "center",
                gap: 3
              }}>
                <S.SelectedText>Type:</S.SelectedText>
                <S.SelectedField>
                  <S.TextInput
                    value={form?.CONNECTIONTYPE?.LABEL}
                    editable={false}
                  />
                </S.SelectedField>
              </View>
            </>
          }
        </S.SelectedView>

        <S.Header>
          <S.SubTitle>Sub Connections ({selectedConnection?.connections?.length ?? 0})</S.SubTitle>
          <S.ButtonsView>
            {mode === "edit" ?
              <S.AddButton onPress={() => {
                setEntityFields([])
                dispatch(setSelectedConnection({
                  masterPath: masterPath,
                  entityName: selectedConnection.ENTITY.ID,
                  fieldPath,
                  collectionPath: `${collectionPath}/${"${" + fieldPath + ".ID" + "}"}`
                }))
              }}>
                <S.TitleButton>New Connection</S.TitleButton>
              </S.AddButton>
              :
              <S.AddButton onPress={() => dispatch(saveConnection({
                connection: form,
                masterPath: masterPath,
                entityName,
                fieldPath,
                collectionPath
              }))}>
                <S.TitleButton>Save Connection</S.TitleButton>
              </S.AddButton>
            }
            {selectedConnection?.connections?.length > 0 &&
              <S.DeleteButton onPress={() => dispatch(deleteConnection({
                selectedConnection,
                connectionPath: `${masterPath}/CONNECTIONS`
              }))}>
                <S.DeleteIcon name="trash-alt" />
              </S.DeleteButton>}
          </S.ButtonsView>
        </S.Header>

        <S.UnderLine />

        <S.ListArea contentContainerStyle={{ gap: 5 }}>
          {
            selectedConnection?.connections?.map((item, index) => (
              <S.Card
                key={index}
                isSelected={index === focusedIndex}
                onPress={() => {
                  setFocusedIndex(index)
                  dispatch(setSelectedConnection({
                    connection: item,
                    masterPath: masterPath
                  }))
                }}
              >
                <S.CardFieldArea>
                  <S.CartText style={{ fontWeight: "600" }}>Entity: <S.CartText>{item.ENTITY.LABEL}</S.CartText></S.CartText>
                </S.CardFieldArea>
                <S.CardFieldArea>
                  <S.CartText style={{ fontWeight: "600" }}>Field: <S.CartText>{item.FIELD ?? item.ENTITY.ID}</S.CartText></S.CartText>
                </S.CardFieldArea>
                <S.CardFieldArea>
                  <S.CartText style={{ fontWeight: "600" }}>Connection Type: <S.CartText>{item.CONNECTIONTYPE.LABEL}</S.CartText></S.CartText>
                </S.CardFieldArea>
              </S.Card>
            ))
          }
        </S.ListArea>

      </S.Container>
    </ModalWrapper>
  )
}
