import styled from "rn-css"
import globalStyles from "../../../../../../global/globalStyles"

export const Container = styled.View`
  background-color: ${globalStyles.colors.background};

  max-height: 70%;
  width: 60%;
  flex: 1;

  align-self: center;

  padding: 10px;
  gap: 5px;

  border-radius: 4px;
`

export const InfoText = styled.Text`
  color: ${globalStyles.colors.removeButton};

  font-weight: bold;

  text-align: center;
`

export const FormView = styled.View`
  flex: 1;
  max-height: 25%;
`

export const TableView = styled.View`
  flex: 1;
`
