import RuntimeConfiguration from "../../../../../../../models/runtimeConfiguration"
import showAlert from "../../../../../../../services/showAlert"

export default async (controller, setUpdateTable, closeModal = () => null, resetModel) => {
  const models = controller.models()
  const { ID } = models.T_DTD_FNC

  if (!ID) return showAlert({
    text: "No function selected",
    title: "Failed to delete function",
    titleType: "error"
  })

  if (!ID.includes("Z_") && !RuntimeConfiguration.getIsDeveloperConfigurationMode())
    return showAlert({
      text: "It is not possible to delete a native function",
      title: "Failed to delete function",
      titleType: "error"
    })

  showAlert({
    type: "confirm",
    text: "Do you want to delete the selected function?",
    title: "This action cannot be undone",
    titleType: "warning",
    handleConfirm: async () => {
      await controller.deleteFunction()
      setUpdateTable(s => !s)
      resetModel()
      closeModal()
    }
  })
}

