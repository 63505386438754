import dbRequest from "../../../../../../../services/dbRequest/dbRequest"

export default async function filterRecords({ fieldData, searchedValue, records }) {
  const { T_DTD_ATR } = fieldData

  const padronizedSearchedValue = padronizeString(searchedValue)

  if (!records) records = await dbRequest.loadRecords(T_DTD_ATR.ENTITY.ID).execute()

  const matchingRecord = records.find(record => {
    const padronizedID = padronizeString(record.ID)
    const padronizedLabel = padronizeString(record[T_DTD_ATR.LABEL])

    return padronizedID === padronizedSearchedValue || padronizedLabel === padronizedSearchedValue
  })

  if (matchingRecord) return [matchingRecord]

  const filteredRecords = records.filter(record => {
    const padronizedID = padronizeString(record.ID)
    const padronizedLabel = padronizeString(record[T_DTD_ATR.LABEL])

    const doesIncludeID = padronizedID.includes(padronizedSearchedValue)
    const doesIncludeLabel = padronizedLabel.includes(padronizedSearchedValue)

    return doesIncludeID || doesIncludeLabel
  })

  return filteredRecords
}

const padronizeString = str => String(str).toUpperCase()
