import recordIdExists from "../../../../../../../../services/registration/recordIdExists"
import showAlert from "../../../../../../../../services/showAlert"

export default ({ setUpdateTablesTable, controller }) => {
  // Create a file input
  const input = document.createElement("input")
  input.type = "file"
  input.style.display = "none"

  // Add the input to the DOM
  document.body.appendChild(input)

  // Add an event listener for when the user chooses a file
  input.addEventListener("change", (event) => handleImport(event, setUpdateTablesTable, controller))

  // Activate the input click to open file selection popup
  input.click()
}

function handleImport(event, setUpdateTablesTable, controller) {
  const file = event.target.files[0]

  if (file) {
    // Read the content of the file using FileReader
    const reader = new FileReader()

    reader.onload = async function (e) {
      try {
        // Convert the JSON string into an object
        const tableData = JSON.parse(e.target.result)

        if (await recordIdExists("T_DTD_TAB", tableData.ID)) {
          showAlert({
            type: "confirm",
            text: "Do you want to update it?",
            title: "The selected table already exists",
            titleType: "warning",
            handleConfirm: async () => {
              await controller.update("T_DTD_TAB", { ...tableData })
              showAlert({
                text: "Data imported successfully",
                title: "Table updated",
                titleType: "success"
              })
              setUpdateTablesTable(s => !s)
            }
          })
        }

        else {
          showAlert({
            text: "Do you want to import the selected table?",
            type: "confirm",
            handleConfirm: async () => {
              await controller.save("T_DTD_TAB", { ...tableData })
              showAlert({
                text: "Data imported successfully",
                title: "Table created",
                titleType: "success"
              })
              setUpdateTablesTable(s => !s)
            }
          })
        }

      } catch (error) {
        console.error("Error parsing JSON file:", error)
      }
    }

    // Start reading the file as text
    reader.readAsText(file)
  }
}
