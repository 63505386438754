import React from "react"
import showAlert from "../../../../../../services/showAlert"
import Access from "../components/Access"
//import ConfigurationModal from "../../../../Toolbars/components/MoreOptionsButton/components/DynamicModal/components/ConfigurationModal"
import FormModal from "../components/FormModal/"
import ReceitasWS from "../components/ReceitaWS"
import handleExport from "./ExportSpreadsheet/handleExport"

export default function getModalContent({ entityName, models, selectedOption, closeModal, setUpdateForm }) {

  const { TYPE, EXPRESSION } = selectedOption

  if (TYPE.VALUE === "E") return

  if (TYPE.VALUE === "N") { //Native functions
    if (EXPRESSION === "fnc_exportSpreadsheet") {
      handleExport({
        entityName,
        models
      })
      closeModal()
      return
    }

    if (EXPRESSION === "fnc_importSpreadsheet") {
      //return <ImportSpreadSheet entityName={entityName} controller={controller} />
    }

    if (EXPRESSION === "fnc_access") {
      if (models[entityName].ADMIN) {
        showAlert({
          text: "It is not possible to change an admin's access",
          title: "Access denied",
          titleType: "error"
        })
        closeModal()
        return
      }

      return <Access entityName={entityName} models={models} closeModal={closeModal} />
    }

    if (EXPRESSION === "fnc_receitaWS") {
      return <ReceitasWS closeModal={closeModal} models={models} setUpdateForm={setUpdateForm} />
    }

    {/*      if (["T_MOV_HDR/T_SET_HDR", "T_MOV_ITE/T_SET_ITE"].includes(EXPRESSION)) {
      return <ConfigurationModal selectedOption={selectedOption} controller={controller} closeModal={closeModal} />
    }
 */ }
  }
  if (TYPE.VALUE === "F") {//Functions with expression to evaluate
    return <FormModal selectedOption={selectedOption} models={models} entityName={entityName} closeModal={closeModal} />
  }
}
