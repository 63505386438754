const checkIfIsInclude = (addedProducts, controller, product) => {
  let updateTemplate = null
  if (addedProducts.length > 0) {
    updateTemplate = addedProducts.filter(item => item.ITEMID.ID == product.ID)
    if (updateTemplate.length > 0) {
      return updateTemplate[0]
    }
    else return controller.itemsHeaderStructure()
  } else {
    return controller.itemsHeaderStructure()
  }
}

export default checkIfIsInclude
