import styled from "styled-components"

const Container = styled.div`
  display: flex;
  width: 70%;
  height: 30px;
  gap: 20px;
  justify-content: space-evenly;
  align-items: center;
`

export default Container
