import React from "react"
import { Chart } from "react-google-charts"
import { useDispatch } from "react-redux"
import { getModalData, updateThisChart } from "../../../../../redux/redux-reducers/dashboard-reducer"
import ExpandableButton from "../../ExpandableButton"
import ReloadButton from "../../ReloadButton"
import SliderInput from "../../SliderInput"
import useChartConstruct from "../hooks/useChartConstruct"
import * as S from "../styles.web"
import { ChartProps } from "../types/types"

export default function ScatterChart({ chartData, controller, chartId, entityName, chartIdArr }: ChartProps) {

  const dispatch = useDispatch()

  const props = {
    chartData,
    controller,
    chartId,
    chartIdArr,
    entityName
  }

  const { data, attributes, actualValue, setActualValue, chartEvents } = useChartConstruct(props)

  if (data.length === 0)
    return (
      <S.LoadingView>
        <S.Loading />
      </S.LoadingView>
    )

  return data.length > 1 && (
    <S.Container>
      <S.ButtonsView>
        <SliderInput
          value={actualValue}
          minValue={1}
          maxValue={data.length}
          onChange={setActualValue}
        />
        <ReloadButton onPress={() => dispatch(updateThisChart(chartId))} />
        <ExpandableButton onPress={() => dispatch(getModalData({
          isVisible: true,
          data,
          attributes,
          chartType: "ScatterChart"
        }))} />
      </S.ButtonsView>
      <Chart
        width="99%"
        height="99%"
        chartType="ScatterChart"
        data={data}
        options={attributes}
        chartEvents={chartEvents}
      />
    </S.Container>
  )
}
