import getTableData from "../../../../services/registration/getTableData"
import getTableRecords from "../../../../services/registration/getTableRecords"

export default async (props) => {
  const { setRecordsAmount = () => null } = props

  const tableData = await getTableData(props)

  const records = await getTableRecords({
    ...props,
    tableFilters: tableData.filters ?? []
  })

  setRecordsAmount(records.length)

  return {
    ...tableData,
    records
  }
}
