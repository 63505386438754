import Navigation from "../../../../models/navigation"
import Permissions from "../../../../models/permissions"

const handleNavigation = (routine) => {
  Permissions.setCurrentRoutine(routine.ID)

  if (routine.TYPE?.VALUE === "Report") {
    // @ts-ignore
    return navigation.navigate("Root", {
      screen: "Report",
      params: {
        attribute: routine.ID,
        controller: null,
        transaction: routine.ID
      }
    })
  }

  Navigation.createTab({
    component: routine.TYPE?.VALUE,
    params: {
      RootParentID: routine.RootParentID,
      attribute: routine.ID,
      transaction: routine.ID,
      screenType: routine.TYPE?.VALUE
    },
    tabName: routine.DESCRIPTION
  })
}

export default handleNavigation