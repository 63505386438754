import { Feather } from "@expo/vector-icons"
import React from "react"
import { useTranslation } from "react-i18next"
import LoginData from "../../../models/loginData"
import { defaultTheme } from "../../../styles/themes/default"
import NavigationPathViewer from "../../screens/OrderComposerScreen/NavigationPathViewer/index.web"
import MoreOptionsButton from "../Toolbars/components/MoreOptionsButton"
import * as S from "./styles.web"
import handleApplyFilters from "./utils/handleApplyFilters"

export default function HeaderComposer({ masterEntity, screenProps, controller, navigation, handleFinalize, handleNewDocument, searchText, setSearchText, setIsLoading }) {

  const { t } = useTranslation()
  const { name, screenOrder, collectionName } = screenProps
  const { permissions } = controller.models()._REGISTRATION_DATA[masterEntity]

  const isSystemAdmin = LoginData.getLoginData().ADMIN
  const hasPermissionToCreate = permissions.CREATE
  const hasPermissionToUpdate = permissions.UPDATE

  const isSearchInputDisable = collectionName.includes("TAB_")

  const isNewOrderButtonDisable = name === "Transaction" && (!hasPermissionToCreate || !isSystemAdmin)

  const validateConfirmOrderButton = () => {

    const isUpdate = controller.models()[masterEntity].ID

    if (isUpdate && (!hasPermissionToUpdate && !isSystemAdmin)) return true
    else if (!isUpdate && !controller?.isLastTab && (!hasPermissionToCreate || !isSystemAdmin)) return true
    return false
  }

  const isConfirmOrderButtonDisable = validateConfirmOrderButton()

  return (
    <S.Container>
      <S.Content>
        <S.TitleContainer>
          {`${screenOrder >= 0 ? `${screenOrder + 1}. ` : ""}`} {t(name)}
        </S.TitleContainer>

        <S.InputContainer>
          <S.TextInput
            disabled={isSearchInputDisable}
            isDisabledSearchInput={isSearchInputDisable}
            placeholder={t("Enter search")}
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value)
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleApplyFilters({
                  controller,
                  setIsLoading
                })
              }
            }}
            autoFocus
          />
          <S.ClearTextButton onClick={() => setSearchText("")}>
            <Feather name={searchText ? "x" : "search"} size={18} color={defaultTheme["gray-500"]} />
          </S.ClearTextButton>
        </S.InputContainer>

        <S.ButtonsContainer>
          <S.Button
            isDisable={isConfirmOrderButtonDisable}
            disabled={isConfirmOrderButtonDisable}
            onClick={async () => handleFinalize()}>
            <S.IconContainer isDisable={isConfirmOrderButtonDisable}>
              <Feather name="check" size={16} color="white" />
            </S.IconContainer>
            {t(controller.models()[masterEntity]?.ID ? "Update changes" : "Finalize Document")}
          </S.Button>
          <S.Button
            disabled={isNewOrderButtonDisable}
            isDisable={isNewOrderButtonDisable}
            onClick={handleNewDocument}
          >
            <S.IconContainer
              isDisable={isNewOrderButtonDisable}>
              <Feather name="plus" size={16} color="white" />
            </S.IconContainer>
            {t("New Document")}
          </S.Button>

          <MoreOptionsButton
            entity={masterEntity}
            models={controller.models()}
            gridRef={null}
            setUpdateForm={() => null}
            mode="form"
            disabled={!controller.isLastTab}
          />
        </S.ButtonsContainer>
      </S.Content>

      <NavigationPathViewer
        navigationScreens={controller.OrderComposerController.orderPrototype}
        models={controller.models()}
        jumpTo={screenName => {
          navigation.navigate(screenName)
        }}
        focusScreenName={name}
      />
    </S.Container>
  )
}

