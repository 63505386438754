import React from "react"
import * as S from "./styles"

export default function ReloadButton({ onPress }: { onPress: () => void }) {

  return (
    <S.Button onPress={onPress}>
      <S.Icon name="sync" />
    </S.Button>
  )
}
