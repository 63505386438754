import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import * as S from "./styles"
import showAlert from "../../../../../../../../../services/showAlert"
import fetchPartnerData from "./utils/fetchPartnerData"
import getAlert from "./utils/getAlert"
import LoadingAnimation from "../../../../../../../LoadingAnimation/index.web"
import { defaultTheme } from "../../../../../../../../../styles/themes/default"
import { Feather } from '@expo/vector-icons'

export default function ReceitasWS({ models, closeModal, setUpdateForm }) {
  const { t } = useTranslation()

  const [inputData, setInputData] = useState({ value: "", isFocused: false })
  const [isFetchingPartnerData, setIsFetchingPartnerData] = useState(false)

  const formatText = cnpj => {
    const numbers = extractNumbersFromCnpj(cnpj)
    const formattedCnpj = numbers.length === 14
      ? numbers.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{0,2})/, "$1.$2.$3/$4-$5")
      : cnpj

    setInputData(state => ({ ...state, value: formattedCnpj }))
  }

  const extractNumbersFromCnpj = cnpj => cnpj.replace(/\D/g, "")

  const handleSearch = async () => {
    if (inputData.value.length < 18) return showAlert({
      text: "Invalid CNPJ",
      title: "Failed to get partner data",
      titleType: "error"
    })

    setIsFetchingPartnerData(true)

    const responseCode = await fetchPartnerData(extractNumbersFromCnpj(inputData.value), models)
    //@ts-ignore
    showAlert(getAlert(responseCode))
    setUpdateForm(s => !s)
    setIsFetchingPartnerData(false)
    closeModal()
  }

  function handleCloseModal() {
    setInputData({ value: "", isFocused: false })
    closeModal()
  }

  return (
    <S.Container>
      <S.Header>
        <S.Title>{t("Enter the CNPJ")}</S.Title>

        <S.CloseButton onClick={handleCloseModal}>
          <Feather name="x" size={24} color={defaultTheme["gray-700"]} />
        </S.CloseButton>
      </S.Header>

      <S.InputContainer>
        <S.InputIcon name="mail" isFocused={inputData.isFocused} />
        <S.Input
          maxLength={18}
          placeholder="CNPJ"
          value={inputData.value}
          onChange={(e) => formatText(e.target.value)}
          onFocus={() => setInputData({ ...inputData, isFocused: true })}
          onBlur={() => setInputData({ ...inputData, isFocused: false })}
        />
      </S.InputContainer>

      <S.SendButton onClick={handleSearch}>
        {isFetchingPartnerData
          ? <LoadingAnimation size='medium' color={defaultTheme["white"]} />
          : (
            <>
              <Feather name="search" size={16} color={defaultTheme["white"]} />
              {t("Search")}
            </>
          )
        }
      </S.SendButton>
    </S.Container>
  )
}
