import styled from "styled-components"
import { defaultTheme } from "../../../../../../../../styles/themes/default"

interface InputProps {
  editable?: boolean
}

export const Input = styled.input<InputProps>`
  background-color: ${({ editable }) => editable ? defaultTheme["white"] : "transparent"};
  border: none;
  border-radius: 16px;
  color: ${defaultTheme["gray-500"]};
  font-size: 1rem;
  font-weight: 600;
  height: ${defaultTheme.formInput.height}px;
  margin-top: 4px;
  outline: none;
  padding: 2px 10px;
  width: 200px;
`
