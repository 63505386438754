import LoginData from "../../../../models/loginData"
import Permissions from "../../../../models/permissions"
import dbRequest from "../../../../services/dbRequest/dbRequest"

const getRoutines = async () => {
  const [tables, reports, dashboards] = await Promise.all([
    getRequest("T_DTD_TAB"),
    getRequest("T_RDB_REP"),
    getRequest("T_RDB_DSB")
  ]) as any

  return [
    {
      ID: "T_REC_CAL",
      DESCRIPTION: "Calendar",
      ORDERBY: 1,
      TYPE: { VALUE: "Calendar" },
      isLauncher: true
    },
    ...tables,
    ...reports,
    ...dashboards
  ]
}

export default getRoutines

const getRequest = async (entity: string) => {
  const isSystemAdmin = LoginData.getLoginData().ADMIN
  const customEntities = {
    T_RDB_DSB: "Dashboard",
    T_RDB_REP: "Reports"
  }

  const routines = await dbRequest.loadRecords(entity).where("NAVID", "!=", null).execute() as any
  const validRoutines = isSystemAdmin ? routines : routines.filter(({ ID }) => Permissions.getPermissions(ID)?.READ)

  return validRoutines.map(({ ID, DESCRIPTION, ENTITY, ORDERBY, TYPE, NAVID }) => ({
    ID,
    DESCRIPTION: entity === "T_DTD_TAB" ? ENTITY : DESCRIPTION,
    ORDERBY,
    NAVID,
    TYPE: entity === "T_DTD_TAB" ? TYPE : { VALUE: customEntities[entity] },
    isLauncher: true
  }))
}
