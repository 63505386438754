import styled from "styled-components"
import { defaultTheme } from "../../../../../../styles/themes/default"

export const Container = styled.div`
  width: calc(100vw - 63px);
  height: 100%;
  margin-top: 2px;
  overflow-y: scroll;
`

export const LoadingModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 80px;

  border-radius: 10px;

  background-color: ${defaultTheme["white"]};
`
