import deleteCollection from "./requests/deleteCollection/"
import deleteRecords from "./requests/deleteRecords/"
import getReport from "./requests/getReport/"
import loadRecords from "./requests/loadRecords/"
import receitaWS from "./requests/receitaWS/"
import saveRecords from "./requests/saveRecords/"
import saveSessionToken from "./requests/saveSessionToken/"
import updateRecords from "./requests/updateRecords/"

interface FilterPeriod {
  FIELD: string;
  STARTING_AT: string;
  ENDING_AT: string;
}

export default class dbRequest {

  constructor(type: string, param: string) {
    this.type = type
    this.param = param
  }

  static collections = {}

  static loadRecords = (i: string) => new dbRequest("load", i)
  static queryExecuter = (i: string) => new dbRequest("query", i)
  static saveRecords = (i: string) => new dbRequest("save", i)
  static updateRecords = (i: string) => new dbRequest("update", i)
  static deleteRecords = (i: string) => new dbRequest("delete", i)
  static deleteCollection = (i: string) => new dbRequest("deleteCollection", i)
  static getReport = (i: string) => new dbRequest("report", i)
  static receitaWS = (i: string) => new dbRequest("receitaWS", i)
  static saveSessionToken = (i?: string) => new dbRequest("saveSessionToken", i)

  private type = null
  private param = null

  private _admin = false
  admin = () => {
    this._admin = true
    return this
  }

  private _record = null
  record = (i: object) => {
    this._record = i
    return this
  }

  private whereArr = []
  where = (field: string, operator: string, value: unknown) => {
    this.whereArr.push({
      field: field,
      operator: operator,
      value: value
    })
    return this
  }

  private searchObj = null
  search = (i: object) => {
    this.searchObj = i
    return this
  }

  private _orderBy = null
  orderBy = (i: string) => {
    this._orderBy = i
    return this
  }

  private _lastDocument = null
  lastDocument = (i: object) => {
    this._lastDocument = i
    return this
  }

  private _hasLimit = false
  hasLimit = () => {
    this._hasLimit = true
    return this
  }

  private _filterPeriod = null
  filterPeriod = (i: FilterPeriod) => {
    this._filterPeriod = i
    return this
  }

  async execute(callback = (r: unknown) => r) {
    //dbAuthorization.ensureSession()

    switch (this.type) {
      case "load": return callback(await loadRecords(this._admin, this.param, this.whereArr, this.searchObj, this._lastDocument, this._hasLimit, this._filterPeriod))
      case "save": return callback(await saveRecords(this._admin, this.param, this._record))
      case "update": return callback(await updateRecords(this._admin, this.param, this._record))
      case "delete": return callback(await deleteRecords(this._admin, this.param, this._record))
      case "deleteCollection": return callback(await deleteCollection(this._admin, this.param))
      case "report": return callback(await getReport(this.param, this._record))
      case "receitaWS": return callback(await receitaWS(this.param))
      case "saveSessionToken": return callback(await saveSessionToken(this._record))
      default: callback(new Error("ServiceNotFoundException: the service request from dbRequest do not exists"))
    }
  }
}
