import React from "react"
import Header from "./components/Header"
import RenderRoutines from "./components/RenderRoutines"
import Container from "./styles"

export default function Access({ entityName, models, closeModal }) {
  const permissions = {}

  const props = {
    entityName,
    models,
    permissions
  }

  return (
    <Container>
      <Header {...props} closeModal={closeModal} />

      <RenderRoutines {...props} />
    </Container>
  )
}

