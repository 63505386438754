import React, { useContext } from "react"
import Form from "../Form"
import Panel from "../Panel"
import Table from "../Table/"
import GridModeProvider, { GridModeContext } from "./contexts/GrideModeProvider"
import useKeyboardShortcuts from "./hooks/useKeyboardShortcuts"
import useRegistration from "./hooks/useRegistration"
import Container from "./styles"
import getFunctions from "./utils/ButtonsFunctions/getFunctions"

function RenderScreenFactory(params) {
  const { mode, changeMode } = useContext(GridModeContext)

  const states = useRegistration({
    ...params,
    mode,
    changeMode
  })

  const props = {
    ...states,
    ...params,
    mode,
    models: params.controller.models(),
    changeMode
  }

  const functions = getFunctions(props)

  useKeyboardShortcuts(props)

  return (
    <Container>
      <Panel  {...props} hasToValidatePermissions functions={functions} />

      {mode === "table"
        ? <Table {...props} />
        : <Form {...props} />
      }
    </Container>
  )
}

export default function ScreenFactory(params) {

  return (
    <GridModeProvider>
      <RenderScreenFactory {...params} />
    </GridModeProvider>
  )
}

