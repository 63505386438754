import { FontAwesome6 } from "@expo/vector-icons"
import { Dropdown } from "react-native-element-dropdown"
import styled from "styled-components/native"
import globalStyles from "../../../../../global/globalStyles"

export const Container = styled.View`
  width: 65%;
  height: 50%;
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
`

export const CloseButton = styled.TouchableOpacity`

  position: absolute;
  right: 10px;
  top: 10px;

  align-items: center;
  padding:  3px;
  
  background-color: ${globalStyles.colors.tertiary};

  border: none;
  border-radius: 5px;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 10;
`
export const ButtonsView = styled.View`
  flex-direction: row;
  gap: 10px;
`

export const Header = styled.View`
  flex-direction: row;
  padding-right: 20px;
  justify-content: space-between;
`

export const AddButton = styled.TouchableOpacity`
  padding: 5px 10px;
  border-radius: 4px;
  background-color: #3498DB;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
`

export const DeleteButton = styled(AddButton)`
  background-color: #F04B4B;
  justify-content: center;
  align-items: center;
`

export const DeleteIcon = styled(FontAwesome6)`
  color: #fff;
  font-size: 14px;
`

export const EditFiltersButton = styled(AddButton)`
  background-color: #E67E22;
  position: absolute;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  right: 50px;
  top: 10px;
`

export const TitleButton = styled.Text`
  font-size: 12px;
  color: #fff;
  font-weight: 600;
`

export const Title = styled.Text`
  font-size: 18px;
  font-weight: 600;
  color: #2e2e2e;
  text-align: center;
`

export const SelectedView = styled.View`
  flex-direction: row;
  align-items: center;
  align-self: center;
  padding: 10px;
  margin: 10px 0px;
  border-radius: 4px;
  background-color: ${globalStyles.colors.tertiary};
  gap: 20px;
`

export const SelectedField = styled.View`
  background-color: #fff;
  padding: 3px 10px;
  border-radius: 4px;
`

export const SelectedText = styled.Text`
  font-size: 14px;
  font-weight: 600;
  color: #3498DB;
`
export const TextInput = styled.TextInput`
  width: 200px;
  height: 24px;
  font-size: 12px;
  font-weight: 400;
  color: #2e2e2e;
  outline-width: 0;
`

export const SubTitle = styled.Text`
  font-size: 16px;
  font-weight: 500;
  color: #2e2e2e;
  margin-left: 10px;
`

export const UnderLine = styled.View`
  width: 40%;
  height: 1.5px;
  margin-top: 3px;
  margin-bottom: 10px;
  background-color: #2e2e2e;
`

export const ListArea = styled.ScrollView`
  flex: 1;
  width: 100%;
  padding: 5px;
  border-radius: 10px;
  background-color: ${globalStyles.colors.background};
`

export const TextBoxInputArea = styled.View`
  flex: 1;
  width: 100%;
  padding: 5px;
  border-width: 1px;
  border-radius: 10px;
  border-color: ${globalStyles.colors.placeholder};
  border-style: dashed;
  background-color: ${globalStyles.colors.tertiary};
`

export const Card = styled.TouchableOpacity`
  flex-direction: row;
  width: 100%;
  height: min-content;
  justify-content: space-around;
  padding: 5px;
  border-radius: 4px;
  background-color: ${({ isSelected, backgroundColor }) => isSelected ? backgroundColor || "#3498DB" : "#fff"};
`

export const CardFieldArea = styled.View`
  width: 32%;
  padding: 5px;
  border-radius: 4px;
  background-color: ${globalStyles.colors.tertiary};
`

export const CartText = styled.Text.attrs({
  numberOfLines: 1
})`
  font-size: 12px;
  color: #2e2e2e;
  font-weight: 500;
`

export const SaveButton = styled(EditFiltersButton)`
  background-color: #3498DB;
`

export const ListInput = styled(Dropdown).attrs(() => ({
  maxHeight: 250,
  activeColor: "transparent",
  placeholderStyle: {
    textAlign: "center",
    fontSize: 12,
    color: "#2e2e2e"
  },
  iconColor: "#3498DB",
  iconStyle: { cursor: "pointer" },
  containerStyle: {
    width: 220,
    borderRadius: 3
  },
  searchPlaceholder: "Search",
  inputSearchStyle: {
    height: 40,
    width: "94%",
    fontSize: 14,
    color: "#2e2e2e",
    outlineWidth: 0
  },
  selectedTextStyle: {
    color: "#2e2e2e",
    width: "100%",
    textAlign: "center",
    fontSize: 12
  },
  selectedTextProps: {
    numberOfLines: 1
  }
}))`
  width: 200px;
  color: #2e2e2e;
  font-size: 12px;
  border-radius: 3px;
  height: 26px;
  padding: 6px;
  outline-width: 0;
`

export const ListsView = styled.View`
  flex-direction: row;
  flex: 1;
  gap: 5px
`

export const DividingLine = styled.View`
  background-color: ${globalStyles.colors.placeholder};

  height: 100%;
  width: 1px;
`

export const TextBoxInput = styled.TextInput`
  flex: 1;
  width: 100%;
  color: #2e2e2e;
  font-size: 12px;
  padding: 6px;
  outline-width: 0;
`

export const ExecuteButton = styled.TouchableOpacity`
  position: absolute;
  top: 5px;
  right: 10px;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  padding: 2px 8px;
  border-radius: 4px;
  background-color: #3498DB;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);

`

export const EditButton = styled.TouchableOpacity`
  width: auto;
  height: 100%;
  padding: 5px;
  background-color: ${globalStyles.colors.editButton};
  border-radius: 4px;
  justify-content: center;
  align-items: center;
`

