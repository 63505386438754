import styled from "rn-css"

export const Container = styled.View`
  height: 100%;
  gap: 10px;
  flex: 1;
`

export const Header = styled.View`
  flex-direction: row;
  width: 80%;
  justify-content: space-between;
  align-items: flex-end;
`

export const Title = styled.Text`
  font-size: 14px;
  font-weight: 500;
  color: #2e2e2e;
`

export const ColumnTitle = styled(Title)`
  font-size: 12px;
`

export const List = styled.ScrollView`
  border: 1px dashed lightgrey;
  border-radius: 10px;
  padding: 5px;
`
