import styled from 'styled-components/native';
import globalStyles from '../../../../../global/globalStyles'
import { FontAwesome6 } from '@expo/vector-icons';

export const Container = styled.View`
  flex: .5;
  padding: 10px 5px;
  background-color: #fff;
`;

export const Header = styled.View`
  flex-direction: row;
  padding-right: 20px;
  justify-content: space-between;
`

export const ButtonsView = styled.View`
  flex-direction: row;
  gap: 10px;
`

export const AddLevelButton = styled.TouchableOpacity`
  padding: 5px 10px;
  border-radius: 4px;
  background-color: #3498DB;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);

`

export const DeleteLevelButton = styled(AddLevelButton)`
  background-color: #F04B4B;
  justify-content: center;
  align-items: center;
`

export const DeleteIcon = styled(FontAwesome6)`
  color: #fff;
  font-size: 16px;
`

export const TitleButton= styled.Text`
  color: #fff;
  font-weight: 600;
`

export const Title = styled.Text`
  font-size: 18px;
  font-weight: 500;
  color: #2e2e2e;
  margin-left: 10px;
`

export const UnderLine = styled.View`
  width: 40%;
  height: 1.5px;
  margin-top: 3px;
  margin-bottom: 10px;
  background-color: #2e2e2e;
`

export const ListArea = styled.View`
  flex: 1;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  padding: 5px;
  gap: 10px;
  border-radius: 10px;
  background-color: ${globalStyles.colors.background};

  overflow: scroll;
`

export const Card = styled.TouchableOpacity`
  width: 32%;
  height: min-content;
  gap: 10px;
  padding: 5px;
  border-radius: 4px;
  background-color: ${({isSelected}) => isSelected ? "#3498DB" : "#fff"};
`

export const CardFieldArea = styled.View`
  width: 100%;
  padding: 5px;
  border-radius: 4px;
  background-color: ${globalStyles.colors.tertiary};
`

export const CartText = styled.Text.attrs({
  numberOfLines: 1
})`
  color: #2e2e2e;
  font-weight: 500;
`

