import showAlert from "../../../../../../../services/showAlert"

export default (controller, models, closeModal) => {
  const { ID, TYPE, DESCRIPTION } = models.MENU

  if (!ID) return showAlert({
    text: "No menu selected",
    title: "Failed to delete",
    titleType: "error"
  })

  if (!TYPE) return showAlert({
    text: "Invalid menu",
    title: "Failed to delete",
    titleType: "error"
  })

  showAlert({
    type: "confirm",
    text: "Do you want to remove the table from the menu?",
    title: `${DESCRIPTION}`,
    handleConfirm: async () => {
      await controller.deleteNavigation(ID)
      showAlert({
        text: "Changes will be applied after restarting the application",
        title: "Table removed successfully",
        titleType: "success"
      })
      models.MENU.reset()
      closeModal("manageMenu")
    }
  })
}
