import dbRequest from "../../../../../../../../../../../../services/dbRequest/dbRequest"

const getReportsRequest = async () => {
  const reportsMenu = {
    ID: "reports",
    IDACCESS: "reports",
    DESCRIPTION: "Reports",
    icon: "arrow-right",
    T_DTD_FNC: {}
  }

  const reports = await dbRequest.loadRecords("T_RDB_REP").execute() as any

  for (const report of reports) {
    const { ID, DESCRIPTION } = report

    reportsMenu.T_DTD_FNC[ID] = {
      ID,
      DESCRIPTION,
      ACTIVE: true
    }
  }

  return reportsMenu
}

export default getReportsRequest
