import React from "react"
import RegistrationBuilder from "../../components/RegistrationBuilder"
import RegistrationProvider from "./contexts/RegistrationProvider"
import Container from "./styles"

export default function RegistrationScreen({ route }) {
  const entity = route.params.attribute
  const screenType = route.params.screenType
  const record = route.params?.record

  return (
    <RegistrationProvider>
      <Container>
        <RegistrationBuilder entity={entity} type={screenType} record={record} />
      </Container>
    </RegistrationProvider>
  )
}
