import styled from "styled-components"
import { defaultTheme } from "../../../../../styles/themes/default"

export const Container = styled.button`
  text-align: center;
  background-color: ${defaultTheme["white"]};
  border-radius: 9px;
  height: 24.8px;
  padding: 0 9px;
  color: ${defaultTheme["gray-600"]};
  font-size: 0.7875rem;
`;