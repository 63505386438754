import React from "react"
import { useTranslation } from "react-i18next"
import * as S from "./styles"

export default function Header({ title, onSave, onClose }) {
  const { t } = useTranslation()

  return (
    <S.Container>
      <S.Title>{t(title)}</S.Title>

      <S.ButtonsView>
        <S.SaveButton onPress={onSave}>
          <S.SaveText>{t("Save")}</S.SaveText>
        </S.SaveButton>

        <S.CloseButton onPress={onClose}>
          <S.Icon name="close" />
        </S.CloseButton>
      </S.ButtonsView>
    </S.Container >
  )
}
