import React from "react"
import { useTranslation } from "react-i18next"
import Form from "../../../../index.web"
import FormToolbar from "../../../../../Toolbars/Form"
import ModalWrapper from "../../../../../Modal"
import handleDelete from "../utils/ButtonsFunctions/handleDelete"
import handleSave from "../utils/ButtonsFunctions/save/handleSave"
import handleUndo from "../utils/ButtonsFunctions/handleUndo"
import handleInsert from "../utils/ButtonsFunctions/handleInsert"
import handleCopy from "../utils/ButtonsFunctions/handleCopy"
import * as S from "./styles"

export default function FormModal(props) {
  const { t } = useTranslation()

  const { modalVisible, closeModal, field } = props

  const functions = {
    handleInsert: () => handleInsert(props),
    handleCopy: () => handleCopy(props),
    handleDelete: () => handleDelete(props),
    handleUndo: () => handleUndo(props),
    handleSave: ({ changeLoadingModalVisibility }) =>
      handleSave({
        ...props,
        setIsLoading: changeLoadingModalVisibility
      })
  }

  return (
    <ModalWrapper isVisible={modalVisible}>
      <S.Container>
        <S.CloseButton onClick={closeModal}>
          <S.CloseIcon />
        </S.CloseButton>

        <S.Title>{t(field.T_DTD_ATR.FIELDDESCRIPTION)}</S.Title>

        <S.FormContainer>
          <FormToolbar {...props} functions={functions} />
          <Form {...props} />
        </S.FormContainer>
      </S.Container>
    </ModalWrapper>
  )
}
