import React, { useState } from "react"
import User from "../../../../../services/dbRequest/user"
import showAlert from "../../../../../services/showAlert"
import * as InputStyles from "../LoginForm/styles"
import * as S from "./styles.web"
import Modal from "react-native-modal"
import { Feather } from "@expo/vector-icons"
import { defaultTheme } from "../../../../../styles/themes/default"

interface ModalProps {
  isVisible: boolean
  closeModal: () => void
}

export default function RecoverPasswordModal({ isVisible, closeModal }: ModalProps) {
  const [inputData, setInputData] = useState({ value: "", isFocused: false })

  async function sendPasswordRecovery() {
    try {
      await User.resetPassword(inputData.value)
      showAlert({
        text: "Check your inbox",
        title: "Email sent",
        titleType: "success"
      })
    } catch (e) {
      showAlert({
        text: "Email not found",
        title: "Failed to send email",
        titleType: "error"
      })
    }

    closeModal()
  }

  function handleCloseModal() {
    setInputData({ value: "", isFocused: false })
    closeModal()
  }

  return (
    <Modal isVisible={isVisible}>
      <S.Container>
        <S.Header>
          <S.Title>Recuperar Senha</S.Title>

          <S.CloseButton onClick={handleCloseModal}>
            <Feather name="x" size={24} color={defaultTheme["gray-700"]} />
          </S.CloseButton>
        </S.Header>

        <InputStyles.InputContainer style={{ width: "100%" }}>
          <InputStyles.InputIcon name="mail" isFocused={inputData.isFocused} />
          <InputStyles.Input
            placeholder="Digite seu email"
            value={inputData.value}
            onChange={(e) => setInputData({ ...inputData, value: e.target.value })}
            onFocus={() => setInputData({ ...inputData, isFocused: true })}
            onBlur={() => setInputData({ ...inputData, isFocused: false })}
          />
        </InputStyles.InputContainer>

        <S.SendButton onClick={sendPasswordRecovery}>
          <Feather name="send" size={16} color={defaultTheme["white"]} />
          Enviar
        </S.SendButton>
      </S.Container>
    </Modal>
  )
}
