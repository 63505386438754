import { createAsyncThunk } from '@reduxjs/toolkit'
import getDashboardQueries from './getDashboardQueries'
import getDashboardInterface from './getDashboardInterface'
import { ChartDataArray } from '../../../../views/screens/DashboardsBuilderScreen/utils/ChartData'
import getDashboardFields from './getDashboardFields'

export const fetchDashboard = createAsyncThunk(
  'dashboardBuilder/fetchDashboardData',
  async (selectedDashboard) => {

    ChartDataArray.clearArr()
    ChartDataArray.setDashboardSelected(selectedDashboard)

    const dashboardQueriesPromise = getDashboardQueries(selectedDashboard.ID)
    const dashboardLevelsPromise = getDashboardInterface(selectedDashboard.ID)
    const dashboardFieldsPromise = getDashboardFields(`T_RDB_DSB/${selectedDashboard.ID}`)

    const [dashboardQueries, dashboardLevels, dashboardFields] = await Promise.all([
      dashboardQueriesPromise,
      dashboardLevelsPromise,
      dashboardFieldsPromise
    ])

    return {
      dashboardFields,
      dashboardQueries,
      dashboardLevels,
      selectedDashboard
    }
  }
)