import allRequiredFieldsFilled from "../../../../../../../services/registration/allRequiredFieldsFilled"
import showAlert from "../../../../../../../services/showAlert"

export default async (controller, setUpdateTable, closeModal = () => null, resetModel) => {
  const models = controller.models()

  if (!await allRequiredFieldsFilled({
    models,
    entity: "T_DTD_FNC"
  })) return

  showAlert({
    type: "confirm",
    text: "Do you want to save?",
    title: "Function saving",
    handleConfirm: async () => {
      const functionID = await controller.saveFunction()

      showAlert({
        text: functionID,
        title: "Function saved successfully",
        titleType: "success"
      })

      resetModel()
      closeModal()
      setUpdateTable(s => !s)
    }
  })
}
