import dbRequest from "../../../../../../../services/dbRequest/dbRequest"

export default async function idCreationValidation({ models, entityName }) {
  const hasId = Boolean(models[entityName].ID)
  if (!hasId) delete models[entityName].ID

  const updatedRecord = await dbRequest
    .saveRecords(entityName)
    .record({ ...models[entityName] })
    .execute()

  models[entityName].populate({ ...updatedRecord })
}
