import Fontisto from "@expo/vector-icons/Fontisto"
import Ionicons from "@expo/vector-icons/Ionicons"
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { resetState } from "../../../../redux/redux-reducers/dashboard-reducer"
import showAlert from "../../../../services/showAlert"
import { defaultTheme } from "../../../../styles/themes/default"
import Form from "../../../../views/components/Form"
import ModalWrapper from "../../../../views/components/Modal"
import showDashboards from "../../utils/showDashboards"
import validationFields from "../SideBarSearchFields/utils/validationFields"
import * as S from "./styles"

const FilterModal = ({
  idDashboard,
  idfilial,
  controller,
  entityName,
  fields,
  isVisible,
  setIsVisible,
  isValid
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [updateForm, setUpdateForm] = useState(false)
  const [previousFilters, setPreviousFilters] = useState(null)

  const handleClearFields = () => {
    controller.models().DASHBOARD.reset()
    setUpdateForm((s) => !s)
  }

  const handleRefresh = async () => {
    if (await validationFields(controller, entityName, idDashboard, idfilial)) {
      dispatch(resetState())
    }
  }

  const handleApplyFilters = async () => {
    const currentFilters = { ...controller.models().DASHBOARD }

    if (JSON.stringify(currentFilters) === JSON.stringify(previousFilters)) {
      showAlert({
        text: t("Please make some changes"),
        title: "No changes",
        titleType: "warning"
      })
      return
    }

    await handleRefresh()

    if (showDashboards(fields, controller.models())) {
      setPreviousFilters(currentFilters)
      setIsVisible((state) => !state)
    }
  }

  return (
    <>
      <S.ButtonsContainer
        style={{ visibility: isValid ? "visible" : "hidden" }}
      >
        <S.Button onClick={() => setIsVisible((s) => !s)}>
          <Ionicons name="filter" color={defaultTheme["gray-700"]} size={16} />
          {t("Filters")}
        </S.Button>

        <S.Button onClick={handleRefresh}>
          <Ionicons name="reload" color={defaultTheme["gray-700"]} size={16} />
          {t("Refresh")}
        </S.Button>
      </S.ButtonsContainer>

      {isVisible && (
        <ModalWrapper isVisible={isVisible}>
          <S.ModalContainer>
            <S.HeaderContainer>
              <S.ModalTitle>{t("Filters")}</S.ModalTitle>

              <S.HeaderButtons>
                <S.ApplyButton onClick={handleApplyFilters}>
                  <S.Icon>
                    <Fontisto
                      name="save"
                      size={12}
                      style={{ marginLeft: 1 }}
                      color={defaultTheme["white"]}
                    />
                  </S.Icon>
                  <S.TitleButton>{t("Apply Filters")}</S.TitleButton>
                </S.ApplyButton>

                <S.ClearButton onClick={handleClearFields}>
                  <S.Icon>
                    <MaterialCommunityIcons
                      name="broom"
                      size={16}
                      color={defaultTheme["white"]}
                    />
                  </S.Icon>
                  <S.TitleButton>{t("Limpar")}</S.TitleButton>
                </S.ClearButton>

                <S.CloseButton onClick={() => setIsVisible((s) => !s)}>
                  <MaterialCommunityIcons
                    name="window-close"
                    size={26}
                    color={defaultTheme["gray-700"]}
                  />
                </S.CloseButton>
              </S.HeaderButtons>
            </S.HeaderContainer>

            <S.FormView>
              <Form
                key={updateForm}
                staticFields={fields}
                entity={"DASHBOARD"}
                models={controller.models()}
                updateForm={updateForm}
                hideTabs={true}
              />
            </S.FormView>
          </S.ModalContainer>
        </ModalWrapper>
      )}
    </>
  )
}

export default FilterModal
