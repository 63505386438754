import React, { useState } from "react"
import * as S from "./styles.web"

type SliderInputProps = {
  value: number;
  minValue: number;
  maxValue: number;
  onChange: React.Dispatch<React.SetStateAction<number>>;
};

const SliderInput: React.FC<SliderInputProps> = ({ value, minValue, maxValue, onChange }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false)

  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.target.value)
    onChange(newValue)
  }

  return (
    <S.SliderContainer>
      <S.ActualValue isVisible={tooltipVisible}>{value}</S.ActualValue>
      <S.InputSlider
        type="range"
        value={value}
        min={minValue}
        max={maxValue - 1}
        onMouseEnter={() => setTooltipVisible(true)}
        onMouseLeave={() => setTooltipVisible(false)}
        onChange={handleSliderChange}
      />
    </S.SliderContainer>
  )
}

export default SliderInput
