import LauncherComposerController from "../../../../controllers/LauncherComposerController"
import Navigation from "../../../../models/navigation"

export default async function insertRecords({ entity, entityName, setIsLoading, detailTabPath, models }) {

  setIsLoading(true)

  const controller = new LauncherComposerController() as any

  const masterTable = models._REGISTRATION_DATA[entity].masterTable

  if (masterTable) controller.models()[masterTable].populate({ ...models[masterTable] })

  await controller.initialize({
    entity,
    tabName: entityName.replace("Panel", ""),
    detailTabPath
  })
  setIsLoading(false)

  Navigation.createTab({
    component: "NavigationComposer",
    params: {
      attribute: null,
      controller: controller,
      isInitialScreen: true,
      initialParams: controller.initialScreenParams,
      RootParentID: "Launchers"
    },
    tabName: entityName.replace("Panel", "")
  })
}
