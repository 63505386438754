import React from "react"
import { useTranslation } from "react-i18next"
import * as S from "./styles"

export default function HeaderItem({ itemData, onPress }) {

  const { t } = useTranslation()

  return (
    <S.Container onPress={onPress}>
      <S.Title>{t(itemData.T_DTD_ATR.FIELDDESCRIPTION)}</S.Title>
    </S.Container>
  )
}
