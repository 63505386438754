import { saveFilter } from "../../../../../../../redux/redux-reducers/dashboardBuilder-reducer/utils/saveFilter"
import showAlert from "../../../../../../../services/showAlert"

export default function deleteFilter({ selectedFilter, selectedQuery, selectedDashboard, dispatch }) {
  if (!selectedFilter) return showAlert({
    title: "Unable to delete filter",
    titleType: "warning",
    text: "No filter selected"
  })

  showAlert({
    type: "confirm",
    title: "This action cannot be undone.",
    titleType: "warning",
    text: "Do you want to delete the filter?",
    handleConfirm: () => {
      const deleteFilterField = selectedFilter.TYPE.VALUE === "FIREBASE" ? "WHERE" : "FILTER"

      let updatedQuery = { ...selectedQuery }

      if (updatedQuery[deleteFilterField]) {
        const newFilterArray = updatedQuery[deleteFilterField].filter(filter => filter.FIELD !== selectedFilter.FIELD)

        updatedQuery = {
          ...updatedQuery,
          [deleteFilterField]: newFilterArray
        }
      }

      if (updatedQuery?.connections) delete updatedQuery.connections

      dispatch(saveFilter({
        updatedQuery,
        path: `T_RDB_DSB/${selectedDashboard.ID}/DATA`
      }))
    }
  })
}
