import styled from "styled-components"
import { defaultTheme } from "../../../../../styles/themes/default"

export const Container = styled.div`
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  outline: 3px solid ${defaultTheme["cyan-300"]};
  border: 2px solid ${defaultTheme["white"]};
  overflow: hidden;
`

export const Placeholder = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${defaultTheme["gray-200"]};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${defaultTheme["gray-700"]};
  font-weight: 600;
`

export const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: ${defaultTheme["gray-200"]};
`
