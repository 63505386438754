import React from "react"
import { useTranslation } from "react-i18next"
import * as S from "./styles"

export default function RenderList({ items, callback, permissions, transactionId }) {
  const { t } = useTranslation()

  const handlePress = (item, index) => {
    const aux = [...items]
    const newValue = !aux[index].isMarked
    aux[index].isMarked = newValue

    if (!permissions[transactionId])
      permissions[transactionId] = {}
    permissions[transactionId][item.ID] = newValue

    callback(aux)
  }

  return items.map((item, index) => (
    <S.Row key={index}>
      <S.CheckBox onClick={() => handlePress(item, index)} isMarked={item.isMarked}>
        <S.CheckIcon style={{ display: item.isMarked ? "flex" : "none" }} />
      </S.CheckBox>

      <span>{t(item.DESCRIPTION)}</span>
    </S.Row>
  ))
}
