import { Platform } from "react-native"

export default function listDecorator(controller, card) {

  controller.card = card
  controller.recordsList = []
  controller.recordsLimit = Platform.OS === "web" ? 15 : 8
  controller.recordsOffset = 5
  controller.recordsOffsetStep = 5
  controller.previousSearchText = " "
  controller.previousForceUpdate = false

  controller.sortingFilter = {
    ORDER_ASC: true,
    FIELD: ""
  }

  controller._sortingFilter = () => controller.sortingFilter.FIELD !== ""
    ? ` ORDER BY ${controller.sortingFilter?.FIELD} ${controller.sortingFilter?.ORDER_ASC ? "ASC" : "DESC"} `
    : ""

  controller.screenName = () => { throw new Error("Method 'screenName' was not overwrited") }

  controller.filterRecords = async (searchText, forceUpdate, scrollListToTop, sortingFilter) => {

    //if (controller.previousSearchText !== searchText) scrollListToTop()

    await controller.startProcessData(searchText, forceUpdate, sortingFilter)

    controller.recordsOffset = controller.recordsOffset + controller.recordsOffsetStep
    //return controller.recordsList.slice(0, controller.recordsOffset)
    return controller.recordsList
  }

  controller.startProcessData = async (searchText, forceUpdate, sortingFilter) => {

    if (controller.previousSearchText === searchText && controller.previousForceUpdate === forceUpdate && controller.sortingFilter === sortingFilter) return

    controller.previousSearchText = searchText
    controller.previousForceUpdate = forceUpdate
    if (sortingFilter !== undefined) controller.sortingFilter = sortingFilter

    await controller.processData(searchText)
  }

  controller.processData = async (searchText) => {

    let requestedRecordsAmount = (Platform.OS === "web" ? " AND ROWNUM <= " + controller.recordsLimit : "")
    let filter = controller?.filterResult ? controller.filterResult : ""
    //if (searchText === "") controller.recordsList = await controller.getRecords(filter, requestedRecordsAmount)
    //controller.recordsList = await controller.startGetFilteredRecords(searchText, filter, requestedRecordsAmount)
  }

  controller.startGetFilteredRecords = async (searchText, filter, requestedRecordsAmount) => {
    try { return await controller.getFilteredRecords(searchText, filter, requestedRecordsAmount) }
    catch (e) { return [] }
  }

  controller.getFilteredRecords = async (searchText, filter, requestedRecordsAmount) => {

    searchText = searchText.trim().split(" ")

    let columnsArray = []
    for (let i = 0; i < searchText.length; i++) {
      columnsArray.push(
        // Object
        //   .values(await apiRequest
        //     .queryExecuter(`SELECT NOMECAMPO FROM TDD_CAM this WHERE this.NOMETAB = '${controller.listEntityName()}'`)
        //     .execute()
        //   )
        // .map(column => "this." + column.NOMECAMPO + " LIKE '%" + searchText[i] + "%'")
        // .join(" OR ")
      )
    }
    let columnsString = columnsArray.map(columns => " AND (" + columns + ") ").join(" ")

    // return await apiRequest
    //   .serviceName(controller.listServiceName())
    //   .entityName(controller.listEntityName())
    //   .where(await controller.listWhere() + filter + columnsString + controller._sortingFilter() + requestedRecordsAmount)
    //   .execute()
  }

  controller.jumpScreenIfOnlyOne = () => { if (controller.recordsList?.length === 1) controller.startChangeScreen(controller.recordsList[0]) }

  controller.checkDefaultNav = async (field, defaultNav) => {
    let screenName = !controller.models().T_MOV_HDR[field] ? defaultNav : "Products"

    controller.models().T_MOV_HDR.TIPMOV = controller.models().T_MOV_HDR.TIPMOV.replace(/'/g, "")
    // if (screenName === "Products") await apiRequest.saveRecords("T_MOV_HDR")
    //   .where(controller.models().T_MOV_HDR)
    //   .execute()

    return screenName
  }
}

