import RuntimeConfiguration from "../../../../../../../models/runtimeConfiguration"
import dbRequest from "../../../../../../../services/dbRequest/dbRequest"
import showAlert from "../../../../../../../services/showAlert"

export default async function deleteVariable({ variable, variablesPath }) {
  const isAdminMode = RuntimeConfiguration.getDashboardBuilderAdminMode()

  try {
    const request = dbRequest
      .deleteRecords(variablesPath)
      .record(variable)

    if (isAdminMode) request.admin()

    await request.execute()

    showAlert({
      titleType: "success",
      title: "Variable deleted",
      text: "Variable deleted successfully"
    })

  } catch (error) {
    showAlert({
      titleType: "error",
      title: "Error when trying to delete variable",
      text: "Please try again"
    })
    console.error(error)
  }
}
