import Navigation from "../../../../../models/navigation"
import Permissions from "../../../../../models/permissions"
import { updateActions, updateCharts as updateChart } from "../../../../../redux/redux-reducers/dashboard-reducer"
import dbRequest from "../../../../../services/dbRequest/dbRequest"
import showAlert from "../../../../../services/showAlert"

export default function generateChartEvents(props) {

  const chartEvents = [
    {
      eventName: "error",
      callback: event => handleErrors(event)
    },
    {
      eventName: "select",
      callback: ({ chartWrapper }) => {

        const { series, chartValue, chartId } = props

        const item = chartWrapper
          .getChart().getSelection()[0]

        const serieName = chartWrapper.getChartType() === "Table" ?
          series["1"].DESCRIPTION
          : item?.column ? chartWrapper.getDataTable().getColumnLabel(item?.column) : null

        let selectedSerie = null

        for (const id in series) if (series[id].DESCRIPTION === serieName) selectedSerie = series[id]

        if (!selectedSerie?.ACTIONEVENT?.TYPE.VALUE && chartWrapper.getChartType() !== "Table") return

        if (
          !item
          || item.row === null
          || (item.column === null && !["PieChart", "Table"].includes(chartWrapper.getChartType()))
        ) return

        const selectedRowLabel = chartWrapper.getDataTable().getValue(item.row, 0)

        const filteredObjectIndex = chartValue?.findIndex(item => {
          return Object.keys(item).some(key => item[key] === selectedRowLabel)
        })

        const selectedRow = {
          serieName: serieName,
          row: item.row,
          chartId: chartId
        }

        props.selectedRow = selectedRow

        let chartParams = null
        if (chartWrapper.getChartType() === "Table") chartParams = chartValue[item.row]
        else chartParams = filteredObjectIndex !== -1 ? chartValue[filteredObjectIndex] : {}

        checkActionsType(selectedSerie, props, chartParams)
      }
    }
  ]

  return chartEvents
}

function handleErrors(err) {
  err.google?.visualization.errors.removeAll(err.chartWrapper?.getContainer())
  err.google?.visualization.errors.removeError(err.chartWrapper?.getContainer())
}

function checkActionsType(serie, props, chartParams) {

  switch (serie?.ACTIONEVENT.TYPE?.VALUE) {
    case "L": return navigateLevel(serie, props, chartParams)
    case "E": return navigateScreen(serie, chartParams)
    case "U": return updateCharts(serie, props, chartParams)
    default: break
  }
}

function navigateLevel(serie, props, chartParams) {
  const { PARAMETER } = serie.ACTIONEVENT
  const { dispatch, currentLevel } = props

  dispatch(updateActions({
    newLevel: PARAMETER,
    chartParams,
    previousLevel: currentLevel
  }))
}

async function navigateScreen(serie, chartParams) {

  const { NAVIGATETO, PARAMETER } = serie.ACTIONEVENT

  const record = await getRecord(PARAMETER, NAVIGATETO.ID, chartParams)

  const hasPermissions = Permissions.getPermission(NAVIGATETO.TRANSACTION)

  if (hasPermissions) {
    Navigation.createTab({
      component: NAVIGATETO.TYPE.VALUE,
      params: {
        attribute: NAVIGATETO.ID,
        record
      },
      tabName: NAVIGATETO.ENTITY
    })
  } else {
    showAlert({
      text: "Insufficient permissions",
      title: "Failed to access the screen",
      titleType: "error"
    })
  }
}

function updateCharts(serie, props, chartParams) {
  const { PARAMETER } = serie.ACTIONEVENT

  const { dispatch, chartIdArr, selectedRow } = props

  dispatch(updateChart({
    chartIdArr,
    chartParams,
    idacaoArr: PARAMETER.split(","),
    selectedRow: selectedRow
  }))
}

async function getRecord(fieldKey, entityID, chartParams) {
  let value = chartParams[fieldKey]
  return await dbRequest.loadRecords(entityID).where("ID", "==", value)
    .execute(r => r[0])
}
