import { createSlice } from "@reduxjs/toolkit"
import showAlert from "../../../services/showAlert"
import { menuDrawerObserver } from "../../../views/components/MenuDrawer/hooks/useMenu"
import { deleteConnection } from "./utils/deleteConnection"
import deleteDashboard from "./utils/deleteDashboard"
import { deleteDashboardField } from "./utils/deleteDashboardField"
import { deleteGadget } from "./utils/deleteGadget"
import { deleteLevel } from "./utils/deleteLevel"
import deleteOptionList from "./utils/deleteOptionList"
import { deleteQuery } from "./utils/deleteQuery"
import { deleteSerie } from "./utils/deleteSerie"
import { fetchDashboard } from "./utils/fetchDashboard"
import processFiltersList from "./utils/processFiltersList"
import processSelectedConnection from "./utils/processSelectedConnection"
import processSelectedDashboard from "./utils/processSelectedDashboard"
import processSelectedField from "./utils/processSelectedField"
import processSelectedFilter from "./utils/processSelectedFilter"
import processSelectedGadget from "./utils/processSelectedGadget"
import processSelectedLevel from "./utils/processSelectedLevel"
import processSelectedQuery from "./utils/processSelectedQuery"
import processSelectedSerie from "./utils/processSelectedSerie"
import saveConnection from "./utils/saveConnection"
import { saveDashboard } from "./utils/saveDashboard"
import { saveDashboardLayout } from "./utils/saveDashboardLayout"
import saveField from "./utils/saveField"
import saveFieldListOption from "./utils/saveFieldListOption"
import { saveFilter } from "./utils/saveFilter"
import { saveGadget } from "./utils/saveGadget"
import { saveLevel } from "./utils/saveLevel"
import { saveQuery } from "./utils/saveQuery"
import saveSerie from "./utils/saveSerie"

export const initialState = {
  dashboardData: {
    selectedDashboard: null,
    selectedQuery: null,
    selectedConnection: null,
    selectedLevel: null,
    selectedGadget: null,
    selectedSerie: null,
    fields: [],
    queries: [],
    levels: [],
    status: "idle",
    error: null,
    path: null
  },
  modals: {
    dashboardModal: {
      name: "dashboardModal",
      isVisible: false,
      mode: "new"
    },
    fieldListModal: {
      name: "fieldListModal",
      title: "Field List",
      isVisible: false,
      mode: "new",
      path: null,
      fieldsArray: []
    },
    fieldModal: {
      name: "fieldModal",
      isVisible: false,
      mode: "new",
      isSaved: false,
      selectedField: null,
      path: "",
      options: []
    },
    configureOptionsListModal: {
      name: "configureOptionsListModal",
      isVisible: false,
      mode: "new"
    },
    filtersConfigurationModal: {
      name: "filtersConfigurationModal",
      isVisible: false,
      filters: []
    },
    filterModal: {
      name: "filterModal",
      isVisible: false,
      mode: "new",
      selectedFilter: null
    },
    queryModal: {
      name: "queryModal",
      isVisible: false,
      mode: null,
      form: {}
    },
    variablesModal: {
      name: "variablesModal",
      isVisible: false
    },
    connectionModal: {
      name: "connectionModal",
      isVisible: false,
      mode: null,
      masterPath: null,
      fieldPath: null,
      collectionPath: null,
      entityName: null,
      form: {}
    },
    levelModal: {
      name: "levelModal",
      isVisible: false,
      mode: null,
      form: {}
    },
    gadgetModal: {
      name: "gadgetModal",
      isVisible: false,
      mode: null,
      form: {}
    },
    editGroupingModal: {
      name: "editGroupingModal",
      isVisible: false,
      selectedQuery: null
    },
    serieModal: {
      name: "serieModal",
      isVisible: false,
      mode: null
    }
  },
  updateTable: false,
  updateGlyphs: false
}

const dashboardBuilderSlice = createSlice({
  name: "dashboardBuilder",
  initialState,
  reducers: {
    setSelectedDashboard: processSelectedDashboard,
    setSelectedField: processSelectedField,
    setSelectedQuery: processSelectedQuery,
    setSelectedFilter: processSelectedFilter,
    setSelectedConnection: processSelectedConnection,
    setSelectedLevel: processSelectedLevel,
    setSelectedGadget: processSelectedGadget,
    setSelectedSerie: processSelectedSerie,
    resetState: (state) => {
      state.dashboardData = initialState.dashboardData
      state.modals = initialState.modals
    },
    openFieldListModal: (state, { payload }) => {
      const { fields, path, title } = payload
      state.modals.fieldListModal.isVisible = true
      state.modals.fieldListModal.fieldsArray = fields ?? []
      state.modals.fieldListModal.path = path
      state.modals.fieldListModal.title = title
    },
    openConfigureOptionsListModal: (state) => {
      state.modals.configureOptionsListModal.isVisible = true
    },
    openFiltersConfigurationModal: (state, { payload }) => {
      const filters = processFiltersList(payload)

      state.modals.filtersConfigurationModal.isVisible = true
      state.modals.filtersConfigurationModal.filters = filters
    },
    openVariablesModal: (state) => {
      state.modals.variablesModal.isVisible = true
    },
    openFilterModal: (state, { payload }) => {
      state.modals.filterModal.isVisible = true
      if (payload) {
        state.modals.filterModal.form = payload
        state.modals.filterModal.mode = "edit"
      } else state.modals.filterModal.mode = "new"
    },

    changeFieldModalMode: (state, { payload }) => {
      if (payload.ID !== "" && payload.ID !== null) state.modals.fieldModal.mode = "edit"
      else showAlert({
        titleType: "warning",
        title: "Unable to proceed",
        text: "Fill in all required fields."
      })
    },
    openEditGroupingModal: (state, { payload }) => {
      state.modals.editGroupingModal.isVisible = true
      state.modals.editGroupingModal.selectedQuery = payload
    },
    changeFormValue: (state, { payload }) => {
      state.modals[payload.modalName].form[payload.fieldName] = payload.value
    },
    closeModal: (state, { payload }) => {
      state.modals[payload.modalName] = initialState.modals[payload.modalName]
    },
    setUpdateGlyphs: (state) => {
      const prevState = state.modals.levelModal.updateGlyphs
      state.modals.levelModal.updateGlyphs = !prevState
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDashboard.fulfilled, (state, action) => {

      const { selectedDashboard, dashboardQueries, dashboardLevels, dashboardFields } = action.payload

      const selectedQuery = state.dashboardData.selectedQuery?.ID

      if (selectedQuery) {
        const [updatedQuery] = dashboardQueries.filter(item => item.ID === selectedQuery)
        state.dashboardData.selectedQuery = updatedQuery
      }

      state.dashboardData.status = "succeeded"
      state.dashboardData.fields = dashboardFields
      state.dashboardData.queries = dashboardQueries
      state.dashboardData.levels = dashboardLevels
      state.dashboardData.selectedDashboard = selectedDashboard
      state.modals.dashboardModal.form = selectedDashboard
      state.modals.dashboardModal.mode = "edit"
      state.dashboardData.path = `T_RDB_DSB/${selectedDashboard.ID}/DATA`
    })
      .addCase(fetchDashboard.rejected, (state, action) => {
        console.error(action.error)
      })

    builder.addCase(saveDashboard.fulfilled, (state, action) => {
      showAlert({
        title: "Success",
        text: "Dashboard saved successfully.",
        titleType: "success"
      })
      state.dashboardData.selectedDashboard = action.payload
      state.modals.dashboardModal.form = action.payload
      state.modals.dashboardModal.mode = "edit"
      state.dashboardData.path = `T_RDB_DSB/${action.payload.ID}/DATA`
      const updateTable = !state.updateTable
      state.updateTable = updateTable
    })
    builder.addCase(saveDashboard.rejected, (_, action) => console.error(action.error))

    builder.addCase(saveField.fulfilled, (state, action) => {
      showAlert({
        title: "Success",
        text: "Field saved successfully.",
        titleType: "success"
      })
      state.modals.fieldModal = initialState.modals.fieldModal
      state.modals.fieldListModal.fieldsArray = [...action.payload.updatedFields]
    })
    builder.addCase(saveField.rejected, (_, action) => console.error(action.error))

    builder.addCase(deleteDashboardField.fulfilled, (state, action) => {
      state.modals.fieldListModal.fieldsArray = [...action.payload.updatedFields]
    })
    builder.addCase(deleteDashboardField.rejected, (_, action) => console.error(action.error))

    builder.addCase(saveQuery.fulfilled, (state, action) => {
      showAlert({
        title: "Success",
        text: "Query saved successfully.",
        titleType: "success"
      })
      state.modals.queryModal.mode = "edit"

      const existingQueryIndex = state.dashboardData.queries.findIndex(query => query.ID === action.payload.ID)
      if (existingQueryIndex === -1) state.dashboardData.queries.push(action.payload)
      else state.dashboardData.queries[existingQueryIndex] = action.payload

      state.dashboardData.selectedQuery = action.payload
      // state.modals.filtersConfigurationModal.filters =
    })
    builder.addCase(saveQuery.rejected, (_, action) => console.error(action.error))

    builder.addCase(saveFilter.fulfilled, (state, action) => {
      const { updatedQuery, queryID } = action.payload

      const filters = []
      if (updatedQuery.FILTER) filters.push(...updatedQuery.FILTER)
      if (updatedQuery.WHERE) filters.push(...updatedQuery.WHERE)

      state.modals.filtersConfigurationModal.filters = filters

      const existingQueryIndex = state.dashboardData.queries.findIndex(query => query.ID === queryID)

      if (updatedQuery.FILTER) {
        state.dashboardData.selectedQuery.FILTER = updatedQuery.FILTER
        state.dashboardData.queries[existingQueryIndex].FILTER = updatedQuery.FILTER
      }
      if (updatedQuery.WHERE) {
        state.dashboardData.selectedQuery.WHERE = updatedQuery.WHERE
        state.dashboardData.queries[existingQueryIndex].WHERE = updatedQuery.WHERE
      }
    }).addCase(saveFilter.rejected, (_, action) => console.error(action.error))

    builder.addCase(saveConnection.fulfilled, (state, action) => {
      showAlert({
        title: "Success",
        text: "Connection saved successfully.",
        titleType: "success"
      })

      state.modals.connectionModal.mode = "edit"
      state.modals.connectionModal.masterPath = action.payload.masterPath
      state.modals.connectionModal.entityName = action.payload.connection.ENTITY.ID
      state.modals.connectionModal.fieldPath = action.payload.connection.FIELDPATH
      state.modals.connectionModal.collectionPath = action.payload.connection.COLLECTIONPATH
      state.dashboardData.selectedConnection = action.payload.connection
    })

    builder.addCase(deleteDashboard.fulfilled, (state) => {
      state.dashboardData = initialState.dashboardData
      state.modals.dashboardModal.isVisible = false
      const updateTable = !state.updateTable
      state.updateTable = updateTable
      menuDrawerObserver.notify()
    })

    builder.addCase(deleteQuery.fulfilled, (state, action) => {
      const queries = state.dashboardData.queries
      state.dashboardData.queries = queries.filter(query => query.ID !== action.payload.deletedQuery.ID)
      state.dashboardData.selectedQuery = null
    })

    builder.addCase(deleteConnection.fulfilled, (state, action) => {
      const connections = state.dashboardData.selectedQuery.connections
      state.dashboardData.selectedQuery.connections = connections.filter(connection => connection.ID !== action.payload.deletedConnection.ID)
      state.dashboardData.selectedConnection = null
    })

    builder.addCase(saveLevel.fulfilled, (state, action) => {
      showAlert({
        title: "Success",
        text: "Level saved successfully.",
        titleType: "success"
      })
      state.modals.levelModal.mode = "edit"
      state.dashboardData.selectedLevel = action.payload
      state.modals.levelModal.form = action.payload

      const levels = state.dashboardData.levels
      if (!levels.some(item => item.ID === action.payload.ID)) { //TODO quando for update atualizar obj no array
        levels.push(action.payload)
        state.dashboardData.levels = levels
      }
    })
    builder.addCase(saveLevel.rejected, (_, action) => console.error(action.error))

    builder.addCase(saveDashboardLayout.fulfilled, (state, action) => {
      const { updatedGadgets, selectedLevel } = action.payload
      const prevLevels = state.dashboardData.levels

      const levelIndex = prevLevels.findIndex(level => level.ID === selectedLevel.ID)

      state.dashboardData.selectedLevel.gadgets = updatedGadgets

      if (levelIndex !== -1) {
        prevLevels[levelIndex] = {
          ...prevLevels[levelIndex],
          gadgets: updatedGadgets
        }
        state.dashboardData.levels = [...prevLevels]
      }
    })

    builder.addCase(deleteLevel.fulfilled, (state, action) => {
      const levels = state.dashboardData.levels
      state.dashboardData.levels = levels.filter(level => level.ID !== action.payload.selectedLevel.ID)
      state.dashboardData.selectedLevel = null
    })
    builder.addCase(deleteLevel.rejected, (_, action) => console.error(action.error))

    builder.addCase(saveGadget.fulfilled, (state, action) => {
      const { savedGadget, selectedLevel } = action.payload

      showAlert({
        title: "Success",
        text: "Gadget saved successfully.",
        titleType: "success"
      })
      state.modals.gadgetModal.mode = "edit"
      state.dashboardData.selectedGadget = savedGadget
      state.modals.gadgetModal.form = savedGadget

      const levelIndex = state.dashboardData.levels.findIndex(item => item.ID === selectedLevel.ID)

      if (levelIndex !== -1) {
        const gadgetIndex = state.dashboardData.levels[levelIndex].gadgets.findIndex(item => item.ID === savedGadget.ID)

        let updatedGadgets = state.dashboardData.levels[levelIndex].gadgets
        if (gadgetIndex !== -1) {
          updatedGadgets = updatedGadgets.map(item => {
            if (item.ID === savedGadget.ID) return savedGadget
            return item
          })
        } else updatedGadgets.push(savedGadget)
        state.dashboardData.selectedLevel.gadgets = updatedGadgets
        state.dashboardData.levels[levelIndex].gadgets = updatedGadgets
      }
    })
    builder.addCase(saveGadget.rejected, (_, action) => console.error(action.error))

    builder.addCase(deleteGadget.fulfilled, (state, action) => {
      const { selectedGadget, selectedLevel } = action.payload

      const levelIndex = state.dashboardData.levels.findIndex(item => item.ID === selectedLevel.ID)

      let updatedGadgets = null
      if (levelIndex !== -1) {
        updatedGadgets = state.dashboardData.levels[levelIndex].gadgets.filter(item => item.ID !== selectedGadget.ID)
        state.dashboardData.selectedLevel.gadgets = updatedGadgets
        state.dashboardData.levels[levelIndex].gadgets = updatedGadgets
      }
      state.dashboardData.selectedGadget = null
    })
    builder.addCase(deleteGadget.rejected, (_, action) => console.error(action.error))

    builder.addCase(saveSerie.fulfilled, (state, action) => {

      const { serie } = action.payload

      const selectedLevel = state.dashboardData.selectedLevel
      const selectedGadget = state.dashboardData.selectedGadget

      showAlert({
        title: "Success",
        text: "Serie saved successfully.",
        titleType: "success"
      })

      state.modals.serieModal.mode = "edit"
      state.dashboardData.selectedSerie = serie
      state.modals.serieModal.form = serie

      const levelIndex = state.dashboardData.levels.findIndex(item => item.ID === selectedLevel.ID)

      if (levelIndex !== -1) {
        const gadgetIndex = state.dashboardData.levels[levelIndex].gadgets.findIndex(item => item.ID === selectedGadget.ID)

        if (gadgetIndex !== -1) {
          let updatedSeries = state.dashboardData.levels[levelIndex].gadgets[gadgetIndex].series

          if (updatedSeries.some(item => item.ID === serie.ID)) {
            updatedSeries = updatedSeries.map(item => {
              if (item.ID === serie.ID) return serie
              return item
            })
          } else {
            updatedSeries.push(serie)
          }

          state.dashboardData.selectedGadget.series = updatedSeries
          state.dashboardData.levels[levelIndex].gadgets[gadgetIndex].series = updatedSeries
          state.dashboardData.selectedLevel.gadgets[gadgetIndex].series = updatedSeries
        } else {
          console.warn(`Gadget with ID ${selectedGadget.ID} not found in level with ID ${selectedLevel.ID}`)
        }
      } else {
        console.warn(`Level with ID ${selectedLevel.ID} not found`)
      }

    })
    builder.addCase(saveSerie.rejected, (_, action) => console.error(action.error))

    builder.addCase(deleteSerie.fulfilled, (state, action) => {
      const { selectedSerie } = action.payload

      const selectedLevel = state.dashboardData.selectedLevel
      const selectedGadget = state.dashboardData.selectedGadget

      const levelIndex = state.dashboardData.levels.findIndex(item => item.ID === selectedLevel.ID)

      if (levelIndex !== -1) {
        const gadgetIndex = state.dashboardData.levels[levelIndex].gadgets.findIndex(item => item.ID === selectedGadget.ID)

        if (gadgetIndex !== -1) {
          let updatedSeries = state.dashboardData.levels[levelIndex].gadgets[gadgetIndex].series.filter(item => item.ID !== selectedSerie.ID)

          state.dashboardData.selectedGadget.series = updatedSeries
          state.dashboardData.levels[levelIndex].gadgets[gadgetIndex].series = updatedSeries
          state.dashboardData.selectedLevel.gadgets[gadgetIndex].series = updatedSeries
        } else {
          console.warn(`Gadget with ID ${selectedGadget.ID} not found in level with ID ${selectedLevel.ID}`)
        }
      } else {
        console.warn(`Level with ID ${selectedLevel.ID} not found`)
      }

      state.dashboardData.selectedSerie = null
      state.dashboardData.selectedSerie = null
    })
    builder.addCase(deleteSerie.rejected, (_, action) => console.error(action.error))

    builder.addCase(saveFieldListOption.fulfilled, (state, action) => {

      const fields = state.modals.fieldListModal.fieldsArray

      const updatedField = state.modals.fieldModal.selectedField

      updatedField.T_DTD_OPT = action.payload.updateOptions
      state.modals.fieldModal.options = action.payload.updateOptions
      const updatedFieldIndex = fields.findIndex(item => item.ID === updatedField.ID)

      if (updatedFieldIndex !== -1) {
        fields[updatedFieldIndex] = updatedField
        state.modals.fieldListModal.fieldsArray = fields
      }

    })
    builder.addCase(saveFieldListOption.rejected, (_, action) => console.error(action.error))

    builder.addCase(deleteOptionList.fulfilled, (state, action) => {
      const fields = state.modals.fieldListModal.fieldsArray

      const updatedField = state.modals.fieldModal.selectedField

      updatedField.T_DTD_OPT = action.payload.updateOptions
      state.modals.fieldModal.options = action.payload.updateOptions
      const updatedFieldIndex = fields.findIndex(item => item.ID === updatedField.ID)

      if (updatedFieldIndex !== -1) {
        fields[updatedFieldIndex] = updatedField
        state.modals.fieldListModal.fieldsArray = fields
      }
    })
    builder.addCase(deleteOptionList.rejected, (_, action) => console.error(action.error))
  }
})

export const {
  resetState,
  setSelectedDashboard,
  setSelectedField,
  setSelectedQuery,
  setSelectedFilter,
  setSelectedLevel,
  setSelectedConnection,
  setSelectedGadget,
  setSelectedSerie,
  changeFormValue,
  closeModal,
  openFieldListModal,
  openConfigureOptionsListModal,
  openFiltersConfigurationModal,
  openFilterModal,
  openVariablesModal,
  changeFieldModalMode,
  openEditGroupingModal,
  setUpdateGlyphs
} = dashboardBuilderSlice.actions
export const dashboardBuilderReducer = dashboardBuilderSlice.reducer
