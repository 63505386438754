import styled, { keyframes } from "styled-components"

interface LoaderProps {
  size: number
  color: string
}

const spin = keyframes`
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg) }
`

const Loader = styled.div<LoaderProps>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;

  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left: 4px solid ${({ color }) => color};
  border-radius: 50%;

  animation: ${spin} 1s linear infinite;
`

export default Loader