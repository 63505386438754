import evaluateFunction from "../../../../../../services/evaluateFunction"

const taxesCalculation = async (controller, MOV_ITE, product, selectedUnit) => {
  const taxes = await controller.taxes
  const taxesTemplate = await controller.getTaxTemplate()

  let _EXTTAX = 0

  for (const tax of taxes) {
    if (tax.ACTIVE) {
      for (const key of tax.T_SET_TAX_ITEMS) {
        taxesTemplate[tax.ID].T_MOV_TAX_ITEMS[key.ID] = await evaluateFunction(key.EXPRESSION, controller.models(), {
          T_MOV_TAX: taxesTemplate,
          T_MOV_ITE: MOV_ITE,
          ITEMID: product
        })
        _EXTTAX += Number(taxesTemplate[tax.ID].T_MOV_TAX_ITEMS[key.ID]) * key.EXTTAX.VALUE
      }
    }
  }
  controller.models().T_MOV_TAX = taxesTemplate
  return Number(_EXTTAX.toFixed(selectedUnit.VALUEDEC))
}

export default taxesCalculation
