import { Ionicons } from "@expo/vector-icons"
import styled from "styled-components"
import { defaultTheme } from "../../../../../../styles/themes/default"

interface ContainerProps {
  hasValue: boolean
  isEditable: boolean
}

export const Container = styled.button<ContainerProps>`
  align-items: center;
  background-color: ${({ hasValue, isEditable }) => hasValue
    ? defaultTheme["orange-500"]
    : defaultTheme[isEditable ? "cyan-500" : "gray-200"]};
  border-radius: 4px;
  display: flex;
  gap: 5px;
  height: ${defaultTheme.formInput.height}px;
  justify-content: center;
  padding: 0 10px 0 5px;

  span {
    color: ${defaultTheme["white"]};
    font-size: 12px;
    font-weight: 500;
  }
`

export const Icon = styled(Ionicons)
  .attrs(({
    color: defaultTheme["white"],
    name: "attach",
    size: 18,
  }))``
