import { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import useAppSelector from "../../../../../redux/hooks/useAppSelector"
import { setProduct, removeProduct } from "../../../../../redux/redux-reducers/order-reducer"
import dbRequest from "../../../../../services/dbRequest/dbRequest"
import removeFieldsFromObject from "../../../../../services/removeFieldsFromObject"
import showAlert from "../../../../../services/showAlert"
import checkIfHasPromotion from "./utils/checkIfHasPromotion"
import checkIfIsInclude from "./utils/checkIfIsInclude"
import checkIncludedProductUnit from "./utils/checkIncludedProductUnit"
import getDefaultProductAmount from "./utils/getDefaultProductAmount"
import taxesCalculation from "./utils/taxesCalculation"

export default function useProductManager(product, controller) {

  const [fields, setFields] = useState([])

  if (controller.screenName === "Products") {

    const dispatch = useDispatch()

    const { addedProducts, totalizerValues } = useAppSelector(({ orderReducer }) => orderReducer)

    controller.addedProducts = addedProducts

    controller.models().T_MOV_HDR.DOCUMENTVALUE = totalizerValues.totalAmount
    controller.models().T_MOV_HDR.TOTALQUANTITY = totalizerValues.totalQuantity
    controller.models().T_MOV_HDR.TOTALWEIGHT = totalizerValues.totalWeight
    controller.models().T_MOV_HDR.EXTTAX = totalizerValues.totalExttax

    const defaultUnit = {
      QTTDEC: product.QTTDEC,
      VALUEDEC: product.VALUEDEC,
      DIVMULT: {
        VALUE: "M"
      },
      QUANTITY: 1,
      VALUEINDEX: 1,
      ID: product.UNITID.ID
    }

    const [isLoading, setIsLoading] = useState(true)
    const [units, setUnits] = useState([defaultUnit])
    const [selectedUnit, setSelectedUnit] = useState(defaultUnit)
    const [defaultAmount, setDefaultAmount] = useState(0)
    const [productTemplate, setProductTemplate] = useState(checkIfIsInclude(addedProducts, controller, product, setDefaultAmount))
    const [editAmountNeg, setEditAmountNeg] = useState(true)

    useEffect(() => {
      (async () => {
        setFields(controller.customFields)

        const newAllFields = checkIfIsInclude(addedProducts, controller, product, setDefaultAmount)
        if (newAllFields.AMOUNTNEG > 0) setDefaultAmount(newAllFields.AMOUNTNEG)

        const productUnit = await checkIncludedProductUnit(addedProducts, product, controller, controller.models())
        if (productUnit) setSelectedUnit(productUnit)

        await getDefaultProductAmount(newAllFields, product)

        const promotionValue = await checkIfHasPromotion(controller, newAllFields, product, setEditAmountNeg)
        if (promotionValue) setDefaultAmount(promotionValue)

        if (!newAllFields.AMOUNTNEG) {
          newAllFields.AMOUNTNEG = selectedUnit.DIVMULT.VALUE === "M" ?
            newAllFields.ITEMAMOUNT * selectedUnit.QUANTITY
            : newAllFields.ITEMAMOUNT / selectedUnit.QUANTITY
        }

        setDefaultAmount(newAllFields.AMOUNTNEG)
        setProductTemplate(newAllFields)

        setIsLoading(false)
      })()
    }, [])

    const handleCalculation = useCallback(async (operation, value) => {

      const unitReference = operation !== "change-unit" ? selectedUnit : value

      const newAllFields = { ...productTemplate }

      if (!newAllFields.ITEMAMOUNT) return showAlert({
        text: "Product without list price",
        title: "Calculation error",
        titleType: "error"
      })

      switch (operation) {
        case "increment-quantity":
          newAllFields.QUANTITY += 1
          break
        case "decrement-quatity":
          if (newAllFields.QUANTITY > 0) newAllFields.QUANTITY -= 1
          if (newAllFields.QUANTITY <= 0) {
            newAllFields.QUANTITY = 0
            newAllFields.AMOUNT = 0
            newAllFields.TOTALQUANTITY = 0
            newAllFields.AMOUNT = 0
            newAllFields.EXTTAX = 0
          }
          break
        case "manual-quantity":
          newAllFields.QUANTITY = value
          if (newAllFields.QUANTITY <= 0) {
            newAllFields.QUANTITY = 0
            newAllFields.AMOUNT = 0
            newAllFields.TOTALQUANTITY = 0
            newAllFields.AMOUNT = 0
            newAllFields.EXTTAX = 0
          }
          break
        case "change-unit-price":
          newAllFields.AMOUNTNEG = value
          break
        default:
          break
      }

      const itemQuantity = newAllFields.QUANTITY || 1

      const quantityReference = unitReference.DIVMULT.VALUE === "M"
        ? itemQuantity * unitReference.QUANTITY
        : itemQuantity / unitReference.QUANTITY

      newAllFields.TOTALQUANTITY = quantityReference

      if (operation === "change-unit") newAllFields.AMOUNTNEG = newAllFields.ITEMAMOUNT * unitReference.QUANTITY

      setDefaultAmount(newAllFields.AMOUNTNEG)

      if (newAllFields.QUANTITY && newAllFields.AMOUNTNEG) {

        await checkIfHasPromotion(controller, newAllFields, product, setEditAmountNeg)

        newAllFields.AMOUNT = (newAllFields.AMOUNTNEG * newAllFields.QUANTITY) * unitReference.VALUEINDEX
        newAllFields.TOTALGROSSWEIGHT = product.GROSSWEIGHT * newAllFields.TOTALQUANTITY
        newAllFields.EXTTAX = await taxesCalculation(controller, newAllFields, product, selectedUnit)
        newAllFields.AMOUNT += newAllFields.EXTTAX
        setDefaultAmount(newAllFields.AMOUNTNEG)

        const newproductTemplate = newAllFields

        setProductTemplate(newproductTemplate)

        if (addedProducts.length === 0) newAllFields.ID = 1
        if (addedProducts.length > 0 && !newAllFields?.ID) newAllFields.ID = Math.max(...addedProducts.map(item => item.ID)) + 1

        newAllFields.UNITID = removeFieldsFromObject(unitReference)

        setProductTemplate(newproductTemplate)
        setTimeout(() => {
          dispatch(setProduct({
            T_MOV_ITE: newAllFields,
            ITEMID: removeFieldsFromObject(product)
          }))
        }, 1)

      } else {
        if (!newAllFields.ID) return
        if (newAllFields.ID !== null || newAllFields !== undefined) dispatch(removeProduct({ newAllFields }))

        newAllFields.ID = newAllFields.ID.toString()

        dbRequest
          .deleteRecords(`T_MOV_HDR/${controller.models().T_MOV_HDR.ID}/T_MOV_ITE`)
          .record({ ...newAllFields })
          .execute(async () => {
            dbRequest
              .deleteCollection(`T_MOV_HDR/${controller.models().T_MOV_HDR.ID}/T_MOV_ITE/${newAllFields.ID}/T_MOV_TAX`)
              .execute()
          })

        newAllFields.AMOUNT = 0
        newAllFields.ID = null
        newAllFields.EXTTAX = 0
        setProductTemplate(newAllFields)
      }
    }, [
      selectedUnit,
      productTemplate,
      showAlert,
      setDefaultAmount,
      checkIfHasPromotion,
      controller,
      setEditAmountNeg,
      taxesCalculation,
      product,
      addedProducts,
      dispatch,
      removeProduct,
      dbRequest
    ])

    const handleGetUnits = async () => {
      if (units.length === 1) {
        const dataUnits = await controller.getProductUnits(product.ID)
        setUnits(prevState => {
          const newArray = [...prevState]
          dataUnits.map(item => newArray.push(item))
          return newArray
        })
      }
    }

    return {
      productTemplate,
      units,
      selectedUnit,
      setSelectedUnit,
      handleCalculation,
      editAmountNeg,
      fields,
      defaultAmount,
      handleGetUnits,
      isLoading
    }

  } else {

    const productTemplate = product
    const editAmountNeg = false

    useEffect(() => { setFields(controller.customFields) }, [])

    return {
      productTemplate,
      editAmountNeg,
      fields
    }
  }
}
