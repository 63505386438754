import LoginData from "../../../../../../models/loginData"
import Permissions from "../../../../../../models/permissions"

export default ({ functions, entityName, mode }) => {
  const addFunction = ({ DESCRIPTION, EXPRESSION, SELECTLINE = false }) => {
    functions.push({
      ACTIVE: true,
      DESCRIPTION,
      EXPRESSION,
      ORDERBY: 1,
      SELECTLINE,
      TYPE: {
        LABEL: "Native",
        VALUE: "N"
      }
    })
  }

  const { ADMIN, EXPORT_REPORTS } = LoginData.getLoginData()

  if (EXPORT_REPORTS)
    addFunction({
      DESCRIPTION: "Export to .xls extension",
      EXPRESSION: "fnc_exportSpreadsheet"
    })

  if (["T_SET_RLU", "T_SET_UGR"].includes(entityName) && ADMIN)
    addFunction({
      DESCRIPTION: "Access configuration",
      EXPRESSION: "fnc_access",
      SELECTLINE: true
    })

  if (entityName === "T_REC_PTN" && Permissions.getPermission("CREATE") && mode === "form")
    addFunction({
      DESCRIPTION: "Import ReceitaWS data",
      EXPRESSION: "fnc_receitaWS"
    })

  if (entityName === "T_SET_TNS") {
    addFunction({
      DESCRIPTION: "Configure header",
      EXPRESSION: "T_MOV_HDR/T_SET_HDR",
      SELECTLINE: true
    })
    addFunction({
      DESCRIPTION: "Configure items",
      EXPRESSION: "T_MOV_ITE/T_SET_ITE",
      SELECTLINE: true
    })
  }
}

